import React, { useState } from 'react'
import { Box, Button, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import ModalCadastroEmpreendimento from './ModalCadastroEmpreendimento'
import PropTypes from 'prop-types'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
  root: {
    width: 320
  },
  titleIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  title: {
    fontSize: '1.4rem',
    marginRight: '1.8rem'
  },
  button: {
    marginTop: 8,
    marginBottom: 8
  },
  icon: {
    color: theme.palette.primary.main
  }
}))

const AdicionarEmpreendimento = ({ change, empreendimentoAdicionado }) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [textoInput, setTextoInput] = useState('')
  const [error, setError] = useState(false)

  const cancelar = () => {
    setDialogOpen(false)
  }

  const handleCadastroInput = (e) => {
    if (error) setError(false)
    setTextoInput(e.target.value)
  }

  const cadastrar = async () => {
    if (textoInput.trim().length < 1) {
      setError(true)
    } else {
      const { data } = await axios.post('/api/empreendimentos', {
        nome: textoInput
      })
      empreendimentoAdicionado(data)
      setDialogOpen(false)
    }
  }

  const classes = useStyles()
  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.titleIcon}>
          <Typography variant='h6' className={classes.title}>Empreendimentos</Typography>
        </Box>
        <Box>
          <TextField
            className={classes.textfield}
            variant='outlined'
            onChange={(e) => change(e)}
            placeholder='Empreendimento ou usuário'
            fullWidth
            InputProps={{
              classes: { input: classes.textfield },
              endAdornment: (
                <InputAdornment position='end'>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
          />
          <Button
            variant='contained'
            className={classes.button}
            fullWidth
            color='primary'
            onClick={() => setDialogOpen((prevState) => !prevState)}
          >
            Cadastrar empreendimento
          </Button>
        </Box>
      </Box>
      <ModalCadastroEmpreendimento
        click={cadastrar}
        handleDialog={dialogOpen}
        handleInput={handleCadastroInput}
        cancelar={cancelar}
        error={error}
      />
    </>
  )
}

AdicionarEmpreendimento.propTypes = {
  change: PropTypes.func,
  empreendimentoAdicionado: PropTypes.func
}

export default AdicionarEmpreendimento
