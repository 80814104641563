import React from 'react'
import { withStyles } from '@material-ui/core'
import styles from '../../styles'
import TableCell from '@material-ui/core/TableCell'
import Chip from '@material-ui/core/Chip'
import { seStringVazia } from '../../../../../../js/util'
import Button from '@material-ui/core/Button'
import IconeErro from '../../../../../comum/IconeErro'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

const CodigoServicoCurvaABC = ({
  exibir,
  itemCurvaAbc,
  match,
  history,
  classes,
}) => {
  const redirecionarAnalise = () =>
    history.push(match.url.concat(`/itens/${itemCurvaAbc.numero}`))

  return (
    exibir && (
      <TableCell className={classes.celulaCodigo}>
        {seStringVazia(itemCurvaAbc.codigoServico) ? (
          <Button color="default" size="small" onClick={redirecionarAnalise} title='Visualizar composição'>
            Não Informado
          </Button>
        ) : (
          <Button color="primary" variant="text" size="small" onClick={redirecionarAnalise} title='Visualizar composição'>
            {itemCurvaAbc.codigoServico}
            {seStringVazia(itemCurvaAbc.sistemaReferencia) ? (
              <IconeErro
                exibir={true}
                mensagem="Não encontrado"
                onClick={redirecionarAnalise}
              />
            ) : (
              <Chip
                style={{ marginLeft: 4 }}
                label={itemCurvaAbc.sistemaReferencia}
                size="small"
                variant="outlined"
              />
            )}
          </Button>
        )}
      </TableCell>
    )
  )
}

CodigoServicoCurvaABC.propTypes = {
  exibir: PropTypes.bool.isRequired,
  itemCurvaAbc: PropTypes.object.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  classes: PropTypes.object,
}

export default withRouter(withStyles(styles)(CodigoServicoCurvaABC))
