import React, { Component } from 'react'
import Fade from '@material-ui/core/Fade'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { Button, Checkbox, Menu, Typography, withStyles, FormControl } from '@material-ui/core'
import styles from '../styles'
import PropTypes from 'prop-types'
import MesmaLinha from '../../../../comum/MesmaLinha'
import Tooltip from '@material-ui/core/Tooltip'
import { existeObjetoNaLista } from '../../../../../js/util'

class SelecionarStatus extends Component {
  static propTypes = {
    classes: PropTypes.object,
    statusExibir: PropTypes.array,
    filtrarStatus: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose() {
    this.setState({ anchorEl: null })
  }

  render () {
    const { anchorEl } = this.state
    const { classes, statusExibir, filtrarStatus } = this.props
    const status = [{ nome: 'Concluída' }, { nome: 'Não analisado' }]
    return (
      <div className={classes.menuCurvaABC}>
        <FormControl variant="outlined">
          <Tooltip title='Selecionar Status da análise para filtrar' className='curpointer' >
            <Button aria-controls='fade-menu' aria-haspopup='true' onClick={this.handleClick} variant='outlined'>
              <Typography className={classes.txtBtn}>Status <ArrowDropDownIcon/></Typography>
            </Button>
          </Tooltip>
          <Menu
            id='fade-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            TransitionComponent={Fade}>
            <div className={classes.menuFiltros}>
              {
                status.map((status) => (
                  <MesmaLinha key={status.nome}>
                    <Checkbox color='primary' checked={existeObjetoNaLista(statusExibir, status)} onClick={() => filtrarStatus(status)} />
                    <Typography>{status.nome}</Typography>
                  </MesmaLinha>
                ))
              }
            </div>
          </Menu>
        </FormControl>
      </div>
    )
  }
}

export default withStyles(styles)(SelecionarStatus)
