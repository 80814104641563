import React from 'react'
import { Divider, Typography, withStyles } from '@material-ui/core'
import styles from '../styles'
import PropTypes from 'prop-types'

const InformacoesGerais = ({ classes }) => {
  return (
    <div className={classes.hackgrupoInfoGeral}>
       <div className={classes.grupoInfoGeral2}>
        <Typography variant='h6' gutterBottom>Informações gerais</Typography>
        <Typography variant='body2'>Complete os dados do orçamento</Typography>
        <Divider />
      </div>
    </div>
  )
}

InformacoesGerais.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(InformacoesGerais)
