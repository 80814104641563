import React, { Component, Fragment } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { context_httpget } from '../../../../../js/httpRequest'
import _ from 'lodash'
import { converterParaNumero, formatarNumeroParaMoedaBR, paginar } from '../../../../../js/util'
import ItemMaoDeObraRow from './ItemMaoDeObraRow'
import Paginacao from '../../../../comum/Paginacao'
import Grid from '@material-ui/core/Grid'
import StyledDrawer from '../../../../comum/StyledDrawer'
import BuscarComPaginacao from '../../../../comum/BuscarComPaginacao'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import FormularioAdicionarMaoDeObraAvulsa from '../../relatorios/precos/composicao/insumos/maos-de-obra/FormularioAdicionarMaoDeObraAvulsa'
import AdicionarMaoDeObraAvulsa from '../../relatorios/precos/composicao/insumos/maos-de-obra/AdicionarMaoDeObraAvulsa'

const colunaCodigo = 'maoDeObraOrcamento.codigo'
const colunaDescricao = 'maoDeObraOrcamento.descricao'

export class MaosDeObra extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    contexto: PropTypes.object.isRequired,
    onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.func
  }

  state = {
    maosDeObra: [],
    maosDeObraCopia: [],
    termoBusca: '',
    colunaOrdenacao: '',
    direcaoOrdenacao: 'desc',
    totalRegistrosPorPagina: 20,
    apartirDoRegistro: 0,
    pagina: 0,
    campoFiltro: 'all',
    exibirDrawerAdicionarMaoDeObra: false,
  }

  componentDidMount () {
    if (!_.isNil(this.props.contexto)) {
      this.recuperarMaosDeObra()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.termoBusca !== this.state.termoBusca) {
      this.buscar()
    }
  }

  async recuperarMaosDeObra() {
    const { contexto } = this.props
    const data = await context_httpget('precos/insumos/maos-de-obra', contexto)
    if (data) {
      this.setState({
        maosDeObra: data,
        maosDeObraCopia: data
      })
    }
  }

  ordernarMaosDeObra = () => {
    const { maosDeObra, colunaOrdenacao, direcaoOrdenacao } = this.state

    const maosDeObraOrdenadas = _.orderBy(maosDeObra, [colunaOrdenacao], [direcaoOrdenacao])

    this.setState({
      maosDeObra: maosDeObraOrdenadas
    })
  }

  handleChangeOrdenacaoColuna = (event, coluna) => {
    const { colunaOrdenacao, direcaoOrdenacao } = this.state

    let direcao
    if (colunaOrdenacao === coluna) {
      direcao = direcaoOrdenacao === 'desc' ? 'asc' : 'desc'
    } else {
      direcao = 'asc'
    }

    this.setState({
      colunaOrdenacao: coluna,
      direcaoOrdenacao: direcao
    }, () => this.ordernarMaosDeObra())
  }

  handleChangeFilter = (event) => {
    const { value } = event.target
    this.setState({ campoFiltro: value })
  }

  handleChange = (event) => {
    const { value } = event.target
    this.setState({ termoBusca: value })
  }

  handleKeyPress = (event) => {
    const { key } = event

    if (key === 'Enter') {
      this.buscar()
    }
  }

  handleChangeDrawerAdicionarMaoDeObra = () => this.setState({ exibirDrawerAdicionarMaoDeObra: !this.state.exibirDrawerAdicionarMaoDeObra })

  buscar = () => {
    const { termoBusca, maosDeObraCopia, campoFiltro } = this.state

    if (_.isEmpty(termoBusca)) {
      this.setState({ maosDeObra: maosDeObraCopia })
    } else {
      const maosDeObraFiltrados = maosDeObraCopia.filter((maoDeObra) => {
        return 'all' === campoFiltro ? maoDeObra.maoDeObraOrcamento.sistemaReferencia.toUpperCase().includes(termoBusca.toUpperCase())
          || `${maoDeObra.maoDeObraOrcamento.mes}/${maoDeObra.maoDeObraOrcamento.ano}`.includes(termoBusca.toUpperCase())
          || maoDeObra.maoDeObraOrcamento.codigo.toUpperCase().includes(termoBusca.toUpperCase())
          || maoDeObra.maoDeObraOrcamento.descricao.toUpperCase().includes(termoBusca.toUpperCase())
          || maoDeObra.maoDeObraOrcamento.unidade.toUpperCase().includes(termoBusca.toUpperCase())
          || formatarNumeroParaMoedaBR(maoDeObra.maoDeObraOrcamento.salario, 2, 5).includes(termoBusca)
          || formatarNumeroParaMoedaBR(maoDeObra.maoDeObraOrcamento.encargosTotais, 2, 5).includes(termoBusca)
          || formatarNumeroParaMoedaBR(maoDeObra.maoDeObraOrcamento.periculosidadeInsalubridade, 2, 5).includes(termoBusca)
          || formatarNumeroParaMoedaBR(converterParaNumero(maoDeObra.maoDeObraOrcamento.salario) * (1 + (converterParaNumero(maoDeObra.maoDeObraOrcamento.encargosTotais) / 100) + (converterParaNumero(maoDeObra.maoDeObraOrcamento.periculosidadeInsalubridade) / 100)), 2, 4).includes(termoBusca):
          'mes/ano' === campoFiltro ? `${maoDeObra.maoDeObraOrcamento.mes}/${maoDeObra.maoDeObraOrcamento.ano}`.includes(termoBusca.toUpperCase()):
          'qtdComposicoes' === campoFiltro ?  maoDeObra[campoFiltro].toString().toUpperCase().includes(termoBusca.toUpperCase()):
            maoDeObra.maoDeObraOrcamento[campoFiltro].toString().toUpperCase().includes(termoBusca.toUpperCase())
      })

      this.setState({
        maosDeObra: maosDeObraFiltrados,
        pagina: 0
      })
    }
  }

  render () {
    const { classes, onAtualizarItemCurvaABCComposicaoDuplicada } = this.props
    const { maosDeObra, colunaOrdenacao, direcaoOrdenacao, pagina, totalRegistrosPorPagina, campoFiltro, exibirDrawerAdicionarMaoDeObra } = this.state

    return(
      <Fragment>
        <Grid container spacing={1}>
    <Grid item xs={12} md={3}>
      <BuscarComPaginacao
        placeholder='Buscar mão de obra'
        onChange={(e) => this.handleChange(e)}
        onKeyPress={(e) => this.handleKeyPress(e)}
        onClick={() => this.buscar()}
        disable={false}
        filterOptions={[
          { label:'Sistema de Referência' ,value: 'sistemaReferencia' },
          { label:'Data referência' ,value: 'mes/ano' },
          { label:'Código' ,value: 'codigo' },
          { label:'Descrição' ,value: 'descricao' },
          { label:'Unidade' ,value: 'unidade' },
          { label:'Qtd composições' ,value: 'qtdComposicoes' }]}
        filterField={campoFiltro}
        onChangeFilter={(e) => this.handleChangeFilter(e)}
      />
    </Grid>
    <Grid item xs={12} md={9} container justify="flex-end">
      <Box mr={2} mt={3}>
        <Button
          variant="outlined"
          color="default"
          onClick={() => this.handleChangeDrawerAdicionarMaoDeObra()}>
        
          Adicionar Mão de Obra 
        </Button>
      </Box>
    </Grid>
  </Grid>

        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Referência</TableCell>
              <TableCell align='left'>Data Referência</TableCell>
              <TableCell>
                <TableSortLabel
                  active={ colunaOrdenacao ===  colunaCodigo}
                  direction={ direcaoOrdenacao }
                  onClick={(e) => this.handleChangeOrdenacaoColuna(e, colunaCodigo) }
                >
                  Código
                </TableSortLabel>
              </TableCell>
              <TableCell align='left'>
                <TableSortLabel
                  active={ colunaOrdenacao ===  colunaDescricao}
                  direction={ direcaoOrdenacao }
                  onClick={(e) => this.handleChangeOrdenacaoColuna(e, colunaDescricao) }
                >
                  Descrição
                </TableSortLabel>
              </TableCell>
              <TableCell align='left'>Unidade</TableCell>
              <TableCell align='left'>Salário (R$)</TableCell>
              <TableCell align='left'>Encargos Totais</TableCell>
              <TableCell align='left'>Periculosidade/Insalubridade</TableCell>
              <TableCell align='left'>Custo</TableCell>
              <TableCell> Aparece em </TableCell>
              <TableCell align='center'>Editar</TableCell>
              { 1+1 === 3 && (<TableCell align='right'>Ver Composição</TableCell>) }
            </TableRow>
          </TableHead>
          <TableBody>
            {paginar(maosDeObra, pagina, totalRegistrosPorPagina).map((maoDeObra, index) => (
              <ItemMaoDeObraRow
                key={maoDeObra.maoDeObraOrcamento.codigo + index}
                maoDeObra={maoDeObra}
                onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
              />
            ))}
            {_.isEmpty(maosDeObra) && <td colSpan="100%"><Alert style={{marginTop: 4, marginBottom: 4}} severity='warning'>Nenhum item foi encontrado.</Alert></td>}
          </TableBody>
        </Table>
        <Paginacao
          total={maosDeObra.length}
          registrosPorPagina={totalRegistrosPorPagina}
          pagina={pagina}
          onChangePage={(e, pag) => { this.setState({ pagina: pag }) }}
          onChangeRowsPerPage={(e) => (this.setState({ totalRegistrosPorPagina: e.target.value })) }
        />

        {
          exibirDrawerAdicionarMaoDeObra && (
           
            <StyledDrawer
            PaperProps={{ className: classes.drawerEdicao }}
            open={true}
            anchor={'right'}>
            <AdicionarMaoDeObraAvulsa
              
              onDadosAlterados={this.handleDadosAlterados}
              onCancelar={() => this.setState({ exibirDrawerAdicionarMaoDeObra: false })}
            />
          </StyledDrawer>
            
          )
        }

      </Fragment>
    )
  }
}

export default withRouter(MaosDeObra)
