const styles = (theme) => ({
  paddingReport: {
    minWidth: 300,
    maxWidth: '400%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3)
  },
  cabecalho: {
    display: 'inline-flex',
    marginTop: theme.spacing(3)
  },
  subCabecalho: {
    textAlign: 'center',
    verticalAlign: 'middle'
  },
  piper: {
    marginLeft: 8,
    marginRight: 8
  },
  mrgTop: {
    marginTop: 3
  },
  mrgRight: {
    marginRight: theme.spacing(7)
  },
  divider: {
    margin: theme.spacing(1, 0)
  },
  table: {
    maxWidth: '100%',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    }
  },
  tableCell: {
    borderBottom: 'none',
    paddingBottom: theme.spacing(1)
  },
  tableCellTitleUtilizacao: {
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(10),
    borderBottom: '1px solid #c4c4c4',
  },
  tableCellTitleCusto: {
    borderBottom: '1px solid #c4c4c4',
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(5)
  },
  tableCellSubHeader: {
    borderBottom: 'none',
    paddingBottom: 1,
    fontSize: '10pt'
  },
  resumo: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(1),
    fontSize: '10pt'
  },
  marginTitle: {
    marginRight: theme.spacing(8)
  },
  composicaoHeader: {
    marginTop: 3,
    marginBottom: 10,
    borderStyle: 'solid',
    borderColor: '#C4C4C4',
    borderWidth: 1,
    borderLeftWidth: 3,
    backgroundColor: '#f5f5f5',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  lastColumn: {
    width: 70,
    textAlign: 'right'
  },
  tableCellTitleDMT: {
    borderBottom: '1px solid #c4c4c4',
    textAlign: 'center'
  },
  drawerEdicao: {
    width: '70%',
    minWidth: 400,
    padding: 12,
    borderLeft: 'solid 4px rgba(67,133,191,1)',
    boxShadow: 'none'
  },
  drawerReferencia: {
    width: '40%',
    minWidth: 300,
    padding: 12,
    borderLeft: 'solid 4px rgba(67,133,191,1)',
    boxShadow: 'none'
  },
  gridReferencia: {
    justifyContent: 'space-between'
  },
  linhaItem: {
    backgroundColor: 'white',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(67, 133, 191, 0.3)'
    }
  },
  linhaItemSelecionada: {
    backgroundColor: '#4a4a4a',
    color: '#fff !important',
    fontWeight: 'bold',
    cursor: 'pointer'
  },
  btnEditarComp: {
    backgroundColor: 'rgba(67, 133, 191,1)',
    color: '#fff',
    padding: '8px 16px',
    boxShadow: '2px 2px 3px rgba(0,0,0,0.3)',
    marginBottom: 20,
    '&:hover': {
      backgroundColor: 'rgb(34, 104, 167)'
    }
  },
  lblDadosBasicos: {
    marginBottom: 2
  },
  btnCloseDadosBasicos: {
    margin: '-3px 0 0 auto',
    '&:hover': {
      background: 'transparent'
    }
  },
  boxSelectDt: {
    marginBottom: 0,
    width: '12.7rem'
  },
  inputSelectDt: {
    padding: '8px 32px 8px 10px'
  },
  formControl: {
    width: '100%'
  }
})
export default styles
