import React, { useState } from 'react'
import Table from '@material-ui/core/Table'
import CabecalhoTabelaReferenciaPreco from './CabecalhoTabelaReferenciaPreco'
import CorpoTabelaReferenciaPreco from './CorpoTabelaReferenciaPreco'
import PropTypes from 'prop-types'
import Paginacao from '../../../../comum/Paginacao'

const TabelaReferenciaPreco = ({ insumos, onSelect }) => {
  const [pagina, setPagina] = useState(0)
  const [registrosPorPagina, setRegistrosPorPagina] = useState(30)
  return (
    <div>
      <Table>
        <CabecalhoTabelaReferenciaPreco />
        <CorpoTabelaReferenciaPreco
          insumos={insumos}
          onSelect={onSelect}
          pagina={pagina}
          registrosPorPagina={registrosPorPagina}
        />
      </Table>
      <Paginacao
        total={insumos.length}
        registrosPorPagina={registrosPorPagina}
        pagina={pagina}
        onChangePage={(e, pag) => { setPagina(pag) }}
        onChangeRowsPerPage={(e) => setRegistrosPorPagina(e.target.value)}
      />
    </div>
  )
}

TabelaReferenciaPreco.propTypes = {
  insumos: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default TabelaReferenciaPreco
