import React, { Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { ListItemIcon } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import fileDownload from 'js-file-download'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../../../js/operationProgressUtil'
import PropTypes from 'prop-types'

class MenuOrcamentoBruto extends Component {
  static propTypes = {
    match: PropTypes.object
  }

  exportarPlanilha = async (params) => {
    const { data } = await axios.get(`/api/empreendimentos/${params.idEmpreendimento
    }/obras/${params.idObra
    }/orcamentos/${params.idOrcamento}/arquivocsv`,
    SLOW_REQUEST_CONFIG)
    fileDownload(new Blob(['\ufeff', data]), 'orcamento.csv', 'text/csv')
  }

  render () {
    return <div className='divTooltip'>

      <Tooltip title='Exportar planilha' className='minw35 curpointer' >
        <ListItemIcon>
          <SaveAltIcon onClick={() => this.exportarPlanilha(this.props.match.params)}/>
        </ListItemIcon>
      </Tooltip>
    </div>
  }
}

export default withRouter(MenuOrcamentoBruto)
