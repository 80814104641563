import React, { useState, useEffect  } from 'react'
import { withStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { formatarNumeroParaMoedaBR, formatarUF, recuperarMes } from '../../../../../../js/util'
import Button from '@material-ui/core/Button'
import MesmaLinha from '../../../../../comum/MesmaLinha'
import StyledDrawer from '../../../../../comum/StyledDrawer'
import styles from '../styles'
import FormularioDadosBasicosComposicao from './FormularioDadosBasicosComposicao'
import RelatorioComposicaoPdf from './pdf/RelatorioComposicaoPdf'
import { useComposition } from '../../../../../../hooks/useComposition'
import { context_httpget } from '../../../../../../js/httpRequest'
import { connect } from 'react-redux'
import { mapStateToPropsContexto } from '../../../../../../js/util'

const DadosBasicosComposicao = ({
  classes,
  value,
  itemCurvaAbc,
  detalhes,
  permitirEdicao,
  contexto,
  valorBDI
}) => {
  const { setDefaultState } = useComposition()

  const [exibirDrawerEdicao, setExibirDrawerEdicao] = useState(false)
  const [imprime, setImprime] = useState(false)


  const handleEditarClick = (duplicarComposicao) => {
    setDefaultState(prevState => ({
      ...prevState,
      codigo: duplicarComposicao ? `${value.codigo}DUP` : value.codigo,
      descricao: value.descricao,
      descricaoEmpty: false,
      unidade: value.unidade,
      unidadeEmpty: false,
      uf: value.uf,
      producao: formatarNumeroParaMoedaBR(detalhes.insumos.producaoEquipe),
      producaoEmpty: false,
      fic: formatarNumeroParaMoedaBR(value.fic, 2, 6),
      ficEmpty: false,
      fit: formatarNumeroParaMoedaBR(value.fit, 2, 6),
      fitEmpty: false,
      mes: recuperarMes(value.mes),
      mesEmpty: false,
      ano: value.ano,
      sistemaReferencia: value.sistemaReferencia,
      anoEmpty: false,
      observacao: value.observacao ? value.observacao : '',
      duplicarComposicao: duplicarComposicao,
      exibirDrawerEdicao: true
    }))

    setExibirDrawerEdicao(true)
  }

  useEffect(() => {
    carregarDetalhes();
  }, []);


  const carregarDetalhes = async () => {
    
    //const { contexto, composicao, onItemModificado } = this.props
    const data = await context_httpget(
      `precos/composicoes/detalhes?composicao=${value.codigo}`,
      contexto,
      null,
      true
    )

  } 

  return (
    <>
      <MesmaLinha>
        <Typography variant='h6'>
          RELATÓRIO DA COMPOSIÇÃO
        </Typography>{' '}
        <span className={classes.piper}>|</span>
        {permitirEdicao && (
          <Button
            color='primary'
            variant='text'
            size='small'
            onClick={() => handleEditarClick(false)}
          >
            Editar
          </Button>
        )}
        <span className={classes.piper}>|</span>
        {permitirEdicao && (
          <Button
            color='primary'
            variant='text'
            size='small'
            onClick={() => handleEditarClick(true)}
          >
            Duplicar
          </Button>
        )}
        <span className={classes.piper}>|</span>
        {
          <Button
            color='primary'
            variant='text'
            size='small'
            onClick={() => setImprime(true)}
          >
            Imprimir
          </Button>
        }
      </MesmaLinha>
      <div className={classes.cabecalho}>
        <Typography className={classes.mrgRight}>
          Fonte: <b>{value.sistemaReferencia}</b>
        </Typography>
        <Typography className={classes.mrgRight}>
          Mês:{' '}
          <b>
            {value.mes} / {value.ano}
          </b>
        </Typography>
        <Typography className={classes.mrgRight}>
          Local: <b>{value.uf && formatarUF(value.uf)}</b>
        </Typography>
        <Typography className={classes.mrgRight}>
          Produção da equipe:{' '}
          <b>
            {formatarNumeroParaMoedaBR(value.producao, 2, 4)} {value.unidade}
          </b>
        </Typography>
        <Typography className={classes.mrgRight}>
          FIC: <b>{formatarNumeroParaMoedaBR(value.fic, 2, 6)}</b>
        </Typography>
        <Typography className={classes.mrgRight}>
          FIT: <b>{formatarNumeroParaMoedaBR(value.fit, 2, 6) + ' %'}</b>
        </Typography>
      </div>
      <Typography>
        Observações: {value.observacao ? value.observacao : ' - '}
      </Typography>

      <Typography>
       
        Custo unitário direto total: 
        { 
          (value.custoComposicao !== null && value.custoComposicao !== 0)
          ? ` R$ ${formatarNumeroParaMoedaBR(  value.custoComposicao)}`
          : ` R$ ${formatarNumeroParaMoedaBR(  detalhes.custoUnitarioDiretoTotal)}`
        }
      </Typography>



      {exibirDrawerEdicao && (
        <StyledDrawer
          disableEnforceFocus
          PaperProps={{ className: classes.drawerEdicao }}
          open
          anchor={'right'}
        >
          <FormularioDadosBasicosComposicao
            codigoEditavel={false}
            criarComposicao={false}
            idItemCurvaAbc={itemCurvaAbc?.id}
            closeDrawerEdicao={() => setExibirDrawerEdicao(false)}
          />
        </StyledDrawer>
      )}
      {imprime && (
        <RelatorioComposicaoPdf
          items={new Array(0).concat([{ value, detalhes, itemCurvaAbc }])}
          onPrint={() => setImprime(false)}
        />
      )}
    </>
  )
}

DadosBasicosComposicao.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.object.isRequired,
  itemCurvaAbc: PropTypes.object,
  detalhes: PropTypes.object.isRequired,
  permitirEdicao: PropTypes.bool,
  contexto: PropTypes.object.isRequired,
}

export default connect(mapStateToPropsContexto)(
  withStyles(styles)(DadosBasicosComposicao)
)
