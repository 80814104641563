const styles = (theme) => ({
  header: {
    borderBottom: 'solid 1px #bbb'
  },
  paper: {
    padding: 12
  },
  paperTable: {
    padding: 12,
    marginTop: 24
  },
  boxLimiter: {
    width: 400
  },
  chipsUnidades: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 500,
    maxWidth: 900
  },
  links: {
    padding: theme.spacing(1),
  },
  relatorioDadosOrcamentos: {
    display: 'flex',
    alignItems: 'center',
  },
  relatorioDadosOrcamentosDetalhes: {
    color: '#8a8a8a',
    fontSize: 10,
    marginLeft: 8
  },
})

export default styles
