export const CODIGO_INSUMO = 'Código do Insumo'
export const CODIGO_SERVICO = 'Código do Serviço'
export const DESCRICAO_SERVICO = 'Descrição'
export const UNIDADE = 'Unidade'
export const QUANTIDADE = 'Quantidade'
export const PRECO_UNITARIO = 'Preço Unitário'
export const VALOR_PARCIAL = 'Valor Parcial'
export const INCIDENCIA = 'Incidência'
export const ERRO_DETECTADO = 'Erro Detectado'
export const MENOR_PRECO_ORCADO = 'Menor Preço Unitário'
export const DIFERENCA = 'Diferença'
export const QUANTIDADE_ITENS = 'Itens'

export const PRECO_UNITARIO_COM_BDI = 'Preço Unitário (com BDI)'
export const PRECO_PARCIAL = 'Preço Parcial'
export const PRECO_REFERENCIA_COM_BDI = 'Preço Referência (com BDI)'
export const PRECO_PARCIAL_REFERENCIA = 'Preço Parcial Referência'
export const SUBPRECO = 'Subpreço'

export const MES = 'Mês'
export const ANO = 'Ano'
export const SISTEMA_REFERENCIA = 'Sistema Referência'

export const MAO_DE_OBRA = 'Mão de Obra'

export const recuperarColunasInconsistencia = (url) => {
  if (url.includes('formulas')) {
    return [CODIGO_SERVICO, DESCRICAO_SERVICO, UNIDADE, QUANTIDADE, PRECO_UNITARIO, VALOR_PARCIAL]
  }
  if (url.includes('verba-explicita')) {
    return [CODIGO_SERVICO, DESCRICAO_SERVICO, UNIDADE, QUANTIDADE, PRECO_UNITARIO, VALOR_PARCIAL, INCIDENCIA]
  }
  if (url.includes('quantidade-um')) {
    return [CODIGO_SERVICO, DESCRICAO_SERVICO, UNIDADE, QUANTIDADE, PRECO_UNITARIO, VALOR_PARCIAL, INCIDENCIA]
  }
  if (url.includes('erro-calculo-total')) {
    return [CODIGO_SERVICO, DESCRICAO_SERVICO, UNIDADE, QUANTIDADE, PRECO_UNITARIO, VALOR_PARCIAL, ERRO_DETECTADO]
  }
  if (url.includes('diferenca-precos-servicos-identicos')) {
    return [QUANTIDADE_ITENS, DESCRICAO_SERVICO, UNIDADE, QUANTIDADE, PRECO_UNITARIO, MENOR_PRECO_ORCADO, DIFERENCA]
  }
  if (url.includes('quantidade-zero')) {
    return [CODIGO_SERVICO, DESCRICAO_SERVICO, UNIDADE, PRECO_UNITARIO, VALOR_PARCIAL]
  }
  if (url.includes('preco-unitario-zero')) {
    return [CODIGO_SERVICO, DESCRICAO_SERVICO, UNIDADE, QUANTIDADE, VALOR_PARCIAL]
  }
  if (url.includes('unidades-divergentes-servico')) {
    return [QUANTIDADE_ITENS, CODIGO_SERVICO, DESCRICAO_SERVICO, UNIDADE, QUANTIDADE, VALOR_PARCIAL]
  }
  if (url.includes('sobrepreco-negativo')) {
    return [CODIGO_SERVICO, DESCRICAO_SERVICO, UNIDADE, QUANTIDADE, PRECO_UNITARIO_COM_BDI, PRECO_PARCIAL, PRECO_REFERENCIA_COM_BDI, PRECO_PARCIAL_REFERENCIA, SUBPRECO]
  }
  if (url.includes('composicao-data-base-diferente')) {
    return [CODIGO_SERVICO, DESCRICAO_SERVICO, UNIDADE, QUANTIDADE, MES, ANO, SISTEMA_REFERENCIA]
  }
  if (url.includes('insumo-data-base-diferente')) {
    return [CODIGO_SERVICO, CODIGO_INSUMO, DESCRICAO_SERVICO, UNIDADE, QUANTIDADE, MES, ANO, SISTEMA_REFERENCIA]
  }
  if (url.includes('maos-de-obra-unidades-diferentes')) {
    return [CODIGO_SERVICO, DESCRICAO_SERVICO, MAO_DE_OBRA, MES, ANO]
  }
  if (url.includes('maos-de-obra-encargos-sociais-diferentes')) {
    return [CODIGO_SERVICO, DESCRICAO_SERVICO, MAO_DE_OBRA, MES, ANO]
  }
  if (url.includes('servicos_insumos_custo_igual_zero')) {
    return [CODIGO_SERVICO, DESCRICAO_SERVICO, QUANTIDADE, PRECO_PARCIAL, PRECO_PARCIAL_REFERENCIA, MES, ANO]
  }
  if (url.includes('expressoes')) {
    return [CODIGO_SERVICO, DESCRICAO_SERVICO, UNIDADE, QUANTIDADE, PRECO_UNITARIO, VALOR_PARCIAL]
  }
}
