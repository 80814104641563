import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import {
  ANOS_COMPOSICAO,
  converterMoedaBRParaNumero,
  handleCampoNumericoChange,
  mapStateToPropsContexto,
  MESES,
  MESES_ANO_ATUAL,
  seStringVazia,
  showErrorMsg,
  showSuccessMsg,
  UFS
} from '../../../../../../js/util'
import MesmaLinha from '../../../../../comum/MesmaLinha'
import { Button, Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'
import StyledFormControl from '../../../../../comum/StyledFormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import styles from '../styles'
import { withStyles } from '@material-ui/styles'
import TituloVoltar from '../../../../../comum/TituloVoltar'
import FormControl from '@material-ui/core/FormControl'
import { CompositionDefaultValues, useComposition } from '../../../../../../hooks/useComposition'
import { context_httppost, context_httpput } from '../../../../../../js/httpRequest'
import { connect } from 'react-redux'
import InfoIcon from '@material-ui/icons/Info' 

const FormularioDadosBasicosComposicao = ({
  contexto,
  codigoEditavel,
  criarComposicao,
  idItemCurvaAbc,
  closeDrawerEdicao,
  classes
}) => {
  const { compositionState, changeField, setDefaultState, onDadosAlterados, onAtualizarItemCurvaABCComposicaoDuplicada } = useComposition()

  const handleCampoTextoChange = (event, campo) => {
    const state = {}
    state[campo] = event.target.value
    state[`${campo}Empty`] = seStringVazia(event.target.value)
    changeField(campo, state[campo])
    changeField(`${campo}Empty`, seStringVazia(event.target.value))
  }

  const isEmptyComboBox = (event) => {
    const { name, value } = event.target
    return name === 'mes'
      ? seStringVazia(value.nome)
      : seStringVazia(value.toString())
  }

  const handleComboBoxChange = (event) => {
    const { name, value } = event.target
    const state = {}
    state[name] = value
    state[`${name}Empty`] = isEmptyComboBox(event)
    changeField(name, state[name])
    changeField(`${name}Empty`, false)
  }

  const handleSalvarClick = async () => {
    const {
      codigo,
      ano,
      descricao,
      unidade,
      producao,
      fic,
      fit,
      mes,
      uf,
      sistemaReferencia,
      observacao,
      duplicarComposicao,
      precoUnitarioOrcamento,
      custoComposicao
    } = compositionState

    const descricaoVazio = seStringVazia(descricao)
    const unidadeVazio = seStringVazia(unidade)
    const producaoVazio = seStringVazia(producao)
    const ficVazio = seStringVazia(fic)
    const fitVazio = seStringVazia(fit)
    const mesVazio = seStringVazia(mes.nome)
    const anoVazio = seStringVazia(ano.toString())
    const custoComposicaoVazio = seStringVazia(custoComposicao)

    if (codigo.length === 0 || codigo === '-') {
      showErrorMsg('Código não preenchido.')
      return
    }

    if ([descricaoVazio, unidadeVazio, producaoVazio, ficVazio, fitVazio, mesVazio, anoVazio  ].some(Boolean)) {
      changeField('descricaoEmpty', descricaoVazio)
      changeField('unidadeEmpty', unidadeVazio)
      changeField('producaoEmpty', producaoVazio)
      changeField('ficEmpty', ficVazio)
      changeField('fitEmpty', fitVazio)
      changeField('mesEmpty', mesVazio)
      changeField('anoEmpty', anoVazio)
    } else {
      let dadosSalvar = {
        codigo: codigo.trim(),
        idItemCurvaAbc,
        descricao,
        unidade,
        producao: converterMoedaBRParaNumero(producao),
        fic: converterMoedaBRParaNumero(fic),
        fit: converterMoedaBRParaNumero(fit),
        mes: mes.numero,
        ano: ano,
        uf,
        sistemaReferencia: sistemaReferencia,
        observacao,
        custoComposicao: converterMoedaBRParaNumero(custoComposicao),
      }

      dadosSalvar = criarComposicao ? { ...dadosSalvar, custoUnitario: precoUnitarioOrcamento } : dadosSalvar

      if (duplicarComposicao) {
        await context_httpput('precos/composicoes/duplicar', contexto, dadosSalvar)
        showSuccessMsg('Composição duplicada com sucesso.')
        onAtualizarItemCurvaABCComposicaoDuplicada()
      } else {
        criarComposicao
          ? await context_httppost('precos/composicoes/dados-basicos', contexto, dadosSalvar)
          : await context_httpput('precos/composicoes/dados-basicos', contexto, dadosSalvar)
        showSuccessMsg('Dados básicos da composição salvos com sucesso.')
        onDadosAlterados(dadosSalvar.codigo)
      }
      limparFormulario()
    }
  }

  const limparFormulario = () => {
    setDefaultState(({
      ...CompositionDefaultValues.compositionState
    }))
  }

  const {
    codigo,
    codigoEmpty,
    ano,
    descricao,
    descricaoEmpty,
    unidade,
    unidadeEmpty,
    producao,
    producaoEmpty,
    fic,
    ficEmpty,
    fit,
    fitEmpty,
    mes,
    mesEmpty,
    anoEmpty,
    uf,
    ufEmpty,
    sistemaReferencia,
    observacao,
    custoComposicao,
    custoComposicaoEmpty
  } = compositionState

  return (
    <div>
      <TituloVoltar onClick={closeDrawerEdicao} label='Dados Básicos' />
      <Grid container spacing={2}>
        <Grid item md={12}>
          <FormControl variant='outlined'>
            <Typography variant='subtitle1' className={classes.lblDadosBasicos}>
              Código do serviço
            </Typography>
            <TextField
              value={codigo}
              error={codigoEmpty}
              disabled={!codigoEditavel}
              onChange={(e) => handleCampoTextoChange(e, 'codigo')}
              variant='outlined'
              placeholder='Ex: 5914359'
            />
          </FormControl>
        </Grid>
        <Grid item md={11}>
          <Typography variant='subtitle1' className={classes.lblDadosBasicos}>
            Descrição
          </Typography>
          <TextField
            value={descricao}
            error={descricaoEmpty}
            multiline
            rows={3}
            onChange={(e) => handleCampoTextoChange(e, 'descricao')}
            variant='outlined'
            fullWidth
            placeholder='Descreva a composição'
          />
        </Grid>
        <Grid item md={3}>
          <FormControl variant='outlined'>
            <Typography variant='subtitle1' className={classes.lblDadosBasicos}>
              Unidade do serviço
            </Typography>
            <TextField
              value={unidade}
              error={unidadeEmpty}
              onChange={(e) => handleCampoTextoChange(e, 'unidade')}
              variant='outlined'
              placeholder='Ex: km'
            />
          </FormControl>
        </Grid>
        <Grid item md={9}>
          <FormControl variant='outlined'>
            <Typography variant='subtitle1' className={classes.lblDadosBasicos}>
              Produção da Equipe
            </Typography>
            <TextField
              value={producao}
              error={producaoEmpty}
              onChange={(e) =>
                handleCampoNumericoChange(e, (e) =>
                  handleCampoTextoChange(e, 'producao')
                )
              }
              variant='outlined'
              placeholder='Ex: 249'
            />
          </FormControl>
        </Grid>
        <Grid item md={3}>
          <FormControl variant='outlined'>
            <Typography variant='subtitle1' className={classes.lblDadosBasicos}>
              FIC
            </Typography>
            <TextField
              value={fic}
              error={ficEmpty}
              onChange={(e) =>
                handleCampoNumericoChange(e, (e) =>
                  handleCampoTextoChange(e, 'fic')
                )
              }
              variant='outlined'
              placeholder='Ex: 10,00'
            />
          </FormControl>
        </Grid>
        <Grid item md={9}>
          <FormControl variant='outlined'>
            <Typography variant='subtitle1' className={classes.lblDadosBasicos}>
              FIT (%)
            </Typography>
            <TextField
              value={fit}
              error={fitEmpty}
              onChange={(e) =>
                handleCampoNumericoChange(e, (e) =>
                  handleCampoTextoChange(e, 'fit')
                )
              }
              variant='outlined'
              placeholder='Ex: 10,00'
            />
          </FormControl>
        </Grid>

        
        <Grid item md={3}>
          <FormControl variant='outlined'>
            <Typography variant='subtitle1' className={classes.lblDadosBasicos}>
              Mês
            </Typography>
            <StyledFormControl
              variant='outlined'
              className={classes.boxSelectDt}
            >
              <Select
                name={'mes'}
                error={mesEmpty}
                value={mes}
                onChange={(e) => handleComboBoxChange(e)}
              >
                {ano === new Date().getFullYear()
                  ? MESES_ANO_ATUAL().map((mes) => (
                    <MenuItem key={mes.numero} value={mes}>
                      <Typography>{mes.nome}</Typography>
                    </MenuItem>
                  ))
                  : MESES.map((mes) => (
                    <MenuItem key={mes.numero} value={mes}>
                      <Typography>{mes.nome}</Typography>
                    </MenuItem>
                  ))}
              </Select>
            </StyledFormControl>
          </FormControl>
        </Grid>
        <Grid item md={9}>
          <FormControl variant='outlined'>
            <Typography variant='subtitle1' className={classes.lblDadosBasicos}>
              Ano
            </Typography>
            <StyledFormControl
              variant='outlined'
              className={classes.boxSelectDt}
            >
              <Select
                name={'ano'}
                error={anoEmpty}
                value={ano}
                onChange={(e) => handleComboBoxChange(e)}
              >
                {ANOS_COMPOSICAO().map((ano) => (
                  <MenuItem key={ano} value={ano}>
                    <Typography>{ano}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </FormControl>
        </Grid>

        <Grid item md={3}>
          <FormControl variant='outlined'>
            <Typography variant='subtitle1' className={classes.lblDadosBasicos}>
              Sistema Referência
            </Typography>
            <TextField
              value={sistemaReferencia}
              variant='outlined'
              disabled
            />
          </FormControl>
        </Grid>


        <Grid item md={9}>
  <FormControl variant='outlined'>
    <Box display="flex" alignItems="center">
      <Typography variant='subtitle1' className={classes.lblDadosBasicos}>
        Custo da Composição
      </Typography>
      <Tooltip
        title={
          <Typography>
            Este campo é utilizado para composições que possuem custo, porém sem insumos associados. (Exemplo: composições de transporte de CAP, utilizadas em orçamentos do DNIT)<br />
          </Typography>
        }
      >
        <InfoIcon fontSize="small" style={{ marginLeft: 4 }} />
      </Tooltip>
    </Box>
    <Typography variant='body2' className={classes.lblDadosBasicos}>
      {' (para composição sem insumos)'}
    </Typography>
    <TextField
      value={custoComposicao}
      onChange={(e) =>
        handleCampoNumericoChange(e, (e) =>
          handleCampoTextoChange(e, 'custoComposicao')
        )
      }
      variant='outlined'
      placeholder='Ex: 10,00'
    />
  </FormControl>
</Grid>



        <Grid item md={2}>
          <Typography className={classes.lblDadosBasicos}>UF:</Typography>
          <StyledFormControl variant='outlined' className={classes.formControl}>
            <Select
              name='uf'
              error={ufEmpty}
              value={uf}
              onChange={(e) => handleComboBoxChange(e)}>
              {
                UFS.map((u) => (
                  <MenuItem key={u.sigla} value={u.sigla}>
                    <Typography>{u.nome}</Typography>
                  </MenuItem>
                ))
              }
            </Select>
          </StyledFormControl>
        </Grid>

        

        <Grid item md={11}>
          <Typography variant='subtitle1' className={classes.lblDadosBasicos}>
            Observação
          </Typography>
          <TextField
            value={observacao}
            onChange={(e) => handleCampoTextoChange(e, 'observacao')}
            variant='outlined'
            fullWidth
            multiline
            rows={3}
            placeholder='Alguma observação?'
          />
        </Grid>
      </Grid>
      <Box mt={3}>
        <MesmaLinha>
          <Button variant='contained' color='primary' onClick={handleSalvarClick}>
            Salvar
          </Button>
          <Button variant='text' color='primary' onClick={closeDrawerEdicao}>
            Cancelar
          </Button>
        </MesmaLinha>
      </Box>
    </div>
  )
}

FormularioDadosBasicosComposicao.propTypes = {
  contexto: PropTypes.object,
  codigoEditavel: PropTypes.bool.isRequired,
  criarComposicao: PropTypes.bool.isRequired,
  idItemCurvaAbc: PropTypes.string,
  closeDrawerEdicao: PropTypes.func.isRequired,
  classes: PropTypes.object
}

export default connect(mapStateToPropsContexto)(withStyles(styles)(FormularioDadosBasicosComposicao))
