import React from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { handleCampoNumericoChange } from '../../../../../../../../js/util'
import PropTypes from 'prop-types'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

const FormularioMaterialComposicao = ({
  codigo, codigoEmpty, codigoEditavel,
  descricao, descricaoEmpty,
  unidade, unidadeEmpty,
  quantidade, quantidadeEmpty,
  precoUnitario, precoUnitarioEmpty,
  handleCampoTextoChange, onSalvar, onCancelar
}) => {

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSalvar();
    }
  };

  const handleEscKeyPress = (event) => {
    if (event.key === 'Escape') {
      onCancelar();
    }
  };

  React.useEffect(() => {
    document.body.addEventListener('keydown', handleEnterKeyPress);
    document.body.addEventListener('keydown', handleEscKeyPress);
    return () => {
      document.body.removeEventListener('keydown', handleEnterKeyPress);
      document.body.removeEventListener('keydown', handleEscKeyPress);
    };
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={3}>
        <Box mb={2} ml={2} >
          <FormControl variant='outlined'>
            <Typography variant='subtitle1'>Código</Typography >
            <TextField
              variant='outlined'
              value={codigo}
              disabled={!codigoEditavel}
              placeholder='Ex: E9500'
              error={codigoEmpty}
              onChange={(e) => handleCampoTextoChange(e, 'codigo')}
            />
          </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={10}>
        <Box mb={2} ml={2} >
          <FormControl variant='outlined' fullWidth>
            <Typography variant='subtitle1'>Descrição</Typography>
            <TextField
              variant='outlined'
              multiline
              rows={3}
              value={descricao}
              error={descricaoEmpty}
              onChange={(e) => handleCampoTextoChange(e, 'descricao')}
            />
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
        <Box mb={2} ml={2} >
          <FormControl variant='outlined' fullWidth>
            <Typography variant='subtitle1'>Unidade</Typography>
            <TextField
              variant='outlined'
              value={unidade}
              error={unidadeEmpty}
              onChange={(e) => handleCampoTextoChange(e, 'unidade')}
            />
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl variant='outlined' fullWidth>
            <Typography variant='subtitle1'>Quantidade</Typography>
            <TextField
              variant='outlined'
              value={quantidade}
              error={quantidadeEmpty}
              onChange={(e) => handleCampoNumericoChange(e, (e) => handleCampoTextoChange(e, 'quantidade'))}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl variant='outlined' fullWidth>
            <Typography variant='subtitle1'>Preço Unitário</Typography>
            <TextField
              variant='outlined'
              value={precoUnitario}
              error={precoUnitarioEmpty}
              onChange={(e) => handleCampoNumericoChange(e, (e) => handleCampoTextoChange(e, 'precoUnitario'))}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box mt={3} ml={2}>
        <MesmaLinha>
          <Button variant='contained' color='primary' onClick={onSalvar}>Salvar</Button>
          <Button variant='text' color='primary' onClick={onCancelar}>Cancelar</Button>
        </MesmaLinha>
      </Box>
    </>
  )
}

FormularioMaterialComposicao.propTypes = {
  codigo: PropTypes.string.isRequired,
  codigoEmpty: PropTypes.bool.isRequired,
  codigoEditavel: PropTypes.bool.isRequired,
  descricao: PropTypes.string.isRequired,
  descricaoEmpty: PropTypes.bool.isRequired,
  unidade: PropTypes.string.isRequired,
  unidadeEmpty: PropTypes.bool.isRequired,
  quantidade: PropTypes.string.isRequired,
  quantidadeEmpty: PropTypes.bool.isRequired,
  precoUnitario: PropTypes.string.isRequired,
  precoUnitarioEmpty: PropTypes.bool.isRequired,
  handleCampoTextoChange: PropTypes.func.isRequired,
  onSalvar: PropTypes.func.isRequired,
  onCancelar: PropTypes.func.isRequired
}

export default FormularioMaterialComposicao
