import axios from 'axios'
import decode from 'jwt-decode'
import _ from 'lodash'

import { removerExibicaoColunas } from '../components/orcamento/analisar/curva-abc/itens/colunas/colunasContext'
import { getAuthentication, removeSecurityToken, saveSecurityToken } from '../security/securityContext'
import store from '../store/configureStore'
import { getBrowserHistory, loginPorTokenLoginIntegradoUrl } from '../js/applicationContext'
import { showAlertMsg } from '../js/util'

import { AUTHENTICATION_FAILED, USER_AUTHENTICATED, USER_LOGOUT } from './types'
import { BYPASS_AUTH_INTERCEPTOR_HEADER, SLOW_REQUEST_HEADER } from '../interceptors/authenticationJwtInterceptors'

export const AUTHENTICATION_SIGA_REDIRECT =
  '@authenticationJwtActions/AUTHENTICATION_SIGA_REDIRECT'

export const axiosWrapper = axios

const nomeSimples = (nome) => {
  const palavras = _.words(nome)
  if (palavras.length === 1) {
    return _.capitalize(palavras[0])
  }
  return `${_.capitalize(palavras[0])} ${_.capitalize(
    palavras[palavras.length - 1]
  )}`
}

export const sessionExpired = () => {
  if (getAuthentication()) {
    removeSecurityToken()
    store.dispatch({
      type: AUTHENTICATION_FAILED,
      payload: {
        tokenJwt: null,
        isUserAuthenticated: false,
        username: null
      }
    })
    getBrowserHistory().push('/login')
    showAlertMsg('Sua sessão expirou realize o login novamente')
  }
}

export const recuperarUrlSiga = () => () =>
  axios.get('/api/auth/url', {
    headers: {
      [SLOW_REQUEST_HEADER]: true,
      [BYPASS_AUTH_INTERCEPTOR_HEADER]: true
    }
  })

export const authenticateSiga = (urlSiga) => (dispatch) => {
  window.location.href = urlSiga.replace(
    '{tagURL}',
    `${window.location.protocol}//${window.location.host}/sso`
  )
  return dispatch({
    type: AUTHENTICATION_SIGA_REDIRECT
  })
}

export const authenticateTokenLoginIntegrado =
  (username, tokenLoginIntegrado) => (dispatch) =>
    axios
      .post(
        loginPorTokenLoginIntegradoUrl,
        { tokenLoginIntegrado: tokenLoginIntegrado },
        {
          headers: {
            [SLOW_REQUEST_HEADER]: true,
            [BYPASS_AUTH_INTERCEPTOR_HEADER]: true
          }
        }
      )
      .then(
        (resposta) => {
          const usuarioAutenticado = resposta.data
          const tokenDecodificado = decode(usuarioAutenticado.tokenJwt)
          const { userFingerPrint } = resposta.data

          const authentication = {
            tokenJwt: usuarioAutenticado.tokenJwt,
            username: tokenDecodificado.sub,
            nomeUsuario: tokenDecodificado.nus,
            nomeSimples: nomeSimples(tokenDecodificado.nus),
            isUserAuthenticated: true,
            perfis: tokenDecodificado.roles,
            tipoUnidade: tokenDecodificado.tus
          }
          saveSecurityToken(authentication, userFingerPrint)
          setTimeout(
            sessionExpired,
            tokenDecodificado.exp - new Date().getTime()
          )
          return dispatch({
            type: USER_AUTHENTICATED,
            payload: authentication
          })
        },
        (err) => {
          console.log(err)
          dispatch({
            type: AUTHENTICATION_FAILED,
            payload: { mensagem: 'Usuário não tem acesso ' }
          })
        }
      )

export const logoutUser = () => {
  removerExibicaoColunas()
  removeSecurityToken()
  return {
    type: USER_LOGOUT,
    payload: {
      tokenJwt: null,
      isUserAuthenticated: false,
      username: null
    }
  }
}
