import React from 'react'
import PropTypes from 'prop-types'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import { Chip } from '@material-ui/core'

const ItemUnidadeConfigurada = ({ unidade, onDesvincular }) => {
  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <Typography>
            {unidade.unidadeInterna.sigla} - {unidade.unidadeInterna.nome}
          </Typography>
        </TableCell>
        <TableCell>
          {unidade.unidadesExternas.map(it => (
            <Chip
              style={{ marginRight: 5 }}
              label={it.sigla}
              onDelete={() => onDesvincular(unidade.unidadeInterna.cod, it.cod)}
            />
          ))}
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

ItemUnidadeConfigurada.propTypes = {
  unidade: PropTypes.object.isRequired,
  onDesvincular: PropTypes.func.isRequired
}

export default ItemUnidadeConfigurada
