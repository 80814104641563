import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import styles from '../../styles'

const tiposOrcamento = [
  {
    label: 'Editais/Licitações',
    value: 'EDITAIS_LICITACOES'
  },
  {
    label: 'Contratos/Aditivos',
    value: 'CONTRATOS_ADITIVOS'
  },
  {
    label: 'Medições/Faturas',
    value: 'MEDICOES_FATURAS'
  }
]

const CampoTipoOrcamento = ({
  tipoOrcamento,
  tipoOrcamentoEmpty,
  onTipoOrcamentoChange,
  classes
}) => {
  return (
    <FormControl error={tipoOrcamentoEmpty} variant='outlined' fullWidth className={classes.campoFormulario}>
      <Typography variant='subtitle1'>Tipo de orçamento</Typography>
      <Select
        value={tipoOrcamento||''}
        onChange={onTipoOrcamentoChange}>
        <MenuItem value=''>
          <em>---</em>
        </MenuItem>
        {
          tiposOrcamento.map((tipo) => (
            <MenuItem key={tipo.value} value={tipo.value}>
              <Typography>{tipo.label}</Typography>
            </MenuItem>
          ))
        }

      </Select>
    </FormControl>
  )
}

CampoTipoOrcamento.propTypes = {
  tipoOrcamento: PropTypes.string,
  tipoOrcamentoEmpty: PropTypes.bool,
  onTipoOrcamentoChange: PropTypes.func.isRequired,
  classes: PropTypes.object
}

export default withStyles(styles)(CampoTipoOrcamento)
