import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import queryString from 'query-string'

import { authenticateTokenLoginIntegrado } from '../../actions/authenticationJwtActions'
import PropTypes from 'prop-types'

class Sso extends Component {
  static propTypes = {
    match: PropTypes.object,
    authenticateTokenLoginIntegrado: PropTypes.func,
    location: PropTypes.object,
    history: PropTypes.object,
    authentication: PropTypes.object,
  }
  componentDidMount() {
    const { username, token } = this.props.match.params
    if (username && token) {
      this.props.authenticateTokenLoginIntegrado(username, token)
    } else {
      const query = queryString.parse(this.props.location.search)
      const { tkn } = query
      if (tkn) {
        this.props.authenticateTokenLoginIntegrado(username, tkn)
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { authentication: prevAuthentication } = prevProps
    const { authentication } = this.props
    if (
      authentication != null &&
      authentication !== prevAuthentication &&
      authentication.isUserAuthenticated
    ) {
      const query = queryString.parse(this.props.location.search)
      const redirectTo = query.redirect || '/'
      this.props.history.push(redirectTo)
    } else {
      this.props.history.push('/httperror')
    }
  }

  render() {
    return <div />
  }
}

const mapStateToProps = ({ authentication }) => ({ authentication })

const reduxEnhanced = connect(mapStateToProps, {
  authenticateTokenLoginIntegrado,
})(Sso)

export default withRouter(reduxEnhanced)
