import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import TabelaInconsistencia from './TabelaInconsistencia'
import PropTypes from 'prop-types'
import MenuPossiveisInconsistencias from './MenuPossiveisInconsistencias'
import { context_httpget } from '../../../../js/httpRequest'
import { connect } from 'react-redux'
import { recuperarColunasInconsistencia } from './ColunasTabelaInconsistencia'

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  typography: {
    padding: theme.spacing(2)
  }
})

export class ModalInconsistencias extends Component {
  static propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool.isRequired,
    inconsistencia: PropTypes.object.isRequired,
    contexto: PropTypes.object,
    close: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      colunas: [],
      colunasSegundaInconsistencia: [],
      itens: [],
      itensSegundaInconsistencia: []
    }
    this.recuperarColunas = this.recuperarColunas.bind(this)
    this.recuperarItensInconsistencia = this.recuperarItensInconsistencia.bind(this)
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }

  componentDidMount () {
    if (this.props.open && this.props.inconsistencia.titulo) {
      this.recuperarColunas()
      this.recuperarItensInconsistencia()
    }
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.open && this.props.open && this.props.inconsistencia.titulo) {
      this.recuperarColunas()
      this.recuperarItensInconsistencia()
    }
  }

  async recuperarItensInconsistencia () {
    const { inconsistencia, contexto } = this.props
    const itens = await context_httpget(`possiveis-inconsistencias/${inconsistencia.url}`, contexto)
    if(itens) {
      this.setState({ itens })
    }
    if (inconsistencia.segundaPossivelInconsistenciaTipologiaCombinada != null) {
      const itensSegundaInconsistencia = await context_httpget(`possiveis-inconsistencias/${inconsistencia.segundaPossivelInconsistenciaTipologiaCombinada.url}`, contexto)
      if(itensSegundaInconsistencia) {
        this.setState({ itensSegundaInconsistencia })
      }
    }
  }

  recuperarColunas () {
    const { inconsistencia } = this.props
    const { url } = inconsistencia

    const colunas = recuperarColunasInconsistencia(url)

    this.setState({ colunas })

    if (inconsistencia.segundaPossivelInconsistenciaTipologiaCombinada != null) {
      const { url } = inconsistencia.segundaPossivelInconsistenciaTipologiaCombinada
      const colunasSegundaInconsistencia = recuperarColunasInconsistencia(url)
      this.setState({ colunasSegundaInconsistencia })
    }
  }

  handleCloseModal () {
    this.setState({
      colunas: [],
      colunasSegundaInconsistencia: [],
      itens: [],
      itensSegundaInconsistencia: []
    })
    this.props.close()
  }

  render () {
    const { open, inconsistencia } = this.props
    const { colunas, colunasSegundaInconsistencia, itens, itensSegundaInconsistencia } = this.state

    return (
      <Dialog
        open={ open }
        onClose={ this.handleCloseModal }
        fullWidth
        aria-labelledby='responsive-dialog-title'>

        {(inconsistencia.segundaPossivelInconsistenciaTipologiaCombinada != null) ?
          <DialogTitle id='responsive-dialog-title'> { inconsistencia.nomeTipologiaCombinada }</DialogTitle>
          :
          <DialogTitle id='responsive-dialog-title'> { inconsistencia.titulo }</DialogTitle>
        }
        <DialogContent>
          <MenuPossiveisInconsistencias
            inconsistencia={ inconsistencia }
            colunas={ colunas }
            itens={ itens }
          />
          {(inconsistencia.segundaPossivelInconsistenciaTipologiaCombinada != null) ?
            <Typography>{inconsistencia.titulo}</Typography> : <></>            
          }
          { itens.length > 0
            ?
            <TabelaInconsistencia
              colunas={ colunas }
              itens={ itens }
            />
            : inconsistencia.tipoPossivelInconsistencia === 'TIPOLOGIA_FORMULA'
              ? <Alert severity="info">Nenhum item encontrado</Alert>
              : <Alert severity="info">Nenhuma inconsistência registrada</Alert>
          }
        </DialogContent>
        
        {(inconsistencia.segundaPossivelInconsistenciaTipologiaCombinada != null) ?
          <DialogContent>
            <MenuPossiveisInconsistencias
              inconsistencia={ inconsistencia.segundaPossivelInconsistenciaTipologiaCombinada }
              colunas={ colunasSegundaInconsistencia }
              itens={ itensSegundaInconsistencia }
            />
            <Typography>{inconsistencia.segundaPossivelInconsistenciaTipologiaCombinada.titulo}</Typography>
            { itensSegundaInconsistencia.length > 0
              ? 
              <TabelaInconsistencia
                colunas={ colunasSegundaInconsistencia }
                itens={ itensSegundaInconsistencia }
              />
              : inconsistencia.segundaPossivelInconsistenciaTipologiaCombinada.tipoPossivelInconsistencia === 'TIPOLOGIA_FORMULA'
                ? <Alert severity="info">Nenhum item encontrado</Alert>
                : <Alert severity="info">Nenhuma inconsistência registrada</Alert>
            }
          </DialogContent>
          :
          <></>
        }

        <DialogActions>
          <Button autoFocus onClick={ this.handleCloseModal } variant='contained' color='primary'>
            Voltar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ contexto }) => ({ contexto })

export default connect(mapStateToProps)(withStyles(styles)(ModalInconsistencias))
