import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import TabelaDetalheItemRelatorioHistoricoOrcamento from './TabelaDetalheItemRelatorioHistoricoOrcamento'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  typography: {
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
})

class ModalDetalheItemRelatorioHistoricoOrcamento extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    classes: PropTypes.object,
    itensDetalheHistoricoOrcamento: PropTypes.array
  }

  constructor (props) {
    super(props)
    this.state = {
      colunas: [],
      itens: []
    }
    this.handleCloseModal = this.handleCloseModal.bind(this)
  }


  handleCloseModal = () => {
    this.props.close()
  }

  render () {
    const { classes, open, itensDetalheHistoricoOrcamento } = this.props

    return (
      <Dialog
        open={open}
        onClose={() => this.handleCloseModal()}
        fullWidth
        aria-labelledby='responsive-dialog-title'>

        <DialogTitle id='responsive-dialog-title'>
          Histórico Orçamento
          <IconButton aria-label='Fechar' className={classes.closeButton} onClick={() => this.handleCloseModal()}>
            <CloseIcon/>
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon/>}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className='backAlmWhite' >
              <Typography className={classes.heading} component='label'>Itens</Typography>
            </ExpansionPanelSummary>

            <ExpansionPanelDetails>
              <TabelaDetalheItemRelatorioHistoricoOrcamento
                itensDetalheHistoricoOrcamento={itensDetalheHistoricoOrcamento}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={() => this.handleCloseModal()} color='primary'>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(ModalDetalheItemRelatorioHistoricoOrcamento)
