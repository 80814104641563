import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TitleTableCell from '../../../../../../comum/TitleTableCell'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import ItemVincularExpressoes from './ItemVincularExpressoes'
import Paginacao from '../../../../../../comum/Paginacao'
import _ from 'lodash'

class TabelaVincularExpressoes extends Component {
  static propTypes = {
    classes: PropTypes.object,
    expressoes: PropTypes.array,
    expressoesSelecionadas: PropTypes.array,
    onSelecionarExpressao: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props);
    this.state = {
      pagina: 0,
      registrosPorPagina: 10
    }
  }

  handleExpressaoIsChecked = (expressao) => {
    const { expressoesSelecionadas } = this.props
    return expressoesSelecionadas.map(it => _.isNil(it.codExpressaoOriginal) ? it.codExpressao : it.codExpressaoOriginal).indexOf(expressao.codExpressao) !== -1;
  }

  handleDesabilitarExpressao = (expressao) => {
    const { expressoesSelecionadas } = this.props
    return expressoesSelecionadas.some(exp => exp.codExpressaoOriginal === expressao.codExpressao);
  }

  render () {
    const {
      classes,
      expressoes,
      onSelecionarExpressao
    } = this.props

    const {
      pagina,
      registrosPorPagina
    } = this.state

    return (
      <React.Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TitleTableCell width={'5%'} />
              <TitleTableCell width={'25%'}>Nome</TitleTableCell>
              <TitleTableCell width={'25%'}>Orçamento</TitleTableCell>
              <TitleTableCell width={'25%'}>Obra</TitleTableCell>
              <TitleTableCell align='right'>Empreendimento</TitleTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { expressoes.slice(pagina * registrosPorPagina, pagina * registrosPorPagina + registrosPorPagina).map((expressao) => (
               <ItemVincularExpressoes
                 key={expressao.codExpressao}
                 classes={classes}
                 expressao={expressao}
                 isDisabled={this.handleDesabilitarExpressao(expressao)}
                 isChecked={this.handleExpressaoIsChecked(expressao)}
                 onSelecionarExpressao={onSelecionarExpressao}
               />
              ))
            }
          </TableBody>
        </Table>
        <Paginacao
          total={expressoes.length}
          registrosPorPagina={registrosPorPagina}
          pagina={pagina}
          onChangePage={(e, pag) => { this.setState({ pagina: pag }) }}
          onChangeRowsPerPage={(e) => (this.setState({ registrosPorPagina: e.target.value }))}
        />
      </React.Fragment>
    )
  }
}

export default TabelaVincularExpressoes
