import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import Button from '@material-ui/core/Button'
import styles from '../styles'
import BotaoConfirmar from './informacoes-gerais/BotaoConfirmar'
import FormControl from '@material-ui/core/FormControl'

const FormularioDadosIniciais = ({
  exibir,
  confirmado,
  exibirCancelarArquivo,
  classes,
  descricao,
  descricaoEmpty,
  onDescricaoChange,
  sigiloso,
  onSigilosoChange,
  onConfirmarClick,
  onCancelarClick
}) => {
  return exibir && (
    <>
      <BotaoConfirmar confirmado={confirmado} onClick={onConfirmarClick} />
      <FormControl variant='outlined'>
        <Typography variant='subtitle1'>Descrição do orçamento</Typography>
        <TextField
          className={classes.campoFormulario}
          fullWidth
          variant='outlined'
          value={descricao || ''}
          onChange={onDescricaoChange}
          error={descricaoEmpty}
          inputProps={{
            maxLength: 4000,
            size: 4000
          }}
        />
        <Typography variant='caption' color={descricao.length > 400 ? 'error' : 'textSecondary'}>
          {Math.max(0, 4000 - descricao.length)} caracteres restantes
        </Typography>
      </FormControl>

      <Typography variant='subtitle1'>Sigiloso?</Typography>
      <RadioGroup
        row
        aria-label='Sigiloso'
        name='sigiloso'
        value={sigiloso}
        onChange={onSigilosoChange}>
        <FormControlLabel value={false} control={<Radio color='primary'/>} label='Não'/>
        <FormControlLabel value={true} control={<Radio color='primary'/>} label='Sim'/>
      </RadioGroup>


      {
        exibirCancelarArquivo &&
          <Button color='primary' onClick={onCancelarClick}>
            Cancelar
          </Button>
      }
    </>
  )
}

FormularioDadosIniciais.propTypes = {
  classes: PropTypes.object,
  exibir: PropTypes.bool.isRequired,
  confirmado: PropTypes.bool.isRequired,
  exibirCancelarArquivo: PropTypes.bool.isRequired,
  descricao: PropTypes.string.isRequired,
  descricaoEmpty: PropTypes.bool.isRequired,
  onDescricaoChange: PropTypes.func.isRequired,
  sigiloso: PropTypes.bool.isRequired,
  onSigilosoChange: PropTypes.func.isRequired,
  onConfirmarClick: PropTypes.func.isRequired,
  onCancelarClick: PropTypes.func.isRequired
}

export default withStyles(styles)(FormularioDadosIniciais)
