import { formatarNumeroParaMoedaBR, formatarUF } from '../../../../../../../js/util'

export function dadosGerais (doc, detalhes, dados) {
  const posicaoInicioTabelas = 130
  const posicaoPrimeiraLinha = posicaoInicioTabelas - 100
  doc.setFontSize(10)
  doc.setDrawColor(200, 200, 200)
  doc.text('Fonte: ', 45, posicaoPrimeiraLinha)
  doc.text('Mês: ', 180, posicaoPrimeiraLinha)
  doc.text('Local: ', 280, posicaoPrimeiraLinha)
  doc.setFontSize(9)
  doc.text('Producao da equipe: ', 450, posicaoPrimeiraLinha)
  doc.text('FIC: ', 650, posicaoPrimeiraLinha)
  doc.text('FIT: ', 740, posicaoPrimeiraLinha)
  doc.setFontSize(10)

  doc.setFont('helvetica', 'bold')

  doc.text(dados.value.sistemaReferencia, 82, posicaoPrimeiraLinha)
  doc.text(
    dados.value.mes.toString() + ' / ' + dados.value.ano.toString(),
    207,
    posicaoPrimeiraLinha
  )
  doc.text(formatarUF(dados.value.uf), 315, posicaoPrimeiraLinha)
  doc.setFontSize(9)
  doc.text(
    formatarNumeroParaMoedaBR(dados.value.producao, 2, 4) +
      ' ' +
      dados.value.unidade,
    540,
    posicaoPrimeiraLinha
  )
  doc.text(
    formatarNumeroParaMoedaBR(dados.value.fic, 2, 6),
    680,
    posicaoPrimeiraLinha
  )
  doc.text(
    formatarNumeroParaMoedaBR(dados.value.fit, 2, 6),
    770,
    posicaoPrimeiraLinha
  )
  doc.setFontSize(10)

  doc.autoTable({
    body: [
      [
        {
          content: 'Observações: ',
          styles: {
            overflow: 'linebreak',
            valign: 'top',
            halign: 'left',
            fontStyle: 'normal',
            fontSize: 9,
            cellWidth: 70
          }
        },
        {
          content: dados.value.observacao ? dados.value.observacao : ' ',
          styles: {
            overflow: 'linebreak',
            valign: 'top',
            halign: 'left',
            fontStyle: 'bold',
            fontSize: 9,
            cellWidth: 445
          }
        }
      ]
    ],
    startY: 30,
    startX: 20,
    theme: 'plain',
    tableWidth: 'auto'
  })

  doc.roundedRect(550, 35, 250, 13, 5, 5, 'S')
  doc.text(
    'Custo Unitário de Referência (Valor em ' +
    'R$/' +
    dados.value.unidade +
    ')',
    558,
    44
  )
  doc.setFont('helvetica', 'normal')
  doc.setFontSize(8)
  doc.line(40, doc['lastAutoTable'].finalY, 800, doc['lastAutoTable'].finalY)
  doc.autoTable({
    body: [
      [
        {
          content: dados.value.codigo + ' ' + dados.value.descricao +
            ' ' + dados.value.mes.toString() + '/' + dados.value.ano.toString(),
          styles: {
            overflow: 'linebreak',
            valign: 'top',
            halign: 'left',
            fontStyle: 'bold',
            fontSize: 9,
            cellWidth: 750
          }
        }
      ]
    ],
    startY: doc['lastAutoTable'].finalY,
    startX: 20,
    theme: 'plain',
    tableWidth: 'auto'
  })
}
