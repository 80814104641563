import React, { Component } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { hotjar } from 'react-hotjar'

import { setBrowserHistory } from '../js/applicationContext'
import ForbiddenAccess from '../components/layout/ForbiddenAccess'
import AuthenticatedRoute from './AuthenticatedRoute'
import LoginSiga from '../components/login/LoginSiga'
import Sso from '../components/login/Sso'
import Logout from '../components/login/Logout'
import NotFoundPage from '../components/layout/NotFoundPage'
import Orcamentos from '../components/orcamento/Orcamentos'
import Home from '../components/home/Home'
import CurvaABC from '../components/orcamento/analisar/curva-abc/CurvaABC'
import CurvaABCInsumo from '../components/orcamento/analisar/curva-abc-insumo/CurvaABCInsumo'
import Precos from '../components/orcamento/analisar/precos/Precos'
import PossiveisErros from '../components/orcamento/analisar/possiveis-erros/PossiveisErros'
import PossiveisInconsistencias from '../components/orcamento/analisar/possiveis-inconsistencias/PossiveisInconsistencias'
import RelatorioHistoricoOrcamento from '../components/orcamento/analisar/relatorios/historico-orcamento/RelatorioHistoricoOrcamento'
import PropTypes from 'prop-types'
import CadastrarOrcamento from '../components/orcamento/cadastrar/CadastrarOrcamento'
import Sobre from '../components/Sobre'
import AnalisarOrcamento from '../components/orcamento/analisar/orcamento-bruto/AnalisarOrcamento'
import AnaliseConteudo from '../components/orcamento/analisar/analise-conteudo/AnaliseConteudo'
import GerenciarTemas from '../components/orcamento/analisar/temas/Temas'
import CadastrarTema from '../components/orcamento/analisar/temas/Tema'
import ItemCurvaAbc from '../components/orcamento/analisar/curva-abc/itens/ItemCurvaAbc'
import ConfigurarUnidade from '../components/configuracao/unidade/ConfigurarUnidade'
import Empreendimentos from '../components/empreendimento/Empreendimentos'
import ConfigurarPerfil from '../components/configuracao/perfil/ConfigurarPerfil'
import AnaliseRisco from '../components/analise-risco/AnaliseRisco'
import CompararOrcamento from '../components/orcamento/comparar/CompararOrcamento'
import ConfigurarCarga from '../components/configuracao/carga/ConfigurarCarga'
import RelatorioGerencial from '../components/configuracao/relatorio-gerencial/RelatorioGerencial'

class AppRoutes extends Component {
  constructor (props) {
    super(props)
    setBrowserHistory(this.props.history)
    // Desativação do hotjar
    // this.initHotjar()
  }

  initHotjar () {
    if (window.location.href.indexOf('apps') > -1) {
      hotjar.initialize(2210473, 6)
    }
  }

  render () {
    return (
      <Switch>
        {/* Defina as rotas por ordem de especificidade, das mais específicas para as mais genéricas... */}
        <Route path='/login' component={LoginSiga} />
        <Route path='/logout' component={Logout} />
        <Route path='/sso' component={Sso} />
        <Route path='/forbidden' component={ForbiddenAccess} />
        <AuthenticatedRoute
          path='/empreendimentos/:idEmpreendimento/obras/:idObra/orcamentos/cadastrar'
          component={CadastrarOrcamento} />
        <AuthenticatedRoute
          path='/empreendimentos/:idEmpreendimento/obras/:idObra/orcamentos/:idOrcamento/analisar'
          component={AnalisarOrcamento} />
        <AuthenticatedRoute
          path='/empreendimentos/:idEmpreendimento/obras/:idObra/orcamentos/:idOrcamento/abc/itens/:numeroItem'
          component={ItemCurvaAbc} />
        <AuthenticatedRoute
          path='/empreendimentos/:idEmpreendimento/obras/:idObra/orcamentos/:idOrcamento/abc'
          component={CurvaABC} />
        <AuthenticatedRoute
          path='/empreendimentos/:idEmpreendimento/obras/:idObra/orcamentos/:idOrcamento/abc-insumo'
          component={CurvaABCInsumo} />
        <AuthenticatedRoute
          path='/empreendimentos/:idEmpreendimento/obras/:idObra/orcamentos/:idOrcamento/precos'
          component={Precos} />
        <AuthenticatedRoute
          path='/empreendimentos/:idEmpreendimento/obras/:idObra/orcamentos/:idOrcamento/historico-orcamento'
          component={RelatorioHistoricoOrcamento} />
        <AuthenticatedRoute
          path='/empreendimentos/:idEmpreendimento/obras/:idObra/orcamentos/:idOrcamento/possiveis-erros'
          component={PossiveisErros} />
        <AuthenticatedRoute
          path='/empreendimentos/:idEmpreendimento/obras/:idObra/orcamentos/:idOrcamento/possiveis-inconsistencias'
          component={PossiveisInconsistencias} />
        <AuthenticatedRoute
          path='/empreendimentos/:idEmpreendimento/obras/:idObra/orcamentos/:idOrcamento/analise-conteudo/gerenciar-temas/cadastrar'
          component={CadastrarTema} />
        <AuthenticatedRoute

          path='/empreendimentos/:idEmpreendimento/obras/:idObra/orcamentos/:idOrcamento/analise-conteudo/gerenciar-temas/:idTema'
          component={CadastrarTema} />
        <AuthenticatedRoute
          path='/empreendimentos/:idEmpreendimento/obras/:idObra/orcamentos/:idOrcamento/analise-conteudo/gerenciar-temas'
          component={GerenciarTemas} />
        <AuthenticatedRoute
          path='/empreendimentos/:idEmpreendimento/obras/:idObra/orcamentos/:idOrcamento/analise-conteudo'
          component={AnaliseConteudo} />
        <AuthenticatedRoute
          path='/empreendimentos/:idEmpreendimento/obras/:idObra/orcamentos/:idOrcamento/comparar'
          component={CompararOrcamento}
        />
        <AuthenticatedRoute
          path='/empreendimentos/:idEmpreendimento/obras/:idObra/orcamentos/:idOrcamento'
          component={CadastrarOrcamento} />
        <AuthenticatedRoute
          path='/empreendimentos/:idEmpreendimento/obras/:idObra/orcamentos'
          component={Orcamentos} />
        <AuthenticatedRoute
          path='/empreendimentos/:idEmpreendimento/obras/orcamentos'
          component={Orcamentos} />
        <AuthenticatedRoute
          path='/empreendimentos/empreendimento?cod=:idEmpreendimento'
          component={Empreendimentos}
        />
        <AuthenticatedRoute
          path='/empreendimentos/'
          component={Empreendimentos}
        />
        <AuthenticatedRoute
          exact path='/'
          component={Home}
        />
        <AuthenticatedRoute
          exact path='/sobre'
          component={Sobre}
        />
        <AuthenticatedRoute
          path='/configurar/perfis'
          component={ConfigurarPerfil}
        />
        <AuthenticatedRoute
          path='/configurar/carga'
          component={ConfigurarCarga}
        />
        <AuthenticatedRoute
          path='/configurar/relatorio-gerencial'
          component={RelatorioGerencial}
        />
        <AuthenticatedRoute
          path='/configurar/unidades'
          component={ConfigurarUnidade}
        />
        <AuthenticatedRoute
          path='/analise-risco'
          component={AnaliseRisco}
        />
        <Route component={NotFoundPage} />
      </Switch>
    )
  }
}

AppRoutes.propTypes = {
  history: PropTypes.objectOf(PropTypes.any).isRequired
}

export default withRouter(AppRoutes)
