import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import TabelaComposicoes from './TabelaComposicoes'
import TituloModal from '../../../../comum/TituloModal'

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  typography: {
    padding: theme.spacing(2)
  }
})

export class ModalComposicoes extends Component {
  static propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    titulo: PropTypes.string.isRequired,
    composicoes: PropTypes.array,
    onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.func
  }

  render () {
    const { open, close, titulo, composicoes, onAtualizarItemCurvaABCComposicaoDuplicada } = this.props

    return (
      <Dialog
        open={open}
        onClose={close}
        fullWidth
        aria-labelledby='responsive-dialog-title'>

        <TituloModal onClose={close}>
          {titulo}
        </TituloModal>

        <DialogContent>
          <TabelaComposicoes
            composicoes={composicoes}
            onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
          />
        </DialogContent>

        <DialogActions>
          <Button autoFocus onClick={ close } variant='contained' color='primary'>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default (withStyles(styles)(ModalComposicoes))
