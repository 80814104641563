import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Divider from '@material-ui/core/Divider/Divider'
import DialogContent from '@material-ui/core/DialogContent'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List/List'
import ListItem from '@material-ui/core/ListItem/ListItem'
import ListItemText from '@material-ui/core/ListItemText/ListItemText'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import { showErrorMsg } from '../../js/util'
import _ from 'lodash'
import { getAuthentication } from '../../security/securityContext'
import { InputAdornment, TextField, withStyles, Switch, FormControlLabel } from '@material-ui/core'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'
import { KeyboardDatePicker, MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { ptBR } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns'
import moment from 'moment'

const DialogTitle = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}))((props) => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label='Close'
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const ModalConcederAcessoUsuario = ({ codEmpreendimento, open, fechar, onComplete }) => {
  const [termo, setTermo] = useState('')
  const [termoEmpty, setTermoEmpty] = useState(false)
  const [usuarios, setUsuarios] = useState([])
  const [definirDataExpiracao, setDefinirDataExpiracao] = useState(false)
  const [dataExpiracao, setDataExpiracao] = useState(() => {
    const date = new Date()
    date.setHours(23, 59, 59, 0)
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
    return date
  })

  const validarCampos = () => {
    if (!_.isEmpty(termo) && termo.length <= 5) {
      setTermoEmpty(true)
      setUsuarios([])
      return true
    }
    return false
  }

  const handleBuscar = async () => {
    if (!validarCampos()) {
      if (!_.isEmpty(termo)) {
        try {
          const response = await axios.get(`/api/empreendimentos/${codEmpreendimento}/obras/usuario?busca=${termo}`, SLOW_REQUEST_CONFIG)
          if (response.status === 200) {
            setUsuarios(response.data)
          } else {
            showErrorMsg('Usuário não encontrado')
          }
        } catch (e) {
          setUsuarios([])
          setTermoEmpty(false)
        }
      }
    }
  }
  const handleKeyUpSearch = (e) => {
    if (e.key === 'Enter') {
      handleBuscar()
    }
  }

  const handleFechar = () => {
    setUsuarios([])
    setTermo(null)
    setTermoEmpty(false)
    fechar()
  }

  const handleSalvar = (usuario) => {
    if (getAuthentication().username !== usuario.login) {
      if (definirDataExpiracao) {
        const usuarioConcedido = { ...usuario, dataExclusao: dataExpiracao }
        onComplete(usuarioConcedido)
      } else {
        onComplete(usuario)
      }      
      handleFechar()
    } else {
      showErrorMsg('Não é possível conceder acesso a você mesmo')
    }
  } 


  const handleChangeDefinirDataExpiracao = () => {    
    setDefinirDataExpiracao(!definirDataExpiracao)
  }

  const handleChangeData = (data) => {
    const novaData = new Date(data);
    novaData.setHours(23, 59, 59, 0);
    novaData.setMinutes(novaData.getMinutes() - novaData.getTimezoneOffset());
    setDataExpiracao(novaData);

  }

  return (
    <Dialog
      open={open}
      onClose={handleFechar}
      fullWidth
    >
      <DialogTitle onClose={handleFechar}>
        Conceder acesso a usuários de outras Unidades
      </DialogTitle>
      <Divider />
      <DialogContent className='mdl-obra-cont'>
        <Box mt={1}>
          <Typography variant='subtitle2' gutterBottom>
            Pesquise por login do TCU ou nome com sobrenome
          </Typography>
          <Box>
            <TextField
              value={termo}
              placeholder='Digite no mínimo 6 caracteres'
              onChange={(e) => setTermo(e.target.value)}
              error={termoEmpty}
              variant='outlined'
              fullWidth
              style={{ marginRight: 12 }}
              onKeyUp={(e) => handleKeyUpSearch(e)}
              InputProps={{
                endAdornment:
                  <InputAdornment position='end'>
                    <IconButton id='btnBuscar' onClick={handleBuscar}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
              }}
            />
            {termoEmpty && <Typography variant='body1' style={{ fontSize: '10px', color: 'red' }}>Informe um valor maior que 5 caracteres</Typography>}
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Switch
                  checked={definirDataExpiracao}
                  name="definirDataExpiracao"
                  color="primary"
                  onChange={handleChangeDefinirDataExpiracao}
                />
              }
              label="Definir data de expiração do acesso"
            />            
          </Box>
          {definirDataExpiracao &&
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                inputVariant='outlined'
                format='dd/MM/yyyy'
                margin='normal'
                //label='Data de Expiração'
                //className={classes.boxInput}
                cancelLabel='Cancelar'
                invalidDateMessage='Data em formato inválido.'
                value={dataExpiracao}
                //onChange={(data) => setDataExpiracao(data)}
                onChange={(data) => handleChangeData(data)}
                minDate={new Date()}
                minDateMessage={`A data deve ser maior ou igual que ${moment(new Date()).format('DD/MM/YYYY')}.`}
                InputProps={{
                  style: {
                    marginBottom: 0,
                    fontSize: 14,
                    height: 60,
                    width: 180,
                  }
                }}
              />
            </MuiPickersUtilsProvider>
          }
        </Box>

        { <List>
          { usuarios.map((usuario) => (
            <ListItem key={usuario.nomeCompleto} button onClick={() => handleSalvar(usuario)}>
              <ListItemText primary={`${usuario.nomeCompleto} (${usuario.login})`} />
            </ListItem>
          ))}
        </List>
        }
      </DialogContent>
    </Dialog>
  )
}

ModalConcederAcessoUsuario.propTypes = {
  codEmpreendimento: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  fechar: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired
}

export default ModalConcederAcessoUsuario
