import React from 'react'
import StyledTableRow from '../../../comum/StyledTableRow'
import TableCell from '@material-ui/core/TableCell'
import Alert from '@material-ui/lab/Alert'

const CurvaAbcVazia = () => (
  <StyledTableRow>
    <TableCell colSpan={15}>
      <Alert style={{marginTop: 16, marginBottom: 16}} severity="warning">Não há itens a exibir</Alert>
    </TableCell>
  </StyledTableRow>
)

export default CurvaAbcVazia
