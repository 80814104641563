import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'
import Carregando from '../../../comum/Carregando'
import { context_httpget } from '../../../../js/httpRequest'
import { withRouter } from 'react-router-dom'
import { mapStateToPropsContexto } from '../../../../js/util'
import CurvaAbcCriada from './CurvaAbcCriada'
import CurvaAbcNaoCriada from './CurvaAbcNaoCriada'

export class CurvaABC extends Component {
  static propTypes = {
    contexto: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      resumoOrcamento: null
    }
    this.handleResumoAlterado = this.handleResumoAlterado.bind(this)
  }

  componentDidMount () {
    if (!_.isNil(this.props.contexto) && !_.isNil(this.props.contexto.orcamento)) {
      this.recuperarResumoOrcamento()
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.contexto !== this.props.contexto) {
      this.recuperarResumoOrcamento()
    }
  }

  async recuperarResumoOrcamento () {
    const { contexto } = this.props
    const resumoOrcamento = await context_httpget('resumo', contexto)
    if (resumoOrcamento) {
      this.setState({ resumoOrcamento })
    }
  }

  handleResumoAlterado(resumoOrcamento) {
    this.setState({ resumoOrcamento })
  }

  render () {
    const { resumoOrcamento } = this.state

    if (_.isNil(resumoOrcamento)) {
      return <Carregando />
    }

    return resumoOrcamento.possuiCurvaAbc ? (
      <CurvaAbcCriada
        resumoOrcamento={resumoOrcamento}
        onCurvaAbcExcluida={this.handleResumoAlterado}
      />
    ) : (
      <CurvaAbcNaoCriada
        resumoOrcamento={resumoOrcamento}
        onCurvaAbcCriada={this.handleResumoAlterado}
      />
    )
  }
}

export default connect(mapStateToPropsContexto)(withRouter(CurvaABC))
