import React, { Component } from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import Table from '@material-ui/core/Table'
import { TableBody, Tooltip } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import StyledTableRow from '../../../../comum/StyledTableRow'
import TitleTableCell from '../../../../comum/TitleTableCell'
import { formatarNumeroParaMoedaBR } from '../../../../../js/util'
import PropTypes from 'prop-types'

class TabelaDescritivaCalculos extends Component {
  static propTypes = {
    cabecalho: PropTypes.array.isRequired,
    calculo: PropTypes.array.isRequired
  }

  descreverFormula = (formula) => (
    <div className='dspflex whiteSp' >
      {
        formula.map((it, i) => (
          it.nome
            ? (
              <Tooltip title={it.descricao} key={i}>
                <div>{it.nome}</div>
              </Tooltip>
            )
            : (
              <div key={i}>{it}</div>
            )
        ))
      }
    </div>
  )

  render () {
    const { cabecalho, calculo } = this.props
    return (
      <Table>
        <TableHead>
          <TableRow>
            {
              //TODO: É considerada uma má prática usar o index como key em uma interação
              // https://medium.com/@vraa/why-using-an-index-as-key-in-react-is-probably-a-bad-idea-7543de68b17c
              cabecalho.map((titulo, i) => (
                <TitleTableCell key={i}>{titulo}</TitleTableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            calculo.map((calc, i) => (
              <StyledTableRow key={i}>
                <TableCell>{calc.descricao}</TableCell>
                <TableCell align="center">{this.descreverFormula(calc.formula)}</TableCell>
                <TableCell align="right">{formatarNumeroParaMoedaBR(
                  calc.valor,
                  calc.precisao == null ? 3 : calc.precisao,
                  calc.precisao == null ? 3 : calc.precisao
                )}
                </TableCell>
              </StyledTableRow>
            ))
          }
        </TableBody>
      </Table>
    )
  }
}

export default TabelaDescritivaCalculos
