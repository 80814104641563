import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { logoutUser } from '../../actions/authenticationJwtActions'
import { getAuthentication } from '../../security/securityContext'
import App from '../App'
import { showSuccessMsg } from '../../js/util'
import PropTypes from 'prop-types'

export class Logout extends Component {
  static propTypes = {
    logoutUser: PropTypes.func
  }

  componentDidMount () {
    const authentication = getAuthentication()
    if (authentication && authentication.isUserAuthenticated) {
      showSuccessMsg(`Até breve ${authentication.nomeSimples}!`)
    }
    this.props.logoutUser()
  }

  render () {
    return (
      <App>
        <Redirect to='/' />
      </App>
    )
  }
}

export default connect(null, { logoutUser })(Logout)
