import React, { Component } from 'react'
import TableCell from '@material-ui/core/TableCell'
import { connect } from 'react-redux'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import Button from '@material-ui/core/Button'
import { converterMoedaBRParaNumero, formatarNumeroParaMoedaBR, showSuccessMsg } from '../../../../../js/util'
import CampoMoeda from '../../../../comum/CampoMoeda'
import IconeSalvar from '../../../../comum/IconeSalvar'
import PropTypes from 'prop-types'
import { context_httpput } from '../../../../../js/httpRequest'
import MesmaLinha from '../../../../comum/MesmaLinha'
import { withStyles } from '@material-ui/core'
import styles from '../styles'

export class BdiCurvaABC extends Component {
  static propTypes = {
    itemCurvaAbc: PropTypes.object.isRequired,
    exibir: PropTypes.bool.isRequired,
    contexto: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    classes: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      bdiEmEdicao: this.props.itemCurvaAbc.bdi,
      houveAlteracao: false,
      editando: false,
    }
    this.editarBdi = this.editarBdi.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.itemCurvaAbc !== this.props.itemCurvaAbc) {
      this.setState({
        bdiEmEdicao: this.props.itemCurvaAbc.bdi || '',
        houveAlteracao: false,
      })
    } else {
      const { houveAlteracao } = this.state
      const houveAlteracaoNovo =
        this.state.bdiEmEdicao !== this.props.itemCurvaAbc.bdi
      if (houveAlteracao !== houveAlteracaoNovo) {
        this.setState({ houveAlteracao: houveAlteracaoNovo })
      }
    }
  }

  data() {
    const { itemCurvaAbc } = this.props
    const { bdiEmEdicao } = this.state
    return { ...itemCurvaAbc, bdi: converterMoedaBRParaNumero(bdiEmEdicao) }
  }

  async editarBdi() {
    const { contexto, itemCurvaAbc, onChange } = this.props
    const result = await context_httpput(
      `curva-abc/itens/${itemCurvaAbc.id}/bdi`,
      contexto,
      { bdi: this.state.bdiEmEdicao.replace(',', '.') }
    )
    if (result) {
      showSuccessMsg('BDI alterado com sucesso')
      this.setState({ editando: false })
      onChange(itemCurvaAbc, result)
    }
  }

  handleChange(event) {
    const bdiEmEdicao = event.target.value
    this.setState({ bdiEmEdicao, houveAlteracao: true })
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.editarBdi()
    }
  }

  render() {
    const { editando, bdiEmEdicao, houveAlteracao } = this.state
    const { exibir } = this.props
    return (
      exibir &&
      (editando ? (
        <TableCell>
          <CampoMoeda
            name={'bdi'}
            value={formatarNumeroParaMoedaBR(bdiEmEdicao, 2, 2)}
            maxLength={6}
            autoFocus={false}
            fullWidth={true}
            margin={'none'}
            variant={'outlined'}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            InputProps={{
              style: {
                height: 32,
                width: 80,
              },
              endAdornment: (
                <InputAdornment position="end" style={{ marginRight: 5 }}>
                  <IconButton
                    onClick={this.editarBdi}
                    disabled={!houveAlteracao}
                    size="small"
                    className="backWhitebrdGrey"
                  >
                    <IconeSalvar
                      textTooltip="Salvar"
                      color={houveAlteracao ? 'primary' : 'action'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </TableCell>
      ) : (
        <TableCell>
          <MesmaLinha>
            <Button
              size="small"
              onClick={() => this.setState({ editando: true })}
            >
              {formatarNumeroParaMoedaBR(bdiEmEdicao, 2, 2)}
            </Button>
            {/* <EditIcon fontSize='small' color='primary' style={{ cursor: 'pointer' }} onClick={() => this.setState({ editando: true })} /> */}
          </MesmaLinha>
        </TableCell>
      ))
    )
  }
}

export const mapStateToProps = ({ contexto }) => ({ contexto })

export default connect(mapStateToProps)(withStyles(styles)(BdiCurvaABC))
