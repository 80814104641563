import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'

const PaperBox = withStyles(() => ({
  root: {
    padding: '16px 16px 0',
  },
}))(Paper);

export default PaperBox
