import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import DialogContenText from '@material-ui/core/DialogContentText'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TitleTableCell from '../../comum/TitleTableCell'
import TableHead from '@material-ui/core/TableHead'
import { Chip, List, ListItem, TableCell, Typography } from '@mui/material'
import StyledTableRow from '../../comum/StyledTableRow'
import TableBody from '@material-ui/core/TableBody'

const estiloSituacao = new Map([['Completa', 'success'], ['Rejeitada', 'error'],
  ['Em andamento', 'warning'], ['Novo período', 'primary']])

const ModalDetalharPeriodo = ({ seExibe, onConfirmar, sistema, periodo }) => {
  const mapa = {
    RR: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_RR_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_RR_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_RR_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_RR_022023_NaoDesonerado.XLS'
    ],
    RS: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_RS_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_RS_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_RS_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_RS_022023_NaoDesonerado.XLS'
    ],
    DF: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_DF_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_DF_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_DF_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_DF_022023_NaoDesonerado.XLS'
    ],
    PR: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_PR_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_PR_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_PR_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_PR_022023_NaoDesonerado.XLS'
    ],
    SC: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_SC_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_SC_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_SC_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_SC_022023_NaoDesonerado.XLS'
    ],
    SE: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_SE_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_SE_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_SE_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_SE_022023_NaoDesonerado.XLS'
    ],
    MA: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_MA_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_MA_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_MA_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_MA_022023_NaoDesonerado.XLS'
    ],
    MG: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_MG_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_MG_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_MG_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_MG_022023_NaoDesonerado.XLS'
    ],
    SP: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_SP_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_SP_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_SP_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_SP_022023_NaoDesonerado.XLS'
    ],
    AC: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_AC_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_AC_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_AC_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_AC_022023_NaoDesonerado.XLS'
    ],
    CE: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_CE_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_CE_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_CE_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_CE_022023_NaoDesonerado.XLS'
    ],
    MS: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_MS_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_MS_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_MS_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_MS_022023_NaoDesonerado.XLS'
    ],
    MT: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_MT_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_MT_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_MT_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_MT_022023_NaoDesonerado.XLS'
    ],
    GO: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_GO_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_GO_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_GO_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_GO_022023_NaoDesonerado.XLS'
    ],
    AL: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_AL_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_AL_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_AL_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_AL_022023_NaoDesonerado.XLS'
    ],
    AM: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_AM_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_AM_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_AM_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_AM_022023_NaoDesonerado.XLS'
    ],
    ES: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_ES_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_ES_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_ES_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_ES_022023_NaoDesonerado.XLS'
    ],
    AP: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_AP_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_AP_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_AP_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_AP_022023_NaoDesonerado.XLS'
    ],
    PA: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_PA_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_PA_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_PA_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_PA_022023_NaoDesonerado.XLS'
    ],
    PB: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_PB_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_PB_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_PB_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_PB_022023_NaoDesonerado.XLS'
    ],
    PE: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_PE_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_PE_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_PE_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_PE_022023_NaoDesonerado.XLS'
    ],
    RJ: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_RJ_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_RJ_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_RJ_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_RJ_022023_NaoDesonerado.XLS'
    ],
    PI: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_PI_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_PI_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_PI_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_PI_022023_NaoDesonerado.XLS'
    ],
    TO: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_TO_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_TO_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_TO_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_TO_022023_NaoDesonerado.XLS'
    ],
    RN: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_RN_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_RN_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_RN_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_RN_022023_NaoDesonerado.XLS'
    ],
    RO: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_RO_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_RO_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_RO_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_RO_022023_NaoDesonerado.XLS'
    ],
    BA: [
      'SINAPI_Custo_Ref_Composicoes_Analitico_BA_202302_Desonerado.xls',
      'SINAPI_Preco_Ref_Insumos_BA_022023_Desonerado.XLS',
      'SINAPI_Custo_Ref_Composicoes_Analitico_BA_202302_NaoDesonerado.xls',
      'SINAPI_Preco_Ref_Insumos_BA_022023_NaoDesonerado.XLS'
    ] }

  // periodo.mapaArquivos = mapa

  return (
    <Dialog open={seExibe}>
      {console.log(periodo)}
      <DialogTitle>Detalhe do Período</DialogTitle>
      <DialogContent>
        <DialogContenText>
          <Table>
            { periodo && <TableHead>
              <TableRow>
                <TitleTableCell>{sistema.toUpperCase()}</TitleTableCell>
                <TitleTableCell><Chip label={periodo.situacao}
                  color={estiloSituacao.get(periodo.situacao)} variant='outlined' /></TitleTableCell>
                <TitleTableCell>{periodo.mes}/{periodo.ano}</TitleTableCell>
              </TableRow>
              <TableRow>
                <TitleTableCell align={'right'}>Material</TitleTableCell>
                <TitleTableCell align={'right'}>Equipamento</TitleTableCell>
                <TitleTableCell align={'right'}>Mão de Obra</TitleTableCell>
                <TitleTableCell align={'right'}>Composição</TitleTableCell>
                { sistema.toUpperCase() === 'SINAPI' && <TitleTableCell align={'right'}>Encargo Social</TitleTableCell>}
              </TableRow>
              <TableRow>
                <TitleTableCell align={'right'}>({periodo && periodo.ufsMaterial ? periodo.ufsMaterial.length : ''}) UFs</TitleTableCell>
                <TitleTableCell align={'right'}>({periodo && periodo.ufsEquipamento ? periodo.ufsEquipamento.length : ''}) UFs</TitleTableCell>
                <TitleTableCell align={'right'}>({periodo && periodo.ufsMaoDeObra ? periodo.ufsMaoDeObra.length : ''}) UFs</TitleTableCell>
                <TitleTableCell align={'right'}>({periodo && periodo.ufsComposicao ? periodo.ufsComposicao.length : ''}) UFs</TitleTableCell>
                {sistema.toUpperCase() === 'SINAPI' && <TitleTableCell align={'right'}>({periodo && periodo.ufsEncargosSociais ? periodo.ufsEncargosSociais.length : ''}) UFs</TitleTableCell>}
              </TableRow>
              <TableRow>
                <TitleTableCell align={'right'}>UF: QTD</TitleTableCell>
                <TitleTableCell align={'right'}>UF: QTD</TitleTableCell>
                <TitleTableCell align={'right'}>UF: QTD</TitleTableCell>
                <TitleTableCell align={'right'}>UF: QTD</TitleTableCell>
                {sistema.toUpperCase() === 'SINAPI' && <TitleTableCell align={'right'}>UF: QTD</TitleTableCell>}
              </TableRow>
            </TableHead>
            }
            <TableBody>
              <TableRow key={'insumos'}>
                <TableCell valign={'top'}>
                  {periodo && periodo.ufsMaterial && periodo.ufsMaterial
                    .map((p) => (
                      <Typography align={'right'} variant={'inherit'}>
                        {p.first}: {p.second}
                      </Typography>
                    ))}
                </TableCell>
                <TableCell valign={'top'}>
                  {periodo && periodo.ufsEquipamento && periodo.ufsEquipamento
                    .map((p) => (
                      <Typography align={'right'} variant={'inherit'}>
                        {p.first}: {p.second}
                      </Typography>
                    ))}
                </TableCell>
                <TableCell valign={'top'}>
                  {periodo && periodo.ufsMaoDeObra && periodo.ufsMaoDeObra
                    .map((p) => (
                      <Typography align={'right'} variant={'inherit'}>
                        {p.first}: {p.second}
                      </Typography>
                    ))}
                </TableCell>
                <TableCell valign={'top'}>
                  {periodo && periodo.ufsComposicao && periodo.ufsComposicao
                    .map((p) => (
                      <Typography align={'right'} variant={'inherit'}>
                        {p.first}: {p.second}
                      </Typography>
                    ))}
                </TableCell>
                <TableCell valign={'top'}>
                  {periodo && periodo.ufsEncargosSociais && periodo.ufsEncargosSociais
                    .map((p) => (
                      <Typography align={'right'} variant={'inherit'}>
                        {p.first}: {p.second}
                      </Typography>
                    ))}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          { periodo &&
            <Table><TableHead>
              <TableRow>
                <TitleTableCell>Arquivos Baixados</TitleTableCell>
              </TableRow>
              <TableRow>
                <TitleTableCell>UF</TitleTableCell>
                <TitleTableCell>Arquivos</TitleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                { Object.entries(periodo.mapaArquivos).sort().map((it) => <StyledTableRow key={it[0]}>
                <TableCell>
                    <Typography variant={'inherit'}>
                    {it[0]}
                  </Typography>
                  </TableCell>
                <TableCell>
                    <List>{ it[1].map((a) =>
                    <ListItem key={a}>{a}
                      </ListItem>
                  )}
                  </List>
                  </TableCell>
              </StyledTableRow>)
              }
              </TableBody>
            </Table>
          }

        </DialogContenText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onConfirmar()}
          variant='contained'
          color='primary'
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ModalDetalharPeriodo.propTypes = {
  seExibe: PropTypes.bool,
  onConfirmar: PropTypes.func,
  onCancelar: PropTypes.func
}

export default ModalDetalharPeriodo
