import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { TIPO_INSUMO_COMPOSICAO_DESTE_ORCAMENTO, TIPO_INSUMO_COMPOSICAO_REFERENCIA_PRECO } from '../composicoesAuxiliares'
import Box from '@material-ui/core/Box'
import Select from 'react-select'
import { defaultStyleReactSelect, errorStyleReactSelect } from '../../../../../../../../js/util'
import PropsTypes from 'prop-types'
import _ from 'lodash'
import { Button, withStyles } from '@material-ui/core'
import StyledDrawer from '../../../../../../../comum/StyledDrawer'
import PesquisarComposicaoAuxiliarReferenciaPreco from '../PesquisarComposicaoAuxiliarReferenciaPreco'
import styles from '../../../styles'

const ComposicaoAdicionar = ({
  classes,
  tipoComposicao,
  composicao,
  composicaoEmpty,
  composicoes,
  handleChange,
  nomeAtributo,
  codigoComposicao
}) => {
  const [exibirDrawerAdicionar, setExibirDrawerAdicionar] = useState(false)
  const [composicaoReferenciaPreco, setComposicaoReferenciaPreco] = useState(null)

  const handleChangeComposicaoReferenciaPrecoSelecionado = (composicao, fit) => {
    setComposicaoReferenciaPreco(composicao)
    handleChange(composicao, nomeAtributo)
    handleChange(fit, 'fit')
  }

  return (
    <>
      {
        tipoComposicao === TIPO_INSUMO_COMPOSICAO_DESTE_ORCAMENTO.value && (
          <Box mt={1}>
            <Select
              datacy-id='tempoFixo'
              id='tempoFixo'
              classNamePrefix='select'
              placeholder={`Selecione a composição${composicaoEmpty ? ' (Preenchimento obrigatório)' : ''}`}
              options={composicoes.map((composicao) => ({ ...composicao, label: `${composicao.codigo} - ${composicao.descricao}` }))}
              onChange={composicao => handleChange(composicao, nomeAtributo)}
              styles={composicaoEmpty ? errorStyleReactSelect : defaultStyleReactSelect}
              value={composicao}
            />
          </Box>
        )
      }
      {
        tipoComposicao === TIPO_INSUMO_COMPOSICAO_REFERENCIA_PRECO.value && (
          <Box mt={1}>
            {!_.isNil(composicaoReferenciaPreco) &&
            <Typography variant='button' gutterBottom style={{ marginRight: 10 }}>
              {`${composicaoReferenciaPreco.codigo} - ${composicaoReferenciaPreco.descricao}`}
            </Typography>
            }
            
            {
              composicaoReferenciaPreco && codigoComposicao && composicaoReferenciaPreco.codigo === codigoComposicao && (
                <Typography variant="body2" color="error">
                  O código da composição selecionada é igual ao código do serviço atual. Selecione outra composição de transporte!
                </Typography>
              )
            }

            <Button
              color='primary'
              variant='outlined'
              size='small'
              onClick={() => setExibirDrawerAdicionar(true)}
            >
              Selecionar composição
            </Button>
            {
              exibirDrawerAdicionar && (
                <StyledDrawer
                  PaperProps={{ className: classes.drawerEdicao }}
                  open
                  anchor='right'>
                  <PesquisarComposicaoAuxiliarReferenciaPreco
                    codigoComposicao={null}
                    onCancelar={() => setExibirDrawerAdicionar(false)}
                    isPesquisaComposicaoEmTempoFixo
                    onChangeComposicaoSelecionada={handleChangeComposicaoReferenciaPrecoSelecionado}
                  />
                </StyledDrawer>
              )
            }
          </Box>
        )
      }
      
    </>
  )
}

ComposicaoAdicionar.propTypes = {
  classes: PropsTypes.object,
  tipoComposicao: PropsTypes.string,
  composicao: PropsTypes.object,
  composicaoEmpty: PropsTypes.bool,
  composicoes: PropsTypes.array,
  handleChange: PropsTypes.func.isRequired,
  nomeAtributo: PropsTypes.string,
  codigoComposicao: PropsTypes.string
}

export default withStyles(styles)(ComposicaoAdicionar)
