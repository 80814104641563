import React from 'react'
import { TableBody } from '@material-ui/core'
import PropTypes from 'prop-types'
import ItemCurvaAbcInsumoRow from './ItemCurvaAbcInsumoRow'
import { paginar } from '../../../../js/util'
import NenhumItemCadastrado from '../../../comum/NenhumItemCadastrado'

const CorpoTabelaCurvaAbcInsumo = ({ insumos, registrosPorPagina, pagina, onItemMemoriaCalculoChange }) => {
  return (
    <React.Fragment>
      <TableBody>
        {insumos.length === 0 && <NenhumItemCadastrado variant='body2' mensagem='Nenhum item encontrado' />}
        {paginar(insumos, pagina, registrosPorPagina).map((itemCurva) => (
          <ItemCurvaAbcInsumoRow
            key={itemCurva.codigoServico}
            item={itemCurva}
            onItemMemoriaCalculoChange={onItemMemoriaCalculoChange}
          />
        ))}
      </TableBody>
    </React.Fragment>
  )
}

CorpoTabelaCurvaAbcInsumo.propTypes = {
  insumos: PropTypes.array.isRequired,
  registrosPorPagina: PropTypes.number,
  pagina: PropTypes.number,
  onItemMemoriaCalculoChange: PropTypes.func.isRequired
}

export default CorpoTabelaCurvaAbcInsumo
