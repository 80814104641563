import { CONCLUIR_ORCAMENTO_CONTEXTO, DEFINIR_CONTEXTO } from '../actions/types'

const reducerContexto = (state = null, action) => {
  switch (action.type) {
    case DEFINIR_CONTEXTO:
      return action.payload
    case CONCLUIR_ORCAMENTO_CONTEXTO:
      return { orcamento: {...state.orcamento, concluido: true}, empreendimento: state.empreendimento, obra: state.obra }
    default:
      return state
  }
}

export default reducerContexto
