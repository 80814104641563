import React from 'react'
import WarningIcon from '@material-ui/icons/Warning'
import Alert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { context_httpdelete } from '../../../../../../../js/httpRequest'
import { showSuccessMsg } from '../../../../../../../js/util'
import ModalConfirmacao from '../../../../../../comum/ModalConfirmacao'

const ModalExcluirTipologiaCombinada = ({ contexto, tipologiaCombinada, fechar, onComplete }) => {
  const handleConfirmar = async () => {
    const result = await context_httpdelete(`analise-conteudo/tipologias-combinadas/${tipologiaCombinada.codigo}`, contexto)
    if (result) {
      showSuccessMsg('Tipologia combinada excluída')
      onComplete()
    }
  }

  return (
    <ModalConfirmacao
      open={Boolean(tipologiaCombinada)}
      onClose={fechar}
      confirmar={handleConfirmar}
      titulo={
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <WarningIcon color='inherit' style={{ paddingRight: 4 }} />Excluir Tipologia Combinada
        </span>
      }
    >
      <Alert severity='warning'>
        Deseja realmente excluir a tipologia combinada <b>{tipologiaCombinada && tipologiaCombinada.nome}</b>?
      </Alert>
    </ModalConfirmacao>
  )
}

ModalExcluirTipologiaCombinada.propTypes = {
  tipologiaCombinada: PropTypes.object,
  fechar: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  contexto: PropTypes.object
}

const mapStateToProps = ({ contexto }) => ({ contexto })
export default connect(mapStateToProps)(ModalExcluirTipologiaCombinada)
