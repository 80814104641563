import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import buscarOrcamentos from '../../../../../../../actions/orcamentos/buscarOrcamentos'
import { CAMPO_EXPRESSAO_POR_UNIDADE, CAMPO_FILTRO_EXPRESSOES, CAMPO_NOME_DA_EXPRESSAO, createState, sleepAutocomplete } from '../../../analise-conteudo'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../../../../../../js/operationProgressUtil'
import { showSuccessMsg, textNormalize } from '../../../../../../../js/util'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import _ from 'lodash'
import { context_httppost } from '../../../../../../../js/httpRequest'
import CampoFiltro from '../../comum/campos/CampoFiltro'
import CampoUnidades from '../../comum/campos/CampoUnidades'
import CampoEmpreendimentos from '../../comum/campos/CampoEmpreendimentos'
import CampoOrcamentos from '../../comum/campos/CampoOrcamentos'
import CampoBusca from '../../comum/campos/CampoBusca'
import PaperBox from '../../../../../../comum/PaperBox'
import TabelaVincularExpressoes from '../../comum/vincular-expressao/TabelaVincularExpressoes'
import buscarTiposUnidades from '../../../../../../../actions/temas/buscarTiposUnidades'

const defaultState = {
  unidade: null,
  empreendimento: null,
  orcamento: null,
  termo: '',
  unidadeEmpty: false,
  empreendimentoEmpty: false,
  carregandoOrcamentos: false,
  filtro: CAMPO_EXPRESSAO_POR_UNIDADE,
  orcamentos: [],
  expressoes: [],
  expressoesFilter: [],
  expressoesSelecionadas: [],
  empreendimentos: [],
  expressoesCriteriosVinculados: []
}

class ImportarExpressao extends Component {
  static propTypes = {
    classes: PropTypes.object,
    contexto: PropTypes.object,
    onRecarregarExpressoes: PropTypes.func.isRequired,
    onFecharDrawer: PropTypes.func.isRequired,
    buscarTiposUnidades: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = defaultState
    this.handleCampoBuscaChange = this.handleCampoBuscaChange.bind(this)
    this.handleCampoUnidade = this.handleCampoUnidade.bind(this)
    this.handleCampoOrcamento = this.handleCampoOrcamento.bind(this)
    this.handleCampoEmpreendimento = this.handleCampoEmpreendimento.bind(this)
    this.handleSelecionarExpressao = this.handleSelecionarExpressao.bind(this)
    this.handleFiltrarExpressoesPorTermo = this.handleFiltrarExpressoesPorTermo.bind(this)
    this.buscarPorNomeDaExpressao = this.buscarPorNomeDaExpressao.bind(this)
  }

  componentDidMount () {
    if(this.props.contexto) {
      this.props.buscarTiposUnidades()
    }
  }

  opcoesImportacoes = () => {
    const opcoes = [ ...CAMPO_FILTRO_EXPRESSOES ]
    opcoes.shift()
    return opcoes
  }

  async buscarOrcamentos() {
    const { empreendimento } = this.state
    this.setState({ carregandoOrcamentos: true })
    if(empreendimento) {
      const response = await axios.get(`/api/empreendimentos/${empreendimento.cod}/orcamentos`, SLOW_REQUEST_CONFIG)
      await sleepAutocomplete(1e2)
      this.setState({
        orcamentos: response.data,
        carregandoOrcamentos: false
      })
    }
  }

  handleSelecionarExpressao(event, expressao) {
    const { expressoesSelecionadas } = this.state
    const expressaoVinculo = {
      codExpressao: expressao.codExpressao,
      nomeExpressao: expressao.nomeExpressao,
      descricaoExpressao: expressao.descricaoExpressao
    }
    if(event.target.checked) {
      this.setState({expressoesSelecionadas: [...expressoesSelecionadas, expressaoVinculo]})
    } else {
      const novaLista = expressoesSelecionadas.filter(it => it.codExpressao !== expressao.codExpressao)
      this.setState({ expressoesSelecionadas: novaLista })
    }
  }

  handleCampoUnidade = (valor) => {
    if(!_.isNil(valor)) {
      this.setState(
        createState('unidade', valor), () => this.buscarEmpreendimentosPorUnidade()
      )
    }
  }

  handleCampoEmpreendimento(value) {
    this.setState(
      createState('empreendimento', value), () => this.buscarOrcamentos()
    )
  }

  handleCampoOrcamento = (values) => {
    this.setState({orcamento: values}, () => {
      const { empreendimento, orcamento } = this.state
      this.buscarExpressoes(empreendimento, orcamento)
    })
  }

  handleCampoBuscaChange = (value) => {
    this.setState({'termo': value})
  }

  handleCampoFiltro = (valor) => {
    this.setState({'filtro': valor}, () => {
      this.setState({
        expressoesFilter: [],
        termo: ''
      })
    })
  }

  async buscarEmpreendimentosPorUnidade() {
    const { unidade } = this.state
    const response = await axios.get(`/api/empreendimentos/unidade/${unidade.cod}`, SLOW_REQUEST_CONFIG)
    this.setState({empreendimentos: response.data })
  }

  buscarExpressoes = async (empreendimento, orcamento) => {
    if(empreendimento && orcamento) {
      const response = await axios.get(`/api/empreendimentos/${empreendimento.cod}/orcamentos/${orcamento.cod}/analise-conteudo/expressoes`, SLOW_REQUEST_CONFIG)
      if(response) {
        this.setState({
          expressoes: response.data,
          expressoesFilter: response.data,
        })
      }
    } else {
      this.setState({
        expressoes: [],
        termo: ''
      })
    }
  }

  buscarPorNomeDaExpressao = async () => {
    const { termo } = this.state
    const response = await axios.get(`/api/expressoes?termo=${termo}`, SLOW_REQUEST_CONFIG)
    if(response) {
      this.setState({ expressoesFilter: response.data })
    }
  }

  handleFiltrarExpressoesPorTermo() {
    const { expressoes, termo } = this.state
    if (termo.length) {
      const novaLista = expressoes.filter(
        expressao => textNormalize(expressao.nomeExpressao).toLowerCase().search(textNormalize(termo).toLowerCase()) !== -1
      )
      this.setState({ expressoesFilter: novaLista })
    } else {
      this.setState({ expressoesFilter: expressoes })
    }
  }

  async salvarImportarExpressoes() {
    const { contexto, onRecarregarExpressoes, onFecharDrawer } = this.props
    const { expressoesSelecionadas } = this.state
    const novasExpressoesSelecionadas = expressoesSelecionadas.filter(it => _.isNil(it.codigoOrcamento))
    const expressoesImportadas = await context_httppost('analise-conteudo/expressoes/importar', contexto, novasExpressoesSelecionadas)
    if(expressoesImportadas) {
      showSuccessMsg('Dados registrados')
      onRecarregarExpressoes()
      onFecharDrawer()
    }
  }

  render () {
    const { classes, onFecharDrawer } = this.props
    const {
      termo,
      unidade,
      unidadeEmpty,
      empreendimentos,
      empreendimento,
      orcamentos,
      expressoesFilter,
      filtro,
      expressoesSelecionadas,
      carregandoOrcamentos
    } = this.state

    return (
      <React.Fragment>
        <Typography variant='h6' style={{'marginBottom': 20}}>Importar expressão</Typography>
        <CampoFiltro
          filtro={filtro}
          values={this.opcoesImportacoes()}
          onFiltroChange={this.handleCampoFiltro}
        />

        { filtro === CAMPO_EXPRESSAO_POR_UNIDADE && (
          <React.Fragment>
            <CampoUnidades
              unidade={unidade}
              unidadeEmpty={unidadeEmpty}
              unidadeUsuarioLogadoPreSelecionada={true}
              onUnidadeChange={this.handleCampoUnidade}
            />

            <CampoEmpreendimentos
              empreendimentos={empreendimentos}
              empreendimento={empreendimento}
              onCampoEmpreendimento={this.handleCampoEmpreendimento}
            />

            <CampoOrcamentos
              orcamentos={orcamentos}
              onCampoOrcamento={this.handleCampoOrcamento}
              carregandoOrcamentos={carregandoOrcamentos}
            />
          </React.Fragment>)
        }

        { filtro === CAMPO_NOME_DA_EXPRESSAO && (
          <CampoBusca
            value={termo}
            name={'termo'}
            placeholder={'Digite o nome da expressão'}
            buttonName={'Pesquisar'}
            onCampoChange={this.handleCampoBuscaChange}
            onClick={this.buscarPorNomeDaExpressao}
          />)
        }

        <PaperBox style={{marginTop: 15}}>
          {filtro !== CAMPO_NOME_DA_EXPRESSAO && (
            <CampoBusca
              value={termo}
              name={'termo'}
              placeholder={'Filtrar por nome'}
              buttonName={'Filtrar'}
              onCampoChange={this.handleCampoBuscaChange}
              onClick={this.handleFiltrarExpressoesPorTermo}
            />
          )}

          <Typography variant='h6' className={classes.subtituloAssociarTema}>Expressões - Marque o que deseja associar ao tema</Typography>
          <TabelaVincularExpressoes
            classes={classes}
            expressoes={expressoesFilter}
            expressoesSelecionadas={expressoesSelecionadas}
            onSelecionarExpressao={this.handleSelecionarExpressao}
          />
        </PaperBox>

        <Box mt={2}>
          <Button
            variant='contained'
            color='primary'
            style={{marginRight: 10}}
            onClick={() => this.salvarImportarExpressoes()}
          > Importar</Button>
          <Button variant='outlined' color='primary' onClick={onFecharDrawer}>Cancelar</Button>
        </Box>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  contexto: state.contexto,
  orcamentos: state.orcamentos
})

export default connect(mapStateToProps, { buscarOrcamentos, buscarTiposUnidades })(ImportarExpressao)
