import React from 'react'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { withRouter } from 'react-router-dom'
import BreadCrumbLink from '../../../../comum/BreadCrumbLink'
import { styles } from '../../../../comum/BreadCrumb'
import { withStyles } from '@material-ui/styles'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography/Typography'

const BreadCrumbItemCurvaAbc = ({ match, classes }) => {
  const numeroItem = match.params.numeroItem
  return (
    <div className={classes.breadcrumb}>
      <Breadcrumbs datacy-id='migalha' separator='›' aria-label='breadcrumb' className={classes.brdcrumb_orc}>
        <BreadCrumbLink key='inicio' to='/' desc='Início' />
        <BreadCrumbLink key='curva-abc' to={`${match.url.split('abc/')[0]}abc`} desc='Curva ABC' />
        <Typography style={{ fontWeight: 500 }}>Item {numeroItem}</Typography>
      </Breadcrumbs>
    </div>
  )
}

BreadCrumbItemCurvaAbc.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  classes: PropTypes.object
}

export default withStyles(styles)(withRouter(BreadCrumbItemCurvaAbc))
