import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { FormControl, Typography } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import CampoCriterio from './CampoCriterio'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import DeleteIcon from '@material-ui/icons/Delete'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import styles from '../../../../styles'
import { operadorCriterio } from '../../../../analise-conteudo'

class CriteriosCadastrados extends Component {
  static propTypes = {
    value: PropTypes.array.isRequired,
    onCriterioChange: PropTypes.func.isRequired,
    onCriterioExcluido: PropTypes.func.isRequired,
    onSalvarCriterioClick: PropTypes.func.isRequired,
    expressoesDisponiveis: PropTypes.array.isRequired,
    operadorExpressao: PropTypes.string.isRequired,
    classes: PropTypes.object
  }
  constructor (props) {
    super(props)
    this.handleTextoChange = this.handleTextoChange.bind(this)
    this.handleCampoChange = this.handleCampoChange.bind(this)
  }

  handleTextoChange (event, value) {
    const { onCriterioChange } = this.props
    onCriterioChange({ ...value, texto: event.target.value })
  }

  handleExpressaoCriterioChange (event, value) {
    const { onCriterioChange } = this.props
    onCriterioChange({ ...value, codigoExpressaoCriterio: event.target.value })
  }

  handleCampoChange (event, value) {
    const { onCriterioChange } = this.props
    onCriterioChange({ ...value, campo: event.target.value })
  }

  criterioSimples(criterio) {
    const { classes } = this.props
    return (
      <FormControl variant='outlined' fullWidth>
        <Typography variant='subtitle1'>{criterio.tipo.label}</Typography>
        <TextField
          variant='outlined'
          autoFocus
          value={criterio.texto || ''}
          onChange={(e) => this.handleTextoChange(e, criterio)}
          classes={{root: classes.textfieldCriterio}}
          InputProps={{
            endAdornment: <InputAdornment position='start'>
              <CampoCriterio
                onChange={(e) => this.handleCampoChange(e, criterio)}
                value={criterio.campo}
              />
            </InputAdornment>,
          }}
        />
      </FormControl>
    )
  }

  criterioExpressao(criterio) {
    const { classes, expressoesDisponiveis } = this.props
    return (
      <FormControl variant='outlined' fullWidth>
        <Typography variant='subtitle1'>Expressão</Typography>
        <Select
          value={criterio.codigoExpressaoCriterio || ''}
          onChange={(e) => this.handleExpressaoCriterioChange(e, criterio)}
          size='small'
          classes={{root: classes.textfieldCriterio}}
        >
          {
            expressoesDisponiveis.map((it) => (
              <MenuItem key={it.codigo} value={it.codigo}>
                { it.nome }
              </MenuItem>
            ))
          }
          {(expressoesDisponiveis.length === 0) && <MenuItem value=''>Nenhuma expressão cadastrada</MenuItem>}
        </Select>
      </FormControl>
    )
  }

  render () {
    const {
      value,
      onCriterioExcluido,
      onSalvarCriterioClick,
      operadorExpressao,
      classes
    } = this.props
    return (
      value.map((it, index) => (
        <div key={`${it.codigo}`} className={classes.cardCriterio}>
          <Paper className={classes.paperCriterio} variant='outlined'>
          <div className={classes.operadorCriterio}>
            {
              operadorCriterio(index, it.tipo, operadorExpressao)
            }
          </div>
            <div className={classes.conteudoCriterio}>
              {
                it.tipo.expressao ? this.criterioExpressao(it) : this.criterioSimples(it)
              }

             <div className={classes.acoesCriterio}>
             {
                it.alterado && <Button color='primary' onClick={() => onSalvarCriterioClick(it)}><SaveIcon /></Button>
              }
              <Button color='default' onClick={() => onCriterioExcluido(it)}>
                <DeleteIcon />
              </Button>
             </div>
            </div>
          </Paper>
        </div>
      ))
    )
  }
}

export default withStyles(styles)(CriteriosCadastrados)
