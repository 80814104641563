import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import Template from '../../../../layout/Template'
import { formatarNumeroParaMoedaBR, mapStateToPropsContexto, showErrorMsg, showSuccessMsg } from '../../../../../js/util'
import { connect } from 'react-redux'
import { context_httpget, context_httpput } from '../../../../../js/httpRequest'
import _ from 'lodash'
import Carregando from '../../../../comum/Carregando'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import PaperBox from '../../../../comum/PaperBox'
import TituloVoltar from '../../../../comum/TituloVoltar'
import StyledSwitch from '../../../../comum/StyledSwitch'
import RelatorioComposicao from '../../relatorios/precos/composicao/RelatorioComposicao'
import { Checkbox, FormControlLabel, withStyles } from '@material-ui/core'
import styles from './styles'
import Button from '@material-ui/core/Button'
import RelatorioEquipamento from './RelatorioEquipamento'
import RelatorioMaterial from './RelatorioMaterial'
import RelatorioMaoDeObra from './RelatorioMaoDeObra'
import BreadCrumbItemCurvaAbc from './BreadCrumbItemCurvaAbc'
import CampoObservacaoItemCurvaAbc from './CampoObservacaoItemCurvaAbc'
import CampoBdiItemCurvaAbc from './CampoBdiItemCurvaAbc'
import CampoCodigoServicoItemCurvaAbc from './CampoCodigoServicoItemCurvaAbc'

const CAMPO_INCLUIR_NA_AMOSTRA = 'campo-incluir-na-amostra'

class ItemCurvaAbc extends Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    contexto: PropTypes.object,
    classes: PropTypes.object,
  }
  constructor(props) {
    super(props)
    this.state = {
      item: null,
      composicao: null,
      equipamento: null,
      material: null,
      maoDeObra: null,
      metodoCalculoSobrepreco: null,
    }
    this.handleBackClick = this.handleBackClick.bind(this)
    this.handleNextClick = this.handleNextClick.bind(this)
    this.handleDadosAlterados = this.handleDadosAlterados.bind(this)
    this.handleAtualizarItemCurvaABCComposicaoDuplicada =
      this.handleAtualizarItemCurvaABCComposicaoDuplicada.bind(this)
    this.handleItemAtualizado = this.handleItemAtualizado.bind(this)
  }

  componentDidMount() {
    if (
      this.props.match &&
      this.props.match.params.numeroItem &&
      this.props.contexto
    ) {
      this.recuperarItem()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.contexto !== this.props.contexto ||
      this.props.match.params.numeroItem !==
        prevProps.match.params.numeroItem ||
      (!_.isNil(prevState.composicao) &&
        !_.isNil(this.state.composicao) &&
        prevState.composicao.codigo !== this.state.composicao.codigo)
    ) {
      this.recuperarItem()
    }
  }

  async recuperarItem() {
    const { match, contexto } = this.props
    const { numeroItem } = match.params
    const result = await context_httpget(
      `curva-abc/itens/${numeroItem}`,
      contexto
    )
    console.log(result)
    if (result) {
      this.setState({
        item: result.itemCurvaAbc,
        composicao: result.composicao,
        equipamento: result.equipamento,
        material: result.material,
        maoDeObra: result.maoDeObra,
        metodoCalculoSobrepreco: result.metodoCalculoSobrepreco,
      })
    }
  }

  handleAtualizarItemCurvaABCComposicaoDuplicada = () => {
    this.recuperarItem()
  }

  precoParcialReferenciaExibir = (item) =>
    _.isNil(item.sistemaReferencia)
      ? '-'
      : formatarNumeroParaMoedaBR(item.precoParcialReferencia, 2, 2)

  temSobrepreco = (valorSobrepreco) => {
    const { metodoCalculoSobrepreco } = this.state
    const temSobreprecoPositivo = valorSobrepreco > 0.009
    const temSobreprecoNegativo = valorSobrepreco < 0
    return Boolean(
      temSobreprecoPositivo ||
        (temSobreprecoNegativo &&
          metodoCalculoSobrepreco &&
          metodoCalculoSobrepreco.consideraSobreprecoNegativo)
    )
  }

  valorSobreprecoExibir = (valorSobrepreco) => {
    return this.temSobrepreco(valorSobrepreco)
      ? formatarNumeroParaMoedaBR(valorSobrepreco, 2, 2)
      : ' - '
  }

  handleBackClick() {
    const { item } = this.state
    if (item.numero > 1)
      this.props.history.push(
        this.props.match.url.replace(
          `itens/${item.numero}`,
          `itens/${item.numero - 1}`
        )
      )
  }

  handleNextClick() {
    const { contexto } = this.props
    const { item } = this.state
    if (item.numero < contexto.orcamento.totalItensCurvaAbc)
      this.props.history.push(
        this.props.match.url.replace(
          `itens/${item.numero}`,
          `itens/${item.numero + 1}`
        )
      )
  }

  urlBase = () => {
    const { params } = this.props.match
    return `/empreendimentos/${params.idEmpreendimento}/obras/${params.idObra}/orcamentos/${params.idOrcamento}`
  }

  handleClickVoltar = () => {
    this.props.history.push(`${this.urlBase()}/abc`)
  }

  handleDadosAlterados = () => {
    this.recuperarItem()
  }

  handleItemAlterado = async () => {
    const { match, contexto } = this.props
    const { numeroItem } = match.params
    const result = await context_httpget(
      `curva-abc/itens/${numeroItem}`,
      contexto,
      () => {},
      true
    )
    if (result) {
      this.setState({
        item: result.itemCurvaAbc,
      })
    }
  }

  handleItemAtualizado = (itemOriginal, novoItem) => {
    if (itemOriginal === novoItem) {
      return novoItem
    }

    this.setState({ item: novoItem })
  }

  handleChange = (event) => {
    const { name } = event.target

    switch (name) {
      case CAMPO_INCLUIR_NA_AMOSTRA:
        this.salvarIncluirNaAmostra()
        break
      default:
        return
    }
  }

  salvarIncluirNaAmostra = () => {
    const { contexto } = this.props
    const { item } = this.state

    context_httpput(`curva-abc/itens/${item.id}/incluir-na-amostra`, contexto)
      .then(() => {
        showSuccessMsg('Incluir na Amostra salvo com sucesso.')
        this.handleItemAtualizado(item, {
          ...item,
          incluirNaAmostra: !item.incluirNaAmostra,
        })
      })
      .catch(() => showErrorMsg('Erro ao salvar incluir na amostra.'))
  }

  salvarAnaliseConcluida = () => {
    const { contexto } = this.props
    const { item } = this.state

    context_httpput(`curva-abc/itens/${item.id}/analise-concluida`, contexto)
      .then(() => {
        showSuccessMsg('Análise salva com sucesso.')
        this.handleItemAtualizado(item, {
          ...item,
          analiseConcluida: !item.analiseConcluida,
        })
      })
      .catch(() => showErrorMsg('Erro ao salvar análise.'))
  }

  campoIncluirNaAmostra = () => {
    const { classes } = this.props
    const { item } = this.state

    return (
      <FormControlLabel
        className={classes.btnAmostra}
        control={
          <Checkbox
            color={'primary'}
            inputProps={{ 'aria-label': 'secondary checkbox' }}
            className={classes.checkStyle}
            name={CAMPO_INCLUIR_NA_AMOSTRA}
            checked={item.incluirNaAmostra}
            onChange={(e) => this.handleChange(e)}
            disabled={item.analiseConcluida}
          />
        }
        label=" Incluir na amostra"
      />
    )
  }

  render() {
    const { item, composicao, equipamento, material, maoDeObra } = this.state
    const {contexto} = this.props

    if (_.isNil(item)) {
      return <Carregando />
    }

    const { classes } = this.props

    return (
      <Template
        insidePaper={false}
        BreadCrumbPersonalizado={BreadCrumbItemCurvaAbc}
      >
        <div className={classes.navCabecalhoItem}>
          <TituloVoltar
            onClick={this.handleClickVoltar}
            label="Item da Curva ABC de Serviços"
          />
          <div className={classes.divCabecalhoItem}>
            <Button color="primary" onClick={this.handleBackClick}>
              <ArrowBackIcon /> Anterior
            </Button>
            <Typography>Item {item.numero}</Typography>
            <Button color="primary" onClick={this.handleNextClick}>
              Próximo <ArrowForwardIcon />
            </Button>
          </div>
        </div>

        <PaperBox className={classes.boxTopo}>
          <div className={classes.dadosItem}>
            <div className={classes.divCabecalhoItem3}>
              <Typography className={classes.tituloBoxItem}>
                Código do serviço:
              </Typography>
              <Typography className={classes.tituloBoxItem}>BDI:</Typography>
              <Typography className={classes.tituloBoxItem}>
                Descrição:
              </Typography>
            </div>
            <div className={classes.divCabecalhoItem3}>
              <div className={classes.divCabecalhoItem}>
                <CampoCodigoServicoItemCurvaAbc
                  itemCurvaAbc={item}
                  onItemAlterado={this.handleDadosAlterados}
                />
              </div>
              <div className={classes.divCabecalhoItem}>
                <CampoBdiItemCurvaAbc
                  itemCurvaAbc={item}
                  onItemAlterado={this.handleItemAtualizado}
                />
              </div>
              <Typography className={classes.textoItemComum}>
                {item.descricaoOrcamento}
              </Typography>
            </div>

            <div className={classes.divCabecalhoItem6}>
              <Typography className={classes.tituloBoxItem2}>
                Quantidade:
              </Typography>
              <Typography className={classes.tituloBoxItem2}>
                Unidade:
              </Typography>
              <Typography className={classes.tituloBoxItem2}>
                Preço Unitário (BDI):
              </Typography>
              <Typography className={classes.tituloBoxItem2}>
                Preço Parcial:
              </Typography>
              <Typography className={classes.tituloBoxItem2}>
                Percentual:
              </Typography>
              <Typography className={classes.tituloBoxItem2}>
                Percentual Acumulado:
              </Typography>
            </div>
            <div className={classes.divCabecalhoItem6}>
              <Typography className={classes.itensCabecalho}>
                {item.quantidadeFormatado}
              </Typography>
              <Typography className={classes.itensCabecalho}>
                {item.unidadeOrcamento}
              </Typography>
              <Typography className={classes.itensCabecalho}>
                {item.precoUnitarioFormatado}
              </Typography>
              <Typography className={classes.itensCabecalho}>
                {item.precoParcialCalculadoFormatado}
              </Typography>
              <Typography className={classes.itensCabecalho}>
                {item.percentualFormatado}
              </Typography>
              <Typography className={classes.itensCabecalho}>
                {item.percentualAcumuladoFormatado}
              </Typography>
            </div>

            <div className={classes.divCabecalhoItem6}>
              <Typography className={classes.tituloBoxItem2}>
                Ref. Preço Parcial:
              </Typography>
              <Typography className={classes.tituloBoxItem2}>
                Sobrepreço:
              </Typography>
            </div>
            <div className={classes.divCabecalhoItem6}>
              <Typography className={classes.itensCabecalho}>
                
                {this.precoParcialReferenciaExibir(item)}
              </Typography>
              <Typography className={classes.itensCabecalho}>
                {this.valorSobreprecoExibir(item.valorSobrepreco)}
              </Typography>
            </div>

            <div className={classes.divCabecalhoItem}>
              <Typography className={classes.tituloBoxItem3}>
                Observação:
              </Typography>
            </div>
            <div className={classes.divCabecalhoItem}>
              <CampoObservacaoItemCurvaAbc
                itemCurvaAbc={item}
                onItemAlterado={this.handleItemAtualizado}
              />
            </div>

            <div className={classes.divCabecalhoItem}>
              {this.campoIncluirNaAmostra()}
            </div>
          </div>

          <div className={classes.btnAcoesItem}>
            <FormControlLabel
              control={
                <StyledSwitch
                  color="primary"
                  checked={item.analiseConcluida}
                  onChange={() => this.salvarAnaliseConcluida()}
                />
              }
              label={
                item.analiseConcluida
                  ? 'Análise concluída'
                  : 'Análise não concluída'
              }
            />
          </div>
        </PaperBox>

        <PaperBox style={{ marginTop: 8 }}>
          {item.sistemaReferencia ? (
            _.isNil(composicao) ? (
              <>
                {equipamento && (
                  <RelatorioEquipamento 
                  equipamento={equipamento} 
                  contexto = {contexto}/>
                )}
                {material && (
                <RelatorioMaterial 
                contexto = {contexto}
                material={material} />
                )}

                {maoDeObra && (<RelatorioMaoDeObra 
                maoDeObra={maoDeObra} 
                contexto = {contexto}/>
                )}
              </>
            ) : (
              <RelatorioComposicao
                composicao={composicao}
                permitirEdicao
                onAtualizarItemCurvaABCComposicaoDuplicada={
                  this.handleAtualizarItemCurvaABCComposicaoDuplicada
                }
                onItemModificado={this.handleItemAlterado}
              />
            )
          ) : (
            <Typography>Serviço sem referência de preço.</Typography>
          )}
        </PaperBox>
      </Template>
    )
  }
}

export default connect(mapStateToPropsContexto)(
  withStyles(styles)(withRouter(ItemCurvaAbc))
)
