import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Portal from '@material-ui/core/Portal'

import styles from './layout/AppStyles'
import AppToolbar from './layout/AppToolbar'
import MessagesBar from './layout/MessagesBar'
import ProgressDialog from './layout/ProgressDialog'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import definirContexto from '../actions/definirContexto'
import { httpget } from '../js/httpRequest'
import { emptyFunction } from '../js/util'
import ReactGA from 'react-ga'
import { Typography } from '@material-ui/core'

export class App extends Component {
  static propTypes = {
    definirContexto: PropTypes.func
  }
  constructor (props) {
    super(props)
    this.state = {
      userMenuAnchorEl: null
    }
    this.redirect = this.redirect.bind(this)
    this.handleUserMenuOpen = this.handleUserMenuOpen.bind(this)
    this.handleUserMenuClose = this.handleUserMenuClose.bind(this)
  }

  componentDidMount () {
    this.recuperarContexto()
    if(window.location.href.indexOf('apps') > -1 || window.location.href.indexOf('prod') > -1 || window.location.href.indexOf('producao') > -1) {
      ReactGA.initialize(`${'UA-198782220-1'}`)
      ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }

  componentDidUpdate () {
    this.recuperarContexto()
  }

  empreendimentoAlterado () {
    const { contexto, match } = this.props
    const { idEmpreendimento } = match.params
    // eslint-disable-next-line
    return idEmpreendimento != contexto.empreendimento.cod
  }

  contextoTemObra () {
    const { contexto } = this.props
    return !_.isNil(contexto.obra) && !_.isNil(contexto.obra.cod)
  }

  urlTemObra () {
    const { idObra } = this.props.match.params
    return !_.isNil(idObra)
  }

  urlTemOrcamento () {
    const { idOrcamento } = this.props.match.params
    return !_.isNil(idOrcamento)
  }

  contextoTemOrcamento () {
    const { contexto } = this.props
    return !_.isNil(contexto.orcamento) && !_.isNil(contexto.orcamento.cod)
  }

  obraAlterada () {
    const { contexto, match } = this.props
    const { idObra } = match.params
    const contextoTemObra = this.contextoTemObra()
    const urlTemObra = this.urlTemObra()
    return (contextoTemObra && !urlTemObra) ||
      (urlTemObra && !contextoTemObra) ||
      (contextoTemObra && contexto.obra.cod != idObra) // eslint-disable-line
  }

  orcamentoAlterado () {
    const { contexto, match } = this.props
    const { idOrcamento } = match.params
    const contextoTemOrcamento = this.contextoTemOrcamento()
    const urlTemOrcamento = this.urlTemOrcamento()
    return (contextoTemOrcamento && !urlTemOrcamento) ||
      (urlTemOrcamento && !contextoTemOrcamento) ||
      (contextoTemOrcamento && contexto.orcamento.cod != idOrcamento) // eslint-disable-line
  }

  deveAtualizarContexto () {
    const { contexto } = this.props
    return contexto == null || this.empreendimentoAlterado() || this.obraAlterada() || this.orcamentoAlterado()
  }

  definirUrlContexto () {
    const { idEmpreendimento, idObra, idOrcamento } = this.props.match.params
    let url = `/api/contexto?idEmpreendimento=${idEmpreendimento}`
    if (!_.isNil(idObra)) url = `${url}&idObra=${idObra}`
    if (!_.isNil(idOrcamento)) url = `${url}&idOrcamento=${idOrcamento}`
    return url
  }

  async atualizarContexto () {
    const result = await httpget(this.definirUrlContexto(), emptyFunction)
    if (result) {
      this.props.definirContexto({ ...result })
    } else {
      this.props.definirContexto(null)
    }
  }

  recuperarContexto () {
    const { match } = this.props
    const { idEmpreendimento } = match.params
    const { contexto } = this.props

    if (idEmpreendimento == null) {
      if (contexto !== null) {
        this.props.definirContexto(null)
      }
      return
    }

    if (this.deveAtualizarContexto()) {
      this.atualizarContexto()
    }

  }

  handleUserMenuOpen (event) {
    this.setState({ userMenuAnchorEl: event.currentTarget })
  }

  handleUserMenuClose (path) {
    this.setState({ userMenuAnchorEl: null })
    this.redirect(path)
  }

  redirect (path) {
    if (path) this.props.history.push(path)
  }

  render () {
    const {
      classes, children, noAppToolbar, contexto
    } = this.props;
    const { userMenuAnchorEl } = this.state;

    const orcamentoNotFound = contexto === null && this.urlTemOrcamento();
  
    return (
      <div className={classes.appFrame}>
        {!noAppToolbar && (
          <AppToolbar
            userMenuAnchorEl={userMenuAnchorEl}
            onUserMenuOpen={this.handleUserMenuOpen}
            onUserMenuClose={this.handleUserMenuClose} />
        )}
        <main className={classes.content}>
          {!noAppToolbar && (<div className={classes.toolbar} />)}
          {orcamentoNotFound ? (
            <Typography variant="h6" style={{  marginTop: '20px' }}>
              Orçamento de código {this.props.match.params.idOrcamento} não foi encontrado.
            </Typography>
          ) : (
            <>
              {children}
              <Portal>
                <MessagesBar />
              </Portal>
              <ProgressDialog />
            </>
          )}
        </main>
      </div>
    );
  }
}

App.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  noAppToolbar: PropTypes.bool.isRequired,
  match: PropTypes.object,
  contexto: PropTypes.object
}

const materialUIEnhance = withStyles(styles)(App)

export const mapStateToProps = (state) => {
  const {
    authentication: { isUserAuthenticated },
    contexto
  } = state
  return {
    isUserAuthenticated,
    contexto
  }
}

export default connect(mapStateToProps, { definirContexto })(withRouter(materialUIEnhance))
