import React, { Component } from 'react'
import { Button, Typography, withStyles } from '@material-ui/core'
import RepeatIcon from '@material-ui/icons/Repeat'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Alert } from '@material-ui/lab'
import Template from '../../../layout/Template'
import { mapStateToPropsContexto, showSuccessMsg } from '../../../../js/util'
import ContainerTratamentoPossiveisErros from './ContainerTratamentoPossiveisErros'
import ModalConcluirTratamentoErros from './ModalConcluirTratamentoErros'
import ModalZeroErros from './ModalZeroErros'
import Cabecalho from '../orcamento-bruto/Cabecalho'
import PropTypes from 'prop-types'
import Carregando from '../../../comum/Carregando'
import styles from './style'
import ModalConfirmacao from '../../../comum/ModalConfirmacao'
import { context_httpget, context_httppatch, context_httpdelete } from '../../../../js/httpRequest'
import { connect } from 'react-redux'
import ModalExcluirCurvaAbc from '../curva-abc/ModalExcluirCurvaAbc'

const CODIGOS_DIFERENTES = {
  cod: 'CODIGOS_DIFERENTES',
  titulo: 'CÓDIGOS DIFERENTES',
  legenda: 'Corrige itens com mesma descrição, unidade e preço unitário, porém, com códigos diferentes.',
  atributosAgrupamento: ['codServico']
}
const DESCRICOES_DIFERENTES = {
  cod: 'DESCRICOES_DIFERENTES',
  titulo: 'DESCRIÇÕES DIFERENTES',
  legenda: 'Corrige itens com mesmos código, unidade e preço unitário, porém, com descrições diferentes.',
  atributosAgrupamento: ['descServico']
}
const UNIDADES_DIFERENTES = {
  cod: 'UNIDADES_DIFERENTES',
  titulo: 'UNIDADES DIFERENTES',
  legenda: 'Corrige itens com mesmos código, descrição e preço unitário, porém, com unidades diferentes.',
  atributosAgrupamento: ['unidade']
}
const CODIGOS_IGUAIS = {
  cod: 'CODIGOS_IGUAIS',
  titulo: 'CÓDIGOS IGUAIS',
  legenda: 'Corrige itens com mesmo código, porém, com descrições, preços unitários ou unidades diferentes.',
  atributosAgrupamento: ['descServico', 'unidade', 'precoUnitario']
}
const DESCRICOES_IGUAIS = {
  cod: 'DESCRICOES_IGUAIS',
  titulo: 'DESCRIÇÕES IGUAIS',
  legenda: 'Corrige itens com mesma descrição, porém, com códigos, preços unitários ou unidades diferentes.',
  atributosAgrupamento: ['codServico', 'unidade', 'precoUnitario']
}

const TIPOS_POSSIVEL_ERRO = [
  CODIGOS_DIFERENTES, DESCRICOES_DIFERENTES, UNIDADES_DIFERENTES, CODIGOS_IGUAIS, DESCRICOES_IGUAIS
]

class PossiveisErros extends Component {
  static propTypes = {
    contexto: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    classes: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      totalOcorrencias: null,
      resumo: [],
      tipoAberto: {},
      exibirModalConclusao: false,
      exibirModalZeroErros: false,
      exibirConfirmacaoReativacao: false,
      resumoOrcamento: null
      
    }
    this.handleTipoClick = this.handleTipoClick.bind(this)
    this.handleConfirmarConclusao = this.handleConfirmarConclusao.bind(this)
    this.handleConfirmarReativacao = this.handleConfirmarReativacao.bind(this)
    this.atualizarResumo = this.atualizarResumo.bind(this)
  }

  componentDidMount () {
    if (this.props.contexto) {
      this.recuperarResumo()
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.contexto !== this.props.contexto) {
      this.recuperarResumo()
    }
  }

  async handleConfirmarReativacao () {
    const { contexto } = this.props

    await context_httpdelete('curva-abc', contexto)
    showSuccessMsg('Curva ABC excluída com sucesso')
    
    await context_httppatch('', contexto, { concluir: false })
    this.setState({ exibirConfirmacaoReativacao: false})
    this.recuperarResumo()
    showSuccessMsg('Tratamento de possíveis erros reativado.')
  }

  handleTipoClick (tipo) {
    const { tipoAberto } = this.state
    if (tipoAberto === tipo) {
      this.setState({ tipoAberto: '' })
    } else {
      this.setState({ tipoAberto: tipo })
    }
  }

  async handleConfirmarConclusao () {
    const { contexto } = this.props
    const result = await context_httppatch('', contexto, { concluir: true })
    if (result) {
      this.props.history.push(this.props.match.url.replace('possiveis-erros', 'abc'))
    }
  }

  async recuperarResumo () {
    const { contexto } = this.props
    const res = await context_httpget('possiveis-erros/resumo', contexto)
    if (res) {
      this.atualizarResumo(res)
    }
  }

  atualizarResumo (resumo) {
    const totalOcorrencias = resumo.tipos.reduce((a, b) => a + b.quantidade, 0)
    this.setState({
      resumo: resumo.tipos,
      encerrado: resumo.concluido,
      totalOcorrencias,
      exibirModalZeroErros: totalOcorrencias === 0,
      resumoOrcamento: {...resumo.resumoOrcamento}
    })
  }

  render () {
    const { classes } = this.props
    const {
      tipoAberto,
      resumo,
      encerrado,
      exibirModalConclusao,
      exibirModalZeroErros,
      totalOcorrencias,
      resumoOrcamento
    } = this.state

    if (totalOcorrencias === null) {
      return <Carregando />
    }

    return (
      <Template>
        <Cabecalho
          resumoOrcamento={resumoOrcamento}
          numeroPossiveisErros={resumoOrcamento.numeroPossiveisErros}
          numeroPossiveisInconsistencias={resumoOrcamento.numeroPossiveisInconsistencias}
          titulo='Tratamento de erros'
        />

        <div className={classes.subtituloAcao}>
          <Typography variant='subtitle2'>
            Corrija possíveis erros nos itens de orçamento, escolha o tipo de ocorrência que deseja tratar:
          </Typography>
          {
          encerrado ? (
            <React.Fragment>
              <Alert severity="warning" style={{marginTop: 16}}>Para alterar ou corrigir uma ocorrência, clique para reativar o tratamento de erros</Alert>
              <Button color='primary' variant='contained' className={classes.btnConcluir} onClick={() => this.setState({ exibirConfirmacaoReativacao: true })}>
                 <RepeatIcon fontSize='small'/> Reativar o tratamento de erros
              </Button>
            </React.Fragment>
          ) : (
            <Button className={classes.btnConcluir} color='primary' variant='contained' onClick={() => this.setState({ exibirModalConclusao: true })}>
              <CheckCircleIcon fontSize='small' />Concluir o tratamento de erros
            </Button>
          )
        }
        </div>

        {
          TIPOS_POSSIVEL_ERRO.map((tipo) => {
            const reducer = (accumulator, currentValue) => accumulator + currentValue.quantidade
            const quantidadeTipo = resumo.filter(
              (i) => (i.tipoPossivelErro === tipo.cod)
            ).reduce(reducer, 0)

            return <ContainerTratamentoPossiveisErros
              key={tipo.cod}
              tipo={tipo}
              open={tipoAberto === tipo}
              quantidadeIgnorados={encerrado ? quantidadeTipo : 0}
              quantidadeNaoIgnorados={encerrado ? 0 : quantidadeTipo}
              encerrado={encerrado}
              atualizar={this.atualizarResumo}
              onClick={() => this.handleTipoClick(tipo)}/>
          })
        }

        <ModalConcluirTratamentoErros
          aberto={exibirModalConclusao}
          fechar={() => this.setState({ exibirModalConclusao: false })}
          confirmar={this.handleConfirmarConclusao}
          numeroErros={totalOcorrencias}
        />

        <ModalConfirmacao
          open={this.state.exibirConfirmacaoReativacao}
          titulo='Reativar Tratamento de Possíveis Erros'
          onClose={() => this.setState({ exibirConfirmacaoReativacao: false })}
          confirmar={this.handleConfirmarReativacao}>
            <Alert severity="warning" style={{marginTop: 16}}> Reativar o tratamento de possíveis erros implicará na necessidade de concluir o tratamento de possíveis erros para gerar curva ABC. </Alert>
            <Alert severity="warning" style={{marginTop: 16}}> Se já houver Curva ABC criada, será excluída para evitar erros da Reativação do Tratamento, deseja continuar?</Alert>
        
        </ModalConfirmacao>


        <ModalZeroErros
          open={exibirModalZeroErros}
          cancelar={() => this.setState({ exibirModalZeroErros: false })}
        />



      </Template>
    )
  }
}


export default connect(mapStateToPropsContexto)(withStyles(styles)(PossiveisErros))
