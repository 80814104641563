import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import Link from '@material-ui/core/Link'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import PropTypes from 'prop-types'

const BreadCrumbLink = ({ to, desc }) => {
  return (
    <Link color='inherit' href={to}>
      {desc}
    </Link>
  )
}

BreadCrumbLink.propTypes = {
  to: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired
}

const BreadCrumbHome = ({ classes }) => {
  return (
    <div className={classes.breadcrumb}>
      <Link color='primary' className={classes.voltar} href={'/'}>VOLTAR</Link> |
      <Breadcrumbs datacy-id='migalha' separator='›' aria-label='breadcrumb' className={classes.breadcrumbItem}>
        <BreadCrumbLink to={'/'} desc={'Início'}/>
        <BreadCrumbLink to={'#'} desc={'Resultados Pesquisa Geral'}/>
      </Breadcrumbs>
    </div>
  )
}

BreadCrumbHome.propTypes = {
  classes: PropTypes.object
}

export default withRouter(withStyles(styles)(BreadCrumbHome))
