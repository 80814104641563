import { Grid } from '@material-ui/core'
import React from 'react'
import Typography from '@material-ui/core/Typography/Typography'
import PropTypes from 'prop-types'

const CabecalhoComposicao = ({ classes, composicao }) => {
  return (
    <Grid container spacing={1} wrap='nowrap' className={classes.composicaoHeader}>
      <Grid item>
        <Typography>{composicao.codigo}</Typography>
      </Grid>
      <Grid item>
        <Typography>{composicao.descricao}</Typography>
      </Grid>
      <Grid item>
        <Typography>{composicao.mes}/{composicao.ano}</Typography>
      </Grid>
    </Grid>
  )
}

CabecalhoComposicao.propTypes = {
  composicao: PropTypes.object.isRequired,
  classes: PropTypes.object
}

export default CabecalhoComposicao
