import React from 'react'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import StyledTableRow from '../../../../comum/StyledTableRow'
import { paginar } from '../../../../../js/util'
import PropTypes from 'prop-types'

const CorpoDetalheItemRelatorioHistoricoOrcamento = ({ itensDetalheHistoricoOrcamento, pagina, registrosPorPagina }) => (
  <TableBody>
    {
      paginar(itensDetalheHistoricoOrcamento, pagina, registrosPorPagina).map((item) => (
        <StyledTableRow key={item.cod}>
          <TableCell className='center'>{item.numeroLinhaOriginal > 0 ? item.numeroLinhaOriginal : ''}</TableCell>
          <TableCell className='center'>{item.codServico}</TableCell>
          <TableCell>{item.descServico}</TableCell>
          <TableCell className='center'>{item.unidade}</TableCell>
          <TableCell className='center'>{item.quantidade}</TableCell>
          <TableCell className='center'>{item.precoUnitario}</TableCell>
          <TableCell className='center'>{item.precoParcial}</TableCell>
        </StyledTableRow>
      ))
    }
  </TableBody>
)

CorpoDetalheItemRelatorioHistoricoOrcamento.propTypes = {
  itensDetalheHistoricoOrcamento: PropTypes.array.isRequired,
  pagina: PropTypes.number.isRequired,
  registrosPorPagina: PropTypes.number.isRequired
}

export default CorpoDetalheItemRelatorioHistoricoOrcamento