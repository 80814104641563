import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { converterMoedaBRParaNumero, formatarNumeroParaMoedaBR, handleCampoNumericoChange, mapStateToPropsContexto, seStringVazia, showErrorMsg, showSuccessMsg } from '../../../../../../../../js/util'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import TituloVoltar from '../../../../../../../comum/TituloVoltar'
import { context_httpget, context_httppost } from '../../../../../../../../js/httpRequest'
import { connect } from 'react-redux'
import _ from 'lodash'
import FormControl from '@material-ui/core/FormControl'
import InsumoAdicionar from '../tempos-fixos/InsumoAdicionar'
import ComposicaoAdicionar from '../tempos-fixos/ComposicaoAdicionar'
import { TIPO_INSUMO_COMPOSICAO_DESTE_ORCAMENTO, TIPO_INSUMO_COMPOSICAO_REFERENCIA_PRECO, TIPO_INSUMO_MATERIAL_DESTE_ORCAMENTO } from '../composicoesAuxiliares'

const OPCOES_INSUMO = [
  TIPO_INSUMO_MATERIAL_DESTE_ORCAMENTO,
  TIPO_INSUMO_COMPOSICAO_DESTE_ORCAMENTO,
]

const OPCOES_TRANSPORTE = [
  TIPO_INSUMO_COMPOSICAO_DESTE_ORCAMENTO,
  TIPO_INSUMO_COMPOSICAO_REFERENCIA_PRECO,
]

const defaultState = {
  tipoInsumo: TIPO_INSUMO_MATERIAL_DESTE_ORCAMENTO.value,
  tipoTransporte: TIPO_INSUMO_COMPOSICAO_REFERENCIA_PRECO.value,
  materiais: [],
  composicoes: [],
  insumo: null,
  insumoEmpty: false,
  transporte: null,
  transporteEmpty: false,
  quantidade: '',
  quantidadeEmpty: false,
  descricao: '',
  descricaoEmpty: false,
  unidade: '',
  unidadeEmpty: false,
  dmt: '',
  dmtEmpty: false,
  tipo: '',
  tipoEmpty: false,
  sugestoesDmtPadrao: [],
  fit: 0.0
}

class AdicionarTransporteComposicao extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired,
    contexto: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = defaultState
    this.handleChange = this.handleChange.bind(this)
    this.handleSalvarClick = this.handleSalvarClick.bind(this)
  }

  componentDidMount() {
    if (!_.isNil(this.props.contexto)) {
      this.recuperarOpcoesTransporte()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contexto !== this.props.contexto) {
      this.recuperarOpcoesTransporte()
    }
  }

  async recuperarOpcoesTransporte() {
    const { contexto, codigoComposicao } = this.props
    const data = await context_httpget(`precos/transportes/opcoes?composicao=${codigoComposicao}`, contexto)
    if (data) {
      this.setState({
        materiais: data.materiais,
        composicoes: data.composicoes
      })
    }
  }

  handleChangeInsumo = (value, campo) => {
    this.setState({
      tipo: '',
      unidade: '',
      dmt: '',
      sugestoesDmtPadrao: [],
      transporte: null
    })
    this.handleChange(value, campo)
  }

  handleChangeTipo = (event) => {
    const { contexto } = this.props
    const { insumo, unidade, dmt } = this.state
    const { name, value } = event.target

    context_httpget(`precos/composicoes/transportes/tipo/${value}/dmt-padrao?insumo=${insumo.codigo}`, contexto)
      .then((sugestoesDmtPadrao) => {
        this.setState({
          sugestoesDmtPadrao,
          unidade: unidade,
          dmt: dmt,
          transporte: null
        })
        this.handleCampoTextoChange(event, name)
      })
      .catch(() => showErrorMsg('Erro ao buscar DMTs Padrão'))

  }

  handleCampoTextoChange(event, campo) {
    this.handleChange(event.target.value, campo)
  }

  handleChange(value, campo) {
    const state = {}
    state[campo] = value
    state[`${campo}Empty`] = _.isNil(value) || _.isEmpty(value)
    this.setState(state)
  }

  selecionaDmtPadrao = (sugestao) => {
    this.setState({
      unidade: sugestao.material.unidade,
      dmt: formatarNumeroParaMoedaBR(sugestao.dmtPadrao.distancia, 2, 3)
    })
    this.handleChange({ label: `${sugestao.composicao.codigo} - ${sugestao.composicao.descricao}`, value: sugestao.composicao.codigo }, 'transporte')
  }

  async handleSalvarClick() {
    const { insumo, quantidade, descricao, unidade, dmt, transporte, tipoInsumo, tipoTransporte, tipo, fit } = this.state
    const insumoEmpty = insumo === null
    const quantidadeEmpty = seStringVazia(quantidade)
    const descricaoEmpty = seStringVazia(descricao)
    const unidadeEmpty = seStringVazia(unidade)
    const dmtEmpty = seStringVazia(dmt)
    const tipoEmpty = seStringVazia(tipo)
    const transporteEmpty = transporte === null

    if ([insumoEmpty, quantidadeEmpty, descricaoEmpty, unidadeEmpty, dmtEmpty, tipoEmpty, transporteEmpty].some(Boolean)) {
      this.setState({
        insumoEmpty,
        quantidadeEmpty,
        descricaoEmpty,
        unidadeEmpty,
        dmtEmpty,
        tipoEmpty,
        transporteEmpty
      })
    } else {

      const { contexto, codigoComposicao, onDadosAlterados } = this.props

      if (transporte.codigo === codigoComposicao) {
        showErrorMsg('O código da composição selecionada é igual ao código da composição atual. Selecione uma composição de Transporte')
        return; 
      }

      const dadosSalvar = {
        origemInsumo: tipoInsumo,
        insumo: {
          codigo: insumo.codigo,
          sistema: insumo.sistema || insumo.sistemaReferencia,
          uf: insumo.uf,
          mes: insumo.mes,
          ano: insumo.ano
        },
        quantidade: converterMoedaBRParaNumero(quantidade),
        descricao,
        unidade,
        tipo,
        dmt: converterMoedaBRParaNumero(dmt),
        origemTransporte: tipoTransporte,
        transporte: {
          codigo: transporte.codigo,
          sistema: transporte.sistema || transporte.sistemaReferencia,
          uf: transporte.uf,
          mes: transporte.mes,
          ano: transporte.ano
        },
        fit: fit
      }
      
      
      const resultado = await context_httppost(`precos/transportes/adicionar?composicao=${codigoComposicao}`, contexto, dadosSalvar)
      if (resultado) {
        showSuccessMsg('Transporte criado e incluído na composição.')
        onDadosAlterados()
      }
    }
    
  }

  render() {
    const { onCancelar, codigoComposicao } = this.props
    const {
      tipoInsumo,
      tipoTransporte,
      materiais,
      composicoes,
      insumo,
      insumoEmpty,
      quantidade,
      quantidadeEmpty,
      descricao,
      descricaoEmpty,
      unidade,
      unidadeEmpty,
      dmt,
      dmtEmpty,
      transporte,
      transporteEmpty,
      tipo,
      tipoEmpty,
      sugestoesDmtPadrao
    } = this.state

    return (
      <>
        <TituloVoltar onClick={onCancelar} label='Vincular Transporte na Composição' />
        <Box mt={2}>
          <Typography variant='overline'>1. Informe o insumo:</Typography>

          <Box mt={1}>
            <TextField
              fullWidth
              value={tipoInsumo}
              onChange={(e) => this.handleCampoTextoChange(e, 'tipoInsumo')}
              variant='outlined'
              select>
              {
                OPCOES_INSUMO.map((it) => (
                  <MenuItem key={it.value} value={it.value}>{it.label}</MenuItem>
                ))
              }
            </TextField>

            <InsumoAdicionar
              tipoInsumo={tipoInsumo}
              insumo={insumo}
              insumoEmpty={insumoEmpty}
              handleChange={this.handleChangeInsumo}
              materiais={materiais}
              composicoes={composicoes}
            />

            <Box mt={2}>
              <FormControl variant='outlined' fullWidth>
                <Typography variant='subtitle1'>Descrição</Typography>
                <TextField
                  multiline
                  rows={3}
                  variant='outlined'
                  value={descricao}
                  error={descricaoEmpty}
                  onChange={(e) => this.handleCampoTextoChange(e, 'descricao')}
                />
              </FormControl>
            </Box>

            <Box mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <FormControl variant='outlined' fullWidth>
                    <Typography variant='subtitle1'>Quantidade</Typography>
                    <TextField
                      variant='outlined'
                      value={quantidade}
                      error={quantidadeEmpty}
                      onChange={(e) => handleCampoNumericoChange(e, (e) => this.handleCampoTextoChange(e, 'quantidade'))}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
        <Box mt={4}>
          <Typography variant='overline'>2. Informe a composição de transporte:</Typography>

          {
            !_.isEmpty(sugestoesDmtPadrao) && (
              <Box mt={1}>
                <Typography variant={'subtitle2'}>
                  Sugestão
                  <Typography variant={'caption'} color={'textSecondary'}> (clique para preencher automaticamente)</Typography>
                </Typography>
                {
                  sugestoesDmtPadrao.map((sugestao) => (
                    <div key={sugestao.dmtPadrao.codInsumo}>
                      <Button color='primary' variant='text' size='small' onClick={() => this.selecionaDmtPadrao(sugestao)} style={{ padding: 0 }}>
                        Material - {sugestao.material.codigo} - {sugestao.material.descricao} - {sugestao.material.unidade} - DMT padrão {formatarNumeroParaMoedaBR(sugestao.dmtPadrao.distancia, 2, 3)} - {sugestao.composicao.codigo} - {sugestao.composicao.descricao}
                      </Button>
                    </div>
                  ))
                }
              </Box>
            )
          }

          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <FormControl variant='outlined' fullWidth>
                  <Typography variant='subtitle1'>Unidade</Typography>
                  <TextField
                    variant='outlined'
                    value={unidade}
                    error={unidadeEmpty}
                    onChange={(e) => this.handleCampoTextoChange(e, 'unidade')}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl variant='outlined' fullWidth>
                  <Typography variant='subtitle1'>DMT</Typography>
                  <TextField
                    variant='outlined'
                    value={dmt}
                    error={dmtEmpty}
                    onChange={(e) => handleCampoNumericoChange(e, (e) => this.handleCampoTextoChange(e, 'dmt'))}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} md={3}>
                <FormControl variant='outlined' fullWidth>
                  <Typography variant='subtitle1'>Tipo</Typography>
                  <TextField
                    fullWidth
                    name={'tipo'}
                    value={tipo}
                    error={tipoEmpty}
                    onChange={(e) => this.handleChangeTipo(e)}
                    variant='outlined'
                    select>
                    <MenuItem value='LN'>LN</MenuItem>
                    <MenuItem value='RP'>RP</MenuItem>
                    <MenuItem value='P'>P</MenuItem>
                    <MenuItem value='FE'>FE</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>

            </Grid>
          </Box>

          <Box mt={2}>
            <TextField
              fullWidth
              value={tipoTransporte}
              onChange={(e) => this.handleCampoTextoChange(e, 'tipoTransporte')}
              variant='outlined'
              select>
              {
                OPCOES_TRANSPORTE.map((it) => (
                  <MenuItem key={it.value} value={it.value}>{it.label}</MenuItem>
                ))
              }
            </TextField>

            <ComposicaoAdicionar
              tipoComposicao={tipoTransporte}
              composicao={transporte}
              composicaoEmpty={transporteEmpty}
              handleChange={this.handleChange}
              nomeAtributo='transporte'
              composicoes={composicoes}
              codigoComposicao={codigoComposicao}
            />
          </Box>
        </Box>

        <Box mt={3}>
          <MesmaLinha>
            <Button color='primary' variant='contained' onClick={this.handleSalvarClick}>Salvar</Button>
            <Button color='primary' onClick={onCancelar}>Cancelar</Button>
          </MesmaLinha>
        </Box>
      </>
    )
  }
}

export default connect(mapStateToPropsContexto)(AdicionarTransporteComposicao)
