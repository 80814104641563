import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import { BUSCAR_TODOS_EMPREENDIMENTOS } from '../types'

const buscarTodosEmpreendimentos = () => async (dispatch) => {
  const success = await axios.get('/api/todos-empreendimentos', SLOW_REQUEST_CONFIG)
  return dispatch({
    type: BUSCAR_TODOS_EMPREENDIMENTOS,
    payload: success
  })
}

export default buscarTodosEmpreendimentos
