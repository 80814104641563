import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import DialogContenText from '@material-ui/core/DialogContentText'
import Alert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'

const ModalExcluirObra = ({ handleModalExcluir, onConfirmar, onCancelar }) => {
  return (
    <Dialog open={handleModalExcluir}>
      <DialogTitle>Excluir Obra</DialogTitle>
      <DialogContent>
        <DialogContenText>
          <Alert severity='warning'>
            A exclusão da obra resultará na exclusão de todos os
            orçamentos relacionados. Deseja continuar?
          </Alert>
        </DialogContenText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCancelar()} variant='outlined' color='primary'>
          Cancelar
        </Button>
        <Button
          onClick={() => onConfirmar()}
          variant='contained'
          color='primary'
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ModalExcluirObra.propTypes = {
  handleModalExcluir: PropTypes.bool,
  onConfirmar: PropTypes.func,
  onCancelar: PropTypes.func
}

export default ModalExcluirObra
