import React, { Component, Fragment } from 'react'
import TableBody from '@material-ui/core/TableBody'
import Alert from '@material-ui/lab/Alert'
import axios from 'axios'
import ItemOrcamentoRow from './ItemOrcamentoRow'
import { paginar } from '../../../../js/util'
import ModalExcluirItemOrcamento from './ModalExcluirItemOrcamento'
import { SLOW_REQUEST_CONFIG } from '../../../../js/operationProgressUtil'
import PropTypes from 'prop-types'
import _ from 'lodash'

class TabelaItensOrcamento extends Component {
  static propTypes = {
    itens: PropTypes.array.isRequired,
    idEmpreendimento: PropTypes.number.isRequired,
    idObra: PropTypes.number.isRequired,
    idOrcamento: PropTypes.number.isRequired,
    pagina: PropTypes.number.isRequired,
    registrosPorPagina: PropTypes.number.isRequired,
    onItemAlterado: PropTypes.func.isRequired,
    onItemExcluido: PropTypes.func.isRequired,
    podeEditar: PropTypes.bool.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      itemExclusao: null
    }
    this.excluir = this.excluir.bind(this)
    this.fecharModalExcluir = this.fecharModalExcluir.bind(this)
    this.confirmarExclusao = this.confirmarExclusao.bind(this)
  }

  excluir (itemExclusao) {
    this.setState({
      itemExclusao
    })
  }

  async confirmarExclusao () {
    const { idEmpreendimento, idObra, idOrcamento } = this.props
    const { itemExclusao } = this.state
    const resumo = (await axios.delete(
      `/api/empreendimentos/${idEmpreendimento}/obras/${idObra}/orcamentos/${idOrcamento}/itens/${itemExclusao.cod}`,
      SLOW_REQUEST_CONFIG
    )).data
    this.fecharModalExcluir()
    this.props.onItemExcluido(itemExclusao, resumo)
  }

  fecharModalExcluir () {
    this.setState({
      itemExclusao: null
    })
  }

  render () {
    const { itemExclusao } = this.state
    const {
      itens, idEmpreendimento, idObra, idOrcamento, pagina, registrosPorPagina, onItemAlterado, podeEditar
    } = this.props
    return (
      <Fragment>
        <TableBody>
          {
            paginar(itens, pagina, registrosPorPagina).map((item, index) => (
              <ItemOrcamentoRow
                contador={((pagina * registrosPorPagina) + index + 1)}
                key={item.cod}
                idEmpreendimento={idEmpreendimento}
                idObra={idObra}
                idOrcamento={idOrcamento}
                onItemAlterado={onItemAlterado}
                podeEditar={podeEditar}
                excluir={this.excluir}
                item={item}
              />
            ))
          }
          {_.isEmpty(itens) && <td colSpan="100%"><Alert style={{marginTop: 4, marginBottom: 4}} severity='warning'>Não há itens a exibir</Alert></td>}
        </TableBody>
       
        <ModalExcluirItemOrcamento
          item={itemExclusao}
          fechar={this.fecharModalExcluir}
          confirmar={this.confirmarExclusao}
        />
      </Fragment>
    )
  }
}

export default TabelaItensOrcamento
