import React, { Component } from 'react'
import { connect } from 'react-redux'
import Fade from '@material-ui/core/Fade'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { Button, Checkbox, Menu, Typography, withStyles } from '@material-ui/core'
import styles from '../../styles'
import PropTypes from 'prop-types'
import MesmaLinha from '../../../../../comum/MesmaLinha'
import alterarExibicaoColuna from '../../../../../../actions/curva-abc/alterarExibicaoColuna'
import { colunas } from './colunas'
import Tooltip from '@material-ui/core/Tooltip'
import { existeObjetoNaLista } from '../../../../../../js/util'

class SelecionarColunas extends Component {
  static propTypes = {
    classes: PropTypes.object,
    colunasExibir: PropTypes.array,
    alterarExibicaoColuna: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose() {
    this.setState({ anchorEl: null })
  }

  render () {
    const { anchorEl } = this.state
    const { classes, colunasExibir, alterarExibicaoColuna } = this.props
    return (
      <>
        <Tooltip title='Selecionar Colunas a Exibir' className='curpointer' >
          <Button aria-controls='fade-menu' aria-haspopup='true' onClick={this.handleClick} variant='outlined'>
            <Typography className={classes.txtBtn}>Colunas <ArrowDropDownIcon/></Typography>
          </Button>
        </Tooltip>
        <Menu
          id='fade-menu'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          TransitionComponent={Fade}>
          <div className={classes.menuColunas}>
            {
              colunas.map((coluna) => (
                <MesmaLinha key={coluna.cod}>
                  <Checkbox color='primary' checked={existeObjetoNaLista(colunasExibir, coluna)} onClick={() => alterarExibicaoColuna(coluna)} />
                  <Typography>{coluna.label}</Typography>
                </MesmaLinha>
              ))
            }
          </div>
        </Menu>
      </>
    )
  }
}

const mapStateToProps = ({ curvaAbc }) => ({
  colunasExibir: curvaAbc.colunasExibir
})

export default connect(mapStateToProps, {
  alterarExibicaoColuna
})(withStyles(styles)(SelecionarColunas))
