import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import _ from 'lodash'
import ItemVincularFormula from './ItemVincularFormula'
import TitleTableCell from '../../../../../../comum/TitleTableCell'
import Paginacao from '../../../../../../comum/Paginacao'

class TabelaVincularFormulas extends Component {
  static propTypes = {
    classes: PropTypes.object,
    formulas: PropTypes.array,
    formulasSelecionadas: PropTypes.array,
    onSelecionarFormula: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props);
    this.state = {
      pagina: 0,
      registrosPorPagina: 10
    }
  }

  handleFormulaIsChecked = (formula) => {
    const { formulasSelecionadas } = this.props
    return formulasSelecionadas.map(it => _.isNil(it.codFormulaOriginal) ? it.codFormula : it.codFormulaOriginal).indexOf(formula.codFormula) !== -1;
  }

  handleDesabilitarFormula = (formula) => {
    const { formulasSelecionadas } = this.props
    return formulasSelecionadas.some(it => it.codFormulaOriginal === formula.codFormula);
  }

  render () {
    const { classes, formulas, onSelecionarFormula } = this.props
    const { pagina, registrosPorPagina } = this.state
    return (
      <React.Fragment>
        <Table>
          <TableHead>
            <TableRow>
              <TitleTableCell width={'5%'} />
              <TitleTableCell width={'25%'}>Nome</TitleTableCell>
              <TitleTableCell width={'25%'}>Orçamento</TitleTableCell>
              <TitleTableCell width={'25%'}>Obra</TitleTableCell>
              <TitleTableCell align='right'>Empreendimento</TitleTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            { formulas.slice(pagina * registrosPorPagina, pagina * registrosPorPagina + registrosPorPagina).map((formula) => (
               <ItemVincularFormula
                 key={formula.codFormula}
                 classes={classes}
                 formula={formula}
                 isDisabled={this.handleDesabilitarFormula(formula)}
                 isChecked={this.handleFormulaIsChecked(formula)}
                 onSelecionarFormula={onSelecionarFormula}
               />
              ))
            }
          </TableBody>
        </Table>
        <Paginacao
          total={formulas.length}
          registrosPorPagina={registrosPorPagina}
          pagina={pagina}
          onChangePage={(e, pag) => { this.setState({ pagina: pag }) }}
          onChangeRowsPerPage={(e) => (this.setState({ registrosPorPagina: e.target.value }))}
        />
      </React.Fragment>
    )
  }
}

export default TabelaVincularFormulas
