import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { mapStateToPropsContexto, showSuccessMsg } from '../../../../../js/util'
import { connect } from 'react-redux'
import { FormControl, Menu, MenuItem, withStyles } from '@material-ui/core'
import styles from './styles'
import _ from 'lodash'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MesmaLinha from '../../../../comum/MesmaLinha'
import IconButton from '@material-ui/core/IconButton'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import Button from '@material-ui/core/Button'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Fade from '@material-ui/core/Fade'
import ModalReferenciaPreco from '../referencia-preco/ModalReferenciaPreco'
import CriarComposicao from '../../relatorios/precos/composicao/CriarComposicao'
import { context_httpput } from '../../../../../js/httpRequest'
import { CompositionProvider } from '../../../../../hooks/useComposition'
import ModalComposicoes from '../referencia-preco/ModalComposicoes'  
import AssociarComposicaoOutroOrcamento from '../../relatorios/precos/composicao/AssociarComposicaoOutroOrcamento'
import TituloVoltar from '../../../../comum/TituloVoltar'
import Box from '@material-ui/core/Box';
import StyledDrawer from '../../../../comum/StyledDrawer'

const defaultState = {
  editar: false,
  codigoServico: '',
  anchorEl: null,
  modalReferenciaPreco: false,
  exibirDrawerCriarComposicao: false,
  modalComposicoes: false,
}

class CampoCodigoServicoItemCurvaAbc extends Component {

  static propTypes = {
    itemCurvaAbc: PropTypes.object.isRequired,
    onItemAlterado: PropTypes.func,
    contexto: PropTypes.object,
    classes: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = defaultState
    this.onChangeModalReferenciaPreco = this.onChangeModalReferenciaPreco.bind(this)
    this.onChangeModalComposicoes = this.onChangeModalComposicoes.bind(this)
    this.adicionarComposicaoOrcamentoOpen = false
  }

  componentDidMount() {
    if (!_.isNil(this.props.itemCurvaAbc)) {
      this.copiarDadosParaEstado()
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isNil(this.props.itemCurvaAbc) && prevProps.itemCurvaAbc.codigoServico !== this.props.itemCurvaAbc.codigoServico) {
      this.copiarDadosParaEstado()
    }
  }

  copiarDadosParaEstado = () => {
    const { itemCurvaAbc } = this.props
    this.setState({
      codigoServico: itemCurvaAbc.codigoServico
    })
  }

  handleChange(event) {
    const { name, value } = event.target
    const state = {}
    state[name] = value
    this.setState(state)
  }


  handleOpenMenu = (event) => this.setState({ anchorEl: event.currentTarget })

  handleCloseMenu = () => this.setState({ anchorEl: null })

  abrirModalReferenciaPreco = () => this.setState({ modalReferenciaPreco: true })

  fecharModalReferenciaPreco = () => this.setState({ modalReferenciaPreco: false })

  abrirModalComposicoes = () => this.setState({ modalComposicoes: true })
  fecharModalComposicoes = () => this.setState({ modalComposicoes: false })

  handleCloseAdicionarComposicaoOrcamento = () => {
    this.setState({ adicionarComposicaoOrcamentoOpen: false });
  }
  handleOpenAdicionarComposicaoOrcamento = () => {
    this.setState({ adicionarComposicaoOrcamentoOpen: true });
  }

  handleVoltarClick = () => {
    this.setState({ adicionarComposicaoOrcamentoOpen: false });
    window.location.reload();
  }

  handleCancelarEdicao = () => {
    this.copiarDadosParaEstado()
    this.handleCloseMenu()
    this.setState({ editar: false })
  }

  handleDrawerCriarComposicao = () => this.setState({ exibirDrawerCriarComposicao: !this.state.exibirDrawerCriarComposicao })

  onChangeModalReferenciaPreco = () => {
    const { onItemAlterado } = this.props

    onItemAlterado()
    this.handleCloseMenu()
    this.fecharModalReferenciaPreco()
  }

  onChangeModalComposicoes = () => {
    const { onItemAlterado } = this.props

    onItemAlterado()
    this.handleCloseMenu()
    this.fecharModalComposicoes()
  }

  handleDadosAlterados = () => {
    const { onItemAlterado } = this.props

    onItemAlterado()
    this.handleCloseMenu()
    this.handleDrawerCriarComposicao()
  }

  salvar = () => {
    const { contexto, itemCurvaAbc, onItemAlterado } = this.props
    const { codigoServico } = this.state

    context_httpput(`curva-abc/itens/${itemCurvaAbc.id}/codigo-servico`, contexto, { codigoServico })
      .then(() => {
        showSuccessMsg('Código do serviço salvo com sucesso.')
        onItemAlterado()
        this.handleCloseMenu()
        this.setState({ editar: false })
      })
  }

  opcoesMenu = () => {
    const { classes } = this.props
    const { anchorEl, codigoServico } = this.state

    return (
      <FormControl variant="outlined" className={classes.btnSelect}>
        <Button
          className={classes.btnCodigo}
          aria-controls='menu-opcoes'
          aria-haspopup='true'
          variant={'text'}
          onClick={(e) => this.handleOpenMenu(e)}
        >
          <Typography className={classes.textoCodigoServico}>
            {codigoServico === '' ? 'Selecione' : codigoServico}
          </Typography>
          <ArrowDropDownIcon/>
        </Button>
        <Menu
          id='menu-opcoes'
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => this.handleCloseMenu()}
          TransitionComponent={Fade}>
          <MenuItem
            value='check'
            onClick={() => {this.abrirModalReferenciaPreco(); this.handleCloseMenu()}}
            className={classes.itemMenuOpcoes}
          >
            <Typography className={classes.tituloItem}>Pesquisar</Typography>
          </MenuItem>
          <MenuItem value='check' onClick={() => this.setState({editar: true})} className={classes.itemMenuOpcoes}>
            <Typography className={classes.tituloItem}>Informar manualmente</Typography>
          </MenuItem>
          <MenuItem value='check' onClick={() => this.handleDrawerCriarComposicao()} className={classes.itemMenuOpcoes}>
            <Typography className={classes.tituloItem}>Criar uma composição</Typography>
          </MenuItem>
          <MenuItem value='check' onClick={() => this.abrirModalComposicoes()} className={classes.itemMenuOpcoes}>
            <Typography className={classes.tituloItem}>Pesquisar em "Preços"</Typography>
          </MenuItem>
          <MenuItem value='check' onClick={() => this.handleOpenAdicionarComposicaoOrcamento()} className={classes.itemMenuOpcoes}>
            <Typography className={classes.tituloItem}>Importar de "Orçamentos"</Typography>
          </MenuItem>

        </Menu>
      </FormControl>
    )
  }

  render() {
    const { itemCurvaAbc, classes } = this.props
    const { editar, codigoServico, modalReferenciaPreco, exibirDrawerCriarComposicao, modalComposicoes } = this.state
    const { adicionarComposicaoOrcamentoOpen } = this.state

    if (itemCurvaAbc.analiseConcluida) {
      return (
        <Typography className={classes.itensCabecalho}>{codigoServico || '-'}</Typography>
      )
    }

    return (
      <CompositionProvider>
        <Fragment>
          {
            editar ? (
              <Fragment>
                <TextField
                  className={classes.campoCodigoServico}
                  name={'codigoServico'}
                  variant='outlined'
                  value={codigoServico}
                  onChange={(e) => this.handleChange(e)}
                />
                <MesmaLinha>
                  <IconButton color='primary' onClick={() => this.salvar()}>
                    <DoneIcon/>
                  </IconButton>
                  <IconButton color='primary' onClick={() => this.handleCancelarEdicao()}>
                    <ClearIcon/>
                  </IconButton>
                </MesmaLinha>
              </Fragment>
            ) : (
              this.opcoesMenu()
            )
          }

          {modalReferenciaPreco && <ModalReferenciaPreco
            open={modalReferenciaPreco}
            close={() => this.fecharModalReferenciaPreco()}
            itemCurvaAbc={itemCurvaAbc}
            onChange={this.onChangeModalReferenciaPreco}
          />}

          {modalComposicoes && <ModalComposicoes
            open={modalComposicoes}
            close={() => this.fecharModalComposicoes()}
            itemCurvaAbc={itemCurvaAbc}
            onChange={this.onChangeModalComposicoes}
          />}

          {exibirDrawerCriarComposicao && (
            <CompositionProvider onDadosAlterados={this.handleDadosAlterados}>
              <CriarComposicao
                value={itemCurvaAbc}
                onCancelar={() => this.setState({exibirDrawerCriarComposicao: false})}
              />
            </CompositionProvider>)
          }


          {adicionarComposicaoOrcamentoOpen && (
            <StyledDrawer PaperProps={{ className: classes.drawerEdicao, style: { width: '50%' } }} open anchor="right">
            <TituloVoltar onClick={this.handleVoltarClick} label='Voltar'/>
            <Box
              my={2}
              p={1}
              border={1}
              borderRadius={2}
              display="flex"
              alignItems="center"
              borderColor="#ced4da"
              width={250} // Set the desired width for the text field
              bgcolor="#ffffff" // Set the background color
              color="#000000" // Set the text color
            >
            Composições de outro orçamento
            </Box>

              <AssociarComposicaoOutroOrcamento
                onCancelar={this.handleCloseAdicionarComposicaoOrcamento}
                itemCurvaAbc={itemCurvaAbc}
               />
                        
            </StyledDrawer>
          )}

        </Fragment>
      </CompositionProvider>
    )
  }
}

export default connect(mapStateToPropsContexto)(withStyles(styles)(CampoCodigoServicoItemCurvaAbc))
