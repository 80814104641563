import React, { Component } from 'react'
import { TableBody } from '@material-ui/core'
import ItemCurvaABCRow from './ItemCurvaABCRow'
import PropTypes from 'prop-types'
import CurvaAbcVazia from '../CurvaAbcVazia'
import ModalCalculoSobreprecoCurvaAbc from '../sobrepreco/ModalCalculoSobreprecoCurvaAbc'

class CorpoTabelaCurvaABC extends Component {
  static propTypes = {
    itens: PropTypes.array.isRequired,
    itensCarregados: PropTypes.array.isRequired,
    metodoCalculo: PropTypes.object,
    mostrarSelecaoLinhas: PropTypes.bool,
    selecionaItem: PropTypes.func,
    itensSelecionados: PropTypes.array,
    onItemAlterado: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      itemExibindoDetalhes: null,
      itemExibindoSobrepreco: null
    }
    this.handleItemExibindoDetalhesChange = this.handleItemExibindoDetalhesChange.bind(this)
    this.handleItemDetalharSobreprecoChange = this.handleItemDetalharSobreprecoChange.bind(this)
  }

  handleItemExibindoDetalhesChange (item) {
    const { itemExibindoDetalhes } = this.state
    if (item !== itemExibindoDetalhes) {
      this.setState({ itemExibindoDetalhes: item })
    } else {
      this.setState({ itemExibindoDetalhes: null })
    }
  }

  handleItemDetalharSobreprecoChange (item) {
    const { itemExibindoSobrepreco } = this.state
    if (item !== itemExibindoSobrepreco) {
      this.setState({ itemExibindoSobrepreco: item })
    } else {
      this.setState({ itemExibindoSobrepreco: null })
    }
  }

  render () {
    const { itens, itensCarregados, onItemAlterado, metodoCalculo, mostrarSelecaoLinhas, selecionaItem, itensSelecionados } = this.props
    const { itemExibindoSobrepreco, itemExibindoDetalhes } = this.state
    
    return (
      <React.Fragment>
        <TableBody>
          {
            itens.length === 0 ? <CurvaAbcVazia /> :
            itens.map((itemCurva) => (
              
              <ItemCurvaABCRow
                key={`${itemCurva.codigoServico}-${itemCurva.descricaoOrcamento}-${itemCurva.unidadeOrcamento}-${itemCurva.precoUnitarioOrcamento}`}
                item={itemCurva}
                hideLoadingPrecos={itensCarregados.includes(itemCurva.id)}
                onItemAlterado={onItemAlterado}
                exibirDetalhes={itemCurva === itemExibindoDetalhes}
                onExibirDetalhesChange={this.handleItemExibindoDetalhesChange}
                onExibirDetalhesSobreprecoChange={this.handleItemDetalharSobreprecoChange}
                metodoCalculo={metodoCalculo}
                mostrarSelecaoLinhas={mostrarSelecaoLinhas}
                selecionaItem={selecionaItem}
                itensSelecionados={itensSelecionados}
              />
            ))
          }
        </TableBody>
        <ModalCalculoSobreprecoCurvaAbc
          item={itemExibindoSobrepreco}
          fechar={this.handleItemDetalharSobreprecoChange}
        />
      </React.Fragment>
    )
  }
}

export default CorpoTabelaCurvaABC
