import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Paginacao from '../../../../comum/Paginacao'
import { paginar } from '../../../../../js/util'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ItemComposicaoRowModal from './ItemComposicaoRowModal'

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    boxShadow: 'none'
  },
  table: {
    minWidth: 890
  },
  tableWrapper: {
    overflowX: 'auto'
  }
})

const defaultState = {
  pagina: 0,
  totalRegistrosPorPagina: 10
}

export class TabelaComposicoes extends Component {
  static propTypes = {
    classes: PropTypes.object,
    composicoes: PropTypes.array.isRequired,
    onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = defaultState
  }

  render () {
    const { classes, composicoes, onAtualizarItemCurvaABCComposicaoDuplicada } = this.props
    const { pagina, totalRegistrosPorPagina } = this.state

    return (
      <Paper className={ classes.root }>
        <div className={ classes.tableWrapper }>
          <Table className={ classes.table } aria-label='custom pagination table'>
            <TableHead>
              <TableRow>
                <TableCell align={'inherit'}>Código do Serviço</TableCell>
                <TableCell align={'left'}>Descrição</TableCell>
                <TableCell align={'inherit'}>Data Referência</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                paginar(composicoes, pagina, totalRegistrosPorPagina).map((composicao) => (
                  <ItemComposicaoRowModal
                    key={composicao.codigo}
                    composicao={composicao}
                    onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
                  />
                ))}
            </TableBody>
          </Table>
          <Paginacao
            pagina={ pagina }
            registrosPorPagina={ totalRegistrosPorPagina }
            total={ composicoes.length }
            onChangePage={(e, pag) => this.setState({ pagina: pag })}
            onChangeRowsPerPage={(e) => this.setState({ totalRegistrosPorPagina: e.target.value })}
          />
        </div>
      </Paper>
    )
  }
}

const mapStateToProps = ({ contexto }) => ({ contexto })

export default connect(mapStateToProps)(withStyles(styles)(TabelaComposicoes))
