import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { TableRow } from '@material-ui/core'
import StyledTableRowComparar from '../../comum/StyledTableRowComparar'
import useStyles from './styles'
import { formatarNumeroParaMoedaBR } from '../../../js/util'

const ItemComparacao = ({ item }) => {
  const classes = useStyles()
  let percentualDiferencaPrecos = '';

  if (item.precoUnitarioOrc1 !== 0) {
    percentualDiferencaPrecos = ((item.precoUnitarioOrc2 - item.precoUnitarioOrc1) / item.precoUnitarioOrc1) * 100;
    percentualDiferencaPrecos = percentualDiferencaPrecos.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }
 
  return (
    <React.Fragment>
    <StyledTableRowComparar>
      <TableCell>
        <Typography variant='body2'>
          {item.codServico}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {item.unidade}
        </Typography>
      </TableCell>
      <TableCell width='30%'>
        <Typography variant='body2'>
          {item.descricao}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {item.ocorrencia}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {formatarNumeroParaMoedaBR(item.valorImpacto, 2, 4)}
        </Typography>
        <Typography variant='body2' color='text.secondary' className={classes.infoSecundaria}>
          {item.percentualImpacto}%
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          <b>A</b>
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {formatarNumeroParaMoedaBR(item.quantidadeOrc1, 2, 4)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {formatarNumeroParaMoedaBR(item.precoUnitarioOrc1, 2, 4)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {formatarNumeroParaMoedaBR(item.valorOrc1, 2, 4)}
        </Typography>
      </TableCell>
    </StyledTableRowComparar>
    <TableRow>
      <TableCell colSpan={2}/> {/* Adjusted colSpan */}
      <TableCell width='30%'>
        <Typography variant='body2'>
          {item.descricaoB}
        </Typography>
      </TableCell>
      <TableCell colSpan={2}/> {/* Adjusted colSpan */}
      <TableCell>
        <Typography variant='body2'><b>B</b></Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {formatarNumeroParaMoedaBR(item.quantidadeOrc2, 2, 4)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {formatarNumeroParaMoedaBR(item.precoUnitarioOrc2, 2, 4)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {formatarNumeroParaMoedaBR(item.valorOrc2, 2, 4)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant='body2'>
          {percentualDiferencaPrecos !== '' ? percentualDiferencaPrecos + '%' : ''}
        </Typography>
      </TableCell>
    </TableRow>
  </React.Fragment>
  

  )
}

ItemComparacao.propTypes = {
  item: PropTypes.object.isRequired
}

export default ItemComparacao
