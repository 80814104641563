import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 400
  },
  withMiniDrawer: {
    marginLeft: 50
  },
  titleAddObraBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 16
  },
  paper: {
    padding: 18
  },
  inputLarge: {
    margin: '10px 0',
    width: '50%'
  },
  input: {
    margin: '10px 0',
    width: '30%'
  },
  content: {
    padding: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '& > div:nth-child(2)': {
      marginBottom: 20,
      marginTop: 20
    }
  },
  table: {
    minWidth: 650
  }
}))

export default useStyles
