import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import PropTypes from 'prop-types'

const CelulaSomenteTexto = ({ exibir, texto }) => {
  return exibir && <TableCell align='right'>{texto}</TableCell>
}

CelulaSomenteTexto.propTypes = {
  exibir: PropTypes.bool.isRequired,
  texto: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired
  ])
}

export default CelulaSomenteTexto
