import React, { Component } from 'react'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TituloModal from '../../../../comum/TituloModal'
import TabelaDescritivaCalculos from './TabelaDescritivaCalculos'
import PropTypes from 'prop-types'

class ModalCalculoSobreprecoCurvaAbc extends Component {
  static propTypes = {
    fechar: PropTypes.func.isRequired,
    item: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.fechar = this.fechar.bind(this)
  }

  fechar() {
    const { item, fechar } = this.props
    fechar(item)
  }

  configuraCalculo = (item) => {
    const valorRef = item.valorTotalReferencia
    const valorRefComBdi =  valorRef + (valorRef * item.bdiPercentual)
    const VUR = {
      nome: 'VUR',
      descricao: 'Valor unitário conforme referência de preço',
      valor: item.precoReferencia
    }
    VUR.formula = [VUR]
    const QTD = {
      nome: 'QTD',
      descricao: 'Quantidade do item orçamentário',
      valor: item.quantidade
    }
    QTD.formula = [QTD]
    const VTR = {
      nome: 'VTR',
      descricao: 'Valor total conforme referência de preço',
      valor: valorRef
    }
    VTR.formula = [VUR, ' x ', QTD, ' = ', VTR]
    const BDI = {
      nome: 'BDI',
      descricao: 'Bônus de Desempenho Individual',
      valor: item.bdi
    }
    BDI.formula = [BDI]
    const PP = {
      nome: 'PP',
      descricao: 'Preço parcial do item orçamentário',
      valor: item.precoParcialCalculado
    }
    PP.formula = [PP]
    const VTRBDI = {
      nome: 'VTRBDI',
      descricao: 'Valor total conforme referência de preço com BDI',
      valor: valorRefComBdi
    }
    VTRBDI.formula = [VTR, ' + ', VTR, ' x ', BDI, '% = ', VTRBDI]
    const SPR = {
      nome: 'SPR',
      descricao: 'Sobrepreço',
      valor: item.precoParcialCalculado - valorRefComBdi
    }
    SPR.formula = [PP, ' - ', VTRBDI, ' = ', SPR]
    return [VUR, QTD, PP, VTR, BDI, VTRBDI, SPR]
  }

  render () {
    const { item } = this.props
    return item && (
      <Dialog
        open={true}
        onClose={this.fechar}
        fullWidth
        aria-labelledby='form-dialog-title'>

        <TituloModal onClose={this.fechar}>
          Cálculo do sobrepreço
        </TituloModal>

        <DialogContent>
          <TabelaDescritivaCalculos
            cabecalho={['Descrição', 'Fórmula', 'Resultado']}
            calculo={this.configuraCalculo(item)}/>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={this.fechar}>Fechar</Button>
        </DialogActions>

      </Dialog>
    )
  }
}

export default ModalCalculoSobreprecoCurvaAbc
