import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import { Link, Typography } from '@material-ui/core'
import HistoryIcon from '@material-ui/icons/History'
import { formatarData, paginar } from '../../../../../js/util'
import PropTypes from 'prop-types'

class CorpoRelatorioHistoricoOrcamento extends Component {
  static propTypes = {
    openModal: PropTypes.func.isRequired,
    itensHistoricoOrcamento: PropTypes.array.isRequired,
    pagina: PropTypes.number.isRequired,
    registrosPorPagina: PropTypes.number.isRequired,
    classes: PropTypes.object.isRequired
  }

  key = (itemHistoricoOrcamento) => (
    `${itemHistoricoOrcamento.dataInclusao} - ${itemHistoricoOrcamento.tipoEvento} - ${itemHistoricoOrcamento.descEventoItem}`
  )

  openModal = (itemHistoricoOrcamento) => {
    this.props.openModal(itemHistoricoOrcamento)
  }

  descQtdItens = (qtdItens) => (
    `${qtdItens} ${qtdItens === 1 ? 'item' : 'itens'}`
  )

  descEventoItem = (descicao) => (
    descicao ? descicao.split(';') : []
  )

  descItemIncluido = (itemHistoricoOrcamento, classes) => (
    <React.Fragment>
      <Typography display='inline'>
        {this.descEventoItem(itemHistoricoOrcamento.descEventoItem)}
      </Typography>
      { !itemHistoricoOrcamento.descEventoItem.includes('Orçamento criado') &&
        <Link display='inline' className={classes.tamanhoFonte} color='primary' onClick={() => this.openModal(itemHistoricoOrcamento)}>
          {`${this.descQtdItens(itemHistoricoOrcamento.quantidadeItens)}`}
        </Link>
      }
    </React.Fragment>
  )

  descItemEditado = (itemHistoricoOrcamento, classes) => (
    <React.Fragment>
      <Link display='inline' className={classes.tamanhoFonte} color='primary' onClick={() => this.openModal(itemHistoricoOrcamento)}>
        {`${this.descQtdItens(itemHistoricoOrcamento.quantidadeItens)} `}
      </Link>
      <Typography display='inline'>
        {`editado${itemHistoricoOrcamento.quantidadeItens > 1 ? 's' : ''}`}
      </Typography>
      <div className={classes.bloco}>
        {
          this.descEventoItem(itemHistoricoOrcamento.descEventoItem).map((desc) => (
            <Typography gutterBottom key={desc}>
              {desc.trim()}
            </Typography>
          ))
        }
      </div>
    </React.Fragment>
  )

  descItemAgrupado = (itemHistoricoOrcamento, classes) => (
    <React.Fragment>
      <div className={classes.bloco}>
        {
          this.descEventoItem(itemHistoricoOrcamento.descEventoItem).map((desc) => (
            <Typography gutterBottom key={desc}>
              {desc.trim()}
            </Typography>
          ))
        }
      </div>
    </React.Fragment>
  )

  descItemExcluido = (itemHistoricoOrcamento, classes) => (
    <React.Fragment>
      {
        itemHistoricoOrcamento.descEventoItem ? (
          <React.Fragment>
            <Typography display='inline'>
              {this.descEventoItem(itemHistoricoOrcamento.descEventoItem)}
            </Typography>
            <Link display='inline' className={classes.tamanhoFonte} color='primary' onClick={() => this.openModal(itemHistoricoOrcamento)}>
              {`${this.descQtdItens(itemHistoricoOrcamento.quantidadeItens)}`}
            </Link>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Link display='inline' className={classes.tamanhoFonte} color='primary' onClick={() => this.openModal(itemHistoricoOrcamento)}>
              {`${this.descQtdItens(itemHistoricoOrcamento.quantidadeItens)}`}
            </Link>
            <Typography display='inline'>
              {` excluído${itemHistoricoOrcamento.quantidadeItens > 1 ? 's' : ''}`}
            </Typography>
          </React.Fragment>
        )
      }
    </React.Fragment>
  )

  descArquivoSubstituido = (itemHistoricoOrcamento) => (
    <React.Fragment>
      <Typography display='inline'>
        {this.descEventoItem(itemHistoricoOrcamento.descEventoItem)}
      </Typography>
    </React.Fragment>
  )

  descGenerica = (itemHistoricoOrcamento) => (
    <React.Fragment>
      <Typography display='inline'>
        {this.descEventoItem(itemHistoricoOrcamento.descEventoItem)}
      </Typography>
    </React.Fragment>
  )

  descricaoEventoItem = (itemHistoricoOrcamento, classes) => {
    switch (itemHistoricoOrcamento.tipoEvento) {
      case 'INCLUIR_ITEM':
        return <div>{this.descItemIncluido(itemHistoricoOrcamento, classes)}</div>
      case 'EXCLUIR_ITEM':
        return <div>{this.descItemExcluido(itemHistoricoOrcamento, classes)}</div>
      case 'EDITAR_ITEM':
        return  <div>{this.descItemEditado(itemHistoricoOrcamento, classes)}</div>
      case 'SUBSTITUIR_ARQUIVO':
        return <div>{this.descArquivoSubstituido(itemHistoricoOrcamento, classes)}</div>
      case 'AGRUPAR_ITEM':
        return  <div>{this.descItemAgrupado(itemHistoricoOrcamento, classes)}</div>
      default:
        return <div>{this.descGenerica(itemHistoricoOrcamento, classes)}</div>
    }
  }

  render () {
    const {
      itensHistoricoOrcamento, pagina, registrosPorPagina
    } = this.props

    const { classes } = this.props

    return (
      <>
        {
          paginar(itensHistoricoOrcamento, pagina, registrosPorPagina).map((itemHistoricoOrcamento) => (
            <div key={this.key(itemHistoricoOrcamento)} className={classes.linha}>
              <div className={classes.grupoDescricao}>
                <HistoryIcon className={classes.iconeHistorico}/>
                <div>
                  <Typography variant='body2' gutterBottom>{itemHistoricoOrcamento.nomeInclusao}</Typography>
                  {this.descricaoEventoItem(itemHistoricoOrcamento, classes)}
                </div>
              </div>
              <div className={classes.dataHora}>
                {formatarData(itemHistoricoOrcamento.dataInclusao, 'YYYY-MM-DD', 'DD/MM/YYYY')}
                <br/>
                {formatarData(itemHistoricoOrcamento.dataInclusao, 'YYYY-MM-DD HH:mm:ss', 'HH:mm')}
              </div>
            </div>
          ))
        }
      </>
    )
  }
}

export default withStyles(styles)(CorpoRelatorioHistoricoOrcamento)
