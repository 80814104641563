import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { formatarNumeroParaMoedaBR, mapStateToPropsContexto, showSuccessMsg, urlContexto } from '../../../../../../../../js/util'
import { Divider, IconButton, Table, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import styles from '../../../styles'
import ResumoCustoTotal from '../../ResumoCustoTotal'
import TableBody from '@material-ui/core/TableBody'
import { context_httpdelete } from '../../../../../../../../js/httpRequest'
import Button from '@material-ui/core/Button'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ModalConfirmacao from '../../../../../../../comum/ModalConfirmacao'
import StyledDrawer from '../../../../../../../comum/StyledDrawer'
import Typography from '@material-ui/core/Typography'
import AdicionarMaterialComposicao from './AdicionarMaterialComposicao'
import EditarMaterialComposicao from './EditarMaterialComposicao'
import { withRouter } from 'react-router-dom'
import iconMaterial from '../../../../../../../../img/materiais.svg'
import ReactTooltip from 'react-tooltip'
import { MENU_MATERIAIS } from '../../../../../precos/preco'

const defaultState = {
  exibirDrawerAdicionar: false,
  materialEdicao: null,
  materialExclusao: null
}

class MateriaisComposicao extends Component {
  static propTypes = {
    insumos: PropTypes.object.isRequired,
    codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    permitirEdicao: PropTypes.bool,
    classes: PropTypes.object,
    contexto: PropTypes.object,
    history: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = defaultState
    this.handleDadosAlterados = this.handleDadosAlterados.bind(this)
    this.handleConfirmarExclusaoMaterial = this.handleConfirmarExclusaoMaterial.bind(this)
    this.handleMateriaisClick = this.handleMateriaisClick.bind(this)
  }

  async handleConfirmarExclusaoMaterial () {
    const { codigoComposicao, contexto, onDadosAlterados } = this.props
    const { materialExclusao } = this.state
    const codigoMaterial = materialExclusao.materialOrcamento.codigo
    const dados = await context_httpdelete(`precos/desvincular-material?composicao=${codigoComposicao}&material=${codigoMaterial}`, contexto)
    if (dados) {
      this.setState(defaultState)
      showSuccessMsg(`Material ${codigoMaterial} desvinculado da composição.`)
      onDadosAlterados()
    }
  }

  handleDadosAlterados() {
    const { onDadosAlterados } = this.props
    this.setState(defaultState)
    onDadosAlterados()
  }

  handleMateriaisClick () {
    const { contexto } = this.props
    this.props.history.push(`${urlContexto(contexto)}/precos`, { menuSelecionado: MENU_MATERIAIS })
  }

  render () {
    const { classes, insumos, permitirEdicao, codigoComposicao } = this.props
    const { exibirDrawerAdicionar, materialEdicao, materialExclusao } = this.state
    return (
      <>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '5%' }} className={classes.tableCell}>
                <MesmaLinha>
                  C
                  <IconButton data-tip='Clique para ver todos os materiais do orçamento' data-for='tooltip-material' onClick={this.handleMateriaisClick}>
                    <img width={21} height={21} src={iconMaterial} alt='Ver todos os materiais do orçamento' />
                  </IconButton>
                  <ReactTooltip id='tooltip-material' data-tip type='info' effect='float' place='left' />
                </MesmaLinha>
              </TableCell>
              <TableCell style={{ width: '40%' }} className={classes.tableCell}>
                Material ou Serviço de Terceiros
                {
                  permitirEdicao && (
                    <Button color='primary' size='small' onClick={() => this.setState({ exibirDrawerAdicionar: true })}>
                      + Adicionar
                    </Button>
                  )
                }
              </TableCell>
              <TableCell style={{ width: '15%' }} className={classes.tableCell}>Quantidade</TableCell>
              <TableCell style={{ width: '15%' }} className={classes.tableCell}>Unidade</TableCell>
              <TableCell style={{ width: '15%' }} className={classes.tableCell}>Preço Unitário</TableCell>
              <TableCell align={'right'} className={classes.tableCell}>Custo Unitário</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              insumos.materiais.map((materialComposicao) => (
                <TableRow key={materialComposicao.codigoMaterial}>
                  <TableCell className={classes.tableCell}>
                    {
                      permitirEdicao && (
                        <MesmaLinha>
                          <IconButton size='small' onClick={() => this.setState({ materialEdicao: materialComposicao })} color='primary'>
                            <EditIcon />
                          </IconButton>
                          <IconButton size='small' onClick={() => this.setState({ materialExclusao: materialComposicao })} color='primary'>
                            <DeleteIcon />
                          </IconButton>
                        </MesmaLinha>
                      )
                    }
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {materialComposicao.materialOrcamento.codigo} - {materialComposicao.materialOrcamento.descricao}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {formatarNumeroParaMoedaBR(materialComposicao.quantidade, 7, 7)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {materialComposicao.materialOrcamento.unidade}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {formatarNumeroParaMoedaBR(materialComposicao.materialOrcamento.precoUnitario, 2, 5)}
                  </TableCell>
                  <TableCell align={'right'} className={classes.tableCell}>
                    {formatarNumeroParaMoedaBR(materialComposicao.custoUnitario, 2, 5)}
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <ResumoCustoTotal classes={classes} titulo='Custo Unitário Total de Material' valorCusto={insumos.custoUnitarioTotalMateriais}/>
        <Divider className={classes.divider}/>
        {
          exibirDrawerAdicionar && (
            <StyledDrawer
              PaperProps={{ className: classes.drawerEdicao }}
              open={true}
              anchor={'right'}>
              <AdicionarMaterialComposicao
                codigoComposicao={codigoComposicao}
                onDadosAlterados={this.handleDadosAlterados}
                onCancelar={() => this.setState({ exibirDrawerAdicionar: false })}
              />
            </StyledDrawer>
          )
        }
        {
          materialEdicao != null && (
            <StyledDrawer
              PaperProps={{ className: classes.drawerEdicao }}
              open={true}
              anchor={'right'}>
              <EditarMaterialComposicao
                materialComposicao={materialEdicao}
                codigoComposicao={codigoComposicao}
                onCancelar={() => this.setState({ materialEdicao: null })}
                onDadosAlterados={this.handleDadosAlterados}
              />
            </StyledDrawer>
          )
        }
        {
          materialExclusao != null && (
            <ModalConfirmacao
              open
              titulo='Confirmar Exclusão de Material'
              confirmar={this.handleConfirmarExclusaoMaterial}
              onClose={() => this.setState({ materialExclusao: null })}>
              <Typography>
                Deseja realmente desvincular o material { materialExclusao.materialOrcamento.codigo } - { materialExclusao.materialOrcamento.descricao } da composição?
              </Typography>
            </ModalConfirmacao>
          )
        }
      </>
    )
  }
}

export default withRouter(connect(mapStateToPropsContexto)(withStyles(styles)(MateriaisComposicao)))
