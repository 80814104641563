import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core/styles'

const TitleTableCell = withStyles((theme) => ({
  head: {
    color: theme.palette.common.text,
    fontSize: '0.8rem',
    letterSpacing: '0.05rem'
  }
}))(TableCell);

export default TitleTableCell
