import React, { Fragment } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Paginacao from '../../comum/Paginacao'
import { paginar } from '../../../js/util'
import TitleTableCell from '../../comum/TitleTableCell'
import Insumo from './Insumo'
import PropTypes from 'prop-types'
import Alert from '@material-ui/lab/Alert'

const descColunaPreco = (tipoInsumo) => {
  switch (tipoInsumo) {
    case 'COMPOSICAO':
      return 'Custo Unitário'
    case 'EQUIPAMENTO':
      return 'Custo Produtivo'
    case 'MAO_DE_OBRA':
      return 'Custo'
    case 'MATERIAL':
      return 'Preço Unitário'
    default:
      return 'Custo'
  }
}

const ListaInsumos = ({
  tipoInsumo, insumos, totalItens, pagina, registrosPorPagina, onChangePage, onChangeRowsPerPage
}) => (
  insumos.length === 0 ? <Alert style={{ marginTop: 4, marginBottom: 4 }} severity='warning'>Não foram encontrados registros com esta pesquisa.</Alert> : (
    <Fragment>
      <Table id='tblListaInsumos' className='mt20'>
        <TableHead>
          <TableRow>
            <TitleTableCell width='10%'>Tipo</TitleTableCell>
            <TitleTableCell width='10%'>Código</TitleTableCell>
            <TitleTableCell width='60%'>Descrição</TitleTableCell>
            <TitleTableCell align='right' width='10%'>Unidade</TitleTableCell>
            <TitleTableCell align='right' width='10%'>{descColunaPreco(tipoInsumo)}</TitleTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            paginar(insumos, pagina, registrosPorPagina)
              .map((insumo) => (
                <Insumo key={insumo.codigo} value={insumo} />
              ))
          }
        </TableBody>
      </Table>
      <Paginacao
        total={totalItens}
        registrosPorPagina={registrosPorPagina}
        pagina={pagina}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </Fragment>
  )
)

ListaInsumos.propTypes = {
  tipoInsumo: PropTypes.string,
  insumos: PropTypes.array,
  totalItens: PropTypes.number,
  pagina: PropTypes.number,
  registrosPorPagina: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func
}

export default ListaInsumos
