import React from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { converterMoedaBRParaNumero, formatarNumeroParaMoedaBR, handleCampoNumericoChange } from '../../../../../../../../js/util'
import PropTypes from 'prop-types'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import StyledFormControl from '../../../../../../../comum/StyledFormControl'
import { Select } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { UFS } from '../../../../../../../../js/util'

const FormularioMaoDeObraComposicao = ({
  codigo, codigoEmpty, codigoEditavel,
  descricao, descricaoEmpty,
  unidade, unidadeEmpty,
  quantidade, quantidadeEmpty,
  salario, salarioEmpty,
  encargosTotais, encargosTotaisEmpty,
  periculosidadeInsalubridade, periculosidadeInsalubridadeEmpty,
  handleCampoTextoChange, onSalvar, onCancelar
}) => {
  const salarioN = converterMoedaBRParaNumero(salario)
  const encargosN = converterMoedaBRParaNumero(encargosTotais)
  const periculosidadeN = converterMoedaBRParaNumero(periculosidadeInsalubridade)
  const custo = formatarNumeroParaMoedaBR(salarioN * (1 + (encargosN / 100) + (periculosidadeN / 100)), 2, 4)

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSalvar();
    }
  };

  const handleEscKeyPress = (event) => {
    if (event.key === 'Escape') {
      onCancelar();
    }
  };

  React.useEffect(() => {
    document.body.addEventListener('keydown', handleEnterKeyPress);
    document.body.addEventListener('keydown', handleEscKeyPress);
    return () => {
      document.body.removeEventListener('keydown', handleEnterKeyPress);
      document.body.removeEventListener('keydown', handleEscKeyPress);
    };
  }, []);


  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={3}>
        <Box ml={2}>
          <FormControl variant='outlined'>
            <Typography variant='subtitle1'>Código</Typography >
            <TextField
              variant='outlined'
              value={codigo}
              disabled={!codigoEditavel}
              placeholder='Ex: P9500'
              error={codigoEmpty}
              onChange={(e) => handleCampoTextoChange(e, 'codigo')}
            />
          </FormControl>
          </Box>
        </Grid>


        <Grid item xs={12} md={10}>
        <Box ml={2}>
          <FormControl variant='outlined' fullWidth>
            <Typography variant='subtitle1'>Descrição</Typography>
            <TextField
              variant='outlined'
              multiline
              rows={3}
              value={descricao}
              error={descricaoEmpty}
              onChange={(e) => handleCampoTextoChange(e, 'descricao')}
            />
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
        <Box ml={2}>
          <FormControl variant='outlined' fullWidth>
            <Typography variant='subtitle1'>Unidade</Typography>
            <TextField
              variant='outlined'
              value={unidade}
              error={unidadeEmpty}
              onChange={(e) => handleCampoTextoChange(e, 'unidade')}
            />
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl variant='outlined' fullWidth>
            <Typography variant='subtitle1'>Quantidade</Typography>
            <TextField
              variant='outlined'
              value={quantidade}
              error={quantidadeEmpty}
              onChange={(e) => handleCampoNumericoChange(e, (e) => handleCampoTextoChange(e, 'quantidade'))}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>      
          <FormControl variant='outlined' fullWidth>
            <Typography variant='subtitle1'>Salário</Typography>
            <TextField
              variant='outlined'
              value={salario}
              error={salarioEmpty}
              onChange={(e) => handleCampoNumericoChange(e, (e) => handleCampoTextoChange(e, 'salario'))}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
        <Box ml={2}>
          <FormControl variant='outlined' fullWidth>
            <Typography variant='subtitle1'>Encargos Totais</Typography>
            <TextField
              variant='outlined'
              value={encargosTotais}
              error={encargosTotaisEmpty}
              onChange={(e) => handleCampoNumericoChange(e, (e) => handleCampoTextoChange(e, 'encargosTotais'))}
            />
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl variant='outlined' fullWidth>
            <Typography variant='subtitle1'>Periculosidade / Insalubridade</Typography>
            <TextField
              variant='outlined'
              value={periculosidadeInsalubridade}
              error={periculosidadeInsalubridadeEmpty}
              onChange={(e) => handleCampoNumericoChange(e, (e) => handleCampoTextoChange(e, 'periculosidadeInsalubridade'))}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormControl variant='outlined' fullWidth>
            <Typography variant='subtitle1'>Custo</Typography>
            <TextField
              variant='outlined'
              value={custo}
              disabled
            />
          </FormControl>
        </Grid>
      </Grid>
      <Box mt={3} ml={2}>
        <MesmaLinha>
          <Button variant='contained' color='primary' onClick={onSalvar}>Salvar</Button>
          <Button variant='text' color='primary' onClick={onCancelar}>Cancelar</Button>
        </MesmaLinha>
      </Box>
    </>
  )
}

FormularioMaoDeObraComposicao.propTypes = {
  codigo: PropTypes.string.isRequired,
  codigoEmpty: PropTypes.bool.isRequired,
  codigoEditavel: PropTypes.bool.isRequired,
  descricao: PropTypes.string.isRequired,
  descricaoEmpty: PropTypes.bool.isRequired,
  unidade: PropTypes.string.isRequired,
  unidadeEmpty: PropTypes.bool.isRequired,
  quantidade: PropTypes.string.isRequired,
  quantidadeEmpty: PropTypes.bool.isRequired,
  salario: PropTypes.string.isRequired,
  salarioEmpty: PropTypes.bool.isRequired,
  encargosTotais: PropTypes.string.isRequired,
  encargosTotaisEmpty: PropTypes.bool.isRequired,
  periculosidadeInsalubridade: PropTypes.string.isRequired,
  periculosidadeInsalubridadeEmpty: PropTypes.bool.isRequired,
  handleCampoTextoChange: PropTypes.func.isRequired,
  onSalvar: PropTypes.func.isRequired,
  onCancelar: PropTypes.func.isRequired,
  classes: PropTypes.object
}

export default FormularioMaoDeObraComposicao
