import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import TabelaMemoriaCalculo from './TabelaMemoriaCalculo'
import PropTypes from 'prop-types'
import Draggable from 'react-draggable'
import { DialogTitle, Paper, Typography, withStyles } from '@material-ui/core'
import styles from '../styles'

const ModalMemoriaCalculoDraggable = (props) => {
  return (
    <Draggable
      handle='#draggable-dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

const ModalMemoriaCalculo = ({ open, close, memorias, classes }) => {
  return (
    <Dialog
      open={open}
      onClose={close}
      fullWidth
      aria-labelledby='draggable-dialog-title'
      PaperComponent={ModalMemoriaCalculoDraggable}
    >

      <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title' onClose={close}>
        <div>
          <Typography variant='h5' className={classes.tituloModal}>
            Memória de Cálculo
          </Typography>
        </div>
      </DialogTitle>

      <DialogContent>
        <TabelaMemoriaCalculo
          memorias={memorias}
        />
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={close} variant='contained' color='primary'>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ModalMemoriaCalculo.propTypes = {
  memorias: PropTypes.array,
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  classes: PropTypes.object
}

export default withStyles(styles)(ModalMemoriaCalculo)
