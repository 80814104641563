import { BUSCAR_ITENS_ORCAMENTO } from '../actions/types'

const reducerItensOrcamento = (state = [], action) => {
  switch (action.type) {
    case BUSCAR_ITENS_ORCAMENTO:
      return action.payload
    default:
      return state
  }
}

export default reducerItensOrcamento
