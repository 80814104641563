import { CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete } from '@mui/material'
import React, { useState } from 'react'
import axios from 'axios'
import _ from 'lodash'
import PropTypes from 'prop-types'

const UnidadeJurisdicionadaAutoComplete = ({ change, error, obraEdicao, options }) => {
  const [fetchData, setFetchData] = useState(obraEdicao ? [obraEdicao.uj] : options?options:[])
  const [value, setValue] = useState(obraEdicao ? [obraEdicao.uj] : [])
  const [loading, setLoading] = useState(false)

  const handleBuscaUj = async (e) => {
    setLoading(true)
    const { data } = await axios.get(`/api/ujs?nome=${e}`)
    setFetchData(data)
    setLoading(false)
  }

  const buscarUj = _.debounce((text) => {
    if (text && text.length > 4) {
      handleBuscaUj(text)
    }
  }, 1000)

  return (
      <Autocomplete
      defaultValue={value && value.length > 0 ? value[0] : null}
      options={fetchData}
      size='small'
      getOptionLabel={(option) => option ? option.nome : ''}
      onInputChange={(e) => buscarUj(e?.target?.value)}
      onChange={(e, t) => {
        setValue(t)
        change(t)
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            !error
              ? 'Pesquise e selecione a unidade jurisdicionada'
              : 'Unidade jurisdicionada é obrigatório'
          }
          error={error}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

UnidadeJurisdicionadaAutoComplete.propTypes = {
  change: PropTypes.func,
  error: PropTypes.bool,
  obraEdicao: PropTypes.object,
  options: PropTypes.array
}

export default UnidadeJurisdicionadaAutoComplete
