export const AUTHENTICATION_FAILED = '@authenticationJwtActions/AUTHENTICATION_FAILED'
export const USER_AUTHENTICATED = '@authenticationJwtActions/USER_AUTHENTICATED'
export const USER_LOGOUT = '@authenticationJwtActions/USER_LOGOUT'
export const TOKEN_LOGIN_INTEGRADO_GERADO = '@authenticationJwtActions/TOKEN_LOGIN_INTEGRADO_GERADO'
export const ERRO_AO_GERAR_TOKEN_LOGIN_INTEGRADO = '@authenticationJwtActions/ERRO_AO_GERAR_TOKEN_LOGIN_INTEGRADO'

export const BUSCAR_EMPREENDIMENTOS = 'BUSCAR_EMPREENDIMENTOS'
export const BUSCAR_TODOS_EMPREENDIMENTOS = 'BUSCAR_TODOS_EMPREENDIMENTOS'
export const ALTERAR_EMPREENDIMENTO = 'ALTERAR_EMPREENDIMENTO'
export const CADASTRAR_EMPREENDIMENTO = 'CADASTRAR_EMPREENDIMENTO'
export const EXCLUIR_EMPREENDIMENTO = 'EXCLUIR_EMPREENDIMENTO'

export const BUSCAR_OBRAS = 'BUSCAR_OBRAS'
export const ALTERAR_OBRA = 'ALTERAR_OBRA'
export const CADASTRAR_OBRA = 'CADASTRAR_OBRA'
export const EXCLUIR_OBRA = 'EXCLUIR_OBRA'

export const BUSCAR_TIPOS_OBRA = 'BUSCAR_TIPOS_OBRA'

export const BUSCAR_ORCAMENTOS = 'BUSCAR_ORCAMENTOS'
export const BUSCAR_TIPOS_ORCAMENTOS = 'BUSCAR_TIPOS_ORCAMENTOS'
export const CADASTRAR_ORCAMENTO = 'CADASTRAR_ORCAMENTO'
export const ALTERAR_ORCAMENTO = 'ALTERAR_ORCAMENTO'
export const EXCLUIR_ORCAMENTO = 'EXCLUIR_ORCAMENTO'
export const DUPLICAR_ORCAMENTO = 'DUPLICAR_ORCAMENTO'
export const CARREGAR_ORCAMENTO_EM_EDICAO = 'CARREGAR_ORCAMENTO_EM_EDICAO'
export const CARREGAR_REFERENCIAS_PRECO_ORCAMENTO_EM_EDICAO = 'CARREGAR_REFERENCIAS_PRECO_ORCAMENTO_EM_EDICAO'

export const BUSCAR_ITENS_ORCAMENTO = 'BUSCAR_ITENS_ORCAMENTO'

export const DEFINIR_CONTEXTO = 'DEFINIR_CONTEXTO'
export const CONCLUIR_ORCAMENTO_CONTEXTO = 'CONCLUIR_ORCAMENTO_CONTEXTO'

export const ALTERAR_EXIBICAO_COLUNA = 'ALTERAR_EXIBICAO_COLUNA'

export const FILTRAR_ANALISE_ORCAMENTO = 'FILTRAR_ANALISE_ORCAMENTO'
export const LIMPAR_FILTRO_ANALISE_ORCAMENTO = 'FILTRAR_ANALISE_ORCAMENTO'

export const BUSCAR_TIPOS_UNIDADE_USUARIO = 'BUSCAR_TIPO_UNIDADES_USUARIO'

export const BUSCAR_UJS = 'BUSCAR_UJS'
