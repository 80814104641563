import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import { converterMoedaBRParaNumero, showSuccessMsg, mapStateToPropsContexto } from '../../../../../js/util';
import { context_httpput } from '../../../../../js/httpRequest';
import { connect } from 'react-redux';

const RelatorioMaoDeObra = ({ maoDeObra, contexto }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedMaoDeObra, setEditedMaoDeObra] = useState({ ...maoDeObra });
  const [initialValues, setInitialValues] = useState({ ...maoDeObra });

  const handleEdit = () => {
    setEditMode(true);
    console.log(maoDeObra)
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setEditedMaoDeObra({ ...maoDeObra });
  };

  const handleSave = async () => {
    const { codigo, descricao, unidade, salario, encargosTotais, custo, periculosidadeInsalubridade } = editedMaoDeObra;

    const salarioChanged = salario !== initialValues.salario;
    const encargosTotaisChanged = encargosTotais !== initialValues.encargosTotais;

    const convertedSalario = salarioChanged
      ? converterMoedaBRParaNumero(salario.toString())
      : salario;

    const convertedEncargosTotais = encargosTotaisChanged
      ? converterMoedaBRParaNumero(encargosTotais.toString())
      : encargosTotais;

    const dadosSalvar = {
      codigo,
      descricao,
      unidade,
      custo,
      salario: convertedSalario,
      encargosTotais: convertedEncargosTotais,
      periculosidadeInsalubridade
    };

    const url = `precos/maos-de-obra/${editedMaoDeObra.codigo}`;
    const resultado = await context_httpput(url, contexto, dadosSalvar);
    if (resultado) {
      showSuccessMsg('Dados da mão de obra alterados com sucesso.');
      setEditMode(false);
      window.location.reload();
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name !== 'codigo') {
      setEditedMaoDeObra((prevMaoDeObra) => ({
        ...prevMaoDeObra,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    setInitialValues({ ...maoDeObra });
  }, [maoDeObra]);

  return (
    <div>
      {editMode ? (
        <div>
          <TextField
            name="codigo"
            label="Código"
            value={editedMaoDeObra.codigo}
            onChange={handleChange}
            InputProps={{ readOnly: true }} 
          />
          <TextField
            name="descricao"
            label="Descrição"
            value={editedMaoDeObra.descricao}
            onChange={handleChange}
            style={{ width: '100%' }}
          />
          <TextField
            name="unidade"
            label="Unidade"
            value={editedMaoDeObra.unidade}
            onChange={handleChange}
          />
          <TextField
            name="salario"
            label="Salário"
            value={editedMaoDeObra.salario.toLocaleString('pt-BR')}
            onChange={handleChange}
          />
          <TextField
            name="encargosTotais"
            label="Encargos Totais"
            value={editedMaoDeObra.encargosTotais.toLocaleString('pt-BR')}
            onChange={handleChange}
          />
          <TextField
            name="custo"
            label="Custo"
            value={editedMaoDeObra.custo.toLocaleString('pt-BR')}
            onChange={handleChange}
            InputProps={{ readOnly: true }} 
          />
          <TextField
            name="periculosidadeInsalubridade"
            label="Periculosidade/Insalubridade"
            value={editedMaoDeObra.periculosidadeInsalubridade}
            onChange={handleChange}
          />
          <IconButton color="primary" onClick={handleSave}>
            <DoneIcon />
          </IconButton>
          <IconButton color="primary" onClick={handleCancelEdit}>
            <ClearIcon />
          </IconButton>
        </div>
      ) : (
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">Mão de Obra</Typography>
            <IconButton color="primary" onClick={handleEdit} style={{ marginLeft: '8px' }}>
              <EditIcon />
            </IconButton>
          </div>
          <Typography>Código: {maoDeObra.codigo}</Typography>
          <Typography>Descrição: {maoDeObra.descricao}</Typography>
          <Typography>Unidade: {maoDeObra.unidade}</Typography>
          <Typography>Salário: {maoDeObra.salario.toLocaleString('pt-BR')}</Typography>
          <Typography>Encargos Totais: {maoDeObra.encargosTotais.toLocaleString('pt-BR')}</Typography>
          <Typography>Custo: {maoDeObra.custo.toLocaleString('pt-BR')}</Typography>
          <Typography>Periculosidade/Insalubridade: {maoDeObra.periculosidadeInsalubridade}</Typography>
        </div>
      )}
    </div>
  );
};

RelatorioMaoDeObra.propTypes = {
  maoDeObra: PropTypes.object.isRequired,
  contexto: PropTypes.object.isRequired,
};

export default connect(mapStateToPropsContexto)(RelatorioMaoDeObra);