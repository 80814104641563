import { pageHeaderAndFooter, round, tableFoot } from './RelatorioComposicaoPdf'
import _ from 'lodash'
import { formatarNumeroParaMoedaBR } from '../../../../../../../js/util'
import { calcularValorBDI } from '../../../../precos/preco'

const valorDmt = (transporte, composicoesAuxiliares) => {
  if (!_.isNil(transporte.dmt)) {
    return transporte.dmt
  }
  const dmtPadrao = composicoesAuxiliares.dmtsPadrao.find((it) => (
    it.codComposicao === transporte.codigoComposicaoTransporte &&
    it.codInsumo === transporte.codigoInsumoTransportado &&
    it.tipo === transporte.tipoTransporte
  ))

  return _.isNil(dmtPadrao) ? 0 : dmtPadrao.distancia
}

const textoDmt = (transporte, composicoesAuxiliares) => {
  if (!_.isNil(transporte.dmt)) {
    return formatarNumeroParaMoedaBR(transporte.dmt, 2, 5)
  }
  const dmtPadrao = composicoesAuxiliares.dmtsPadrao.find((it) => (
    it.codComposicao === transporte.codigoComposicaoTransporte &&
    it.codInsumo === transporte.codigoInsumoTransportado &&
    it.tipo === transporte.tipoTransporte
  ))

  return _.isNil(dmtPadrao) ? '0,00' : `${formatarNumeroParaMoedaBR(dmtPadrao.distancia, 2, 5)} (DMT padrão)`
}

export function dadosTransporte (doc, detalhes, itemCurvaAbc, titulo) {
  const tableHeaderMomentoDeTransporte = [
    { dataKey: 'descricao', title: 'F - Momento de Transporte' },
    { dataKey: 'tipo', title: 'Tipo' },
    { dataKey: 'quantidade', title: 'Quantidade' },
    { dataKey: 'unidade', title: 'Unidade' },
    { dataKey: 'dmt', title: 'DMT' },
    { dataKey: 'servTransp', title: 'Serv. Transp.' },
    { dataKey: 'custo', title: 'Custo' },
    { dataKey: 'custoUnitario', title: '   Custo Unitário' }
  ]

  const tableDataMomentoDeTransporte = []
  detalhes.composicoesAuxiliares.transportes.forEach((transporte) => {
    tableDataMomentoDeTransporte.push({
      descricao: transporte.codigoInsumoTransportado + ' - ' + transporte.composicaoTransporte.descricao,
      tipo: transporte.tipoTransporte,
      quantidade: round(transporte.quantidade, 4),
      unidade: transporte.composicaoTransporte.unidade,
      dmt: textoDmt(transporte, detalhes.composicoesAuxiliares),
      servTransp: transporte.codigoComposicaoTransporte,
      custo: round(detalhes.composicoesAuxiliares.catalogoPrecos[transporte.codigoComposicaoTransporte], 5),
      custoUnitario: round(transporte.quantidade * detalhes.composicoesAuxiliares.catalogoPrecos[transporte.codigoComposicaoTransporte] *
        valorDmt(transporte, detalhes.composicoesAuxiliares), 5)
    })
  })
  doc.autoTable(tableHeaderMomentoDeTransporte, tableDataMomentoDeTransporte, {
    startY: doc.lastAutoTable.finalY,
    tableWidth: 'auto',
    margin: { top: 20 },
    tableLineColor: 255,
    tableLineWidth: 1,
    fontSize: 9,
    styles: { halign: 'right', cellPadding: 1 },
    headStyles: {
      fillColor: [200, 200, 200],
      textColor: [0, 0, 0],
      descricao: {
        overflow: 'linebreak',
        cellWidth: 350,
        valign: 'middle',
        halign: 'center',
        fontStyle: 'bold'
      }
    },
    columnStyles: {
      descricao: {
        overflow: 'linebreak',
        cellWidth: 350,
        valign: 'middle',
        halign: 'left',
        fontStyle: 'bold'
      }
    },
    didDrawPage: (data) => pageHeaderAndFooter(doc, data, titulo),
    willDrawCell: function (data) {
      if (data.column.index === 0 && data.cell.section === 'head') {
        data.cell.styles.halign = 'left'
      }
    }
  })

  tableFoot([{
    label: 'Custo Unitário Total de Transporte',
    value: round(detalhes.composicoesAuxiliares.custoUnitarioTotalTransportes, 2)
  }],
  doc)

  tableFoot([{
    label: 'Custo Unitário Direto Total',
    value: round(detalhes.custoUnitarioDiretoTotal, 2)
  }, {
    label: `BDI   (${!_.isNil(itemCurvaAbc) ? itemCurvaAbc.bdi : 0}%)`,
    value: formatarNumeroParaMoedaBR(calcularValorBDI(itemCurvaAbc, detalhes.custoUnitarioDiretoTotal), 2, 2)
  }, {
    label: 'Preço Unitário Total',
    value: formatarNumeroParaMoedaBR(calcularValorBDI(itemCurvaAbc, detalhes.custoUnitarioDiretoTotal) + detalhes.custoUnitarioDiretoTotal, 2, 2)
  }],
  doc)
}
