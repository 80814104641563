import { withStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'

const StyledTableRowComparar = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#fff',
    },
  },
}))(TableRow);

export default StyledTableRowComparar
