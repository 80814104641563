import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { converterMoedaBRParaNumero, formatarNumeroParaMoedaBR, mapStateToPropsContexto, seStringVazia, showSuccessMsg } from '../../../../../../../../js/util'
import Box from '@material-ui/core/Box'
import TituloVoltar from '../../../../../../../comum/TituloVoltar'
import { context_httpput } from '../../../../../../../../js/httpRequest'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FormularioMaoDeObraComposicao from './FormularioMaoDeObraComposicao'

class EditarMaoDeObraComposicao extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string.isRequired,
    maoDeObraComposicao: PropTypes.object.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired,
    contexto: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      quantidade: '',
      quantidadeEmpty: false,
      descricao: '',
      descricaoEmpty: false,
      unidade: '',
      unidadeEmpty: false,
      salario: '',
      salarioEmpty: false,
      encargosTotais: '',
      encargosTotaisEmpty: false,
      periculosidadeInsalubridade: '',
      periculosidadeInsalubridadeEmpty: false
    }
    this.handleSalvarClick = this.handleSalvarClick.bind(this)
    this.handleCampoTextoChange = this.handleCampoTextoChange.bind(this)
  }

  componentDidMount () {
    if (this.props.maoDeObraComposicao) {
      this.copiarDadosParaEdicao()
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.maoDeObraComposicao !== this.props.maoDeObraComposicao) {
      this.copiarDadosParaEdicao()
    }
  }

  copiarDadosParaEdicao() {
    const { maoDeObraComposicao } = this.props
    this.setState({
      quantidade: formatarNumeroParaMoedaBR(maoDeObraComposicao.quantidade, 7, 7),
      descricao: maoDeObraComposicao.maoDeObraOrcamento.descricao,
      unidade: maoDeObraComposicao.maoDeObraOrcamento.unidade,
      salario: formatarNumeroParaMoedaBR(maoDeObraComposicao.maoDeObraOrcamento.salario, 2, 5),
      encargosTotais: formatarNumeroParaMoedaBR(maoDeObraComposicao.maoDeObraOrcamento.encargosTotais, 2, 5),
      periculosidadeInsalubridade: formatarNumeroParaMoedaBR(maoDeObraComposicao.maoDeObraOrcamento.periculosidadeInsalubridade, 2, 5)
    })
  }

  handleCampoTextoChange (event, campo) {
    const state = {}
    state[campo] = event.target.value
    state[`${campo}Empty`] = seStringVazia(event.target.value)
    this.setState(state)
  }

  async handleSalvarClick () {
    const { quantidade, descricao, unidade, salario, encargosTotais, periculosidadeInsalubridade } = this.state
    const quantidadeEmpty = seStringVazia(quantidade)
    const descricaoEmpty = seStringVazia(descricao)
    const unidadeEmpty = seStringVazia(unidade)
    const salarioEmpty = seStringVazia(salario)
    const encargosTotaisEmpty = seStringVazia(encargosTotais)
    const periculosidadeInsalubridadeEmpty = seStringVazia(periculosidadeInsalubridade)

    if ([quantidadeEmpty, descricaoEmpty, unidadeEmpty, salarioEmpty, encargosTotaisEmpty, periculosidadeInsalubridadeEmpty].some(Boolean)) {
      this.setState({ quantidadeEmpty, descricaoEmpty, unidadeEmpty, salarioEmpty, encargosTotaisEmpty, periculosidadeInsalubridadeEmpty })
    } else {
      const dadosSalvar = {
        quantidade: converterMoedaBRParaNumero(quantidade),
        descricao,
        unidade,
        salario: converterMoedaBRParaNumero(salario),
        encargosTotais: converterMoedaBRParaNumero(encargosTotais),
        periculosidadeInsalubridade: converterMoedaBRParaNumero(periculosidadeInsalubridade)
      }
      const { contexto, codigoComposicao, onDadosAlterados, maoDeObraComposicao } = this.props
      const url = `precos/editar-mao-de-obra?composicao=${codigoComposicao}&mao-de-obra=${maoDeObraComposicao.maoDeObraOrcamento.codigo}`
      const resultado = await context_httpput(url, contexto, dadosSalvar)
      if (resultado) {
        showSuccessMsg('Dados da mão de obra na composição alterados com sucesso.')
        onDadosAlterados()
      }
    }
  }
  render () {
    const { maoDeObraComposicao, onCancelar } = this.props

    const {
      quantidade,
      quantidadeEmpty,
      descricao,
      descricaoEmpty,
      unidade,
      unidadeEmpty,
      salario,
      salarioEmpty,
      encargosTotais,
      encargosTotaisEmpty,
      periculosidadeInsalubridade,
      periculosidadeInsalubridadeEmpty
    } = this.state

    return (
      <div>
        <TituloVoltar onClick={onCancelar} label='Editar Mão de Obra da Composição'/>
        <Box mt={2}>
          <Typography variant='overline'>
            { maoDeObraComposicao.maoDeObraOrcamento.codigo } - { maoDeObraComposicao.maoDeObraOrcamento.descricao }
          </Typography>
        </Box>
        <FormularioMaoDeObraComposicao
          codigo={maoDeObraComposicao.maoDeObraOrcamento.codigo}
          codigoEmpty={false}
          codigoEditavel={false}
          descricao={descricao}
          descricaoEmpty={descricaoEmpty}
          unidade={unidade}
          unidadeEmpty={unidadeEmpty}
          quantidade={quantidade}
          quantidadeEmpty={quantidadeEmpty}
          salario={salario}
          salarioEmpty={salarioEmpty}
          encargosTotais={encargosTotais}
          encargosTotaisEmpty={encargosTotaisEmpty}
          periculosidadeInsalubridade={periculosidadeInsalubridade}
          periculosidadeInsalubridadeEmpty={periculosidadeInsalubridadeEmpty}
          handleCampoTextoChange={this.handleCampoTextoChange}
          onSalvar={this.handleSalvarClick}
          onCancelar={onCancelar}
        />
      </div>
    )
  }
}

export default connect(mapStateToPropsContexto)(EditarMaoDeObraComposicao)
