import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import Paper from '@material-ui/core/Paper'
import { computarPagina } from '../../../../../js/util'
import Paginacao from '../../../../comum/Paginacao'
import CabecalhoDetalheItemRelatorioHistoricoOrcamento from './CabecalhoDetalheItemRelatorioHistoricoOrcamento'
import CorpoDetalheItemRelatorioHistoricoOrcamento from './CorpoDetalheItemRelatorioHistoricoOrcamento'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    boxShadow: 'none'
  },
  table: {
    minWidth: 890
  },
  tableWrapper: {
    overflowX: 'auto'
  }
})

const defaultState = {
  pagina: 0,
  registrosPorPagina: 10
}

class TabelaDetalheItemRelatorioHistoricoOrcamento extends Component {
  static propTypes = {
    classes: PropTypes.object,
    itensDetalheHistoricoOrcamento: PropTypes.array
  }

  constructor (props) {
    super(props)
    this.state = defaultState
  }

  paginaComputada = () => {
    const { pagina, registrosPorPagina } = this.state
    const { itensDetalheHistoricoOrcamento } = this.props
    return computarPagina(pagina, registrosPorPagina, itensDetalheHistoricoOrcamento.length)
  }

  render () {
    const { classes, itensDetalheHistoricoOrcamento } = this.props
    const { pagina, registrosPorPagina } = this.state

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper} style={{ overflowX: 'hidden' }}>
          <Table className={classes.table}>
            <CabecalhoDetalheItemRelatorioHistoricoOrcamento/>
            <CorpoDetalheItemRelatorioHistoricoOrcamento
              itensDetalheHistoricoOrcamento={itensDetalheHistoricoOrcamento}
              pagina={this.paginaComputada()}
              registrosPorPagina={registrosPorPagina}
            />
          </Table>
          <Paginacao
            pagina={pagina}
            registrosPorPagina={registrosPorPagina}
            total={itensDetalheHistoricoOrcamento.length}
            onChangePage={(e, pag) => this.setState({ pagina: pag })}
            onChangeRowsPerPage={(e) => this.setState({ registrosPorPagina: e.target.value })}
          />
        </div>
      </Paper>
    )
  }
}

export default withStyles(styles)(TabelaDetalheItemRelatorioHistoricoOrcamento)
