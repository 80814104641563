const styles = (t) => ({
  menuListas: {
    display: 'flex',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    '& button ': {
      marginRight: 8
    }
  },
  menuAtivado: { 
    background: t.palette.text.primary,
    color: '#fff',
    '&:hover': {
      background: t.palette.text.primary,
      color: '#fff',
      boxShadow: 'none'
    },
    '&:active': {
      background: t.palette.text.primary,
      color: '#fff',
      borderColor: '#005cbf'
    },
    '&:focus': {
      background: t.palette.text.primary,
      color: '#fff'
    },
    width: 173,
    marginRight: 10,
    textTransform: 'uppercase'
  },
  table: {
    maxWidth: '100%',
    [t.breakpoints.down('md')]: {
      maxWidth: '100%'
    },

    // Refatorar para aplicar no tema
    marginTop: 24,
    marginBottom: 8,
    '& tr:hover': {
      // background: '#e6f3fe',
      cursor: 'pointer'
    }
  },
  voltar: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
  },
  iconeComTexto: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: 8
    }
  },
  textoCampoEditavel: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(217, 231, 242)'
    }
  }
})

export default styles
