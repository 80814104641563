import { ALTERAR_OBRA, BUSCAR_OBRAS, CADASTRAR_OBRA, EXCLUIR_OBRA } from '../actions/types'

const reducerObras = (state = [], action) => {
  switch (action.type) {
    case BUSCAR_OBRAS:
      return action.payload.data
    case ALTERAR_OBRA:
      return state.map((e) => (e.cod === action.payload.cod ? action.payload : e))
    case EXCLUIR_OBRA:
      return state.filter((e) => e.cod !== action.payload.cod)
    case CADASTRAR_OBRA:
      return [action.payload].concat(state)
    default:
      return state
  }
}

export default reducerObras
