import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { UFS } from '../../../js/util'
import ComboBox from '../../comum/ComboBox'
import PropTypes from 'prop-types'

const UFs = ({value, empty, onChange}) => (
  <ComboBox
    fullWidth={false}
    label='Selecione a UF'
    value={value}
    empty={empty}
    onChange={onChange}
  >

    {
      UFS.map((uf) => (
          <MenuItem value={uf.sigla} key={uf.sigla}>
            <Typography>{uf.nome}</Typography>
          </MenuItem>
        )
      )
    }
  </ComboBox>
)

UFs.propTypes = {
  value: PropTypes.string.isRequired,
  empty: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default UFs
