import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import TituloVoltar from '../../../../../../../comum/TituloVoltar'
import FormularioAdicionarMaoDeObraComposicao from './FormularioAdicionarMaoDeObraComposicao'
import AdicionarMaoDeObraComposicaoSelecionarDoOrcamento from './AdicionarMaoDeObraComposicaoSelecionarDoOrcamento'
import AdicionarMaodeObraComposicaoPesquisarEmReferenciasDePrecos from './AdicionarMaodeObraComposicaoPesquisarEmReferenciasDePrecos'

const ESTE_ORCAMENTO = 0
const CRIAR = 1
const REFERENCIA_PRECO = 2

class AdicionarEquipamentoComposicao extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      modo: 2
    }
  }

  render() {
    const { codigoComposicao, onDadosAlterados, onCancelar } = this.props
    const { modo } = this.state

    return (
      <>
        <TituloVoltar onClick={onCancelar} label='Vincular mão de obra a composição'/>
        <Box my={2}>
          <TextField variant='outlined' value={modo} select onChange={(e) => this.setState({ modo: e.target.value })} style={{minWidth: 500}}>
            <MenuItem value={REFERENCIA_PRECO}>Pesquisar em referências de preço</MenuItem>
            <MenuItem value={ESTE_ORCAMENTO}>Mãos de obra deste orçamento</MenuItem>
            <MenuItem value={CRIAR}>Criar nova mão de obra</MenuItem>
          </TextField>
        </Box>

        {
          modo === CRIAR && (
            <FormularioAdicionarMaoDeObraComposicao
              codigoComposicao={codigoComposicao}
              onCancelar={onCancelar}
              onDadosAlterados={onDadosAlterados}
            />
          )
        }
        {
          modo === ESTE_ORCAMENTO && (
            <AdicionarMaoDeObraComposicaoSelecionarDoOrcamento
              codigoComposicao={codigoComposicao}
              onCancelar={onCancelar}
              onDadosAlterados={onDadosAlterados}
            />
          )
        }
        {
          modo === REFERENCIA_PRECO && (
            <AdicionarMaodeObraComposicaoPesquisarEmReferenciasDePrecos
              codigoComposicao={codigoComposicao}
              onCancelar={onCancelar}
              onDadosAlterados={onDadosAlterados}
            />
          )
        }
      </>
    )
  }
}

export default AdicionarEquipamentoComposicao
