import ItemOrcamento from './ItemOrcamento'

export const calcularResumoArquivoOrcamento = (linhasBrutas) => {
  const itensOrcamento = linhasBrutas.map(
    (linhaBruta, index) => ItemOrcamento.fromLinhaBruta(index + 1, linhaBruta)
  )

  let valorGlobalInformado = 0
  let valorGlobalCalculado = 0
  let numeroLinhasOrcamentarias = 0
  let numeroLinhasNaoOrcamentarias = 0

  itensOrcamento.forEach((itemOrcamento) => {
    if (itemOrcamento.erros.length > 0) {
      numeroLinhasNaoOrcamentarias += 1
    } else {
      numeroLinhasOrcamentarias += 1
      valorGlobalInformado += itemOrcamento.precoParcialNumerico
      valorGlobalCalculado += itemOrcamento.precoParcialCalculado
    }
  })

  valorGlobalCalculado = valorGlobalCalculado.toFixed(2)

  return {
    valorGlobalInformado, valorGlobalCalculado, numeroLinhasNaoOrcamentarias, numeroLinhasOrcamentarias, itensOrcamento
  }
}

export const calcularValores = (itensSemValorCalculado) => itensSemValorCalculado.map((item) => ItemOrcamento.fromItem(item))
