import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { TIPO_INSUMO_COMPOSICAO_DESTE_ORCAMENTO, TIPO_INSUMO_COMPOSICAO_REFERENCIA_PRECO } from '../composicoesAuxiliares'
import Box from '@material-ui/core/Box'
import Select from 'react-select'
import { defaultStyleReactSelect, errorStyleReactSelect } from '../../../../../../../../js/util'
import PropsTypes from 'prop-types'
import _ from 'lodash'
import { Button, withStyles } from '@material-ui/core'
import StyledDrawer from '../../../../../../../comum/StyledDrawer'
import styles from '../../../styles'
import PesquisarReferenciaPrecoAlterarCodigoTransporte from '../transportes/PesquisarReferenciaPrecoAlterarCodigoTransporte'
import StyledFormControl from '../../../../../../../comum/StyledFormControl'

const ComposicaoAdicionarAlterarCodigoTransporte = ({
  classes,
  tipoComposicao,
  composicao,
  composicaoEmpty,
  composicoes,
  handleChange,
  nomeAtributo
}) => {
  const [exibirDrawerAdicionar, setExibirDrawerAdicionar] = useState(false)
  const [composicaoReferenciaPreco, setComposicaoReferenciaPreco] = useState(null)

  const handleChangeComposicaoReferenciaPrecoSelecionado = (composicao, fit) => {
    setComposicaoReferenciaPreco(composicao)
    handleChange(composicao, nomeAtributo)
    handleChange(fit, 'fit')
    
  }

  return (
    <>

{
        tipoComposicao === TIPO_INSUMO_COMPOSICAO_DESTE_ORCAMENTO.value && (
          <Box mt={1} mr={6}>
            <Select
              fullWidth
              datacy-id='tempoFixo'
              id='tempoFixo'
              classNamePrefix='select'
              placeholder={`Pesquisar uma composição${composicaoEmpty ? ' (Preenchimento obrigatório)' : ''}`}
              options={composicoes.map((composicao) => ({ ...composicao, label: `${composicao.codigo} - ${composicao.descricao}` }))}
              onChange={composicao => {
                handleChange(composicao, nomeAtributo);
                handleChange('', 'inputValue');
              }}
              styles={composicaoEmpty ? errorStyleReactSelect : defaultStyleReactSelect}
              value={composicao}
              filterOption={(option, searchText) =>
                option.label.toLowerCase().includes(searchText.toLowerCase())
              }
              isSearchable={true}
              style={{ maxHeight: '300px' }}
              onInputChange={() => handleChange('', 'inputValue')}
            />
          </Box>
        )
      }
     
     {
  tipoComposicao === TIPO_INSUMO_COMPOSICAO_REFERENCIA_PRECO.value && (
    <>
      <Box mt={1}>
        <Button
          color='primary'
          variant='outlined'
          size='small'
          onClick={() => setExibirDrawerAdicionar(true)}
        >
          Selecionar composição
        </Button>
        {
          exibirDrawerAdicionar && (
            <StyledDrawer
              PaperProps={{ className: classes.drawerEdicao }}
              open
              anchor='right'>
              <PesquisarReferenciaPrecoAlterarCodigoTransporte
                onCancelar={() => setExibirDrawerAdicionar(false)}
                isPesquisaComposicaoEmTempoFixo
                onChangeComposicaoSelecionada={handleChangeComposicaoReferenciaPrecoSelecionado}
              />
            </StyledDrawer>
          )
        }
      </Box>
      <Box mt={1}>
        {!_.isNil(composicaoReferenciaPreco) &&
          <Typography variant='button' gutterBottom style={{ marginRight: 10 }}>
            {`${composicaoReferenciaPreco.codigo} - ${composicaoReferenciaPreco.descricao}`}
          </Typography>
        }
      </Box>
    </>
  )
}
    </>
  )
}

ComposicaoAdicionarAlterarCodigoTransporte.propTypes = {
  classes: PropsTypes.object,
  tipoComposicao: PropsTypes.string,
  composicao: PropsTypes.object,
  composicaoEmpty: PropsTypes.bool,
  composicoes: PropsTypes.array,
  handleChange: PropsTypes.func.isRequired,
  nomeAtributo: PropsTypes.string
}

export default withStyles(styles)(ComposicaoAdicionarAlterarCodigoTransporte)
