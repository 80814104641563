import React from 'react'
import Button from '@material-ui/core/Button'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import PropTypes from 'prop-types'
import { showAlertMsg } from '../../../../../js/util'

const handleClickConfirmado = () => {
  showAlertMsg('Arquivo já confirmado, caso precise alterá-lo, use o botão Alterar Arquivo.')
}

const BotaoConfirmar = ({ confirmado, onClick }) => {
  const handleClick = confirmado ? handleClickConfirmado : onClick
  return (
    <Button variant='outlined' size='small' color='secondary' onClick={handleClick} startIcon={confirmado ? <CheckCircleIcon /> : null}>
      { confirmado ? 'Arquivo Confirmado' : 'Confirmar Arquivo' }
    </Button>
  )
}

BotaoConfirmar.propTypes = {
  confirmado: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default BotaoConfirmar
