import React from 'react'
import Typography from '@material-ui/core/Typography'
import PropsTypes from 'prop-types'

const NenhumItemCadastrado = ({ mensagem, variant }) => {
  return (
    <div>
      <Typography variant={variant}>{mensagem}</Typography>
    </div>
  )
}

NenhumItemCadastrado.propTypes = {
  mensagem: PropsTypes.string.isRequired,
  variant: PropsTypes.string
}

export default NenhumItemCadastrado
