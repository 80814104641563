import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import axios from 'axios'

import Template from '../../../../layout/Template'
import { CODE_NUMBER_BACKSPACE_EVENT, computarPagina, showErrorMsg, textNormalize, url } from '../../../../../js/util'
import { SLOW_REQUEST_CONFIG } from '../../../../../js/operationProgressUtil'
import CorpoRelatorioHistoricoOrcamento from './CorpoRelatorioHistoricoOrcamento'
import Paginacao from '../../../../comum/Paginacao'
import ModalDetalheItemRelatorioHistoricoOrcamento from './ModalDetalheItemRelatorioHistoricoOrcamento'
import Cabecalho from '../../orcamento-bruto/Cabecalho'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Carregando from '../../../../comum/Carregando'
import { Box, FormControl, IconButton, InputAdornment } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import CloseIcon from '@material-ui/icons/Close'

const styles = (theme) => ({
  table: {
    margin: theme.spacing(4),
    marginLeft: 'auto',
    marginRight: 'auto'
  }
})

const urlRelatorioHistoricoOrcamento = (params) => (
  `${url(params)}/relatorio/historico-orcamento`
)

const defaultState = {
  itensHistoricoOrcamento: null,
  itensHistoricoOrcamentoCopia: [],
  pagina: 0,
  registrosPorPagina: 10,
  open: false,
  itensDetalheHistoricoOrcamento: [],
  resumoOrcamento: null,
  termoBusca: '',
  tipoEvento: 'TODOS'
}

class RelatorioHistoricoOrcamento extends Component {
  static propTypes = {
    match: PropTypes.object,
    classes: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = defaultState
  }

  componentDidMount () {
    this.recuperarHistoricoOrcamento()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.match !== this.props.match) {
      this.recuperarHistoricoOrcamento()
    }
  }

  recuperarHistoricoOrcamento = () => {
    axios.get(urlRelatorioHistoricoOrcamento(this.props.match.params), SLOW_REQUEST_CONFIG)
      .then((resp) => {
        this.setState({
          itensHistoricoOrcamento: resp.data.listHistoricoOrcamento,
          itensHistoricoOrcamentoCopia: resp.data.listHistoricoOrcamento,
          resumoOrcamento: resp.data.resumoOrcamento
        })
      })
      .catch(() => (showErrorMsg('Erro ao recuperar o Histórico do Orçamento')))
  }

  recuperarItensDetalheHistoricoOrcamento = (item) => {
    axios.get(`${urlRelatorioHistoricoOrcamento(this.props.match.params)}/itens?dataInclusao=${item.dataInclusaoString}&tipoEvento=${item.tipoEvento}&descEventoItem=${item.descEventoItem}`, SLOW_REQUEST_CONFIG) // eslint-disable-line max-len
      .then((resp) => {
        this.setState({
          open: true,
          itensDetalheHistoricoOrcamento: resp.data
        })
      })
      .catch(() => showErrorMsg('Erro ao buscar itens no histórico do orçamento'))
  }

  handleOpen = (item) => {
    this.recuperarItensDetalheHistoricoOrcamento(item)
  }

  handleClose = () => {
    this.setState({
      open: false,
      itensDetalheHistoricoOrcamento: []
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleChangeTipoEvento = (e) => {
    const { itensHistoricoOrcamentoCopia } = this.state
    this.setState({
      [e.target.name]: e.target.value,
      itensHistoricoOrcamento: itensHistoricoOrcamentoCopia
    }, () => this.filtrarPorTipoEvento())
  }

  tiposEventos = () => {
    return [
      { label: 'Todos', value: 'TODOS' },
      { label: 'Inclusão', value: 'INCLUIR_ITEM' },
      { label: 'Alteração', value: 'EDITAR_ITEM' },
      { label: 'Exclusão', value: 'EXCLUIR_ITEM' },
      { label: 'Arquivo substituído', value: 'SUBSTITUIR_ARQUIVO' },
      { label: 'Agrupamento', value: 'AGRUPAR_ITEM' }
    ]
  }

  handleFiltrar(e) {
    const { itensHistoricoOrcamento, itensHistoricoOrcamentoCopia, termoBusca, tipoEvento } = this.state
    if(e.keyCode !== CODE_NUMBER_BACKSPACE_EVENT) {
      let itens = []
      if (termoBusca.length) {
        this.setState({ itensHistoricoOrcamento: this.filterItensPorTermo(termoBusca, itensHistoricoOrcamento) })
      } else {
        tipoEvento !== 'TODOS'
          ? this.setState({ itensHistoricoOrcamento: itens })
          : this.setState({ itensHistoricoOrcamento: itensHistoricoOrcamentoCopia })
      }
    } else {
      if(tipoEvento !== 'TODOS') {
        let itens = this.filterItensPorTermo(termoBusca, itensHistoricoOrcamentoCopia)
        this.setState({ itensHistoricoOrcamento: itens.filter((historico) => historico.tipoEvento === tipoEvento) })
      } else {
        this.setState({ itensHistoricoOrcamento: this.filterItensPorTermo(termoBusca, itensHistoricoOrcamentoCopia) })
      }
    }
  }

  filterItensPorTermo = (termoBusca, itens) =>
    itens.filter((historico) =>
      textNormalize(historico.descEventoItem)
        .toLowerCase()
        .search(textNormalize(termoBusca).toLowerCase()) !== -1)

  filtrarPorTipoEvento = () => {
    const { tipoEvento, termoBusca, itensHistoricoOrcamento, itensHistoricoOrcamentoCopia } = this.state
    if(tipoEvento === 'TODOS') {
      if(!_.isNil(termoBusca)) {
        this.setState({ itensHistoricoOrcamento: this.filterItensPorTermo(termoBusca, itensHistoricoOrcamento) })
      } else {
        this.setState({ itensHistoricoOrcamento: itensHistoricoOrcamentoCopia })
      }
    } else {
      if(!_.isNil(termoBusca)) {
        let itens = this.filterItensPorTermo(termoBusca, itensHistoricoOrcamento)
        this.setState({ itensHistoricoOrcamento: itens.filter((historico) => historico.tipoEvento === tipoEvento) })
      } else {
        this.setState({
          itensHistoricoOrcamento: itensHistoricoOrcamento.filter((historico) => historico.tipoEvento === tipoEvento)
        })
      }
    }
  }

  handleLimparFiltro = () => {
    const { tipoEvento, itensHistoricoOrcamentoCopia } = this.state
    if(tipoEvento === 'TODOS') {
      this.setState({
        termoBusca: '',
        itensHistoricoOrcamento: itensHistoricoOrcamentoCopia
      })
    } else {
      this.setState({
        termoBusca: '',
        itensHistoricoOrcamento: itensHistoricoOrcamentoCopia.filter((historico) => historico.tipoEvento === tipoEvento)
      })
    }
  }

  handleChangeRowsPerPage = (event) => {
    const newRegistrosPorPagina = event.target.value
    const maxPaginas = Math.ceil(this.state.itensHistoricoOrcamento.length / newRegistrosPorPagina)

    let newPagina = this.state.pagina
    if (newPagina >= maxPaginas) {
      newPagina = maxPaginas - 1
    }

    this.setState({
      registrosPorPagina: newRegistrosPorPagina,
      pagina: newPagina
    })
  }

  numeroPossiveisErros = () => this.state.resumoOrcamento.numeroPossiveisErros

  numeroPossiveisInconsistencias = () => this.state.resumoOrcamento.numeroPossiveisInconsistencias

  paginaComputada = () => {
    const { pagina, registrosPorPagina, itensHistoricoOrcamento } = this.state
    return computarPagina(pagina, registrosPorPagina, itensHistoricoOrcamento.length)
  }

  render () {
    const { idEmpreendimento, idObra, idOrcamento } = this.props.match.params
    const { itensHistoricoOrcamento, pagina, registrosPorPagina, open, itensDetalheHistoricoOrcamento, termoBusca, tipoEvento } = this.state

    if (_.isNil(itensHistoricoOrcamento)) {
      return <Carregando />
    }

    return (
      <Template>
        <Cabecalho
          numeroPossiveisErros={this.numeroPossiveisErros()}
          numeroPossiveisInconsistencias={this.numeroPossiveisInconsistencias()}
          titulo='Histórico'
        />
        <div>
          <Box display='flex'>
            <Box mt={2} mr={2}>
              <FormControl variant='outlined' style={{ width: 200 }}>
                <Typography variant='subtitle1'>Tipo de evento</Typography>
                <Select
                  labelId='tipoEvento'
                  value={tipoEvento}
                  name='tipoEvento'
                  onChange={(e) => this.handleChangeTipoEvento(e)}
                >
                  {this.tiposEventos().map((it) => (
                    <MenuItem key={it.value} value={it.value}>
                      {it.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box mt={2}>
              <Typography variant='subtitle1'>Filtrar</Typography>
              <TextField
                name='termoBusca'
                style={{ width: 600 }}
                value={termoBusca}
                onChange={(e) => this.handleChange(e)}
                onKeyUp={(e) => this.handleFiltrar(e)}
                variant='outlined'
                InputProps={{
                  endAdornment:
                    <InputAdornment position='end'>
                      <IconButton color='primary' onClick={() => this.handleLimparFiltro()}>
                        <CloseIcon/>
                      </IconButton>
                    </InputAdornment>
                }}
              />
            </Box>
          </Box>
          {/* <CabecalhoRelatorioHistoricoOrcamento/> */}
          <CorpoRelatorioHistoricoOrcamento
            idEmpreendimento={idEmpreendimento}
            idObra={idObra}
            idOrcamento={idOrcamento}
            itensHistoricoOrcamento={itensHistoricoOrcamento}
            pagina={this.paginaComputada()}
            registrosPorPagina={registrosPorPagina}
            openModal={(item) => this.handleOpen(item)}
          />
        </div>
        <Paginacao
          pagina={pagina}
          registrosPorPagina={registrosPorPagina}
          total={itensHistoricoOrcamento.length}
          onChangePage={(e, pag) => this.setState({ pagina: pag })}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <ModalDetalheItemRelatorioHistoricoOrcamento
          open={open}
          close={() => this.handleClose()}
          itensDetalheHistoricoOrcamento={itensDetalheHistoricoOrcamento}
        />
      </Template>
    )
  }
}


export default withStyles(styles)(RelatorioHistoricoOrcamento)
