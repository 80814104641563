import { pageHeaderAndFooter, round, tableFoot } from './RelatorioComposicaoPdf'

export function dadosMaterialServico (doc, detalhes, titulo) {
  const tableHeaderMateriaisOuServicosTerceiros = [
    { dataKey: 'descricao', title: 'C - Materiais ou Serviço de Terceiros' },
    { dataKey: 'quantidade', title: 'Quantidade' },
    { dataKey: 'unidade', title: 'Unidade' },
    { dataKey: 'precoUnitario', title: 'Preço Unitário' },
    { dataKey: 'custoUnitario', title: 'Custo Unitário' }
  ]

  const tableDataMateriaisOuServicosTerceiros = []
  detalhes.insumos.materiais.forEach((material) => {
    tableDataMateriaisOuServicosTerceiros.push({
      descricao: material.codigoMaterial + ' - ' + material.materialOrcamento.descricao,
      quantidade: round(material.quantidade, 4),
      unidade: material.materialOrcamento.unidade,
      precoUnitario: round(material.materialOrcamento.precoUnitario, 4),
      custoUnitario: round(material.custoUnitario, 4)
    })
  })
  doc.autoTable(tableHeaderMateriaisOuServicosTerceiros, tableDataMateriaisOuServicosTerceiros, {
    startY: doc.lastAutoTable.finalY,
    tableWidth: 'auto',
    margin: { top: 20 },
    tableLineColor: 255,
    tableLineWidth: 1,
    fontSize: 9,
    styles: { halign: 'right', cellPadding: 1 },
    headStyles: {
      fillColor: [200, 200, 200],
      textColor: [0, 0, 0],
      descricao: {
        overflow: 'linebreak',
        cellWidth: 350,
        valign: 'middle',
        halign: 'left',
        fontStyle: 'bold'
      }
    },
    columnStyles: {
      descricao: {
        overflow: 'linebreak',
        cellWidth: 350,
        valign: 'middle',
        halign: 'left',
        fontStyle: 'bold'
      }
    },
    didDrawPage: (data) => pageHeaderAndFooter(doc, data, titulo),
    willDrawCell: function (data) {
      if (data.column.index === 0 && data.cell.section === 'head') {
        data.cell.styles.halign = 'left'
      }
    }
  })

  tableFoot([{
    label: 'Custo Unitário Total de Material',
    value: round(detalhes.insumos.custoUnitarioTotalMateriais, 2)
  }],
  doc)
}
