import React from 'react'
import { Box, FormControl, MenuItem, Select, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  combo: {
    backgroundColor: '#fff',
    width: 550
  }
}))

const CampoEmpreendimentos = ({ empreendimentos, empreendimento, onCampoEmpreendimento }) => {
  const classes = useStyles()
  return (
    <Box mt={1}>
      <FormControl variant='outlined'>
        <Typography variant='subtitle1'>Empreendimentos</Typography>
        <Select
          name={'empreendimento'}
          className={classes.combo}
          value={empreendimento || ''}
          onChange={(e) => onCampoEmpreendimento(e.target.value)}>
          {
            empreendimentos.length > 0 ? (
              empreendimentos.map((it) => (
                <MenuItem key={it.cod} value={it}>
                  <Typography>{it.nome}</Typography>
                </MenuItem>
              ))
            ) : (
              <MenuItem value={null}>
                <Typography>Nenhum resultado encontrado</Typography>
              </MenuItem>
            )
          }
        </Select>
      </FormControl>
    </Box>
  )
}

CampoEmpreendimentos.propTypes = {
  empreendimento: PropTypes.object,
  empreendimentos: PropTypes.array,
  onCampoEmpreendimento: PropTypes.func.isRequired
}
export default CampoEmpreendimentos
