import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Paper, TextField, Typography, withStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TabelaReferenciaPreco from './TabelaReferenciaPreco';
import { context_httpget, context_httppost, context_httpput, httpget } from '../../../../../js/httpRequest';
import { seStringVazia, showErrorMsg, showSuccessMsg, SISTEMAS_REFERENCIAS, UFS } from '../../../../../js/util';
import _ from 'lodash';
import styles from './style';
import Draggable from 'react-draggable';
import { CAMPO_REFERENCIA_PRECO, CAMPOS_CRITERIO } from '../../analise-conteudo/analise-conteudo';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import Select from '@material-ui/core/Select';
import Composicoes from '../../precos/composicoes/Composicoes';
import TabelaReferenciaPesquisarEmPrecos from './TabelaReferenciaPesquisarEmPrecos';
import Grid from '@material-ui/core/Grid';

const ModalComposicoeslDraggable = (props) => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};

const ModalComposicoes = ({ open, close, itemCurvaAbc, onChange, classes }) => {
  const contexto = useSelector((state) => state.contexto);
  const [insumos, setInsumos] = useState([]);
  const [insumoSelecionado, setInsumoSelecionado] = useState(null);
  const [termoBusca, setTermoBusca] = useState('');
  const [tipoFiltro, setTipoFiltro] = useState('codigo');

  const handleBuscarComposicoes = () => {
    context_httpget('precos/composicoes-avulsas', contexto)
      .then((data) => {
        setInsumos(data);
        setInsumoSelecionado(null);
       
      })
      .catch(() => showErrorMsg('Erro ao buscar referências de preço'));
  };

  useEffect(() => {
    if (open) {
      handleBuscarComposicoes();
    }
  }, [open]);

  const selecionarInsumo = (insumo) => {
    setInsumoSelecionado(insumo);
  };

  const handleConfirmarComposicao = () => {
    if (_.isNil(insumoSelecionado)) {
      showErrorMsg('É obrigatório selecionar uma referência de preço.');
    } else {
      let promise;

      const { sistema, uf, periodo } = insumoSelecionado;
      const data = { codigoServico: insumoSelecionado.codigo, referenciaPrecoNaoVinculadoAoOrcamento: { sistema, uf, mes: periodo.mes, ano: periodo.ano } };
      
      promise = context_httpput(`curva-abc/itens/${itemCurvaAbc.id}/codigo-servico/referencia-nao-vinculada`, contexto, data);

      promise
        .then((data) => {
          const novoItem = {
            ...data,
            percentual: itemCurvaAbc.percentual,
            percentualFormatado: itemCurvaAbc.percentualFormatado,
            percentualAcumulado: itemCurvaAbc.percentualAcumulado,
            percentualAcumuladoFormatado: itemCurvaAbc.percentualAcumuladoFormatado,
          };
          showSuccessMsg('Referência de preço selecionada com sucesso.');
          onChange(itemCurvaAbc, novoItem);
        })
       
    }
  };

  const closeModal = () => {
    //setCriterios(criterioDefault())
    close();
  };

  const handleInputChange = (event) => {
    const termo = event.target.value;
    setTermoBusca(termo);
  };

  const handleSearchTypeChange = (event) => {
    setTipoFiltro(event.target.value);
  };

  
  const filteredInsumos = termoBusca
    ? insumos.filter(item => {
        const columnValue = item[tipoFiltro].toLowerCase();
        return columnValue.includes(termoBusca.toLowerCase());
      })
    : insumos;

  return (
    <Dialog open={open} onClose={closeModal} maxWidth="lg" fullWidth aria-labelledby="draggable-dialog-title" PaperComponent={ModalComposicoeslDraggable}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        <div>
          <Typography variant="h5" className={classes.tituloModal}>
            ASSOCIAR SERVIÇO
          </Typography>
        </div>
        <div>
          <Typography variant="subtitle2">Utilize a busca para encontrar o serviço relacionado ao item da curva ABC</Typography>
        </div>
      </DialogTitle>
      <DialogContent>
      <Grid container spacing={2}>
          <Grid item >
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="search-type-label">Tipo de Busca</InputLabel>
              <Select
                labelId="search-type-label"
                id="search-type"
                value={tipoFiltro}
                onChange={handleSearchTypeChange}
                label="Tipo de Busca"
              >
                <MenuItem value="codigo">Código Serviço</MenuItem>
                <MenuItem value="descricao">Descrição</MenuItem>
                
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <TextField
              label="Pesquisar"
              value={termoBusca}
              fullWidth
              onChange={handleInputChange}
              variant="outlined"
            />
          </Grid>
        </Grid>
        <TabelaReferenciaPesquisarEmPrecos insumos={filteredInsumos} onSelect={selecionarInsumo} />
      </DialogContent>

      <DialogActions>
        <Button onClick={closeModal}>Cancelar</Button>
        <Button variant="contained" onClick={() => handleConfirmarComposicao()} color="primary">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ModalComposicoes.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  itemCurvaAbc: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object,
};

export default withStyles(styles)(ModalComposicoes);
