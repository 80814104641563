import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'

import { Link as RouterLink, withRouter } from 'react-router-dom'
import styles from './AppToolbarStyles'
import PropTypes from 'prop-types'
import UserMenu from './UserMenu'
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications'
import { Box, Button, Chip, Menu, MenuItem } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import ChatIcon from '@material-ui/icons/Chat'
import ArticleIcon from '@mui/icons-material/Article';
import ReportProblemIcon from '@material-ui/icons/ReportProblem'
import { hasAutorities } from '../../security/securityContext'
import FeedbackPopover from '../comum/FeedbackPopover'
import { PERFIL } from '../../js/perfis'
import FeedbackListModal from '../feedbacks/FeedbacksListModal'

const AppToolbar = ({ classes, authentication, userMenuAnchorEl, onUserMenuOpen, onUserMenuClose }) => {
  const [anchor, setAnchor] = useState(null)
  const [menuConfiguracoesAberto, setMenuConfiguracoesAberto] = useState(false)
  const [openFeedback, setOpenFeedback] = useState(false)
  const [isProd, setIsProd] = useState(false)
  const [feedbackModalOpen, setFeedbackModalOpen] = useState(false);

  useEffect(() => {
    if (window.location.href.indexOf('apps') > -1 || window.location.href.indexOf('prod') > -1 || window.location.href.indexOf('producao') > -1) { setIsProd(true) }
  })

  const handleOpenFeedback = (event) => {
    setAnchor(event.currentTarget)
    setOpenFeedback(true)
  }

  const handleCloseFeedbackModal = () => {
    setFeedbackModalOpen(false);
  }

  const handleMenuConfiguracao = (event) => {
    setMenuConfiguracoesAberto(true)
    setAnchor(event.currentTarget)
  }

  return (authentication && authentication.isUserAuthenticated)
    ? (
      <AppBar position='fixed' className={classes.appBar}>
        <Toolbar>
          <Grid container justifyContent='space-between' alignItems='center'>
            <Grid container display='flex' alignItems='center' item md={11} xl={12}>
              <Grid item sm={3}>
                <Box display='flex'>
                  <RouterLink to='/'>
                    <img width='80' src='/images/logo.png' alt='Sistema de Análise de Orçamentos' />
                  </RouterLink>
                  {!isProd && <Box ml={1}><Chip color='primary' label='Desenvolvimento' /></Box>}
                </Box>
              </Grid>
              <Grid item sm={9}>
                <Box
                  display='flex'
                  justifyContent='flex-end'
                  alignItems='center'
                >
                  {hasAutorities([PERFIL.GESTOR_TCU, PERFIL.DESENVOLVEDOR]) && (
                    <Box mr={2}>
                      <Button
                        color='default'
                        startIcon={<ReportProblemIcon />}
                        component={RouterLink} to='/analise-risco' title='Análise Risco'
                      >
                        Análise Risco
                      </Button>
                    </Box>
                  )}
                  <Box mr={2}>
                    <Button
                      color='default'
                      className={classes.feedbackAppToolBar}
                      startIcon={<ArticleIcon />}
                      onClick={(e) => handleOpenFeedback(e)}
                    >
                      Fale Conosco
                    </Button>
                    <FeedbackPopover
                      anchorEl={anchor}
                      onclose={() => setOpenFeedback(false)}
                      openFeedback={openFeedback}
                    />
                  </Box>

                  <Box mr={2}>
                    <Button
                      color='default'
                      className={classes.feedbackAppToolBar}
                      startIcon={<ChatIcon />}
                      onClick={() => setFeedbackModalOpen(true)} // Open modal on click
                    >
                      Feedbacks
                    </Button>
                    <FeedbackListModal open={feedbackModalOpen} onClose={handleCloseFeedbackModal} /> {/* Pass state and handler to FeedbackListModal */}
                  </Box>

                  <Box mr={2}>
                    <Button
                      color='default'
                      startIcon={<InfoIcon />}
                      aria-label='menu configurações'
                      component={RouterLink} to='/sobre' title='Sobre o sistema'
                    >
                      Sobre
                    </Button>
                  </Box>
                  <Button
                    color='default'
                    startIcon={<SettingsApplicationsIcon />}
                    aria-label='menu configurações'
                    title='Configuração'
                    onClick={(event) => handleMenuConfiguracao(event)}
                  >
                    Configurações
                  </Button>
                  <Menu
                    open={menuConfiguracoesAberto}
                    anchorEl={anchor}
                    onClose={() => setMenuConfiguracoesAberto(false)}
                  >
                    <MenuItem component={RouterLink} to='/configurar/perfis'>
                      Configurar perfil
                    </MenuItem>
                    {hasAutorities([PERFIL.GESTOR_TCU]) && (
                      <MenuItem component={RouterLink} to='/configurar/unidades'>
                        Configurar unidade
                      </MenuItem>
                    )}
                    {hasAutorities([PERFIL.GESTOR_TCU]) && (
                      <MenuItem component={RouterLink} to='/configurar/carga'>
                        Relatório de carga
                      </MenuItem>
                    )}
                    {hasAutorities([PERFIL.GESTOR_TCU]) && (
                      <MenuItem component={RouterLink} to='/configurar/relatorio-gerencial'>
                        Relatório gerencial
                      </MenuItem>
                    )}
                  </Menu>
                  <UserMenu
                    userMenuAnchorEl={userMenuAnchorEl}
                    onUserMenuOpen={onUserMenuOpen}
                    onUserMenuClose={onUserMenuClose}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    ) : <div />
}

AppToolbar.propTypes = {
  classes: PropTypes.object,
  authentication: PropTypes.object,
  userMenuAnchorEl: PropTypes.any,
  onUserMenuOpen: PropTypes.func,
  onUserMenuClose: PropTypes.func
}

const materialUIEnhanced = withStyles(styles)(AppToolbar)
const mapStateToProps = ({ authentication }) => ({ authentication })
export default connect(mapStateToProps)(withRouter(materialUIEnhanced))
