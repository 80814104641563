export const colunasDatagridRisco1 = [
  { field: 'empreendimento', headerName: 'Empreendimento', width: 180, sortable: false, editable: false },
  { field: 'tipoObra', headerName: 'Tipo de Obra', width: 160, sortable: false, editable: false },
  { field: 'obra', headerName: 'Obra', width: 240, sortable: false, editable: false },
  { field: 'orcamento', headerName: 'Orçamento', width: 240, sortable: false, editable: false },
  { field: 'tipoOrcamento', headerName: 'Tipo de Orçamento', width: 160, sortable: false, editable: false },
  { field: 'usuarioCadastrador', headerName: 'Usuário Cadastrador', width: 100, sortable: false, editable: false },
  { field: 'valorInformado', type: 'number', headerName: 'Valor informado', width: 160, sortable: false, editable: false },
  { field: 'observacao', headerName: 'Observação', width: 160, sortable: false, editable: false }
]

export const rowsDatagridRisco1 = (orcamentos) => {
  return orcamentos.map((orc) => {
    return {
      id: orc.codigo,
      empreendimento: orc.nomeEmpreendimento,
      obra: orc.nomeObra,
      tipoObra: orc.descricaoTipoObra,
      orcamento: orc.nomeOrcamento,
      tipoOrcamento: orc.descricaoTipo,
      usuarioCadastrador: orc.usuarioCadastrador,
      observacao: orc.observacao,
      valorInformado: orc.valorInformado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
  })
}

export const colunasDatagridRisco2 = [
  { field: 'nomeEmpreendimento', headerName: 'Empreendimento', width: 180, sortable: false, editable: false },
  { field: 'nomeObra', headerName: 'Obra', width: 160, sortable: false, editable: false },
  { field: 'tipoObra', headerName: 'Tipo de Obra', width: 100, editable: false, sortable: false },
  { field: 'orcamento', headerName: 'Orçamento', width: 240, description: 'Nome do orçamento.', sortable: false },
  { field: 'tipo', headerName: 'Tipo', width: 100, sortable: false },
  { field: 'valorGlobal', headerName: 'Valor Global', width: 180, type: 'number', sortable: false },
  { field: 'indiceRisco', headerName: 'Risco', width: 180, type: 'number', sortable: false },
  { field: 'dataSistemaReferencia', headerName: 'Data sistema referência', width: 180, type: 'number', sortable: false },
  { field: 'ufSistemaReferencia', headerName: 'UF sistema referência', width: 180, sortable: false },
  { field: 'custoParametrico', headerName: 'Custo paramétrico', width: 180, type: 'number', sortable: false },
  { field: 'risco1', headerName: 'Risco Tipo 1', width: 180, type: 'number', sortable: false },
  { field: 'risco2', headerName: 'Risco Tipo 2', width: 180, type: 'number', sortable: false },
  { field: 'risco3', headerName: 'Risco Tipo 3', width: 180, type: 'number', sortable: false },
  { field: 'risco5', headerName: 'Risco Tipo 4', width: 180, type: 'number', sortable: false },
  { field: 'importancia', headerName: 'Importância', width: 180, type: 'number', description: 'This column has a value getter and is not sortable.', sortable: false },
  { field: 'bdi', headerName: 'BDI', width: 180, type: 'number', description: 'This column has a value getter and is not sortable.', sortable: false },
  { field: 'bdiMaximo', width: 180, type: 'number', headerName: 'BDI Máximo', sortable: false },
  { field: 'erroCalculo', width: 180, type: 'number', headerName: 'Erro de cálculo', sortable: false },
  { field: 'diferencaPreco', width: 180, type: 'number', headerName: 'Diferença de preço em serviços idênticos', sortable: false },
  { field: 'sobreprecoAmostra', width: 180, type: 'number', headerName: 'Sobrepreço da Amostra', sortable: false },
  { field: 'percentualItensAmostra', width: 180, type: 'number', headerName: '% itens na amostra', sortable: false }
]

export const rowsDatagridRisco2 = (orcamentos, riscos) => {
  return riscos.map((risco) => {
    const orc = orcamentos.find((it) => it.codigo === risco.codigoOrcamento)
    const formatoNumeroPtBr = { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    const formatoMoedaPtBr = { style: 'currency', currency: 'BRL' }

    return {
      id: orc.codigo,
      nomeEmpreendimento: orc.nomeEmpreendimento,
      nomeObra: orc.nomeObra,
      tipoObra: risco.nomeTipoObra,
      orcamento: orc.nomeOrcamento,
      tipo: orc.descricaoTipo,
      valorGlobal: risco.valorGlobalOrcamento.toLocaleString('pt-BR', formatoMoedaPtBr),
      indiceRisco: risco.indiceRisco && risco.indiceRisco.toLocaleString('pt-BR', formatoNumeroPtBr),
      dataSistemaReferencia: risco.dataSistemaReferenciaPrincipal,
      ufSistemaReferencia: risco.ufSistemaReferenciaPrincipal,
      custoParametrico: risco.custoParametrico.toLocaleString('pt-BR', formatoNumeroPtBr),
      risco1: risco.riscoTipo1.toLocaleString('pt-BR', formatoMoedaPtBr),
      risco2: risco.riscoTipo2.toLocaleString('pt-BR', formatoMoedaPtBr),
      risco3: risco.riscoTipo3.toLocaleString('pt-BR', formatoMoedaPtBr),
      risco5: risco.riscoTipo5.toLocaleString('pt-BR', formatoMoedaPtBr),
      importancia: risco.importanciaOrcamento.toLocaleString('pt-BR', formatoNumeroPtBr),
      bdi: risco.bdiOrcamento.toLocaleString('pt-BR', formatoNumeroPtBr),
      bdiMaximo: risco.bdiMaximo.toLocaleString('pt-BR', formatoNumeroPtBr),
      erroCalculo: risco.valorTotalErroCalculo.toLocaleString('pt-BR', formatoMoedaPtBr),
      diferencaPreco: risco.valorTotalDiferencaPreco.toLocaleString('pt-BR', formatoMoedaPtBr),
      sobreprecoAmostra: risco.valorTotalSobreprecoAmostraCurvaAbc.toLocaleString('pt-BR', formatoMoedaPtBr),
      percentualItensAmostra: risco.percentualItensAmostraCurvaAbc.toLocaleString('pt-BR', formatoNumeroPtBr)
    }
  })
}
