import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Paper, Typography } from '@material-ui/core'
import { colunasDatagridRisco1, rowsDatagridRisco1 } from './analise-risco'
import StyledDataGrid from '../comum/StyledDataGrid'

const AnaliseRiscoOrcamentos = ({ orcamentos }) => {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  return (
    <Box pb={2} mt={3}>
      <Typography variant='overline' gutterBottom>
        Orçamentos selecionados para análise
      </Typography>
      <Paper variant='outlined' elevation={0}>
        <div style={{ height: 600, width: '100%' }}>
          <StyledDataGrid
            pagination
            page={page}
            headerHeight={75}
            rowHeight={65}
            columns={colunasDatagridRisco1}
            pageSize={pageSize}
            disableSelectionOnClick
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            rows={rowsDatagridRisco1(orcamentos)}
            sx={{ fontSize: 'x-small' }}
            rowsPerPageOptions={[10, 15, 20]}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          />
        </div>
      </Paper>
    </Box>
  )
}

AnaliseRiscoOrcamentos.propTypes = {
  orcamentos: PropTypes.array
}

export default AnaliseRiscoOrcamentos
