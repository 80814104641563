import React, { Component } from 'react'
import Paper from '@material-ui/core/Paper'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { Button, Typography, withStyles } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import _ from 'lodash'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import { url } from '../../../../js/util'
import { SLOW_REQUEST_CONFIG } from '../../../../js/operationProgressUtil'
import TratamentoPossivelErro from './TratamentoPossivelErro'
import PropTypes from 'prop-types'
import styles from './style'

class ContainerTratamentoPossiveisErros extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    quantidadeIgnorados: PropTypes.number.isRequired,
    quantidadeNaoIgnorados: PropTypes.number.isRequired,
    match: PropTypes.object,
    tipo: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    atualizar: PropTypes.func.isRequired,
    classes: PropTypes.object,
    encerrado: PropTypes.bool.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      pagina: 0,
      registrosPorPagina: 10,
      ocorrencias: []
    }
    this.handleProximaOcorrenciaClick = this.handleProximaOcorrenciaClick.bind(this)
    this.handleOcorrenciaAnteriorClick = this.handleOcorrenciaAnteriorClick.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.atualizar = this.atualizar.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.open && this.props.open) {
      this.definirEstado()
    }
  }

  async definirEstado () {
    const { tipo, quantidadeIgnorados, quantidadeNaoIgnorados } = this.props
    if (quantidadeIgnorados > 0 || quantidadeNaoIgnorados > 0) {
      const { pagina, registrosPorPagina } = this.state
      const res = await axios.get(`${url(this.props.match.params)}/possiveis-erros/${tipo.cod}?page=${pagina}&size=${registrosPorPagina}`, SLOW_REQUEST_CONFIG)
      this.setState({
        ocorrencias: res.data
      })
    } else {
      this.setState({
        ocorrencias: []
      })
    }
  }

  handleProximaOcorrenciaClick () {
    this.setState({
      pagina: this.state.pagina + 1
    }, this.definirEstado)
  }

  handleOcorrenciaAnteriorClick () {
    this.setState({
      pagina: this.state.pagina - 1
    }, this.definirEstado)
  }

  atualizar (dados) {
    this.definirEstado()
    this.props.atualizar(dados)
  }

  exibirBotoes () {
    const { pagina, registrosPorPagina } = this.state
    const { quantidadeNaoIgnorados } = this.props
    const { quantidadeIgnorados } = this.props
    const quantidadeTotal = quantidadeIgnorados + quantidadeNaoIgnorados
    const registroMaximoPagina = Math.min(quantidadeTotal, (pagina*registrosPorPagina)+registrosPorPagina)
    const registroMinimoPagina = quantidadeTotal === 0 ? 0 : (pagina*registrosPorPagina)+1

    return (
      <div className='btnsPossiveisErros'>
        <div>
          <Button variant='text' color='default' disabled={pagina === 0} onClick={this.handleOcorrenciaAnteriorClick}>
            <ArrowBackIcon/> Ocorrências anteriores
          </Button>
          <span className='fw pad10' >
           <strong>{registroMinimoPagina}-{registroMaximoPagina} de {quantidadeTotal}</strong>
          </span>
          <Button variant='text' color='default' onClick={this.handleProximaOcorrenciaClick} disabled={registroMaximoPagina === quantidadeTotal}>
            Próximas Ocorrências <ArrowForwardIcon/>
          </Button>
        </div>
      </div>
    )
  }

  handleChange (e, atributo) {
    const state = {}
    state[atributo] = e.target.value
    state[`${atributo}Empty`] = _.isEmpty(e.target.value)
    this.setState(state)
  }

  render () {
    const {
      tipo,
      onClick,
      open,
      encerrado,
      quantidadeIgnorados,
      quantidadeNaoIgnorados,
      classes
    } = this.props
    const { titulo, legenda } = tipo
    const {
      ocorrencias
    } = this.state

    const corNaoIgnoradas = quantidadeNaoIgnorados === 0 ? 'rgba(0,184,74,.2)' : 'rgba(250,123,155,.2)'
    return (
      <div className='container' style={{ marginTop: 20 }}>
        <div className={ classes.row }>
          <div className={ classes.colSm10 }>
            <Paper style={
              {
                backgroundColor: '#f6f7f8',
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: open ? 0 : 1
              }
            }>
              <div className='divh90dspflex'>
                <div className='pad20' >
                  <Typography variant='subtitle2'>
                    { titulo }
                  </Typography>
                  <Typography variant='body2'>
                    { legenda }
                  </Typography>
                </div>
                <div className='pad20 curpointer'  onClick={onClick}>
                  <Typography variant='subtitle2'>
                    Ver erros <KeyboardArrowDownIcon className='vaMid'  />
                  </Typography>
                </div>
              </div>
            </Paper>
          </div>
          <div className={ classes.colSm1 }>
            <div className={classes.paperIgnorados}>
              <div className={ classes.paddingErros } >
                <Typography variant='h6' className={ classes.estiloTextoNumeroOcorrencias }>
                  { quantidadeIgnorados }
                </Typography>
                <Typography variant='body2' className={ classes.estiloTextoNumeroOcorrencias }>
                  Ignoradas
                </Typography>
              </div>
            </div>
          </div>
          <div className={ classes.colSm1 }>
            <Paper style={
              {
                backgroundColor: corNaoIgnoradas,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: open ? 0 : 1,
                height: 90
              }
            }>
              <div className={ classes.paddingErros } >
                <Typography variant='h6' className={ classes.estiloTextoNumeroOcorrencias }>
                  { quantidadeNaoIgnorados }
                </Typography>
                <Typography variant='body2' className={ classes.estiloTextoNumeroOcorrencias }>
                  Ocorrências
                </Typography>
              </div>
            </Paper>
          </div>
        </div>

        {
          open && (
            <div className='col-sm-12'>
              <Paper className={classes.paperOcorrencia} >
                <div className='pad20' >
                  <TratamentoPossivelErro
                    encerrado={encerrado}
                    lista={ocorrencias}
                    tipo={tipo}
                    atualizar={this.atualizar}
                  />
                </div>
                {
                  this.exibirBotoes()
                }
              </Paper>
            </div>
          )
        }

      </div>
    )
  }
}

export default withStyles(styles)(withRouter(ContainerTratamentoPossiveisErros))
