import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, Box, Button, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'
import AnaliseConteudoContentCombinadaItem from '../../AnaliseConteudoContentCombinadaItem'
import SimplePopover from '../../../../../../comum/SimplePopover'
import StyledDrawer from '../../../../../../comum/StyledDrawer'
import StyledAccordionSumary from '../../../../../../comum/StyledAccordionSumary'
import ModalExcluirTipologiaCombinada from './ModalExcluirTipologiaCombinada'
import NenhumResultadoEncontrado from '../../comum/NenhumResultadoEncontrado'
import CombinarTipologia from './CombinarTipologia'
import { PERSONALIZADA, tipoOperadorTipologiaCombinada } from '../../../analise-conteudo'

const TipologiasCombinadas = ({
  classes,
  tipologiasCombinadas,
  tipoAnaliseSelecionada,
  onChangeOperador,
  onRemoverTipologiaCombinadaDoEstado,
  onRecarregarTipologiaCombinadas
}) => {
  const [expandedPanel, setExpandedPanel] = useState(true)
  const [combinadaAtiva, setCombinadaAtiva] = useState(null)
  const [combinadaExcluir, setCombinadaExcluir] = useState(null)
  const [combinadaFoiExcluida, setCombinadaFoiExcluida] = useState(false)
  const [openDrawerAdicionarCombinada, setOpenDrawerAdicionarCombinada] = useState(false)

  useEffect(() => {
    combinadaFoiExcluida && onRemoverTipologiaCombinadaDoEstado(combinadaExcluir)
  }, [combinadaFoiExcluida])

  const handleOpenDrawer = (e) => {
    e.stopPropagation()
    setCombinadaAtiva(null)
    setOpenDrawerAdicionarCombinada(true)
  }

  const handleChangePanel = (panel) => (event, newExpanded) =>
    setExpandedPanel(newExpanded ? panel : false)

  const handleFecharDrawer = () => {
    setOpenDrawerAdicionarCombinada(!openDrawerAdicionarCombinada)
    onRecarregarTipologiaCombinadas()
  }

  const handleOnCompleteModalExcluirCombinada = () => {
    setCombinadaFoiExcluida(true)
    setCombinadaExcluir(null)
  }

  const handleEditarCombinada = (combinada) => {
    setOpenDrawerAdicionarCombinada(true)
    setCombinadaAtiva(combinada)
  }

  return (
    <React.Fragment>
      <Box mt={2}>
        <Accordion
          className={classes.accordionAnaliseConteudo} expanded={expandedPanel === true}
          square
          onChange={handleChangePanel(true)}
        >
          <StyledAccordionSumary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='accordionCombinadas'
            className={classes.accordionSummaryAnalise}
          >
            <Box display='flex' alignItems='center'>
              <Typography variant='subtitle2' className={classes.tituloAccordionAnaliseConteudo}>Tipologias Combinadas</Typography>
              <SimplePopover>
                Alguma descrição aqui sobre a combinação de tipologia
              </SimplePopover>

              {tipoAnaliseSelecionada === PERSONALIZADA &&
                <Button variant='text' color='primary' onClick={(e) => handleOpenDrawer(e)} style={{ marginRight: 5 }}>
                  <AddIcon fontSize='small' color='primary' /> Adicionar
                </Button>
              }
            </Box>
          </StyledAccordionSumary>
          <AccordionDetails style={{ display: 'grid' }}>
            {tipologiasCombinadas.length === 0 && <NenhumResultadoEncontrado titulo='Nenhuma tipologia combinada encontrada' subtitulo='Combine tipologias personalizadas' />}
            {tipologiasCombinadas.map(combinada => (
              <AnaliseConteudoContentCombinadaItem
                key={combinada.codigo}
                classes={classes}
                codigo={combinada.codigo}
                nome={combinada.nome}
                descricao={combinada.descricao}
                operador={tipoOperadorTipologiaCombinada(combinada.operador)}
                primeiraTipologia={combinada.primeiraTipologia}
                resultadoPrimeiraTipologia={combinada.resultadoPrimeiraTipologia}
                segundaTipologia={combinada.segundaTipologia}
                resultadoSegundaTipologia={combinada.resultadoSegundaTipologia}
                onExcluirClick={() => setCombinadaExcluir(combinada)}
                onEditarCombinada={() => handleEditarCombinada(combinada)}
                onChangeOperador={onChangeOperador}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
      <StyledDrawer
        classes={{ paper: classes.paperDrawer }}
        open={openDrawerAdicionarCombinada}
        anchor={'right'}
        onClose={handleFecharDrawer}>
        <CombinarTipologia
          combinadaAtiva={combinadaAtiva}
          tipoAnaliseSelecionada={tipoAnaliseSelecionada}
          onRecarregarTipologiaCombinadas={onRecarregarTipologiaCombinadas}
          onFecharDrawer={handleFecharDrawer}
        />
      </StyledDrawer>
      <ModalExcluirTipologiaCombinada
        tipologiaCombinada={combinadaExcluir}
        fechar={() => setCombinadaExcluir(null)}
        onComplete={handleOnCompleteModalExcluirCombinada}
      />
    </React.Fragment>
  )
}

TipologiasCombinadas.propTypes = {
  classes: PropTypes.object,
  tipologiasCombinadas: PropTypes.array,
  tipoAnaliseSelecionada: PropTypes.string,
  onChangeOperador: PropTypes.func.isRequired,
  onRecarregarTipologiaCombinadas: PropTypes.func.isRequired,
  onRemoverTipologiaCombinadaDoEstado: PropTypes.func.isRequired
}
export default TipologiasCombinadas
