import React from 'react'
import TitleTableCell from '../../../comum/TitleTableCell'
import { TableHead, TableRow } from '@material-ui/core'

const CabecalhoTabelaCurvaAbcInsumo = () => {
  return (
    <TableHead>
      <TableRow>
        <TitleTableCell>Tipo</TitleTableCell>
        <TitleTableCell>Código</TitleTableCell>
        <TitleTableCell>Descrição</TitleTableCell>
        <TitleTableCell>Unidade</TitleTableCell>
        <TitleTableCell>Quantidade</TitleTableCell>
        <TitleTableCell>Quantidade Improdutiva</TitleTableCell>
        <TitleTableCell>Preço parcial</TitleTableCell>
        <TitleTableCell>%</TitleTableCell>
      </TableRow>
    </TableHead>
  )
}

export default CabecalhoTabelaCurvaAbcInsumo
