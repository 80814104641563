import React, { useState } from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import { Checkbox } from '@material-ui/core'
import TitleTableCell from '../../../../../../comum/TitleTableCell'
import { paginar } from '../../../../../../../js/util'
import Paginacao from '../../../../../../comum/Paginacao'

const TabelaCombinarTipologias = ({ tipologiasPersonalizadas, tipologiasSelecionadas, onSelecionarTipologia }) => {
  const [pagina, setPagina] = useState(0)
  const [registrosPorPagina, setRegistrosPorPagina] = useState(20)

  const handleTipologiaIsChecked = (tipologia) => tipologiasSelecionadas.map(it => it.codigo).indexOf(tipologia.codigo) !== -1
  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TitleTableCell width='5%' />
            <TitleTableCell width='40%'>Nome</TitleTableCell>
            <TitleTableCell width='40%'>Descrição</TitleTableCell>
            <TitleTableCell align='right' width='10%'>Tipo</TitleTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <React.Fragment>
            {!tipologiasPersonalizadas.length &&
              <TableRow>
                <TableCell component='th' scope='row' colSpan={4} align='center'>Nenhum resultado encontrado</TableCell>
              </TableRow>
            }
            {paginar(tipologiasPersonalizadas, pagina, registrosPorPagina).map((tipologia) => {
              return (
                <TableRow key={tipologia.codigo}>
                  <TableCell component='th' scope='row'>
                    <Checkbox
                      color='primary'
                      checked={handleTipologiaIsChecked(tipologia)}
                      onChange={(e) => onSelecionarTipologia(e, tipologia)}
                    />
                  </TableCell>
                  <TableCell component='th' scope='row'>{tipologia.nome}</TableCell>
                  <TableCell component='th' scope='row'>{tipologia.descricao}</TableCell>
                  <TableCell component='th' scope='row' align='right'>{tipologia.tipo}</TableCell>
                </TableRow>
              )
            })}
          </React.Fragment>
        </TableBody>
      </Table>
      <Paginacao
        total={tipologiasPersonalizadas.length}
        registrosPorPagina={registrosPorPagina}
        pagina={pagina}
        onChangePage={(e, pag) => { setPagina(pag) }}
        onChangeRowsPerPage={(e) => setRegistrosPorPagina(e.target.value)}
      />
    </React.Fragment>
  )
}

TabelaCombinarTipologias.propTypes = {
  tipologiasPersonalizadas: PropTypes.array.isRequired,
  tipologiasSelecionadas: PropTypes.array,
  onSelecionarTipologia: PropTypes.func.isRequired
}

export default TabelaCombinarTipologias
