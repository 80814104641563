import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../styles'
import PropTypes from 'prop-types'
import { Box, Divider } from '@material-ui/core'
import CustomScroller from 'react-custom-scroller'
import AnaliseConteudoSidebarItem from './AnaliseConteudoSidebarItem'
import { ANALISE_CONTEUDO_PERSONALIZADA, ANALISE_CONTEUDO_TEMA } from '../../../../../js/util'

const AnaliseConteudoSidebar = ({
  classes,
  temas,
  temaAtivo,
  personalizadaAtiva,
  onSelecionarItemSidbar
}) => {
  return (
    <React.Fragment>
      <Divider />
      <CustomScroller className={classes.gridSidebar}>
          <Box pt={2} mr={1}>
            <AnaliseConteudoSidebarItem
              classes={classes}
              titulo={'Verificações específicas deste orçamento'}
              subtitulo={'Conjuto de fórmulas e expressões que verifica vários tipos de quantitativos e taxas em orçamentos da Seinfra.'}
              value
              tipo={ANALISE_CONTEUDO_PERSONALIZADA}
              seAtiva={personalizadaAtiva}
              onSelecionarItemSidbar={onSelecionarItemSidbar}
            />
            { temas.map((tema) => (
              <AnaliseConteudoSidebarItem
                key={tema.codigo}
                classes={classes}
                titulo={tema.nome}
                subtitulo={tema.descricao}
                value={tema}
                tipo={ANALISE_CONTEUDO_TEMA}
                seAtiva={Boolean(temaAtivo) && temaAtivo.codigo === tema.codigo}
                onSelecionarItemSidbar={onSelecionarItemSidbar}
              />)
            )}
          </Box>
        <Divider />
      </CustomScroller>
    </React.Fragment>
  )
}

AnaliseConteudoSidebar.propTypes = {
  classes: PropTypes.object,
  temas: PropTypes.array,
  temaAtivo: PropTypes.object,
  personalizadaAtiva: PropTypes.bool,
  onSelecionarItemSidbar: PropTypes.func.isRequired
}

export default withStyles(styles)(AnaliseConteudoSidebar)
