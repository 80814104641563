const styles = () => ({
  linha: {
    borderBottom: 'solid 1px rgba(196,196,196, 0.5)',
    display: 'flex',
    paddingTop: 12,
    paddingBottom: 12,
    justifyContent: 'space-between',
    width: '100%'
  },
  grupoDescricao: {
    display: 'flex'
  },
  iconeHistorico: {
    color: 'rgba(0,0,0, 0.5)',
    marginRight: 16
  },
  bloco: {
    borderLeft: '3px solid rgba(67,133,191,1)',
    paddingLeft: 8,
    marginTop: 8
  },
  dataHora: {
    textAlign: 'right'
  },
  tamanhoFonte: {
    fontSize: '1rem'
  }
})

export default styles
