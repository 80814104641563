import React, { Fragment, useState } from 'react'
import { Link, TableCell, TableRow } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import RelatorioComposicao from '../../relatorios/precos/composicao/RelatorioComposicao'
import PropTypes from 'prop-types'

const useStyles = makeStyles({
  textDecorat: {
    textDecoration: 'none'
  }
})

const ItemComposicaoRowModal = ({ composicao, onAtualizarItemCurvaABCComposicaoDuplicada }) => {
  const classes = useStyles()
  const [openRelatorioComposicao, setopenRelatorioComposicao] = useState( false)

  const handleChangeRelatorioComposicao = () => {
    setopenRelatorioComposicao(!openRelatorioComposicao)
  }

  return (
    <Fragment>
      <TableRow>
        <TableCell>{composicao.codigo}</TableCell>
        <TableCell>
          <Link className={classes.textDecorat} onClick={() => handleChangeRelatorioComposicao()}>
            {composicao.descricao}
          </Link>
        </TableCell>
        <TableCell>{composicao.mes}/{composicao.ano}</TableCell>
      </TableRow>
      {
        openRelatorioComposicao &&
        <TableRow>
          <TableCell colSpan={3}>
            <RelatorioComposicao
              permitirEdicao
              composicao={composicao}
              onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
            />
          </TableCell>
        </TableRow>
      }
    </Fragment>
  )
}

ItemComposicaoRowModal.propTypes = {
  composicao: PropTypes.object.isRequired,
  onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.func
}

export default ItemComposicaoRowModal
