import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TituloModal from '../../../../comum/TituloModal'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { formatarNumeroParaMoedaBR } from '../../../../../js/util'
import TableFooter from '@material-ui/core/TableFooter'

const ModalResumoCurvaAbcInsumo = ({ insumos, open, close }) => {
  const [custoTotalEquipamentos, setCustoTotalEquipamentos] = useState(0)
  const [custoTotalMaoDeObras, setCustoTotalMaoDeObras] = useState(0)
  const [custoTotalMateriais, setCustoTotalMateriais] = useState(0)

  const [percentualTotalEquipamentos, setPercentualTotalEquipamentos] = useState(0)
  const [percentualTotalMaoDeObras, setPercentualTotalMaoDeObras] = useState(0)
  const [percentualTotalMateriais, setPercentualTotalMateriais] = useState(0)
  const [precoTotalInsumos, setPrecoTotalInsumos] = useState(0)

  useEffect(() => {
    calcularValores()
  }, [open])

  const calcularValores = () => {
    const equipamentos = insumos.filter(it => it.tipo === 'Equipamento')
    const maosDeObras = insumos.filter(it => it.tipo === 'Mão de Obra')
    const materiais = insumos.filter(it => it.tipo === 'Material')

    const custoEquipamento = equipamentos.reduce((acumulador, item) => acumulador + item.precoParcialTotal, 0)
    const custoMaoDeObra = maosDeObras.reduce((acumulador, item) => acumulador + item.precoParcialTotal, 0)
    const custoMaterial = materiais.reduce((acumulador, item) => acumulador + item.precoParcialTotal, 0)

    setCustoTotalEquipamentos(custoEquipamento)
    setCustoTotalMaoDeObras(custoMaoDeObra)
    setCustoTotalMateriais(custoMaterial)

    setPercentualTotalEquipamentos(equipamentos.reduce((acumulador, item) => acumulador + item.percentualTotal, 0))
    setPercentualTotalMaoDeObras(maosDeObras.reduce((acumulador, item) => acumulador + item.percentualTotal, 0))
    setPercentualTotalMateriais(materiais.reduce((acumulador, item) => acumulador + item.percentualTotal, 0))
    setPrecoTotalInsumos(custoEquipamento + custoMaoDeObra + custoMaterial)
  }

  return open && (
    <Dialog
      open
      onClose={close}
      aria-labelledby='form-dialog-title'>

      <TituloModal onClose={close}>
        Resumo
      </TituloModal>

      <DialogContent>
        <Table style={{ width: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Tipo</TableCell>
              <TableCell>Incidência %</TableCell>
              <TableCell align='right'>
                Custo parcial R$
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>Equipamento</TableCell>
              <TableCell>{formatarNumeroParaMoedaBR(percentualTotalEquipamentos, 2, 2)}</TableCell>
              <TableCell align='right'>{formatarNumeroParaMoedaBR(custoTotalEquipamentos, 2, 2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Mão de Obra</TableCell>
              <TableCell>{formatarNumeroParaMoedaBR(percentualTotalMaoDeObras, 2, 2)}</TableCell>
              <TableCell align='right'>{formatarNumeroParaMoedaBR(custoTotalMaoDeObras, 2, 2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Material</TableCell>
              <TableCell>{formatarNumeroParaMoedaBR(percentualTotalMateriais, 2, 2)}</TableCell>
              <TableCell align='right'>{formatarNumeroParaMoedaBR(custoTotalMateriais, 2, 2)}</TableCell>
            </TableRow>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell> </TableCell>
              <TableCell> </TableCell>
              <TableCell align='right'>
                <b>Preço total de insumos: {formatarNumeroParaMoedaBR(precoTotalInsumos, 2, 2)}</b>
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={close}>Fechar</Button>
      </DialogActions>
    </Dialog>
  )
}

ModalResumoCurvaAbcInsumo.propTypes = {
  insumos: PropTypes.array,
  open: PropTypes.bool,
  close: PropTypes.func.isRequired
}

export default ModalResumoCurvaAbcInsumo
