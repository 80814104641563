import React from 'react'
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core/'
import { makeStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    width: 'fit-content',
    marginTop: 24
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}))

const ModalDuplicarOrcamento = ({ orcamento, fechar, confirmar }) => {
  const [loading, setLoading] = React.useState(false)
  const [success, setSuccess] = React.useState(false)
  const timer = React.useRef()

  const classes = useStyles()

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  const handleButtonClick = async (orcamento) => {
    if (!loading) {
      setSuccess(false)
      setLoading(true)
      await confirmar(orcamento)
      console.log('fim')
      setSuccess(true)
      setLoading(false)
    }
  }

  return (
    <Dialog
      open={orcamento != null}
      onClose={(event, reason) => {
        // eslint-disable-next-line no-unused-expressions
        if (reason !== 'backdropClick') { fechar }
      }}
      confirmar={confirmar}
    >
      <DialogTitle>Duplicar orçamento</DialogTitle>
      <DialogContent>
        <Typography variant='subtitle2'>
          Inicie a operação de duplicar esse orçamento. Aguarde alguns minutos para concluímos.
          O orçamento será criado com nome do atual adicionado do prefixo <strong>Cópia -</strong>
          . Exemplo: Cópia de orçamento DNIT
        </Typography>
        <div className={classes.wrapper}>
          <Button
            variant='contained'
            color={!success ? 'primary' : 'secondary'}
            disabled={loading}
            onClick={() => handleButtonClick(orcamento)}
          >Iniciar
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={fechar}>Fechar</Button>
      </DialogActions>
    </Dialog>
  )
}

ModalDuplicarOrcamento.propTypes = {
  orcamento: PropTypes.object,
  fechar: PropTypes.func,
  confirmar: PropTypes.func
}

export default ModalDuplicarOrcamento
