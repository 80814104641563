import axios from 'axios'
import _ from 'lodash'
import { getAuthentication } from '../security/securityContext'

export const BYPASS_AUTH_INTERCEPTOR_HEADER = 'X-Bypass-Auth-Interceptor'
export const SLOW_REQUEST_HEADER = 'X-Slow-Request'

const onFulfilled = (config) => {
  const result = _.clone(config)
  const auth = getAuthentication()
  if (auth && auth.isUserAuthenticated) {
    const { tokenJwt } = auth
    result.headers.Authorization = `Bearer ${tokenJwt}`
  }
  return result
}

const onRejected = (err) => Promise.reject(err)

axios.interceptors.request.use(onFulfilled, onRejected)
