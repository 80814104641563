export const calcularValorReferencia = (precoRef, item) => precoRef + (precoRef * item.bdiPercentual)

export const calcularValorTotalReferencia = (precoRef, item) => item.quantidade * calcularValorReferencia(precoRef, item)

export const calcularSobrepreco = (precoRef, item) => item.precoParcialCalculado - calcularValorTotalReferencia(precoRef, item)

export const temSobrepreco = (valorSobrepreco, metodoCalculoSobrepreco) => {
  const temSobreprecoPositivo = valorSobrepreco > 0.01
  const temSobreprecoNegativo = valorSobrepreco < 0
  return Boolean(temSobreprecoPositivo || (temSobreprecoNegativo && metodoCalculoSobrepreco && metodoCalculoSobrepreco.consideraSobreprecoNegativo))
}
