import React from 'react'
import { Typography } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import ModalConfirmacao from '../../../comum/ModalConfirmacao'

export default withRouter((props) => (
  <ModalConfirmacao
    open={props.open}
    titulo='Seguir para Curva ABC?'
    onClose={props.cancelar}
    confirmar={() => props.history.push(props.match.url.replace('possiveis-erros', 'abc'))}>
    <Typography>
      Não há mais possíveis erros pendentes de tratamento, deseja seguir para a curva ABC?
    </Typography>
  </ModalConfirmacao>
))
