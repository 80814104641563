import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { converterMoedaBRParaNumero, formatarNumeroParaMoedaBR, mapStateToPropsContexto, seStringVazia, showSuccessMsg } from '../../../../../../../../js/util'
import Box from '@material-ui/core/Box'
import TituloVoltar from '../../../../../../../comum/TituloVoltar'
import { context_httpput } from '../../../../../../../../js/httpRequest'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FormularioEquipamentoComposicao from './FormularioEquipamentoComposicao'

class EditarEquipamentoComposicao extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string.isRequired,
    equipamentoComposicao: PropTypes.object.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired,
    contexto: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      quantidade: '',
      quantidadeEmpty: false,
      utilizacaoOperativa: '',
      utilizacaoOperativaEmpty: false,
      descricao: '',
      descricaoEmpty: false,
      custoProdutivo: '',
      custoImprodutivo: '',
    }
    this.handleSalvarClick = this.handleSalvarClick.bind(this)
    this.handleCampoTextoChange = this.handleCampoTextoChange.bind(this)
  }

  componentDidMount() {
    if (this.props.equipamentoComposicao) {
      this.copiarDadosParaEdicao()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.equipamentoComposicao !== this.props.equipamentoComposicao) {
      this.copiarDadosParaEdicao()
    }
  }

  copiarDadosParaEdicao() {
    const { equipamentoComposicao } = this.props
    this.setState({
      quantidade: formatarNumeroParaMoedaBR(equipamentoComposicao.quantidade, 7, 7),
      utilizacaoOperativa: formatarNumeroParaMoedaBR(equipamentoComposicao.utilizacaoOperativa, 2, 5),
      utilizacaoImprodutiva: formatarNumeroParaMoedaBR(equipamentoComposicao.utilizacaoImprodutiva, 2, 5),
      descricao: equipamentoComposicao.equipamentoOrcamento.descricao,
      custoProdutivo: formatarNumeroParaMoedaBR(equipamentoComposicao.equipamentoOrcamento.custoProdutivo, 2, 5),
      custoImprodutivo: formatarNumeroParaMoedaBR(equipamentoComposicao.equipamentoOrcamento.custoImprodutivo, 2, 5),
    })
  }

  handleCampoTextoChange(event, campo) {
    const state = {}
    state[campo] = event.target.value
    state[`${campo}Empty`] = seStringVazia(event.target.value)
    this.setState(state)
  }

  async handleSalvarClick() {
    const { quantidade, utilizacaoOperativa, utilizacaoImprodutiva, descricao, custoProdutivo, custoImprodutivo } = this.state
    const quantidadeEmpty = seStringVazia(quantidade)
    const utilizacaoOperativaEmpty = seStringVazia(utilizacaoOperativa)
    const utilizacaoImprodutivaEmpty = seStringVazia(utilizacaoImprodutiva)
    const descricaoEmpty = seStringVazia(descricao)
    const custoProdutivoEmpty = seStringVazia(custoProdutivo)
    const custoImprodutivoEmpty = seStringVazia(custoImprodutivo)

    if ([quantidadeEmpty, utilizacaoOperativaEmpty, utilizacaoImprodutivaEmpty, descricaoEmpty, custoProdutivoEmpty, custoImprodutivoEmpty].some(Boolean)) {
      this.setState({
        quantidadeEmpty,
        utilizacaoOperativaEmpty,
        utilizacaoImprodutivaEmpty,
        descricaoEmpty,
        custoProdutivoEmpty,
        custoImprodutivoEmpty,
      })
    } else {
      const dadosSalvar = {
        quantidade: converterMoedaBRParaNumero(quantidade),
        utilizacaoOperativa: converterMoedaBRParaNumero(utilizacaoOperativa),
        descricao,
        custoProdutivo: converterMoedaBRParaNumero(custoProdutivo),
        custoImprodutivo: converterMoedaBRParaNumero(custoImprodutivo),
      }
      const { contexto, codigoComposicao, onDadosAlterados, equipamentoComposicao } = this.props
      const url = `precos/editar-equipamento?composicao=${codigoComposicao}&equipamento=${equipamentoComposicao.equipamentoOrcamento.codigo}`
      const resultado = await context_httpput(url, contexto, dadosSalvar)
      if (resultado) {
        showSuccessMsg('Dados do equipamento na composição alterados com sucesso.')
        onDadosAlterados()
      }
    }
  }

  render() {
    const { equipamentoComposicao, onCancelar } = this.props

    const {
      quantidade,
      quantidadeEmpty,
      utilizacaoOperativa,
      utilizacaoOperativaEmpty,
      descricao,
      descricaoEmpty,
      custoProdutivo,
      custoProdutivoEmpty,
      custoImprodutivo,
      custoImprodutivoEmpty,
    } = this.state

    return (
      <div>
        <TituloVoltar
          onClick={onCancelar}
          label="Editar Equipamento da Composição"
        />
        <Box mt={2}>
          <Typography variant="overline">
            {equipamentoComposicao.equipamentoOrcamento.codigo} -{' '}
            {equipamentoComposicao.equipamentoOrcamento.descricao}
          </Typography>
        </Box>

        <FormularioEquipamentoComposicao
          codigo={equipamentoComposicao.equipamentoOrcamento.codigo}
          codigoEmpty={false}
          codigoEditavel={false}
          descricao={descricao}
          descricaoEmpty={descricaoEmpty}
          quantidade={quantidade}
          quantidadeEmpty={quantidadeEmpty}
          utilizacaoOperativa={utilizacaoOperativa}
          utilizacaoOperativaEmpty={utilizacaoOperativaEmpty}
          custoProdutivo={custoProdutivo}
          custoProdutivoEmpty={Boolean(custoProdutivoEmpty)}
          custoImprodutivo={custoImprodutivo}
          custoImprodutivoEmpty={Boolean(custoImprodutivoEmpty)}
          handleCampoTextoChange={this.handleCampoTextoChange}
          onSalvar={this.handleSalvarClick}
          onCancelar={onCancelar}
        />
      </div>
    )
  }
}

export default connect(mapStateToPropsContexto)(EditarEquipamentoComposicao)
