import React, { Component } from 'react'
import { Table, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import ResumoCustoTotal from '../../ResumoCustoTotal'
import styles from '../../../styles'
import PropTypes from 'prop-types'
import { formatarNumeroParaMoedaBR, mapStateToPropsContexto, showSuccessMsg, urlContexto } from '../../../../../../../../js/util'
import TableBody from '@material-ui/core/TableBody'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import StyledDrawer from '../../../../../../../comum/StyledDrawer'
import EditarTransporteComposicao from './EditarTransporteComposicao'
import ModalConfirmacao from '../../../../../../../comum/ModalConfirmacao'
import Typography from '@material-ui/core/Typography'
import { context_httppost } from '../../../../../../../../js/httpRequest'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import AdicionarTransporteComposicao from './AdicionarTransporteComposicao'
import _ from 'lodash'
import Link from '@material-ui/core/Link'
import iconTransporte from '../../../../../../../../img/transporte.svg'
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router-dom'
import { MENU_TRANSPORTES } from '../../../../../precos/preco'

const defaultState = {
  transporteExclusao: null,
  transporteEdicao: null,
  exibirDrawerAdicionar: false
}

class TransportesComposicao extends Component {
  static propTypes = {
    classes: PropTypes.object,
    contexto: PropTypes.object,
    composicoesAuxiliares: PropTypes.object.isRequired,
    permitirEdicao: PropTypes.bool,
    codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    detalharComposicao: PropTypes.func.isRequired,
    history: PropTypes.object
  }
  constructor (props) {
    super(props)
    this.state = defaultState
    this.handleDadosAlterados = this.handleDadosAlterados.bind(this)
    this.handleConfirmarExclusaoTransporte = this.handleConfirmarExclusaoTransporte.bind(this)
    this.handleTransportesClick = this.handleTransportesClick.bind(this)
  }

  handleDadosAlterados() {
    const { onDadosAlterados } = this.props
    this.setState(defaultState)
    onDadosAlterados()
  }

  handleTransportesClick () {
    const { contexto } = this.props
    this.props.history.push(`${urlContexto(contexto)}/precos`, { menuSelecionado: MENU_TRANSPORTES })
  }

  dmt (transporte) {
    if (!_.isNil(transporte.dmt)) {
      return formatarNumeroParaMoedaBR(transporte.dmt, 2, 5)
    }
    const { composicoesAuxiliares } = this.props
    const dmtPadrao = composicoesAuxiliares.dmtsPadrao.find((it) => (
      it.codComposicao === transporte.codigoComposicaoTransporte
        && it.codInsumo === transporte.codigoInsumoTransportado
        && it.tipo === transporte.tipoTransporte
    ))

    return _.isNil(dmtPadrao) ? '0,00': `${formatarNumeroParaMoedaBR(dmtPadrao.distancia, 2, 5)} (DMT padrão)`
  }

  valorDmt (transporte) {
    if (!_.isNil(transporte.dmt)) {
      return transporte.dmt
    }

    const { composicoesAuxiliares } = this.props
    const dmtPadrao = composicoesAuxiliares.dmtsPadrao.find((it) => (
      it.codComposicao === transporte.codigoComposicaoTransporte
      && it.codInsumo === transporte.codigoInsumoTransportado
      && it.tipo === transporte.tipoTransporte
    ))

    return _.isNil(dmtPadrao) ? 0 : dmtPadrao.distancia
  }

  async handleConfirmarExclusaoTransporte () {
    const { codigoComposicao, contexto, onDadosAlterados } = this.props
    const { transporteExclusao } = this.state
    const resultado = await context_httppost(`precos/transportes/desvincular-transporte-composicao?composicao=${codigoComposicao}`, contexto, { transporte: transporteExclusao })
    if (resultado) {
      this.setState(defaultState)
      showSuccessMsg('Transporte desvinculado da composição.')
      onDadosAlterados()
    }
  }

  handleDetalharServicoTransporte(transporte) {
    const { detalharComposicao } = this.props
    if(transporte.codigoComposicaoTransporte !== 'N/A') {
      detalharComposicao(transporte.composicaoTransporte)
    }
  }

  render () {
    const { classes, composicoesAuxiliares, permitirEdicao, codigoComposicao } = this.props
    const { transporteEdicao, transporteExclusao, exibirDrawerAdicionar } = this.state
    const transportes = _.sortBy(composicoesAuxiliares.transportes, 'codigoInsumoTransportado')
    return (
      <>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '5%' }} className={classes.tableCell}>
                <MesmaLinha>
                  F
                  <IconButton data-tip='Clique para acessar o quadro resumo de transportes' data-for='tooltip-transporte' onClick={this.handleTransportesClick}>
                    <img width={21} height={21} src={iconTransporte} alt='Ver o quadro resumo de transportes' />
                  </IconButton>
                  <ReactTooltip id='tooltip-transporte' data-tip type='info' effect='float' place='top' />
                </MesmaLinha>
              </TableCell>
              <TableCell style={{ width: '40%' }} className={classes.tableCell}>
                Momento de Transporte
                {
                  permitirEdicao && (
                    <Button color='primary' onClick={() => this.setState({ exibirDrawerAdicionar: true })}>
                      + Adicionar
                    </Button>
                  )
                }
              </TableCell>
              <TableCell style={{ width: '5%' }} className={classes.tableCell}>Tipo</TableCell>
              <TableCell style={{ width: '8%' }} className={classes.tableCell}>Quantidade</TableCell>
              <TableCell style={{ width: '8%' }} className={classes.tableCell}>Unidade</TableCell>
              <TableCell style={{ width: '8%' }} className={classes.tableCell}>DMT</TableCell>
              <TableCell style={{ width: '8%' }} className={classes.tableCell}>Serv. Transp.</TableCell>
              <TableCell style={{ width: '8%' }} className={classes.tableCell}>Custo</TableCell>
              <TableCell align={'right'} className={classes.tableCell}>Custo Unitário</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              transportes.map((transporte) => (
                <TableRow key={`${transporte.codigoComposicaoTransporte}${transporte.codigoInsumoTransportado}${transporte.tipoTransporte.name}`}>
                  <TableCell className={classes.tableCell}>
                    {
                      permitirEdicao && (
                        <MesmaLinha>
                          <IconButton size='small' onClick={() => this.setState({ transporteEdicao: transporte })} color='primary'>
                            <EditIcon />
                          </IconButton>
                          <IconButton size='small' onClick={() => this.setState({ transporteExclusao: transporte })} color='primary'>
                            <DeleteIcon />
                          </IconButton>
                        </MesmaLinha>
                      )
                    }
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {transporte.codigoInsumoTransportado} - {transporte.descricao}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {transporte.tipoTransporte}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    { formatarNumeroParaMoedaBR(transporte.quantidade, 7, 7) }
                  </TableCell>

                  <TableCell className={classes.tableCell}> {transporte.unidade} </TableCell>

                  <TableCell className={classes.tableCell}>
                    { this.dmt(transporte) }
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    <Link onClick={() => this.handleDetalharServicoTransporte(transporte)}>
                      {transporte.codigoComposicaoTransporte}
                    </Link>
                  </TableCell>

                  <TableCell className={classes.tableCell}>
                    { formatarNumeroParaMoedaBR(composicoesAuxiliares.catalogoPrecos[transporte.codigoComposicaoTransporte], 2, 5) }
                  </TableCell>
                  <TableCell className={classes.tableCell} align='right'>
                    {
                      formatarNumeroParaMoedaBR(
                        composicoesAuxiliares.catalogoPrecos[transporte.codigoComposicaoTransporte] * transporte.quantidade * this.valorDmt(transporte),
                        2,
                        5
                      )
                    }
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <ResumoCustoTotal
          titulo='Custo Unitário Total de Transporte'
          valorCusto={composicoesAuxiliares.custoUnitarioTotalTransportes} />

        {
          exibirDrawerAdicionar && (
            <StyledDrawer
              PaperProps={{ className: classes.drawerEdicao }}
              open
              anchor={'right'}>
              <AdicionarTransporteComposicao
                codigoComposicao={codigoComposicao}
                onDadosAlterados={this.handleDadosAlterados}
                onCancelar={() => this.setState({ exibirDrawerAdicionar: false })}
              />
            </StyledDrawer>
          )
        }
        {
          transporteEdicao != null && (
            <StyledDrawer
              PaperProps={{ className: classes.drawerEdicao }}
              open
              anchor='right'>
              <EditarTransporteComposicao
                transporte={transporteEdicao}
                codigoComposicao={codigoComposicao}
                onCancelar={() => this.setState({ transporteEdicao: null })}
                onDadosAlterados={this.handleDadosAlterados}
              />
            </StyledDrawer>
          )
        }

        {
          transporteExclusao != null && (
            <ModalConfirmacao
              open
              titulo='Confirmar Exclusão de Transporte'
              confirmar={this.handleConfirmarExclusaoTransporte}
              onClose={() => this.setState({ transporteExclusao: null })}>
              <Typography>
                Deseja realmente desvincular o transporte {transporteExclusao.codigoInsumoTransportado} - {transporteExclusao.descricao} da composição?
              </Typography>
            </ModalConfirmacao>
          )
        }
      </>
    )
  }
}

export default withRouter(connect(mapStateToPropsContexto)(withStyles(styles)(TransportesComposicao)))
