import React from 'react'

import { Box, Button, Dialog, DialogContent, DialogTitle, makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(4)
  },
  boxConteudo: {
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  boxBotoes: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 0
  },
  botaoCancelar: {
    marginRight: theme.spacing(2)
  }
}))

const ModalExclusaoEmpreendimento = ({ click, handleDialog, cancelar }) => {
  const classes = useStyles()
  return (
    <Dialog open={handleDialog}>
      <DialogTitle>Excluir Empreendimento</DialogTitle>
      <DialogContent className={classes.dialog}>
        <Box className={classes.boxConteudo}>
          <Alert severity='warning'>
            A exclusão do empreendimento resultará na exclusão de todas
            as suas obras e orçamentos relacionados. Deseja continuar?
          </Alert>
          <Box className={classes.boxBotoes}>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => cancelar()}
              className={classes.botaoCancelar}
            >
              Cancelar
            </Button>
            <Button variant='contained' color='primary' onClick={() => click()}>
              Confirmar
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

ModalExclusaoEmpreendimento.propTypes = {
  click: PropTypes.func,
  handleDialog: PropTypes.bool,
  cancelar: PropTypes.func
}

export default ModalExclusaoEmpreendimento
