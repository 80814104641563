import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormularioAdicionarEquipamentoComposicao from './FormularioAdicionarEquipamentoComposicao'
import TituloVoltar from '../../../../../../../comum/TituloVoltar'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import FormularioAdicionarEquipamentoAvulso from './FormularioAdicionarEquipamentoAvulso'
import AdicionarEquipamentoAvulsoPesquisarEmReferenciasDePrecos from './AdicionarEquipamentoAvulsoPesquisarEmReferenciasDePrecos'

const CRIAR = 2
const REFERENCIA_PRECO = 1

class AdicionarEquipamentoAvulso extends Component {
  static propTypes = {
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      modo: 2
    }
  }

  render() {
    const { codigoComposicao, onDadosAlterados, onCancelar } = this.props
    const { modo } = this.state

    return (
      <>
        <TituloVoltar onClick={onCancelar} label='Voltar para Equipamentos'/>
        <Box my={2} ml={2}>
          <TextField variant='outlined' value={modo} select onChange={(e) => this.setState({ modo: e.target.value })} style={{minWidth: 500}}>
            
            <MenuItem value={CRIAR}>Criar novo equipamento</MenuItem>
            <MenuItem value={REFERENCIA_PRECO}>Pesquisar em referências de preço</MenuItem>
            
          </TextField>
        </Box>

        {
          modo === CRIAR && (
            <FormularioAdicionarEquipamentoAvulso
              onCancelar={onCancelar}
              onDadosAlterados={onDadosAlterados}
            />
          )
        }
        
        {
          modo === REFERENCIA_PRECO && (
            <AdicionarEquipamentoAvulsoPesquisarEmReferenciasDePrecos
              codigoComposicao={codigoComposicao}
              onCancelar={onCancelar}
              onDadosAlterados={onDadosAlterados}
            />
          )
        }
      </>
    )
  }
}

export default AdicionarEquipamentoAvulso
