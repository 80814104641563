import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import { showErrorMsg, showSuccessMsg, url } from '../../js/util'
import recuperarReferenciasPrecosOrcamentoEmEdicao from './recuperarReferenciasPrecosOrcamentoEmEdicao'

const excluirReferenciaPrecoOrcamento = (codReferencia, idEmpreendimento, idObra, idOrcamento) => async (dispatch) => {
  if (!idObra || !idEmpreendimento || !idOrcamento) throw Error('erro aqui')
  await axios.delete(`${url({ idEmpreendimento, idObra, idOrcamento })}/referencia-preco/${codReferencia}`,
    SLOW_REQUEST_CONFIG).then(() => {
    dispatch(recuperarReferenciasPrecosOrcamentoEmEdicao(idEmpreendimento, idObra, idOrcamento))
    showSuccessMsg('Referência removida com sucesso')
  })
    .catch(() => showErrorMsg('Erro ao remover a referência'))
}

export default excluirReferenciaPrecoOrcamento
