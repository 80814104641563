import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { FormControl, Typography } from '@material-ui/core'
import { handleCampoNumericoChange } from '../../../../../../../../../js/util'

const CampoValorNumerico = ({ classes, parametro, onCampoChange }) => {
  return (
    <FormControl variant='outlined' fullWidth>
      <Typography variant='subtitle2'>{parametro.tipo.label}</Typography>
      <TextField
        variant='outlined'
        autoFocus
        value={parametro.valorNumerico || ''}
        classes={{ root: classes.textfieldCriterio }}
        onChange={(e) => handleCampoNumericoChange(e, e => onCampoChange(e, parametro, 'valorNumerico'))}
      />
    </FormControl>
  )
}

CampoValorNumerico.propTypes = {
  classes: PropTypes.object,
  parametro: PropTypes.object.isRequired,
  onCampoChange: PropTypes.func.isRequired
}
export default CampoValorNumerico
