import _ from 'lodash'
import { BUSCAR_TIPOS_OBRA } from '../actions/types'

const reducerTiposObra = (state = [], action) => {
  switch (action.type) {
    case BUSCAR_TIPOS_OBRA:
      return _.sortBy(action.payload.data, 'descricao')
    default:
      return state
  }
}

export default reducerTiposObra
