import React, { Component } from 'react'
import { converterMoedaBRParaNumero, mapStateToPropsContexto, seStringVazia, showSuccessMsg } from '../../../../../../../../js/util'
import { context_httppost } from '../../../../../../../../js/httpRequest'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FormularioDadosBasicosComposicao from '../../FormularioDadosBasicosComposicao'

const defaultState = {
  codigo: '',
  codigoEmpty: false,
  descricao: '',
  descricaoEmpty: false,
  unidade: '',
  unidadeEmpty: false,
  producao: '',
  producaoEmpty: false,
  fic: '',
  ficEmpty: false,
  fit: '',
  fitEmpty: false,
  mes: '',
  mesEmpty: false,
  ano: '',
  anoEmpty: false,
  observacao: ''
}

class AdicionarAtividadeAuxiliar extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired,
    contexto: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = defaultState
    this.handleSalvarClick = this.handleSalvarClick.bind(this)
    this.handleCampoTextoChange = this.handleCampoTextoChange.bind(this)
    this.handleComboBoxChange = this.handleComboBoxChange.bind(this)
  }

  handleCampoTextoChange (event, campo) {
    const state = {}
    state[campo] = event.target.value
    state[`${campo}Empty`] = seStringVazia(event.target.value)
    this.setState(state)
  }

  handleComboBoxChange (event) {
    const { name, value } = event.target
    const state = {}
    state[name] = value
    state[`${name}Empty`] = this.isEmptyComboBox(event)

    this.setState(state)
  }

  isEmptyComboBox = (event) => {
    const { name, value } = event.target

    return name === 'mes' ? seStringVazia(value.nome) : seStringVazia(value.toString())
  }

  async handleSalvarClick () {
    const {
      codigo,
      descricao,
      unidade,
      producao,
      fic,
      fit,
      mes,
      ano,
      observacao
    } = this.state
    const codigoEmpty = seStringVazia(codigo)
    const descricaoEmpty = seStringVazia(descricao)
    const unidadeEmpty = seStringVazia(unidade)
    const producaoEmpty = seStringVazia(producao)
    const ficEmpty = seStringVazia(fic)
    const fitEmpty = seStringVazia(fit)
    const mesEmpty = seStringVazia(mes.nome)
    const anoEmpty = seStringVazia(ano.toString())

    if ([codigoEmpty, descricaoEmpty, unidadeEmpty, producaoEmpty, ficEmpty, fitEmpty, mesEmpty, anoEmpty].some(Boolean)) {
      this.setState({
        codigoEmpty,
        descricaoEmpty,
        unidadeEmpty,
        producaoEmpty,
        ficEmpty,
        fitEmpty,
        mesEmpty,
        anoEmpty
      })
    } else {
      const dadosSalvar = {
        codigo,
        descricao,
        unidade,
        producao: converterMoedaBRParaNumero(producao),
        fic: converterMoedaBRParaNumero(fic),
        fit: converterMoedaBRParaNumero(fit),
        mes: mes.numero,
        ano: ano,
        observacao
      }
      const { contexto, codigoComposicao, onDadosAlterados } = this.props
      const resultado = await context_httppost(`precos/atividades-auxiliares/criar?composicao=${codigoComposicao}`, contexto, dadosSalvar)
      if (resultado) {
        this.setState(defaultState)
        showSuccessMsg('Atividade auxiliar cadastrada com sucesso.')
        onDadosAlterados()
      }
    }
  }

  render () {
    const {
      codigo,
      codigoEmpty,
      descricao,
      descricaoEmpty,
      unidade,
      unidadeEmpty,
      producao,
      producaoEmpty,
      fic,
      ficEmpty,
      fit,
      fitEmpty,
      mes,
      mesEmpty,
      ano,
      anoEmpty,
      observacao
    } = this.state

    const { onCancelar } = this.props

    return (
      <FormularioDadosBasicosComposicao
        codigo={codigo}
        codigoEmpty={codigoEmpty}
        codigoEditavel={true}
        descricao={descricao}
        descricaoEmpty={descricaoEmpty}
        unidade={unidade}
        unidadeEmpty={unidadeEmpty}
        producao={producao}
        producaoEmpty={producaoEmpty}
        fic={fic}
        ficEmpty={ficEmpty}
        fit={fit}
        fitEmpty={fitEmpty}
        mes={mes}
        mesEmpty={mesEmpty}
        ano={ano}
        anoEmpty={anoEmpty}
        observacao={observacao}
        onSalvarClick={this.handleSalvarClick}
        onCancelarClick={onCancelar}
        handleCampoTextoChange={this.handleCampoTextoChange}
        onChangeComboBox={this.handleComboBoxChange}
      />
    )
  }
}

export default connect(mapStateToPropsContexto)(AdicionarAtividadeAuxiliar)
