import React, { useEffect, useState } from 'react'
import { Box, Button, Grid, IconButton, InputAdornment, Paper, TableCell, TextField, Tooltip, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Template from '../../layout/Template'
import { useDispatch, useSelector } from 'react-redux'
import buscarOrcamentos from '../../../actions/orcamentos/buscarOrcamentos'
import { Autocomplete } from '@material-ui/lab'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG, SLOW_REQUEST_HEADER } from '../../../js/operationProgressUtil'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import Paginacao from '../../comum/Paginacao'
import ItemComparacao from './ItemComparacao'
import { alterarSeparadorDecimal, CODE_NUMBER_BACKSPACE_EVENT, formatarNumeroParaMoedaBR, replaceSpecialChars } from '../../../js/util'
import _ from 'lodash'
import fileDownload from 'js-file-download'
import CloseIcon from '@material-ui/icons/Close'
import TableHeadCompararOrcamento from './TableHeadCompararOrcamento'
import { getComparator, stableSort } from '../../../js/paginacaoUtil'

const CompararOrcamento = () => {
  const dispatch = useDispatch()
  const [itens, setItens] = useState([])
  const [itensCopia, setItensCopia] = useState([])
  const [termoBusca, setTermoBusca] = useState('')
  const [order, setOrder] = useState('asc')
  const [orderBy, setOrderBy] = useState('codServico')
  const [orcamentoA, setOrcamentoA] = useState(null)
  const [orcamentoB, setOrcamentoB] = useState(null)
  const [totalAcrescimo, setTotalAcrescimo] = useState(0)
  const [totalReducao, setTotalReducao] = useState(0)
  const [valorTotalOrc1, setValorTotalOrc1] = useState(0)
  const [valorTotalOrc2, setValorTotalOrc2] = useState(0)
  const [pagina, setPagina] = useState(0)
  const [registrosPorPagina, setRegistrosPorPagina] = useState(10)
  const [error1, setError1] = useState(false)
  const [error2, setError2] = useState(false)

  const orcamentos = useSelector((state) => state.orcamentos)
  const contexto = useSelector((state) => state.contexto)

  useEffect(() => {
    contexto && dispatch(buscarOrcamentos(contexto.empreendimento.cod, contexto.obra.cod))
    contexto && setOrcamentoA(contexto.orcamento)
  }, [contexto, dispatch])

  const calcularTotalAcrescimo = () =>
    formatarNumeroParaMoedaBR(itens.filter((it) => it.valorImpacto > 0).reduce((accumulator, currentValue) => accumulator + currentValue.valorImpacto, 0), 2, 4)

  const calcularTotalReducao = () =>
    formatarNumeroParaMoedaBR(itens.filter((it) => it.valorImpacto < 0).reduce((accumulator, currentValue) => accumulator + currentValue.valorImpacto, 0), 2, 4)

 /* const calcularValorTotalOrcamento1 = () =>
    formatarNumeroParaMoedaBR(itens.reduce((accumulator, currentValue) => accumulator + currentValue.valorOrc1, 0), 2, 4)

  const calcularValorTotalOrcamento2 = () =>
    formatarNumeroParaMoedaBR(itens.reduce((accumulator, currentValue) => accumulator + currentValue.valorOrc2, 0), 2, 4)
*/
const calcularValorTotalOrcamento1 = () => {
  const valorTotalOrc1 = itens.reduce((total, item) => total + parseFloat(item.valorOrc1), 0);
  return formatarNumeroParaMoedaBR(valorTotalOrc1, 2, 4);
};

const calcularValorTotalOrcamento2 = () => {
  const valorTotalOrc2 = itens.reduce((total, item) => total + parseFloat(item.valorOrc2), 0);
  return formatarNumeroParaMoedaBR(valorTotalOrc2, 2, 4);
};

  useEffect(() => {
    //setValorTotalOrc1(calcularValorTotalOrcamento1())
    //setValorTotalOrc2(calcularValorTotalOrcamento2())
    setTotalAcrescimo(calcularTotalAcrescimo)
    setTotalReducao(calcularTotalReducao)
  }, [itens])

  const validarCamposObrigatorios = () => {
    if (_.isNil(orcamentoA)) {
      setError1(true)
      throw 'Campos obrigatórios não preenchidos'
    } else if (_.isNil(orcamentoB)) {
      setError2(true)
      throw 'Campos obrigatórios não preenchidos'
    } else {
      setError1(false)
      setError2(false)
    }
  }

  const handleComparar = async (e) => {
    const { empreendimento, obra } = contexto
    validarCamposObrigatorios()
    const { data } = await axios.get(`/api/empreendimentos/${empreendimento.cod}/obras/${obra.cod}/orcamentos/${orcamentoA.cod}/${orcamentoB.cod}/comparar-orcamento-selecionar-itens`, SLOW_REQUEST_CONFIG)
    
    setValorTotalOrc1(formatarNumeroParaMoedaBR(data[0].totalValorOrc1))
    setValorTotalOrc2(formatarNumeroParaMoedaBR(data[0].totalValorOrc2))
    setItens(data)
    setItensCopia(data)
  }

  const field = (value) => _.isNil(value) ? '' : value

  const handleDownloadPlanilhaAditivos = () => {
    const linhas = itens.map((it) => {
      const { codServico, descricao, unidade, quantidadeOrc2, quantidadeOrc1, precoUnitarioOrc2, precoUnitarioOrc1 } = it
      const quantidade = quantidadeOrc2 - quantidadeOrc1
      const precoUnitario = (!_.isNull(precoUnitarioOrc2) && precoUnitarioOrc2 !== 0) ? precoUnitarioOrc2 : precoUnitarioOrc1
      const valorParcial = quantidade * precoUnitario
      const separador = { separadorAtual: '.', separadorNovo: ',' }
      return `${field(codServico)};${field(descricao)};${field(unidade)};${alterarSeparadorDecimal(quantidade, separador)};${alterarSeparadorDecimal(precoUnitario, separador)};${alterarSeparadorDecimal(valorParcial, separador)}`
    }).join('\n')
    fileDownload(new Blob(['\ufeff', linhas]), 'aditivos', 'text/csv')
  }

  const handleDownloadPlanilhaDiferencas = async () => {
    const { empreendimento, obra } = contexto
    validarCamposObrigatorios()
    const HEADER = {
      responseType: 'arraybuffer',
      headers: {
        [SLOW_REQUEST_HEADER]: true
      }
    }
    const { data } = await axios.get(`/api/empreendimentos/${empreendimento.cod}/obras/${obra.cod}/orcamentos/${orcamentoA.cod}/${orcamentoB.cod}/comparar/download`, HEADER)
    fileDownload(new Blob([data]), 'diferenças', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
  }

  const handleLimparBusca = () => {
    setTermoBusca('')
    handleBuscar() 
  };
  
  useEffect(() => {
    handleBuscar();
  }, [termoBusca]);

  const criteriosBusca = (item) => {
    return (
      replaceSpecialChars(_.isNil(item.codServico) ? '' : item.codServico)
        .toUpperCase()
        .includes(replaceSpecialChars(termoBusca).toUpperCase()) ||
      replaceSpecialChars(item.descricao)
        .toUpperCase()
        .includes(replaceSpecialChars(termoBusca).toUpperCase()) ||
      replaceSpecialChars(item.ocorrencia)
        .toUpperCase()
        .includes(replaceSpecialChars(termoBusca).toUpperCase())
    )
  }

  const handleBuscar = () => {
    if (_.isEmpty(termoBusca)) {
      setItens(itensCopia);
    } else {
      const itensFiltrados = itensCopia.filter((item) => criteriosBusca(item));
      setItens(itensFiltrados);
    }
  };
  

  const handleKeyPress = (event) => {
    if (event.keyCode === CODE_NUMBER_BACKSPACE_EVENT) {
      setTermoBusca(termoBusca)
    }
    handleBuscar()
  }

  const handleLimparComparacao = () => {
    setOrcamentoB(null)
    setItens([])
    setItensCopia([])
    setTermoBusca('')
    setTotalAcrescimo(0)
    setTotalReducao(0)
    setValorTotalOrc1(0)
    setValorTotalOrc2(0)
    setPagina(0)
    setRegistrosPorPagina(10)
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

    
  return (
    <Template insidePaper={false}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Paper variant='outlined' elevation={0} style={{ padding: '20px' }}>
            <Box display='flex' alignItems='center'>
              <IconButton>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant='h6'>Comparar Orçamento</Typography>
            </Box>
            <Box mt={2} mb={2} display='flex' alignItems='center'>
              <Typography variant='subtitle2' style={{ marginRight: 10 }}>A</Typography>
              <Autocomplete
                options={orcamentos || []}
                getOptionLabel={(orcamentos) => orcamentos.descricao}
                value={orcamentoA}
                onChange={(e, value) => setOrcamentoA(value)}
                style={{ width: 500 }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    variant='outlined'
                    label={
                      !error1
                        ? 'Selecione o orçamento'
                        : 'Campo obrigatório'
                    }
                    error={error1}
                  />
                }
              />
            </Box>
            <Box mt={1} mb={2} display='flex' alignItems='center'>
              <Typography variant='subtitle2' style={{ marginRight: 10 }}>B</Typography>
              <Autocomplete
                options={orcamentos || []}
                getOptionLabel={(orcamentos) => orcamentos.descricao}
                value={orcamentoB}
                onChange={(e, value) => setOrcamentoB(value)}
                style={{ width: 500 }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    variant='outlined'
                    label={
                      !error2
                        ? 'Selecione o orçamento'
                        : 'Campo obrigatório'
                    }
                    error={error2}
                  />
                }
              />
            </Box>
            <Box mt={1} mb={2} display='flex' justifyContent='space-between'>
              <div>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={(e) => handleComparar(e)}
                  style={{ marginRight: 5 }}
                >
                  Comparar
                </Button>
                <Button
                  variant='contained'
                  onClick={handleLimparComparacao}
                  style={{ marginRight: 5 }}
                >
                  Limpar
                </Button>

              </div>
              {!_.isEmpty(itens) && (<div>
                <Tooltip title='Baixar planilha de itens orçamentários com diferenças' arrow>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={handleDownloadPlanilhaDiferencas}
                    startIcon={<CloudDownloadIcon />}
                    style={{ marginRight: 5 }}
                    data-for='tooltip-diferencas'
                  >
                    Diferenças
                  </Button>
                </Tooltip>

                <Tooltip title='Baixar planilha de aditivos' arrow>
                  <Button
                    variant='outlined'
                    color='primary'
                    onClick={handleDownloadPlanilhaAditivos}
                    startIcon={<CloudDownloadIcon />}
                  >
                    Aditivos
                  </Button>
                </Tooltip>
              </div>)}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box display='flex' alignItems='flex-end' style={{ height: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Card variant='outlined'>
                  <CardContent>
                    <Typography variant='subtitle2' color='text.secondary'>Orçamento A</Typography>
                    <Typography variant='body2'>{valorTotalOrc1}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card variant='outlined'>
                  <CardContent>
                    <Typography variant='subtitle2' color='text.secondary'>Orçamento B</Typography>
                    <Typography variant='body2'>{valorTotalOrc2}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card variant='outlined'>
                  <CardContent>
                    <Typography variant='subtitle2' color='text.secondary'>Acréscimos</Typography>
                    <Typography variant='body2'>{totalAcrescimo}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card variant='outlined'>
                  <CardContent>
                    <Typography variant='subtitle2' color='text.secondary'>Redução</Typography>
                    <Typography variant='body2'>{totalReducao}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Box mt={2}>
        <Paper variant='outlined' elevation={0}>
         
            <Box display='flex' alignItems='end' ml={1} mt={2} mb={2}>
            <TextField
              name='termoBusca'
              label='Filtrar por'
              placeholder='Digite parte do Código, Descrição ou Ocorrência'
              style={{ width: 700 }}
              value={termoBusca}
              onChange={(e) => setTermoBusca(e.target.value)}   
              onKeyUp={(e) => handleKeyPress(e)}
              variant='outlined'
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton color='primary' onClick={() => handleLimparBusca()}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
          
          <Table>
            <TableHeadCompararOrcamento
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody style={{ backgroundColor: '#eee' }}>
              {_.isEmpty(itens) && (<TableRow> <TableCell component='th' scope='row' colSpan={9} align='center'>Nenhum resultado encontrado</TableCell> </TableRow>)}
              { stableSort(itens, getComparator(order, orderBy))
                .slice(pagina * registrosPorPagina, pagina * registrosPorPagina + registrosPorPagina)
                .map((item, index) => <ItemComparacao key={`${item.codServico}-${index}`} item={item} />)
              }
            </TableBody>
          </Table>
          <Paginacao
            total={itens.length}
            registrosPorPagina={registrosPorPagina}
            pagina={pagina}
            onChangePage={(e, pag) => setPagina(pag)}
            onChangeRowsPerPage={(e) => setRegistrosPorPagina(e.target.value)}
          />
        </Paper>
      </Box>
    </Template>
  )
}

export default CompararOrcamento
