import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import { BUSCAR_TIPOS_UNIDADE_USUARIO } from '../types'

const buscarTiposUnidades = () => async (dispatch) => {
  const success = await axios.get('/api/unidades/usuario-logado', SLOW_REQUEST_CONFIG)
  return dispatch({
    type: BUSCAR_TIPOS_UNIDADE_USUARIO,
    payload: success
  })
}

export default buscarTiposUnidades
