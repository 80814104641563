import React from 'react'
import { Box, Button, Grid, Typography, Paper } from '@material-ui/core'
import useStyles from './styles'
import AnaliseRiscoPesquisa from './AnaliseRiscoPesquisa'
import AnaliseRiscoOrcamentos from './AnaliseRiscoOrcamentos'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'

const AnaliseRiscoEtapa1 = ({ orcamentos, onChangeOrcamentos, onChangeEtapa }) => {
  const classes = useStyles()

  return (
    <Box mt={2}>
      <Paper variant='outlined' elevation={0}>
        <Box p={2}>
          <Box my={1}>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link color='inherit' href='/'>
                  Início
              </Link>
              <Typography color='primary'>Análise de Risco</Typography>
            </Breadcrumbs>
          </Box>
          <Grid container spacing={3}>
            <Grid item md={3}>
              <AnaliseRiscoPesquisa onChangeOrcamentos={onChangeOrcamentos} />
            </Grid>
            <Grid item md={9}>
              <Box className={classes.boxRelative}>
                <Box display='flex' justifyContent='space-between'>
                  <Box display='flex'>
                    <Box className={classes.stepActive}>
                      <Typography color='primary' className={classes.stepTitle}>Etapa 1</Typography>
                      <Typography variant='caption'>Escolha de orçamentos</Typography>
                    </Box>
                    <Box className={classes.stepInactive}>
                      <Typography className={classes.stepTitle} style={{ color: 'gray' }}>Etapa 2</Typography>
                      <Typography variant='caption'>Gerar relatório de risco</Typography>
                    </Box>
                  </Box>
                  <Box display='flex' alignItems='flex-end'>
                    <Button
                        m={4}
                        color='primary'
                        variant='contained'
                        size='large'
                        onClick={() => onChangeEtapa(2)}
                        disabled={orcamentos.length === 0}
                      >
                      Próxima etapa
                    </Button>
                  </Box>
                </Box>
                <AnaliseRiscoOrcamentos orcamentos={orcamentos} />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  )
}

export default AnaliseRiscoEtapa1
