import React from 'react'
import { Box, FormControl, IconButton, InputAdornment, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const BuscarComPaginacao = ({
  placeholder, value, onChange, onClick, onKeyPress, disable, clear, width, filterOptions, filterField, onChangeFilter
}) => {
  return (
    <Box display='flex' alignContent={'baseline'}>
      {filterOptions &&
        <Box>
          <FormControl size='small'>
            <Select
            labelId='filterBY'
            value={filterField}
            name='filterBY'
            style={{ height: 44 }}
            size='small'
            onChange={ onChangeFilter }
            variant='outlined'
          >
            <MenuItem key='all' value='all'>
              Todos os campos
            </MenuItem>
            { filterOptions.map((it) => (
              <MenuItem key={it.value} value={it.value}>
                {it.label}
              </MenuItem>
            )) }
            </Select>
          </FormControl>
        </Box>}
        <FormControl size='small'>
          <TextField
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onKeyPress={onKeyPress}
            disabled={disable}
            size='small'
            variant='outlined'
            style={{ width: _.isNil(width) ? 350 : width }}
            InputProps={{
              endAdornment:
                <InputAdornment position='end'>
                  {
                    disable &&
                    <IconButton color='primary' onClick={clear}>
                      <CloseIcon />
                    </IconButton>
                  }
                  {!disable &&
                  <IconButton color='primary' onClick={onClick} style={{ cursor: 'default' }} >
                    <SearchIcon />
                  </IconButton>
                  }
                </InputAdornment>
            }}
          />
        </FormControl>
    </Box>
  )
}

BuscarComPaginacao.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  disable: PropTypes.bool.isRequired,
  clear: PropTypes.func,
  width: PropTypes.number
}

export default BuscarComPaginacao
