import WarningIcon from '@material-ui/icons/Warning'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'
import _ from 'lodash'

const IconeErro = ({ exibir, mensagem, onClick }) => (
  exibir && (
    <Tooltip title={mensagem}>
      <WarningIcon
        onClick={onClick}
        className='icon_alert'
        fontSize='small'
        style={{ margin: 2, cursor: `${_.isNil(onClick) ? 'default': 'pointer'}` }}  />
    </Tooltip>
  )
)

IconeErro.propTypes = {
  exibir: PropTypes.bool.isRequired,
  mensagem: PropTypes.string,
  onClick: PropTypes.func
}

export default IconeErro
