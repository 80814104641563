import axios from 'axios'
import _ from 'lodash'

import { arrayBufferToJson, ERROR_CANCEL_PROMISE, isArrayBuffer, showErrorMsg } from '../js/util'
import { getBrowserHistory } from '../js/applicationContext'
import store from '../store/configureStore'
import { logoutUser } from '../actions/authenticationJwtActions'

const handleError = {
  handleError: () => {
    return 'Um erro inesperado aconteceu, tente novamente mais tarde.'
  }
}

const errorHandlers = {
  401: {
    handleError: () => {
      store.dispatch(logoutUser())
      getBrowserHistory().push('/login')
      return 'Usuário não autenticado. Informe usuário e senha corretos e tente novamente.'
    }
  },
  403: {
    handleError: () => {
      getBrowserHistory().push('/forbidden')
      return 'Você não tem permissão para acessar este recurso, solicite ao responsável, por favor.'
    }
  },
  424: {
    handleError: (response) => {
      getBrowserHistory().push('/httperror')
      const responseAsJSON = isArrayBuffer(response.data) ? arrayBufferToJson(response.data) : response.data
      const servico = responseAsJSON && _.size(responseAsJSON.servicoInformado) ? responseAsJSON.servicoInformado : null
      return servico ? `O serviço "${servico}" está indisponível no momento.` : response.statusText
    }
  },
  400: handleError,
  405: handleError,
  422: {
    handleError: (response) => response
  },
  500: handleError,
  default: { // o default é importante. não remova.
    handleError: (msg) => {
      // se chegar aqui, é que o erro é incomum e de alguma forma o usuário deve ser alertado
      showErrorMsg(msg)
      return msg
    }
  }
}

const onFulfilled = (response) => response

const onRejected = (err) => {
  let msgText = ''
  if (err.message === ERROR_CANCEL_PROMISE) {
    return Promise.reject(err)
  } else {
    if (_.isUndefined(err.response)) {
      msgText = err.message
      errorHandlers.default.handleError(msgText)
    } else {
      const code = err.response.status
      const { statusText, data } = err.response

      let errorHandler = null
      if (!_.isUndefined(errorHandlers[code])) {
        errorHandler = errorHandlers[code]
      } else if (code >= 400) {
        errorHandler = errorHandlers.default
      }

      if (errorHandler) {
        if (code === 422 || code === 412) {
          msgText = errorHandler.handleError(data)
        } else {
          msgText = errorHandler.handleError(statusText)
        }
      } else {
        msgText = `${code} ${statusText}`
      }
    }
    showErrorMsg(msgText)
    return Promise.reject(err)
  }
}

axios.interceptors.response.use(onFulfilled, onRejected)
