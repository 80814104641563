import React from 'react'
import { FormControl, Typography } from '@material-ui/core'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  textField: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 200
  }
})

const ComboBox = ({
  classes, label, value, empty, onChange, children, fullWidth
}) => (
  <FormControl
    className={classes.textField}
    fullWidth={fullWidth === undefined ? true : fullWidth}
    error={empty}>
    <Typography variant='subtitle2' gutterBottom>{label}</Typography>
    <Select
      value={value}
      variant='outlined'
      onChange={onChange}>
      <MenuItem value=''>
        <em>---</em>
      </MenuItem>
      {
        children
      }
    </Select>
  </FormControl>
)

ComboBox.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
  empty: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  fullWidth: PropTypes.bool
}

export default withStyles(styles)(ComboBox)
