import React, { Component } from 'react'
import _ from 'lodash'
import { context_httpget, context_httppost } from '../../../../../../../../js/httpRequest'
import { formatarNumeroParaMoedaBR, mapStateToPropsContexto, showAlertMsg, showSuccessMsg } from '../../../../../../../../js/util'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { withStyles } from '@material-ui/core'
import styles from '../../../styles'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import StyledTableContainerAdicionar from '../../../../../../../comum/StyledTableContainerAdicionar'

class AdicionarMaterialComposicaoSelecionarDoOrcamento extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired,
    contexto: PropTypes.object,
    classes: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      materiais: [],
      materialSelecionado: null
    }
    this.handleConfirmarClick = this.handleConfirmarClick.bind(this)
  }

  componentDidMount () {
    if (!_.isNil(this.props.contexto)) {
      this.recuperarMateriais()
    }
  }

  async recuperarMateriais() {
    const { contexto } = this.props
    const data = await context_httpget('precos/insumos/materiais', contexto)
    if (data) {
      this.setState({
        materiais: data
      })
    }
  }

  handleRowMaterialClick (codigoMaterial) {
    const { materialSelecionado } = this.state
    if (materialSelecionado !== codigoMaterial) {
      this.setState({ materialSelecionado: codigoMaterial })
    }
  }

  async handleConfirmarClick () {
    const { materialSelecionado } = this.state
    if (_.isNil(materialSelecionado)) {
      showAlertMsg('Por favor, selecione um material para vincular à composição.')
      return
    }
    const { codigoComposicao, contexto, onDadosAlterados } = this.props
    const dados = await context_httppost(`precos/vincular-material?composicao=${codigoComposicao}&material=${materialSelecionado}`, contexto)
    if (dados) {
      showSuccessMsg(`Material ${materialSelecionado} vinculado à composição.`)
      onDadosAlterados()
    }
  }

  render() {
    const { classes, onCancelar } = this.props
    const { materiais, materialSelecionado } = this.state

    return (
      <>
        <StyledTableContainerAdicionar>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Referência</TableCell>
                <TableCell>Data Referência</TableCell>
                <TableCell>Código</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Unidade</TableCell>
                <TableCell>Preço Unitário (R$)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                materiais.map((it) => {
                  const estiloCelula = materialSelecionado === it.materialOrcamento.codigo ? { color: 'white' } : {}
                  return (
                    <TableRow
                      key={it.materialOrcamento.codigo}
                      className={materialSelecionado === it.materialOrcamento.codigo ? classes.linhaItemSelecionada : classes.linhaItem}
                      onClick={() => this.handleRowMaterialClick(it.materialOrcamento.codigo)}>
                      <TableCell style={estiloCelula}>{it.materialOrcamento.sistemaReferencia}</TableCell>
                      <TableCell style={estiloCelula}>{it.materialOrcamento.mes}/{it.materialOrcamento.ano}</TableCell>
                      <TableCell style={estiloCelula}>{it.materialOrcamento.codigo}</TableCell>
                      <TableCell style={estiloCelula}>{it.materialOrcamento.descricao}</TableCell>
                      <TableCell style={estiloCelula}>{it.materialOrcamento.unidade}</TableCell>
                      <TableCell style={estiloCelula}>{formatarNumeroParaMoedaBR(it.materialOrcamento.precoUnitario, 2, 5)}</TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
          </StyledTableContainerAdicionar>
        <MesmaLinha>
          <Button color='primary' variant='contained' onClick={this.handleConfirmarClick}>Confirmar</Button>
          <Button color='primary' onClick={onCancelar}>Cancelar</Button>
        </MesmaLinha>
      </>
    )
  }
}

export default connect(mapStateToPropsContexto)(withStyles(styles)(AdicionarMaterialComposicaoSelecionarDoOrcamento))
