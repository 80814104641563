import React, { Component } from 'react'
import DadosBasicos from './DadosBasicos'
import { context_httpput } from '../../../../../../../js/httpRequest'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Criterios from './criterios/Criterios'
import ResultadoExpressao from './ResultadoExpressao'
import Grid from '@material-ui/core/Grid'
import _ from 'lodash'

class Expressao extends Component {
  static propTypes = {
    contexto: PropTypes.object,
    tipoAnaliseSelecionada: PropTypes.string.isRequired,
    expressaoAtiva: PropTypes.object,
    onFecharDrawer: PropTypes.func,
    onExpressaoChange: PropTypes.func,
    onCriarTipologia: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      resultado: null,
      criterios: null,
      expressoesDisponiveis: []
    }
    this.executarExpressao = this.executarExpressao.bind(this)
    this.handleAtualizarResultadosExpressaoChange = this.handleAtualizarResultadosExpressaoChange.bind(this)
  }

  componentDidMount () {
    if(this.props.expressaoAtiva) {
      this.executarExpressao()
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate (prevProps, prevState, snapshot) {
    const { resultado, criterios } = this.state
    if (this.props.expressaoAtiva && (_.isNil(resultado) && _.isNil(criterios))) {
      this.executarExpressao()
    }
  }

  async executarExpressao () {
    const { contexto, expressaoAtiva, tipoAnaliseSelecionada } = this.props
    const detalheExecucaoExpressao = await context_httpput(`analise-conteudo/expressoes/${expressaoAtiva.codigo}/play?tipoAnalise=${tipoAnaliseSelecionada}`, contexto)
    if (detalheExecucaoExpressao) {
      this.setState({
        resultado: detalheExecucaoExpressao.resultado,
        criterios: detalheExecucaoExpressao.criterios,
        expressoesDisponiveis: detalheExecucaoExpressao.expressoesDisponiveis
      })
    }
  }

  handleAtualizarResultadosExpressaoChange(expressaoAtiva, resultado) {
    this.setState({
      resultado
    }, () => this.props.onExpressaoChange(expressaoAtiva))
  }

  render () {
    const { onFecharDrawer, tipoAnaliseSelecionada, expressaoAtiva, onExpressaoChange, onCriarTipologia } = this.props
    const { criterios, expressoesDisponiveis, resultado } = this.state
    return (
      <React.Fragment>
        <Grid container spacing={2}>
          <Grid item lg={8}>
            <DadosBasicos
              expressaoAtiva={expressaoAtiva}
              tipoAnaliseSelecionada={tipoAnaliseSelecionada}
              onExpressaoChange={onExpressaoChange}
              onFecharDrawer={onFecharDrawer}
            />
          </Grid>
        </Grid>

        { expressaoAtiva && (
          <>
            <Criterios
              expressao={expressaoAtiva}
              criterios={criterios}
              expressoesDisponiveis={expressoesDisponiveis}
              onExpressaoChange={this.handleAtualizarResultadosExpressaoChange}
              tipoAnaliseSelecionada={tipoAnaliseSelecionada}
            />
            { resultado &&
              <ResultadoExpressao
                value={resultado}
                expressao={expressaoAtiva}
                onCriarTipologia={onCriarTipologia}
              />
            }
          </>)
        }
      </React.Fragment>
    )
  }
}

export const mapStateToProps = ({ contexto }) => ({
  contexto
})

export default connect(mapStateToProps)(Expressao)
