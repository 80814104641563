import React, { Component, Fragment } from 'react'
import { Link, TableCell, TableRow } from '@material-ui/core'
import PropTypes from 'prop-types'
import { converterMoedaBRParaNumero, converterParaNumero, formatarNumeroParaMoedaBR, handleCampoNumericoChange, mapStateToPropsContexto, seStringVazia, showSuccessMsg } from '../../../../../js/util'
import IconButton from '@material-ui/core/IconButton'
import { context_httpget, context_httpput } from '../../../../../js/httpRequest'
import TextField from '@material-ui/core/TextField'
import MesmaLinha from '../../../../comum/MesmaLinha'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import EditIcon from '@material-ui/icons/Edit'
import { connect } from 'react-redux'
import ModalComposicoes from '../composicoes/ModalComposicoes'

class ItemMaoDeObraRow extends Component {
  static propTypes = {
    maoDeObra: PropTypes.object.isRequired,
    contexto: PropTypes.object,
    onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.func
  }
  constructor (props) {
    super(props)
    this.state = {
      editando: false,
      descricao: '',
      descricaoEmpty: false,
      unidade: '',
      unidadeEmpty: false,
      salario: '',
      salarioEmpty: false,
      encargosTotais: '',
      encargosTotaisEmpty: false,
      periculosidadeInsalubridade: '',
      periculosidadeInsalubridadeEmpty: false,
      modalComposicoes: false,
      composicoes: null
    }
    this.handleCancelarEdicao = this.handleCancelarEdicao.bind(this)
    this.handleSalvarEdicao = this.handleSalvarEdicao.bind(this)
  }

  componentDidMount () {
    if (this.props.maoDeObra) {
      this.copiarDadosParaEstado()
    }
  }

  copiarDadosParaEstado() {
    const { maoDeObra } = this.props
    this.setState({
      descricao: maoDeObra.maoDeObraOrcamento.descricao,
      descricaoEmpty: false,
      unidade: maoDeObra.maoDeObraOrcamento.unidade,
      unidadeEmpty: false,
      salario: formatarNumeroParaMoedaBR(maoDeObra.maoDeObraOrcamento.salario, 2, 5),
      salarioEmpty: false,
      encargosTotais: formatarNumeroParaMoedaBR(maoDeObra.maoDeObraOrcamento.encargosTotais, 2, 5),
      encargosTotaisEmpty: false,
      periculosidadeInsalubridade: formatarNumeroParaMoedaBR(maoDeObra.maoDeObraOrcamento.periculosidadeInsalubridade, 2, 5),
      periculosidadeInsalubridadeEmpty: false
    })
  }

  handleCampoTextoChange (event, campo) {
    const state = {}
    state[campo] = event.target.value
    state[`${campo}Empty`] = seStringVazia(event.target.value)
    this.setState(state)
  }

  handleCancelarEdicao () {
    this.copiarDadosParaEstado()
    this.setState({ editando: false })
  }

  async handleSalvarEdicao () {
    const { descricao, unidade, salario, encargosTotais, periculosidadeInsalubridade } = this.state
    const descricaoEmpty = seStringVazia(descricao)
    const unidadeEmpty = seStringVazia(unidade)
    const salarioEmpty = seStringVazia(salario)
    const encargosTotaisEmpty = seStringVazia(encargosTotais)
    const periculosidadeInsalubridadeEmpty = seStringVazia(periculosidadeInsalubridade)

    if([descricaoEmpty, unidadeEmpty, salarioEmpty, encargosTotaisEmpty, periculosidadeInsalubridadeEmpty].some(Boolean)) {
      this.setState({
        descricaoEmpty, unidadeEmpty, salarioEmpty, encargosTotaisEmpty, periculosidadeInsalubridadeEmpty
      })
    } else {
      const { maoDeObra, contexto } = this.props
      const dados = {
        descricao,
        unidade,
        salario: converterMoedaBRParaNumero(salario),
        encargosTotais: converterMoedaBRParaNumero(encargosTotais),
        periculosidadeInsalubridade: converterMoedaBRParaNumero(periculosidadeInsalubridade)
      }
      const ok = await context_httpput(`precos/maos-de-obra/${maoDeObra.maoDeObraOrcamento.codigo}`, contexto, dados)
      if (ok) {
        showSuccessMsg('Dados da mão de obra alterados com sucesso.')
        this.setState({ editando: false })
      }
    }
  }

  abrirModalComposicoes () {
    const { contexto, maoDeObra } = this.props

    context_httpget(`precos/composicoes/mao-de-obra/${maoDeObra.maoDeObraOrcamento.codigo}`, contexto)
      .then((composicoes) => {
        this.setState({
          composicoes,
          modalComposicoes: true
        })
      })
  }

  fecharModalComposicoes () {
    this.setState({ modalComposicoes: false })
  }

  render () {
    const { maoDeObra, onAtualizarItemCurvaABCComposicaoDuplicada } = this.props
    const {
      editando,
      descricao,
      descricaoEmpty,
      unidade,
      unidadeEmpty,
      salario,
      salarioEmpty,
      encargosTotais,
      encargosTotaisEmpty,
      periculosidadeInsalubridade,
      periculosidadeInsalubridadeEmpty,
      modalComposicoes,
      composicoes
    } = this.state

    const custo = formatarNumeroParaMoedaBR(converterParaNumero(salario) * (1 + (converterParaNumero(encargosTotais) / 100) + (converterParaNumero(periculosidadeInsalubridade) / 100)), 2, 4)

    return (
      <Fragment>
        <TableRow>
          <TableCell>
            {maoDeObra.maoDeObraOrcamento.sistemaReferencia}
          </TableCell>
          <TableCell>
            {maoDeObra.maoDeObraOrcamento.mes}/{maoDeObra.maoDeObraOrcamento.ano}
          </TableCell>
          <TableCell>
            {maoDeObra.maoDeObraOrcamento.codigo}
          </TableCell>
          <TableCell>
            {
              editando ? (
                <TextField
                  fullWidth
                  variant='outlined'
                  value={descricao}
                  error={descricaoEmpty}
                  onChange={(e) => this.handleCampoTextoChange(e, 'descricao')}
                />
              ) : descricao
            }
          </TableCell>

          <TableCell>
            {
              editando ? (
                <TextField
                  fullWidth
                  variant='outlined'
                  value={unidade}
                  error={unidadeEmpty}
                  onChange={(e) => this.handleCampoTextoChange(e, 'unidade')}
                />
              ) : unidade
            }
          </TableCell>
          <TableCell>
            {
              editando ? (
                <TextField
                  fullWidth
                  variant='outlined'
                  value={salario}
                  error={salarioEmpty}
                  onChange={(e) => handleCampoNumericoChange(e, (e) => this.handleCampoTextoChange(e, 'salario'))}
                />
              ) : salario
            }
          </TableCell>
          <TableCell>
            {
              editando ? (
                <TextField
                  fullWidth
                  variant='outlined'
                  value={encargosTotais}
                  error={encargosTotaisEmpty}
                  onChange={(e) => handleCampoNumericoChange(e, (e) => this.handleCampoTextoChange(e, 'encargosTotais'))}
                />
              ) : encargosTotais
            }
          </TableCell>
          <TableCell>
            {
              editando ? (
                <TextField
                  fullWidth
                  variant='outlined'
                  value={periculosidadeInsalubridade}
                  error={periculosidadeInsalubridadeEmpty}
                  onChange={(e) => handleCampoNumericoChange(e, (e) => this.handleCampoTextoChange(e, 'periculosidadeInsalubridade'))}
                />
              ) : periculosidadeInsalubridade
            }
          </TableCell>
          <TableCell>
            { custo }
          </TableCell>
          <TableCell>
            <Link display='inline' color='primary' onClick={() => this.abrirModalComposicoes()}>
              {maoDeObra.qtdComposicoes} composiç{maoDeObra.qtdComposicoes === 1 ? 'ão' : 'ões'}
            </Link>
          </TableCell>
          <TableCell align='center'>
            {
              editando ? (
                <MesmaLinha>
                  <IconButton color='primary' onClick={this.handleSalvarEdicao}>
                    <DoneIcon />
                  </IconButton>
                  <IconButton color='primary' onClick={this.handleCancelarEdicao}>
                    <ClearIcon />
                  </IconButton>
                </MesmaLinha>
              ) : (
                <IconButton color='primary' onClick={() => this.setState({ editando: true })}>
                  <EditIcon />
                </IconButton>
              )
            }
          </TableCell>
        </TableRow>

        <ModalComposicoes
          open={modalComposicoes}
          close={() => this.fecharModalComposicoes()}
          titulo={`Composições da mão de obra: ${maoDeObra.maoDeObraOrcamento.codigo} - ${maoDeObra.maoDeObraOrcamento.descricao}`}
          composicoes={composicoes}
          onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
        />
      </Fragment>
    )
  }
}

export default connect(mapStateToPropsContexto)(ItemMaoDeObraRow)
