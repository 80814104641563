import React, { useState } from 'react'
import { Link, TableCell, TableRow } from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import RelatorioComposicao from '../../relatorios/precos/composicao/RelatorioComposicao'
import PropTypes from 'prop-types'
import { possuiSubComposicao } from '../preco'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'

const useStyles = makeStyles({
  textDecorat: {
    textDecoration: 'none'
  }
})

const ItemComposicaoRow = ({ composicaoComAuxiliares, onExcluirComposicao, handleVerComposicaoAuxiliar, onAtualizarItemCurvaABCComposicaoDuplicada }) => {
  const classes = useStyles()
  const [openDrawer, setOpenDrawer] = useState(false)

  const redirectComposicaoAuxiliar = (composicao) => {
    const rederComposicaoAuxiliar = true
    handleVerComposicaoAuxiliar(composicao, rederComposicaoAuxiliar)
  }

  const redirectExcluirComposicao = (composicao) => {
    const renderExcluirComposicao = true
    onExcluirComposicao(composicao, renderExcluirComposicao)
  }

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  return (
    <>
      <TableRow>
        <TableCell component='th' scope='row'>
          {composicaoComAuxiliares.codigo}
        </TableCell>
        <TableCell align='left'>
          <Link id={'drawerId'} className={classes.textDecorat} onClick={() => toggleDrawer()}> {composicaoComAuxiliares.descricao} </Link>
        </TableCell>
        <TableCell align='right'>{composicaoComAuxiliares.periodo}</TableCell>
        { possuiSubComposicao(composicaoComAuxiliares)
          ? (
            <TableCell align='right'>
              <Link id={'composicaoAuxiliarId'} onClick={() => redirectComposicaoAuxiliar(composicaoComAuxiliares)} className={classes.textDecorat}> Ver </Link>
            </TableCell>)
          : (<TableCell align='right' />) }
        <TableCell align='right'><Button onClick={() => redirectExcluirComposicao(composicaoComAuxiliares)} className={classes.textDecorat}><DeleteIcon color='primary' /></Button></TableCell>
      </TableRow>

      {
        openDrawer &&
        <TableRow>
          <TableCell colSpan={4}>
            <RelatorioComposicao
              permitirEdicao
              composicao={composicaoComAuxiliares}
              onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
            />
          </TableCell>
        </TableRow>
      }
    </>
  )
}

ItemComposicaoRow.propTypes = {
  composicaoComAuxiliares: PropTypes.object.isRequired,
  handleVerComposicaoAuxiliar: PropTypes.func.isRequired,
  onExcluirComposicao: PropTypes.func.isRequired,
  onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.func
}

export default ItemComposicaoRow
