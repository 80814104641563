import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import StyledDrawer from '../../../comum/StyledDrawer'
import styles from '../relatorios/precos/styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import TituloVoltar from '../../../comum/TituloVoltar'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import MesmaLinha from '../../../comum/MesmaLinha'
import Grid from '@material-ui/core/Grid'
import StyledFormControl from '../../../comum/StyledFormControl'
import Divider from '@material-ui/core/Divider'
import { seStringVazia, seObjectVazio, recuperarMes, mapStateToPropsContexto, MESES, UFS, showSuccessMsg } from '../../../../js/util'
import { context_httpget, httpget, context_httppatch, httppatch } from '../../../../js/httpRequest'

const defaultState = {
  sistema: '',
  sistemas: [],
  sistemaEmpty: false,
  periodos: [],
  periodoAtual: '',
  periodoAtualEmpty: false,
  periodoNovo: '',
  periodoNovoEmpty: false,
  referenciaEmpty: false,
  ufs: [],
  uf: '',
  ufEmpty: false,
  referenciasPreco: []
}

export class AtualizarReferencia extends Component {
  static propTypes = {
    onCancelar: PropTypes.func,
    classes: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = defaultState
  }

  componentDidMount () {
    this.recuperarReferenciasPrecoOrcamento()
  }

  recuperarReferenciasPrecoOrcamento = async () => {
    const { contexto } = this.props
    const referenciasPreco = await context_httpget('referencias-preco', contexto)
    if(referenciasPreco) {
      const referenciasAtualizadas = referenciasPreco.map(referencia => {
        const { mes, ano, sistemaReferencia } = referencia
        const nomeMes = MESES.find(item => item.numero === mes)?.nome.toLocaleLowerCase() || ''
        const periodo = {referencia: `${nomeMes}/${ano}`, mes: mes, ano: ano}
        const uf = UFS.find(item => item.sigla === referencia.uf)
        return { sistemaReferencia, periodo, uf }
      })
      const sistemas = [...new Set(referenciasAtualizadas.map(referencia => referencia.sistemaReferencia))]
      const sistema = referenciasAtualizadas[0].sistemaReferencia
      const ufs = [...new Set(referenciasAtualizadas.filter(item => item.sistemaReferencia === sistema).map(referencia => referencia.uf))]
      this.setState({
        sistema,
        sistemas,
        uf: referenciasAtualizadas[0].uf.sigla,
        periodoAtual: referenciasAtualizadas[0].periodo,
        referenciasPreco: referenciasAtualizadas,
        ufs
      }, async () => {
        await this.recuperarPeriodos()
      })
    }
  }

  recuperarReferenciaPrecoPrincipalOrcamento = async () => {
    const { contexto } = this.props
    const referenciaPreco = await context_httpget('referencias-preco/principal', contexto)
    if(referenciaPreco) {
      this.setState({
        sistema: referenciaPreco.sigla,
        uf: referenciaPreco.uf
      }, async () => {
        await this.recuperarPeriodos()
        this.setState({ periodo: this.state.periodos.find(it => it.referencia === `${recuperarMes(referenciaPreco.mes).nome}/${referenciaPreco.ano}`) })
      })
    }
  }

  recuperarPeriodos = async () => {
    const periodos = await httpget(`/api/referencias-preco/periodos/${this.state.sistema}`)
    this.setState({ periodos })
  }

  handleChange = (event) => {
    const { name, value } = event.target
    const state = {}
    state[name] = value
    state[`${name}Empty`] = seStringVazia(['periodoNovo', 'periodoAtual'].includes(name) ? value.referencia : value);
    this.setState(state)
  }

  handleChangeCampoSistema = (event) => {
    const { referenciasPreco } = this.state
    const ufs = [...new Set(referenciasPreco.filter(item => item.sistemaReferencia === event.target.value).map(referencia => referencia.uf))]
    this.setState({ sistema: event.target.value, periodoNovo: '', periodoAtualEmpty: false, periodoNovoEmpty: false, ufs }, () => this.recuperarPeriodos())
  }

  atualizarReferencia = async () => {
    const { sistema, uf, periodoAtual, periodoNovo } = this.state
    const { contexto, onReferenciaAtualizada } = this.props

    const sistemaEmpty = seStringVazia(sistema)
    const ufEmpty = seStringVazia(uf)
    const periodoAtualEmpty = seObjectVazio(periodoAtual)
    const periodoNovoEmpty = seObjectVazio(periodoNovo)    

    const empties = [sistemaEmpty, ufEmpty, periodoAtualEmpty, periodoNovoEmpty]

    if (empties.some(Boolean)) {
      this.setState({
        sistemaEmpty, ufEmpty, periodoAtualEmpty, periodoNovoEmpty
      })
    } else {
      const atualizarReferencia = await context_httppatch(`atualizar-referencia/${sistema}/${uf}/${periodoAtual.mes}/${periodoAtual.ano}/${periodoNovo.mes}/${periodoNovo.ano}`, contexto)
      if (atualizarReferencia) {
        showSuccessMsg('Referência de preço atualizada com sucesso.')
        onReferenciaAtualizada()
      }
    }
  }

  render() {
    const { sistema, sistemas, sistemaEmpty, periodos, periodoAtual, periodoNovo, periodoAtualEmpty, periodoNovoEmpty, referenciaEmpty, ufs, uf, ufEmpty, referenciasPreco } = this.state
    const { onCancelar, classes } = this.props

    return (
      <StyledDrawer PaperProps={{ className: classes.drawerReferencia }} open anchor="right">
        <TituloVoltar onClick={onCancelar} label='Voltar'/>
        <Grid className={classes.gridReferencia} container spacing={2}>
          <Grid item xs={12} md={4}>
            <Typography className={classes.lblDadosBasicos}>Referência:</Typography>
            <StyledFormControl variant='outlined' className={classes.formControl}>
              <Select
                name={'sistema'}
                error={sistemaEmpty}
                value={sistema}
                onChange={(e) => this.handleChangeCampoSistema(e)}
              >
                {
                  sistemas.map((it) => (
                    <MenuItem key={it} value={it}>
                      <Typography>{it}</Typography>
                    </MenuItem>
                  ))
                }
              </Select>
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography className={classes.lblDadosBasicos}>Estado:</Typography>
            <StyledFormControl variant='outlined' className={classes.formControl}>
              <Select
                name={'uf'}
                error={ufEmpty}
                value={uf}
                onChange={(e) => this.handleChange(e)}
              >
                {
                  ufs.map((uf) => (
                    <MenuItem key={uf.sigla} value={uf.sigla}>
                      <Typography>{uf.nome}</Typography>
                    </MenuItem>
                  ))
                }
              </Select>
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography className={classes.lblDadosBasicos}>Período atual:</Typography>
            <StyledFormControl variant='outlined' className={classes.formControl}>
              <Select
                name={'periodoAtual'}
                error={periodoAtualEmpty}
                value={periodoAtual}
                onChange={(e) => this.handleChange(e)}
              >
                {
                  referenciasPreco.filter(item => (item.sistemaReferencia === sistema) && (item.uf.sigla === uf)).map((ref) => (
                    <MenuItem key={ref.periodo.referencia} value={ref.periodo}>
                      <Typography>{ref.periodo.referencia}</Typography>
                    </MenuItem>
                  ))
                }
              </Select>
            </StyledFormControl>
          </Grid>
        </Grid>

        <Grid className={classes.gridReferencia} container spacing={2}>
          <Grid item xs={12} md={4}>
          <Typography className={classes.lblDadosBasicos}>Novo Período:</Typography>
            <StyledFormControl variant='outlined' className={classes.formControl}>
              <Select
                name={'periodoNovo'}
                error={periodoNovoEmpty}
                value={periodoNovo}
                onChange={(e) => this.handleChange(e)}
              >
                {
                  periodos.map((p) => (
                    <MenuItem key={p.referencia} value={p}>
                      <Typography>{p.referencia}</Typography>
                    </MenuItem>
                  ))
                }
              </Select>
            </StyledFormControl>
          </Grid>
        </Grid>

        <Box my={1}>
          <Typography >
            Se houver código de insumos na referência, estado e novo período informados, o sistema substituirá os preços existentes no período atual pelos encontrados na nova data selecionada.
          </Typography>
        </Box>

        <Box my={1}>
          <Divider/>
        </Box>

        <Box mt={3}>
          <MesmaLinha>
            <Button color='primary' variant='contained' onClick={this.atualizarReferencia}>Confirmar</Button>
            <Button color='default' onClick={onCancelar}>Cancelar</Button>
          </MesmaLinha>
        </Box>
      </StyledDrawer>
    )
  }
}

AtualizarReferencia.propTypes = {
  classes: PropTypes.object.isRequired
}


export default connect(mapStateToPropsContexto)(withStyles(styles)(AtualizarReferencia));
