import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import TituloVoltar from '../../../../../../../comum/TituloVoltar'
import FormularioAdicionarMaterialAvulso from './FormularioAdicionarMaterialAvulso'
import AdicionarMaterialAvulsoPesquisarEmReferenciasDePrecos from './AdicionarMaterialAvulsoPesquisarEmReferenciasDePrecos'
import { connect } from 'react-redux'
import styles from '../../../styles'
import { withStyles } from '@material-ui/core'
import { mapStateToPropsContexto } from '../../../../../../../../js/util'

const CRIAR = 2
const REFERENCIA_PRECO = 1

class AdicionarMaterialAvulso extends Component {
  static propTypes = {
    
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      modo: 2
    }
  }

  render() {
    const { codigoComposicao, onDadosAlterados, onCancelar } = this.props
    const { modo } = this.state

    return (
      <>
        <TituloVoltar onClick={onCancelar} label='Voltar para Materiais'/>
        <Box my={2} ml={2}>
          <TextField variant='outlined' value={modo} select onChange={(e) => this.setState({ modo: e.target.value })} style={{minWidth: 500}}>
            
            <MenuItem value={CRIAR}>Criar novo material</MenuItem>
            <MenuItem value={REFERENCIA_PRECO}>Pesquisar em referências de preço</MenuItem>
            
          </TextField>
        </Box>

        {
          modo === CRIAR && (
            <FormularioAdicionarMaterialAvulso
              onCancelar={onCancelar}
              onDadosAlterados={onDadosAlterados}
            />
          )
        }
        
        {
          modo === REFERENCIA_PRECO && (
            <AdicionarMaterialAvulsoPesquisarEmReferenciasDePrecos
              onCancelar={onCancelar}
              onDadosAlterados={onDadosAlterados}
              isPesquisaMateriaisEmTempoFixo={false}
            />
          )
        }
      </>
    )
  }
}

export default connect(mapStateToPropsContexto)(withStyles(styles)(AdicionarMaterialAvulso))
