import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { context_httpget, context_httppost } from '../../../../../../../../js/httpRequest'
import { mapStateToPropsContexto, replaceSpecialChars, showAlertMsg, showSuccessMsg } from '../../../../../../../../js/util'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { withStyles } from '@material-ui/core'
import styles from '../../../styles'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import StyledTableContainerAdicionar from '../../../../../../../comum/StyledTableContainerAdicionar'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import BuscarComPaginacao from '../../../../../../../comum/BuscarComPaginacao'

class AdicionarComposicaoOrcamento extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired,
    contexto: PropTypes.object,
    classes: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      composicoes: [],
      composicoesCopia: [],
      termoBusca: '',
      composicaoSelecionada: null
    }
    this.handleConfirmarClick = this.handleConfirmarClick.bind(this)
  }

  componentDidMount () {
    if (!_.isNil(this.props.contexto)) {
      this.recuperarComposicoes()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.termoBusca !== this.state.termoBusca) {
      this.buscar()
    }
  }

  async recuperarComposicoes() {
    const { contexto, codigoComposicao } = this.props
    const data = await context_httpget(`precos/atividades-auxiliares/opcoes?composicao=${codigoComposicao}`, contexto)
    if (data) {
      this.setState({
        composicoes: data,
        composicoesCopia: data
      })
    }
  }

  handleChange = (event) => {
    const { value } = event.target
    this.setState({ termoBusca: value })
  }

  handleKeyPress = (event) => {
    const { key } = event

    if (key === 'Enter') {
      this.buscar()
    }
  }

  buscar = () => {
    const { termoBusca, composicoesCopia } = this.state

    if (_.isEmpty(termoBusca)) {
      this.setState({ composicoes: composicoesCopia })
    } else {
      const composicoesFiltrados = composicoesCopia.filter((composicao) => {
        return replaceSpecialChars(composicao.codigo).toUpperCase().includes(replaceSpecialChars(termoBusca).toUpperCase())
          || replaceSpecialChars(composicao.descricao).toUpperCase().includes(replaceSpecialChars(termoBusca).toUpperCase())
          || replaceSpecialChars(composicao.sistemaReferencia).toUpperCase().includes(replaceSpecialChars(termoBusca).toUpperCase())
          || `${composicao.mes}/${composicao.ano}`.includes(termoBusca.toUpperCase())
      })

      this.setState({ composicoes: composicoesFiltrados })
    }
  }

  handleRowComposicaoClick (codigoComposicao) {
    const { composicaoSelecionada } = this.state
    if (composicaoSelecionada !== codigoComposicao) {
      this.setState({ composicaoSelecionada: codigoComposicao })
    }
  }

  async handleConfirmarClick () {
    const { composicaoSelecionada } = this.state
    if (_.isNil(composicaoSelecionada)) {
      showAlertMsg('Por favor, selecione uma composição para vincular como atividade auxiliar.')
      return
    }
    const { codigoComposicao, contexto, onDadosAlterados } = this.props
    const composicaoComDetalhes = await context_httppost(`precos/vincular-atividade-auxiliar?composicao=${codigoComposicao}&atividade-auxiliar=${composicaoSelecionada}`, contexto)
    if (composicaoComDetalhes) {
      showSuccessMsg(`Atividade auxiliar ${composicaoSelecionada} vinculada à composição.`)
      onDadosAlterados()
    }

  }

  render() {
    const { classes, onCancelar } = this.props
    const { composicoes, composicaoSelecionada } = this.state

    return (
      <Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <BuscarComPaginacao
              placeholder='Buscar composição'
              onChange={(e) => this.handleChange(e)}
              onKeyPress={(e) => this.handleKeyPress(e)}
              onClick={() => this.buscar()}
              disable={false}
            />
          </Grid>
        </Grid>
        <StyledTableContainerAdicionar>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell align='left'>Referência</TableCell>
                <TableCell align='left'>Data Referência</TableCell>
                <TableCell>Cód. Serviço</TableCell>
                <TableCell align='left'>Descrição</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                composicoes.map((it) => {
                  const estiloCelula = composicaoSelecionada === it.codigo ? { color: 'white' } : {}
                  return (
                    <TableRow
                      key={it.codigo}
                      className={composicaoSelecionada === it.codigo ? classes.linhaItemSelecionada : classes.linhaItem}
                      onClick={() => this.handleRowComposicaoClick(it.codigo)}>
                      <TableCell style={estiloCelula}>{it.sistemaReferencia}</TableCell>
                      <TableCell style={estiloCelula}>{it.mes}/{it.ano}</TableCell>
                      <TableCell style={estiloCelula}>{it.codigo}</TableCell>
                      <TableCell style={estiloCelula}>{it.descricao}</TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </StyledTableContainerAdicionar>
        <MesmaLinha>
          <Button color='primary' variant='contained' onClick={this.handleConfirmarClick}>Confirmar</Button>
          <Button color='primary' onClick={onCancelar}>Cancelar</Button>
        </MesmaLinha>
      </Fragment>

    )
  }
}

export default connect(mapStateToPropsContexto)(withStyles(styles)(AdicionarComposicaoOrcamento))
