import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { converterMoedaBRParaNumero, mapStateToPropsContexto, seStringVazia, showSuccessMsg } from '../../../../../../../../js/util'
import { context_httppost } from '../../../../../../../../js/httpRequest'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FormularioMaoDeObraComposicao from './FormularioMaoDeObraComposicao'
import styles from '../../../styles'
import { withStyles } from '@material-ui/core'
import { Box } from '@material-ui/core'

class FormularioAdicionarMaoDeObraAvulsa extends Component {
  static propTypes = {
    //codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired,
    contexto: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      codigo: '',
      codigoEmpty: false,
      descricao: '',
      descricaoEmpty: false,
      unidade: '',
      unidadeEmpty: false,
      quantidade: '0,00',
      quantidadeEmpty: false,
      salario: '0,00',
      salarioEmpty: false,
      encargosTotais: '0,00',
      encargosTotaisEmpty: false,
      periculosidadeInsalubridade: '0,00',
      periculosidadeInsalubridadeEmpty: false
    }
    this.handleSalvarClick = this.handleSalvarClick.bind(this)
    this.handleCampoTextoChange = this.handleCampoTextoChange.bind(this)
  }

  handleCampoTextoChange (event, campo) {
    const state = {}
    state[campo] = event.target.value
    state[`${campo}Empty`] = seStringVazia(event.target.value)
    this.setState(state)
  }

  async handleSalvarClick () {
    const {
      codigo,
      descricao,
      unidade,
      quantidade,
      salario,
      encargosTotais,
      periculosidadeInsalubridade
    } = this.state
    const codigoEmpty = seStringVazia(codigo)
    const descricaoEmpty = seStringVazia(descricao)
    const unidadeEmpty = seStringVazia(unidade)
    const quantidadeEmpty = seStringVazia(quantidade)
    const salarioEmpty = seStringVazia(salario)
    const encargosTotaisEmpty = seStringVazia(encargosTotais)
    const periculosidadeInsalubridadeEmpty = seStringVazia(periculosidadeInsalubridade)

    const empties = [
      codigoEmpty,
      descricaoEmpty,
      unidadeEmpty,
      quantidadeEmpty,
      salarioEmpty,
      encargosTotaisEmpty,
      periculosidadeInsalubridadeEmpty
    ]

    if (empties.some(Boolean)) {
      this.setState({
        codigoEmpty,
        descricaoEmpty,
        unidadeEmpty,
        quantidadeEmpty,
        salarioEmpty,
        encargosTotaisEmpty,
        periculosidadeInsalubridadeEmpty
      })
    } else {
      const dadosSalvar = {
        codigo,
        descricao,
        unidade,
        quantidade: converterMoedaBRParaNumero(quantidade),
        salario: converterMoedaBRParaNumero(salario),
        encargosTotais: converterMoedaBRParaNumero(encargosTotais),
        periculosidadeInsalubridade: converterMoedaBRParaNumero(periculosidadeInsalubridade)
      }
      const { contexto } = this.props

      const resultado = await context_httppost('precos/maos-de-obra/criar-avulsa', contexto, dadosSalvar)

      if (resultado) {
        showSuccessMsg('Mão de obra criada com sucesso.')
        
        window.location.reload()
      }
     
    }
  }

  render () {
    const {
      codigo,
      codigoEmpty,
      descricao,
      descricaoEmpty,
      unidade,
      unidadeEmpty,
      quantidade,
      quantidadeEmpty,
      salario,
      salarioEmpty,
      encargosTotais,
      encargosTotaisEmpty,
      periculosidadeInsalubridade,
      periculosidadeInsalubridadeEmpty
    } = this.state

    const { onCancelar } = this.props

    return (
      <div>
        <Box mb={2} ml={2} > 
        <Typography variant='h6' gutterBottom>Cadastrar Nova Mão de Obra</Typography>
        </Box>
        <FormularioMaoDeObraComposicao
          codigo={codigo}
          codigoEmpty={codigoEmpty}
          codigoEditavel={true}
          descricao={descricao}
          descricaoEmpty={descricaoEmpty}
          unidade={unidade}
          unidadeEmpty={unidadeEmpty}
          quantidade={quantidade}
          quantidadeEmpty={quantidadeEmpty}
          salario={salario}
          salarioEmpty={salarioEmpty}
          encargosTotais={encargosTotais}
          encargosTotaisEmpty={encargosTotaisEmpty}
          periculosidadeInsalubridade={periculosidadeInsalubridade}
          periculosidadeInsalubridadeEmpty={periculosidadeInsalubridadeEmpty}
          handleCampoTextoChange={this.handleCampoTextoChange}
          onSalvar={this.handleSalvarClick}
          onCancelar={onCancelar}
        />
      </div>
    )
  }
}


export default connect(mapStateToPropsContexto)(withStyles(styles)(FormularioAdicionarMaoDeObraAvulsa))
