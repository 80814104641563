export const DESCRICAO = {
  cod: 'NUMERO_DESCRICAO',
  titulo: 'Descrição',
  label: 'Descrição',
  orcamento: true,
  referencia: false,
  exibirInicio: true,
  exibirNoCabecalho: false,
  align: 'left'
}

export const UNIDADE = {
  cod: 'UND',
  titulo: 'Und',
  label: 'Unidade',
  orcamento: true,
  referencia: false,
  exibirInicio: true,
  exibirNoCabecalho: true,
  align: 'right'
}

export const STATUS = {
  cod: 'STATUS',
  titulo: 'Status',
  label: 'Status',
  orcamento: true,
  referencia: false,
  exibirInicio: true,
  exibirNoCabecalho: true,
  align: 'left'
}

export const QUANTIDADE = {
  cod: 'QTDE',
  titulo: 'Quantidade',
  label: 'Quantidade',
  orcamento: true,
  referencia: false,
  exibirInicio: true,
  exibirNoCabecalho: true,
  align: 'right'
}

export const PRECO_UNITARIO = {
  cod: 'PRECO_UNITARIO',
  titulo: 'Preço Unitário (com BDI)',
  label: 'Preço Unitário',
  orcamento: true,
  referencia: false,
  exibirInicio: true,
  exibirNoCabecalho: true,
  align: 'right'
}

export const PRECO_PARCIAL = {
  cod: 'PRECO_PARCIAL',
  titulo: 'Preço Parcial',
  label: 'Preço Parcial',
  orcamento: true,
  referencia: false,
  exibirInicio: true,
  exibirNoCabecalho: true,
  align: 'right'
}

export const PERCENTUAL_ACUMULADO = {
  cod: 'PERCENTUAL_ACUMULADO',
  titulo: '% acum',
  label: '% acumumulado',
  orcamento: true,
  referencia: false,
  exibirInicio: true,
  exibirNoCabecalho: true,
  align: 'right'
}

export const PERCENTUAL = {
  cod: 'PERCENTUAL',
  titulo: '%',
  label: 'Percentual',
  orcamento: true,
  referencia: false,
  exibirInicio: true,
  exibirNoCabecalho: true,
  align: 'right'
}

export const COD_SERVICO = {
  cod: 'COD_SERVICO',
  titulo: 'Cod. Serviço',
  label: 'Cod. Serviço',
  maxWidth: 180,
  orcamento: false,
  referencia: false,
  exibirInicio: true,
  exibirNoCabecalho: true,
  align: 'left'
}

export const BDI = {
  cod: 'BDI',
  titulo: 'BDI',
  label: 'BDI',
  maxWidth: 150,
  orcamento: false,
  referencia: true,
  exibirInicio: true,
  exibirNoCabecalho: true,
  align: 'left'
}

export const INCLUIR_NA_AMOSTRA = {
  cod: 'INCLUIR_NA_AMOSTRA',
  titulo: 'Amostra',
  label: 'Incluir na amostra',
  orcamento: false,
  referencia: false,
  exibirInicio: true,
  exibirNoCabecalho: true,
  align: 'center'
}

export const SOBREPRECO = {
  cod: 'SOBREPRECO',
  titulo: 'Sobrepreço',
  label: 'Sobrepreço',
  orcamento: false,
  referencia: true,
  exibirInicio: true,
  exibirNoCabecalho: true,
  align: 'right'
}

export const DESCRICAO_REF = {
  cod: 'DESCRICAO_REF',
  titulo: 'Descrição',
  label: 'Ref. Descrição',
  orcamento: false,
  referencia: true,
  exibirInicio: true,
  exibirNoCabecalho: false,
  align: 'left'
}

export const PRECO_UNIT_REF = {
  cod: 'PRECO_UNIT_REF',
  titulo: 'Preço com BDI',
  label: 'Ref. Preço com BDI',
  orcamento: false,
  referencia: true,
  exibirInicio: true,
  exibirNoCabecalho: true,
  align: 'right'
}

export const PRECO_PARCIAL_REF = {
  cod: 'PRECO_PARCIAL_REF',
  titulo: 'Preço Parcial',
  label: 'Ref. Preço Parcial',
  orcamento: false,
  referencia: true,
  exibirInicio: true,
  exibirNoCabecalho: true,
  align: 'right'
}

export const OBSERVACAO = {
  cod: 'OBSERVACAO',
  titulo: 'Obs.',
  label: 'Observação',
  minWidth: 50,
  orcamento: false,
  referencia: false,
  exibirInicio: true,
  exibirNoCabecalho: true,
  align: 'center'
}

export const UF = {
  cod: 'UF',
  titulo: 'UF',
  label: 'UF',
  minWidth: 50,
  orcamento: false,
  referencia: true,
  exibirInicio: false,
  exibirNoCabecalho: true,
  align: 'right'
}

export const DATA = {
  cod: 'DATA',
  titulo: 'Data',
  label: 'Data',
  minWidth: 50,
  orcamento: false,
  referencia: true,
  exibirInicio: false,
  exibirNoCabecalho: true,
  align: 'right'
}

export const colunas = [
  COD_SERVICO,
  STATUS,
  INCLUIR_NA_AMOSTRA,
  DESCRICAO,
  UNIDADE,
  QUANTIDADE,
  PRECO_UNITARIO,
  PRECO_PARCIAL,
  PERCENTUAL_ACUMULADO,
  PERCENTUAL,
  BDI,
  UF,
  DATA,
  PRECO_UNIT_REF,
  PRECO_PARCIAL_REF,
  SOBREPRECO,
  DESCRICAO_REF,
  OBSERVACAO
]
