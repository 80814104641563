const styles = (theme) => ({
  secaoCurvaABC: {
    display: 'flex',
    flexFlow: 'column',
    height: ' calc(100vh - 160px)'
  },
  cabecalhoGeral: {
    maxHeight: 10
  },
  paperABC: {
    flexGrow: 1
  },
  boxLimite: {
    height: 'calc(100vh - 315px)'
  },
  boxLimiteCabecalhoRecolhido: {
    height: 'calc(100vh - 205px)'
  },
  erroSublinhado: {
    // borderBottom: '2px solid #FFD080'
  },
  menuColunas: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    padding: 4,
  },
  menuFiltros: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    padding: 4,
  },
  textoUnidadeABC: {
    textAlign: 'right',
    margin: 0,
    width: '100%'
  },
  btnItensABC: {
    margin: '0 5px'
  },
  menuCurvaABC: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      width: 100,
      height: 44,
      '&:hover': {
        backgroundColor: 'rgba(250, 250, 250, 1)'
      }
    }
  },
  celulaCodigo: {
    borderLeft: 'solid 1px #e5e5e6 !important',
  },
  celulaCodigoComAnaliseConcluida: {
    borderLeft: 'solid 4px #4caf50 !important',
  },
  paper: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1)
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto'
  },
  formGroup: {

  },
  textoExcluirCurvaABC: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex'
  },
  mesmaLinha: {
    alignItems: 'flex-end !important'
  },
  labelCheckBox: {
    '& span': {
      fontSize: '0.875rem'
    }
  },
  totalItensSelecionados: {
    fontSize: 16
  },
  tituloItem: {
    fontSize: 16,
    fontWeight: 500
  },
  subtituloItem: {
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.6)'
  },
  botaoFiltro: {
    height: 44,
    fontSize: 14,
    fontWeight: 400
  },
  txtBtn: {
    fontSize: 14,
    display: 'inline-flex',
    alignItems: 'center',
    padding: '2px 2px 2px 8px'
  },
  itemMenuOpcoes: {
    padding: 10,
    margin: 8
  },
  cabecalhoTabela: {
    backgroundColor: '#fff'
  },
  tituloOrcamento: {
    backgroundColor: theme.palette.primary.main,
    borderTopLeftRadius: 4,
    color: '#fff',
    fontWeight: 500,
    padding: '0 8px',
    lineHeight: '1rem',
    fontSize: '0.8rem',
    top: 0
  },
  tituloRefPreco: {
    backgroundColor: '#f4f5f8',
    borderTopRightRadius: 4,
    color: '#4a4a4a',
    padding: '0 8px',
    fontWeight: 500,
    fontSize: '0.8rem',
    top: 0
  },
  celulaObservacao: {
    paddingBottom: 0,
    paddingTop: 0,
    borderLeft: '4px solid #e5e5e6 !important'
  },
  divObservacao: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  tabelaCaixa: {
    height: '100%',
    overflow: 'scroll',
    '& td': {
      border: 'none'
    }
  },
  // Hack para exibir toda tabela na rolagem
  tabelaABC: {
    '& tr:last-child > td': {
      borderBottom: '1px solid #e5e5e6 !important'
    }
  },
  etiqueta: {
    top: 0,
    borderBottom: 'none'
  },
  resetarBorderCelula: {
    border: 'none',
    top: 24
  },
  linhaPrincipal: {
    '& td': {
      borderTop: '1px solid #e5e5e6 !important'
    }
  },
  linhaPrincipalPar: {
    '& td': {
      borderTop: '1px solid #e5e5e6 !important',
      backgroundColor: '#f9fafb'
    }
  },
  linhaPar: {
    '& td': {
      backgroundColor: '#f9fafb'
    }
  },
  divCabecalhoItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 5
  },
  navCabecalhoItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  boxTopo: {
    position:'relative',
    padding: 12
  },
  tituloBoxItem: {
    fontWeight: 500,
    fontSize: 14
  },
  textoItemBdi: {
    marginLeft: '10%',
    marginRight: '20%'
  },
  textoItemLink: {
    textAlign: 'left',
    color: theme.palette.primary.main,
    fontSize: 16,
    padding: 5,
    '&:hover': {
      background: 'rgba(67, 133, 191, 0.1)',
      marginRight: '10%'
    }
  },
  textoItemComum: {
    padding: '6px 0'
  },
  divCabecalhoItem3: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 4fr',
    marginBottom: 5
  },
  itemBdi: {
    color: 'rgba(74,74,74,1)',
    borderRadius: 2,
    width: '40%',
    '&:hover': {
      border: 'solid 1px #ccc',
      paddingLeft: 2
    }
  },
  btnCodigo: {
    padding: 0,
    alignSelf: 'flex-start',
    display: 'flex',
    alignItems: 'center'
  },
  divCabecalhoItem6: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    marginBottom: 5
  },
  tituloBoxItem2: {
    fontWeight: 500,
    fontSize: 14
  },
  tituloBoxItem3: {
    fontWeight: 500,
    width: '100%',
    fontSize: 14
  },
  itensCabecalho: {
    width: '15%'
  },
  btnAcoesItem: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  dadosItem: {
    display: 'inline-block',
    width: '78%'
  },
  btnAmostra: {
    marginLeft: 0,
  },
  checkStyle: {
    paddingLeft: 0
  },
  colorBtn: {
    color: theme.palette.primary.main
  },
  textoCampoEditavel: {
    borderRadius: 2,
    textTransform: 'uppercase',
    cursor: 'pointer',
    '&:hover': {
      border: 'solid 1px #ccc',
      paddingLeft: 2
    }
  },
  textoCampoEditavelObservacao: {
    borderRadius: 2,
    width: '100%',
    cursor: 'pointer',
    '&:hover': {
      border: 'solid 1px #ccc',
      paddingLeft: 2
    }
  },
  itemAnalise: {
    backgroundColor: '#FFC700',
    borderRadius: '3px 0 0 3px',
    color: '#fff !important',
    width: '50%'
  },
  itemConcluido: {
    backgroundColor: '#73DEAA',
    borderRadius: '3px 0 0 3px',
    color: '#fff !important',
    width: '50%'
  },
  itemSituacao: {
    width: '50%',
    color: '#4A4A4A !important'
  },
  textoCodigoServico: {
    textAlign: 'left',
    color: theme.palette.primary.main,
    fontSize: 16,
    display: 'inline-flex',
    alignItems: 'center',
    padding: 5
  },
  celulaObservacaoVazia: {
    padding: 0,
    height: 0
  }
})

export default styles
