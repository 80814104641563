import React from 'react'
import { Box, Typography } from '@material-ui/core'
import {
  criterioComparacao,
  TIPO_COMPARACAO_ENTRE,
  TIPO_COMPARACAO_IGUAL_A,
  TIPO_MEDIDA_DISPERSAO_DESVIO_PADRAO,
  tipoComparacao,
  tipoMedidaDispersao,
  variavelAnalise
} from '../../../analise-conteudo'
import PropTypes from 'prop-types'
import { getTipoCombinacaoTipologia, METODO_ANALISE_EXPRESSAO } from '../../../../../../../js/util'

const RegraDescritivaTipologia = ({ classes, tipoTipologia, tipologia }) => {
  const isTipologiaExpressao = getTipoCombinacaoTipologia(tipoTipologia) === METODO_ANALISE_EXPRESSAO
  return (
    <Box display='flex' alignItems='center'>
      {isTipologiaExpressao
        ? <Typography variant={'overline'} color='textSecondary' className={classes.regrasTipologiaCombinadas}>{variavelAnalise(tipologia).label}</Typography>
        : <Typography variant={'overline'} color='textSecondary' className={classes.regrasTipologiaCombinadas}> O resultado da fórmula </Typography>
      }

      <Typography variant={'overline'} color='textSecondary' className={classes.regrasTipologiaCombinadas}>
        {criterioComparacao(tipologia).label}
      </Typography>

      <Typography variant={'overline'} color='textSecondary' className={classes.regrasTipologiaCombinadas}>
        {tipoComparacao(tipologia).label}
      </Typography>

      <Typography variant={'overline'} color='textSecondary' className={classes.regrasTipologiaCombinadas}>
        {tipologia.valorComparacao}
      </Typography>

      {tipologia.tipoComparacao === TIPO_COMPARACAO_ENTRE.value &&
        <React.Fragment>
          <Typography variant={'overline'} color='textSecondary' className={classes.regrasTipologiaCombinadas}> e </Typography>
          <Typography variant={'overline'} color='textSecondary' className={classes.regrasTipologiaCombinadas}>
            {tipologia.valorComparacaoEntre}
          </Typography>
        </React.Fragment>
      }

      {tipologia.tipoComparacao === TIPO_COMPARACAO_IGUAL_A.value &&
        <Typography variant={'overline'} color='textSecondary' className={classes.regrasTipologiaCombinadas}>
          {tipoMedidaDispersao(tipologia).label}
        </Typography>
      }

      {tipologia.tipoMedidaDispersao === TIPO_MEDIDA_DISPERSAO_DESVIO_PADRAO.value &&
        <Typography variant={'overline'} color='textSecondary' className={classes.regrasTipologiaCombinadas}>
          {tipologia.valorMedidaDispersao}
        </Typography>
      }
    </Box>
  )
}

RegraDescritivaTipologia.propTypes = {
  classes: PropTypes.object,
  tipoTipologia: PropTypes.string,
  tipologia: PropTypes.object
}

export default RegraDescritivaTipologia
