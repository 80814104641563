import { pageHeaderAndFooter } from '../../precos/composicao/pdf/RelatorioComposicaoPdf'
import { formatarNumeroParaMoedaBR } from '../../../../../../js/util'
import {
  criterioComparacao,
  operador,
  TIPO_COMPARACAO_ENTRE,
  TIPO_COMPARACAO_IGUAL_A,
  TIPO_MEDIDA_DISPERSAO_DESVIO_PADRAO,
  TIPO_PARAMETRO_EXPRESSAO,
  TIPO_PARAMETRO_FORMULA,
  TIPO_PARAMETRO_VALOR_NUMERICO,
  TIPO_PARAMETRO_VARIAVEL_ORCAMENTO,
  tipoComparacao,
  tipoMedidaDispersao,
  variavelExpressao,
  variavelOrcamento
} from '../../../analise-conteudo/analise-conteudo'
import { iconCheck, iconInfo } from '../relatorio-analise-conteudo'
import _ from 'lodash'

const valorTotal = (formula) => `Valor total: ${formatarNumeroParaMoedaBR(formula.valor, 2, 3)}`
const expressao = (formula) => `Expressão: ${formula.formulaGerada}`

// Regra da tipologia
const criterio = (tipologia) => `${criterioComparacao(tipologia).label}`
const tipo = (tipologia) => `${tipoComparacao(tipologia).label}`
const valor = (tipologia) => `${tipologia.valorComparacao}`
const entre = (tipologia) => tipologia.tipoComparacao === TIPO_COMPARACAO_ENTRE.value ? `e ${tipologia.valorComparacaoEntre}` : ''
const igualA = (tipologia) => tipologia.tipoComparacao === TIPO_COMPARACAO_IGUAL_A.value ? `${tipoMedidaDispersao(tipologia).label}` : ''
const medidaDispersao = (tipologia) => tipologia.tipoMedidaDispersao === TIPO_MEDIDA_DISPERSAO_DESVIO_PADRAO.value ? `${tipologia.valorMedidaDispersao}` : ''
const regraTipologiaFormula = (tipologia) => `    ${tipologia.nome}: O resultado da fórmula ${criterio(tipologia)} ${tipo(tipologia)} ${valor(tipologia)} ${entre(tipologia)} ${igualA(tipologia)} ${medidaDispersao(tipologia)}`

const parametros = (parametros) => {
  let parametrosString = '= '
  parametros.forEach((it, index) => {
    const operadorValue = index !== 0 ? operador(it.operador).operator : ''
    switch (it.tipo) {
      case TIPO_PARAMETRO_VALOR_NUMERICO.value:
        parametrosString = parametrosString.concat(`${operadorValue} Valor numérico: ${formatarNumeroParaMoedaBR(it.valorNumerico, 2, 3)}\n\t`)
        break
      case TIPO_PARAMETRO_VARIAVEL_ORCAMENTO.value:
        parametrosString = parametrosString.concat(`${operadorValue} Variável de orçamento: ${variavelOrcamento(it.variavelOrcamento).label}\n\t`)
        break
      case TIPO_PARAMETRO_EXPRESSAO.value:
        parametrosString = parametrosString.concat(`${operadorValue} Expressão: ${it.nomeExpressaoParametro} Variável: ${variavelExpressao(it.variavelExpressao).label}\n\t`)
        break
      case TIPO_PARAMETRO_FORMULA.value:
        parametrosString = parametrosString.concat(`${operadorValue} Fórmula: ${it.nomeFormulaParametro}\n\t`)
        break
    }
  })
  parametrosString = parametrosString.substring(1, parametrosString.length)
  parametrosString = `=${parametrosString}`
  parametrosString = parametrosString.substring(0, parametrosString.length - 2)
  return parametrosString
}

let count = 5
const formulasMap = new Map()

export function tabelaFormulas (doc, formulas, { posicaoX, posicaoY }) {
  const tableHeadFormulas = [[{ content: 'FÓRMULAS' }]]
  const tableBodyFormulas = []

  formulas.forEach((formula) => {
    const linha = [{ content: formula.nome, styles: { fontStyle: 'bold', overflow: 'linebreak' } }]
    const linha2 = [{ content: formula.descricao, styles: { overflow: 'linebreak' } }]
    const linha3 = [{ content: '\tParâmetros', styles: { fontStyle: 'italic' } }]
    const linha4 = [{ content: `\t${parametros(formula.parametros)}`, styles: { overflow: 'linebreak' } }]
    const linha5 = [{ content: `${valorTotal(formula)} | ${expressao(formula)}`, styles: { overflow: 'linebreak' } }]
    const linha6 = [{ content: !_.isNil(formula.tipologia) ? regraTipologiaFormula(formula.tipologia) : '', styles: { overflow: 'linebreak' } }]
    if (!_.isNil(formula.tipologia)) {
      formulasMap.set(formula.tipologia.nome, formula.tipologiaPossuiInconsistencia)
    }
    tableBodyFormulas.push(linha, linha2, linha3, linha4, linha5, linha6)
  })

  if (_.isEmpty(formulas)) {
    tableBodyFormulas.push([{ content: 'Nenhuma fórmula encontrada' }])
  }

  posicaoY += 20
  doc.autoTable(
    {
      body: tableBodyFormulas,
      head: tableHeadFormulas,
      startX: posicaoX,
      startY: posicaoY,
      tableWidth: 'auto',
      theme: 'plain',
      margin: {
        top: 20,
        right: 30,
        left: 30
      },
      bodyStyles: {
        fillColor: [245, 245, 245],
        textColor: [70, 70, 70],
        cellPadding: {
          top: 3,
          left: 5,
          right: 5,
          bottom: 3
        },
        tableLineColor: [210, 210, 210],
        tableLineWidth: 1
      },
      headStyles: {
        fillColor: [210, 210, 210],
        textColor: [70, 70, 70],
        0: {
          valign: 'middle',
          halign: 'left'
        }
      },
      didDrawPage: (data) => pageHeaderAndFooter(doc, data, 'Relatório de Análise Conteúdo'),
      didDrawCell: function (data) {
        if (data.column.index === 0 && data.cell.section === 'body' && data.row.index === count) {
          if (data.cell.raw.content.includes('% - Taxa de aço')) {
            debugger
            console.log('')
          }
          const possuiInconsistencia = formulasMap.get(data.cell.raw.content.split(':')[0].trim())
          if (!_.isNil(possuiInconsistencia)) {
            const textPos = data.cell
            doc.addImage(possuiInconsistencia ? iconInfo : iconCheck, textPos.x, textPos.y, 11, 11)
            count += 6
          }
        }
      },
      willDrawCell: function (data) {
        if (data.column.index === 0 && data.cell.section === 'head') {
          data.cell.styles.halign = 'left'
        }
      }
    })
  return { posicaoX, posicaoY: doc.lastAutoTable.finalY }
}
