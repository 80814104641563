import React from 'react'
import { Checkbox, TableRow, withStyles } from '@material-ui/core'
import TableHead from '@material-ui/core/TableHead'
import TitleTableCell from '../../../../comum/TitleTableCell'
import PropTypes from 'prop-types'
import { colunas, OBSERVACAO } from './colunas/colunas'
import { connect } from 'react-redux'
import styles from './styles'
import MesmaLinha from '../../../../comum/MesmaLinha'
import { existeObjetoNaLista } from '../../../../../js/util'

export const CabecalhoTabelaCurvaABC = ({ colunasExibir, mostrarSelecaoLinhas, selecionarTodosItens, todosItensSelecionados, classes }) => {
  const qtdeColunasOrcamento = colunasExibir.filter((col) => (col.orcamento && col.exibirNoCabecalho)).length
  const qtdeNemOrcamentoNemReferencia = 1 + colunasExibir.filter(col => col !== OBSERVACAO && !col.orcamento && !col.referencia).length
  const qtdeReferencia = colunasExibir.filter((col) => (col.referencia && col.exibirNoCabecalho)).length
  return (
    <TableHead>
      <TableRow>
        <TitleTableCell className={classes.etiqueta} colSpan={qtdeNemOrcamentoNemReferencia} align='center' />

        {
          qtdeColunasOrcamento > 0 && (
            <TitleTableCell className={classes.tituloOrcamento} colSpan={qtdeColunasOrcamento}>Orçamento</TitleTableCell>
          )
        }

        {
          qtdeReferencia > 0 && (
            <TitleTableCell className={classes.tituloRefPreco} colSpan={qtdeReferencia}>Referência de Preço</TitleTableCell>
          )
        }

        {
          colunasExibir.includes(OBSERVACAO) && (
            <TitleTableCell className={classes.etiqueta} colSpan={1} align='center' />
          )
        }
      </TableRow>

      <TableRow>
        <TitleTableCell className={classes.resetarBorderCelula}>
          <MesmaLinha>
            {
              mostrarSelecaoLinhas
              && <Checkbox color={'primary'} checked={todosItensSelecionados} onChange={(e) => selecionarTodosItens(e)} />
            }
            Nº
          </MesmaLinha>
        </TitleTableCell>
        {
          colunas.map((coluna) => existeObjetoNaLista(colunasExibir, coluna) && coluna.exibirNoCabecalho && (
            <TitleTableCell align={coluna.align}
              style={{ minWidth: coluna.minWidth, maxWidth: coluna.maxWidth ? coluna.maxWidth : coluna.minWidth }}
              className={classes.resetarBorderCelula}
              key={coluna.cod}>
              {coluna.titulo}
            </TitleTableCell>
          ))
        }
      </TableRow>
    </TableHead>
  )
}

CabecalhoTabelaCurvaABC.propTypes = {
  colunasExibir: PropTypes.array,
  mostrarSelecaoLinhas: PropTypes.bool,
  selecionarTodosItens: PropTypes.func,
  todosItensSelecionados: PropTypes.bool,
  classes: PropTypes.object
}

const mapStateToProps = ({ curvaAbc }) => ({
  colunasExibir: curvaAbc.colunasExibir
})

export default connect(mapStateToProps)(withStyles(styles)(CabecalhoTabelaCurvaABC))
