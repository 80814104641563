import React, { useEffect, useState } from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { intersection, not, ordenar } from '../analise-conteudo/analise-conteudo'
import styles from './styles'
import buscarTiposObra from '../../../../actions/obras/buscarTiposObras'

const CampoTipoObra = ({
  classes,
  tipoObrasEdicao,
  buscarTiposObra,
  onTipoObrasSelecionados
}) => {
  const [checked, setChecked] = useState([])
  const [tiposSelecionados, setTiposSelecionados] = useState([])
  const [tiposNaoSelecionados, setTiposNaoSelecionados] = useState([])
  const [atualizarComponentePrincipal, setAtualizarComponentePrincipal] = useState(false)

  useEffect(() => {
    const buscarTipoObras = () => {
      buscarTiposObra().then((result) => {
        setTiposNaoSelecionados(result.payload.data)
      })
    }
    buscarTipoObras()
  }, [])

  useEffect(() => {
    setTiposSelecionados([...tipoObrasEdicao])
    setTiposNaoSelecionados(not(tiposNaoSelecionados, tipoObrasEdicao))
  }, [tipoObrasEdicao])

  useEffect(() => {
    atualizarComponentePrincipal && onTipoObrasSelecionados(tiposSelecionados)
  }, [atualizarComponentePrincipal])

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const handleMoverTodosItensParaDireita = (tiposSelecionados, tiposNaoSelecionados) => {
    setTiposSelecionados(ordenar(tiposSelecionados.concat(tiposNaoSelecionados)))
    setTiposNaoSelecionados([])
    setAtualizarComponentePrincipal(true)
  }

  const handleMoverItensSelecionadosParaDireita = (leftChecked, tiposSelecionados, tiposNaoSelecionados, checked) => {
    setTiposSelecionados(ordenar(tiposSelecionados.concat(leftChecked)))
    setTiposNaoSelecionados(not(tiposNaoSelecionados, leftChecked))
    setChecked(not(checked, leftChecked))
    setAtualizarComponentePrincipal(true)
  }

  const handleMoverItensSelecionadosParaEsquerda = (rightChecked, tiposSelecionados, tiposNaoSelecionados, checked) => {
    setTiposNaoSelecionados(ordenar(tiposNaoSelecionados.concat(rightChecked)))
    setTiposSelecionados(not(tiposSelecionados, rightChecked))
    setChecked(not(checked, rightChecked))
    setAtualizarComponentePrincipal(true)
  }

  const handleMoverTodosItensParaEsquerda = (tiposSelecionados, tiposNaoSelecionados) => {
    setTiposNaoSelecionados(ordenar(tiposNaoSelecionados.concat(tiposSelecionados)))
    setTiposSelecionados([])
    setAtualizarComponentePrincipal(true)
  }

  const customList = (items) => {
    return (
      <Paper elevation={0} variant='outlined' className={classes.paperListTransfer}>
        <List dense component='div' role='list'>
          {items.map((value) => {
            const labelId = `transfer-list-item-${value.id}-label`
            return (
              <ListItem key={value.id} role='listitem' button onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    color={'primary'}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value.descricao} />
              </ListItem>
            )
          })}
          <ListItem />
        </List>
      </Paper>
    )
  }

  const leftChecked = intersection(checked, tiposNaoSelecionados)
  const rightChecked = intersection(checked, tiposSelecionados)
  return (
    <React.Fragment>
      <div className={classes.tituloListTransfer}>
        <Typography variant='subtitle1' className={classes.labelTipoObra}>Atribuir tipo de obra</Typography>
      </div>
      <Grid container spacing={0} alignItems='center' className={classes.rootListTransfer}>
        <Grid item>{customList(tiposNaoSelecionados)}</Grid>
        <Grid item>
          <Grid container direction='column' alignItems='center'>
            <Box display='flex' flexDirection='column' mx={2}>
              <Button
                variant='outlined'
                size='small'
                className={classes.buttonListTransfer}
                onClick={() => handleMoverTodosItensParaDireita(tiposSelecionados, tiposNaoSelecionados)}
                disabled={tiposNaoSelecionados.length === 0}
                aria-label='move all right'
              >
                ≫
              </Button>
              <Button
                variant='outlined'
                size='small'
                className={classes.buttonListTransfer}
                onClick={() => handleMoverItensSelecionadosParaDireita(leftChecked, tiposSelecionados, tiposNaoSelecionados, checked)}
                disabled={leftChecked.length === 0}
                aria-label='move selected right'
              >
                &gt;
              </Button>
              <Button
                variant='outlined'
                size='small'
                className={classes.buttonListTransfer}
                onClick={() => handleMoverItensSelecionadosParaEsquerda(rightChecked, tiposSelecionados, tiposNaoSelecionados, checked)}
                disabled={rightChecked.length === 0}
                aria-label='move selected left'
              >
                &lt;
              </Button>
              <Button
                variant='outlined'
                size='small'
                className={classes.buttonListTransfer}
                onClick={() => handleMoverTodosItensParaEsquerda(tiposSelecionados, tiposNaoSelecionados)}
                disabled={tiposSelecionados.length === 0}
                aria-label='move all left'
              >
                ≪
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid item>{customList(tiposSelecionados)}</Grid>
      </Grid>
    </React.Fragment>
  )
}

CampoTipoObra.propTypes = {
  classes: PropTypes.object,
  buscarTiposObra: PropTypes.func.isRequired,
  tipoObrasEdicao: PropTypes.array.isRequired,
  onTipoObrasSelecionados: PropTypes.func.isRequired
}

export default connect(null, { buscarTiposObra })(withStyles(styles)(CampoTipoObra))
