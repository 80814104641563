import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import _ from 'lodash'
import Paginacao from '../../../comum/Paginacao'
import { paginar, showErrorMsg, url } from '../../../../js/util'
import * as ColunasTabelaInconsistencia from './ColunasTabelaInconsistencia'
import PropTypes from 'prop-types'
import { Box, Chip, Link } from '@material-ui/core'
import { connect } from 'react-redux'
import filtrarAnaliseOrcamento from '../../../../actions/orcamentos/analise/filtrarAnaliseOrcamento'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../../../js/operationProgressUtil'

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    boxShadow: 'none'
  },
  table: {
    minWidth: 890
  },
  tableWrapper: {
    overflowX: 'auto'
  }
})

const defaultState = (props) => ({
  pagina: 0,
  registrosPorPagina: 10,
  colunaOrdenacao: '',
  direcaoOrdenacao: 'asc',
  colunas: props.colunas,
  itens: props.itens,
  referenciasPrecoOrcamento: []
})

export class TabelaInconsistencia extends Component {
  static propTypes = {
    classes: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    filtrarAnaliseOrcamento: PropTypes.func,
    recuperarReferenciasPreco: PropTypes.func,
    contexto: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = defaultState(props)
    this.handleChangePage = this.handleChangePage.bind(this)
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
    this.handleDirecaoOrdenacao = this.handleDirecaoOrdenacao.bind(this)

  }

  async UNSAFE_componentWillMount () {
    let referenciasCarregadas = []
    const { contexto = {} } = this.props
    const idEmpreendimento = contexto?.empreendimento?.cod
    const idObra = contexto?.obra?.cod
    const idOrcamento = contexto?.orcamento?.cod
    if (idOrcamento) {
      await axios.get(`${url({ idEmpreendimento, idObra, idOrcamento })}/referencia-preco`, SLOW_REQUEST_CONFIG)
        .then((resp) => {
          const { data } = resp
          if (data) {
            referenciasCarregadas = data
          }
        })
        .catch((e) => {
          showErrorMsg(`Erro inesperado: ${e.message}`)
        })

      this.setState({referenciasPrecoOrcamento: referenciasCarregadas})
    }
  }

  handleChangePage (event, pagina) {
    this.setState({ pagina })
  }

  handleChangeRowsPerPage (event) {
    this.setState({
      pagina: 0,
      registrosPorPagina: parseInt(event.target.value, 10)
    })
  }

  handleDirecaoOrdenacao (event, colunaOrdenacao) {
    let { direcaoOrdenacao } = this.state

    if (_.isEmpty(direcaoOrdenacao) || this.state.colunaOrdenacao !== colunaOrdenacao) {
      direcaoOrdenacao = 'desc'
    } else {
      direcaoOrdenacao = direcaoOrdenacao === 'desc' ? 'asc' : 'desc'
    }

    this.setState({
      direcaoOrdenacao,
      colunaOrdenacao
    }, () => this.ordernarItens(colunaOrdenacao, direcaoOrdenacao))
  }

  handleFiltroAnaliseOrcamento (descricao) {
    const { match, history, filtrarAnaliseOrcamento } = this.props
    filtrarAnaliseOrcamento(descricao)
    if(match.url.indexOf('/possiveis-inconsistencias') >= 0) {
      history.push(match.url.replace('possiveis-inconsistencias', 'analisar'))
    } else if(match.url.indexOf('/expressoes') >= 0) {
      history.push(match.url.replace('analise-conteudo/expressoes', 'analisar'))
    }
  }

  ordernarItens (colunaOrdenacao, direcaoOrdenacao) {
    const { itens } = this.state
    let attributeItemOrdena

    switch (colunaOrdenacao) {
      case ColunasTabelaInconsistencia.QUANTIDADE_ITENS:
        attributeItemOrdena = 'quantidadeItens'
        break
      case ColunasTabelaInconsistencia.CODIGO_SERVICO:
        if (_.isNil(itens[0].itemCurvaAbc)) {
          attributeItemOrdena = 'codServico'
        } else {
          attributeItemOrdena = 'itemCurvaAbc.codigoServico'
        }
        break
      case ColunasTabelaInconsistencia.DESCRICAO_SERVICO:
        if (_.isNil(itens[0].itemCurvaAbc)) {
          attributeItemOrdena = 'descServico'
        } else {
          attributeItemOrdena = 'itemCurvaAbc.descricaoOrcamento'
        }
        break
      case ColunasTabelaInconsistencia.UNIDADE:
        if (_.isNil(itens[0].itemCurvaAbc)) {
          attributeItemOrdena = 'unidade'
        } else {
          attributeItemOrdena = 'itemCurvaAbc.unidadeOrcamento'
        }
        break
      case ColunasTabelaInconsistencia.QUANTIDADE:
        if (_.isNil(itens[0].itemCurvaAbc)) {
          attributeItemOrdena = 'valorQuantidade'
        } else {
          attributeItemOrdena = 'itemCurvaAbc.quantidade'
        }
        break
      case ColunasTabelaInconsistencia.PRECO_UNITARIO:
        attributeItemOrdena = 'valorPrecoUnitario'
        break
      case ColunasTabelaInconsistencia.VALOR_PARCIAL:
        attributeItemOrdena = 'valorPrecoParcial'
        break
      case ColunasTabelaInconsistencia.INCIDENCIA:
        attributeItemOrdena = 'incidenciaPrecoParcial'
        break
      case ColunasTabelaInconsistencia.ERRO_DETECTADO:
        attributeItemOrdena = 'erroDetectado'
        break
      case ColunasTabelaInconsistencia.MENOR_PRECO_ORCADO:
        attributeItemOrdena = 'valorMenorPrecoOrcado'
        break
      case ColunasTabelaInconsistencia.DIFERENCA:
        attributeItemOrdena = 'diferencaoMenorPrecoOrcado'
        break
      case ColunasTabelaInconsistencia.PRECO_UNITARIO_COM_BDI:
        attributeItemOrdena = 'itemCurvaAbc.precoUnitarioOrcamento'
        break
      case ColunasTabelaInconsistencia.PRECO_PARCIAL:
        attributeItemOrdena = 'itemCurvaAbc.precoParcialCalculado'
        break
      case ColunasTabelaInconsistencia.PRECO_REFERENCIA_COM_BDI:
        attributeItemOrdena = 'itemCurvaAbc.valorReferenciaComBdi'
        break
      case ColunasTabelaInconsistencia.PRECO_PARCIAL_REFERENCIA:
        attributeItemOrdena = 'itemCurvaAbc.precoParcialReferencia'
        break
      case ColunasTabelaInconsistencia.SUBPRECO:
        attributeItemOrdena = 'itemCurvaAbc.valorSobrepreco'
        break
      default:
        attributeItemOrdena = ''
    }

    if (!_.isEmpty(attributeItemOrdena)) {
      this.setState({
        itens: _.orderBy(itens, [attributeItemOrdena], [direcaoOrdenacao])
      })
    }
  }

  render () {
    const { classes } = this.props
    const { pagina, registrosPorPagina, colunas, itens } = this.state

    return (
      <Paper className={ classes.root }>
        <div className={ classes.tableWrapper }>
          { colunas.includes(ColunasTabelaInconsistencia.MES) && colunas.includes(ColunasTabelaInconsistencia.ANO) &&
            <Box display='flex'>
              <Typography variant='overline' style={{ marginRight: 5 }}>Referência(s) de preço do orçamento:</Typography>
              <Typography variant='body2'>
                { this.state.referenciasPrecoOrcamento.map((it,index) =>
                  <Chip
                    key={index}
                    label={`${it.sigla}-${it.mes}/${it.ano}-${it.uf}`}
                    variant='outlined'
                    color='primary'
                    size='small'
                  />
                )}
              </Typography>
            </Box>
          }
          <Table className={ classes.table } aria-label='custom pagination table'>
            <TableHead>
              <TableRow>
                { colunas.map((coluna) => (
                  <TableCell
                    key={ coluna }
                    align={ coluna === ColunasTabelaInconsistencia.DESCRICAO_SERVICO ? 'inherit' : 'left' }>
                    <TableSortLabel
                      active={ this.state.colunaOrdenacao === coluna }
                      direction={ this.state.direcaoOrdenacao }
                      onClick={(e) => this.handleDirecaoOrdenacao(e, coluna) }>
                      { coluna }
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {
                paginar(itens, pagina, registrosPorPagina).map((item, index) => (
                  <TableRow key={ index }>
                    { colunas.includes(ColunasTabelaInconsistencia.QUANTIDADE_ITENS)
                    && <TableCell>{ item.quantidadeItens }</TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.CODIGO_SERVICO)
                      && !item.descServicoReferenciaPreco && <TableCell>{ _.isNil(item.itemCurvaAbc) ? item.codServico : item.itemCurvaAbc.codigoServico }</TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.CODIGO_INSUMO)
                      && <TableCell>{ item.codInsumo }</TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.CODIGO_SERVICO)
                      && item.descServicoReferenciaPreco && <TableCell style={{color: 'blue'}} title={item.descServicoReferenciaPreco}>{ _.isNil(item.itemCurvaAbc) ? item.codServico: item.itemCurvaAbc.codigoServico }</TableCell>
                    }
                    <TableCell align='inherit' component='th' scope='row'>
                      { _.isNil(item.itemCurvaAbc)
                        ? <Link href="#" onClick={() => this.handleFiltroAnaliseOrcamento(item.descServico)}>{item.descServico}</Link>
                        : item.itemCurvaAbc.descricaoOrcamento
                      }
                      </TableCell>
                    { colunas.includes(ColunasTabelaInconsistencia.MAO_DE_OBRA)
                    && <TableCell ><Table><TableHead><TableRow>
                      <TableCell>Código</TableCell>
                      <TableCell>Descrição</TableCell>
                      <TableCell>Unidade</TableCell>
                      <TableCell>Custo</TableCell>
                      <TableCell>Salário</TableCell>
                      <TableCell>Encargos Totais</TableCell>
                      <TableCell>Encargos Totais Desoneração</TableCell>
                    </TableRow></TableHead>
                      <TableBody>
                        {item.maosDeObraOrcamento.map((it, index) => <TableRow key={index}>
                        <TableCell>{it.codigo}</TableCell>
                        <TableCell>{it.descricao}</TableCell>
                        <TableCell>{it.unidade}</TableCell>
                        <TableCell>{it.custo}</TableCell>
                        <TableCell>{it.salario}</TableCell>
                        <TableCell>{it.encargosTotais}</TableCell>
                        <TableCell>{it.encargosTotaisDesoneracao}</TableCell>
                      </TableRow>)}
                      </TableBody>

                    </Table></TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.UNIDADE)
                      && <TableCell>{ _.isNil(item.itemCurvaAbc) ?  item.unidade : item.itemCurvaAbc.unidadeOrcamento }</TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.QUANTIDADE)
                      && <TableCell>{ _.isNil(item.itemCurvaAbc) ? item.quantidade : item.itemCurvaAbc.quantidadeFormatado }</TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.PRECO_UNITARIO)
                      && <TableCell>{ item.precoUnitario }</TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.PRECO_UNITARIO_COM_BDI)
                      && <TableCell>{ item.itemCurvaAbc.precoUnitarioFormatado }</TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.VALOR_PARCIAL)
                      && <TableCell >{ item.precoParcial }</TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.PRECO_PARCIAL)
                      && <TableCell >{ item.itemCurvaAbc.precoParcialCalculadoFormatado }</TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.PRECO_REFERENCIA_COM_BDI)
                      && <TableCell >{ item.itemCurvaAbc.valorReferenciaComBdiFormatado }</TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.PRECO_PARCIAL_REFERENCIA)
                      && <TableCell >{ item.itemCurvaAbc.precoParcialReferenciaFormatado }</TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.SUBPRECO)
                      && <TableCell >
                           <Typography style={{ color: item.itemCurvaAbc.valorSobrepreco < -0.001 ? 'red' : 'black' }}>
                            { item.itemCurvaAbc.valorSobreprecoFormatado }
                           </Typography>
                         </TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.INCIDENCIA)
                      && <TableCell >{ item.incidenciaPrecoParcial }%</TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.ERRO_DETECTADO)
                      && <TableCell >
                          <Typography style={{ color: item.erroDetectado < -0.001 ? 'red' : 'black' }}>
                            { item.erroDetectadoFormatado }
                          </Typography>
                         </TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.MENOR_PRECO_ORCADO)
                      && <TableCell >{ item.menorPrecoOrcado }</TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.MES)
                    && <TableCell >{ _.isNil(item.itemCurvaAbc) ? item.mes : item.itemCurvaAbc.mes }</TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.ANO)
                    && <TableCell >{ _.isNil(item.itemCurvaAbc) ? item.ano : item.itemCurvaAbc.ano }</TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.SISTEMA_REFERENCIA)
                    && <TableCell >{ item.sistemaReferencia }</TableCell>
                    }
                    { colunas.includes(ColunasTabelaInconsistencia.DIFERENCA)
                      && <TableCell >
                          <Typography style={{ color: item.diferencaoMenorPrecoOrcado < -0.001 ? 'red' : 'black' }}>
                            { item.diferencaoMenorPrecoOrcadoFormatado }
                          </Typography>
                         </TableCell>
                    }

                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Paginacao
            pagina={ pagina }
            registrosPorPagina={ registrosPorPagina }
            total={ itens.length }
            onChangePage={(e, pag) => this.setState({ pagina: pag })}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    )
  }
}

export const mapStateToProps = (state) => ({
  contexto: state.contexto
})

export default connect(
  mapStateToProps, {
    filtrarAnaliseOrcamento
  }
)(withStyles(styles)(withRouter(TabelaInconsistencia)))
