import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import { url } from '../../js/util'
import recuperarReferenciasPrecosOrcamentoEmEdicao from './recuperarReferenciasPrecosOrcamentoEmEdicao'

const montarRequisicaoSalvar = (referencia, refState) => {
  return {
    cod: referencia !== undefined ? referencia.cod : null,
    sigla: refState.sistema,
    mes: refState.periodo.mes,
    ano: refState.periodo.ano,
    uf: refState.uf,
    encargosSociais:
      refState.encargosSociais !== undefined
        ? refState.encargosSociais.toString().replace(',', '.')
        : null,
    fatorInterferenciaTrafego:
      refState.fatorInterferenciaTrafego !== null
        ? refState.fatorInterferenciaTrafego.toString().replace(',', '.')
        : null,
    ordemRelevancia:
      referencia !== undefined
        ? referencia.ordemRelevancia
        : refState.ordemRelevancia
  }
}

const salvarReferenciaPrecoOrcamento =
  (referencia, refState, idEmpreendimento, idObra, idOrcamento) =>
    async (dispatch) => {
      const referenciaSalvar = montarRequisicaoSalvar(referencia, refState)
      const requestMethod = referenciaSalvar.cod ? 'put' : 'post'
      await axios[requestMethod](
        `${url({ idEmpreendimento, idObra, idOrcamento })}/referencia-preco`,
        referenciaSalvar,
        SLOW_REQUEST_CONFIG
      ).then(() => {
        dispatch(
          recuperarReferenciasPrecosOrcamentoEmEdicao(
            idEmpreendimento,
            idObra,
            idOrcamento
          )
        )
        return true
      })
      return false
    }

export default salvarReferenciaPrecoOrcamento
