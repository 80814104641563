import decode from 'jwt-decode'

const authTokenKey = 'authTokenKey'

export const saveSecurityToken = (authentication) => {
  try {
    localStorage.setItem(authTokenKey, JSON.stringify(authentication))
    return authentication
  } catch (err) {
    console.log(err) // eslint-disable-line no-console
    throw err
  }
}

export const removeSecurityToken = () => {
  try {
    localStorage.removeItem(authTokenKey)
    return authTokenKey
  } catch (err) {
    console.log(err) // eslint-disable-line no-console
    throw err
  }
}

export const getAuthentication = () => {
  try {
    const item = localStorage.getItem(authTokenKey)
    return item ? JSON.parse(item) : item
  } catch (err) {
    console.log(err) // eslint-disable-line no-console
    throw err
  }
}

export const isValidToken = (token) => {
  const tokenJwt = token || getAuthentication().tokenJwt
  return decode(tokenJwt).exp > new Date().getTime()
}

export const isAuthenticated = () => {
  const authentication = getAuthentication()
  return authentication && authentication.isUserAuthenticated && isValidToken()
}

export const getPerfisUsuario = () => {
  return isAuthenticated() ? decode(getAuthentication().tokenJwt).roles : []
}

export const hasAutorities = (perfis) => perfis.some(it => getPerfisUsuario().indexOf(it) >= 0)
