import React, { useState } from 'react'
import App from '../App'
import AnaliseRiscoEtapa1 from './AnaliseRiscoEtapa1'
import AnaliseRiscoEtapa2 from './AnaliseRiscoEtapa2'

const AnaliseRisco = () => {
  const [etapa, setEtapa] = useState(1)
  const [orcamentos, setOrcamentos] = useState([])

  const handleChangeEtapa = (numeroEtapa) => {
    setEtapa(numeroEtapa)
  }

  const handleChangeOrcamentos = (orc) => {
    setOrcamentos(orc)
  }

  return (
    <App noAppToolbar={false}>
      <>
        {etapa === 1 && (
          <AnaliseRiscoEtapa1
            orcamentos={orcamentos}
            onChangeOrcamentos={handleChangeOrcamentos}
            onChangeEtapa={handleChangeEtapa}
            etapa={etapa}
          />
        )}

        {etapa === 2 && (
          <AnaliseRiscoEtapa2
            orcamentos={orcamentos}
            onChangeEtapa={handleChangeEtapa}
            etapa={etapa}
          />
        )}
      </>
    </App>
  )
}

export default AnaliseRisco
