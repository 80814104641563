export default (theme) => ({
  avatar: {
    margin: 0,
    color: '#fff',
    backgroundColor: theme.palette.primary.main

  },
  userMenu: {
    '& ul': {
      width: 150,
      padding: 0
    }
  },
  row: {
    display: 'flex',
    justifyContent: 'center'
  }
})
