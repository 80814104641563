import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Template from '../../../layout/Template'
import NovaAnaliseConteudoSidebar from './sidebar/AnaliseConteudoSidebar'
import NovaAnaliseConteudoContent from './content/AnaliseConteudoContent'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { context_httpget } from '../../../../js/httpRequest'
import { ANALISE_CONTEUDO_PERSONALIZADA } from '../../../../js/util'
import { Box, Button, Divider, IconButton, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Alert from '@material-ui/lab/Alert'
import Link from '@material-ui/core/Link'

const AnaliseConteudo = ({ contexto, classes, history, match }) => {
  const [possuiCurvaAbc, setPossuiCurvaAbc] = useState(false)
  const [temas, setTemas] = useState([])
  const [temaSelecionado, setTemaSelecionado] = useState(null)
  const [personalizadaSelecionada, setPersonalizadaSelecionada] = useState(true)
  const [show, setShow] = useState(true)

  useEffect(() => {
    const verificaSeOrcamentoPossuiCurvaABC = async () => {
      const { possuiCurvaAbc } = await context_httpget('possui-curva-abc', contexto)
      setPossuiCurvaAbc(possuiCurvaAbc)
    }

    if (contexto) {
      contexto && verificaSeOrcamentoPossuiCurvaABC()
    }
  }, [contexto])

  useEffect(() => {
    const recuperarTemas = async () => {
      const temas = await context_httpget('analise-conteudo/temas', contexto)
      setTemas(temas)
    }

    possuiCurvaAbc && recuperarTemas()
  }, [possuiCurvaAbc])

  const handleSelecionarItemSidbar = (tipo, item) => {
    if (tipo === ANALISE_CONTEUDO_PERSONALIZADA) {
      setPersonalizadaSelecionada(item)
      setTemaSelecionado(null)
    } else {
      setTemaSelecionado(item)
      setPersonalizadaSelecionada(false)
    }
  }

  const handleCurvaAbcClick = () => history.push(match.url.replace('analise-conteudo', 'abc'))

  const handleShowMenu = () => {
    setShow(!show)
  }

  return (
    <Template>
      {possuiCurvaAbc
        ? (<React.Fragment>
          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Box>
              <Box display='flex' alignItems='center' onClick={handleCurvaAbcClick}>
                <IconButton>
                  <ArrowBackIcon />
                </IconButton>
                <Typography variant='h6' className={classes.colorTitle}> Análise de conteúdo</Typography>
              </Box>
            </Box>
          </Box>
          <Grid container spacing={0}>
            {show &&
              <Grid item xs={12} sm={12} md={6} lg={4} xl={3} >
                <NovaAnaliseConteudoSidebar
                  classes={classes}
                  temas={temas}
                  temaAtivo={temaSelecionado}
                  personalizadaAtiva={personalizadaSelecionada}
                  onSelecionarItemSidbar={handleSelecionarItemSidbar}
                />
              </Grid>}

            <Grid item md={show ? 6 : 12} lg={show ? 8 : 12} xl={show ? 9 : 12}>
              <Divider />
              <div style={{ borderRight: 'solid 1px #ced2d9' }}>
                {show && <Button variant='outlined' color='secondary' size='small' onClick={handleShowMenu} className={classes.buttonShow}>Esconder temas</Button>}
                {show === false && <Button variant='outlined' color='secondary' size='small' onClick={handleShowMenu} className={classes.buttonShow}>Mostrar temas</Button>}
              </div>
              {(temaSelecionado || personalizadaSelecionada) && (
                <NovaAnaliseConteudoContent
                  temaAtivo={temaSelecionado}
                  personalizadaAtiva={personalizadaSelecionada}
                  classes={classes}
                  contexto={contexto}
                />
              )}
            </Grid>
          </Grid>
        </React.Fragment>)
        : (
          <Alert severity='warning'>
            Só é possível visualizar Análise de Conteúdo do Orçamento após <Link onClick={handleCurvaAbcClick}>criar a Curva ABC</Link>.
          </Alert>
        )
      }
    </Template>
  )
}

AnaliseConteudo.propTypes = {
  classes: PropTypes.object,
  contexto: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object
}

export const mapStateToProps = ({ contexto }) => ({
  contexto
})

export default connect(mapStateToProps)(withStyles(styles)(AnaliseConteudo))
