import _ from 'lodash'
import { arrayToCSV, converterParaNumero } from './util'

export const parametroPreenchido = (parametro) => {
  return Boolean(parametro && !_.isEmpty(parametro.trim()))
}

export const validarParametroObrigatorio = (parametro, label, erros) => {
  if (!parametroPreenchido(parametro)) {
    erros.push(`Preenchimento obrigatório: ${label}.`)
  }
  return erros
}

export const possuiSobreprecoPositivo = (item, precosRef) => {
  const precoRef = precosRef[item.codigoServico]
  if (_.isNil(precoRef)) {
    return false
  }
  return valorSobrepreco(item, precoRef) > 0.01
}

const valorSobrepreco = (item, precoRef) => item.precoParcialCalculado - valorTotalRef(item, precoRef)

const valorTotalRef = (item, precoRef) => precoRefComBdi(item, precoRef) * item.quantidade

const precoRefComBdi = (item, precoRef) => precoRef + (precoRef * item.bdiPercentual)

export const possuiSobreprecoNegativo = (item, precosRef) => {
  const precoRef = precosRef[item.codigoServico]
  if (_.isNil(precoRef)) {
    return false
  }
  return valorSobrepreco(item, precoRef) < -0.01
}

export const validarParametroNumerico = (parametro, parametroNumerico, label, erros) => {
  
  if (parametro === null || parametro === undefined) {
    erros.push(`O valor do atributo ${label} não está definido.`);
    return erros;
  }
  
  let numero = parseFloat(parametro.replace(/[^\d,]/g, '').replace(/\./g, '').replace(',', '.'));
  numero = converterParaNumero(numero)

  if (numero != parametroNumerico) {
    erros.push(`O valor do atributo ${label} precisa ser numérico.`);
  }

  return erros
}

export const validar = (itemOrcamento) => {
  if (!itemOrcamento.linhaOriginal || _.isEmpty(itemOrcamento.linhaOriginal.trim())) {
    return ['Linha em branco']
  }
  let erros = validarParametroObrigatorio(itemOrcamento.descServico, 'Descrição do Serviço', [])
  erros = validarParametroObrigatorio(itemOrcamento.unidade, 'Unidade do Serviço', erros)
  erros = validarParametroObrigatorio(itemOrcamento.quantidade, 'Quantidade do Serviço', erros)
  erros = validarParametroObrigatorio(itemOrcamento.precoUnitario, 'Preço Unitário', erros)
  erros = validarParametroObrigatorio(itemOrcamento.precoParcial, 'Preço Parcial', erros)

  erros = validarParametroNumerico(itemOrcamento.quantidade, itemOrcamento.qtdeNumerico, 'Quantidade do Serviço', erros)
  
  erros = validarParametroNumerico(itemOrcamento.precoUnitario, itemOrcamento.precoUnitarioNumerico, 'Preço Unitário', erros)
  
  erros = validarParametroNumerico(itemOrcamento.precoParcial, itemOrcamento.precoParcialNumerico, 'Preço Parcial', erros)
  
  return erros
}

class ItemOrcamento {
  constructor (linhaOriginal, numero, codServico, descServico, unidade, quantidade, precoUnitario, precoParcial) {
    this.numero = numero
    this.codServico = codServico
    this.descServico = descServico
    this.unidade = unidade
    this.quantidade = quantidade
    this.qtdeNumerico = converterParaNumero(quantidade)
    this.precoUnitario = precoUnitario
    this.precoUnitarioNumerico = converterParaNumero(precoUnitario)
    this.precoParcial = precoParcial ? precoParcial.replace(/(\r\n|\n|\r)/gm, '') : precoParcial
    this.precoParcialNumerico = converterParaNumero(precoParcial)
    this.linhaOriginal = linhaOriginal
    this.erros = validar(this)
  }

  get precoParcialCalculado () {
    return this.qtdeNumerico && this.precoUnitarioNumerico ? (this.qtdeNumerico * this.precoUnitarioNumerico) : 0
  }

  get diferencaCalculo () {
    return this.precoParcialNumerico ? this.precoParcialNumerico - this.precoParcialCalculado : 0
  }

  get diferencaCalculoFormatada () {
    const diferenca = this.diferencaCalculo
    return diferenca < 0.001 && diferenca > -0.001 ? ' - ' : diferenca.toFixed(2)
  }

  static fromItem (item) {
    return new ItemOrcamento(
      item.linhaOriginal,
      item.numero,
      item.codServico,
      item.descServico,
      item.unidade,
      item.quantidade,
      item.precoUnitario,
      item.precoParcial
    )
  }

  static fromLinhaBruta (numero, linhaBruta) {
    if (!linhaBruta || _.isEmpty(linhaBruta)) return new this(linhaBruta, numero)
    const parametros = arrayToCSV(linhaBruta)
    if (parametros.length === 1) return new this(linhaBruta, numero, undefined, parametros[0])
    return new this(linhaBruta, numero, parametros[0], parametros[1], parametros[2], parametros[3], parametros[4], parametros[5])
  }
}

export default ItemOrcamento
