import { AUTHENTICATION_FAILED, ERRO_AO_GERAR_TOKEN_LOGIN_INTEGRADO, TOKEN_LOGIN_INTEGRADO_GERADO, USER_AUTHENTICATED, USER_LOGOUT } from '../actions/types'
import { getAuthentication } from '../security/securityContext'

const INITIAL_STATE = getAuthentication() || { isUserAuthenticated: false }

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTHENTICATION_FAILED:
      return action.payload
    case USER_AUTHENTICATED:
      return action.payload
    case TOKEN_LOGIN_INTEGRADO_GERADO:
      return action.payload
    case ERRO_AO_GERAR_TOKEN_LOGIN_INTEGRADO:
      return action.payload
    case USER_LOGOUT:
      return action.payload
    default:
      return state
  }
}
