import React from 'react'
import Template from '../layout/Template'
import Typography from '@material-ui/core/Typography'

const Carregando = () => (
  <Template ocultarBreadCrumb>
    <Typography variant='h6'>
      Carregando...
    </Typography>
  </Template>
)

export default Carregando
