import React, { Component } from 'react'
import { Divider, IconButton, Table, TableCell, TableHead, TableRow } from '@material-ui/core'
import ResumoCustoTotal from '../../ResumoCustoTotal'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../../../styles'
import Button from '@material-ui/core/Button'
import AdicionarEquipamentoComposicao from './AdicionarEquipamentoComposicao'
import { formatarNumeroParaMoedaBR, mapStateToPropsContexto, showSuccessMsg, urlContexto } from '../../../../../../../../js/util'
import TableBody from '@material-ui/core/TableBody'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import EditarEquipamentoComposicao from './EditarEquipamentoComposicao'
import ModalConfirmacao from '../../../../../../../comum/ModalConfirmacao'
import Typography from '@material-ui/core/Typography'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import StyledDrawer from '../../../../../../../comum/StyledDrawer'
import { context_httpdelete } from '../../../../../../../../js/httpRequest'
import { connect } from 'react-redux'
import iconEquipamento from '../../../../../../../../img/equipamento.svg'
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router-dom'
import { MENU_EQUIPAMENTOS } from '../../../../../precos/preco'

class EquipamentosComposicao extends Component {
  static propTypes = {
    insumos: PropTypes.object.isRequired,
    codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    permitirEdicao: PropTypes.bool,
    classes: PropTypes.object,
    contexto: PropTypes.object,
    history: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      exibirDrawerAdicionar: false,
      equipamentoEdicao: null,
      equipamentoExclusao: null
    }
    this.handleDadosAlterados = this.handleDadosAlterados.bind(this)
    this.handleConfirmarExclusaoEquipamento = this.handleConfirmarExclusaoEquipamento.bind(this)
    this.handleEquipamentosClick = this.handleEquipamentosClick.bind(this)
  }

  handleDadosAlterados() {
    const { onDadosAlterados } = this.props
    this.setState({
      exibirDrawerAdicionar: false,
      equipamentoEdicao: null,
      equipamentoExclusao: null
    })
    onDadosAlterados()
  }

  async handleConfirmarExclusaoEquipamento () {
    const { codigoComposicao, contexto, onDadosAlterados } = this.props
    const { equipamentoExclusao } = this.state
    const codigoEquipamento = equipamentoExclusao.equipamentoOrcamento.codigo
    const resultado = await context_httpdelete(`precos/desvincular-equipamento?composicao=${codigoComposicao}&equipamento=${codigoEquipamento}`, contexto)
    if (resultado) {
      this.setState({
        exibirDrawerAdicionar: false,
        equipamentoEdicao: null,
        equipamentoExclusao: null
      })
      showSuccessMsg(`Equipamento ${codigoEquipamento} desvinculado da composição.`)
      onDadosAlterados()
    }
  }

  handleEquipamentosClick () {
    const { contexto } = this.props
    this.props.history.push(`${urlContexto(contexto)}/precos`, { menuSelecionado: MENU_EQUIPAMENTOS })
  }

  render() {
    const { classes, insumos, codigoComposicao, permitirEdicao } = this.props
    const { exibirDrawerAdicionar, equipamentoEdicao, equipamentoExclusao } = this.state
    return (
      <>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell} colSpan={3}/>
              <TableCell className={classes.tableCellTitleUtilizacao} colSpan={2}>Utilização</TableCell>
              <TableCell className={classes.tableCellTitleCusto} colSpan={2}>Custo Operacional</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ width: '5%' }} className={classes.tableCell}>
                <MesmaLinha>
                  A
                  <IconButton data-tip='Clique para ver todos os equipamentos do orçamento' data-for='tooltip-equipamento' onClick={this.handleEquipamentosClick}>
                    <img width={21} height={21} src={iconEquipamento} alt='Ver todos os equipamentos do orçamento' />
                  </IconButton>
                  <ReactTooltip id='tooltip-equipamento' data-tip type='info' effect='float' place='left' />
                </MesmaLinha>
              </TableCell>
              <TableCell style={{ width: '45%' }} className={classes.tableCell}>
                Equipamentos
                {
                  permitirEdicao && (
                    <Button color='primary' size='small' onClick={() => this.setState({ exibirDrawerAdicionar: true })}>
                      + Adicionar
                    </Button>
                  )
                }
              </TableCell>
              <TableCell style={{ width: '10%' }} className={classes.tableCell}>Quantidade</TableCell>
              <TableCell style={{ width: '10%' }} className={classes.tableCell}>Operativa</TableCell>
              <TableCell style={{ width: '10%' }} className={classes.tableCell}>Improdutiva</TableCell>
              <TableCell style={{ width: '10%' }} className={classes.tableCell}>Operativo</TableCell>
              <TableCell style={{ width: '10%' }} className={classes.tableCell}>Improdutivo</TableCell>
              <TableCell align={'right'} className={classes.tableCell}>Custo Horário</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              insumos.equipamentos.map((equipamentoComposicao) => (
                <TableRow key={equipamentoComposicao.codigoEquipamento}>
                  <TableCell className={classes.tableCell}>
                    {
                      permitirEdicao && (
                        <MesmaLinha>
                          <IconButton size='small' onClick={() => this.setState({ equipamentoEdicao: equipamentoComposicao })} color='primary'>
                            <EditIcon />
                          </IconButton>
                          <IconButton size='small' onClick={() => this.setState({ equipamentoExclusao: equipamentoComposicao })} color='primary'>
                            <DeleteIcon />
                          </IconButton>
                        </MesmaLinha>
                      )
                    }
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {equipamentoComposicao.equipamentoOrcamento.codigo} - {equipamentoComposicao.equipamentoOrcamento.descricao}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {formatarNumeroParaMoedaBR(equipamentoComposicao.quantidade, 7, 7)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {formatarNumeroParaMoedaBR(equipamentoComposicao.utilizacaoOperativa, 2, 4)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {formatarNumeroParaMoedaBR(equipamentoComposicao.utilizacaoImprodutiva, 2, 4)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {formatarNumeroParaMoedaBR(equipamentoComposicao.equipamentoOrcamento.custoProdutivo, 2, 4)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {formatarNumeroParaMoedaBR(equipamentoComposicao.equipamentoOrcamento.custoImprodutivo, 2, 4)}
                  </TableCell>
                  <TableCell align={'right'} className={classes.tableCell}>
                    {formatarNumeroParaMoedaBR(equipamentoComposicao.custoHorarioTotal, 2, 4)}
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <ResumoCustoTotal titulo='Custo Horário Total de Equipamentos' valorCusto={insumos.custoHorarioTotalEquipamentos}/>
        <Divider className={classes.divider}/>
        {
          exibirDrawerAdicionar && (
            <StyledDrawer
              PaperProps={{ className: classes.drawerEdicao }}
              open={true}
              anchor={'right'}>
              <AdicionarEquipamentoComposicao
                codigoComposicao={codigoComposicao}
                onDadosAlterados={this.handleDadosAlterados}
                onCancelar={() => this.setState({ exibirDrawerAdicionar: false })}
              />
            </StyledDrawer>
          )
        }
        {
          equipamentoEdicao != null && (
            <StyledDrawer
              PaperProps={{ className: classes.drawerEdicao }}
              open={true}
              anchor={'right'}>
              <EditarEquipamentoComposicao
                equipamentoComposicao={equipamentoEdicao}
                codigoComposicao={codigoComposicao}
                onCancelar={() => this.setState({ equipamentoEdicao: null })}
                onDadosAlterados={this.handleDadosAlterados}
              />
            </StyledDrawer>
          )
        }
        {
          equipamentoExclusao != null && (
            <ModalConfirmacao
              open
              titulo='Confirmar Exclusão de Equipamento'
              confirmar={this.handleConfirmarExclusaoEquipamento}
              onClose={() => this.setState({ equipamentoExclusao: null })}>
              <Typography>
                Deseja realmente desvincular o equipamento { equipamentoExclusao.equipamentoOrcamento.codigo } - { equipamentoExclusao.equipamentoOrcamento.descricao } da composição?
              </Typography>
            </ModalConfirmacao>
          )
        }
      </>
    )
  }
}

export default withRouter(connect(mapStateToPropsContexto)(withStyles(styles)(EquipamentosComposicao)))
