import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import styles from '../../../../styles'
import Typography from '@material-ui/core/Typography'
import BotaoAdicionarParametro from './BotaoAdicionarParametro'
import { converterParametro, converterParametroToBackend, TIPO_PARAMETRO_VARIAVEL_ORCAMENTO } from '../../../../analise-conteudo'
import { connect } from 'react-redux'
import ParametrosCadastrados from './ParametrosCadastrados'
import { showSuccessMsg } from '../../../../../../../../js/util'
import { context_httpdelete, context_httppost, context_httpput } from '../../../../../../../../js/httpRequest'
import NenhumItemCadastrado from '../../../../../../../comum/NenhumItemCadastrado'

class Parametros extends Component {
  static propTypes = {
    classes: PropTypes.object,
    formula: PropTypes.object,
    contexto: PropTypes.object,
    parametros: PropTypes.array,
    tipoAnaliseSelecionada: PropTypes.string,
    expressoesDisponiveis: PropTypes.array.isRequired,
    formulasDisponiveis: PropTypes.array.isRequired,
    onFormulaChange: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props);
    this.state = {
      todosParametros: [],
    }
    this.handleAdicionarParametro = this.handleAdicionarParametro.bind(this)
    this.handleParametroChange = this.handleParametroChange.bind(this)
    this.handleSalvarParametro = this.handleSalvarParametro.bind(this)
    this.handleExcluirParametro = this.handleExcluirParametro.bind(this)
  }

  componentDidMount () {
    if(this.props.formula) {
      this.atualizarParametros()
    }
  }

  componentDidUpdate (prevProps) {
    const { parametros } = this.props
    if(prevProps.parametros !== parametros) {
      this.atualizarParametros()
    }
  }

  atualizarParametros() {
    const { parametros } = this.props
    this.setState({
      todosParametros: parametros.map(it => converterParametro(it, false))
    })
  }

  async handleAdicionarParametro (tipo) {
    const { formula, contexto } = this.props
    const parametro = await context_httppost(`analise-conteudo/formulas/${formula.codigo}/parametros`, contexto, { tipo: tipo.value })
    const { todosParametros } = this.state
    this.setState({
      todosParametros: todosParametros.concat(converterParametro(parametro, tipo === TIPO_PARAMETRO_VARIAVEL_ORCAMENTO))
    })
  }

  handleParametroChange(parametroNovo) {
    const { todosParametros } = this.state
    const substituirParametro = (parametro) => {
      return parametro.codigo === parametroNovo.codigo
        ? { ...parametroNovo, alterado: true }
        : parametro
    }
    const novaLista = todosParametros.map(substituirParametro)
    this.setState({ todosParametros: novaLista })
  }

  async handleSalvarParametro (parametro) {
    const { formula, contexto, onFormulaChange, tipoAnaliseSelecionada } = this.props
    const dados = converterParametroToBackend(parametro)
    const formulaExecutada = await context_httpput(`analise-conteudo/formulas/${formula.codigo}/parametros?tipoAnalise=${tipoAnaliseSelecionada}`, contexto, dados)
    if (formulaExecutada) {
      const { resultado, parametros } = formulaExecutada
      const { todosParametros } = this.state
      const substituirParametro = (it) => {
        return it.codigo === parametro.codigo
          ? { ...parametro, alterado: false }
          : it
      }
      const novaLista = todosParametros.map(substituirParametro)
      this.setState({ todosParametros: novaLista })
      onFormulaChange(formula, resultado, parametros)
    }
  }

  async handleExcluirParametro(parametro) {
    const { formula, contexto, onFormulaChange, tipoAnaliseSelecionada } = this.props
    const formulaExecutada = await context_httpdelete(`analise-conteudo/formulas/${formula.codigo}/parametros/${parametro.codigo}?tipoAnalise=${tipoAnaliseSelecionada}`, contexto)
    if (formulaExecutada) {
      const { resultado, parametros } = formulaExecutada
      const { todosParametros } = this.state
      this.setState({
        todosParametros: todosParametros.filter((it) => it.codigo !== parametro.codigo)
      })
      showSuccessMsg('Parâmetro excluído')
      onFormulaChange(formula, resultado, parametros)
    }
  }

  render () {
    const { classes, expressoesDisponiveis, formulasDisponiveis } = this.props
    const { todosParametros } = this.state

    return (
      <div className={classes.divParametros}>
        <Typography variant='h6'>Parâmetros</Typography>
        {
          Boolean(todosParametros) && (
            todosParametros.length === 0
              ? <NenhumItemCadastrado variant='subtitle2' mensagem='Nenhum parâmetro cadastrado' />
              : (
                <>
                  <ParametrosCadastrados
                    classes={classes}
                    value={todosParametros}
                    expressoesDisponiveis={expressoesDisponiveis}
                    formulasDisponiveis={formulasDisponiveis}
                    onParametroChange={this.handleParametroChange}
                    onSalvarParametro={this.handleSalvarParametro}
                    onExcluirParametro={this.handleExcluirParametro}
                  />
                </>
              )
          )
        }
        <BotaoAdicionarParametro onAdicionarParametro={this.handleAdicionarParametro} />
      </div>
    )
  }
}

const mapStateToProps = ({ contexto }) => ({
  contexto
})
export default connect(mapStateToProps)(withStyles(styles)(Parametros))
