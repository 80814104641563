import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TableRow from '@material-ui/core/TableRow'
import { Checkbox } from '@material-ui/core'
import ReactTooltip from 'react-tooltip'

const ItemVincularFormula = ({ classes, formula, isChecked, isDisabled, onSelecionarFormula }) => {
  return (
    <React.Fragment>
      <TableRow>
        <TableCell width={'5%'} rowSpan={2} className={classes.tabelaExpressao}>
          <a data-tip data-for={`descricaoID${formula.codFormula}`}>
            <Checkbox
              color={'primary'}
              checked={isChecked}
              disabled={isDisabled}
              onChange={(e) => onSelecionarFormula(e, formula)}
            />
          </a>
          { isDisabled && <ReactTooltip id={`descricaoID${formula.codFormula}`} place='right' type='dark' effect='solid'>
            <span>Esta fórmula já está vinculada ao tema</span>
          </ReactTooltip> }
        </TableCell>
        <TableCell width={'20%'}>
          <Typography>{formula.nomeFormula}</Typography>
        </TableCell>
        <TableCell width={'20%'}>
          <Typography>{formula.nomeOrcamento}</Typography>
        </TableCell>
        <TableCell width={'20%'}>
          <Typography>{formula.nomeObra}</Typography>
        </TableCell>
        <TableCell align='right' width={'20%'}>
          <Typography>{formula.nomeEmpreendimento}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4}>
          <strong>Descrição: </strong> {formula.descricaoFormula}
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

ItemVincularFormula.propTypes = {
  classes: PropTypes.object,
  formula: PropTypes.object,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onSelecionarFormula: PropTypes.func.isRequired
}
export default ItemVincularFormula
