import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { context_httpput } from '../../../../js/httpRequest'
import { showSuccessMsg } from '../../../../js/util'
import ModalConfirmacao from '../../../comum/ModalConfirmacao'

class ModalLimparAmostraCurvaAbc extends Component {

  static propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    onItensAlterados: PropTypes.func.isRequired,
    contexto: PropTypes.object,
    classes: PropTypes.object
  }

  confirmar = () => {
    const { contexto, onItensAlterados } = this.props

    context_httpput('curva-abc/limpar-amostra', contexto, {})
      .then(() => {
        showSuccessMsg('Limpar amostra da Curva ABC feita com sucesso')
        onItensAlterados()
        this.fechar()
      })
  }

  fechar = () => this.props.close()

  render() {
    const { open } = this.props

    return (
      <ModalConfirmacao
        open={open}
        onClose={() => this.fechar()}
        confirmar={() => this.confirmar()}
        titulo={'Limpar amostra'}
      >
        <Typography align='justify'>
          Todos os itens serão removidos da amostra. Deseja continuar?
        </Typography>
      </ModalConfirmacao>
    )
  }
}

const mapStateToProps = ({ contexto }) => ({ contexto })

export default connect(mapStateToProps)(withStyles(styles)(ModalLimparAmostraCurvaAbc))
