import React, { Component } from 'react'
import { Button, Dialog, DialogActions, DialogContent, withStyles } from '@material-ui/core'
import styles from './styles'
import TituloModal from '../../../comum/TituloModal'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import ForwardIcon from '@material-ui/icons/Forward'
import { context_httpdelete } from '../../../../js/httpRequest'
import { showErrorMsg, showSuccessMsg } from '../../../../js/util'


class ModalExcluirCurvaAbc extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    onCurvaAbcExcluida: PropTypes.func.isRequired,
    contexto: PropTypes.object,
    classes: PropTypes.object
  }

  confirmar = () => {
    const {contexto, onCurvaAbcExcluida} = this.props
    context_httpdelete('curva-abc', contexto)
      .then((resumoOrcamento) => {
        showSuccessMsg('Curva ABC excluída com sucesso')
        onCurvaAbcExcluida(resumoOrcamento)
        window.location.reload(false)
      })
      .catch(() => showErrorMsg('Erro ao excluir Curva ABC'))
  }

  fechar = () => this.props.close()

  render() {
    const {open, classes} = this.props

    return open && (
      <Dialog
        open={true}
        onClose={() => this.fechar()}
        aria-labelledby='form-dialog-title'
        fullWidth
      >

        <TituloModal onClose={this.fechar}>
          Excluir curva ABC para editar
        </TituloModal>

        <DialogContent>

          <Typography className={classes.textoExcluirCurvaABC} variant={'h6'}>
            Entenda os passos para habilitar a edição dos Dados Brutos do Orçamento
          </Typography>

          <Typography className={classes.textoExcluirCurvaABC} variant={'body1'} color={'primary'}>
            <ForwardIcon/>
            Passo 1
          </Typography>

          <Typography className={classes.textoExcluirCurvaABC}>Confirme que deseja excluir a Curva ABC</Typography>

          <Typography className={classes.textoExcluirCurvaABC} variant={'body1'} color={'primary'}>
            <ForwardIcon/>
            Passo 2
          </Typography>

          <Typography className={classes.textoExcluirCurvaABC}>
            Realizar os ajuste necessários (Dados Brutos e Tratamento de Possíveis Erros)
          </Typography>

          <Typography className={classes.textoExcluirCurvaABC} variant={'body1'} color={'primary'}>
            <ForwardIcon/>
            Passo 3
          </Typography>

          <Typography className={classes.textoExcluirCurvaABC}>
            Crie uma nova Curva ABC
          </Typography>

        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={() => this.fechar()}>CANCELAR</Button>
          <Button variant='contained' color={'primary'} onClick={() => this.confirmar()}>CONFIRMAR</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({contexto}) => ({contexto})

export default connect(mapStateToProps)(withStyles(styles)(ModalExcluirCurvaAbc))
