import React from 'react'
import PropTypes from 'prop-types'

const MesmaLinha = ({ children, className, mt, onMouseEnter, onMouseLeave }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: mt ? mt : 0
    }}
    className={className}
    onMouseEnter={onMouseEnter ? onMouseEnter : ()=>({})}
    onMouseLeave={onMouseLeave ? onMouseLeave : ()=>({})}>
    { children }
  </div>
)

MesmaLinha.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  mt: PropTypes.number,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func
}

export default MesmaLinha
