const styles = (theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(1)
  },
  linkIcon: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    paddingRight: 12
  },
  btnPrincipal: {
    fontWeight: '500',
    textTransform: 'lowercase',
    boxShadow: 'none',
    '-webkit-font-smoothing': 'antialiased',
    '-moz-osx-font-smoothing': 'grayscale',
    '&:first-child': {
      marginLeft: 0
    }
  },
  btnIcone: {
    marginLeft: 4,
    marginRight: 4
  }
})

export default styles
