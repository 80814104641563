import React from 'react'
import WarningIcon from '@material-ui/icons/Warning'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import List from '@material-ui/core/List'
import Alert from '@material-ui/lab/Alert'
import DeviceHubIcon from '@material-ui/icons/DeviceHub'
import FunctionsIcon from '@material-ui/icons/Functions'
import { context_httpdelete } from '../../../../../../../js/httpRequest'
import { showSuccessMsg } from '../../../../../../../js/util'
import ModalConfirmacao from '../../../../../../comum/ModalConfirmacao'
import ItensVinculadosExcluir from '../../comum/ItensVinculadosExcluir'

const ModalExcluirFormula = ({
  contexto,
  formula,
  fechar,
  formulasVinculadas,
  tipologiasCombinadas,
  onComplete,
  onFormulasVinculadasChange
}) => {
  const handleConfirmar = async () => {
    const { formulas, tipologiasCombinadas } = await context_httpdelete(`analise-conteudo/formulas/${formula.codigo}`, contexto)
    if (formulas.length === 0 && tipologiasCombinadas.length === 0) {
      showSuccessMsg('Fórmula excluída')
      onComplete()
    } else {
      onFormulasVinculadasChange(formulas, tipologiasCombinadas)
    }
  }

  return (
    <React.Fragment>
      <ModalConfirmacao
        open={Boolean(formula)}
        onClose={fechar}
        confirmar={handleConfirmar}
        disabled={formulasVinculadas.length > 0}
        titulo={
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <WarningIcon color='inherit' style={{ paddingRight: 4 }} /> Excluir Fórmula
          </span>
        }
      >
        {(formulasVinculadas.length > 0 || tipologiasCombinadas.length > 0)
          ? <React.Fragment>
            <Alert severity='warning'>
              Não foi possível excluir esta fórmula, pois está em uso por outras Fórmulas ou Tipologias Combinadas apresentadas abaixo.
            </Alert>
            <List>
              <ItensVinculadosExcluir values={formulasVinculadas} iconLabel={FunctionsIcon} iconText='Fórmula' showSecondaryTitle />
              <ItensVinculadosExcluir values={tipologiasCombinadas} iconLabel={DeviceHubIcon} iconText='Tipologia Combinada' showSecondaryTitle={false} />
            </List>
          </React.Fragment>
          : <Alert severity='warning'>
            Deseja realmente excluir a fórmula <b>{formula && formula.nome}</b>?
          </Alert>
        }
      </ModalConfirmacao>
    </React.Fragment>
  )
}

ModalExcluirFormula.propTypes = {
  formula: PropTypes.object,
  formulasVinculadas: PropTypes.array,
  tipologiasCombinadas: PropTypes.array,
  fechar: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onFormulasVinculadasChange: PropTypes.func.isRequired,
  contexto: PropTypes.object
}

const mapStateToProps = ({ contexto }) => ({ contexto })
export default connect(mapStateToProps)(ModalExcluirFormula)
