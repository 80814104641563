import React from 'react'
import { Link } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import CurvaABC from '../../img/curvaABC.png'
import styles from './styles'
import PropTypes from 'prop-types'

const HomePrimeiroAcesso = ({ classes }) => {
  return (
    <Grid container>
      <Grid item xs={12} sm={7}>
        <Typography variant='h4' color='primary'>O SAO é uma ferramenta de auxílio a análise de orçamentos da administração pública.</Typography>
        <Typography variant='h6' className={classes.desc}>Crie seu primeiro orçamento e economize tempo.</Typography>
        <Button datacy-id='btnCadastrarEmpreendimento' component={Link} to='/empreendimentos' variant='contained' color='primary' href='#contained-buttons'>
          Cadastre ou acesse um empreendimento
        </Button>
      </Grid>
      <Grid item xs={12} sm={5}>
        <img className={classes.img} src={CurvaABC} alt="Imagem exemplo Curva ABC"/>
      </Grid>
    </Grid>
  )
}

HomePrimeiroAcesso.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(HomePrimeiroAcesso)
