import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography/Typography'
import { Box, Divider } from '@material-ui/core'
import PropTypes from 'prop-types'
import { mapStateToPropsContexto } from '../../../../../../js/util'
import { withStyles } from '@material-ui/core/styles'
import styles from '../styles'
import CabecalhoComposicao from './CabecalhoComposicao'
import { connect } from 'react-redux'
import { context_httpget } from '../../../../../../js/httpRequest'
import DadosBasicosComposicao from './DadosBasicosComposicao'
import EquipamentosComposicao from './insumos/equipamentos/EquipamentosComposicao'
import MaosDeObraComposicao from './insumos/maos-de-obra/MaosDeObraComposicao'
import MateriaisComposicao from './insumos/materiais/MateriaisComposicao'
import AtividadesAuxiliaresComposicao from './composicoes-auxiliar/atividades-auxiliares/AtividadesAuxiliaresComposicao'
import TemposFixosComposicao from './composicoes-auxiliar/tempos-fixos/TemposFixosComposicao'
import ResumoCustoTotal from './ResumoCustoTotal'
import TransportesComposicao from './composicoes-auxiliar/transportes/TransportesComposicao'
import Skeleton from '@material-ui/lab/Skeleton'
import _ from 'lodash'
import ModalComposicaoAuxiliar from './ModalComposicaoAuxiliar'
import { CompositionProvider } from '../../../../../../hooks/useComposition'
import { calcularValorBDI } from '../../../precos/preco'

const defaultState = {
  composicao: null,
  composicaoAuxiliarVisualizacao: null,
  detalhes: null,
  itemCurvaAbc: null,
}

class RelatorioComposicao extends Component {
  static propTypes = {
    composicao: PropTypes.object.isRequired,
    permitirEdicao: PropTypes.bool,
    onEditarDadosBasicosClick: PropTypes.func,
    classes: PropTypes.object,
    contexto: PropTypes.object,
    onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.func,
    onItemModificado: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = defaultState
    this.carregarDetalhes = this.carregarDetalhes.bind(this)
    this.fecharDetalhamentoComposicaoAuxiliar =
      this.fecharDetalhamentoComposicaoAuxiliar.bind(this)
  }

  componentDidMount() {
    if (this.props.composicao) {
      this.carregarDetalhesState()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.composicao !== prevProps.composicao) {
      this.carregarDetalhesState()
    }
  }

  fecharDetalhamentoComposicaoAuxiliar() {
    this.setState({ composicaoAuxiliarVisualizacao: null })
    this.carregarDetalhes()
  }

  async carregarDetalhesState() {
    this.setState(defaultState)
    const { contexto, composicao } = this.props
    const data = await context_httpget(
      `precos/composicoes/detalhes?composicao=${composicao.codigo}`,
      contexto,
      null,
      true
    )
    if (data) {
      this.setState({
        composicao: data.composicao,
        detalhes: data.detalhes,
        itemCurvaAbc: data.itemCurvaAbc
      })
    }
  }

  async carregarDetalhes() {
    this.setState(defaultState)
    const { contexto, composicao, onItemModificado } = this.props
    const data = await context_httpget(
      `precos/composicoes/detalhes?composicao=${composicao.codigo}`,
      contexto,
      null,
      true
    )
    if (data) {
      this.setState({
        composicao: data.composicao,
        detalhes: data.detalhes,
        itemCurvaAbc: data.itemCurvaAbc
      })
    }
    onItemModificado && onItemModificado()
  }

  render() {
    const { classes, permitirEdicao, onAtualizarItemCurvaABCComposicaoDuplicada, contexto } = this.props
    const { detalhes, composicao, itemCurvaAbc, composicaoAuxiliarVisualizacao } = this.state

    if (_.isNil(detalhes)) {
      return (
        <>
          <Typography variant="h6">RELATÓRIO DA COMPOSIÇÃO</Typography>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Box mb={1}>
            <Skeleton animation="wave" variant="rect" height={200} />
          </Box>
          <Skeleton animation="wave" />
          <Skeleton animation="wave" />
          <Box mb={1}>
            <Skeleton animation="wave" variant="rect" height={200} />
          </Box>
        </>
      )
    }

    const valorBDI = calcularValorBDI(itemCurvaAbc, detalhes.custoUnitarioDiretoTotal)

    return (
      detalhes && (
        <Box className={classes.paddingReport}>
          <CompositionProvider
            onDadosAlterados={this.carregarDetalhes}
            onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
          >
            <DadosBasicosComposicao
              value={composicao}
              detalhes={detalhes}
              permitirEdicao={permitirEdicao}
              itemCurvaAbc={itemCurvaAbc}
              contexto={contexto}
              valorBDI={valorBDI}
            />
            <Divider className={classes.divider} />
          </CompositionProvider>

          <div className={classes.subCabecalho}>
            <Typography variant={'h6'}>
              Custo Unitário de Referência (Valor em R$/{composicao.unidade})
            </Typography>
          </div>

          <CabecalhoComposicao classes={classes} composicao={composicao} />

          <EquipamentosComposicao
            insumos={detalhes.insumos}
            codigoComposicao={composicao.codigo}
            onDadosAlterados={this.carregarDetalhes}
            permitirEdicao={permitirEdicao}
          />

          <MaosDeObraComposicao
            insumos={detalhes.insumos}
            codigoComposicao={composicao.codigo}
            onDadosAlterados={this.carregarDetalhes}
            permitirEdicao={permitirEdicao}
            sistemaReferencia={composicao.sistemaReferencia}
          />

          <MateriaisComposicao
            insumos={detalhes.insumos}
            codigoComposicao={composicao.codigo}
            onDadosAlterados={this.carregarDetalhes}
            permitirEdicao={permitirEdicao}
          />

          <AtividadesAuxiliaresComposicao
            composicoesAuxiliares={detalhes.composicoesAuxiliares}
            codigoComposicao={composicao.codigo}
            onDadosAlterados={this.carregarDetalhes}
            detalharComposicao={(composicao) =>
              this.setState({ composicaoAuxiliarVisualizacao: composicao })
            }
            permitirEdicao={permitirEdicao}
            subtotal={detalhes.subtotal}
          />

          <TemposFixosComposicao
            composicoesAuxiliares={detalhes.composicoesAuxiliares}
            codigoComposicao={composicao.codigo}
            onDadosAlterados={this.carregarDetalhes}
            detalharComposicao={(composicao) =>
              this.setState({ composicaoAuxiliarVisualizacao: composicao })
            }
            permitirEdicao={permitirEdicao}
          />

          <TransportesComposicao
            composicoesAuxiliares={detalhes.composicoesAuxiliares}
            permitirEdicao={permitirEdicao}
            codigoComposicao={composicao.codigo}
            detalharComposicao={(composicao) =>
              this.setState({ composicaoAuxiliarVisualizacao: composicao })
            }
            onDadosAlterados={this.carregarDetalhes}
          />

          <Divider className={classes.divider} />

          <ResumoCustoTotal
            titulo="Custo unitário direto total"
            valorCusto={detalhes.custoUnitarioDiretoTotal}
          />
          <ResumoCustoTotal
            titulo={`BDI   (${!_.isNil(itemCurvaAbc) ? itemCurvaAbc?.bdi : 0}%)`}
            valorCusto={valorBDI}
          />
          <ResumoCustoTotal
            titulo="Preço Unitário Total"
            valorCusto={valorBDI + detalhes.custoUnitarioDiretoTotal}
          />
          <Divider className={classes.divider} />

          <ModalComposicaoAuxiliar
            composicao={composicaoAuxiliarVisualizacao}
            fechar={this.fecharDetalhamentoComposicaoAuxiliar}
            onAtualizarItemCurvaABCComposicaoDuplicada={
              onAtualizarItemCurvaABCComposicaoDuplicada
            }
          />
        </Box>
      )
    )
  }
}

export default connect(mapStateToPropsContexto)(
  withStyles(styles)(RelatorioComposicao)
)
