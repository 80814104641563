import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContenText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

import { dismissMessage } from '../../actions/messagesActions'

const styles = {
  dialogPaperClassName: {
    borderRadius: '2%',
    outline: 'none'
  },
  dialogBackdropClassName: {
    backgroundColor: 'rgba(255, 255, 255, 0.65)'
  },
  dialogItem: {
    paddingTop: 12
  },
  button: {
    padding: 12
  }
}

export class ForbiddenAccess extends Component {
  static propTypes = {
    dismissMessage: PropTypes.func,
    classes: PropTypes.object,
    history: PropTypes.object
  }

  componentWillUnmount () {
    this.props.dismissMessage()
  }

  render () {
    const { classes, history } = this.props
    return (
      <main>
        <Dialog open
          BackdropProps={{ className: classes.dialogBackdropClassName }}
          PaperProps={{ className: classes.dialogPaperClassName, elevation: 2 }}>
          <DialogTitle disableTypography>
            <Typography variant='h4' color='error'>
              Acesso negado
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContenText component='div'>
              <Typography variant='h6' style={{ maxWidth: 500 }}>
                Você não tem permissão para acessar este recurso, solicite ao responsável, por favor.
              </Typography>
            </DialogContenText>
            <DialogContenText component='div'>
              <Typography variant='subtitle1' className={classes.dialogItem}>
                Entre em contato através do 0800 644 1500 se o problema persistir.
              </Typography>
            </DialogContenText>
          </DialogContent>
          <DialogActions>
            <Button className={classes.button} onClick={() => history.push('/')} color='primary'>
              Voltar
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    )
  }
}

const materialUIEnhanced = withStyles(styles)(ForbiddenAccess)

const mapStateToProps = ({ messages }) => ({ messages })

const reduxEnhanced = connect(mapStateToProps, { dismissMessage })(materialUIEnhanced)

export default withRouter(reduxEnhanced)
