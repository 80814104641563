import React, { Component } from 'react'
import {IconButton, TableCell} from '@material-ui/core/'
import TurnedInIcon from '@material-ui/icons/TurnedIn'
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { context_httpput } from '../../../../../js/httpRequest'
import { showSuccessMsg } from '../../../../../js/util'

export class ToggleIncluirNaAmostraCurvaABC extends Component {
  static propTypes = {
    contexto: PropTypes.object,
    itemCurvaAbc: PropTypes.object.isRequired,
    exibir: PropTypes.bool.isRequired,
    incluirNaAmostraCurvaAbc: PropTypes.func,
    onItemAlterado: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.turnOn = this.turnOn.bind(this)
    this.turnOff = this.turnOff.bind(this)
  }

  async editarIncluirAmostra (incluirNaAmostra) {
    const {
      contexto, itemCurvaAbc, onItemAlterado
    } = this.props
    const result = await context_httpput(`curva-abc/itens/${itemCurvaAbc.id}/incluir-na-amostra`, contexto)
    if (result) {
      showSuccessMsg('Incluir na amostra alterado com sucesso')
      onItemAlterado(itemCurvaAbc, { ...itemCurvaAbc, incluirNaAmostra })
    }
  }

  turnOn() {
    this.editarIncluirAmostra(true)
  }

  turnOff() {
    this.editarIncluirAmostra(false)
  }

  render () {
    const { itemCurvaAbc, exibir } = this.props
    return exibir && (
      <TableCell align='center'>
        {
          itemCurvaAbc.incluirNaAmostra
            ? <IconButton size='small' title='Remover da amostra' color='primary' onClick={this.turnOff}><TurnedInIcon/></IconButton>
            : <IconButton size='small' title='Incluir na amostra' onClick={this.turnOn}><TurnedInNotIcon /></IconButton>
        }
      </TableCell>
    )
  }
}

export const mapStateToProps = ({ contexto }) => ({ contexto })

export default connect(mapStateToProps)(ToggleIncluirNaAmostraCurvaABC)
