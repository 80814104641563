import React, { Fragment } from 'react'
import Alert from '@material-ui/lab/Alert'
import { withRouter } from 'react-router-dom'
import ModalConfirmacao from '../../../comum/ModalConfirmacao'

const textoPossiveisErros = (numeroErros) => (
  `${numeroErros === 0 ? 'não há' : `ainda há ${numeroErros}`} ${numeroErros === 1 ? 'possível erro pendente' : ' possíveis erros pendentes'}
  de tratamento. O tratamento de possíveis erros estará inativo após confirmação.`
)

export default withRouter(({
  aberto, fechar, confirmar, numeroErros
}) => {
  if (numeroErros === undefined || numeroErros === null) {
    return null
  }
  return aberto &&
    <ModalConfirmacao
      open={aberto}
      onClose={fechar}
      confirmar={confirmar}
      titulo={
        <Fragment>
          Concluir Tratamento de Possíveis Erros
        </Fragment>
      }>
      <Alert severity="warning">{textoPossiveisErros(numeroErros)}</Alert>
    </ModalConfirmacao>
})
