import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { connect } from 'react-redux'
import { getTipoCombinacaoTipologia, METODO_ANALISE_EXPRESSAO, seStringVazia, showErrorMsg, showSuccessMsg } from '../../../../../../../js/util'
import { context_httpget, context_httppost } from '../../../../../../../js/httpRequest'
import TabelaCombinarTipologias from './TabelaCombinarTipologias'
import PaperBox from '../../../../../../comum/PaperBox'
import _ from 'lodash'
import { PERSONALIZADA, TEMATICA } from '../../../analise-conteudo'

const CombinarTipologia = ({
  contexto,
  combinadaAtiva,
  tipoAnaliseSelecionada,
  onFecharDrawer,
  onRecarregarTipologiaCombinadas
}) => {
  const [nome, setNome] = useState('')
  const [nomeEmpty, setNomeEmpty] = useState(false)
  const [descricao, setDescricao] = useState('')
  const [tipologiasPersonalizadas, setTipologiasPersonalizadas] = useState([])
  const [tipologiasSelecionadasParaCombinacao, setTipologiasSelecionadasParaCombinacao] = useState([])

  useEffect(() => {
    const obterTipologiasPersonalizadas = async () => {
      return context_httpget('analise-conteudo/tipologias/personalizadas', contexto)
    }
    if (contexto && tipoAnaliseSelecionada === PERSONALIZADA) {
      obterTipologiasPersonalizadas().then((tipologiasDisponiveis) => {
        if (combinadaAtiva) {
          copiarDadosCombinadaParaEstado(tipologiasDisponiveis)
        } else {
          setTipologiasPersonalizadas(tipologiasDisponiveis)
        }
      })
    } else {
      copiarDadosCombinadaParaEstado([]) // Temática
    }
  }, [])

  const copiarDadosCombinadaParaEstado = (tipologiasDisponiveis) => {
    setNome(combinadaAtiva.nome)
    setDescricao(combinadaAtiva.descricao)
    const tipologias = [
      ...tipologiasPersonalizadas,
      ...tipologiasDisponiveis,
      ...[
        copiarTipologia(combinadaAtiva.primeiraTipologia, combinadaAtiva.resultadoPrimeiraTipologia),
        copiarTipologia(combinadaAtiva.segundaTipologia, combinadaAtiva.resultadoSegundaTipologia)
      ]
    ]
    setTipologiasPersonalizadas(_.sortBy(tipologias, 'nome'))
    setTipologiasSelecionadasParaCombinacao([
      ...[
        copiarTipologia(combinadaAtiva.primeiraTipologia, combinadaAtiva.resultadoPrimeiraTipologia),
        copiarTipologia(combinadaAtiva.segundaTipologia, combinadaAtiva.resultadoSegundaTipologia)
      ]
    ])
  }

  const copiarTipologia = (tipologiaCombinada, resultadaTipologia) => {
    const primeiraTipologiaIsExpressao = resultadaTipologia.tipoTipologia === METODO_ANALISE_EXPRESSAO
    return {
      codigo: primeiraTipologiaIsExpressao ? tipologiaCombinada.codigoExpressao : tipologiaCombinada.codigoFormula,
      nome: tipologiaCombinada.nome,
      descricao: tipologiaCombinada.descricao,
      tipo: resultadaTipologia.tipoTipologia
    }
  }

  const combinar = async () => {
    validarFormulario()
    const combinacao = {
      codigo: _.isNil(combinadaAtiva) ? null : combinadaAtiva.codigo,
      nome,
      descricao,
      codTema: tipoAnaliseSelecionada === TEMATICA ? combinadaAtiva.codTema : null,
      tipologias: tipologiasSelecionadasParaCombinacao
    }
    const resultado = await context_httppost(`analise-conteudo/tipologias-combinadas?tipoAnalise=${tipoAnaliseSelecionada}`, contexto, combinacao)
    if (resultado) {
      showSuccessMsg('Tipologias combinadas com sucesso')
      onRecarregarTipologiaCombinadas()
      onFecharDrawer()
    }
  }

  const validarFormulario = () => {
    if (seStringVazia(nome)) {
      setNomeEmpty(true)
      throw 'Formulário inválido. Campo obrigatório não preenchido'
    }

    if (tipologiasSelecionadasParaCombinacao.length < 2) {
      const mensagem = 'É necessário selecionar duas tipologias para fazer a combinação'
      showErrorMsg(mensagem)
      throw mensagem
    }

    if (tipologiasSelecionadasParaCombinacao.length > 2) {
      const mensagem = 'Não é possível combinar mais de uma tipologia'
      showErrorMsg(mensagem)
      throw mensagem
    }
  }

  const handleSelecionarTipologia = (e, tipologia) => {
    if (e.target.checked) {
      setTipologiasSelecionadasParaCombinacao([
        ...tipologiasSelecionadasParaCombinacao,
        { codigo: tipologia.codigo, tipo: getTipoCombinacaoTipologia(tipologia.tipo) }
      ])
    } else {
      setTipologiasSelecionadasParaCombinacao(tipologiasSelecionadasParaCombinacao.filter(it => it.codigo !== tipologia.codigo))
    }
  }

  return (
    <React.Fragment>
      <Box my={2}>
        <Typography variant='h6'>Combinar tipologias neste orçamento</Typography>
      </Box>
      <Box my={2}>
        <Typography gutterBottom>Nome</Typography>
        <TextField
          name='nome'
          variant='outlined'
          fullWidth
          value={nome}
          error={nomeEmpty}
          onChange={(e) => setNome(e.target.value)}
        />
      </Box>
      <Box my={2}>
        <Typography gutterBottom>Descrição</Typography>
        <TextField
          name='descricao'
          variant='outlined'
          fullWidth
          multiline
          rows={3}
          value={descricao}
          onChange={(e) => setDescricao(e.target.value)}
          placeholder={'Descreva a finalidade desta tipologia combinada e o que ela verifica'}
          inputProps={{ maxLength: 500, 'aria-label': 'naked' }}
        />
      </Box>
      <PaperBox style={{ marginTop: 25 }}>
        <Box display='flex' alignItems='baseline' mb={5}>
          <Typography variant='h6' style={{ marginRight: 5 }}>
            Tipologias
          </Typography>
          <Typography variant='subtitle1'>
            - Marque duas tipologias que deseja combinar
          </Typography>
        </Box>
        <TabelaCombinarTipologias
          tipologiasPersonalizadas={tipologiasPersonalizadas}
          tipologiasSelecionadas={tipologiasSelecionadasParaCombinacao}
          onSelecionarTipologia={handleSelecionarTipologia}
        />
      </PaperBox>
      <Box display='flex' justifyContent='flex-end' mt={3}>
        <Button variant='outlined' color='primary' onClick={onFecharDrawer} style={{ marginRight: 10 }}>
          Cancelar
        </Button>
        <Button color='primary' variant='contained' onClick={combinar}>
          Combinar
        </Button>
      </Box>
    </React.Fragment>
  )
}

CombinarTipologia.propTypes = {
  contexto: PropTypes.object,
  combinadaAtiva: PropTypes.object,
  tipoAnaliseSelecionada: PropTypes.string,
  onFecharDrawer: PropTypes.func.isRequired,
  onRecarregarTipologiaCombinadas: PropTypes.func.isRequired
}
const mapStateToProps = ({ contexto }) => ({ contexto })
export default connect(mapStateToProps)(CombinarTipologia)
