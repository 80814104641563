import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import _ from 'lodash'
import {
  CRITERIO_COMPARACAO_NAO_PODE_SER,
  criterioComparacao,
  PERSONALIZADA,
  TIPO_COMPARACAO_ENTRE,
  TIPO_COMPARACAO_SUPERIOR_A,
  TIPO_MEDIDA_DISPERSAO_DESVIO_PADRAO,
  TIPO_MEDIDA_DISPERSAO_SELECIONE,
  tipoComparacao,
  TIPOLOGIA_FORMULA_PERSONALIZADA,
  TIPOLOGIA_FORMULA_TEMATICA,
  TIPOLOGIA_INCIDENCIA_PERCENTUAL,
  tipoMedidaDispersao
} from '../../../../analise-conteudo'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import styles from '../../../../styles'
import { withRouter } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import RegraTipologiaFormula from './RegraTipologiaFormula'
import { context_httpput } from '../../../../../../../../js/httpRequest'
import { seStringVazia, showAlertMsg, showSuccessMsg } from '../../../../../../../../js/util'

class ModalCriarTipologiaFormula extends Component {
  static propTypes = {
    formula: PropTypes.object,
    isModeReadOnly: PropTypes.bool,
    onToggleModal: PropTypes.func.isRequired,
    tipoAnaliseSelecionada: PropTypes.string.isRequired,
    contexto: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object,
    onChangeTipologia: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)
    this.state = {
      valorComparacao: '',
      valorComparacaoEmpty: false,
      valorComparacaoEntre: '',
      valorComparacaoEntreEmpty: false,
      valorMedidaDispersao: '',
      valorMedidaDispersaoEmpty: false,
      nome: '',
      nomeEmpty: false,
      descricao: '',
      descricaoEmpty: false,
      observacao: '',
      variavelAnalise: TIPOLOGIA_INCIDENCIA_PERCENTUAL,
      criterioComparacao: CRITERIO_COMPARACAO_NAO_PODE_SER,
      tipoComparacao: TIPO_COMPARACAO_SUPERIOR_A,
      tipoMedidaDispersao: TIPO_MEDIDA_DISPERSAO_SELECIONE
    }
    this.handleValidarCampoEntre = this.handleValidarCampoEntre.bind(this)
    this.handleCampoTipologiaChange = this.handleCampoTipologiaChange.bind(this)
    this.handleCampoTipoComparacaoChange = this.handleCampoTipoComparacaoChange.bind(this)
  }

  componentDidMount () {
    if(this.props.formula) {
      this.copiarDadosFormulaParaEstado()
    }
  }

  componentDidUpdate (prevProps) {
    if(prevProps.formula !== this.props.formula) {
      this.copiarDadosFormulaParaEstado()
    }
  }

  copiarDadosFormulaParaEstado() {
    const { formula } = this.props
    this.setState({
      criterioComparacao: criterioComparacao(formula.tipologia),
      tipoComparacao: tipoComparacao(formula.tipologia),
      valorComparacao: formula.tipologia ? formula.tipologia.valorComparacao.toString().replace('.', ',') : '0',
      nome: formula.tipologia ? formula.tipologia.nome : `Verificação de ${formula.nome}`,
      descricao: formula.tipologia ? formula.tipologia.descricao : formula.descricao,
      observacao: formula.tipologia ? formula.tipologia.observacao : formula.observacao,
      tipoMedidaDispersao: tipoMedidaDispersao(formula.tipologia),
      valorComparacaoEntre: !_.isNil(formula.tipologia) && !_.isNil(formula.tipologia.valorComparacaoEntre) ? formula.tipologia.valorComparacaoEntre.toString().replace('.', ',') : '0',
      valorMedidaDispersao: !_.isNil(formula.tipologia) && !_.isNil(formula.tipologia.valorMedidaDispersao) ? formula.tipologia.valorMedidaDispersao.toString().replace('.', ',') : '0',
    })
  }

  handleCampoTipologiaChange(event, campo) {
    const valorCampo = event.target.value
    if(!_.isNil(valorCampo)) {
      const state = {}
      state[campo] = valorCampo
      state[`${campo}Empty`] = _.isEmpty(valorCampo)
      this.setState(state)
    }
  }

  handleCampoChange(event, campo) {
    const state = {}
    state[campo] = event.target.value
    state[`${campo}Empty`] = seStringVazia(event.target.value)
    this.setState(state)
  }

  handleCampoTipoComparacaoChange(event, campo) {
    const state = {}
    state[campo] = event.target.value
    if(event.target.value !== TIPO_COMPARACAO_ENTRE.value){
      state['valorComparacaoEntre'] = '0'
      state['valorMedidaDispersao'] = '0'
      state['tipoMedidaDispersao'] = TIPO_MEDIDA_DISPERSAO_SELECIONE
    }
    this.setState(state)
  }

  async handleConfirmar() {
    const { formula, contexto, onChangeTipologia } = this.props
    this.validarFormulario()
    const tipologia = {
      nome: this.state.nome,
      descricao: this.state.descricao,
      observacao: this.state.observacao,
      criterioComparacao: this.state.criterioComparacao.value,
      tipoComparacao: this.state.tipoComparacao.value,
      valorComparacao: this.state.valorComparacao.replace(',', '.'),
      valorComparacaoEntre: this.state.valorComparacaoEntre.replace(',', '.')
    }
    if(!_.isNil(this.state.tipoMedidaDispersao.value)) {
      tipologia.tipoMedidaDispersao = this.state.tipoMedidaDispersao.value
      tipologia.valorMedidaDispersao = this.state.valorMedidaDispersao.replace(',', '.')
    }
    const resultado = await context_httpput(`analise-conteudo/formulas/${formula.codigo}/tipologia?tipoAnalise=${this.definirTipoTipologia()}`, contexto, tipologia)
    if (resultado) {
      showSuccessMsg('Dados registrados')
      onChangeTipologia(false, resultado)
    }
  }

  definirTipoTipologia = () => this.props.tipoAnaliseSelecionada === PERSONALIZADA ? TIPOLOGIA_FORMULA_PERSONALIZADA : TIPOLOGIA_FORMULA_TEMATICA

  validarFormulario() {
    const { valorComparacao, valorComparacaoEntre, nome, descricao, tipoComparacao, valorMedidaDispersao, tipoMedidaDispersao } = this.state
    this.validarParametroDeRegraEntre()
    const valorComparacaoEmpty = seStringVazia(valorComparacao)
    const valorComparacaoEntreEmpty = seStringVazia(valorComparacaoEntre) && tipoComparacao === TIPO_COMPARACAO_ENTRE
    const nomeEmpty = seStringVazia(nome)
    const descricaoEmpty = seStringVazia(descricao)
    const valorMedidaDispersaoEmpty = seStringVazia(valorMedidaDispersao) && tipoMedidaDispersao === TIPO_MEDIDA_DISPERSAO_DESVIO_PADRAO
    if (valorComparacaoEmpty || nomeEmpty || descricaoEmpty || valorComparacaoEntreEmpty || valorMedidaDispersaoEmpty) {
      this.setState({
        valorComparacaoEmpty,
        valorComparacaoEntreEmpty,
        valorMedidaDispersaoEmpty,
        nomeEmpty,
        descricaoEmpty
      })
      throw 'Formulário inválido. Campos obrigatórios não preenchidos'
    }
  }

  validarParametroDeRegraEntre() {
    const { valorComparacao, valorComparacaoEntre, tipoComparacao } = this.state
    if(tipoComparacao === TIPO_COMPARACAO_ENTRE && Number(valorComparacao) > Number(valorComparacaoEntre)) {
      this.setState({ valorComparacaoEmpty: true })
      showAlertMsg('Os valores informados para comparação estão inválidos. O primeiro valor deve ser menor ou igual ao segundo valor.')
      throw 'Formulário inválido'
    }
  }

  handleValidarCampoEntre() {
    const { valorComparacao, valorComparacaoEntre, tipoComparacao } = this.state
    if(tipoComparacao === TIPO_COMPARACAO_ENTRE && Number(valorComparacao) > Number(valorComparacaoEntre)) {
      this.setState({ valorComparacaoEmpty: true })
    } else {
      this.setState({ valorComparacaoEmpty: false })
    }
  }

  render () {
    const { formula, onToggleModal, isModeReadOnly } = this.props
    const {
      nome,
      nomeEmpty,
      descricao,
      descricaoEmpty,
      observacao,
      criterioComparacao,
      tipoComparacao,
      valorComparacao,
      valorComparacaoEmpty,
      valorComparacaoEntre,
      valorComparacaoEntreEmpty,
      tipoMedidaDispersao,
      valorMedidaDispersao,
      valorMedidaDispersaoEmpty
    } = this.state
    return (
      <Dialog open={Boolean(formula)} maxWidth='lg' fullWidth>
        <DialogTitle>
          {_.isNil(formula.tipologia) ? 'Criar Tipologia' : 'Alterar Tipologia'}
        </DialogTitle>
        <DialogContent>
          <Box my={2}>
            <Typography variant='overline'><strong>Fórmula:</strong> { formula.nome }</Typography>
          </Box>
          <Box my={2}>
            <FormControl fullWidth>
              <Typography gutterBottom>Nome da tipologia: </Typography>
              <TextField
                variant='outlined'
                value={nome || ''}
                error={nomeEmpty}
                onChange={(e) => this.handleCampoChange(e, 'nome')}
              />
            </FormControl>
          </Box>
          <Box my={2}>
            <Typography gutterBottom>Descrição: </Typography>
            <TextField
              variant='outlined'
              multiline
              rows={3}
              value={descricao || ''}
              error={descricaoEmpty}
              fullWidth
              inputProps={{ maxLength: 1000, 'aria-label': 'naked' }}
              onChange={(e) => this.handleCampoChange(e, 'descricao')}
            />
          </Box>
          <Box my={2}>
            <FormControl fullWidth>
              <Typography gutterBottom>Observação: </Typography>
              <TextField
                variant='outlined'
                multiline
                rows={3}
                value={observacao || ''}
                inputProps={{ maxLength: 2000, 'aria-label': 'naked' }}
                onChange={(e) => this.handleCampoChange(e, 'observacao')}
              />
            </FormControl>
          </Box>

          <RegraTipologiaFormula
            criterioComparacao={criterioComparacao}
            tipoComparacao={tipoComparacao}
            valorComparacao={valorComparacao}
            valorComparacaoEmpty={valorComparacaoEmpty}
            valorComparacaoEntre={valorComparacaoEntre}
            valorComparacaoEntreEmpty={valorComparacaoEntreEmpty}
            tipoMedidaDispersao={tipoMedidaDispersao}
            valorMedidaDispersao={valorMedidaDispersao}
            valorMedidaDispersaoEmpty={valorMedidaDispersaoEmpty}
            onCampoChange={this.handleCampoTipologiaChange}
            onCampoTipoComparacaoChange={this.handleCampoTipoComparacaoChange}
            onValidarCampoEntre={this.handleValidarCampoEntre}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onToggleModal(false)}>Cancelar</Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => this.handleConfirmar()} disabled={isModeReadOnly}>Confirmar</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ contexto }) => ({ contexto })

export default connect(mapStateToProps)(withRouter((withStyles(styles)(ModalCriarTipologiaFormula))))
