import React, { Component } from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { Box, FormControl, withStyles } from '@material-ui/core'
import styles from '../../../styles'
import { connect } from 'react-redux'
import _ from 'lodash'

class CampoUnidades extends Component {
  static propTypes = {
    unidade: PropTypes.object,
    unidadeEmpty: PropTypes.bool,
    onUnidadeChange: PropTypes.func.isRequired,
    classes: PropTypes.object,
    unidades: PropTypes.array,
    unidadeUsuarioLogado: PropTypes.object,
    unidadeUsuarioLogadoPreSelecionada: PropTypes.bool
  }

  componentDidMount () {
    const { unidadeUsuarioLogado, unidadeUsuarioLogadoPreSelecionada } = this.props
    if((unidadeUsuarioLogadoPreSelecionada && this.props.unidade == null) || this.props.unidade != null) {
      this.props.onUnidadeChange(unidadeUsuarioLogado)
    }
  }

  componentDidUpdate(prevProps) {
    const { unidades, unidadeUsuarioLogadoPreSelecionada, unidadeUsuarioLogado } = this.props
    if((unidadeUsuarioLogadoPreSelecionada && this.props.unidade == null) || this.props.unidade != null) {
      this.props.onUnidadeChange(unidadeUsuarioLogado)
    }
    if(this.props.unidade != null && (prevProps.unidade !== this.props.unidade)) {
      this.props.onUnidadeChange(unidades.filter(option => option.cod === this.props.unidade.cod)[0])
    }
  }

  render() {
    const {
      unidade,
      unidadeEmpty,
      classes,
      onUnidadeChange,
      unidades
    } = this.props

    return(
      <Box mt={1}>
        <FormControl variant='outlined'>
          <Typography variant='subtitle1'>Unidades</Typography>
          <Select
            name={'unidade'}
            className={classes.comboExpressao}
            error={unidadeEmpty}
            value={unidade || ''}
            onChange={(e) => onUnidadeChange(e.target.value)}>
            {
              unidades.map((it) => (
                <MenuItem key={it.cod} value={it}>
                  <Typography>{it.sigla} - {it.nome}</Typography>
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Box>
    )
  }
}

const mapStateToProps = (state) => ({
  unidades: _.isNil(state.tiposUnidade.unidades) ? [] : state.tiposUnidade.unidades,
  unidadeUsuarioLogado: state.tiposUnidade.unidadeUsuarioLogado
})

export default connect(mapStateToProps)(withStyles(styles)(CampoUnidades))
