import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { withRouter } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography/Typography'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'
import Template from '../layout/Template'
import buscarOrcamentos from '../../actions/orcamentos/buscarOrcamentos'
import excluirOrcamento from '../../actions/orcamentos/excluirOrcamento'
import duplicarOrcamento from '../../actions/orcamentos/duplicarOrcamento'
import ListaOrcamentos from './ListaOrcamentos'
import { buscarPalavrasEmTexto, showErrorMsg, showSuccessMsg } from '../../js/util'
import Buscar from '../comum/Buscar'
import Adicionar from '../comum/Adicionar'
import ModalExcluirOrcamento from './ModalExcluirOrcamento'
import ModalDuplicarOrcamento from './ModalDuplicarOrcamento'
import PropTypes from 'prop-types'
import CadastrarOrcamento from './cadastrar/CadastrarOrcamento'
import Carregando from '../comum/Carregando'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'

const styles = (theme) => ({
  empreendimento: {
    margin: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    marginTop: 0
  },
  obra: {
    margin: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    marginTop: 0
  },
  orcamentos: {
    margin: theme.spacing(2)
  }
})

export class Orcamentos extends Component {
  static propTypes = {
    match: PropTypes.object,
    orcamentos: PropTypes.array,
    contexto: PropTypes.object,
    buscarOrcamentos: PropTypes.func,
    history: PropTypes.array,
    excluirOrcamento: PropTypes.func,
    duplicarOrcamento: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.state = {
      empreendimento: {},
      obra: {},
      orcamentos: [],
      orcamentosBusca: [],
      pagina: 0,
      registrosPorPagina: 10,
      termoBusca: '',
      orcamentoExclusao: null,
      orcamentoDuplicado: null,
      cadastrando: false
    }
    this.editar = this.editar.bind(this)
    this.excluir = this.excluir.bind(this)
    this.duplicar = this.duplicar.bind(this)
    this.fecharModalExcluir = this.fecharModalExcluir.bind(this)
    this.fecharModalDuplicar = this.fecharModalDuplicar.bind(this)
    this.orcamentoExcluido = this.orcamentoExcluido.bind(this)
    this.orcamentoDuplicado = this.orcamentoDuplicado.bind(this)
  }

  componentDidMount() {
    this.buscarOrcamentos()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match !== this.props.match) {
      this.buscarOrcamentos()
    }

    if (prevProps.orcamentos !== this.props.orcamentos) {
      this.setState({
        orcamentosBusca: this.props.orcamentos
      })
    }

    if (prevState.termoBusca !== this.state.termoBusca) {
      this.buscar()
    }

    if (prevProps.contexto !== this.props.contexto) {
      this.contexto()
    }
  }

  buscar() {
    const texto = this.state.termoBusca
    const { orcamentos } = this.props

    if (_.isEmpty(texto)) {
      this.setState({ orcamentosBusca: orcamentos })
      return
    }

    const palavras = _.words(texto.toUpperCase())

    this.setState({
      orcamentosBusca: orcamentos.filter((orcamento) => buscarPalavrasEmTexto(orcamento.descricao.toUpperCase(), palavras))
    })
  }

  async buscarOrcamentos() {
    const { match, buscarOrcamentos = (idEmpreendimento, idObra)=>{} } = this.props
    buscarOrcamentos(match?.params?.idEmpreendimento, match?.params?.idObra)
  }

  contexto() {
    const { contexto } = this.props
    this.setState({
      ...contexto,
      mostrarBreadCrumb: true
    })
  }

  editar(orcamento) {
    this.props.history.push(`${this.props.match.url}/${orcamento.cod}`)
  }

  excluir(orcamento) {
    this.setState({
      orcamentoExclusao: orcamento
    })
  }

  fecharModalExcluir() {
    this.setState({
      orcamentoExclusao: null
    })
  }

  duplicar(orcamento) {
    this.setState({
      orcamentoDuplicado: orcamento
    })
  }

  fecharModalDuplicar() {
    this.setState({
      orcamentoDuplicado: null
    })
  }

  async orcamentoDuplicado(orcamento) {
    const { idEmpreendimento, idObra } = this.props.match.params
    await axios.post(`/api/empreendimentos/${idEmpreendimento}/obras/${idObra}/orcamentos/${
      orcamento.cod}/copiar`, SLOW_REQUEST_CONFIG)
      .then(() => {
        showSuccessMsg('Orçamento duplicado com sucesso.')
        this.props.duplicarOrcamento(orcamento)
        this.buscarOrcamentos()
      })
      .catch(() => showErrorMsg('Erro ao duplicar orçamento.'))
    this.fecharModalDuplicar()
  }

  async orcamentoExcluido() {
    const { idEmpreendimento, idObra } = this.props.match.params
    await axios.delete(
      `/api/empreendimentos/${idEmpreendimento}/obras/${idObra}/orcamentos/${this.state.orcamentoExclusao.cod}`,
      SLOW_REQUEST_CONFIG
    )
    this.props.excluirOrcamento(this.state.orcamentoExclusao)
    showSuccessMsg(`Orçamento ${this.state.orcamentoExclusao.descricao} excluído com sucesso.`)
    this.fecharModalExcluir()
  }

  render() {
    const {
      empreendimento, obra, orcamentosBusca, pagina, registrosPorPagina, orcamentoExclusao, orcamentoDuplicado, cadastrando
    } = this.state

    if (!empreendimento || !empreendimento.cod || !obra || !obra.cod) {
      return <Carregando />
    }

    if (cadastrando) {
      return <CadastrarOrcamento />
    }

    const { orcamentos } = this.props
    return (
      <Template>
        <Typography variant='h6'>
          Orçamentos
        </Typography>
        <Box display='flex' justifyContent='space-between' my={2}>
          <Buscar
            invertido={false}
            placeholder='Buscar orçamento'
            onChange={(e) => this.setState({ termoBusca: e.target.value })}
          />
          <Adicionar
            label='Cadastrar orçamento'
            onClick={() => this.setState({ cadastrando: true })}
          />
        </Box>

        {
          _.isEmpty(orcamentos)
            ? (<Box mt={3}><Alert severity="info">A obra selecionada não possui orçamentos cadastrados.</Alert></Box>)
            : <ListaOrcamentos
              codEmpreendimento={empreendimento.cod}
              orcamentos={orcamentosBusca}
              pagina={pagina}
              registrosPorPagina={registrosPorPagina}
              duplicar={this.duplicar}
              editar={this.editar}
              excluir={this.excluir}
              onChangePage={(e, pag) => { this.setState({ pagina: pag }) }}
              onChangeRowsPerPage={(e) => (this.setState({ registrosPorPagina: e.target.value }))}
            />
        }

        <ModalExcluirOrcamento
          orcamento={orcamentoExclusao}
          fechar={this.fecharModalExcluir}
          confirmar={this.orcamentoExcluido}
        />
        <ModalDuplicarOrcamento
          orcamento={orcamentoDuplicado}
          fechar={this.fecharModalDuplicar}
          confirmar={this.orcamentoDuplicado}
        />
      </Template>
    )
  }
}

const mapStateToProps = (state) => ({
  contexto: state.contexto,
  orcamentos: state.orcamentos
})

export default connect(
  mapStateToProps, {
    buscarOrcamentos,
    excluirOrcamento,
    duplicarOrcamento
}
)(withStyles(styles)(withRouter(Orcamentos)))
