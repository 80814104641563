import React from 'react'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import styles from '../../styles'
import { withStyles } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'

const CampoBdiGestor = ({
  classes,
  bdi,
  bdiEmpty,
  onBdiChange
}) => {
  return (
    <FormControl variant='outlined'>
      <Typography variant='subtitle1'>BDI utilizado pelo gestor (%)</Typography>
      <TextField
        maxLength='7'
        variant='outlined'
        className={classes.campoFormulario}
        value={bdi || ''}
        onChange={onBdiChange}
        error={bdiEmpty}
      />
    </FormControl>
  )
}

CampoBdiGestor.propTypes = {
  classes: PropTypes.object,
  bdi: PropTypes.string,
  bdiEmpty: PropTypes.bool,
  onBdiChange: PropTypes.func.isRequired
}

export default withStyles(styles)(CampoBdiGestor)
