import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { converterMoedaBRParaNumero, formatarNumeroParaMoedaBR, mapStateToPropsContexto, seStringVazia, showSuccessMsg } from '../../../../../../../../js/util'
import Box from '@material-ui/core/Box'
import TituloVoltar from '../../../../../../../comum/TituloVoltar'
import { context_httpput } from '../../../../../../../../js/httpRequest'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FormularioMaterialComposicao from './FormularioMaterialComposicao'

class EditarMaterialComposicao extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string.isRequired,
    materialComposicao: PropTypes.object.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired,
    contexto: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      quantidade: '',
      quantidadeEmpty: false,
      descricao: '',
      descricaoEmpty: false,
      unidade: '',
      unidadeEmpty: false,
      precoUnitario: '',
      precoUnitarioEmpty: false
    }
    this.handleSalvarClick = this.handleSalvarClick.bind(this)
    this.handleCampoTextoChange = this.handleCampoTextoChange.bind(this)
  }

  componentDidMount () {
    if (this.props.materialComposicao) {
      this.copiarDadosParaEdicao()
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.materialComposicao !== this.props.materialComposicao) {
      this.copiarDadosParaEdicao()
    }
  }

  copiarDadosParaEdicao() {
    const { materialComposicao } = this.props
    this.setState({
      descricao: materialComposicao.materialOrcamento.descricao,
      unidade: materialComposicao.materialOrcamento.unidade,
      quantidade: formatarNumeroParaMoedaBR(materialComposicao.quantidade, 7, 7),
      precoUnitario: formatarNumeroParaMoedaBR(materialComposicao.materialOrcamento.precoUnitario, 2, 5)
    })
  }

  handleCampoTextoChange (event, campo) {
    const state = {}
    state[campo] = event.target.value
    state[`${campo}Empty`] = seStringVazia(event.target.value)
    this.setState(state)
  }

  async handleSalvarClick () {
    const { quantidade, descricao, unidade, precoUnitario } = this.state
    const quantidadeEmpty = seStringVazia(quantidade)
    const descricaoEmpty = seStringVazia(descricao)
    const unidadeEmpty = seStringVazia(unidade)
    const precoUnitarioEmpty = seStringVazia(precoUnitario)

    if ([quantidadeEmpty, descricaoEmpty, unidadeEmpty, precoUnitarioEmpty].some(Boolean)) {
      this.setState({ quantidadeEmpty, descricaoEmpty, unidadeEmpty, precoUnitarioEmpty })
    } else {
      const dadosSalvar = {
        quantidade: converterMoedaBRParaNumero(quantidade),
        descricao,
        unidade,
        precoUnitario: converterMoedaBRParaNumero(precoUnitario)
      }
      const { contexto, codigoComposicao, onDadosAlterados, materialComposicao } = this.props
      const url = `precos/editar-material?composicao=${codigoComposicao}&material=${materialComposicao.materialOrcamento.codigo}`
      const resultado = await context_httpput(url, contexto, dadosSalvar)
      if (resultado) {
        showSuccessMsg('Dados do material na composição alterados com sucesso.')
        onDadosAlterados()
      }
    }
  }

  render () {
    const { materialComposicao, onCancelar } = this.props

    const {
      quantidade,
      quantidadeEmpty,
      descricao,
      descricaoEmpty,
      unidade,
      unidadeEmpty,
      precoUnitario,
      precoUnitarioEmpty
    } = this.state

    return (
      <div>
        <TituloVoltar onClick={onCancelar} label='Editar Material da Composição'/>

        <Box mt={2}>
          <Typography variant='overline'>
            { materialComposicao.materialOrcamento.codigo } - { materialComposicao.materialOrcamento.descricao }
          </Typography>
        </Box>

        <FormularioMaterialComposicao
          codigo={materialComposicao.materialOrcamento.codigo}
          codigoEmpty={false}
          codigoEditavel={false}
          descricao={descricao}
          descricaoEmpty={descricaoEmpty}
          unidade={unidade}
          unidadeEmpty={unidadeEmpty}
          quantidade={quantidade}
          quantidadeEmpty={quantidadeEmpty}
          precoUnitario={precoUnitario}
          precoUnitarioEmpty={precoUnitarioEmpty}
          handleCampoTextoChange={this.handleCampoTextoChange}
          onSalvar={this.handleSalvarClick}
          onCancelar={onCancelar}
        />
      </div>
    )
  }
}

export default connect(mapStateToPropsContexto)(EditarMaterialComposicao)
