import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import TituloVoltar from '../../../../../../../comum/TituloVoltar'
import FormularioAdicionarMaoDeObraAvulsa from './FormularioAdicionarMaoDeObraAvulsa'
import AdicionarMaoDeObraComposicaoSelecionarDoOrcamento from './AdicionarMaoDeObraComposicaoSelecionarDoOrcamento'
import AdicionarMaodeObraComposicaoPesquisarEmReferenciasDePrecos from './AdicionarMaodeObraComposicaoPesquisarEmReferenciasDePrecos'
import { connect } from 'react-redux'
import styles from '../../../styles'
import { withStyles } from '@material-ui/core'
import { mapStateToPropsContexto } from '../../../../../../../../js/util'
import AdicionarMaodeObraAvulsaPesquisarEmReferenciasDePrecos from './AdicionarMaodeObraAvulsaPesquisarEmReferenciasDePrecos'
 

const CRIAR = 2
const REFERENCIA_PRECO = 1

class AdicionarMaoDeObraAvulsa extends Component {
  static propTypes = {
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      modo: 2
    }
  }

  render() {
    const { onDadosAlterados, onCancelar } = this.props
    const { modo } = this.state

    return (
      <>
        <TituloVoltar onClick={onCancelar} label='Voltar para Mão de Obra'/>
        <Box my={2} ml={2}>
          <TextField variant='outlined' value={modo} select onChange={(e) => this.setState({ modo: e.target.value })} style={{minWidth: 500}}>
            
            <MenuItem value={CRIAR}>Criar nova mão de obra</MenuItem>
            <MenuItem value={REFERENCIA_PRECO}>Pesquisar em referências de preço</MenuItem>
            
          </TextField>
        </Box>

        {
          modo === CRIAR && (
            <FormularioAdicionarMaoDeObraAvulsa
              onCancelar={onCancelar}
              onDadosAlterados={onDadosAlterados}
            />
          )
        }
        {
          modo === REFERENCIA_PRECO && (
            <AdicionarMaodeObraAvulsaPesquisarEmReferenciasDePrecos
              onCancelar={onCancelar}
              onDadosAlterados={onDadosAlterados}
            />
          )
        }
      </>
    )
  }
}

export default connect(mapStateToPropsContexto)(withStyles(styles)(AdicionarMaoDeObraAvulsa))

