import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'

const styles = (theme) => ({
  textField: {
    margin: theme.spacing(1),
    marginLeft: 0,
    width: 200
  },
  small: {
    fontSize: '0.6rem'
  }
})

const TermoBusca = ({classes, value, onChange, onKeyPress}) => (
  <div className={classes.textField}>
    <Typography variant='subtitle2'  gutterBottom>Código ou descrição <small className={classes.small}>(opcional)</small></Typography>
    <TextField
      value={value}
      variant='outlined'
      onChange={onChange}
      onKeyPress={onKeyPress}
    />
  </div>
)

TermoBusca.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func
}

export default withStyles(styles)(TermoBusca)
