const styles = (theme) => ({
  headerHome: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  },
  tituloPrincipal: {
    margin: '32px 0 16px',
    width: '62.5%',
    display: 'inline-block'
  },
  cabecalho: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 16
  },
  tituloCabecalho: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  botoesPesquisa: {
    marginTop: 24,
    display: 'flex',
    gap: 16,
  },
  botaoHome: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase'

  },
  paperHome: {
    margin: '16px 0 32px'
  },
  titulo: {
    color: 'rgba(67,133,191,1)',
    fontWeight: 500,
    fontSize: '1.9rem',
    margin: '0 0 15px',
    display: 'flex',
  },
  desc: {
    fontSize: '1.5rem',
    fontWeight: 100,
    marginBottom: theme.spacing(2),
  },
  img: {
    maxWidth: 600,
    minWidth: 300,
  },
  cards: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit))'
  },
  cardAcessosRecentes: {
    borderRadius: 6,
    padding: 12,
    position: 'relative',
    justifyContent: 'space-between',
    flexDirection: 'column',
    display: 'flex',
    marginBottom: 8,
    marginRight: 16,
    minWidth: '100%',
    cursor: 'pointer',
    '&:hover':{
      transition: 'all 0.2s ease 0s',
      boxShadow: 'rgb(4 17 29 / 25%) 0px 0px 8px 0px',
      backgroundColor: 'rgb(251, 253, 255)',
      // border: 'none'
    }
  },
  tituloObra: {
    width: '80%'
  },
  cardFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'rgba(74,74,74,0.87)',
  },
  breadcrumb: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'baseline'
  },
  voltar: {
    marginRight: theme.spacing(1),
    cursor: 'pointer'
  },
  breadcrumbItem: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    '& li:last-child > a': {
     fontWeight: 500,
      textDecoration: 'none'
    }
  },
  inputPesquisaGeral: {
    width: '100%'
  },
  btnPesquisar: {
    padding: '9px 15px',
    marginRight: 16
  },
  tituloCard: {
    fontSize: 24,
    marginBottom: 4
  },
  itemDestaque: {
    backgroundColor: '#FFC700',
    width: 4,
    height: 15,
    display: 'block',
    float: 'left',
    margin: '4px 3px 0 0',
    borderRadius: 12
  }
})

export default styles
