import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import UnidadeJurisdicionadaAutoComplete from '../empreendimento/UnidadeJurisdicionadaAutoComplete'
import { connect, useDispatch, useSelector } from 'react-redux'
import buscarTiposObra from '../../actions/obras/buscarTiposObras'
import { Autocomplete } from '@material-ui/lab'
import { getAuthentication } from '../../security/securityContext'
import buscarUjs from '../../actions/uj/buscarUjs'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Badge from '@material-ui/core/Badge/Badge'
import CancelIcon from '@material-ui/icons/Cancel'
import { PERFIL } from '../../js/perfis'
import _ from 'lodash'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import useStyles from './styles'
import cadastrarObra from '../../actions/obras/cadastrarObra'
import alterarObra from '../../actions/obras/alterarObra'
import ModalConcederAcessoUsuario from './ModalConcederAcessoUsuario'

const ModalEditarAdicionarObra = ({ openDialog, onCancelar, onOpenDialog, empreendimento, obra }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const tiposObra = useSelector((state) => state.tiposObra)

  const [uj, setUj] = useState(null)
  const [tipoObra, setTipoObra] = useState(null)
  const [descricao, setDescricao] = useState(null)
  const [errorDescricao, setErrorDescricao] = useState(false)
  const [errorUj, setErrorUj] = useState(false)
  const [errorTipoObra, setErrorTipoObra] = useState(false)
  const [ujsUsuarioExterno, setUjsUsuarioExterno] = useState([])
  const [usuariosConcedidos, setUsuariosConcedidos] = useState([])
  const [modalConcederAcessoUsuario,setModalConcederAcessoUsuario] = useState(false)

  const setToInitialState = () => {
    setUj(null)
    setDescricao(null)
    setTipoObra(null)
    setErrorDescricao(false)
    setErrorUj(false)
    setErrorTipoObra(false)
    setUsuariosConcedidos([])
  }

  const recuperarCodUjsDePerfisExternos = () => {
      const idPerfis = getAuthentication().perfis.filter((it) => {
        if (it.length > 6 && it.includes('E')) return it
      })
    return idPerfis.map((it)=>{return it.split('E')[1]})
  }

  const usuarioGestorOuOperadorUJ = ()  => {
    const perfis = getAuthentication().perfis.filter((it) => {
      if (it.includes(PERFIL.GESTOR_UNID_JURISDICIONADA) || it.includes(PERFIL.OPERADOR) ) return it
    })
    return perfis.length > 0
  }

  function recuperarUjs (codujs) {
    if (codujs && codujs.length > 0 && isUsuarioExterno() && usuarioGestorOuOperadorUJ()) {
      buscarUjs(codujs).payload.then((ucods) => {
        setUjsUsuarioExterno(ucods.data)
      })
    } else {
      setUjsUsuarioExterno(undefined)
    }
  }

  useEffect(() => {
    dispatch(buscarTiposObra())
    if(ujsUsuarioExterno.length === 0){
      recuperarUjs(recuperarCodUjsDePerfisExternos())
    }
  }, [])

  useEffect(() => {
    if (obra && obra.uj) {
      setUj(obra.uj.id)
      setTipoObra(obra.tipoObra.id)
      setDescricao(obra.nome)
      setUsuariosConcedidos([...obra.usuariosConcedidos])
    }
  }, [obra])

  const isUsuarioExterno = () => getAuthentication().tipoUnidade === 'EXTERNO'

  const handleChangeUj = (uj) => {
    if(!_.isNil(uj)) {
      setUj(uj.id)
    } else {
      setUj(null)
    }
  }

  const validarCampoObrigatorios = () => {
    const msgErro = 'Campo obrigatório'
    if (_.isNil(uj) && _.isNil(tipoObra) && _.isNil(descricao)) {
      setErrorUj(true)
      setErrorTipoObra(true)
      setErrorDescricao(true)
    }

    _.isNil(uj) ? setErrorUj(true) : setErrorUj(false)
    _.isNil(tipoObra) ? setErrorTipoObra(true) : setErrorTipoObra(false)
    _.isNil(descricao) ? setErrorDescricao(true) : setErrorDescricao(false)

    if (_.isNil(uj) || _.isNil(tipoObra) || _.isNil(descricao))
      throw msgErro // eslint-disable-next-line no-throw-literal
  }

  const handleConfirmar = async () => {
    validarCampoObrigatorios()
    const body = { nome: descricao, codPessoaUJ: uj, idTipoObra: tipoObra, usuariosConcedidos }
    if(obra) {
      const { data } = await axios.put(`/api/empreendimentos/${empreendimento.cod}/obras/${obra.cod}`, body, SLOW_REQUEST_CONFIG)
      dispatch(alterarObra(data))
    } else {
      const { data } = await axios.post(`/api/empreendimentos/${empreendimento.cod}/obras`, body, SLOW_REQUEST_CONFIG)
      dispatch(cadastrarObra(data))
    }
    setToInitialState()
    onOpenDialog(false)
  }

  const removerUsuario = (usuario) => {
    const novosUsuariosConcedidos = usuariosConcedidos.filter((u) => u.login !== usuario.login)
    if (novosUsuariosConcedidos && novosUsuariosConcedidos.length > 0) {
      setUsuariosConcedidos( novosUsuariosConcedidos )
    } else {
      setUsuariosConcedidos( [] )
    }
  }

  const handleOnCompleteConcederAcesso = ({ login, dataExclusao }) => {
    
    usuariosConcedidos.push({ login, dataExclusao });
    
    let novo = [...usuariosConcedidos]
    setUsuariosConcedidos(novo)
  }

  return (
    <Dialog open={openDialog} fullWidth maxWidth="md">
      <DialogTitle>{obra ? 'Editar Obra' : 'Cadastrar Obra'}</DialogTitle>
      <DialogContent className={classes.content}>
        <Box mb={1}>
          <UnidadeJurisdicionadaAutoComplete
            obraEdicao={obra}
            change={handleChangeUj}
            error={errorUj}
            options={ujsUsuarioExterno}
          />
        </Box>
        <Box mb={1}>
          <Autocomplete
            defaultValue={tiposObra.find(tipoObra => tipoObra.id === obra?.tipoObra?.id)}
            options={tiposObra}
            size="small"
            getOptionLabel={option => option.descricao}
            onChange={(e, t) => setTipoObra(t ? t.id : null)}
            fullWidth
            renderInput={params => (
              <TextField
                {...params}
                label={
                  !errorTipoObra
                    ? 'Pesquise e selecione o tipo de obra'
                    : 'Tipo de obra é obrigatório'
                }
                error={errorTipoObra}
                variant="outlined"
              />
            )}
          />
        </Box>
        {!isUsuarioExterno() && (
          <Box mb={1}>
            <Typography variant='subtitle2' gutterBottom>
              Conceder acesso a usuarios de outras Unidades
            </Typography>
            <Button
              color='primary'
              variant='contained'
              size='small'
              aria-label='add'
              onClick={() => setModalConcederAcessoUsuario(true)}
            >
              Selecionar usuário
            </Button>
            {usuariosConcedidos && usuariosConcedidos.length > 0 &&
            <Box display='flex'>
  
              {usuariosConcedidos.map((usuario) => {
                let dataExclusao;
                if (usuario.dataExclusao instanceof Date) {
                  dataExclusao = usuario.dataExclusao;
                } else if (Array.isArray(usuario.dataExclusao)) {
                  const [year, month, day, hour = 0, minute = 0, second = 0] = usuario.dataExclusao;
                  dataExclusao = new Date(year, month - 1, day, hour, minute, second);
                } else {
                  dataExclusao = null;
                }

                const formattedDate = dataExclusao ? `até ${dataExclusao.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' })}` : '';
                
                return (
                  <Badge
                    key={usuario.login}
                    badgeContent={<CancelIcon onClick={() => removerUsuario(usuario)} />}
                    className='m2percent'
                    style={{ zIndex: 0 }}
                  >
                    <Button variant='contained' className='btn-yellow-back'>
                      {usuario.login}
                      {console.log(formattedDate)}
                      {formattedDate ? ` (${formattedDate})` : null}
                    </Button>
                       
                  </Badge>
                );
              })}
            </Box>
          }

          </Box>
        )}
        <Box mt={2}>
          <TextField
            name='descricao'
            defaultValue={obra?.nome}
            variant="outlined"
            color="primary"
            label={
              !errorDescricao
                ? 'Descrição da Obra ou Etapa (Obrigatório)'
                : 'Descrição é obrigatória'
            }
            fullWidth
            multiline
            maxRows={4}
            inputProps={{ maxLength: 200 }}
            error={errorDescricao}
            onChange={(e) => setDescricao(e.target.value)}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            setToInitialState()
            onCancelar()
          }}
        >
          Cancelar
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleConfirmar()}
        >
          Salvar
        </Button>
      </DialogActions>
      {empreendimento && modalConcederAcessoUsuario && (
        <ModalConcederAcessoUsuario
          open={modalConcederAcessoUsuario}
          fechar={() => setModalConcederAcessoUsuario(false)}
          codEmpreendimento={Number(empreendimento.cod)}
          onComplete={usuario => handleOnCompleteConcederAcesso(usuario)}
        />
      )}
    </Dialog>
  )
  
}

ModalEditarAdicionarObra.propTypes = {
  openDialog: PropTypes.bool,
  onCancelar: PropTypes.func,
  obra: PropTypes.object,
  empreendimento: PropTypes.object,
  onOpenDialog: PropTypes.func
}

export default connect(null, { buscarUjs })(ModalEditarAdicionarObra)
