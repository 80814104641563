import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { formatarNumeroParaMoedaBR, mapStateToPropsContexto, showSuccessMsg, urlContexto } from '../../../../../../../../js/util'
import { Divider, IconButton, Table, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import styles from '../../../styles'
import ResumoTabelaMaoDeObra from '../ResumoTabelaMaoDeObra'
import TableBody from '@material-ui/core/TableBody'
import Button from '@material-ui/core/Button'
import AdicionarMaoDeObraComposicao from './AdicionarMaoDeObraComposicao'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import StyledDrawer from '../../../../../../../comum/StyledDrawer'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ModalConfirmacao from '../../../../../../../comum/ModalConfirmacao'
import Typography from '@material-ui/core/Typography'
import { context_httpdelete } from '../../../../../../../../js/httpRequest'
import EditarMaoDeObraComposicao from './EditarMaoDeObraComposicao'
import iconMaoObra from '../../../../../../../../img/mao-de-obra.svg'
import ReactTooltip from 'react-tooltip'
import { withRouter } from 'react-router-dom'
import { MENU_MAOS_DE_OBRA } from '../../../../../precos/preco'

const defaultState = {
  exibirDrawerAdicionar: false,
  maoDeObraEdicao: null,
  maoDeObraExclusao: null
}

class MaosDeObraComposicao extends Component {
  static propTypes = {
    insumos: PropTypes.object.isRequired,
    codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    permitirEdicao: PropTypes.bool,
    sistemaReferencia: PropTypes.string,
    classes: PropTypes.object,
    contexto: PropTypes.object,
    history: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = defaultState
    this.handleDadosAlterados = this.handleDadosAlterados.bind(this)
    this.handleConfirmarExclusaoMaoDeObra = this.handleConfirmarExclusaoMaoDeObra.bind(this)
    this.handleMaosDeObraClick = this.handleMaosDeObraClick.bind(this)
  }

  async handleConfirmarExclusaoMaoDeObra () {
    const { codigoComposicao, contexto, onDadosAlterados } = this.props
    const { maoDeObraExclusao } = this.state
    const codigoMaoDeObra = maoDeObraExclusao.maoDeObraOrcamento.codigo
    const resultado = await context_httpdelete(`precos/desvincular-mao-de-obra?composicao=${codigoComposicao}&mao-de-obra=${codigoMaoDeObra}`, contexto)
    if (resultado) {
      this.setState(defaultState)
      showSuccessMsg(`Mão de obra ${codigoMaoDeObra} desvinculada da composição.`)
      onDadosAlterados()
    }
  }

  handleDadosAlterados() {
    const { onDadosAlterados } = this.props
    this.setState(defaultState)
    onDadosAlterados()
  }

  handleMaosDeObraClick () {
    const { contexto } = this.props
    this.props.history.push(`${urlContexto(contexto)}/precos`, { menuSelecionado: MENU_MAOS_DE_OBRA })
  }

  render () {
    const { classes, insumos, permitirEdicao, codigoComposicao, sistemaReferencia } = this.props
    const { exibirDrawerAdicionar, maoDeObraEdicao, maoDeObraExclusao } = this.state
    return (
      <>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '5%' }} className={classes.tableCell}>
                <MesmaLinha>
                  B
                  <IconButton data-tip='Clique para ver todas as mãos de obra do orçamento' data-for='tooltip-maodeobra' id={`mb${codigoComposicao}`} onClick={this.handleMaosDeObraClick}>
                    <img width={21} height={21} src={iconMaoObra} alt='Ver todas as mãos de obra do orçamento' />
                  </IconButton>
                  <ReactTooltip id='tooltip-maodeobra' data-tip type='info' effect='float' place='left' />
                </MesmaLinha>
              </TableCell>
              <TableCell style={{ width: '40%' }} className={classes.tableCell}>
                Mão de Obra
                {
                  permitirEdicao && (
                    <Button color='primary' size='small' onClick={() => this.setState({ exibirDrawerAdicionar: true })}>
                      + Adicionar
                    </Button>
                  )
                }
              </TableCell>
              <TableCell style={{ width: '15%' }} className={classes.tableCell}>Quantidade</TableCell>
              <TableCell style={{ width: '15%' }} className={classes.tableCell}>Unidade</TableCell>
              <TableCell style={{ width: '15%' }} className={classes.tableCell}>Custo</TableCell>
              <TableCell align={'right'} className={classes.tableCell}>Custo Horário Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              insumos.maosDeObra.map((maoDeObraComposicao) => (
                <TableRow key={maoDeObraComposicao.codigoMaoDeObra}>
                  <TableCell className={classes.tableCell}>
                    {
                      permitirEdicao && (
                        <MesmaLinha>
                          <IconButton size='small' onClick={() => this.setState({ maoDeObraEdicao: maoDeObraComposicao })} color='primary'>
                            <EditIcon />
                          </IconButton>
                          <IconButton size='small' onClick={() => this.setState({ maoDeObraExclusao: maoDeObraComposicao })} color='primary'>
                            <DeleteIcon />
                          </IconButton>
                        </MesmaLinha>
                      )
                    }
                  </TableCell>
                  <TableCell className={classes.tableCell}>{maoDeObraComposicao.maoDeObraOrcamento.codigo} - {maoDeObraComposicao.maoDeObraOrcamento.descricao}</TableCell>
                  <TableCell className={classes.tableCell}>{formatarNumeroParaMoedaBR(maoDeObraComposicao.quantidade, 7, 7)}</TableCell>
                  <TableCell className={classes.tableCell}>{maoDeObraComposicao.maoDeObraOrcamento.unidade}</TableCell>
                  <TableCell className={classes.tableCell}>{formatarNumeroParaMoedaBR(maoDeObraComposicao.maoDeObraOrcamento.custo, 2, 4)}</TableCell>
                  <TableCell className={classes.tableCell} align={'right'}>{formatarNumeroParaMoedaBR(maoDeObraComposicao.custoHorarioTotal, 2, 4)}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <ResumoTabelaMaoDeObra classes={classes} insumos={insumos} sistemaReferencia={sistemaReferencia} />
        <Divider className={classes.divider}/>
        {
          exibirDrawerAdicionar && (
            <StyledDrawer
              PaperProps={{ className: classes.drawerEdicao }}
              open={true}
              anchor={'right'}>
              <AdicionarMaoDeObraComposicao
                codigoComposicao={codigoComposicao}
                onDadosAlterados={this.handleDadosAlterados}
                onCancelar={() => this.setState({ exibirDrawerAdicionar: false })}
              />
            </StyledDrawer>
          )
        }
        {
          maoDeObraEdicao != null && (
            <StyledDrawer
              PaperProps={{ className: classes.drawerEdicao }}
              open={true}
              anchor={'right'}>
              <EditarMaoDeObraComposicao
                maoDeObraComposicao={maoDeObraEdicao}
                codigoComposicao={codigoComposicao}
                onCancelar={() => this.setState({ maoDeObraEdicao: null })}
                onDadosAlterados={this.handleDadosAlterados}
              />
            </StyledDrawer>
          )
        }
        {
          maoDeObraExclusao != null && (
            <ModalConfirmacao
              open
              titulo='Confirmar Exclusão de Mão de Obra'
              confirmar={this.handleConfirmarExclusaoMaoDeObra}
              onClose={() => this.setState({ maoDeObraExclusao: null })}>
              <Typography>
                Deseja realmente desvincular a mão de obra { maoDeObraExclusao.maoDeObraOrcamento.codigo } - { maoDeObraExclusao.maoDeObraOrcamento.descricao } da composição?
              </Typography>
            </ModalConfirmacao>
          )
        }
      </>
    )
  }
}

export default withRouter(connect(mapStateToPropsContexto)(withStyles(styles)(MaosDeObraComposicao)))
