import React, { useState } from 'react'
import { Box, CircularProgress, TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  combo: {
    backgroundColor: '#fff',
    width: 550
  }
}))

const CampoOrcamentos = ({ onCampoOrcamento, orcamentos, carregandoOrcamentos }) => {
  const classes = useStyles()
  const [openAutoComplete, setOpenAutoComplete] = useState(false)
  return (
    <Box mt={1}>
      <Autocomplete
        name={'orcamento'}
        className={classes.combo}
        open={openAutoComplete}
        onOpen={() => setOpenAutoComplete(true)}
        onClose={() => setOpenAutoComplete(false)}
        getOptionSelected={(orcamento, value) => orcamento.descricao === value.descricao}
        getOptionLabel={(orcamento) => orcamento.descricao}
        options={orcamentos}
        onChange={(e, values) => onCampoOrcamento(values)}
        noOptionsText={'Nenhum resultado encontrado'}
        renderInput={(params) => (
          <React.Fragment>
            <Typography variant='subtitle1' style={{ background: '#f6f8fa' }}>Orçamentos</Typography>
            <TextField
              {...params}
              variant='outlined'
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    { carregandoOrcamentos ? (<CircularProgress color='inherit' size={20} />) : null }
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          </React.Fragment>
        )}
      />
    </Box>
  )
}

CampoOrcamentos.propTypes = {
  orcamentos: PropTypes.array,
  onCampoOrcamento: PropTypes.func.isRequired,
  carregandoOrcamentos: PropTypes.func
}

export default CampoOrcamentos
