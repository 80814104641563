import { combineReducers } from 'redux'

import messages from './messagesReducer'
import authentication from './authenticationJwtReducer'
import operationProgress from './operationProgressReducer'
import empreendimentos from './empreendimentos-reducer'
import obras from './obras-reducer'
import tiposObra from './tipos-obra-reducer'
import orcamentos from './orcamentos-reducer'
import itensOrcamento from './itens-orcamento-reducer'
import contexto from './contexto-reducer'
import curvaAbc from './curvaabc-reducer'
import filtroAnalise from './analiseOrcamentoReducer'
import tiposUnidade from './tipos-unidade-reducer'

const rootReducer = combineReducers({
  messages,
  authentication,
  operationProgress,
  empreendimentos,
  obras,
  tiposObra,
  orcamentos,
  itensOrcamento,
  contexto,
  curvaAbc,
  filtroAnalise,
  tiposUnidade
})

export default rootReducer
