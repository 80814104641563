import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import ComboBox from '../../comum/ComboBox'
import PropTypes from 'prop-types'

const Periodos = ({ value, empty, onChange, periodos }) => (
  <ComboBox
    fullWidth={false}
    label="Selecione o período"
    value={value}
    empty={empty}
    onChange={onChange}
  >
    {periodos.map((periodo) => (
      <MenuItem value={periodo} key={periodo.referencia}>
        <Typography>{periodo.referencia}</Typography>
      </MenuItem>
    ))}
  </ComboBox>
)

Periodos.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  empty: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  periodos: PropTypes.array.isRequired,
}

export default Periodos
