import React from 'react'
import { Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import PaperBox from '../comum/PaperBox'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Alert from '@material-ui/lab/Alert'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import TitleTableCell from '../comum/TitleTableCell'
import iconAnalise from '../../img/IconAnaliseOrc.svg'
import { urlAnaliseOrcamento } from '../../js/util'
import _ from 'lodash'
import PropTypes from 'prop-types'

const ListaPesquisaGeral = ({ itens }) => {
  return (
    <PaperBox elevation={0} variant="outlined">
      <Typography
        component="span"
        variant="h6"
        className="m0-1-10"
        gutterBottom
      >
        Resultados
      </Typography>
      {_.isEmpty(itens) ? (
        <Alert style={{ marginTop: 4, marginBottom: 4 }} severity="warning">
          Não foram encontrados resultados com essa pesquisa.
        </Alert>
      ) : (
        <Table className="mb25">
          <TableHead>
            <TableRow>
              <TitleTableCell>Analisar</TitleTableCell>
              <TitleTableCell>Orçamento</TitleTableCell>
              <TitleTableCell>Obra</TitleTableCell>
              <TitleTableCell>Empreendimento</TitleTableCell>
            </TableRow>
            {itens &&
              itens.map((item, i) => (
                <TableRow key={i}>
                  <TableCell>
                    <Link
                      to={urlAnaliseOrcamento(
                        item.empreendimento.cod,
                        item.obra.cod,
                        item.orcamento.cod,
                        item.orcamento.possuiCurvaAbc
                      )}
                    >
                      <Button color="primary" variant="outlined" size="small">
                        <img src={iconAnalise} alt={'Análise de Orçamento'} />
                      </Button>
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      className="link-tab-last"
                      to={urlAnaliseOrcamento(
                        item.empreendimento.cod,
                        item.obra.cod,
                        item.orcamento.cod,
                        item.orcamento.possuiCurvaAbc
                      )}
                    >
                      {item.orcamento.descricao}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      className="link-tab-last"
                      to={`/empreendimentos/${item.empreendimento.cod}/obras/${item.obra.cod}/orcamentos`}
                    >
                      {item.obra.nome}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      className="link-tab-last"
                      to={`/empreendimentos/${item.empreendimento.cod}/obras`}
                    >
                      {item.empreendimento.nome}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
          </TableHead>
        </Table>
      )}
    </PaperBox>
  )
}

ListaPesquisaGeral.propTypes = {
  itens: PropTypes.array.isRequired,
}

export default ListaPesquisaGeral
