import axios from 'axios'
import { CARREGAR_REFERENCIAS_PRECO_ORCAMENTO_EM_EDICAO } from '../types'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import { showErrorMsg, url } from '../../js/util'

const recuperarReferenciasPrecosOrcamentoEmEdicao = (idEmpreendimento, idObra, idOrcamento) => async (dispatch) => {
  let referenciasCarregadas = []
  if (idOrcamento) {
    await axios.get(`${url({ idEmpreendimento, idObra, idOrcamento })}/referencia-preco`, SLOW_REQUEST_CONFIG)
      .then((resp) => {
        const { data } = resp
        if (data) {
          referenciasCarregadas = data.map((ref) => ({
            cod: ref.cod,
            sigla: ref.sigla,
            uf: ref.uf,
            dataReferencia: `${ref.ano}-${ref.mes < 10 ? `0${ref.mes}` : ref.mes}-01`,
            encargosSociais: `${ref.encargosSociais}`,
            fatorInterferenciaTrafego: `${ref.fatorInterferenciaTrafego}`,
            ordemRelevancia: ref.ordemRelevancia,
          }))
        }
      })
      .catch((e) => {
        showErrorMsg(`Erro inesperado: ${e.message}`)
      })
  }
  dispatch({
    type: CARREGAR_REFERENCIAS_PRECO_ORCAMENTO_EM_EDICAO,
    payload: referenciasCarregadas
  })
}

export default recuperarReferenciasPrecosOrcamentoEmEdicao
