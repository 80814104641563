import React, { Component, Fragment } from 'react'
import { Link, TableCell, TableRow } from '@material-ui/core'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { converterMoedaBRParaNumero, cursorPointer, formatarNumeroParaMoedaBR, handleCampoNumericoChange, mapStateToPropsContexto, seStringVazia, showSuccessMsg } from '../../../../../js/util'
import TextField from '@material-ui/core/TextField'
import MesmaLinha from '../../../../comum/MesmaLinha'
import IconButton from '@material-ui/core/IconButton'
import CancelIcon from '@material-ui/icons/Clear'
import DoneIcon from '@material-ui/icons/Done'
import EditIcon from '@material-ui/icons/Edit'
import StyledDrawer from '../../../../comum/StyledDrawer'
import { context_httpget, context_httpput } from '../../../../../js/httpRequest'
import { connect } from 'react-redux'
import ModalComposicoes from '../composicoes/ModalComposicoes'
import EditarCodigoTransporte from '../../relatorios/precos/composicao/composicoes-auxiliar/transportes/EditarCodigoTransporte'


class ItemTransporteRow extends Component {
  static propTypes = {
    transporte: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    contexto: PropTypes.object,
    onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      editando: false,
      dmtPadrao: this.props.transporte.distancia ? this.props.transporte.distancia.toString().replace('.', ',') : '',
      dmtPadraoEmpty: false,
      modalComposicoes: false,
      composicoes: null,
      exibirDrawerEditarCodigoTransporte: false
    }
    this.salvarEdicao = this.salvarEdicao.bind(this)
  }

  handleCampoTextoChange (event, campo) {
    const state = {}
    state[campo] = event.target.value
    state[`${campo}Empty`] = seStringVazia(event.target.value)
    this.setState(state)
  }

  edicaoDmtPadrao() {
    const { dmtPadrao, dmtPadraoEmpty } = this.state
    return (
      <TableCell>
        <TextField
          variant='outlined'
          style={{maxWidth: 80}}
          value={dmtPadrao}
          error={dmtPadraoEmpty}
          onChange={(e) => handleCampoNumericoChange(e, (e) => this.handleCampoTextoChange(e, 'dmtPadrao'))}
        />
        { this.acoes() }
      </TableCell>
    )
  }

  async salvarEdicao () {
    const { dmtPadrao } = this.state
    const dmtPadraoEmpty = seStringVazia(dmtPadrao)
    if (dmtPadraoEmpty) {
      this.setState({ dmtPadraoEmpty })
    } else {
      const { contexto, transporte, onDadosAlterados } = this.props
      const dados = {
        codigoInsumo: transporte.codigoInsumo,
        codigoComposicao: transporte.codigoComposicaoTransporte,
        tipo: transporte.tipoRodovia,
        distancia: converterMoedaBRParaNumero(dmtPadrao)
      }
      const ok = await context_httpput('precos/composicoes/transportes/dmt', contexto, dados)
      if (ok) {
        this.setState({ editando: false })
        showSuccessMsg('Dados de DMT padrão alterados com sucesso.')
        onDadosAlterados()
      }
    }
  }

  abrirModalComposicoes () {
    const { contexto, transporte } = this.props

    context_httpget(`precos/composicoes-transporte?insumo-transportado=${transporte.codigoInsumo}&composicao-transporte=${transporte.codigoComposicaoTransporte}&tipo-transporte=${transporte.tipoRodovia}`, contexto)
      .then((composicoes) => {
        this.setState({
          composicoes,
          modalComposicoes: true
        })
      })
  }

  fecharModalComposicoes () {
    this.setState({ modalComposicoes: false })
  }

  acoes() {
    const { editando } = this.state
    return editando ? (
      <Fragment>
        <IconButton size='small' color='primary' variant='outlined' onClick={() => this.setState({ editando: false })}>
          <CancelIcon fontSize='small' style={cursorPointer}/>
        </IconButton>
        <IconButton size='small' color='primary' variant='outlined' onClick={this.salvarEdicao}>
          <DoneIcon fontSize='small' style={cursorPointer}/>
        </IconButton>
      </Fragment>
    ) : (
      <IconButton size='small' color='primary' variant='outlined' onClick={() => this.setState({ editando: true })}>
        <EditIcon fontSize='small'/>
      </IconButton>
    )
  }

  dmtPadrao() {
    const { editando } = this.state
    const { transporte } = this.props

    if (editando) {
      return this.edicaoDmtPadrao()
    }

    return (
      <TableCell component='th' scope='row' onClick={() => this.setState({ editando: true })}>
        <MesmaLinha>
          { _.isNil(transporte.distancia) ? 'Não cadastrada' : formatarNumeroParaMoedaBR(transporte.distancia, 2, 4) }
          { this.acoes() }
        </MesmaLinha>
      </TableCell>
    )
  }

  handleChangeDrawerEditarCodigoTransporte = () => this.setState({ exibirDrawerEditarCodigoTransporte: !this.state.exibirDrawerEditarCodigoTransporte })

  render () {
    const { transporte, onAtualizarItemCurvaABCComposicaoDuplicada, classes } = this.props
    const { modalComposicoes, composicoes, exibirDrawerEditarCodigoTransporte } = this.state

    const transporteSelecionado = transporte

    return (
      <Fragment>
        <TableRow>
          <TableCell>
            {transporte.tipoInsumo}
          </TableCell>

          <TableCell>
            {transporte.codigoInsumo}
          </TableCell>

          <TableCell>
            {transporte.descricaoInsumo}
          </TableCell>

          <TableCell>
            {transporte.unidadeInsumo}
          </TableCell>

          <TableCell>
            {transporte.tipoRodovia}
          </TableCell>

          <TableCell>
            {transporte.codigoComposicaoTransporte}
              <Fragment>
                <IconButton size='small' color='primary' variant='outlined' onClick={() => this.handleChangeDrawerEditarCodigoTransporte()}>
                  <EditIcon fontSize='small'/>
                </IconButton>
              </Fragment>
          </TableCell>

          <TableCell>
            {transporte.descricaoComposicaoTransporte}
          </TableCell>

          <TableCell>
            <Link display='inline' color='primary' onClick={() => this.abrirModalComposicoes()}>
              {transporte.quantidadeOcorrencias} composiç{transporte.quantidadeOcorrencias === 1 ? 'ão' : 'ões'}
            </Link>
          </TableCell>

          { this.dmtPadrao() }

          {
          exibirDrawerEditarCodigoTransporte && (
           
            <StyledDrawer
            //PaperProps={{ className: classes.drawerEdicao }}
            open={true}
            anchor={'right'}>
            <EditarCodigoTransporte
              transporteSelecionado = {transporteSelecionado}
              onDadosAlterados={this.handleDadosAlterados}
              onCancelar={() => this.setState({ exibirDrawerEditarCodigoTransporte: false })}
            />
          </StyledDrawer>
            
          )
        }

        </TableRow>

        <ModalComposicoes
          open={modalComposicoes}
          close={() => this.fecharModalComposicoes()}
          titulo={`Composições do transporte: ${transporte.codigoInsumo} - ${transporte.descricaoInsumo} - ${transporte.codigoComposicaoTransporte} - ${transporte.descricaoComposicaoTransporte}`}
          composicoes={composicoes}
          onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
        />
      </Fragment>
    )
  }
}

export default connect(mapStateToPropsContexto)(ItemTransporteRow)
