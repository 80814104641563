import React, { Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { ListItemIcon } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import fileDownload from 'js-file-download'
import { formatarNumeroParaMoedaBR } from '../../../js/util'
import { httpget } from '../../../js/httpRequest'
import PropTypes from 'prop-types'

const formataPreco = (preco) =>
  preco ? `R$ ${formatarNumeroParaMoedaBR(preco, 2, 3)}` : ''

class MenuSistemaReferencia extends Component {
  async exportarPlanilha() {
    const { insumos, totalItens, pesquisa } = this.props
    let insumosCopy = [...insumos]

    if (totalItens > insumos.length) {
      const dados = await httpget(
        `/api/insumos/${pesquisa.sistema}/${pesquisa.uf}/${pesquisa.periodo.mes}/${pesquisa.periodo.ano}/${pesquisa.tipoInsumo}?busca=${pesquisa.termoBusca}&from=0&size=${totalItens}`
      )
      insumosCopy = [...dados.insumosCatalogos]
    }

    let arquivo = ''
    let quantidadeServico = 1

    insumosCopy.forEach((insumo) => {
      let precoParcial = quantidadeServico * insumo.preco
      arquivo += `${insumo.codigo};${insumo.descricao
        .replace(/\r/g, '')
        .replace(/\n/g, '')};${
        insumo.unidade
      };${quantidadeServico};${formataPreco(insumo.preco)};${formataPreco(
        precoParcial
      )}\n`
    })

    fileDownload(
      new Blob(['\ufeff', arquivo]),
      'referencias-de-preco.csv',
      'text/csv'
    )
  }

  render() {
    return (
      <div className="divTooltip">
        <Tooltip title="Exportar planilha" className="minw35 curpointer">
          <ListItemIcon>
            <SaveAltIcon onClick={() => this.exportarPlanilha()} />
          </ListItemIcon>
        </Tooltip>
      </div>
    )
  }
}

MenuSistemaReferencia.propTypes = {
  insumos: PropTypes.array.isRequired,
  totalItens: PropTypes.number.isRequired,
  pesquisa: PropTypes.object,
}

export default withRouter(MenuSistemaReferencia)
