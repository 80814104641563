import React, { Component, Fragment } from 'react'
import { Link, TableCell, TableRow, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { converterMoedaBRParaNumero, formatarNumeroParaMoedaBR, handleCampoNumericoChange, mapStateToPropsContexto, seStringVazia, showSuccessMsg } from '../../../../../js/util'
import TextField from '@material-ui/core/TextField'
import { context_httpget, context_httpput } from '../../../../../js/httpRequest'
import MesmaLinha from '../../../../comum/MesmaLinha'
import IconButton from '@material-ui/core/IconButton'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import EditIcon from '@material-ui/icons/Edit'
import { connect } from 'react-redux'
import ModalComposicoes from '../composicoes/ModalComposicoes'
import styles from '../styles'

class ItemMaterialRow extends Component {
  static propTypes = {
    material: PropTypes.object.isRequired,
    contexto: PropTypes.object,
    classes: PropTypes.object.isRequired,
    onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      editando: false,
      descricao: '',
      descricaoEmpty: false,
      unidade: '',
      unidadeEmpty: false,
      precoUnitario: '',
      precoUnitarioEmpty: false,
      modalComposicoes: false,
      composicoes: null
    }
    this.handleCancelarEdicao = this.handleCancelarEdicao.bind(this)
    this.handleSalvarEdicao = this.handleSalvarEdicao.bind(this)
  }

  componentDidMount () {
    if (this.props.material) {
      this.copiarDadosParaEstado()
    }
  }

  copiarDadosParaEstado() {
    const { material } = this.props
    this.setState({
      descricao: material.materialOrcamento.descricao,
      descricaoEmpty: false,
      unidade: material.materialOrcamento.unidade,
      unidadeEmpty: false,
      precoUnitario: formatarNumeroParaMoedaBR(material.materialOrcamento.precoUnitario, 2, 5),
      precoUnitarioEmpty: false
    })
  }

  handleCampoTextoChange (event, campo) {
    const state = {}
    state[campo] = event.target.value
    state[`${campo}Empty`] = seStringVazia(event.target.value)
    this.setState(state)
  }

  handleCancelarEdicao () {
    this.copiarDadosParaEstado()
    this.setState({ editando: false })
  }

  async handleSalvarEdicao () {
    const { descricao, unidade, precoUnitario } = this.state
    const descricaoEmpty = seStringVazia(descricao)
    const unidadeEmpty = seStringVazia(unidade)
    const precoUnitarioEmpty = seStringVazia(precoUnitario)

    if([descricaoEmpty, unidadeEmpty, precoUnitarioEmpty].some(Boolean)) {
      this.setState({
        descricaoEmpty, unidadeEmpty, precoUnitarioEmpty
      })
    } else {
      const { material, contexto } = this.props
      const dados = {
        descricao,
        unidade,
        precoUnitario: converterMoedaBRParaNumero(precoUnitario)
      }
      const ok = await context_httpput(`precos/materiais/${material.materialOrcamento.codigo}`, contexto, dados)
      if (ok) {
        showSuccessMsg('Dados do material alterados com sucesso.')
        this.setState({ editando: false })
      }
    }
  }

  abrirModalComposicoes () {
    const { contexto, material } = this.props

    context_httpget(`precos/composicoes/material/${material.materialOrcamento.codigo}`, contexto)
      .then((composicoes) => {
        this.setState({
          composicoes,
          modalComposicoes: true
        })
    })
  }

  fecharModalComposicoes () {
    this.setState({ modalComposicoes: false })
  }

  render() {
    const { material, onAtualizarItemCurvaABCComposicaoDuplicada } = this.props
    const {
      editando,
      descricao,
      descricaoEmpty,
      unidade,
      unidadeEmpty,
      precoUnitario,
      precoUnitarioEmpty,
      modalComposicoes,
      composicoes
    } = this.state

    return (
      <Fragment>
        <TableRow>
          <TableCell>
            {material.materialOrcamento.sistemaReferencia}
          </TableCell>
          <TableCell>
            {material.materialOrcamento.mes}/{material.materialOrcamento.ano}
          </TableCell>
          <TableCell>
            {material.materialOrcamento.codigo}
          </TableCell>
          <TableCell>
            {
              editando ? (
                <TextField
                  fullWidth
                  variant='outlined'
                  value={descricao}
                  error={descricaoEmpty}
                  onChange={(e) => this.handleCampoTextoChange(e, 'descricao')}
                />
              ) : descricao
            }
          </TableCell>

          <TableCell>
            {
              editando ? (
                <TextField
                  fullWidth
                  variant='outlined'
                  value={unidade}
                  error={unidadeEmpty}
                  onChange={(e) => this.handleCampoTextoChange(e, 'unidade')}
                />
              ) : unidade
            }
          </TableCell>
          <TableCell>
            {
              editando ? (
                <TextField
                  fullWidth
                  variant='outlined'
                  value={precoUnitario}
                  error={precoUnitarioEmpty}
                  onChange={(e) => handleCampoNumericoChange(e, (e) => this.handleCampoTextoChange(e, 'precoUnitario'))}
                />
              ) : precoUnitario
            }
          </TableCell>
          <TableCell>
            <Link display='inline' color='primary' onClick={() => this.abrirModalComposicoes()}>
              {material.qtdComposicoes} composiç{material.qtdComposicoes === 1 ? 'ão' : 'ões'}
            </Link>
          </TableCell>
          <TableCell align='center'>
            {
              editando ? (
                <MesmaLinha>
                  <IconButton color='primary' onClick={this.handleSalvarEdicao}>
                    <DoneIcon />
                  </IconButton>
                  <IconButton color='primary' onClick={this.handleCancelarEdicao}>
                    <ClearIcon />
                  </IconButton>
                </MesmaLinha>
              ) : (
                <IconButton color='primary' onClick={() => this.setState({ editando: true })}>
                  <EditIcon />
                </IconButton>
              )
            }
          </TableCell>
        </TableRow>

        <ModalComposicoes
          open={modalComposicoes}
          close={() => this.fecharModalComposicoes()}
          titulo={`Composições do material: ${material.materialOrcamento.codigo} - ${material.materialOrcamento.descricao}`}
          composicoes={composicoes}
          onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
        />
      </Fragment>
    )
  }
}

export default connect(mapStateToPropsContexto)(withStyles(styles)(ItemMaterialRow))
