import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import PropTypes from 'prop-types';

const CabecalhoTabelaPesquisarEmPrecos = ({ onSort, sortField, sortOrder }) => {
  return (
    <TableHead>
      <TableRow>
        
        <TableCell>
          <TableSortLabel
            active={sortField === 'descricao'}
            direction={sortField === 'descricao' ? sortOrder : 'asc'}
            onClick={() => onSort('descricao')}
          >
            Descrição
          </TableSortLabel>
        </TableCell>

        <TableCell>
          <TableSortLabel
            active={sortField === 'codigo'}
            direction={sortField === 'codigo' ? sortOrder : 'asc'}
            onClick={() => onSort('codigo')}
          >
            Código Serviço
          </TableSortLabel>
        </TableCell>

        <TableCell>
          
            Sistema
          
        </TableCell>

        <TableCell>
          
            Data
      
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

CabecalhoTabelaPesquisarEmPrecos.propTypes = {
  onSort: PropTypes.func.isRequired,
  sortField: PropTypes.string.isRequired,
  sortOrder: PropTypes.string.isRequired,
};


export default CabecalhoTabelaPesquisarEmPrecos
