import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import Template from '../../../layout/Template'
import PaperBox from '../../../comum/PaperBox'
import { Box, Button, Divider, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'
import { context_httpget } from '../../../../js/httpRequest'
import { connect } from 'react-redux'
import ListarTemas from './ListarTemas'
import { textNormalize } from '../../../../js/util'
import Alert from '@material-ui/lab/Alert'
import Link from '@material-ui/core/Link'

const Temas = ({ classes, match, history, contexto }) => {
  const [possuiCurvaAbc, setPossuiCurvaAbc] = useState(false)
  const [termoPesquisa, setTermoPesquisa] = useState('')
  const [temas, setTemas] = useState([])
  const [temasFilter, setTemasFilter] = useState([])

  useEffect(() => {
    const verificaSeOrcamentoPossuiCurvaABC = async () => {
      const { possuiCurvaAbc } = await context_httpget('possui-curva-abc', contexto)
      setPossuiCurvaAbc(possuiCurvaAbc)
    }

    if (contexto) {
      contexto && verificaSeOrcamentoPossuiCurvaABC()
    }
  }, [contexto])

  useEffect(() => {
    const recuperarTemas = async () => {
      const data = await context_httpget('analise-conteudo/temas', contexto)
      setTemas(data)
      setTemasFilter(data)
    }

    possuiCurvaAbc && recuperarTemas()
  }, [possuiCurvaAbc])

  useEffect(() => {
    contexto && handlePesquisarTemas()
  }, [termoPesquisa])

  const handlePesquisarTemas = () => {
    if (termoPesquisa.length) {
      setTemasFilter(filtrarTemas(temasFilter))
    } else {
      setTemasFilter(temas)
    }
  }

  const handleChangeTermo = (e) => {
    if (e.keyCode === 8) {
      setTemasFilter(filtrarTemas(temas))
    }
  }

  const filtrarTemas = (temasFilter) =>
    temasFilter.filter(
      (tema) => textNormalize(tema.nome).toLowerCase().search(textNormalize(termoPesquisa).toLowerCase()) !== -1
    )

  const handleCurvaAbcClick = () => history.push(match.url.replace('analise-conteudo/gerenciar-temas', 'abc'))

  const handleClickVoltar = () => history.push(match.url.replace('/gerenciar-temas', ''))

  const handleClickCadastrarTema = () => history.push(match.url.concat('/cadastrar'))

  const handleEditar = (tema) => history.push(match.url.concat(`/${tema.codigo}`))

  return (
    <Template insidePaper={false}>
      {possuiCurvaAbc
        ? (<PaperBox elevation={0}>
          <Box display='flex' alignItems='center'>
            <IconButton onClick={handleClickVoltar} size='small'>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant='h6' onClick={handleClickVoltar}>
              Temas
            </Typography>
          </Box>
          <Box my={2}>
            <Divider />
          </Box>
          <Box display='flex' alignItems='center' justifyContent='space-between' mt={2}>
            <TextField
              className={classes.searchTema}
              variant='outlined'
              name='termoPesquisa'
              fullWidth
              value={termoPesquisa}
              placeholder='Pesquise um tema'
              onChange={(e) => setTermoPesquisa(e.target.value)}
              onKeyUp={(e) => handleChangeTermo(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <SearchIcon color='primary' />
                  </InputAdornment>
                )
              }}
            />
            <Button variant='contained' color='primary' onClick={handleClickCadastrarTema}>
              <AddIcon fontSize='small' color='inherit' /> Tema
            </Button>
          </Box>
          <ListarTemas
            classes={classes}
            temas={temasFilter}
            onEditar={handleEditar}
          />
        </PaperBox>)
        : (
          <Alert severity='warning'>
            Só é possível visualizar Análise de Conteúdo do Orçamento após <Link onClick={handleCurvaAbcClick}>criar a Curva ABC</Link>.
          </Alert>
        )
      }
    </Template>
  )
}

Temas.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  contexto: PropTypes.object
}

export const mapStateToProps = ({ contexto }) => ({ contexto })
export default connect(mapStateToProps)(withStyles(styles)(Temas))
