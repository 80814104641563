import { ALTERAR_EXIBICAO_COLUNA } from '../actions/types'
import { colunas } from '../components/orcamento/analisar/curva-abc/itens/colunas/colunas'
import { recuperarExibicaoColunas, salvarExibicaoColunas } from '../components/orcamento/analisar/curva-abc/itens/colunas/colunasContext'
import { existeObjetoNaLista, objetosIguais } from '../js/util'

const removerColuna = (colunas, coluna) => (
  colunas.filter((col) => !objetosIguais(col, coluna))
)

const incluirColuna = (colunas, coluna) => (
  colunas.concat(coluna)
)

const alterarExibicaoColuna = (state, coluna) => {
  const { colunasExibir } = state
  const novasColunasExibir = existeObjetoNaLista(colunasExibir, coluna) ? removerColuna(colunasExibir, coluna) : incluirColuna(colunasExibir, coluna)
  salvarExibicaoColunas({ ...state, colunasExibir: novasColunasExibir })
  return recuperarExibicaoColunas()
}

export const reducerCurvaAbc = (state = { colunasExibir: colunas.filter(it => it.exibirInicio) }, action) => {
  switch (action.type) {
    case ALTERAR_EXIBICAO_COLUNA:
      return alterarExibicaoColuna(state, action.payload)
    default:
      return recuperarExibicaoColunas()
  }
}

export default reducerCurvaAbc
