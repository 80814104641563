import React, { useEffect, useState } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import { Button, Chip, Divider, FormControl, Input, MenuItem, Typography, useTheme } from '@material-ui/core'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../../js/operationProgressUtil'
import Select from '@material-ui/core/Select'
import styles from '../styles'
import Box from '@material-ui/core/Box'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Link from '@material-ui/core/Link'
import Template from '../../layout/Template'
import TabelaUnidadesConfiguradas from './TabelaUnidadesConfiguradas'

const ConfigurarPerfil = ({ classes }) => {
  const theme = useTheme()
  const [unidadeInterna, setUnidadeInterna] = useState(null)
  const [unidadeExterna, setUnidadeExterna] = useState([])
  const [unidadesExternas, setUnidadesExternas] = useState([])
  const [unidadesInternas, setUnidadesInternas] = useState([])
  const [unidadesSupervisionadas, setUnidadesSupervisionadas] = useState([])

  useEffect(() => {
    const recuperarUnidadesSupervisionadas = async () => {
      const { data } = await axios.get('/api/unidades/supervisao', SLOW_REQUEST_CONFIG)
      setUnidadesSupervisionadas(data)
    }

    const recuperarUnidadesExternas = async () => {
      const { data } = await axios.get('/api/unidades/externas', SLOW_REQUEST_CONFIG)
      setUnidadesExternas(data)
    }

    const recuperarUnidadesInternas = async () => {
      const { data } = await axios.get('/api/unidades/internas', SLOW_REQUEST_CONFIG)
      setUnidadesInternas(data)
    }
    recuperarUnidadesExternas()
    recuperarUnidadesInternas()
    recuperarUnidadesSupervisionadas()
  }, [])

  const getStyles = (codUnidade, unidade, theme) => {
    return {
      fontWeight:
        unidade.map(it => it.codUnidade).indexOf(codUnidade) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium
    }
  }

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 250
      }
    }
  }

  const handleSupervisionar = async () => {
    const { data } = await axios.post('/api/unidades/supervisao', { unidadeInterna, unidadesExternas: unidadeExterna }, SLOW_REQUEST_CONFIG)
    setUnidadesSupervisionadas(data)
    setUnidadeInterna(null)
    setUnidadeExterna([])
  }

  const handleDesvincular = async (codUnidadeInterna, codUnidadeExterna) => {
    const response = await axios.delete(`/api/unidades/supervisao?codUnidadeInterna=${codUnidadeInterna}&codUnidadeExterna=${codUnidadeExterna}`, SLOW_REQUEST_CONFIG)
    if (response) {
      const unidadesSupervisao = unidadesSupervisionadas.map(it => {
        if (it.unidadeInterna.cod === codUnidadeInterna) {
          it.unidadesExternas = it.unidadesExternas.filter(unidEx => unidEx.cod !== codUnidadeExterna)
          return it
        } else {
          return it
        }
      })
      setUnidadesSupervisionadas(unidadesSupervisao)
    }
  }

  return (
    <Template>
      <Box pt={4} mb={2}>
        <Breadcrumbs aria-label='breadcrumb' style={{ marginTop: '-8px' }}>
          <Link color='inherit' href='/'>
            Início
          </Link>
          <Typography color='primary'>Configuração de unidade</Typography>
        </Breadcrumbs>
      </Box>
      <Box pt={2}>
        <Typography variant='h5' gutterBottom>Configuração de unidade</Typography>
      </Box>
      <Box py={2}>
        <Divider />
      </Box>
      <Box mb={4}>
        <Typography variant='subtitle2' gutterBottom>
          1. Selecione uma unidade interna:
        </Typography>
        <Select
          name='unidadeInterna'
          value={unidadeInterna}
          style={{ width: '500px' }}
          onChange={(e) => setUnidadeInterna(e.target.value)}
        >
          {unidadesInternas.map((it) => (
            <MenuItem key={it.cod} value={it}>
              <Typography>{it.sigla} - {it.nome}</Typography>
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box mb={4}>
        <Typography variant='subtitle2' gutterBottom>
          2. Quais unidades externas deseja supervisionar?
        </Typography>
        <FormControl className={classes.formControl}>
          <Select
            value={unidadeExterna}
            multiple
            onChange={(e) => setUnidadeExterna(e.target.value)}
            input={<Input />}
            renderValue={(selected) => (
              <div className={classes.chipsUnidades}>
                {selected.map((value) => (
                  <Chip label={value.sigla} className={classes.chipsUnidades} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            <MenuItem disabled>Selecione...</MenuItem>
            {unidadesExternas.map((unid) => (
              <MenuItem
                key={unid.codUnidade}
                value={unid}
                style={getStyles(unid.codUnidade, unidadeExterna, theme)}
              >
                {unid.sigla} - {unid.nome}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box my={4}>
        <Button
          variant='contained'
          color='primary'
          size='large'
          onClick={handleSupervisionar}
        >
          Supervisionar
        </Button>
      </Box>

      <Box mb={4}>
        <TabelaUnidadesConfiguradas
          unidades={unidadesSupervisionadas}
          onDesvincular={handleDesvincular}
        />
      </Box>
    </Template>
  )
}

ConfigurarPerfil.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(ConfigurarPerfil)
