import React from 'react'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import { INCIDENCIA_PERCENTUAL_EXPRESSAO, QUANTIDADE_ITENS_EXPRESSAO, VALOR_GLOBAL_EXPRESSAO } from '../../../../../analise-conteudo'
import { Typography } from '@material-ui/core'

const CampoExpressao = ({ classes, parametro, expressoesDisponiveis, onCampoChange }) => (
  <FormControl variant='outlined' className={classes.campoFormulaExpressao}>
    <div>
      <Typography variant='subtitle2'>{parametro.tipo.label}</Typography>
      <TextField
        select
        variant='outlined'
        value={parametro.codigoExpressao || ''}
        onChange={(e) => onCampoChange(e, parametro, 'codigoExpressao')}
        className={classes.textFildExpressoes}
        style={{ maxWidth: 400 }}>
        {
          expressoesDisponiveis.map((it) => (
            <MenuItem key={it.codigo} value={it.codigo}>
              {it.nome}
            </MenuItem>
          ))
        }
        {(expressoesDisponiveis.length === 0) && <MenuItem value=''>Nenhuma expressão cadastrada</MenuItem>}
      </TextField>
    </div>
    <div>
      <Typography variant='subtitle2'>Variável</Typography>
      <TextField
        select
        variant='outlined'
        value={parametro.variavelExpressao || ''}
        onChange={(e) => onCampoChange(e, parametro, 'variavelExpressao')}
      >
        <MenuItem value={VALOR_GLOBAL_EXPRESSAO}>{VALOR_GLOBAL_EXPRESSAO.label}</MenuItem>
        <MenuItem value={INCIDENCIA_PERCENTUAL_EXPRESSAO}>{INCIDENCIA_PERCENTUAL_EXPRESSAO.label}</MenuItem>
        <MenuItem value={QUANTIDADE_ITENS_EXPRESSAO}>{QUANTIDADE_ITENS_EXPRESSAO.label}</MenuItem>
      </TextField>
    </div>
  </FormControl>
)

CampoExpressao.propTypes = {
  classes: PropTypes.object,
  parametro: PropTypes.object.isRequired,
  expressoesDisponiveis: PropTypes.array.isRequired,
  onCampoChange: PropTypes.func.isRequired
}

export default CampoExpressao
