import { pageHeaderAndFooter } from '../../precos/composicao/pdf/RelatorioComposicaoPdf'
import {
  calcularQuantidadeTotalItensOrcamentarios,
  calcularTotalItensOrcamentarios,
  calcularTotalPercentual,
  calcularTotalValorParcial,
  campoCriterio,
  criterioComparacao,
  TIPO_COMPARACAO_ENTRE,
  TIPO_COMPARACAO_IGUAL_A,
  TIPO_CRITERIO_EXPRESSAO,
  TIPO_CRITERIO_NEGACAO_EXPRESSAO,
  TIPO_MEDIDA_DISPERSAO_DESVIO_PADRAO,
  tipoComparacao,
  tipoCriterio,
  tipoMedidaDispersao,
  tipoOperadorTipologiaCombinada,
  variavelAnalise
} from '../../../analise-conteudo/analise-conteudo'
import _ from 'lodash'
import { formatarNumeroParaMoedaBR } from '../../../../../../js/util'
import { iconCheck, iconInfo } from '../relatorio-analise-conteudo'

const insumosServicos = (expressao) => `Insumos e serviços: ${calcularTotalItensOrcamentarios(expressao.resultado)}`
const qtdTotalItens = (expressao) => `Quantidade total de itens: ${formatarNumeroParaMoedaBR(calcularQuantidadeTotalItensOrcamentarios(expressao.resultado), 2, 3)}`
const valorTotal = (expressao) => `Valor total: R$ ${formatarNumeroParaMoedaBR(calcularTotalValorParcial(expressao.resultado), 2, 3)}`
const percentual = (expressao) => `Percentual: ${formatarNumeroParaMoedaBR(calcularTotalPercentual(expressao.resultado), 2, 3)}% do valor do orçamento`

// Regra da tipologia
const varAnalise = (tipologia) => `${variavelAnalise(tipologia).label}`
const criterio = (tipologia) => `${criterioComparacao(tipologia).label}`
const tipo = (tipologia) => `${tipoComparacao(tipologia).label}`
const valor = (tipologia) => `${tipologia.valorComparacao}`
const entre = (tipologia) => tipologia.tipoComparacao === TIPO_COMPARACAO_ENTRE.value ? `e ${tipologia.valorComparacaoEntre}` : ''
const igualA = (tipologia) => tipologia.tipoComparacao === TIPO_COMPARACAO_IGUAL_A.value ? `${tipoMedidaDispersao(tipologia).label}` : ''
const medidaDispersao = (tipologia) => tipologia.tipoMedidaDispersao === TIPO_MEDIDA_DISPERSAO_DESVIO_PADRAO.value ? `${tipologia.valorMedidaDispersao}` : ''
export const regraTipologiaExpressao = (tipologia) => `    ${tipologia.nome}: ${varAnalise(tipologia)} ${criterio(tipologia)} ${tipo(tipologia)} ${valor(tipologia)} ${entre(tipologia)} ${igualA(tipologia)} ${medidaDispersao(tipologia)}`

const criterios = (criterios) => {
  let criteriosString = '\t'
  criterios.forEach(it => {
    if (tipoCriterio(it.tipo) !== TIPO_CRITERIO_EXPRESSAO && tipoCriterio(it.tipo) !== TIPO_CRITERIO_NEGACAO_EXPRESSAO) {
      criteriosString = criteriosString.concat(`${String.fromCharCode(176)} ${campoCriterio(it.campo).label}, ${tipoCriterio(it.tipo).label}: ${it.texto} \n\t`)
    } else {
      criteriosString = criteriosString.concat(`${String.fromCharCode(176)} Expressão: ${it.nomeExpressaoCriterio} \n\t`)
    }
  })
  return criteriosString.substring(0, criteriosString.length - 3)
}

let count = 5
const expressoesMap = new Map()

export function tabelaExpressoes (doc, expressoes, posicaoX, posicaoY) {
  const tableHeadExpressoes = [[{ content: 'EXPRESSÕES' }]]
  const tableBodyExpressoes = []

  expressoes.forEach((expressao) => {
    const linha1 = [{ content: expressao.nome, styles: { fontStyle: 'bold', overflow: 'linebreak' } }]
    const linha2 = [{ content: expressao.descricao, styles: { overflow: 'linebreak' } }]
    const linha3 = [{ content: `\tOs critérios dessa expressão serão combinados utilizando o operador: ${tipoOperadorTipologiaCombinada(expressao.operador).label.toUpperCase()}`, styles: { fontStyle: 'italic' } }]
    const linha4 = [{ content: criterios(expressao.criterios), styles: { overflow: 'linebreak' } }]
    const linha5 = [{ content: `${insumosServicos(expressao)} | ${qtdTotalItens(expressao)}  | ${valorTotal(expressao)} | ${percentual(expressao)}`, styles: { overflow: 'linebreak' } }]
    const linha6 = [{ content: !_.isNil(expressao.tipologia) ? regraTipologiaExpressao(expressao.tipologia) : '', styles: { overflow: 'linebreak' } }]
    if (!_.isNil(expressao.tipologia)) {
      expressoesMap.set(expressao.tipologia.nome, expressao.tipologiaPossuiInconsistencia)
    }
    tableBodyExpressoes.push(linha1, linha2, linha3, linha4, linha5, linha6)
  })

  if (_.isEmpty(expressoes)) {
    tableBodyExpressoes.push([{ content: 'Nenhuma expressão encontrada' }])
  }

  posicaoY += 20
  doc.autoTable(
    {
      body: tableBodyExpressoes,
      head: tableHeadExpressoes,
      startX: posicaoX,
      startY: posicaoY,
      tableWidth: 'auto',
      theme: 'plain',
      margin: {
        top: 20,
        right: 30,
        left: 30
      },
      bodyStyles: {
        fillColor: [245, 245, 245],
        textColor: [70, 70, 70],
        cellPadding: {
          top: 3,
          left: 5,
          right: 5,
          bottom: 3
        },
        tableLineColor: [210, 210, 210],
        tableLineWidth: 1
      },
      headStyles: {
        fillColor: [210, 210, 210],
        textColor: [70, 70, 70],
        0: {
          valign: 'middle',
          halign: 'left'
        }
      },
      didDrawPage: (data) => pageHeaderAndFooter(doc, data, 'Relatório de Análise Conteúdo'),
      didDrawCell: function (data) {
        if (data.column.index === 0 && data.cell.section === 'body' && data.row.index === count) {
          const possuiInconsistencia = expressoesMap.get(data.cell.raw.content.split(':')[0].trim())
          if (!_.isNil(possuiInconsistencia)) {
            const textPos = data.cell
            doc.addImage(possuiInconsistencia ? iconInfo : iconCheck, textPos.x, textPos.y, 11, 11)
          }
          count += 6
        }
      },
      willDrawCell: function (data) {
        if (data.column.index === 0 && data.cell.section === 'head') {
          data.cell.styles.halign = 'left'
        }
      }
    })
  return { posicaoX, posicaoY: doc.lastAutoTable.finalY }
}
