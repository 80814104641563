import React, { Component, Fragment } from 'react'
import ModalConfirmacao from '../../../comum/ModalConfirmacao'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TitleTableCell from '../../../comum/TitleTableCell'
import { Checkbox, FormControl, FormControlLabel, Switch, TableCell, Typography, TextField, Box } from '@material-ui/core'
import StyledTableRow from '../../../comum/StyledTableRow'
import TableBody from '@material-ui/core/TableBody'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import { context_httppost } from '../../../../js/httpRequest'
import { mapStateToPropsContexto, showErrorMsg, showSuccessMsg } from '../../../../js/util'
import { connect } from 'react-redux'

const defaultState = {
  itensMarcados: new Map(),
  itemAgrupadorSelecionado: '_',
  marcarTodos: false,
  customInput: '',
}

class ModalAgrupamentoPersonalizado extends Component {
  static propTypes = {
    aberto: PropTypes.bool.isRequired,
    numeroErros: PropTypes.number,
    fechar: PropTypes.func.isRequired,
    contexto: PropTypes.object,
    possivelErro: PropTypes.object,
    campo: PropTypes.string,
    atualizar: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = defaultState
    this.selecionarItem = this.selecionarItem.bind(this)
    this.selecionarTodos = this.selecionarTodos.bind(this)
    this.selecionarItemAgrupador = this.selecionarItemAgrupador.bind(this)
    this.fechar = this.fechar.bind(this)
    this.confirmar = this.confirmar.bind(this)
  }

  fechar() {
    var inicialState = {
      itensMarcados: new Map(),
      itemAgrupadorSelecionado: '_',
      marcarTodos: false,
    }
    this.setState(inicialState)
    this.props.fechar()
  }

  confirmar() {
    const { contexto, campo, atualizar } = this.props
    var itemAgrupador = this.state.itemAgrupadorSelecionado
    const customInput = this.state.customInput

    const erro = this.props.possivelErro
    const codigosSubstituidos =
      campo === 'codServico'
        ? this.recuperaCodigosParaSubstituicao(erro, itemAgrupador)
        : []
    const descricoesSubstituidas =
      campo === 'descServico'
        ? this.recuperaDescricoesParaSubstituicao(erro)
        : []
    const unidadesSubstituidas =
      campo === 'unidade'
        ? this.recuperaUnidadesParaSubstituicao(erro, itemAgrupador)
        : []
    const precosUnitariosSubstituidos =
      campo === 'precoUnitario'
        ? this.recuperaPrecosUnitariosParaSubstituicao(erro, itemAgrupador)
        : []

    if ('_' === itemAgrupador) {
      showErrorMsg('Escolha o item em que deseja agrupar.')
      return
    } else if ('customInput' === itemAgrupador && !(descricoesSubstituidas.length > 1)) {
      showErrorMsg('Escolha pelo menos dois itens para agrupar e customizar.')
      return
    } else if ('customInput' === itemAgrupador && descricoesSubstituidas.length > 1) {
      itemAgrupador = customInput
    }

    const agrupamento = this.montaAgrupamento(
      erro,
      campo,
      itemAgrupador,
      codigosSubstituidos,
      descricoesSubstituidas,
      unidadesSubstituidas,
      precosUnitariosSubstituidos
    )

    context_httppost('possiveis-erros/agrupamento', contexto, agrupamento).then(
      (dados) => {
        if (dados) {
          atualizar(dados)
          this.fechar()
          showSuccessMsg('Itens agrupados com sucesso.')
        }
      }
    )
  }

  recuperaCodigosParaSubstituicao(erro, itemAgrupador) {
    const codigos = erro.possiveisCodigos
    return codigos.isEmpty
      ? erro.possivelErro.codServico
      : codigos
          .filter(
            (it) =>
              it.codServico !== itemAgrupador &&
              this.state.itensMarcados.get(it.codServico)
          )
          .map((it) => (it.codServico ? it.codServico : null))
  }

  recuperaDescricoesParaSubstituicao(erro) {
    const descricoes = erro.possiveisDescricoes
    return descricoes.isEmpty
      ? erro.possivelErro.descServico
      : descricoes
          .filter(
            (it) =>
              this.state.itensMarcados.get(it.descServico)
          )
          .map((it) => (it.descServico ? it.descServico : null))
  }

  recuperaUnidadesParaSubstituicao(erro, itemAgrupador) {
    const unidades = erro.possiveisUnidades
    return unidades.isEmpty
      ? erro.possivelErro.unidade
      : unidades
          .filter(
            (it) =>
              it.unidade !== itemAgrupador &&
              this.state.itensMarcados.get(it.unidade)
          )
          .map((it) => (it.unidade ? it.unidade : null))
  }

  recuperaPrecosUnitariosParaSubstituicao(erro, itemAgrupador) {
    const precos = erro.possiveisPrecosUnitarios
    return precos.isEmpty
      ? erro.possivelErro.precoUnitario
      : precos.filter((it) => it.precoUnitarioFormatado !== itemAgrupador && this.state.itensMarcados.get(it.precoUnitarioFormatado))
          .map((it) => (it.precoUnitarioFormatado ? it.precoUnitarioFormatado : null))
  }

  // eslint-disable-next-line class-methods-use-this
  montaAgrupamento(
    erro,
    campo,
    itemAgrupador,
    codigosSubstituidos,
    descricoesSubstituidas,
    unidadesSubstituidas,
    precosUnitariosSubstituidos
  ) {
    return {
      possivelErro: erro.possivelErro,
      dados: {
        codServico: campo === 'codServico' ? itemAgrupador : erro.codServico,
        descServico: campo === 'descServico' ? itemAgrupador : erro.descServico,
        unidade: campo === 'unidade' ? itemAgrupador : erro.unidade,
        precoUnitario: campo === 'precoUnitario' ? itemAgrupador : erro.possivelErro.precoUnitario,
        codigosSubstituidos: codigosSubstituidos,
        descricoesSubstituidas: descricoesSubstituidas,
        unidadesSubstituidas: unidadesSubstituidas,
        precosUnitariosSubstituidos: precosUnitariosSubstituidos,
      }
    }
  }

  selecionarTodos(ev, campo) {
    let mapCodigos = this.state.itensMarcados
    if (campo === 'codServico') {
      this.props.possivelErro.possiveisCodigos.map((it) =>
        mapCodigos.set(
          it.codServico === '' ? null : it.codServico,
          ev.target.checked
        )
      )
    }
    if (campo === 'descServico') {
      this.props.possivelErro.possiveisDescricoes.map((it) =>
        mapCodigos.set(
          it.descServico === '' ? null : it.descServico,
          ev.target.checked
        )
      )
    }
    if (campo === 'unidade') {
      this.props.possivelErro.possiveisUnidades.map((it) => mapCodigos.set(it.unidade === '' ? null : it.unidade, ev.target.checked))
    }
    if (campo === 'precoUnitario') {
      this.props.possivelErro.possiveisPrecosUnitarios.map((it) =>
        mapCodigos.set(
          it.precoUnitarioFormatado === '' ? null : it.precoUnitarioFormatado,
          ev.target.checked
        )
      )
    }

    this.setState({
      itensMarcados: mapCodigos,
      marcarTodos: ev.target.checked,
    })
  }

  selecionarItem(ev) {
    let mapCodigos = this.state.itensMarcados
    mapCodigos.set(
      ev.target.value === '' ? null : ev.target.value,
      ev.target.checked
    )
    this.setState({
      itensMarcados: mapCodigos,
    })
    if (!ev.target.checked) {
      this.setState({
        marcarTodos: ev.target.checked,
      })
    }
  }

  selecionarItemAgrupador(ev) {
    this.setState({ itemAgrupadorSelecionado: ev.target.value })
  }

  handleCustomInputChange = (event) => {
    this.setState({ customInput: event.target.value });
  };

  render() {
    const { numeroErros, aberto, possivelErro, campo } = this.props

    const { itensMarcados, marcarTodos } = this.state
    if (numeroErros === undefined || numeroErros === null) {
      return null
    }
    return (
      aberto && (
        <ModalConfirmacao
          open={aberto}
          onClose={this.fechar}
          confirmar={this.confirmar}
          titulo={<Fragment>Agrupar itens</Fragment>}
        >
          <Typography variant="subtitle2">
            1. Selecione quais itens serão agrupados
          </Typography>
          {
            <Table>
              <TableHead>
                <TableRow>
                  <TitleTableCell key={'selecao'}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={marcarTodos}
                            onChange={(e) => { this.selecionarTodos(e, campo) }}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="Selecionar todos"
                      />
                    </FormControl>
                  </TitleTableCell>
                  <TitleTableCell key={'codigo'}>Código</TitleTableCell>
                  <TitleTableCell key={'descricao'}>Descrição</TitleTableCell>
                  <TitleTableCell key={'unidade'}>Unidade</TitleTableCell>
                  <TitleTableCell key={'preco'} align={'right'}>
                    Preço Unitário
                  </TitleTableCell>
                  <TitleTableCell key={'qtditens'} align={'right'}>
                    Qtd itens
                  </TitleTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(possivelErro.possivelErro.tipoErro === 'CODIGOS_DIFERENTES' ||
                  campo === 'codServico') &&
                  possivelErro.possiveisCodigos.map((it) => (
                    <StyledTableRow key={it.codServico}>
                      <TableCell>
                        <FormControl>
                          <Checkbox
                            color={'primary'}
                            key={it.codServico}
                            value={it.codServico ? it.codServico : ''}
                            defaultChecked={false}
                            checked={
                              itensMarcados.get(it.codServico)
                                ? itensMarcados.get(it.codServico)
                                : false
                            }
                            onChange={(e) => {
                              this.selecionarItem(e)
                            }}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <b>{it.codServico}</b>
                      </TableCell>
                      <TableCell>
                        {possivelErro.possivelErro.descServico
                          ? possivelErro.possivelErro.descServico
                          : 'várias'}
                      </TableCell>
                      <TableCell>
                        {possivelErro.possivelErro.unidade
                          ? possivelErro.possivelErro.unidade
                          : possivelErro.possiveisUnidades.map(
                              (u) =>
                                (possivelErro.possiveisUnidades.length > 1
                                  ? ' | '
                                  : '') + u.unidade
                            )}
                      </TableCell>
                      <TableCell align={'right'}>
                        {possivelErro.possivelErro.precoUnitarioFormatado
                          ? possivelErro.possivelErro.precoUnitarioFormatado
                          : 'vários'}
                      </TableCell>
                      <TableCell align={'right'}>
                        {it.quantidadeItens}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                {(possivelErro.possivelErro.tipoErro ===
                  'DESCRICOES_DIFERENTES' ||
                  campo === 'descServico') &&
                  possivelErro.possiveisDescricoes.map((it) => (
                    <StyledTableRow key={it.descServico}>
                      <TableCell>
                        <FormControl>
                          <Checkbox
                            color={'primary'}
                            key={it.descServico}
                            value={it.descServico ? it.descServico : ''}
                            defaultChecked={false}
                            checked={
                              itensMarcados.get(it.descServico)
                                ? itensMarcados.get(it.descServico)
                                : false
                            }
                            onChange={(e) => {
                              this.selecionarItem(e)
                            }}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        {possivelErro.possivelErro.codServico}
                      </TableCell>
                      <TableCell>
                        <b>{it.descServico}</b>
                      </TableCell>
                      <TableCell>
                        {possivelErro.possivelErro.unidade
                          ? possivelErro.possivelErro.unidade
                          : possivelErro.possiveisUnidades.map(
                              (u) =>
                                (possivelErro.possiveisUnidades.length > 1
                                  ? ' | '
                                  : '') + u.unidade
                            )}
                      </TableCell>
                      <TableCell align={'right'}>
                        {possivelErro.possivelErro.precoUnitarioFormatado
                          ? possivelErro.possivelErro.precoUnitarioFormatado
                          : 'vários'}
                      </TableCell>
                      <TableCell align={'right'}>
                        {it.quantidadeItens}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                {(possivelErro.possivelErro.tipoErro ===
                  'UNIDADES_DIFERENTES' ||
                  campo === 'unidade') &&
                  possivelErro.possiveisUnidades.map((it) => (
                    <StyledTableRow key={it.unidade}>
                      <TableCell>
                        <FormControl>
                          <Checkbox
                            color={'primary'}
                            key={it.unidade}
                            value={it.unidade ? it.unidade : ''}
                            defaultChecked={false}
                            checked={
                              itensMarcados.get(it.unidade)
                                ? itensMarcados.get(it.unidade)
                                : false
                            }
                            onChange={(e) => {
                              this.selecionarItem(e)
                            }}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        {possivelErro.possivelErro.codServico
                          ? possivelErro.possivelErro.codServico
                          : 'vários'}
                      </TableCell>
                      <TableCell>
                        {possivelErro.possivelErro.descServico
                          ? possivelErro.possivelErro.descServico
                          : 'várias'}
                      </TableCell>
                      <TableCell>
                        <b>{it.unidade}</b>
                      </TableCell>
                      <TableCell align={'right'}>
                        {possivelErro.possivelErro.precoUnitarioFormatado
                          ? possivelErro.possivelErro.precoUnitarioFormatado
                          : 'vários'}
                      </TableCell>
                      <TableCell align={'right'}>
                        {it.quantidadeItens}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                {campo === 'precoUnitario' &&
                  possivelErro.possiveisPrecosUnitarios.map((it) => (
                    <StyledTableRow key={it.precoUnitarioFormatado}>
                      <TableCell>
                        <FormControl>
                          <Checkbox
                            color={'primary'}
                            key={it.possivelPreco}
                            value={
                              it.precoUnitarioFormatado ? it.precoUnitarioFormatado : ''
                            }
                            defaultChecked={false}
                            checked={
                              itensMarcados.get(it.precoUnitarioFormatado)
                                ? itensMarcados.get(it.precoUnitarioFormatado)
                                : false
                            }
                            onChange={(e) => {
                              this.selecionarItem(e)
                            }}
                          />
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        {possivelErro.possivelErro.codServico
                          ? possivelErro.possivelErro.codServico
                          : 'vários'}
                      </TableCell>
                      <TableCell>
                        {possivelErro.possivelErro.descServico
                          ? possivelErro.possivelErro.descServico
                          : 'várias'}
                      </TableCell>
                      <TableCell>
                        {possivelErro.possivelErro.unidade
                          ? possivelErro.possivelErro.unidade
                          : possivelErro.possiveisUnidades.map(
                              (u) =>
                                (possivelErro.possiveisUnidades.length > 1
                                  ? ' | '
                                  : '') + u.unidade
                            )}
                      </TableCell>
                      <TableCell align={'right'}>
                        <b>{it.precoUnitarioFormatado}</b>
                      </TableCell>
                      <TableCell align={'right'}>
                        {it.quantidadeItens}
                      </TableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          }
          <Typography variant="subtitle2">
            2. Deseja agrupar em qual item ?
          </Typography>
          <FormControl variant="outlined" fullWidth>
            {(possivelErro.possivelErro.tipoErro === 'CODIGOS_DIFERENTES' ||
              campo === 'codServico') && (
              <Select
                title={'Código'}
                value={
                  this.state.itemAgrupadorSelecionado
                    ? this.state.itemAgrupadorSelecionado
                    : '_'
                }
                onChange={(e) => {
                  this.selecionarItemAgrupador(e)
                }}
              >
                <MenuItem key={'_'} value={'_'}>
                  {' '}
                  Escolha um código
                </MenuItem>
                {possivelErro.possiveisCodigos.map(
                  (it) =>
                    it.codServico &&
                    it.codServico.length > 0 &&
                    itensMarcados.get(it.codServico) && (
                      <MenuItem key={it.codServico} value={it.codServico}>
                        {it.codServico}
                      </MenuItem>
                    )
                )}
              </Select>
            )}
            {(possivelErro.possivelErro.tipoErro === 'DESCRICOES_DIFERENTES' ||
              campo === 'descServico') && (
              <Select
                title={'Descrição'}
                value={
                  this.state.itemAgrupadorSelecionado
                    ? this.state.itemAgrupadorSelecionado
                    : '_'
                }
                onChange={(e) => {
                  this.selecionarItemAgrupador(e)
                }}
              >
                <MenuItem key={'_'} value={'_'}>
                  {' '}
                  Escolha uma descrição de serviço
                </MenuItem>
                {possivelErro.possiveisDescricoes.map(
                  (it) =>
                    it.descServico &&
                    it.descServico.length > 0 &&
                    itensMarcados.get(it.descServico) && (
                      <MenuItem key={it.descServico} value={it.descServico}>
                        {it.descServico}
                      </MenuItem>
                    )
                )}
                <MenuItem key={'customInput'} value={'customInput'} >
                  <em>Agrupar em uma descrição customizada</em>
                </MenuItem>
              </Select>
            )}
            {(this.state.itemAgrupadorSelecionado === 'customInput') && (
              <Box mt={2}>
                <Typography variant="subtitle2">
                  3. Informe a descrição desejada
                </Typography>
                <TextField
                  variant="outlined"
                  placeholder="Insira uma descrição customizada"
                  fullWidth={true}
                  value={this.state.customInput}
                  onChange={this.handleCustomInputChange}
                />
              </Box>
            )}
            {(possivelErro.possivelErro.tipoErro === 'UNIDADES_DIFERENTES' ||
              campo === 'unidade') && (
              <Select
                title={'Unidade'}
                value={
                  this.state.itemAgrupadorSelecionado
                    ? this.state.itemAgrupadorSelecionado
                    : '_'
                }
                onChange={(e) => {
                  this.selecionarItemAgrupador(e)
                }}
              >
                <MenuItem key={'_'} value={'_'}>
                  {' '}
                  Escolha uma unidade
                </MenuItem>
                {possivelErro.possiveisUnidades.map(
                  (it) =>
                    it.unidade &&
                    it.unidade.length > 0 &&
                    itensMarcados.get(it.unidade) && (
                      <MenuItem key={it.unidade} value={it.unidade}>
                        {it.unidade}
                      </MenuItem>
                    )
                )}
              </Select>
            )}
            {campo === 'precoUnitario' && (
              <Select
                title={'Preço unitário'}
                value={
                  this.state.itemAgrupadorSelecionado
                    ? this.state.itemAgrupadorSelecionado
                    : '_'
                }
                onChange={(e) => {
                  this.selecionarItemAgrupador(e)
                }}
              >
                <MenuItem key={'_'} value={'_'}>
                  {' '}
                  Escolha um valor de preço unitário
                </MenuItem>
                {possivelErro.possiveisPrecosUnitarios.map(
                  (it) => it.precoUnitarioFormatado && it.precoUnitarioFormatado.length > 0 &&
                    itensMarcados.get(it.precoUnitarioFormatado) && (
                      <MenuItem
                        key={it.precoUnitarioFormatado}
                        value={it.precoUnitarioFormatado}
                      >
                        {it.precoUnitarioFormatado}
                      </MenuItem>
                    )
                )}
              </Select>
            )}
          </FormControl>
        </ModalConfirmacao>
      )
    )
  }
}

export default connect(mapStateToPropsContexto)(ModalAgrupamentoPersonalizado)
