import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import TitleTableCell from '../../../../comum/TitleTableCell'
import Paginacao from '../../../../comum/Paginacao'
import ItemVincularTipologiaCombinada from './ItemVincularTipologiaCombinada'

const TabelaVincularTipologiasCombinadas = ({
  classes,
  tipologiasCombinadas,
  tipologiasSelecionadas,
  onSelecionarTipologia
}) => {
  const [pagina, setPagina] = useState(0)
  const [registrosPorPagina, setRegistrosPorPagina] = useState(10)

  const handleTipologiaIsChecked = (tipologia) => tipologiasSelecionadas.indexOf(tipologia.tipologiaCombinada.codigo) !== -1

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TitleTableCell width='5%' />
            <TitleTableCell width='25%'>Nome</TitleTableCell>
            <TitleTableCell width='25%'>Orçamento</TitleTableCell>
            <TitleTableCell width='25%'>Obra</TitleTableCell>
            <TitleTableCell align='right'>Empreendimento</TitleTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { tipologiasCombinadas.slice(pagina * registrosPorPagina, pagina * registrosPorPagina + registrosPorPagina).map((tipologia) => (
            <ItemVincularTipologiaCombinada
              key={tipologia.tipologiaCombinada.codigo}
              classes={classes}
              tipologia={tipologia}
              isDisabled={false}
              isChecked={handleTipologiaIsChecked(tipologia)}
              onSelecionarTipologia={onSelecionarTipologia}
            />
          ))
          }
        </TableBody>
      </Table>
      <Paginacao
        total={tipologiasCombinadas.length}
        registrosPorPagina={registrosPorPagina}
        pagina={pagina}
        onChangePage={(e, pag) => setPagina(pag)}
        onChangeRowsPerPage={(e) => setRegistrosPorPagina(e.target.value)}
      />
    </React.Fragment>
  )
}

TabelaVincularTipologiasCombinadas.propTypes = {
  classes: PropTypes.object,
  tipologiasCombinadas: PropTypes.array,
  tipologiasSelecionadas: PropTypes.array,
  onSelecionarTipologia: PropTypes.func.isRequired
}

export default TabelaVincularTipologiasCombinadas
