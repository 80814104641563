import axios from 'axios'
import { showErrorMsg, url } from '../../js/util'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'

const salvarInformacoesGeraisOrcamento = async (orcamento, idEmpreendimento, idObra) => {
    await axios.put(`${url({ idEmpreendimento, idObra, idOrcamento: orcamento.cod })}`,
    orcamento, SLOW_REQUEST_CONFIG)
    .then(() => {
    })
    .catch((e) => {
      showErrorMsg(`Erro inesperado: ${e.message}`)
    })
}

export default salvarInformacoesGeraisOrcamento
