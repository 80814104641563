import React, { Component } from 'react'
import TableCell from '@material-ui/core/TableCell'
import PropTypes from 'prop-types'
import { Link } from '@material-ui/core'
import filtrarAnaliseOrcamento from '../../../../../../actions/orcamentos/analise/filtrarAnaliseOrcamento'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class CelulaDescricao extends Component {
  static propTypes = {
    exibir: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    match: PropTypes.object,
    history: PropTypes.object,
    filtrarAnaliseOrcamento: PropTypes.func,
    colSpan: PropTypes.number
  }

  constructor (props) {
    super(props)
    this.handleFiltroAnaliseOrcamento = this.handleFiltroAnaliseOrcamento.bind(this)
  }

  handleFiltroAnaliseOrcamento () {
    const { item, match, history, filtrarAnaliseOrcamento } = this.props
    filtrarAnaliseOrcamento(item.descricaoOrcamento)
    history.push(match.url.replace('abc', 'analisar'))
  }

  render () {
    const { exibir, item } = this.props
    return exibir && <React.Fragment>
      <TableCell style={{borderBottom: 'none'}}/>
      <TableCell colSpan='100%' style={{border: 'none', borderLeft: '4px solid #93afd8'}}>
        {/* <strong>Descrição:</strong>  */}
        <Link href="#" onClick={this.handleFiltroAnaliseOrcamento}>{`${item.descricaoOrcamento}`}</Link>
      </TableCell>
    </React.Fragment>
  }
}

export default connect(null, { filtrarAnaliseOrcamento })(withRouter(CelulaDescricao))
