import React, { Component } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import { Button } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import fileDownload from 'js-file-download'
import PropTypes from 'prop-types'
import * as ColunasTabelaInconsistencia from './ColunasTabelaInconsistencia'

class MenuPossiveisInconsistencias extends Component {

  exportarPlanilha = () => {
    const { inconsistencia, itens } = this.props
    let arquivo = ''
    const { colunas } = this.props
    if (itens[0].itemCurvaAbc) {
      colunas.forEach((col) => {arquivo += `${col};`})
      arquivo += '\n'
    }
    itens.forEach((item) => {
      arquivo += `${ this.seInconsistenciaDeCurvaABC(inconsistencia) ? this.lineItemArquivoInconsistenciaAbc(item) : this.lineItemArquivo(item) }`
    })
    fileDownload(new Blob(['\ufeff', arquivo]), `${ inconsistencia.titulo }_possível-inconsistencia.csv`, 'text/csv')
  }

  seInconsistenciaDeCurvaABC = (inconsistencia) => {
    return inconsistencia.url.includes('data-base-diferente') ||
      inconsistencia.url.includes('sobrepreco-negativo') ||
      inconsistencia.url.includes('maos-de-obra-unidades-diferentes') ||
      inconsistencia.url.includes('maos-de-obra-encargos-sociais-diferentes') ||
      inconsistencia.url.includes('servicos_insumos_custo_igual_zero')
  }

  lineItemArquivoInconsistenciaAbc = (item) => {
    const { colunas } = this.props
    let linhaItem = ''
    if(colunas.includes(ColunasTabelaInconsistencia.CODIGO_SERVICO)) {
      linhaItem += item.itemCurvaAbc.codigoServico ? `${item.itemCurvaAbc.codigoServico};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.DESCRICAO_SERVICO)) {
      linhaItem += item.itemCurvaAbc.descricaoOrcamento ? `${item.itemCurvaAbc.descricaoOrcamento};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.UNIDADE)) {
      linhaItem += item.itemCurvaAbc.unidadeOrcamento ? `${item.itemCurvaAbc.unidadeOrcamento};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.QUANTIDADE)) {
      linhaItem += item.itemCurvaAbc.quantidade ? `${item.itemCurvaAbc.quantidade};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.PRECO_UNITARIO)) {
      linhaItem += item.itemCurvaAbc.precoUnitarioFormatado ? `${item.itemCurvaAbc.precoUnitarioFormatado};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.PRECO_UNITARIO_COM_BDI)) {
      linhaItem += item.itemCurvaAbc.precoUnitarioOrcamento ? `${item.itemCurvaAbc.precoUnitarioOrcamento};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.PRECO_PARCIAL)) {
      linhaItem += item.itemCurvaAbc.precoParcialCalculadoFormatado ? `${item.itemCurvaAbc.precoParcialCalculadoFormatado};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.PRECO_REFERENCIA_COM_BDI)) {
      linhaItem += item.itemCurvaAbc.valorReferenciaComBdiFormatado ? `${item.itemCurvaAbc.valorReferenciaComBdiFormatado};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.PRECO_PARCIAL_REFERENCIA)) {
      linhaItem += item.itemCurvaAbc.precoParcialReferenciaFormatado ? `${item.itemCurvaAbc.precoParcialReferenciaFormatado};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.SUBPRECO)) {
      linhaItem += item.itemCurvaAbc.valorSobreprecoFormatado ? `${item.itemCurvaAbc.valorSobreprecoFormatado};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.MES)) {
      linhaItem += item.itemCurvaAbc.mes ? `${item.itemCurvaAbc.mes};`.trim(): ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.ANO)) {
      linhaItem += item.itemCurvaAbc.ano ? `${item.itemCurvaAbc.ano};`.trim(): ';'
    }

    linhaItem += '\n'

    return linhaItem
  }


  lineItemArquivo = (item) => {
    const { colunas } = this.props
    let linhaItem = ''

    if(colunas.includes(ColunasTabelaInconsistencia.CODIGO_SERVICO)) {
      linhaItem += item.codServico ? `${item.codServico};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.DESCRICAO_SERVICO)) {
      linhaItem += item.descServico ? `${item.descServico};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.UNIDADE)) {
      linhaItem += item.unidade ? `${item.unidade};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.QUANTIDADE)) {
      linhaItem += item.quantidade ? `${item.quantidade};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.PRECO_UNITARIO)) {
      linhaItem += item.precoUnitario ? `${item.precoUnitario};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.VALOR_PARCIAL)) {
      linhaItem += item.precoParcial ? `${item.precoParcial};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.INCIDENCIA)) {
      linhaItem += item.incidenciaPrecoParcial ? `${item.incidenciaPrecoParcial}%;`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.ERRO_DETECTADO)) {
      linhaItem += item.erroDetectadoFormatado ? `${item.erroDetectadoFormatado};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.MENOR_PRECO_ORCADO)) {
      linhaItem += item.menorPrecoOrcado ? `${item.menorPrecoOrcado};`.trim() : ';'
    }

    if(colunas.includes(ColunasTabelaInconsistencia.DIFERENCA)) {
      linhaItem += item.diferencaoMenorPrecoOrcadoFormatado ? `${item.diferencaoMenorPrecoOrcadoFormatado};`.trim(): ';'
    }

    linhaItem += '\n'

    return linhaItem
  }

  render () {
    return <div style={{display: 'flex', justifyContent:'flex-end'}}>
      <Tooltip title='Exportar planilha'>
        <Button color='primary' variant='text' onClick={() => this.exportarPlanilha() }>
          <SaveAltIcon fontSize='small' /> Fazer download
        </Button>
      </Tooltip>

    </div>
  }
}

MenuPossiveisInconsistencias.propTypes = {
  inconsistencia: PropTypes.object,
  colunas: PropTypes.array.isRequired,
  itens: PropTypes.array.isRequired
}

export default withRouter(MenuPossiveisInconsistencias)
