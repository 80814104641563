import React from 'react'
import { withStyles } from '@material-ui/core'
import styles from '../../styles'
import PropTypes from 'prop-types'
import CampoTipoOrcamento from './CampoTipoOrcamento'
import CampoObservacao from './CampoObservacao'
import CampoUnidadeParametrica from './CampoUnidadeParametrica'
import CampoQuantidadeParametrica from './CampoQuantidadeParametrica'
import CampoBdiGestor from './CampoBdiGestor'
import CampoEncargosSociais from './CampoEncargosSociais'

const InformacoesGerais = ({
  classes,
  tipoOrcamento,
  tipoOrcamentoEmpty,
  onTipoOrcamentoChange,
  observacao,
  onObservacaoChange,
  unidadeParametrica,
  unidadeParametricaEmpty,
  onUnidadeParametricaChange,
  quantidadeParametrica,
  quantidadeParametricaEmpty,
  onQuantidadeParametricaChange,
  bdi,
  bdiEmpty,
  onBdiChange,
  encargosSociais,
  encargosSociaisEmpty,
  encargosSociaisRequired,
  onEncargosSociaisChange
}) => {
  return (
    <div className={classes.grupoInfoGeral2}>
      <CampoTipoOrcamento
        tipoOrcamento={tipoOrcamento}
        tipoOrcamentoEmpty={tipoOrcamentoEmpty}
        onTipoOrcamentoChange={onTipoOrcamentoChange}
      />
      <CampoObservacao
        observacao={observacao}
        onObservacaoChange={onObservacaoChange}
      />
      <div className={classes.doisCamposFormulario}>
        <CampoUnidadeParametrica
          unidadeParametrica={unidadeParametrica}
          unidadeParametricaEmpty={unidadeParametricaEmpty}
          handleUnidadeParametricaChange={onUnidadeParametricaChange}
        />
        <CampoQuantidadeParametrica
          quantidadeParametrica={quantidadeParametrica}
          quantidadeParametricaEmpty={quantidadeParametricaEmpty && unidadeParametrica !== 'NAO_SE_APLICA'}
          onQuantidadeParametricaChange={onQuantidadeParametricaChange}
          disabled={unidadeParametrica === 'NAO_SE_APLICA'}
        />
      </div>
      <div className={classes.doisCamposFormulario}>
        <CampoBdiGestor
          bdi={bdi}
          bdiEmpty={bdiEmpty}
          onBdiChange={onBdiChange}
        />
        <CampoEncargosSociais
          encargosSociais={encargosSociais}
          encargosSociaisEmpty={encargosSociaisEmpty && encargosSociaisRequired}
          onEncargosSociaisChange={onEncargosSociaisChange}
        />
      </div>
    </div>
  )
}

InformacoesGerais.propTypes = {
  classes: PropTypes.object,
  tipoOrcamento: PropTypes.string,
  tipoOrcamentoEmpty: PropTypes.bool,
  onTipoOrcamentoChange: PropTypes.func.isRequired,
  observacao: PropTypes.string,
  onObservacaoChange: PropTypes.func.isRequired,
  unidadeParametrica: PropTypes.string,
  unidadeParametricaEmpty: PropTypes.bool,
  onUnidadeParametricaChange: PropTypes.func.isRequired,
  quantidadeParametrica: PropTypes.string,
  quantidadeParametricaEmpty: PropTypes.bool,
  onQuantidadeParametricaChange: PropTypes.func.isRequired,
  bdi: PropTypes.string,
  bdiEmpty: PropTypes.bool,
  onBdiChange: PropTypes.func.isRequired,
  encargosSociais: PropTypes.string,
  encargosSociaisEmpty: PropTypes.bool,
  encargosSociaisRequired: PropTypes.bool,
  onEncargosSociaisChange: PropTypes.func.isRequired
}

export default withStyles(styles)(InformacoesGerais)
