import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import ItemVincularExpressoes from './ItemVincularExpressoes'
import _ from 'lodash'
import TitleTableCell from '../../../../comum/TitleTableCell'
import Paginacao from '../../../../comum/Paginacao'

const TabelaVincularExpressoes = ({
  classes,
  expressoes,
  expressoesSelecionadas,
  onSelecionarExpressao
}) => {
  const [pagina, setPagina] = useState(0)
  const [registrosPorPagina, setRegistrosPorPagina] = useState(10)

  const handleExpressaoIsChecked = (expressao) => {
    return expressoesSelecionadas.map(it => _.isNil(it.codExpressaoOriginal) ? it.codExpressao : it.codExpressaoOriginal).indexOf(expressao.codExpressao) !== -1
  }

  const handleDesabilitarExpressao = (expressao) => {
    return expressoesSelecionadas.some(exp => exp.codExpressaoOriginal === expressao.codExpressao)
  }

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TitleTableCell width='5%' />
            <TitleTableCell width='25%'>Nome</TitleTableCell>
            <TitleTableCell width='25%'>Orçamento</TitleTableCell>
            <TitleTableCell width='25%'>Obra</TitleTableCell>
            <TitleTableCell align='right'>Empreendimento</TitleTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { expressoes.slice(pagina * registrosPorPagina, pagina * registrosPorPagina + registrosPorPagina).map((expressao) => (
            <ItemVincularExpressoes
              key={expressao.codExpressao}
              classes={classes}
              expressao={expressao}
              isDisabled={handleDesabilitarExpressao(expressao)}
              isChecked={handleExpressaoIsChecked(expressao)}
              onSelecionarExpressao={onSelecionarExpressao}
            />
          ))
          }
        </TableBody>
      </Table>
      <Paginacao
        total={expressoes.length}
        registrosPorPagina={registrosPorPagina}
        pagina={pagina}
        onChangePage={(e, pag) => setPagina(pag)}
        onChangeRowsPerPage={(e) => setRegistrosPorPagina(e.target.value)}
      />
    </React.Fragment>
  )
}

TabelaVincularExpressoes.propTypes = {
  classes: PropTypes.object,
  expressoes: PropTypes.array,
  expressoesSelecionadas: PropTypes.array,
  onSelecionarExpressao: PropTypes.func.isRequired
}
export default TabelaVincularExpressoes
