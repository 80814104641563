import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

export const ItemDefaultValues = {
  state: {
    item: {
      codigoOrcamento: 0,
      codigoServico: '',
      descricaoOrcamento: '',
      unidadeOrcamento: '',
      quantidade: 0,
      precoUnitarioOrcamento: 0,
      precoParcialInformado: 0,
      bdi: 0,
      incluirNaAmostra: false,
      sistemaReferencia: '',
      valorSobrepreco: '',
      unidadeReferencia: '',
      precoParcialReferencia: '',
    },
    composicao: null,
    equipamento: null,
    material: null,
    maoDeObra: null,
    metodoCalculoSobrepreco: null,
  },
  setState: () => null,
}

export const ItemContext = createContext(ItemDefaultValues)

const ItemProvider = ({ children }) => {
  const [state, setState] = useState({
    item: {
      codigoOrcamento: 0,
      codigoServico: '',
      descricaoOrcamento: '',
      unidadeOrcamento: '',
      quantidade: 0,
      precoUnitarioOrcamento: 0,
      precoParcialInformado: 0,
      bdi: 0,
      incluirNaAmostra: false,
      sistemaReferencia: '',
      valorSobrepreco: '',
      unidadeReferencia: '',
    },
    composicao: {},
    equipamento: null,
    material: null,
    maoDeObra: null,
    metodoCalculoSobrepreco: null,
  })

  return (
    <ItemContext.Provider value={{ state: state, setState }}>
      {children}
    </ItemContext.Provider>
  )
}

ItemProvider.propTypes = {
  children: PropTypes.node,
}

const useItem = () => useContext(ItemContext)

export { ItemProvider, useItem }
