import React from 'react'
import { FormControl, MenuItem, Select, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  combo: {
    backgroundColor: '#fff',
    width: 550
  }
}))

const CampoFiltro = ({ filtro, values, onFiltroChange }) => {
  const classes = useStyles()
  return (
    <FormControl variant='outlined'>
      <Typography variant='subtitle1'>
         Filtrar por
      </Typography>
      <Select
        name={'filtro'}
        value={filtro || ''}
        className={classes.combo}
        onChange={(e) => onFiltroChange(e.target.value)}>
        {values.map((it) => (
          <MenuItem key={it.value} value={it}>
            <Typography>{it.label}</Typography>
          </MenuItem>))
        }
      </Select>
    </FormControl>
  )
}

CampoFiltro.propTypes = {
  filtro: PropTypes.object,
  values: PropTypes.array.isRequired,
  onFiltroChange: PropTypes.func.isRequired
}
export default CampoFiltro
