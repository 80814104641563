import { withStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'

const StyledTableRowDestaque = withStyles(() => ({
  root: {
    backgroundColor: '#4a4a4a',
    color: '#fff !important',
    fontWeight: 'bold'
  },
}))(TableRow);

export default StyledTableRowDestaque
