import React from 'react'
import Arquivo from './Arquivo'
import FormularioDadosIniciais from './FormularioDadosIniciais'
import { withStyles } from '@material-ui/core'
import styles from '../styles'
import PropTypes from 'prop-types'
import { validarArquivo } from '../cadastrar-orcamento'
import { calcularResumoArquivoOrcamento } from '../../../../js/orcamento'

const handleArquivoCarregado = (arquivo, onArquivoCarregado) => {
  if (validarArquivo(arquivo)) {
    const reader = new FileReader()
    reader.readAsText(arquivo, 'ISO-8859-1')
    reader.onload = () => {

      //const regex = new RegExp(/(\r\n)/gm).test(reader.result) ? /(\r\n)/gm : /(\n)/gm
      //const resumo = calcularResumoArquivoOrcamento(reader.result.split(regex).filter(it => it !== '\r\n'))

      const lines = reader.result.split(/\r?\n/);
      const resumo = calcularResumoArquivoOrcamento(lines);

      onArquivoCarregado({
        nome: arquivo.name,
        resumo,
        itensOrcamento: resumo.itensOrcamento,
        tamanho: arquivo.size
      })
    }
  }
}

const DadosIniciais = ({
  classes,
  arquivo,
  onArquivoCarregado,
  descricao,
  descricaoEmpty,
  onDescricaoChange,
  sigiloso,
  onSigilosoChange,
  confirmado,
  exibirCancelarArquivo,
  onConfirmarClick,
  onCancelarClick
}) => {
  return (
    <div className={classes.grupoDadosIniciais}>
      <Arquivo arquivo={arquivo} onArquivoCarregado={(file) => handleArquivoCarregado(file, onArquivoCarregado)} />
      <FormularioDadosIniciais
        exibir={arquivo != null}
        confirmado={confirmado}
        exibirCancelarArquivo={exibirCancelarArquivo}
        descricao={descricao}
        descricaoEmpty={descricaoEmpty}
        onDescricaoChange={onDescricaoChange}
        sigiloso={sigiloso}
        onSigilosoChange={onSigilosoChange}
        onConfirmarClick={onConfirmarClick}
        onCancelarClick={onCancelarClick}
      />
    </div>
  )
}

DadosIniciais.propTypes = {
  classes: PropTypes.object,
  arquivo: PropTypes.object,
  onArquivoCarregado: PropTypes.func.isRequired,
  descricao: PropTypes.string,
  descricaoEmpty: PropTypes.bool,
  onDescricaoChange: PropTypes.func.isRequired,
  sigiloso: PropTypes.bool,
  onSigilosoChange: PropTypes.func.isRequired,
  confirmado: PropTypes.bool.isRequired,
  exibirCancelarArquivo: PropTypes.bool.isRequired,
  onConfirmarClick: PropTypes.func.isRequired,
  onCancelarClick: PropTypes.func.isRequired
}

export default withStyles(styles)(DadosIniciais)
