const styles = (theme) => ({
  // Novo card de inconsistencia
  linkIncon: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontWeight: '500'
  },
  listagem: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  },
  cardInconsistencia: {
    borderBottom: 'solid #ccc 1px',
    position: 'relative',
    flexDirection: 'row',
    display: 'flex',
    width: '100%'
  },
  colunaAnalise: {
    borderRight: 'solid #ccc 1px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    marginRight: 8,
    paddingRight: 8,
    paddingTop: 6,
    width: 120
  },
  colunaAnaliseFormulaCombinada: {
    borderRight: 'solid #ccc 1px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginRight: 8,
    paddingRight: 8,
    paddingTop: 6,
    paddingBottom: 6,
    width: 120
  },
  iconePadrao: {
    color: '#41c88a'
  },
  linhasTexto: {
    display: 'flex',
    alignItems: 'space-around',
    flexDirection: 'column',
    width: '100%'
  },
  linhaTitulo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  colunaValorAcao: {
    display: 'flex'
  },
  textoValor: {
    paddingTop: 6,
    fontWeight: 500,
    width: 200
  },
  cardSemInconsistencia: {
    border: 'solid #ccc 1px',
    borderRadius: '2px',
    padding: 12,
    position: 'relative',
    justifyContent: 'space-between',
    flexDirection: 'column',
    display: 'flex',
    marginBottom: 8,
    marginRight: 8,
    width: '100%',
    opacity: 0.8,
    '& :hover': {
      backgroundColor: '#fafafa'
    }
  },
  cardFooter: {
    width: 100,
    display: 'flex',
    paddingRight: 8,
    justifyContent: 'flex-end'
  },
  linkDetalhar: {
    display: 'flex',
    alignItems: 'center',
    textDecorator: 'none'
  },
  infoIcon: {
    marginRight: 4
  },
  item: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 2,
    color: '#fff',
    padding: '1px 4px'
  },
  semTipologia: {
    padding: [[4, 16]]
  },
  possivelInconsitenciaErro: {
    display: 'flex',
    alignItems: 'center',
    color: '#ffaa5f'
  },
  listaResultadoTipologia: {
    display: 'flex',
    paddingLeft: 0,
    margin: 0,
    listStyle: 'none',
    '& li:first-child': {
      paddingRight: '1ch'
    },
    '& li:not(:first-child)': {
      paddingRight: '1ch'
    },
    '& li:not(:first-child)::before': {
      color: '#aaa',
      content: '"•"',
      marginRight: '1ch'
    }
  },
  colunaResumo1: {
    display: 'flex',
    justifyContent: 'left',
    width: '100%'
  },
  columaResumo2: {
    display: 'column',
    justifyContent: 'left',
    width: '100%'
  }
})

export default styles
