import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import { BUSCAR_ORCAMENTOS } from '../types'

const buscarOrcamentos = (idEmpreendimento, idObra) => async (dispatch) => {
  if (!idObra || !idEmpreendimento) throw Error('erro aqui')
  const success = await axios.get(`/api/empreendimentos/${idEmpreendimento}/obras/${idObra}/orcamentos`, SLOW_REQUEST_CONFIG)
  return dispatch({
    type: BUSCAR_ORCAMENTOS,
    payload: success
  })
}

export default buscarOrcamentos
