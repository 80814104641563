import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { mapStateToPropsContexto, showSuccessMsg } from '../../../../../js/util'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import _ from 'lodash'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import MesmaLinha from '../../../../comum/MesmaLinha'
import IconButton from '@material-ui/core/IconButton'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import EditIcon from '@material-ui/icons/Edit'
import { context_httpput } from '../../../../../js/httpRequest'

const defaultState = {
  editar: false,
  observacao: ''
}

class CampoObservacaoItemCurvaAbc extends Component {

  static propTypes = {
    itemCurvaAbc: PropTypes.object.isRequired,
    onItemAlterado: PropTypes.func,
    contexto: PropTypes.object,
    classes: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = defaultState
  }

  componentDidMount() {
    if (!_.isNil(this.props.itemCurvaAbc)) {
      this.copiarDadosParaEstado()
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const naoAlterouEstado = this.state.observacao === prevState.observacao
    const seNavegouParaOutroItemCurvaAbc = this.props.itemCurvaAbc.observacao !== prevState.observacao
    if (!_.isNil(prevState) && seNavegouParaOutroItemCurvaAbc && naoAlterouEstado) {
      this.copiarDadosParaEstado()
    }
  }

  copiarDadosParaEstado = () => {
    const { itemCurvaAbc } = this.props
    this.setState({
      observacao: itemCurvaAbc.observacao
    })
  }

  handleChange(event) {
    const { name, value } = event.target
    const state = {}
    state[name] = value
    this.setState(state)
  }

  handleCancelarEdicao = () => {
    this.copiarDadosParaEstado()
    this.setState({ editar: false })
  }

  salvar = () => {
    const { contexto, itemCurvaAbc, onItemAlterado } = this.props
    const { observacao } = this.state

    context_httpput(`curva-abc/itens/${itemCurvaAbc.id}/observacao`, contexto, { observacao })
      .then(() => {
        showSuccessMsg('Observação salva com sucesso.')
        onItemAlterado(itemCurvaAbc, { ...itemCurvaAbc, observacao })
        this.setState({ editar: false })
      })
  }

  textoObservacao = () => {
    const { classes } = this.props
    const { observacao } = this.state

    return (
      <Typography className={classes.itensCabecalho}>{observacao || '-'}</Typography>
    )
  }

  render() {
    const { itemCurvaAbc } = this.props
    const { editar, observacao } = this.state

    if (itemCurvaAbc.analiseConcluida) {
      return this.textoObservacao()
    }

    return (
      <Fragment>
        {
          editar ? (
            <Fragment>
              <TextField
                fullWidth
                name={'observacao'}
                variant='outlined'
                value={observacao}
                onChange={(e) => this.handleChange(e)}
              />
              <MesmaLinha>
                <IconButton color='primary' onClick={() => this.salvar()}>
                  <DoneIcon/>
                </IconButton>
                <IconButton color='primary' onClick={() => this.handleCancelarEdicao()}>
                  <ClearIcon/>
                </IconButton>
              </MesmaLinha>
            </Fragment>
          ) : (
            <Fragment>
              {this.textoObservacao()}
              <IconButton color='primary' onClick={() => this.setState({editar: true})}>
                <EditIcon/>
              </IconButton>
            </Fragment>
          )
        }
      </Fragment>
    )
  }
}

export default connect(mapStateToPropsContexto)(withStyles(styles)(CampoObservacaoItemCurvaAbc))
