import React, { Component } from 'react'
import { Button, Dialog, DialogActions, DialogContent, TextField, withStyles } from '@material-ui/core'
import styles from './styles'
import TituloModal from '../../../comum/TituloModal'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import { context_httpput } from '../../../../js/httpRequest'
import { showErrorMsg, showSuccessMsg } from '../../../../js/util'

class ModalIncluirNaAmostra extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    contexto: PropTypes.object,
    classes: PropTypes.object
  }

  constructor (props) {
    super(props);
    this.state = {
      valorPercentual : 80
    }
  }

  alterarPercentual(valor){
    if(valor < 0 || valor > 100) return
    this.setState({valorPercentual : valor})
  }

  confirmar () {
    const { contexto, onChange } = this.props
    const {valorPercentual} = this.state
    const novosDados = context_httpput('curva-abc/atribuir-amostra/percentual-acumulado/'+ valorPercentual, contexto, {}).then(
      () => {showSuccessMsg('Atribuição de amostra executada com sucesso')
        onChange(novosDados)
        this.props.close()
        this.setState({valorPercentual : 80})
      }
    ).catch(() => showErrorMsg('Erro na atribuição de amostra'))

  }

  fechar = () => this.props.close()

  render() {
    const {open, classes} = this.props


    return  open ? (
      <Dialog
        open={true}
        onClose={() => this.fechar()}
        aria-labelledby='form-dialog-title'
        fullWidth
      >

        <TituloModal onClose={this.fechar}>
          Incluir na amostra
        </TituloModal>

        <DialogContent>

          <Typography className={classes.textoExcluirCurvaABC} variant={'h8'}>
            Inclui automaticamente na amostra todos os itens que possuem uma referência de preço válido
            É possível personalizar com base no percental acumulado (coluna %acum) a faixa que será incluída na amostra.
          </Typography>

          <Typography className={classes.textoExcluirCurvaABC} variant={'h6'} color={'primary'}>
            Selecione o valor percentual acumulado que deseja incluir na amostra
          </Typography>
          <TextField
            id="standard-number"
            label="  %"
            type="number"
            defaultValue={80}
            onChange={(ev) => this.alterarPercentual( ev.target.value)}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{step: 5}}
            value={this.state.valorPercentual}
          />

        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={() => this.fechar()}>CANCELAR</Button>
          <Button variant='contained' color={'primary'} onClick={() => this.confirmar()}>CONFIRMAR</Button>
        </DialogActions>
      </Dialog>
    ) : <div/>
  }
}

const mapStateToProps = ({contexto}) => ({contexto})

export default connect(mapStateToProps)(withStyles(styles)(ModalIncluirNaAmostra))
