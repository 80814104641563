const styles = (theme) => ({
  secaoCurvaABC: {
    display: 'flex',
    flexFlow: 'column',
    height: ' calc(100vh - 160px)'
  },
  paper: {
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1)
  },
  paperABC: {
    flexGrow: 1
  },
  boxLimite: {
    height: 'calc(100vh - 315px)'
  },
  boxLimiteCabecalhoRecolhido: {
    height: 'calc(100vh - 205px)'
  },
  tabelaCaixa: {
    height: '100%',
    overflow: 'scroll',
    '& td': {
      border: 'none'
    }
  },
  tabelaABC: {
    '& tr:last-child > td': {
      borderBottom: '1px solid #e5e5e6 !important'
    }
  },
  rootTableCurvaAbcInsumo: {
    width: '100%'
  },
  paperTableCurvaAbcInsumo: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  tituloModal: {
    fontSize: '1.4rem',
    fontFamily: 'Roboto',
    fontWeight: 400,
    letterSpacing: '0.00735em',
    color: '#fff',
    marginBottom: 0
  }
})

export default styles
