import React, { Component } from 'react'
import { DialogActions, Link, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'
import { context_httppost } from '../../../../js/httpRequest'
import { mapStateToPropsContexto, showErrorMsg, showSuccessMsg } from '../../../../js/util'
import Template from '../../../layout/Template'
import Cabecalho from '../orcamento-bruto/Cabecalho'
import { connect } from 'react-redux'
import TituloModal from '../../../comum/TituloModal'
import Divider from '@material-ui/core/Divider/Divider'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import Dialog from '@material-ui/core/Dialog/Dialog'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../../../js/operationProgressUtil'

class CurvaAbcNaoCriada extends Component {
  static propTypes = {
    resumoOrcamento: PropTypes.object.isRequired,
    onCurvaAbcCriada: PropTypes.func.isRequired,
    history: PropTypes.object,
    match: PropTypes.object,
    contexto: PropTypes.object
  }

  static MSG_EXISTE_CATALOGO = 'Os preços deste orçamento já foram importados anteriormente, deseja manter a ' +
    'importação anterior?'

  static MSG_CATALOGO_ALTERADO = 'Os preços deste orçamento já foram importados anteriormente, e foram efetuadas ' +
    'customizações nestes preços, deseja manter a importação anterior e as modificações realizadas?'

  constructor (props) {
    super(props)
    this.state = {
      modalAberta: false,
      msgUsuario: ''
    }
    this.linkPossiveisErrosClick = this.linkPossiveisErrosClick.bind(this)
    this.handleCriarCurvaAbcClick = this.handleCriarCurvaAbcClick.bind(this)
    this.criarCurvaAbc = this.criarCurvaAbc.bind(this)
    this.exibicaoDeModal = this.exibicaoDeModal.bind(this)
  }
  
  async handleCriarCurvaAbcClick () {
    const { contexto } = this.props
    const urlBase = `/api/empreendimentos/${contexto.empreendimento.cod}/obras/${contexto.obra.cod}/orcamentos/${contexto.orcamento.cod}`
    axios.get(urlBase + '/se-possui-catalogo-de-preco', SLOW_REQUEST_CONFIG).then(
      async (catalogoCriado) => {
        if (catalogoCriado.status === 200) {
          if(catalogoCriado.data=== true){
            axios.get(urlBase + '/se-referencia-preco-alterada', SLOW_REQUEST_CONFIG).then(
              (precoAlterado) => {
                if(precoAlterado.status === 200){
                  let mensagem =  precoAlterado.data===true?CurvaAbcNaoCriada.MSG_CATALOGO_ALTERADO: CurvaAbcNaoCriada.MSG_EXISTE_CATALOGO
                  this.setState({modalAberta:true, msgUsuario: mensagem})
                } else {
                  showErrorMsg('Desculpe, ocorreu um erro inesperado')
                }
              }
            )
          }else{
            await this.criarCurvaAbc(true)
          }
        } else {
          showErrorMsg('Desculpe, ocorreu um erro inesperado')
        }
      }
    )
  }



  async criarCurvaAbc (seCriaCatalogo) {
    const { contexto, onCurvaAbcCriada  } = this.props
    const curvaAbcComResumo = await context_httppost('curva-abc', contexto,
      {seCriaCatalogo: seCriaCatalogo.toString()})
    if (curvaAbcComResumo) {
     
      await onCurvaAbcCriada(curvaAbcComResumo)
      
      const curvaAbcInsumoComResumo = await context_httppost('curva-abc-insumo', contexto)
    
      await showSuccessMsg('Curvas ABC de Serviços e Insumos criadas!')
      window.location.reload(false)
    }
  }

   criarCurvaAbcInsumo = async () => {
    const { contexto  } = this.props
    const curvaAbcComResumo = await context_httppost('curva-abc-insumo', contexto)
    if (curvaAbcComResumo) {
     // showSuccessMsg('Curva ABC de insumo criada')
      //window.location.reload(false)
    }
  }

  linkPossiveisErrosClick() {
    const { history, match } = this.props
    history.push(match.url.replace('abc', 'possiveis-erros'))
  }

  exibicaoDeModal() {
    return <Dialog id='mdlConfirmacao' open={this.state.modalAberta} fullWidth disableEnforceFocus>
      <TituloModal onClose={()=>this.setState({modalAberta:false})}>
        Confirmação de criação da Curva ABC
      </TituloModal>

      <Divider />

      <DialogContent >
        <Typography variant='subtitle2'>{this.state.msgUsuario} </Typography>
      </DialogContent>

      <DialogActions>
        <Button id='btnCancelar'     variant='outlined' color='primary'
                onClick={()=>this.setState({modalAberta:false})}>Cancelar</Button>
        <Button id='btnConfirmarSim' variant='contained' color='primary'
                onClick={()=>this.criarCurvaAbc(false)}>Manter preços</Button>
        <Button id='btnConfirmarNao' variant='contained' color='primary'
                onClick={()=>this.criarCurvaAbc(true)}>Recriar catálogo de preços</Button>
      </DialogActions>
    </Dialog>
  }

  criacaoCurvaAbc() {
    return (
      <>
         <Alert severity="info">Agora que não há mais possíveis erros pendentes de análise, é possível gerar a curva ABC. Esta operação pode ser um pouco demorada.</Alert>
        <Box mt={2}>
          <Button variant='contained' color='primary' onClick={this.handleCriarCurvaAbcClick}>
            Criar Curva ABC
          </Button>
        </Box>
        {
          this.exibicaoDeModal()
        }

      </>
    )
  }

  render () {
    const { resumoOrcamento } = this.props
    return <Template>
      <Cabecalho
        numeroPossiveisErros={resumoOrcamento.numeroPossiveisErros}
        numeroPossiveisInconsistencias={resumoOrcamento.numeroPossiveisInconsistencias}
        titulo='Curva ABC'
      />
      {
        resumoOrcamento.numeroPossiveisErros > 0 && !resumoOrcamento.encerrouPossiveisErros ? (
          <Alert severity="info">
            A curva ABC ainda não foi gerada para este orçamento.
            É necessário concluir o <Link onClick={this.linkPossiveisErrosClick}>tratamento de possíveis erros</Link> para que seja possível gerar a curva ABC.
          </Alert>
        ) : this.criacaoCurvaAbc()
      }
    </Template>
  }
}

export default connect(mapStateToPropsContexto)(withRouter(CurvaAbcNaoCriada))
