import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import PropTypes from 'prop-types'

import { Modal } from '@material-ui/core'
import styles from './ProgressDialogStyles'

class ProgressDialog extends Component {
  static propTypes = {
    operationProgress: PropTypes.object
  }

  render () {
    const { operationProgress: { operationInProgress } } = this.props
    return (
      <Modal open={operationInProgress} disableBackdropClick>
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          outline: 'none',
          transform: 'translate(-50%, -50%)',
        }}>
          <CircularProgress />
        </div>
      </Modal>
    )
  }
}

const materialUIEnhanced = withStyles(styles)(ProgressDialog)

const mapStateToProps = ({ operationProgress }) => ({ operationProgress })

export default connect(mapStateToProps)(materialUIEnhanced)
