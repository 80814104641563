import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, CircularProgress, Divider, FormControl, Paper, Select, TextField, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import buscarTodosEmpreendimentos from '../../actions/empreendimentos/buscarTodosEmpreendimentos'
import PropTypes from 'prop-types'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { ptBR } from 'date-fns/locale'
import DateFnsUtils from '@date-io/date-fns'
import buscarTiposObra from '../../actions/obras/buscarTiposObras'
import { Autocomplete } from '@material-ui/lab'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import { UFS } from '../../js/util'
import MenuItem from '@material-ui/core/MenuItem'
import axios from 'axios'
import _ from 'lodash'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'

import useStyles from './styles'

import CustomScroller from 'react-custom-scroller'
import buscarTiposOrcamentos from '../../actions/orcamentos/buscarTiposOrcamentos'
import moment from 'moment'

const AnaliseRiscoPesquisa = ({ buscarTodosEmpreendimentos, buscarTiposObra, buscarTiposOrcamentos, onChangeOrcamentos }) => {
  const [uf, setUF] = useState(null)
  const [open, setOpen] = useState(false)
  const [dataFim, setDataFim] = useState(new Date())
  const [loading, setLoading] = useState(false)
  const [dataInicio, setDataInicio] = useState(new Date())
  const [tiposObra, setTiposObras] = useState([])
  const [tiposOrcamento, setTiposOrcamento] = useState([])
  const [tiposObraSelecionadas, setTiposObrasSelecionadas] = useState([])
  const [tiposOrcamentoSelecionados, setTiposOrcamentoSelecionados] = useState([])
  const [unidadesJurisdicionadas, setUnidadesJurisdicionadas] = useState([])
  const [unidadesJurisdicionadasSelecionadas, setUnidadesJurisdicionadasSelecionadas] = useState([])
  const [empreendimentos, setEmpreendimentos] = useState([])
  const [empreendimentosSelecionados, setEmpreendimentosSelecionados] = useState([])
  const [usuarioCadastrador, setUsuarioCadastrador] = useState(null)

  const classes = useStyles()

  useEffect(() => {
    const obterEmprendimentos = async () => {
      const { payload } = await buscarTodosEmpreendimentos()
      setEmpreendimentos(payload.data)
    }

    const obterTiposObra = async () => {
      const { payload } = await buscarTiposObra()
      setTiposObras(payload.data)
    }

    const obterTiposOrcamento = async () => {
      const { payload } = await buscarTiposOrcamentos()
      setTiposOrcamento(payload.data)
    }

    obterEmprendimentos()
    obterTiposObra()
    obterTiposOrcamento()
  }, [])

  const handleBuscaUj = async (e) => {
    setLoading(true)
    const { data } = await axios.get(`/api/ujs?nome=${e}`)
    setUnidadesJurisdicionadas(data)
    setLoading(false)
  }

  const handleObterUjs = _.debounce((text) => {
    if (text && text.length > 3) {
      handleBuscaUj(text)
    }
  }, 1000)

  const handlePesquisar = async () => {
    const filtro = {
      dataInicio,
      dataFim,
      uf,
      empreendimentos: empreendimentosSelecionados.map(it => it.cod),
      tiposDeObras: tiposObraSelecionadas.map(it => it.id),
      unidadesJurisdicionadas: unidadesJurisdicionadasSelecionadas.map(it => it.id),
      tiposDeOrcamentos: tiposOrcamentoSelecionados.map(it => it.id),
      usuarioCadastrador: usuarioCadastrador
    }
    const { data } = await axios.post('/api/analise-risco/orcamentos', filtro, SLOW_REQUEST_CONFIG)
    if (data) {
      onChangeOrcamentos(data)
    }
  }

  return (
    <Paper variant='outlined' elevation={0} className={classes.containerPesquisa}>
      <Box p={1} >
        <Typography variant='h6' className={classes.stepTitle}>FILTROS</Typography>
        <CustomScroller style={{ height: '55vh' }}>
          <Box mt={1} pb={1}>
            <Typography variant='subtitle2' ml={6}>Período</Typography>
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
                <Box mr={1}>
                  <KeyboardDatePicker
                    disableToolbar
                    inputVariant='outlined'
                    format='dd/MM/yyyy'
                    margin='normal'
                    label='Data início'
                    className={classes.boxInput}
                    cancelLabel='Cancelar'
                    invalidDateMessage='Data em formato inválido.'
                    value={dataInicio}
                    onChange={(data) => setDataInicio(data)}
                    minDateMessage={`A data deve ser maior que ${moment(Date.parse('01/01/2000')).format('DD/MM/YYYY')}.`}
                    InputProps={{
                      style: {
                        marginBottom: 0,
                        fontSize: 14,
                        height: 60,
                        width: 180,
                      }
                    }}
                  />
                </Box>

                <KeyboardDatePicker
                  disableToolbar
                  inputVariant='outlined'
                  format='dd/MM/yyyy'
                  margin='normal'
                  label='Data fim'
                  className={classes.boxInput}
                  value={dataFim}
                  cancelLabel='Cancelar'
                  invalidDateMessage='Data em formato inválido.'
                  minDateMessage={`A data deve ser maior que ${moment(Date.parse('01/01/2000')).format('DD/MM/YYYY')}.`}
                  onChange={(data) => setDataFim(data)}
                  InputProps={{
                    style: {
                      marginBottom: 0,
                      fontSize: 14,
                      height: 60,
                      width: 180
                    }
                  }}
                />
              </Box>
            </MuiPickersUtilsProvider>
            <Box mt={1} mb={2}>
              <Typography variant='subtitle2'>Empreendimentos</Typography>
              <Box className={classes.boxInput}>
                <Autocomplete
                  multiple
                  options={empreendimentos}
                  disableCloseOnSelect
                  getOptionLabel={(selected) => selected.nome}
                  onChange={(e, values) => setEmpreendimentosSelecionados(values)}
                  renderOption={(empreendimento, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                        checkedIcon={<CheckBoxIcon fontSize='small' />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {empreendimento.nome}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant='outlined' placeholder='Escolha os empreendimentos' />
                  )}
                  InputProps={{
                    style: {
                      marginBottom: 0,
                      marginRight: 4,
                      fontSize: 14
                    }
                  }}
                />
              </Box>
            </Box>
            <Box mt={1} mb={2}>
              <Typography variant='subtitle2'>Tipo de obra</Typography>
              <Box className={classes.boxInput}>
                <Autocomplete
                  multiple
                  options={tiposObra}
                  disableCloseOnSelect
                  getOptionLabel={(tipo) => tipo.descricao}
                  onChange={(e, values) => setTiposObrasSelecionadas(values)}
                  renderOption={(tipo, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                        checkedIcon={<CheckBoxIcon fontSize='small' />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {tipo.descricao}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant='outlined' placeholder='Tipo de obra' />
                  )}
                />
              </Box>
            </Box>
            <Box mt={1} mb={2}>
              <Typography variant='subtitle2'>Tipo de orçamento</Typography>
              <Box className={classes.boxInput}>
                <Autocomplete
                  multiple
                  options={tiposOrcamento}
                  disableCloseOnSelect
                  getOptionLabel={(tipo) => tipo.descricao}
                  onChange={(e, values) => setTiposOrcamentoSelecionados(values)}
                  renderOption={(tipo, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                        checkedIcon={<CheckBoxIcon fontSize='small' />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {tipo.descricao}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} variant='outlined' placeholder='Tipo de orçamento' />
                  )}
                />
              </Box>
            </Box>
            <Box mt={1} mb={2}>
              <Typography variant='subtitle2'>Cadastrado por</Typography>
              <Box className={classes.boxInput}></Box>
                <TextField value={usuarioCadastrador}
                  onChange={(e) => { setUsuarioCadastrador(e.target.value) }}
                  fullWidth
                  variant='outlined' />
              </Box>
            <Box mt={1} mb={2}>
              <Typography variant='subtitle2'>Jurisdicionado</Typography>
              <Box className={classes.boxInput}>
                <Autocomplete
                  multiple
                  open={open}
                  onOpen={() => { setOpen(true) }}
                  onClose={() => { setOpen(false) }}
                  options={unidadesJurisdicionadas}
                  loading={loading}
                  disableCloseOnSelect
                  getOptionLabel={(unidade) => unidade.nome}
                  onInputChange={(e) => handleObterUjs(e.target.value)}
                  onChange={(e, values) => setUnidadesJurisdicionadasSelecionadas(values)}
                  renderOption={(tipo, { selected }) => (
                    <React.Fragment>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize='small' />}
                        checkedIcon={<CheckBoxIcon fontSize='small' />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {tipo.nome}
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      placeholder='Jurisdicionado'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            { loading ? (<CircularProgress color='primary' size={20} />) : null }
                            { params.InputProps.endAdornment }
                          </>
                        )
                      }}
                    />
                  )}
                />
              </Box>
            </Box>
            <Box mt={1} mb={2}>
              <FormControl variant='outlined' fullWidth>
                <Typography variant='subtitle2'>UF do sistema de referência</Typography>
                <Select
                  label='UF'
                  labelId='uf'
                  value={uf}
                  onChange={(e) => setUF(e.target.value)}
                >
                  { UFS.map((uf) => (
                    <MenuItem value={uf.sigla} key={uf.sigla}>
                      <Typography>{uf.nome}</Typography>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </CustomScroller>
        <Box py={2}>
          <Divider />
        </Box>
        <Box p={0} m={0}>
          <Button
            variant='contained'
            color='secondary'
            fullWidth
            onClick={handlePesquisar}
          >
            Pesquisar
          </Button>
        </Box>
      </Box>
    </Paper>
  )
}

AnaliseRiscoPesquisa.propTypes = {
  buscarTodosEmpreendimentos: PropTypes.func
}

const mapStateToProps = (state) => ({
  empreendimentos: state.empreendimentos,
  tiposObra: state.tiposObra
})
export default connect(mapStateToProps, { buscarTodosEmpreendimentos, buscarTiposObra, buscarTiposOrcamentos })(AnaliseRiscoPesquisa)
