import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { converterMoedaBRParaNumero, mapStateToPropsContexto, seStringVazia, showSuccessMsg } from '../../../../../../../../js/util'
import { context_httppost } from '../../../../../../../../js/httpRequest'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FormularioMaterialComposicao from './FormularioMaterialComposicao'

class FormularioAdicionarMaterialComposicao extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired,
    contexto: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      codigo: '',
      codigoEmpty: false,
      descricao: '',
      descricaoEmpty: false,
      unidade: '',
      unidadeEmpty: false,
      quantidade: '0,00',
      quantidadeEmpty: false,
      precoUnitario: '0,00',
      precoUnitarioEmpty: false
    }
    this.handleSalvarClick = this.handleSalvarClick.bind(this)
    this.handleCampoTextoChange = this.handleCampoTextoChange.bind(this)
  }

  handleCampoTextoChange (event, campo) {
    const state = {}
    state[campo] = event.target.value
    state[`${campo}Empty`] = seStringVazia(event.target.value)
    this.setState(state)
  }

  async handleSalvarClick () {
    const {
      codigo,
      descricao,
      unidade,
      quantidade,
      precoUnitario
    } = this.state
    const codigoEmpty = seStringVazia(codigo)
    const descricaoEmpty = seStringVazia(descricao)
    const unidadeEmpty = seStringVazia(unidade)
    const quantidadeEmpty = seStringVazia(quantidade)
    const precoUnitarioEmpty = seStringVazia(precoUnitario)

    const empties = [
      codigoEmpty,
      descricaoEmpty,
      unidadeEmpty,
      quantidadeEmpty,
      precoUnitarioEmpty
    ]

    if (empties.some(Boolean)) {
      this.setState({
        codigoEmpty,
        descricaoEmpty,
        unidadeEmpty,
        quantidadeEmpty,
        precoUnitarioEmpty
      })
    } else {
      const dadosSalvar = {
        codigo,
        descricao,
        unidade,
        quantidade: converterMoedaBRParaNumero(quantidade),
        precoUnitario: converterMoedaBRParaNumero(precoUnitario)
      }
      const { contexto, codigoComposicao, onDadosAlterados } = this.props
      const resultado = await context_httppost(`precos/materiais/criar?composicao=${codigoComposicao}`, contexto, dadosSalvar)
      if (resultado) {
        showSuccessMsg('Material criado e incluído na composição.')
        onDadosAlterados()
      }
    }
  }

  render () {
    const {
      codigo,
      codigoEmpty,
      descricao,
      descricaoEmpty,
      unidade,
      unidadeEmpty,
      quantidade,
      quantidadeEmpty,
      precoUnitario,
      precoUnitarioEmpty
    } = this.state

    const { onCancelar } = this.props

    return (
      <div>
        <Typography variant='h6' gutterBottom>Cadastrar Novo Material</Typography>
        <FormularioMaterialComposicao
          codigo={codigo}
          codigoEmpty={codigoEmpty}
          codigoEditavel
          descricao={descricao}
          descricaoEmpty={descricaoEmpty}
          unidade={unidade}
          unidadeEmpty={unidadeEmpty}
          quantidade={quantidade}
          quantidadeEmpty={quantidadeEmpty}
          precoUnitario={precoUnitario}
          precoUnitarioEmpty={precoUnitarioEmpty}
          handleCampoTextoChange={this.handleCampoTextoChange}
          onSalvar={this.handleSalvarClick}
          onCancelar={onCancelar}
        />
      </div>
    )
  }
}

export default connect(mapStateToPropsContexto)(FormularioAdicionarMaterialComposicao)
