import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Divider from '@material-ui/core/Divider'
import styles from '../styles'

const Arquivo = ({ classes }) => (
  <div className={classes.grupoDadosIniciais}>
    <Typography variant='h6' gutterBottom>Arquivo</Typography>
    <Typography variant='body2' className={classes.textoInstrucoes} gutterBottom>
      Informe o arquivo CSV com os dados do orçamento no qual devem constar, obrigatoriamente e na
      sequência, as seguintes colunas:
    </Typography>

    <Typography variant='body2' className={classes.textoInstrucoes} gutterBottom>
      1 - Código do serviço nos Sistemas de Referências (não obrigatório);
    </Typography>

    <Typography variant='body2' className={classes.textoInstrucoes} gutterBottom>
      2 - Descrição do serviço;
    </Typography>

    <Typography variant='body2' className={classes.textoInstrucoes} gutterBottom>
      3 - Unidade do serviço;
    </Typography>

    <Typography variant='body2' className={classes.textoInstrucoes} gutterBottom>
      4 - Quantidade do serviço;
    </Typography>

    <Typography variant='body2' className={classes.textoInstrucoes} gutterBottom>
      5 - Preço unitário (com BDI);
    </Typography>

    <Typography variant='body2' className={classes.textoInstrucoes} gutterBottom>
      6 - Preço parcial (Qtd x PU)
    </Typography>

    <Divider />
  </div>
)

Arquivo.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(Arquivo)
