import React, { Component } from 'react'
import _ from 'lodash'
import { context_httpget, context_httppost } from '../../../../../../../../js/httpRequest'
import { converterParaNumero, formatarNumeroParaMoedaBR, mapStateToPropsContexto, showAlertMsg, showSuccessMsg } from '../../../../../../../../js/util'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import { withStyles } from '@material-ui/core'
import styles from '../../../styles'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import StyledTableContainerAdicionar from '../../../../../../../comum/StyledTableContainerAdicionar'

class AdicionarMaoDeObraComposicaoSelecionarDoOrcamento extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired,
    contexto: PropTypes.object,
    classes: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      maosDeObra: [],
      maoDeObraSelecionada: null
    }
    this.handleConfirmarClick = this.handleConfirmarClick.bind(this)
  }

  componentDidMount () {
    if (!_.isNil(this.props.contexto)) {
      this.recuperarMaosDeObra()
    }
  }

  async recuperarMaosDeObra() {
    const { contexto } = this.props
    const data = await context_httpget('precos/insumos/maos-de-obra', contexto)
    if (data) {
      this.setState({
        maosDeObra: data
      })
    }
  }

  handleRowMaoDeObraClick (codigoMaoDeObra) {
    const { maoDeObraSelecionada } = this.state
    if (maoDeObraSelecionada !== codigoMaoDeObra) {
      this.setState({ maoDeObraSelecionada: codigoMaoDeObra })
    }
  }

  async handleConfirmarClick () {
    const { maoDeObraSelecionada } = this.state
    if (_.isNil(maoDeObraSelecionada)) {
      showAlertMsg('Por favor, selecione uma mão de obra para vincular à composição.')
      return
    }
    const { codigoComposicao, contexto, onDadosAlterados } = this.props
    const resultado = await context_httppost(`precos/vincular-mao-de-obra?composicao=${codigoComposicao}&mao-de-obra=${maoDeObraSelecionada}`, contexto)
    if (resultado) {
      showSuccessMsg(`Mão de obra ${maoDeObraSelecionada} vinculada à composição.`)
      onDadosAlterados()
    }
  }

  render() {
    const { classes, onCancelar } = this.props
    const { maosDeObra, maoDeObraSelecionada } = this.state

    return (
      <>
        <StyledTableContainerAdicionar>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Referência</TableCell>
                <TableCell>Data Referência</TableCell>
                <TableCell>Código</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Unidade</TableCell>
                <TableCell>Salário (R$)</TableCell>
                <TableCell>Encargos Totais</TableCell>
                <TableCell>Periculosidade/Insalubridade</TableCell>
                <TableCell>Custo</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                maosDeObra.map((it) => {
                  const estiloCelula = maoDeObraSelecionada === it.maoDeObraOrcamento.codigo ? { color: 'white' } : {}
                  return (
                    <TableRow
                      key={it.maoDeObraOrcamento.codigo}
                      className={maoDeObraSelecionada === it.maoDeObraOrcamento.codigo ? classes.linhaItemSelecionada : classes.linhaItem}
                      onClick={() => this.handleRowMaoDeObraClick(it.maoDeObraOrcamento.codigo)}>
                      <TableCell style={estiloCelula}>{it.maoDeObraOrcamento.sistemaReferencia}</TableCell>
                      <TableCell style={estiloCelula}>{it.maoDeObraOrcamento.mes}/{it.maoDeObraOrcamento.ano}</TableCell>
                      <TableCell style={estiloCelula}>{it.maoDeObraOrcamento.codigo}</TableCell>
                      <TableCell style={estiloCelula}>{it.maoDeObraOrcamento.descricao}</TableCell>
                      <TableCell style={estiloCelula}>{it.maoDeObraOrcamento.unidade}</TableCell>
                      <TableCell style={estiloCelula}>{formatarNumeroParaMoedaBR(it.maoDeObraOrcamento.salario, 2, 5)}</TableCell>
                      <TableCell style={estiloCelula}>{formatarNumeroParaMoedaBR(it.maoDeObraOrcamento.encargosTotais, 2, 5)}</TableCell>
                      <TableCell style={estiloCelula}>{formatarNumeroParaMoedaBR(it.maoDeObraOrcamento.periculosidadeInsalubridade, 2, 5)}</TableCell>
                      <TableCell style={estiloCelula}>{formatarNumeroParaMoedaBR(converterParaNumero(it.maoDeObraOrcamento.salario) * (1 + (converterParaNumero(it.maoDeObraOrcamento.encargosTotais) / 100) + (converterParaNumero(it.maoDeObraOrcamento.periculosidadeInsalubridade) / 100)), 2, 4)}</TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
        </StyledTableContainerAdicionar>
        <MesmaLinha>
          <Button color='primary' variant='contained' onClick={this.handleConfirmarClick}>Confirmar</Button>
          <Button color='primary' onClick={onCancelar}>Cancelar</Button>
        </MesmaLinha>
      </>
    )
  }
}

export default connect(mapStateToPropsContexto)(withStyles(styles)(AdicionarMaoDeObraComposicaoSelecionarDoOrcamento))
