import React, { Component } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { context_httpput } from '../../../../../../../js/httpRequest'
import { connect } from 'react-redux'
import { showSuccessMsg } from '../../../../../../../js/util'
import styles from './style'
import { withStyles } from '@material-ui/core'

class ModalObservacao extends Component {
  static propTypes = {
    exibir: PropTypes.bool.isRequired,
    fechar: PropTypes.func.isRequired,
    onComplete: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    contexto: PropTypes.object,
    classes: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      observacao: ''
    }
    this.cadastrar = this.cadastrar.bind(this)
    this.handleObservacaoChange = this.handleObservacaoChange.bind(this)
  }

  componentDidMount () {
    this.setState({ observacao: this.props.item.observacao })
  }

  componentDidUpdate (prevProps) {
    if(this.props.item !== prevProps.item) {
      this.setState({
        observacao: this.props.item.observacao
      })
    }
  }

  handleObservacaoChange (event) {
    const observacao = event.target.value
    this.setState({
      observacao
    })
  }

  async cadastrar () {
    const { observacao } = this.state
    const { contexto, onComplete, item, fechar } = this.props

    const result = await context_httpput(`curva-abc/itens/${item.id}/observacao`, contexto, { observacao })
    if (result) {
      showSuccessMsg('Observação atualizada')
      fechar()
      onComplete(item, { ...item, observacao })
    }
  }

  render () {
    const { exibir, fechar, classes } = this.props
    const { observacao } = this.state

    return exibir && (
      <Dialog
        open={true}
        onClose={fechar}
        fullWidth>

        <DialogTitle>
          Observação
        </DialogTitle>

        <DialogContent>
          <TextField
            autoFocus
            variant='outlined'
            multiline
            rows={3}
            className={classes.inputObservacao}
            value={observacao}
            fullWidth
            onChange={this.handleObservacaoChange}
          />
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={fechar} color='primary'>
            Cancelar
          </Button>
          <Button variant='contained' onClick={this.cadastrar} color='primary'>
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ contexto }) => ({
  contexto
})

export default connect(mapStateToProps)(withStyles(styles)(ModalObservacao))
