/* eslint-disable no-use-before-define */
import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Box, TextField, Typography } from '@material-ui/core'

import useStyles from './styles'

export default function Temas ({ temas, setTemasSelecionados }) {
  const listaTemas = [{ codigo: -1, title: '         ' }].concat(temas)

  const classes = useStyles()

  return (
    <div>
      <Typography variant='h6'  className={classes.stepTitle}>Temas</Typography>
      <Box py={2}>
        <Typography variant='subtitle2' gutterBottom>Adicione os temas para gerar o relátorio</Typography>
        <Box className={classes.boxInput}>
          <Autocomplete
            multiple
            id='analiseRiscoTemas'
            size='small'
            onChange={(event, value) => {
              const valores = value.filter((it) => it.codigo !== -1)
              setTemasSelecionados(valores)
            }}
            options={listaTemas}
            getOptionLabel={(option) => option.title}
            defaultValue={[listaTemas[0]]}
            renderInput={(params) => (
              <TextField {...params} variant='outlined' placeholder='Pesquise os temas' />
            )}
          />
        </Box>
      </Box>
    </div>
  )
}
