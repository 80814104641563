import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'

const StyledDrawer= withStyles((theme) => ({
  paperAnchorRight: {
    backgroundColor: theme.palette.background.default,
  },
}))(Drawer);

export default StyledDrawer
