import React from 'react'
import WarningIcon from '@material-ui/icons/Warning'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import List from '@material-ui/core/List'
import Alert from '@material-ui/lab/Alert'
import ExplicitIcon from '@material-ui/icons/Explicit'
import FunctionsIcon from '@material-ui/icons/Functions'
import DeviceHubIcon from '@material-ui/icons/DeviceHub'
import { context_httpdelete } from '../../../../../../../js/httpRequest'
import { showSuccessMsg } from '../../../../../../../js/util'
import ModalConfirmacao from '../../../../../../comum/ModalConfirmacao'
import ItensVinculadosExcluir from '../../comum/ItensVinculadosExcluir'

const ModalExcluirExpressao = ({
  expressao,
  expressoesVinculadas,
  formulasVinculadas,
  tipologiasCombinadas,
  fechar,
  onComplete,
  onExpressoesEFormulasVinculadasChange,
  contexto
}) => {
  const handleConfirmarClick = async () => {
    const { expressoes, formulas, tipologiasCombinadas } = await context_httpdelete(`analise-conteudo/expressoes/${expressao.codigo}`, contexto)
    if (expressoes.length === 0 && formulas.length === 0 && tipologiasCombinadas.length === 0) {
      showSuccessMsg('Expressão excluída')
      onComplete()
    } else {
      onExpressoesEFormulasVinculadasChange(expressoes, formulas, tipologiasCombinadas)
    }
  }

  return (
    <ModalConfirmacao
      open={Boolean(expressao)}
      onClose={fechar}
      confirmar={handleConfirmarClick}
      disabled={expressoesVinculadas.length > 0 || formulasVinculadas.length > 0 || tipologiasCombinadas.length > 0}
      titulo={
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <WarningIcon color='inherit' style={{ paddingRight: 4 }} />Excluir Expressão
        </span>
      }
    >
      {expressoesVinculadas.length > 0 || formulasVinculadas.length > 0 || tipologiasCombinadas.length > 0
        ? <React.Fragment>
          <Alert severity='warning'>
            Não foi possível excluir esta expressão, pois está em uso por outras Expressões, Fórmulas ou Tipologias Combinadas apresentadas abaixo.
          </Alert>
          <List>
            <ItensVinculadosExcluir values={formulasVinculadas} iconLabel={FunctionsIcon} iconText='Fórmula' showSecondaryTitle />
            <ItensVinculadosExcluir values={expressoesVinculadas} iconLabel={ExplicitIcon} iconText='Expressão' showSecondaryTitle />
            <ItensVinculadosExcluir values={tipologiasCombinadas} iconLabel={DeviceHubIcon} iconText='Tipologia Combinada' showSecondaryTitle={false} />
          </List>
        </React.Fragment>
        : <Alert severity='warning'>
          A exclusão da expressão resultará na exclusão de
          todas as tipologias e critérios relacionados. Deseja continuar?
        </Alert>
      }
    </ModalConfirmacao>
  )
}

ModalExcluirExpressao.propTypes = {
  expressao: PropTypes.object,
  expressoesVinculadas: PropTypes.array,
  formulasVinculadas: PropTypes.array,
  tipologiasCombinadas: PropTypes.array,
  fechar: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onExpressoesEFormulasVinculadasChange: PropTypes.func.isRequired,
  contexto: PropTypes.object
}

const mapStateToProps = ({ contexto }) => ({ contexto })
export default connect(mapStateToProps)(ModalExcluirExpressao)
