import React, { Component } from 'react'
import Box from '@material-ui/core/Box'
import Template from '../../../layout/Template'
import Cabecalho from '../orcamento-bruto/Cabecalho'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Carregando from '../../../comum/Carregando'
import Tipologias from './Tipologias'
import { context_httpget } from '../../../../js/httpRequest'
import { connect } from 'react-redux'

class PossiveisInconsistencias extends Component {
  static propTypes = {
    contexto: PropTypes.object,
    match: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      tipologiasBasicas: null,
      tipologiasTematicas: null,
      tipologiasDeAnalise: null,
      tipologiasPersonalizadas: null,
      resumoOrcamento: null
    }
  }

  componentDidMount () {
    if(this.props.contexto) {
      this.recuperarPossiveisInconsistencias()
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.contexto !== this.props.contexto) {
      this.recuperarPossiveisInconsistencias()
    }
  }

  async recuperarPossiveisInconsistencias () {
    const { contexto } = this.props
    const resultado = await context_httpget('possiveis-inconsistencias', contexto)
    if (resultado) {
      this.setState({
        tipologiasBasicas: resultado.tipologiasBasicas,
        tipologiasDeAnalise: resultado.tipologiasDeAnalise,
        tipologiasTematicas: resultado.tipologiasTematicas,
        tipologiasPersonalizadas: resultado.tipologiasPersonalizadas,
        resumoOrcamento: resultado.resumoOrcamento
      })
    }
  }

  handleAtualizarTipologiaCustoZero = (inconsistencia) => {
    const { tipologiasDeAnalise, resumoOrcamento } = this.state
    this.setState({
      tipologiasDeAnalise: tipologiasDeAnalise.map(it => it.titulo === inconsistencia.titulo ? inconsistencia : it ),
      resumoOrcamento: {...resumoOrcamento, numeroPossiveisInconsistencias: resumoOrcamento.numeroPossiveisInconsistencias}
    })
  }

  listagemTipologiasTematicas() {
    const { tipologiasTematicas } = this.state
    return tipologiasTematicas.map((tipologiaTematica, index) => {
      return (
        <Box mt={3} key={tipologiaTematica.nomeTema + index}>
          <Tipologias
            titulo= {`Tipologias de ${tipologiaTematica.nomeTema}`}
            value={tipologiaTematica.possivelInconsistencia || []}
          />
        </Box>
      )
    })
  }

  numeroPossiveisErros = () => this.state.resumoOrcamento.numeroPossiveisErros

  numeroPossiveisInconsistencias = () => this.state.resumoOrcamento.numeroPossiveisInconsistencias

  render () {
    const { tipologiasBasicas, tipologiasDeAnalise, tipologiasPersonalizadas, resumoOrcamento } = this.state

    if (_.isNil(tipologiasBasicas)) {
      return <Carregando />
    }

    return (
      <Template>
        <Cabecalho
          resumoOrcamento={resumoOrcamento}
          numeroPossiveisErros={this.numeroPossiveisErros()}
          numeroPossiveisInconsistencias={this.numeroPossiveisInconsistencias()}
          titulo='Possíveis Inconsistências'
        />
        <div>
          <Tipologias
              titulo='Tipologias de Dados Brutos'
              value={tipologiasBasicas}
            />
          <Box mt={3}>
            <Tipologias
              titulo='Tipologias da Curva ABC'
              value={tipologiasDeAnalise}
              onAtualizarTipologiaCustoZero={this.handleAtualizarTipologiaCustoZero}
            />
          </Box>
          <Box mt={3}>
            <Tipologias
              titulo='Tipologias Personalizadas'
              value={tipologiasPersonalizadas}
            />
          </Box>
          {
            this.listagemTipologiasTematicas()
          }
        </div>
      </Template>
    )
  }
}

const mapStateToProps = ({ contexto }) => ({ contexto })

export default connect(mapStateToProps)(PossiveisInconsistencias)
