import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2)
  },
  iconHelpAnaliseConteudo: {
    padding: 1,
    cursor: 'hover',
  }
}))

export default function SimplePopover ({ children }) {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }

  const handleClose = (event) => {
    event.stopPropagation()
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <div>
      <ContactSupportIcon color='primary' fontSize='small' className={classes.iconHelpAnaliseConteudo} onClick={handleClick} />
      <Popover
        id='simple-popover'
        open={open}
        anchorEl={anchorEl}
        onClose={(e) => handleClose(e)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          style: { width: '40%' }
        }}
      >
        <Typography className={classes.typography}>{children}</Typography>
      </Popover>
    </div>
  )
}

SimplePopover.propTypes = {
  children: PropTypes.node
}
