import React, { Component } from 'react'
import DadosIniciais from './DadosIniciais'
import PaperBox from '../../../comum/PaperBox'
import InformacoesGerais from './informacoes-gerais/InformacoesGerais'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { contemSomenteNumerosPositivos, converterMoedaBRParaNumero, showErrorMsg, showAlertMsg, showSuccessMsg, url } from '../../../../js/util'
import salvarInformacoesGeraisOrcamento from '../../../../actions/orcamentos/salvarInformacoesGeraisOrcamento'
import alterarOrcamento from '../../../../actions/orcamentos/alterarOrcamento'
import cadastrarOrcamento from '../../../../actions/orcamentos/cadastrarOrcamento'
import { calcularResumoArquivoOrcamento } from '../../../../js/orcamento'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../../../js/operationProgressUtil'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core'
import styles from '../styles'
import SendIcon from '@material-ui/icons/Send'
import { withRouter } from 'react-router-dom'
import concluirOrcamentoContexto from '../../../../actions/concluirOrcamentoContexto'
import ReferenciaPrecos from './ReferenciaPrecos'

export class Formulario extends Component {
  static propTypes = {
    classes: PropTypes.object,
    orcamento: PropTypes.object,
    idEmpreendimento: PropTypes.number.isRequired,
    idObra: PropTypes.number.isRequired,
    idOrcamento: PropTypes.number,
    alterarOrcamento: PropTypes.func,
    cadastrarOrcamento: PropTypes.func,
    salvarInformacoesGeraisOrcamento: PropTypes.func,
    concluirOrcamentoContexto: PropTypes.func,
    referenciasOrcamento: PropTypes.array,
    history: PropTypes.object.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      arquivo: null,
      descricao: '',
      descricaoEmpty: false,
      sigiloso: false,
      tipo: null,
      tipoEmpty: false,
      observacao: '',
      unidadeParametrica: null,
      unidadeParametricaEmpty: false,
      quantidadeParametrica: '',
      quantidadeParametricaEmpty: false,
      bdiGestor: '',
      bdiGestorEmpty: false,
      encargosSociaisEdital: null,
      encargosSociaisEditalEmpty: false,
      encargosSociaisEditalRequired: false,
      referencia: null,
      referenciaEmpty: false,
      arquivoFoiAlterado: false
    }
    this.handleArquivoCarregado = this.handleArquivoCarregado.bind(this)
    this.handleConfirmarClick = this.handleConfirmarClick.bind(this)
    this.handleCancelarClick = this.handleCancelarClick.bind(this)
    this.handleCampoChange = this.handleCampoChange.bind(this)
    this.handleSigilosoChange = this.handleSigilosoChange.bind(this)
    this.handleDescricaoChange = this.handleDescricaoChange.bind(this)
    this.handleEncargosSociaisChange = this.handleEncargosSociaisChange.bind(this)
    this.handleBdiChange = this.handleBdiChange.bind(this)
    this.handleQuantidadeParametricaChange = this.handleQuantidadeParametricaChange.bind(this)
    this.handleUnidadeParametricaChange = this.handleUnidadeParametricaChange.bind(this)
    this.handleObservacaoChange = this.handleObservacaoChange.bind(this)
    this.handleTipoOrcamentoChange = this.handleTipoOrcamentoChange.bind(this)
    this.handleSistemaReferenciaChange = this.handleSistemaReferenciaChange.bind(this)
    this.salvar = this.salvar.bind(this)
  }

  componentDidMount () {
    if (this.existeOrcamentoENaoCarregouArquivo()) {
      this.carregarArquivo()
    }
  }

  existeOrcamentoENaoCarregouArquivo () {
    const { orcamento } = this.props
    return !_.isNil(orcamento) && !_.isNil(orcamento.cod) && _.isNil(this.state.arquivo)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.orcamento !== this.props.orcamento) {
      this.handleOrcamentoChange()
    }
    this.validarAtributo('descricao', prevState)
    this.validarAtributo('tipo', prevState)
    this.validarAtributo('unidadeParametrica', prevState)
    this.validarAtributo('quantidadeParametrica', prevState)
    this.validarAtributo('bdiGestor', prevState)
    this.validarAtributo('encargosSociaisEdital', prevState)
  }

  async recuperarArquivoOriginal() {
    const { idEmpreendimento, idObra, idOrcamento } = this.props
    const { data } = await axios.get(`/api/empreendimentos/${idEmpreendimento}/obras/${idObra}/orcamentos/${idOrcamento}/arquivo`, SLOW_REQUEST_CONFIG)
    return data
  }

  async handleOrcamentoChange () {
    const { orcamento } = this.props
    if (!_.isNil(orcamento) && !_.isNil(orcamento.cod)) {
      this.carregarArquivo()
    }
  }

  async carregarArquivo () {
    const { orcamento } = this.props
    const arquivoOriginal = await this.recuperarArquivoOriginal()
    const resumo = calcularResumoArquivoOrcamento(arquivoOriginal)
    this.setState({
      arquivo: {
        nome: orcamento.nomeArquivo,
        tamanho: orcamento.tamanhoArquivo,
        resumo: resumo,
        itensOrcamento: resumo.itensOrcamento
      },
      descricao: orcamento.descricao,
      sigiloso: orcamento.sigiloso,
      tipo: orcamento.tipoOrcamento,
      observacao: orcamento.observacao,
      unidadeParametrica: orcamento.unidadeParametrica,
      quantidadeParametrica: !_.isNil(orcamento.quantidadeParametrica) ? orcamento.quantidadeParametrica.toString().replace('.', ',') : '',
      bdiGestor: !_.isNil(orcamento.bdi) ? orcamento.bdi.toString().replace('.', ',') : '',
      encargosSociaisEdital: !_.isNil(orcamento.encargosSociais) ? orcamento.encargosSociais.toString().replace('.', ',') : orcamento.encargosSociais
    })
  }

  validarAtributo (atributo, prevState) {
    if (this.alterouAtributo(atributo, prevState)) {
      this.verificarSeAtributoPreenchido(atributo)
    }
  }

  verificarSeAtributoPreenchido (atributo) {
    const state = {}
    const empty = this.atributoEmpty(atributo)
    state[`${atributo}Empty`] = empty
    this.setState(state)
    return !empty
  }

  atributoEmpty (atributo) {
    const value = this.state[atributo]
    return value === null || value === undefined || (typeof value === 'string' && value.trim() === '')
  }

  alterouAtributo (atributo, prevState) {
    return this.state[atributo] !== prevState[atributo]
  }

  handleCampoChange (campo, event) {
    const valor = event.target.value
    const state = {}
    state[campo] = valor
    this.setState(state)
  }

  handleSigilosoChange(event) {
    const sigiloso = event.target.value === 'true'
    this.setState({ sigiloso })
  }

  handleArquivoCarregado (arquivo) {
    const arquivoFoiAlterado = this.orcamentoConfirmado() && arquivo !== this.state.arquivo
    this.setState({ arquivo, arquivoFoiAlterado })
  }

  handleCancelarClick () {
    this.setState({
      arquivo: null
    })
  }

  orcamento () {
    const { arquivo, descricao, sigiloso } = this.state
    return {
      arquivo,
      descricao,
      sigiloso
    }
  }

  async confirmarCadastro () {
    const { idEmpreendimento, idObra } = this.props
    if (this.arquivoConfirmadoAlterado()) {
      const { orcamento } = this.props
      const urlAtualizarArquivo = `/api/empreendimentos/${idEmpreendimento}/obras/${idObra}/orcamentos/${orcamento.cod}/arquivo`
      const response = await axios.put(
        urlAtualizarArquivo,
        this.orcamento(),
        SLOW_REQUEST_CONFIG
      )
      if(response.status === 200) {
        showSuccessMsg('Dados atualizados')
        this.setState({
          arquivoFoiAlterado: false
        })
      }

    } else {
      const codOrcamentoCadastrado = await this.props.cadastrarOrcamento(this.orcamento(), idEmpreendimento, idObra)
      if (codOrcamentoCadastrado) {
        const { history } = this.props
        history.push(`/empreendimentos/${idEmpreendimento}/obras/${idObra}/orcamentos/${codOrcamentoCadastrado}`)
      }
    }
  }

  validar () {
    const referenciasEmpty = _.isEmpty(this.props.referenciasOrcamento)
    if (referenciasEmpty) {
      showAlertMsg('Adicione ao menos uma referência de preço')
    }
    return !referenciasEmpty &
      this.verificarSeAtributoPreenchido('descricao') &
      this.verificarSeAtributoPreenchido('tipo') &
      this.verificarSeAtributoPreenchido('unidadeParametrica') &
      (this.verificarSeAtributoPreenchido('quantidadeParametrica') || this.state.unidadeParametrica === 'NAO_SE_APLICA') &
      this.verificarSeAtributoPreenchido('bdiGestor') &
      (this.verificarSeAtributoPreenchido('encargosSociaisEdital') || !this.state.encargosSociaisEditalRequired  )
  }

  orcamentoAtualizar () {
    const { orcamento } = this.props
    const {
      descricao,
      sigiloso,
      tipo,
      observacao,
      unidadeParametrica,
      quantidadeParametrica,
      bdiGestor,
      encargosSociaisEdital
    } = this.state
    return {
      cod: orcamento.cod,
      descricao: descricao,
      sigiloso: sigiloso,
      tipoOrcamento: tipo,
      observacao: observacao,
      unidadeParametrica: unidadeParametrica,
      quantidadeParametrica: converterMoedaBRParaNumero(quantidadeParametrica),
      bdi: converterMoedaBRParaNumero(bdiGestor),
      encargosSociais: (_.isEmpty(this.state.encargosSociaisEdital) && !this.state.encargosSociaisEditalRequired) ?
        converterMoedaBRParaNumero('0'): converterMoedaBRParaNumero(encargosSociaisEdital)
    }
  }

  async salvar () {
    const { idEmpreendimento, idObra, orcamento, history } = this.props
    if((this.props.referenciasOrcamento||[]).filter(ref  => ref.sigla !== 'SICRO').length > 0 &&
      (this.atributoEmpty ('encargosSociaisEdital')|| this.state.encargosSociaisEdital === 0)){
      showAlertMsg('Preencha o campo encargos sociais do edital')
      return
    }
    if (this.validar()) {
      const urlAtualizar = `${url({ idEmpreendimento, idObra, idOrcamento: orcamento.cod })}`
      await axios.put(urlAtualizar, this.orcamentoAtualizar(), SLOW_REQUEST_CONFIG)
      await this.props.concluirOrcamentoContexto()
      history.push(`/empreendimentos/${idEmpreendimento}/obras/${idObra}/orcamentos/${orcamento.cod}/analisar`)
    } else {
      showErrorMsg('Por favor, verifique o preenchimento dos campos obrigatórios.')
    }
  }

  handleConfirmarClick() {
    if (this.atributoEmpty('descricao')) {
      this.setState({
        descricaoEmpty: true
      })
      showAlertMsg('Por favor, preencha a descrição.')
    } else {
      this.confirmarCadastro()
    }
  }

  arquivoConfirmadoNaoAlterado () {
    return this.orcamentoConfirmado() && !this.state.arquivoFoiAlterado
  }

  arquivoConfirmadoAlterado() {
    return this.orcamentoConfirmado() && this.state.arquivoFoiAlterado
  }

  orcamentoConfirmado () {
    return !_.isNil(this.props.orcamento)
  }

  handleDescricaoChange(e) {
    this.handleCampoChange('descricao', e)
  }

  handleSistemaReferenciaChange(e) {
    if('SICRO' === e.target.value || '' === e.target.value){
      this.setState({encargosSociaisEditalRequired: false})
    } else {
      if(this.state.encargosSociais === undefined){
        this.setState({encargosSociaisEditalEmpty: true})
      }
      this.setState({encargosSociaisEditalRequired: true})
    }
  }

  handleEncargosSociaisChange(e) {
    if (contemSomenteNumerosPositivos(e.target.value)) {
      this.setState({
        encargosSociaisEdital: e.target.value
      })
    }
  }

  handleBdiChange(e) {
    if (contemSomenteNumerosPositivos(e.target.value)) {
      this.setState({
        bdiGestor: e.target.value
      })
    }
  }

  handleQuantidadeParametricaChange(e) {
    if (contemSomenteNumerosPositivos(e.target.value)) {
      this.setState({
        quantidadeParametrica: e.target.value
      })
    }
  }

  handleUnidadeParametricaChange(e) {
    if('NAO_SE_APLICA' === e.target.value){
      this.setState({
        quantidadeParametrica: 0.0
      })
    }

    this.handleCampoChange('unidadeParametrica', e)
  }

  handleObservacaoChange(e) {
    this.handleCampoChange('observacao', e)
  }

  handleTipoOrcamentoChange(e) {
    this.handleCampoChange('tipo', e)
  }

  render () {
    const {
      arquivo,
      descricao,
      descricaoEmpty,
      sigiloso,
      tipo,
      tipoEmpty,
      observacao,
      unidadeParametrica,
      unidadeParametricaEmpty,
      quantidadeParametrica,
      quantidadeParametricaEmpty,
      bdiGestor,
      bdiGestorEmpty,
      encargosSociaisEdital,
      encargosSociaisEditalEmpty,
      encargosSociaisEditalRequired
    } = this.state

    const { idEmpreendimento, idObra, orcamento, classes } = this.props

    return (
      <div>
        <PaperBox>
          <DadosIniciais
            arquivo={arquivo}
            onArquivoCarregado={this.handleArquivoCarregado}
            descricao={descricao}
            descricaoEmpty={descricaoEmpty}
            onDescricaoChange={this.handleDescricaoChange}
            sigiloso={sigiloso}
            onSigilosoChange={this.handleSigilosoChange}
            confirmado={this.arquivoConfirmadoNaoAlterado()}
            exibirCancelarArquivo={!this.orcamentoConfirmado()}
            onConfirmarClick={this.handleConfirmarClick}
            onCancelarClick={this.handleCancelarClick}
          />
          {
            this.orcamentoConfirmado() &&
            <InformacoesGerais
              tipoOrcamento={tipo}
              tipoOrcamentoEmpty={tipoEmpty}
              onTipoOrcamentoChange={this.handleTipoOrcamentoChange}
              observacao={observacao}
              onObservacaoChange={this.handleObservacaoChange}
              unidadeParametrica={unidadeParametrica}
              unidadeParametricaEmpty={unidadeParametricaEmpty}
              onUnidadeParametricaChange={this.handleUnidadeParametricaChange}
              quantidadeParametrica={quantidadeParametrica}
              quantidadeParametricaEmpty={quantidadeParametricaEmpty}
              onQuantidadeParametricaChange={this.handleQuantidadeParametricaChange}
              bdi={bdiGestor}
              bdiEmpty={bdiGestorEmpty}
              onBdiChange={this.handleBdiChange}
              encargosSociais={encargosSociaisEdital}
              encargosSociaisEmpty={encargosSociaisEditalEmpty}
              encargosSociaisRequired={encargosSociaisEditalRequired}
              onEncargosSociaisChange={this.handleEncargosSociaisChange}
            />
          }
          {
            this.orcamentoConfirmado() &&
            <ReferenciaPrecos
              idEmpreendimento={idEmpreendimento}
              idObra={idObra}
              orcamento={orcamento}
              onChangeSistemaReferencia={this.handleSistemaReferenciaChange}
              encargosSociaisEdital={encargosSociaisEdital}
            />
          }
        </PaperBox>
        <div className={classes.botaoSalvarBox}>
          <Button
            onClick={this.salvar}
            variant='contained'
            color='primary'
            className={classes.botaoSalvar}
            endIcon={<SendIcon />}>
            Salvar e Analisar
          </Button>
        </div>
      </div>
    )
  }
}

export const mapStateToProps = (state) => {
  const { referenciasPrecoOrcamentoEmEdicao } = state.orcamentos
  return {
    referenciasOrcamento: referenciasPrecoOrcamentoEmEdicao
  }
}

const mapActionsToProps = {
  salvarInformacoesGeraisOrcamento,
  alterarOrcamento,
  cadastrarOrcamento,
  concluirOrcamentoContexto
}

export default connect(mapStateToProps, mapActionsToProps)(withStyles(styles)(withRouter(Formulario)))
