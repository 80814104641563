import React, { Component } from 'react'
import { Container, Hidden, Typography, withStyles } from '@material-ui/core'

import { connect } from 'react-redux'
import alterarOrcamento from '../../../actions/orcamentos/alterarOrcamento'
import cadastrarOrcamento from '../../../actions/orcamentos/cadastrarOrcamento'
import salvarInformacoesGeraisOrcamento from '../../../actions/orcamentos/salvarInformacoesGeraisOrcamento'
import mudarPropriedadesOrcamentoEmEdicao from '../../../actions/orcamentos/mudarPropriedadesOrcamentoEmEdicao'
import styles from './styles'

import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Carregando from '../../comum/Carregando'
import Template from '../../layout/Template'
import Orientacoes from './orientacoes/Orientacoes'
import Formulario from './formularios/Formulario'
import _ from 'lodash'
import { tituloPagina } from './cadastrar-orcamento'
import { SLOW_REQUEST_CONFIG } from '../../../js/operationProgressUtil'
import { showErrorMsg } from '../../../js/util'
import axios from 'axios'

export class CadastrarOrcamento extends Component {
  static propTypes = {
    match: PropTypes.object,
    contexto: PropTypes.object,
    classes: PropTypes.object
  }

  constructor (props) {
    super(props);
    this.state = {
      empreendimento: null,
      obra: null,
      orcamento: null
    }
  }

  componentDidMount () {
    this.contexto()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.contexto !== this.props.contexto) {
      this.contexto()
    }
  }

  contexto () {
    const { contexto } = this.props
    if (contexto && contexto.orcamento) {
      this.recuperarOrcamentoEdicao()
    }
  }

  async recuperarOrcamentoEdicao() {
    const { empreendimento, obra, orcamento } = this.props.contexto
    const url = `/api/empreendimentos/${empreendimento.cod}/obras/${obra.cod}/orcamentos/${orcamento.cod}`
    const result = await axios.get(url, SLOW_REQUEST_CONFIG)
    if (result.status === 200) {
      this.setState({
        orcamento: result.data
      })
    } else {
      this.setState({
        orcamento: null
      })
      showErrorMsg('Desculpe, ocorreu um erro inesperado')
    }
  }

  orcamentoCriado () {
    const { orcamento } = this.state
    return !_.isNil(orcamento) && !_.isNil(orcamento.cod)
  }

  render () {
    const { orcamento } = this.state
    const { classes, contexto } = this.props

    if (!contexto || !contexto.empreendimento || !contexto.obra) {
      return <Carregando />
    }

    const orcamentoCriado = this.orcamentoCriado()

    return <Template insidePaper={false}>
      <Container maxWidth='xl'>
      <Typography variant='h5'>{tituloPagina(orcamentoCriado)}</Typography>
        <section className={classes.editarOrcamentoBox}>
          <Hidden smDown>
            <Orientacoes orcamentoCriado={orcamentoCriado} />
          </Hidden>
          <Formulario
            orcamento={orcamento}
            idEmpreendimento={contexto.empreendimento.cod}
            idObra={contexto.obra.cod}
            idOrcamento={orcamentoCriado ? orcamento.cod : null}
          />
        </section>
      </Container>
    </Template>
  }
}

const mapStateToProps = ({ contexto }) => ({
  contexto
})

const mapActionsToProps = {
  alterarOrcamento,
  cadastrarOrcamento,
  salvarInformacoesGeraisOrcamento,
  mudarPropriedadesOrcamentoEmEdicao
}

export default connect(mapStateToProps, mapActionsToProps)(
  withRouter(
    withStyles(styles)(CadastrarOrcamento)
  )
)
