import React, { Component } from 'react'
import { Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import styles from './style'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import LinhaTratamentoPossiveisErros from './LinhaTratamentoPossiveisErros'
import ModalAgrupamentoPersonalizado from './ModalAgrupamentoPersonalizado'

class TratamentoPossivelErro extends Component {

  constructor (props) {
    super(props)
    this.state = {
      personalizar: false
    }
    this.personalizar = this.personalizar.bind(this)
  }

  static propTypes = {
    lista: PropTypes.array.isRequired,
    tipo: PropTypes.object.isRequired,
    atualizar: PropTypes.func.isRequired,
    encerrado: PropTypes.bool.isRequired
  }

  personalizar = (exibe , possivelErro, campo ) => {
      this.setState({personalizar: exibe, possivelErro: possivelErro, campo: campo})
  }

  render () {
    const {
      lista,
      tipo,
      atualizar,
      encerrado
    } = this.props
    if (lista.length === 0) {
      return <Typography>
        Não há ocorrências de possíveis erros para exibir.
      </Typography>
    }

    return (
      <div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width='15%'>
              <Typography variant='subtitle2'>Código</Typography>
            </TableCell>
            <TableCell width='35%'>
              <Typography variant='subtitle2'>Descrição</Typography>
            </TableCell>
            <TableCell width='15%'>
              <Typography variant='subtitle2'>Unidade</Typography>
            </TableCell>
            <TableCell width='15%'>
              <Typography variant='subtitle2'>Preço Unitário</Typography>
            </TableCell>
            <TableCell width='15%'>
              <Typography variant='subtitle2'>Relevância</Typography>
            </TableCell>
            <TableCell width='15%'>
              <Typography variant='subtitle2'>Ações</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            lista.map((it) => (
              <LinhaTratamentoPossiveisErros
                key={`${it.possivelErro.tipoErro}_${it.possivelErro.codServico}_${it.possivelErro.descServico}_${it.possivelErro.unidade}_${it.possivelErro.precoUnitario}`}
                possivelErro={it}
                tipo={tipo}
                atualizar={atualizar}
                encerrado={encerrado}
                personalizar={this.personalizar}
              />
            ))
          }
        </TableBody>
      </Table>
      <ModalAgrupamentoPersonalizado
        aberto={this.state.personalizar}
        fechar={() => this.setState({personalizar: false})}
        numeroErros={0}
        possivelErro={this.state.possivelErro}
        campo={this.state.campo}
        atualizar={atualizar}
      />
      </div>
    )
  }
}

export default withStyles(styles)(TratamentoPossivelErro)
