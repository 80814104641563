import { createTheme } from '@material-ui/core/styles'
import { ptBR as corePtBR } from '@material-ui/core/locale'

const black = '#4A4A4A'

export const theme = createTheme({
  palette: {
    text: {
      primary: black,
    },
    background: {
      default: '#EDEBEB',
    },
    primary: {
      main: '#1C519B',
    },
    secondary: {
      main: '#005F5B',
    },
    gray: {
      light: '#ccc',
      main: black,
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTab: {
      root: {
        padding: '12px 12px',
      },
    },
    MuiTypography: {
      h5: {
        color: black,
      },
      h6: {
        color: black,
        fontSize: '1.02rem',
      },
      body2: {
        fontWeight: 'normal',
        fontSize: '0.9rem',
        color: 'rgba(74,74,74,0.87)',
      },
    },
    MuiAccordion: {
      root: {
        boxShadow: 'none',
      }
    },
    MuiAlert: {
      root: {
        marginTop: 2,
        marginBottom: 2
      }
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '1000px',
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: '#1C519B',
        color: '#fff',
        '& h2, h6, button': {
          color: '#fff',
        },
      },
    },
    MuiCardHeader: {
      avatar: {
        marginRight: 0,
      },
    },
    MuiButton: {
      root: {
        textTransform: 'initial',
      },
      text: {
        letterSpacing: '-1px',
        padding: '4px 8px'
      }
    },
    MuiInputBase: {
      input: {
        backgroundColor: '#fff',
        height: 'auto',
      },
      root: {
        lineHeight: 'inherit',
      },
    },
    MuiOutlinedInput: {
      multiline: {
        backgroundColor: '#fff',
      },
      input: {
        padding: 12,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid #e5e5e6',
        fontSize: '0.8125rem',
        padding: '6px 10px',
      },
      stickyHeader: {
        backgroundColor: '#fff',
      },
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'rgba(67,133,191,1)',
        color: '#fff',
        fontSize: '0.95rem',
      },
    },
    MuiTreeItem: {
      content: {
        alignItems: 'flex-start',
      },
    },
    MuiMenu: {
      paper: {
        paddingRight: 8,
      }
    },
    MuiLink: {
      root: {
        cursor: 'pointer',
        '& :hover': {
          textDecoration: 'none',
        },
      },
    },
  } },
corePtBR
)

const styles = (t) => ({
  root: t.typography.body2Next,
})

export default styles
