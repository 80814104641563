import React from 'react'
import { TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import StyledTableRowDestaque from '../../../../../comum/StyledTableRowDestaque'
import PropTypes from 'prop-types'
import styles from './style'

const CabecalhoComposicaoAuxiliar = ({ composicao, classes }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Cód. Serviço</TableCell>
        <TableCell align='left'>Descrição</TableCell>
        <TableCell align='right'>Data Referência</TableCell>
        <TableCell align='right'>Tipo</TableCell>
        <TableCell align='right'>Auxiliar</TableCell>
      </TableRow>
      <StyledTableRowDestaque key={composicao.codigo}>
        <TableCell className={classes.celulaTabelaCompAuxiliar} component='th' scope='row'> {composicao.codigo} </TableCell>
        <TableCell className={classes.celulaTabelaCompAuxiliar} align='left'> {composicao.descricao} </TableCell>
        <TableCell className={classes.celulaTabelaCompAuxiliar} align='right'> {composicao.periodo} </TableCell>
        <TableCell className={classes.celulaTabelaCompAuxiliar} align='right'> </TableCell>
        <TableCell className={classes.celulaTabelaCompAuxiliar} align='right'> </TableCell>
      </StyledTableRowDestaque>
    </TableHead>
  )
}

CabecalhoComposicaoAuxiliar.propTypes = {
  composicao: PropTypes.object.isRequired,
  classes: PropTypes.object
}

export default (withStyles(styles)(CabecalhoComposicaoAuxiliar))
