import { showErrorMsg } from '../../../js/util'

const mimeTypesValidos = ['application/vnd.ms-excel', 'text/plain', 'text/csv']

export const validarArquivo = (arquivo) => {
  if (arquivo.size > 10485760) {
    showErrorMsg('Arquivo excede o tamanho máximo de 10MB!')
    return false
  }

  if (mimeTypesValidos.indexOf(arquivo.type) === -1 &&
    !arquivo.name.endsWith('.csv') && !arquivo.name.endsWith('.txt')) {
    showErrorMsg('É permitido apenas arquivo do tipo CSV')
    return false
  }

  return true
}

export const tituloPagina = (orcamentoCriado) => (orcamentoCriado ? 'Editar orçamento' : 'Novo orçamento')
