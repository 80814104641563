import React from 'react'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import styles from '../../styles'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'

const CampoObservacao = ({
  observacao,
  onObservacaoChange,
  classes
}) => {
  return (
    <FormControl variant='outlined' fullWidth>
      <Typography variant='subtitle1'>Observação</Typography>
      <TextField
        fullWidth
        multiline
        rows={2}
        className={classes.campoFormulario}
        variant='outlined'
        value={observacao||''}
        onChange={onObservacaoChange}
      />
    </FormControl>
  )
}

CampoObservacao.propTypes = {
  classes: PropTypes.object,
  observacao: PropTypes.string,
  onObservacaoChange: PropTypes.func.isRequired
}

export default withStyles(styles)(CampoObservacao)
