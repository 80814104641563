import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import { Menu, Checkbox, Button, MenuItem, Chip, FormControlLabel } from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { formatarNumeroParaMoedaBR } from '../../../../js/util'

const SelecaoLinhasCurvaAbc = ({
  exibirSomenteSobrepreco,
  exibirSomenteLinhasSelecionadas,
  percentualLinhasSelecionadas,
  habilitarSelecaoLinhas,
  onChange,
  classes
}) => {
  const labelSelecionadas = <span>Apenas linhas selecionadas <strong>({formatarNumeroParaMoedaBR(percentualLinhasSelecionadas, 2, 3)}% da curva)</strong></span>

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClickMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRemoveTag = (name) => {
    onChange({ target: { name, checked: false } });
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={openMenu ? 'basic-menu' : undefined}
        aria-haspopup="true"
        variant='outlined'
        className={classes.botaoFiltro}
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleClickMenu}
        endIcon={<ArrowDropDownIcon style={{ fontSize: 24 }} />}
      >
        Exibir
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleCloseMenu}>
          <FormControlLabel
            control={<Checkbox color={'primary'} checked={habilitarSelecaoLinhas} onChange={onChange} name='habilitarSelecaoLinhas' />}
            label='Seleção de linhas'
          />
        </MenuItem>
        <MenuItem onClick={handleCloseMenu}>
          <FormControlLabel
            control={<Checkbox color={'primary'} checked={exibirSomenteLinhasSelecionadas} onChange={onChange} name='exibirSomenteLinhasSelecionadas' />}
            label={labelSelecionadas}
          />
        </MenuItem>
        <MenuItem onClick={handleCloseMenu}>
          <FormControlLabel
            control={<Checkbox color={'primary'} checked={exibirSomenteSobrepreco} onChange={onChange} name='exibirSomenteSobrepreco' />}
            label='Somente linhas com sobrepreço'
          />
        </MenuItem>
      </Menu>
      {habilitarSelecaoLinhas && <Chip label="Seleção de linhas" onDelete={() => handleRemoveTag('habilitarSelecaoLinhas')} />}
      {exibirSomenteLinhasSelecionadas && <Chip label={labelSelecionadas} onDelete={() => handleRemoveTag('exibirSomenteLinhasSelecionadas')} />}
      {exibirSomenteSobrepreco && <Chip label="Somente com sobrepreço" onDelete={() => handleRemoveTag('exibirSomenteSobrepreco')} />}
    </>
  )
}

SelecaoLinhasCurvaAbc.propTypes = {
  exibirSomenteSobrepreco: PropTypes.bool.isRequired,
  exibirSomenteLinhasSelecionadas: PropTypes.bool.isRequired,
  percentualLinhasSelecionadas: PropTypes.number.isRequired,
  habilitarSelecaoLinhas: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object
}

export default withStyles(styles)(SelecaoLinhasCurvaAbc)
