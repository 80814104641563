import ForwardIcon from '@material-ui/icons/Forward'
import React from 'react'
import _ from 'lodash'
import { converterParaNumero, formatarNumeroParaMoedaBR, seObjectVazio } from '../../../../js/util'

export const TAB_INDEX_ANALISE = 0
export const TAB_INDEX_TEMAS_ = 1

export const TAB_INDEX_EXPRESSAO_BUSCA = 0
export const TAB_INDEX_FORMULAS = 1
export const TAB_INDEX_TIPOLOGIAS = 2
export const TAB_INDEX_TEMAS = 3
export const TAB_INDEX_CONFIGURAR_TEMA = 4

export const TIPO_TODOS_ESSES_TERMOS = {
  value: 'TODOS_ESSES_TERMOS',
  label: 'Todos esses termos',
  expressao: false
}

export const TIPO_QUALQUER_DESSES_TERMOS = {
  value: 'QUALQUER_DESSES_TERMOS',
  label: 'Qualquer desses termos',
  expressao: false
}

export const TIPO_NENHUM_DESSES_TERMOS = {
  value: 'NENHUM_DESSES_TERMOS',
  label: 'Nenhum desses termos',
  expressao: false
}

export const TIPO_CRITERIO_EXPRESSAO = {
  value: 'EXPRESSAO',
  label: 'Expressão',
  expressao: true
}

export const TIPO_CRITERIO_NEGACAO_EXPRESSAO = {
  value: 'NEGACAO_EXPRESSAO',
  label: 'Negação de expressão',
  expressao: true
}

const TIPOS_CRITERIO = [
  TIPO_TODOS_ESSES_TERMOS,
  TIPO_QUALQUER_DESSES_TERMOS,
  TIPO_NENHUM_DESSES_TERMOS,
  TIPO_CRITERIO_EXPRESSAO,
  TIPO_CRITERIO_NEGACAO_EXPRESSAO
]

export const tipoCriterio = (value) => TIPOS_CRITERIO.find((it) => it.value === value)

export const CAMPO_DESCRICAO = {
  value: 'DESCRICAO',
  label: 'em Descrição'
}
export const CAMPO_UNIDADE = {
  value: 'UNIDADE',
  label: 'em Unidade'
}

export const CAMPO_CODIGO = {
  value: 'CODIGO',
  label: 'em Código'
}

export const CAMPO_REFERENCIA_PRECO = {
  value: 'REFERENCIA',
  label: 'em Referência'
}


export const CAMPOS_CRITERIO = [
  CAMPO_DESCRICAO, CAMPO_UNIDADE, CAMPO_CODIGO
]

export const campoCriterio = (value) => CAMPOS_CRITERIO.find((it) => it.value === value)

export const converterCriterio = (criterioBackend) => {
  return {
    codigo: criterioBackend.codigo,
    tipo: tipoCriterio(criterioBackend.tipo),
    campo: campoCriterio(criterioBackend.campo),
    texto: criterioBackend.texto,
    codigoExpressaoCriterio: criterioBackend.codigoExpressaoCriterio,
    alterado: false
  }
}

export const urlCriterio = (expressao, criterio, tipoExpressaoSelecionda) => (
  criterio.tipo.expressao ? `analise-conteudo/expressoes/${expressao.codigo}/criterios-expressao/${criterio.codigo}?tipoAnalise=${tipoExpressaoSelecionda}`
    : `analise-conteudo/expressoes/${expressao.codigo}/criterios/${criterio.codigo}?tipoAnalise=${tipoExpressaoSelecionda}`
)

export const TIPOLOGIA_INCIDENCIA_PERCENTUAL = {
  value: 'INCIDENCIA_PERCENTUAL',
  label: 'A incidência percentual dos itens encontrados'
}

export const TIPOLOGIA_VALOR_TOTAL = {
  value: 'VALOR_TOTAL',
  label: 'O valor total dos itens encontrados'
}

export const TIPOLOGIA_QUANTIDADE = {
  value: 'QUANTIDADE',
  label: 'A quantidade de itens encontrados'
}

export const PERSONALIZADA = 'PERSONALIZADA'
export const TEMATICA = 'TEMATICA'
export const TIPOLOGIA_EXPRESSAO_PERSONALIZADA = 'TIPOLOGIA_EXPRESSAO_PERSONALIZADA'
export const TIPOLOGIA_FORMULA_PERSONALIZADA = 'TIPOLOGIA_FORMULA_PERSONALIZADA'
export const TIPOLOGIA_COMBINADA = 'TIPOLOGIA_COMBINADA'
export const TIPOLOGIA_FORMULA_TEMATICA = 'TIPOLOGIA_FORMULA_TEMATICA'
export const TIPOLOGIA_EXPRESSAO_TEMATICA = 'TIPOLOGIA_EXPRESSAO_TEMATICA'

export const TIPOLOGIAS_PERSONALIZADAS_KEY_DEFAULT_EXPANDED = 'TIPOLOGIAS_PERSONALIZADAS_KEY_DEFAULT_EXPANDED'
export const TIPOLOGIAS_TEMATICAS_KEY_DEFAULT_EXPANDED = 'TIPOLOGIAS_TEMATICAS_KEY_DEFAULT_EXPANDED'

// const TIPOLOGIA_QUANTIDADE_ITEM = {
//   value: 'QUANTIDADE_ITEM',
//   label: 'A quantidade de qualquer dos itens encontrados'
// }

export const VARIAVEIS_ANALISE_TIPOLOGIA = [
  TIPOLOGIA_INCIDENCIA_PERCENTUAL,
  TIPOLOGIA_VALOR_TOTAL,
  TIPOLOGIA_QUANTIDADE
  // TIPOLOGIA_QUANTIDADE_ITEM
]

export const variavelAnalise = (tipologia) => {
  return (tipologia && tipologia.variavelAnalise)
    ? VARIAVEIS_ANALISE_TIPOLOGIA.find((it) => it.value === tipologia.variavelAnalise)
    : TIPOLOGIA_INCIDENCIA_PERCENTUAL
}

export const CRITERIO_COMPARACAO_NAO_PODE_SER = {
  value: 'NAO_PODE_SER',
  label: 'não pode ser'
}

const CRITERIO_COMPARACAO_DEVE_SER = {
  value: 'DEVE_SER',
  label: 'deve ser'
}

export const CRITERIOS_COMPARACAO_TIPOLOGIA = [
  CRITERIO_COMPARACAO_NAO_PODE_SER,
  CRITERIO_COMPARACAO_DEVE_SER
]

export const criterioComparacao = (tipologia) => {
  return (tipologia && tipologia.criterioComparacao)
    ? CRITERIOS_COMPARACAO_TIPOLOGIA.find((it) => it.value === tipologia.criterioComparacao)
    : CRITERIO_COMPARACAO_NAO_PODE_SER
}

const TIPO_COMPARACAO_INFERIOR_A = {
  value: 'INFERIOR_A',
  label: 'inferior a'
}

export const TIPO_COMPARACAO_SUPERIOR_A = {
  value: 'SUPERIOR_A',
  label: 'superior a'
}

export const TIPO_COMPARACAO_ENTRE = {
  value: 'ENTRE',
  label: 'entre'
}

export const TIPO_COMPARACAO_IGUAL_A = {
  value: 'IGUAL_A',
  label: 'igual a'
}

export const TIPO_COMPARACAO_IGUAL_OU_INFERIOR_A = {
  value: 'IGUAL_OU_INFERIOR_A',
  label: 'igual ou inferior a'
}

export const TIPO_COMPARACAO_IGUAL_OU_SUPERIOR_A = {
  value: 'IGUAL_OU_SUPERIOR_A',
  label: 'igual ou superior a'
}

export const TIPOS_COMPARACAO_TIPOLOGIA = [
  TIPO_COMPARACAO_SUPERIOR_A,
  TIPO_COMPARACAO_INFERIOR_A,
  TIPO_COMPARACAO_ENTRE,
  TIPO_COMPARACAO_IGUAL_A,
  TIPO_COMPARACAO_IGUAL_OU_INFERIOR_A,
  TIPO_COMPARACAO_IGUAL_OU_SUPERIOR_A
]

export const tipoComparacao = (tipologia) => {
  return (tipologia && tipologia.tipoComparacao)
    ? TIPOS_COMPARACAO_TIPOLOGIA.find((it) => it.value === tipologia.tipoComparacao)
    : TIPO_COMPARACAO_SUPERIOR_A
}

export const TIPO_MEDIDA_DISPERSAO_DESVIO_PADRAO = {
  value: 'DESVIO_PADRAO',
  label: 'com desvio padrão de'
}

export const TIPO_MEDIDA_DISPERSAO_SELECIONE = {
  value: null,
  label: 'selecione a medida de dispersão'
}

export const TIPOS_MEDIDA_DISPERSAO = [
  TIPO_MEDIDA_DISPERSAO_SELECIONE,
  TIPO_MEDIDA_DISPERSAO_DESVIO_PADRAO
]

export const TIPO_OPERADOR_AND = {
  value: 'AND',
  label: 'e'
}

export const TIPO_OPERADOR_OR = {
  value: 'OR',
  label: 'ou'
}

export const TIPOS_OPERADORES_TIPOLOGIA_COMBINADA = [
  TIPO_OPERADOR_AND,
  TIPO_OPERADOR_OR
]

export const tipoOperadorTipologiaCombinada = (operador) =>
  TIPOS_OPERADORES_TIPOLOGIA_COMBINADA.find((it) => it.label === operador.toLowerCase())

export const tipoMedidaDispersao = (tipologia) => {
  return (tipologia && tipologia.tipoMedidaDispersao)
    ? TIPOS_MEDIDA_DISPERSAO.find((it) => it.value === tipologia.tipoMedidaDispersao)
    : TIPO_MEDIDA_DISPERSAO_SELECIONE
}

export const operadorCriterio = (index, tipo, operadorExpressao) => {
  if (index === 0) {
    return tipo === TIPO_CRITERIO_NEGACAO_EXPRESSAO ? <span style={{ color: 'red' }}>NÃO</span> : <ForwardIcon color='primary' />
  }
  const texto = `${operadorExpressao}${tipo === TIPO_CRITERIO_NEGACAO_EXPRESSAO ? ' NÃO' : ''}`
  const style = tipo === TIPO_CRITERIO_NEGACAO_EXPRESSAO ? { color: 'red' } : {}
  return <span style={style}>{texto}</span>
}

export const calcularTotalPercentual = (valores) => {
  return (
    valores.reduce((acumulador, item) => {
      return acumulador + item.percentual
    }, 0)
  )
}

export const calcularTotalValorParcial = (valores) => {
  return (
    valores.reduce((acumulador, item) => {
      return acumulador + item.precoParcialCalculado
    }, 0)
  )
}

export const calcularQuantidadeTotalItensOrcamentarios = (valores) => {
  return (
    valores.reduce((acumulador, item) => {
      return acumulador + item.quantidade
    }, 0)
  )
}

export const calcularTotalItensOrcamentarios = (itens) =>
  itens.length === 0 ? 0 : itens.map((it) => it.codigosItensOrcamento.length).reduce((a, b) => a + b)

export const montarTextoResultadoDaTipologia = (possuiInconsistencia, regraTipologia, totalPercentual, totalValorParcial, totalItens) => {
  if (!possuiInconsistencia) {
    switch (regraTipologia) {
      case TIPOLOGIA_INCIDENCIA_PERCENTUAL.value:
        return `Validação OK. A incidência percentual dos itens identificados foi de ${totalPercentual}%, dentro dos parâmetros estabelecidos pela tipologia.`
      case TIPOLOGIA_VALOR_TOTAL.value:
        return `Validação OK. O valor total dos itens identificados foi de ${totalValorParcial}, dentro dos parâmetros estabelecidos pela tipologia.`
      case TIPOLOGIA_QUANTIDADE.value:
        return `Validação OK. A quantidade dos itens identificados foi ${totalItens}, dentro dos parâmetros estabelecidos pela tipologia.`
    }
  } else {
    switch (regraTipologia) {
      case TIPOLOGIA_INCIDENCIA_PERCENTUAL.value:
        return `Possível inconsistência identificada. A incidência percentual dos itens identificados foi de ${totalPercentual}%, fora dos parâmetros estabelecidos pela tipologia.`
      case TIPOLOGIA_VALOR_TOTAL.value:
        return `Possível inconsistência identificada. O valor total dos itens identificados foi de ${totalValorParcial}, fora dos parâmetros estabelecidos pela tipologia.`
      case TIPOLOGIA_QUANTIDADE.value:
        return `Possível inconsistência identificada. A quantidade dos itens identificados foi ${totalItens}, fora dos parâmetros estabelecidos pela tipologia.`
    }
  }
}

export const montarTextoResultadoDaTipologiaFormula = (temInconsistencia, valor) => {
  const valorFormatado = formatarNumeroParaMoedaBR(valor, 2, 4)
  if (temInconsistencia) {
    return `Possível inconsistência identificada. O resultado identificado da fórmula foi de ${valorFormatado}, fora dos parâmetros estabelecidos pela tipologia.`
  }
  return `Validação OK. O resultado identificado da fórmula foi de ${valorFormatado}, dentro dos parâmetros estabelecidos pela tipologia.`
}

export const ordenar = (list) =>
  list.sort((a, b) => a.descricao < b.descricao ? -1 : a.descricao > b.descricao ? 1 : 0)

export const not = (a, b) => {
  return a.filter((value) => {
    return b.map(it => it.id).indexOf(value.id) === -1
  })
}

export const intersection = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1)
}

export const createState = (campo, valor) => {
  const state = {}
  state[campo] = campo
  state[campo] = valor
  state[`${campo}Empty`] = seObjectVazio(valor)
  return state
}

export const CAMPO_EXPRESSOES_DESTE_ORCAMENTO = {
  value: 'EXPRESSOES_DESTE_ORCAMENTO',
  label: 'Expressões deste orçamento'
}

export const CAMPO_EXPRESSAO_POR_UNIDADE = {
  value: 'CAMPO_EXPRESSAO_POR_UNIDADE',
  label: 'Expressão por unidade'
}

export const CAMPO_NOME_DA_EXPRESSAO = {
  value: 'CAMPO_NOME_DA_EXPRESSAO',
  label: 'Nome da expressão'
}

export const CAMPO_FILTRO_EXPRESSOES = [
  CAMPO_EXPRESSOES_DESTE_ORCAMENTO, CAMPO_EXPRESSAO_POR_UNIDADE, CAMPO_NOME_DA_EXPRESSAO
]

export const CAMPO_FORMULAS_DESTE_ORCAMENTO = {
  value: 'FORMULAS_DESTE_ORCAMENTO',
  label: 'Fórmulas deste orçamento'
}

export const CAMPO_FORMULA_POR_UNIDADE = {
  value: 'FORMULA_POR_UNIDADE',
  label: 'Fórmula por unidade'
}

export const CAMPO_NOME_DA_FORMULA = {
  value: 'NOME_DA_FORMULA',
  label: 'Nome da fórmula'
}

export const CAMPO_FILTRO_FORMULAS = [
  CAMPO_FORMULAS_DESTE_ORCAMENTO, CAMPO_FORMULA_POR_UNIDADE, CAMPO_NOME_DA_FORMULA
]

export const CAMPO_TIPOLOGIA_COMBINADA_POR_UNIDADE = {
  value: 'CAMPO_TIPOLOGIA_COMBINADA_POR_UNIDADE',
  label: 'Tipologia combinada por unidade'
}

export const CAMPO_NOME_DA_TIPOLOGIA_COMBINADA = {
  value: 'CAMPO_NOME_DA_TIPOLOGIA_COMBINADA',
  label: 'Nome da tipologia combinada'
}

export const CAMPO_FILTRO_TIPOLOGIAS_COMBINADAS = [
  CAMPO_TIPOLOGIA_COMBINADA_POR_UNIDADE, CAMPO_NOME_DA_TIPOLOGIA_COMBINADA
]

export const sleepAutocomplete = (delay = 0) => {
  return new Promise((resolve) => {
    setTimeout(resolve, delay)
  })
}

export const TIPO_PARAMETRO_VALOR_NUMERICO = {
  value: 'VALOR_NUMERICO',
  label: 'Valor numérico'
}

export const TIPO_PARAMETRO_VARIAVEL_ORCAMENTO = {
  value: 'VARIAVEL_ORCAMENTO',
  label: 'Variável de orçamento'
}

export const TIPO_PARAMETRO_EXPRESSAO = {
  value: 'EXPRESSAO',
  label: 'Expressão'
}

export const TIPO_PARAMETRO_FORMULA = {
  value: 'FORMULA',
  label: 'Fórmula'
}

export const TIPOS_PARAMETROS = [
  TIPO_PARAMETRO_VALOR_NUMERICO,
  TIPO_PARAMETRO_VARIAVEL_ORCAMENTO,
  TIPO_PARAMETRO_EXPRESSAO,
  TIPO_PARAMETRO_FORMULA
]

export const VALOR_GLOBAL_ORCAMENTO = {
  value: 'VALOR_GLOBAL',
  label: 'Valor global do orçamento'
}

export const QUANTIDADE_PARAMETRICA = {
  value: 'QUANTIDADE_PARAMETRICA',
  label: 'Quantidade paramétrica'
}

export const VARIAVEIS_ORCAMENTOS = [
  VALOR_GLOBAL_ORCAMENTO,
  QUANTIDADE_PARAMETRICA
]

export const OPERADOR_ADICAO = {
  value: 'ADICAO',
  label: '+ (adição)',
  operator: '+'
}

export const OPERADOR_SUBTRACAO = {
  value: 'SUBTRACAO',
  label: '- (subtração)',
  operator: '-'
}

export const OPERADOR_DIVISAO = {
  value: 'DIVISAO',
  label: '/ (divisão)',
  operator: '/'
}

export const OPERADOR_MULTIPLICACAO = {
  value: 'MULTIPLICACAO',
  label: '* (multiplicação)',
  operator: '*'
}

export const TIPO_OPERADORES = [
  OPERADOR_ADICAO,
  OPERADOR_SUBTRACAO,
  OPERADOR_MULTIPLICACAO,
  OPERADOR_DIVISAO
]

export const VALOR_GLOBAL_EXPRESSAO = {
  value: 'VALOR_GLOBAL',
  label: 'O valor total dos itens encontrados'
}

export const INCIDENCIA_PERCENTUAL_EXPRESSAO = {
  value: 'INCIDENCIA_PERCENTUAL',
  label: 'A incidência percentual dos itens encontrados'
}

export const QUANTIDADE_ITENS_EXPRESSAO = {
  value: 'QUANTIDADE',
  label: 'A quantidade de itens encontrados'
}

export const VARIAVEIS_EXPRESSOES = [
  VALOR_GLOBAL_EXPRESSAO,
  INCIDENCIA_PERCENTUAL_EXPRESSAO,
  QUANTIDADE_ITENS_EXPRESSAO
]

export const converterParametro = (parametroBackend, alterado) => {
  return {
    codigo: parametroBackend.codigo,
    tipo: tipoParametro(parametroBackend.tipo),
    operador: operador(parametroBackend.operador),
    valorNumerico: !_.isNull(parametroBackend.valorNumerico) ? formatarNumeroParaMoedaBR(parametroBackend.valorNumerico, 2, 3) : null,
    variavelOrcamento: parametroBackend.variavelOrcamento,
    variavelExpressao: variavelExpressao(parametroBackend.variavelExpressao),
    codigoFormula: parametroBackend.codigoFormula,
    codigoExpressao: parametroBackend.codigoExpressao,
    codigoOrcamento: parametroBackend.codigoOrcamento,
    codigoTema: parametroBackend.codigoTema,
    codigoFormulaParametro: parametroBackend.codigoFormulaParametro,
    alterado: alterado
  }
}

export const converterParametroToBackend = (parametro) => {
  return {
    codigo: parametro.codigo,
    codigoExpressao: parametro.codigoExpressao,
    codigoFormula: parametro.codigoFormula,
    codigoFormulaParametro: parametro.codigoFormulaParametro,
    codigoOrcamento: parametro.codigoOrcamento,
    codigoTema: parametro.codigoTema,
    operador: parametro.operador.value,
    tipo: parametro.tipo.value !== null ? parametro.tipo.value : parametro.tipo,
    valorNumerico: parametro.valorNumerico !== null ? converterParaNumero(parametro.valorNumerico) : null,
    variavelExpressao: parametro.variavelExpressao ? parametro.variavelExpressao.value : null,
    variavelOrcamento: parametro.variavelOrcamento
  }
}

export const operador = (value) => TIPO_OPERADORES.find((it) => it.value === value)

export const tipoParametro = (value) => TIPOS_PARAMETROS.find((it) => it.value === value)

export const variavelOrcamento = (value) => VARIAVEIS_ORCAMENTOS.find((it) => it.value === value)

export const variavelExpressao = (value) => {
  if (value !== null) {
    return VARIAVEIS_EXPRESSOES.find((it) => it.value === value)
  }
  return value
}

export const atualizarListaExpressoesVinculadas = (expressoesVinculadasAoTemaState, expressoesExcluidas) => {
  const novaLista = expressoesVinculadasAoTemaState
  expressoesExcluidas.forEach(it => {
    const indexTemDuplicado = novaLista.findIndex(exp => exp.codExpressao === it)
    if (indexTemDuplicado !== -1) {
      novaLista.splice(indexTemDuplicado, 1)
    }
  })
  return novaLista
}

export const atualizarListaFormulasVinculadas = (formulasVinculadasAoTemaState, formulasExcluidas) => {
  const novaLista = formulasVinculadasAoTemaState
  formulasExcluidas.forEach(it => {
    const indexTemDuplicado = formulasVinculadasAoTemaState.findIndex(form => form.codFormula === it)
    if (indexTemDuplicado !== -1) {
      novaLista.splice(indexTemDuplicado, 1)
    }
  })
  return novaLista
}

export const atualizarFormulasTematicas = (formulasTematicas, codigoTema, formula) => {
  return formulasTematicas
    .map(formTematica => {
      if (formTematica.codTema === codigoTema) {
        formTematica.formulas = formTematica.formulas
          .map(it => it.codigo === formula.codigo ? formula : it)
      }
      return formTematica
    })
}

export const removerExpressaoTematica = (expressoesTematicas, codigoTema, expressao) => {
  return expressoesTematicas
    .map(expTematica => {
      if (expTematica.codTema === codigoTema) {
        expTematica.expressoes = expTematica.expressoes.filter(expr => expr.codigo !== expressao.codigo)
      }
      return expTematica
    })
}

export const removerFormulaTematica = (formulasTematicas, codigoTema, formula) => {
  return formulasTematicas
    .map(formulaTematica => {
      if (formulaTematica.codTema === codigoTema) {
        formulaTematica.formulas = formulaTematica.formulas.filter(form => form.codigo !== formula.codigo)
      }
      return formulaTematica
    })
}

export const isTipoExpressaoPersonalizada = (tipo) => tipo === PERSONALIZADA
export const isTipoFormulaPersonalizada = (tipo) => tipo === PERSONALIZADA
export const isTipoTipologiaExpressaoPersonalizada = (tipo) => tipo === TIPOLOGIA_EXPRESSAO_PERSONALIZADA
export const isTipoTipologiaFormulaPersonalizada = (tipo) => tipo === TIPOLOGIA_FORMULA_PERSONALIZADA

export const definirDefaultExpandedTipologia = (tipoSelecionado, objeto) => {
  return tipoSelecionado === PERSONALIZADA ? TIPOLOGIAS_PERSONALIZADAS_KEY_DEFAULT_EXPANDED : `TIPOLOGIAS_TEMATICAS_KEY_DEFAULT_EXPANDED_${objeto.tema.codigo}`
}

export const getTipologiaCombinada = (tipologiaSelecionada) => {
  let tipologia1 = null
  let tipologia2 = null

  if (!_.isNil(tipologiaSelecionada.expressoesCombinadas) && tipologiaSelecionada.expressoesCombinadas.length === 1) {
    tipologia1 = tipologiaSelecionada.expressoesCombinadas[0].tipologia
  } else if (!_.isNil(tipologiaSelecionada.expressoesCombinadas) && tipologiaSelecionada.expressoesCombinadas.length === 2) {
    tipologia1 = tipologiaSelecionada.expressoesCombinadas[0].tipologia
    tipologia2 = tipologiaSelecionada.expressoesCombinadas[1].tipologia
  }

  if (!_.isNil(tipologiaSelecionada.formulasCombinadas) && tipologiaSelecionada.formulasCombinadas.length === 1) {
    tipologia2 = tipologiaSelecionada.formulasCombinadas[0].tipologia
  } else if (!_.isNil(tipologiaSelecionada.formulasCombinadas) && tipologiaSelecionada.formulasCombinadas.length === 2) {
    tipologia1 = tipologiaSelecionada.formulasCombinadas[0].tipologia
    tipologia2 = tipologiaSelecionada.formulasCombinadas[1].tipologia
  }
  return {
    primeiraTipologia: tipologia1,
    segundaTipologia: tipologia2
  }
}

export const removerExpressoesTematicasJaVinculadas = (expressoesJaVinculadas, expressoesASeremViculadas) => {
  const expressoes = []
  expressoesASeremViculadas.forEach((it) => {
    const expressaoExistente = expressoesJaVinculadas.some((ex) => ex.nomeExpressao === it.nomeExpressao && ex.descricaoExpressao === it.descricaoExpressao)
    if (!expressaoExistente) {
      expressoes.push(it)
    }
  })
  return expressoes
}

export const removerFormulasTematicasJaVinculadas = (formulasJaVinculadas, formulasASeremViculadas) => {
  const formulas = []
  formulasASeremViculadas.forEach((it) => {
    const formulasExistente = formulasJaVinculadas.some((ex) => ex.nomeFormula === it.nomeFormula && ex.descricaoFormula === it.descricaoFormula)
    if (!formulasExistente) {
      formulas.push(it)
    }
  })
  return formulas
}

export const removerTipologiasCombinadasTematicasJaVinculadas = (tipologiasJaVinculadas, tipologiasASeremViculadas) => {
  const tipologias = []
  tipologiasASeremViculadas.forEach((it) => {
    const formulasExistente = tipologiasJaVinculadas.some((ex) => ex.nome === it.nome && ex.descricao === it.descricao)
    if (!formulasExistente) {
      tipologias.push(it)
    }
  })
  return tipologias
}
