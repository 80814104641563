import React, { Component } from 'react'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TituloModal from '../../../../comum/TituloModal'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { context_httpget } from '../../../../../js/httpRequest'
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Box from '@material-ui/core/Box'
import SelecaoMetodoCalculoSobrepreco from './SelecaoMetodoCalculoSobrepreco'

class ModalResumoSobreprecoCurvaAbc extends Component {
  static propTypes = {
    fechar: PropTypes.func.isRequired,
    ativo: PropTypes.bool.isRequired,
    metodoCalculoSobrepreco: PropTypes.object.isRequired,
    onItensAlterados: PropTypes.func,
    contexto: PropTypes.object,
    onChangeMetodoCalculoSobrepresso: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      detalhes: null
    }
    this.handleChange = this.handleChange.bind(this)
    this.fechar = this.fechar.bind(this)
  }

  componentDidUpdate (prevProps) {
    const { ativo } = this.props
    if ((ativo && !prevProps.ativo) || (ativo && prevProps.metodoCalculoSobrepreco !== this.props.metodoCalculoSobrepreco)) {
      this.recuperarDetalhes()
    }
  }

  async recuperarDetalhes () {
    const { contexto } = this.props
    const detalhes = await context_httpget('curva-abc/resumo-sobrepreco', contexto)
    if (detalhes) {
      this.setState({ detalhes })
    }
  }

  handleChange () {
    this.recuperarDetalhes()
  }

  fechar () {
    const { onItensAlterados, fechar } = this.props
    onItensAlterados()
    fechar()
  }

  render () {
    const { ativo, metodoCalculoSobrepreco, onChangeMetodoCalculoSobrepresso} = this.props
    const { detalhes } = this.state

    return ativo && !_.isNil(detalhes) && (
      <Dialog
        open={true}
        onClose={this.fechar}
        aria-labelledby='form-dialog-title'>

        <TituloModal onClose={this.fechar}>
          Resumo da análise de sobrepreço
        </TituloModal>

        <DialogContent>
          <SelecaoMetodoCalculoSobrepreco
            valor={metodoCalculoSobrepreco && metodoCalculoSobrepreco.sigla}
            onChange={this.handleChange}
            onChangeMetodoCalculoSobrepresso={onChangeMetodoCalculoSobrepresso}
          />

          <Table style={{ width: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={3} align='right'>
                  Valor Total (R$)
                </TableCell>
                <TableCell align='right' style={{ width: 135 }}>
                  Quantidade itens
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Orçamento (Curva ABC)</TableCell>
                <TableCell>[A]</TableCell>
                <TableCell align='right'>{detalhes.valorTotalOrcamentoFormatado}</TableCell>
                <TableCell align='right'>{detalhes.quantidadeItensCurvaAbc}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Amostra - Orçamento</TableCell>
                <TableCell>[B]</TableCell>
                <TableCell align='right'>{detalhes.valorAmostraOrcamentoFormatado}</TableCell>
                <TableCell rowSpan={2} align='right'>{detalhes.quantidadeItensAmostra}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Amostra - Referência</TableCell>
                <TableCell>[C]</TableCell>
                <TableCell align='right'>{detalhes.valorAmostraReferenciaPrecoFormatado}</TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <Box mt={3}>
            <Table style={{ width: 500 }}>
              <TableBody>
                <TableRow>
                  <TableCell>Representatividade da Amostra</TableCell>
                  <TableCell>[B]/[A]</TableCell>
                  <TableCell align='right'>{detalhes.percentualRepresentatividadeAmostraFormatado}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sobrepreço</TableCell>
                  <TableCell>[D]=[B]-[C]</TableCell>
                  <TableCell align='right'>{detalhes.valorSobreprecoFormatado}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Orçamento referencial</TableCell>
                  <TableCell>[E]=[A]-[D]</TableCell>
                  <TableCell align='right'>{detalhes.valorOrcamentoReferencialFormatado}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sobrepreço em relação à Amostra</TableCell>
                  <TableCell>[D]/[C]</TableCell>
                  <TableCell align='right'>{detalhes.percentualSobreprecoAmostraFormatado}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell>Sobrepreço em relação ao Total</TableCell>
                  <TableCell>[D]/[E]</TableCell>
                  <TableCell align='right'>{detalhes.percentualSobreprecoTotalFormatado}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' onClick={this.fechar}>Fechar</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ contexto }) => ({ contexto })

export default connect(mapStateToProps)(ModalResumoSobreprecoCurvaAbc)
