import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { CAMPO_EXPRESSAO_POR_UNIDADE, CAMPO_FILTRO_EXPRESSOES, CAMPO_NOME_DA_EXPRESSAO, sleepAutocomplete } from '../../analise-conteudo/analise-conteudo'
import axios from 'axios'
import TabelaVincularExpressoes from './TabelaVincularExpressoes'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import _ from 'lodash'
import CampoFiltro from '../../analise-conteudo/content/comum/campos/CampoFiltro'
import CampoUnidades from '../../analise-conteudo/content/comum/campos/CampoUnidades'
import CampoEmpreendimentos from '../../analise-conteudo/content/comum/campos/CampoEmpreendimentos'
import CampoOrcamentos from '../../analise-conteudo/content/comum/campos/CampoOrcamentos'
import CampoBusca from '../../analise-conteudo/content/comum/campos/CampoBusca'
import PaperBox from '../../../../comum/PaperBox'
import buscarOrcamentos from '../../../../../actions/orcamentos/buscarOrcamentos'
import { SLOW_REQUEST_CONFIG } from '../../../../../js/operationProgressUtil'
import { showSuccessMsg, textNormalize } from '../../../../../js/util'
import { context_httppost } from '../../../../../js/httpRequest'

const VincularExpressao = ({
  classes,
  contexto,
  codigoTema,
  expressoesVinculadas,
  onVincularExpressoesAoTema,
  onFecharDrawer
}) => {
  const [unidade, setUnidade] = useState(null)
  const [empreendimento, setEmpreendimento] = useState(null)
  const [orcamento, setOrcamento] = useState(null)
  const [termo, setTermo] = useState('')
  const [carregandoOrcamentos, setCarregandoOrcamentos] = useState(false)
  const [filtro, setFiltro] = useState(CAMPO_EXPRESSAO_POR_UNIDADE)
  const [orcamentos, setOrcamentos] = useState([])
  const [expressoes, setExpressoes] = useState([])
  const [expressoesFilter, setExpressoesFilter] = useState([])
  const [expressoesSelecionadas, setExpressoesSelecionadas] = useState([])
  const [empreendimentos, setEmpreendimentos] = useState(null)

  useEffect(() => {
    buscarExpressoes(empreendimento, orcamento)
    setExpressoesSelecionadas(expressoesVinculadas)
  }, [contexto])

  useEffect(() => {
    const buscarEmpreendimentosPorUnidade = async () => {
      const { data } = await axios.get(`/api/empreendimentos/unidade/${unidade.cod}`, SLOW_REQUEST_CONFIG)
      setEmpreendimentos(data)
    }

    if (unidade && empreendimentos === null) {
      unidade && buscarEmpreendimentosPorUnidade()
    }
  }, [unidade])

  useEffect(() => {
    const buscarOrcamentos = async () => {
      setCarregandoOrcamentos(true)
      if (empreendimento) {
        const { data } = await axios.get(`/api/empreendimentos/${empreendimento.cod}/orcamentos`, SLOW_REQUEST_CONFIG)
        await sleepAutocomplete(1e2)
        setOrcamentos(data)
        setCarregandoOrcamentos(false)
      }
    }

    empreendimento && buscarOrcamentos()
  }, [empreendimento])

  useEffect(() => {
    orcamento && buscarExpressoes(empreendimento, orcamento)
  }, [orcamento])

  const handleSelecionarExpressao = (event, expressao) => {
    const expressaoVinculo = {
      codExpressao: expressao.codExpressao,
      nomeExpressao: expressao.nomeExpressao,
      descricaoExpressao: expressao.descricaoExpressao
    }
    if (event.target.checked) {
      setExpressoesSelecionadas([...expressoesSelecionadas, expressaoVinculo])
    } else {
      setExpressoesSelecionadas(expressoesSelecionadas.filter(it => it.codExpressao !== expressao.codExpressao))
    }
  }

  const handleCampoUnidade = (unidade) => {
    if (unidade) {
      setUnidade(unidade)
    }
  }

  const handleCampoFiltro = (valor) => {
    setFiltro(valor)
    setExpressoesFilter([])
    setTermo('')
  }

  const buscarExpressoes = async (empreendimento, orcamento) => {
    if (empreendimento && orcamento) {
      const { data } = await axios.get(`/api/empreendimentos/${empreendimento.cod}/orcamentos/${orcamento.cod}/analise-conteudo/expressoes`, SLOW_REQUEST_CONFIG)
      if (data) {
        setExpressoes(data)
        setExpressoesFilter(data)
      }
    } else {
      setExpressoes([])
      setTermo('')
    }
  }

  const handleBuscarPorNomeDaExpressao = async () => {
    const { data } = await axios.get(`/api/expressoes?termo=${termo}`, SLOW_REQUEST_CONFIG)
    if (data) {
      setExpressoesFilter(data)
    }
  }

  const handleFiltrarExpressoesPorTermo = () => {
    if (termo.length) {
      const novaLista = expressoes.filter((expressao) => textNormalize(expressao.nomeExpressao).toLowerCase().search(textNormalize(termo).toLowerCase()) !== -1)
      setExpressoesFilter(novaLista)
    } else {
      setExpressoesFilter(expressoes)
    }
  }

  const handleVincular = async () => {
    const novasExpressoesSelecionadas = expressoesSelecionadas.filter(it => _.isNil(it.codExpressaoOriginal))
    const expressaoVinculada = await context_httppost(`analise-conteudo/temas/${codigoTema}/expressoes/vincular`, contexto, novasExpressoesSelecionadas)
    if (expressaoVinculada) {
      onVincularExpressoesAoTema(expressaoVinculada)
      showSuccessMsg('Dados registrados')
      onFecharDrawer()
    }
  }

  const handleOpcoesImportacoes = () => {
    const opcoes = [...CAMPO_FILTRO_EXPRESSOES]
    opcoes.shift()
    return opcoes
  }

  return (
    <React.Fragment>
      <Typography variant='h6' style={{ marginBottom: 20 }}>
        Vincular expressão ao tema
      </Typography>
      <CampoFiltro
        filtro={filtro}
        values={handleOpcoesImportacoes()}
        onFiltroChange={handleCampoFiltro}
      />

      { filtro === CAMPO_EXPRESSAO_POR_UNIDADE && (
        <React.Fragment>
          <CampoUnidades
            unidade={unidade}
            unidadeEmpty={false}
            unidadeUsuarioLogadoPreSelecionada
            onUnidadeChange={handleCampoUnidade}
          />

          <CampoEmpreendimentos
            empreendimentos={empreendimentos === null ? [] : empreendimentos}
            empreendimento={empreendimento}
            onCampoEmpreendimento={setEmpreendimento}
          />

          <CampoOrcamentos
            orcamentos={orcamentos}
            onCampoOrcamento={setOrcamento}
            carregandoOrcamentos={carregandoOrcamentos}
          />
        </React.Fragment>)
      }

      { filtro === CAMPO_NOME_DA_EXPRESSAO && (
        <CampoBusca
          value={termo}
          name='termo'
          placeholder='Digite o nome da expressão'
          buttonName='Pesquisar'
          onCampoChange={setTermo}
          onClick={handleBuscarPorNomeDaExpressao}
        />)
      }

      <PaperBox style={{ marginTop: 15 }}>
        {filtro !== CAMPO_NOME_DA_EXPRESSAO && (
          <CampoBusca
            value={termo}
            name='termo'
            placeholder='Filtrar por nome'
            buttonName='Filtrar'
            onCampoChange={setTermo}
            onClick={handleFiltrarExpressoesPorTermo}
          />
        )}

        <Typography variant='h6' className={classes.subtituloAssociarTema}>
          Expressões - Marque o que deseja associar ao tema
        </Typography>
        <TabelaVincularExpressoes
          classes={classes}
          expressoes={expressoesFilter}
          expressoesSelecionadas={expressoesSelecionadas}
          onSelecionarExpressao={handleSelecionarExpressao}
        />
      </PaperBox>

      <Box mt={2}>
        <Button
          variant='contained'
          color='primary'
          style={{ marginRight: 10 }}
          onClick={() => handleVincular()}
        >
          Associar ao Tema
        </Button>
        <Button variant='outlined' color='primary' onClick={() => onFecharDrawer()}>Cancelar</Button>
      </Box>
    </React.Fragment>
  )
}

VincularExpressao.propTypes = {
  classes: PropTypes.object,
  contexto: PropTypes.object,
  codigoTema: PropTypes.number,
  expressoesVinculadas: PropTypes.array,
  onVincularExpressoesAoTema: PropTypes.func.isRequired,
  onFecharDrawer: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  contexto: state.contexto,
  orcamentos: state.orcamentos
})

export default connect(mapStateToProps, { buscarOrcamentos })(withRouter(VincularExpressao))
