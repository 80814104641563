import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import { converterMoedaBRParaNumero, showSuccessMsg, mapStateToPropsContexto } from '../../../../../js/util';
import { context_httpput } from '../../../../../js/httpRequest';
import { connect } from 'react-redux';

const RelatorioEquipamento = ({ equipamento, contexto }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedEquipamento, setEditedEquipamento] = useState({ ...equipamento });
  
  const [initialValues, setInitialValues] = useState({ ...equipamento });


  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setEditedEquipamento({ ...equipamento });
  };


  const handleSave = async () => {
    const { codigo, descricao, custoProdutivo, custoImprodutivo } = editedEquipamento;

    
    const custoProdutivoChanged = custoProdutivo !== initialValues.custoProdutivo;
    const custoImprodutivoChanged = custoImprodutivo !== initialValues.custoImprodutivo;

    
    const convertedCustoProdutivo = custoProdutivoChanged
      ? converterMoedaBRParaNumero(custoProdutivo.toString())
      : custoProdutivo;

    const convertedCustoImprodutivo = custoImprodutivoChanged
      ? converterMoedaBRParaNumero(custoImprodutivo.toString())
      : custoImprodutivo;

    const dadosSalvar = {
      codigo,
      descricao,
      custoProdutivo: convertedCustoProdutivo,
      custoImprodutivo: convertedCustoImprodutivo,
    };

    const url = `precos/equipamentos/${editedEquipamento.codigo}`;
    const resultado = await context_httpput(url, contexto, dadosSalvar);
    if (resultado) {
      showSuccessMsg('Dados do equipamento alterados com sucesso.');
      setEditMode(false);
      window.location.reload();
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name !== 'codigo') {
      setEditedEquipamento((prevEquipamento) => ({
        ...prevEquipamento,
        [name]: value,
      }));

  }
};

useEffect(() => {
  setInitialValues({ ...equipamento });
}, [equipamento]);

  return (
    <div>
      {editMode ? (
        <div>
          <TextField
            name="codigo"
            label="Código"
            value={editedEquipamento.codigo}
            onChange={handleChange}
            InputProps={{ readOnly: true }} 
          />
          <TextField
            name="descricao"
            label="Descrição"
            value={editedEquipamento.descricao}
            onChange={handleChange}
            style={{ width: '100%' }}
          />
          <TextField
            name="custoProdutivo"
            label="Custo Produtivo"
            value={editedEquipamento.custoProdutivo.toLocaleString('pt-BR')}
            onChange={handleChange}
          />
          <TextField
            name="custoImprodutivo"
            label="Custo Improdutivo"
            value={editedEquipamento.custoImprodutivo.toLocaleString('pt-BR')}
            onChange={handleChange}
          />
          <IconButton color="primary" onClick={handleSave}>
            <DoneIcon />
          </IconButton>
          <IconButton color="primary" onClick={handleCancelEdit}>
            <ClearIcon />
          </IconButton>
        </div>
      ) : (
        <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">Equipamento</Typography>
            <IconButton color="primary" onClick={handleEdit} style={{ marginLeft: '8px' }}>
              <EditIcon />
            </IconButton>
          </div>
          <Typography>Código: {equipamento.codigo}</Typography>
          <Typography>Descrição: {equipamento.descricao}</Typography>
          <Typography>Custo Produtivo: {equipamento.custoProdutivo.toLocaleString('pt-BR')}</Typography>
          <Typography>Custo Improdutivo: {equipamento.custoImprodutivo.toLocaleString('pt-BR')}</Typography>
        </div>
      )}
    </div>
  );
};

RelatorioEquipamento.propTypes = {
  equipamento: PropTypes.object.isRequired,
  contexto: PropTypes.object.isRequired,
};

export default connect(mapStateToPropsContexto)(RelatorioEquipamento);
