import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import RegrasTipologiaComum from '../../../comum/tipologia/RegrasTipologiaComum'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'

const RegraTipologiaFormula = ({
  tipoComparacao,
  criterioComparacao,
  valorComparacao,
  valorComparacaoEmpty,
  valorComparacaoEntre,
  valorComparacaoEntreEmpty,
  tipoMedidaDispersao,
  valorMedidaDispersao,
  valorMedidaDispersaoEmpty,
  onCampoChange,
  onCampoTipoComparacaoChange,
  onValidarCampoEntre
}) => {
  return (
    <Box my={2}>
      <Typography gutterBottom>
        Regra da tipologia:
      </Typography>

      <MesmaLinha>
        <Typography gutterBottom style={{ marginRight: '1%' }}> O resultado da fórmula </Typography>
        <RegrasTipologiaComum
          tipoComparacao={tipoComparacao}
          criterioComparacao={criterioComparacao}
          valorComparacao={valorComparacao}
          valorComparacaoEmpty={valorComparacaoEmpty}
          valorComparacaoEntre={valorComparacaoEntre}
          valorComparacaoEntreEmpty={valorComparacaoEntreEmpty}
          tipoMedidaDispersao={tipoMedidaDispersao}
          valorMedidaDispersao={valorMedidaDispersao}
          valorMedidaDispersaoEmpty={valorMedidaDispersaoEmpty}
          onCampoChange={onCampoChange}
          onCampoTipoComparacaoChange={onCampoTipoComparacaoChange}
          onValidarCampoEntre={onValidarCampoEntre}
        />
      </MesmaLinha>
    </Box>
  )
}

RegraTipologiaFormula.propTypes = {
  classes: PropTypes.object,
  tipoComparacao: PropTypes.object.isRequired,
  criterioComparacao: PropTypes.object.isRequired,
  valorComparacao: PropTypes.string.isRequired,
  valorComparacaoEmpty: PropTypes.bool.isRequired,
  valorComparacaoEntre: PropTypes.string,
  valorComparacaoEntreEmpty: PropTypes.bool,
  tipoMedidaDispersao: PropTypes.object,
  valorMedidaDispersao: PropTypes.string,
  valorMedidaDispersaoEmpty: PropTypes.bool,
  onCampoChange: PropTypes.func.isRequired,
  onCampoTipoComparacaoChange: PropTypes.func.isRequired,
  onValidarCampoEntre: PropTypes.func.isRequired
}

export default RegraTipologiaFormula
