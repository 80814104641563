import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import PropTypes from 'prop-types'
import _ from 'lodash'
import AdicionarObservacao from './AdicionarObservacao'
import IconeExibirObservacao from './IconeExibirObservacao'

const IconeObservacao = ({
  item,
  open,
  setOpen,
  onItemAlterado,
  abrirModal
}) => {
  return (
    <TableCell>
      {
        (
          (_.isNil(item.observacao) || _.isEmpty(item.observacao)) &&
            <AdicionarObservacao
              item={item}
              onItemAlterado={onItemAlterado}
              abrirModal={abrirModal}
            />
        ) || (
          <IconeExibirObservacao open={open} setOpen={setOpen} />
        )
      }
    </TableCell>
  )
}

IconeObservacao.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onItemAlterado: PropTypes.func.isRequired,
  abrirModal: PropTypes.func.isRequired
}

export default IconeObservacao
