import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Card, CardContent, Chip, IconButton, makeStyles, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { Edit } from '@material-ui/icons'
import ModalExclusaoEmpreendimento from './ModalExclusaoEmpreendimento'
import axios from 'axios'
import ModalEdicaoEmpreendimento from './ModalEdicaoEmpreendimento'
import _ from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  content: {
    padding: 16,
    '&:last-child': {
      paddingBottom: 16,
    },
  },
  card: {
    border: '1px solid #e5e5e5',
    borderRadius: 4,
    width: '320px',
    margin: 0,
    '&:hover': {
      cursor: 'pointer',
      transition: 'all 0.2s ease 0s',
      boxShadow: 'rgb(4 17 29 / 25%) 0px 0px 8px 0px',
      backgroundColor: 'rgb(251, 253, 255)',
    },
  },
  cardSelected: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderLeft: `6px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    width: '320px',
    margin: 0,
    transition: 'all 0.1s ease 0s',
  },
  dateInfo: {
    color: '#9B9B9B',
  },
  iconActions: {
    marginRight: 12
  }
}))

const CardEmpreendimento = ({ empreendimento, click, excluirEmpreendimento, alterarEmpreendimento }) => {
  const { state } = useLocation()
  const history = useHistory()
  const classes = useStyles()

  const { nome, nomeUsuarioIncluiu, numeroObras, cod, siglaUnidade } = empreendimento
  const [openModalExclusao, setOpenModalExclusao] = useState(false)
  const [openModalEdicao, setOpenModalEdicao] = useState(false)
  const [inputEdicao, setInputEdicao] = useState('')
  const [error, setError] = useState(false)

  const abrirModalEdicao = () => {
    setOpenModalEdicao(true)
  }

  const fecharModalEdicao = () => {
    setOpenModalEdicao(false)
  }

  const handleEdicaoInput = (e) => {
    if (error && inputEdicao.trim().length > 0) setError(false)
    setInputEdicao(e.target.value)
  }

  const confirmarEdicao = async () => {
    try {
      if (inputEdicao.trim().length < 1) {
        setError(true)
      } else {
        const empreendimentoModificado = _.assign({}, empreendimento)
        empreendimentoModificado.nome = inputEdicao
        setOpenModalEdicao(false)
        await axios.patch(`/api/empreendimentos/${cod}`, { nome: inputEdicao })
        alterarEmpreendimento(empreendimentoModificado)
      }
    } catch (e) {
      return e
    }
  }

  const abrirModalExclusao = () => {
    setOpenModalExclusao(true)
  }

  const excluir = async () => {
    const response = await axios.delete(`/api/empreendimentos/${cod}`)
    if(response) {
      excluirEmpreendimento(empreendimento)
      setOpenModalExclusao(false)
      history.push('/empreendimentos/')
    }
  }

  const fecharModalExclusao = () => {
    setOpenModalExclusao(false)
  }

  return (
    <Card
      className={ state?.cod === empreendimento.cod ? classes.cardSelected : classes.card }
      elevation={0}
    >
      <CardContent className={classes.content} onClick={() => { click(cod, nome) }}>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant="subtitle2">
            {nome}
          </Typography>
          <Chip
              size='small'
              label={siglaUnidade}
              color='primary'
            />
        </Box>
        <Typography variant="caption" color='primary'>
            {numeroObras || '0'} {numeroObras === 1 ? ' obra':' obras'} • {nomeUsuarioIncluiu
                .split(/(\s).+\s/)
                .join('')
                .toLowerCase()}
          </Typography>
          <>
          {state?.cod === empreendimento.cod ? (
            <Box display='flex'>
              <IconButton onClick={() => abrirModalEdicao()} size="small" className={classes.iconActions}>
                <Edit fontSize='small' color="primary" />
              </IconButton>
              <IconButton onClick={() => abrirModalExclusao()} size="small">
                <DeleteIcon fontSize='small' color="primary" />
              </IconButton>
            </Box>
          ) : null}
        </>
      </CardContent>
      <ModalExclusaoEmpreendimento
        cancelar={fecharModalExclusao}
        handleDialog={openModalExclusao}
        click={() => excluir()}
      />
      <ModalEdicaoEmpreendimento
        cancelar={fecharModalEdicao}
        handleDialog={openModalEdicao}
        click={() => confirmarEdicao()}
        error={error}
        handleInput={handleEdicaoInput}
        nomeEmpreendimento={nome}
      />
    </Card>
  )
}

CardEmpreendimento.propTypes = {
  empreendimento: PropTypes.object,
  click: PropTypes.func,
  excluirEmpreendimento: PropTypes.func,
  alterarEmpreendimento: PropTypes.func,
  urlCod: PropTypes.number,
}

export default CardEmpreendimento
