import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  pesquisaOrcamento: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  comboExpressao: {
    backgroundColor: '#fff',
    width: 550
  },
  botaoPesquisarOrcamento: {
    height: 46,
    marginLeft: theme.spacing(2),
    padding: '15px 20px'
  }
}))

const CampoBusca = ({ value, name, buttonName, placeholder, onCampoChange, onClick }) => {
  const classes = useStyles()
  return (
    <Box mt={1} className={classes.pesquisaOrcamento}>
      <TextField
        name={name}
        value={value}
        variant='outlined'
        placeholder={placeholder}
        className={classes.comboExpressao}
        onChange={(e) => onCampoChange(e.target.value)}
      />
      <Button
        variant='outlined'
        color='primary'
        className={classes.botaoPesquisarOrcamento}
        onClick={() => onClick()}>{buttonName}
      </Button>
    </Box>
  )
}

CampoBusca.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  buttonName: PropTypes.string,
  onCampoChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
}

export default CampoBusca
