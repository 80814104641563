import React, { Component } from 'react'
import Divider from '@material-ui/core/Divider/Divider'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import { DialogActions } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog/Dialog'
import _ from 'lodash'
import { connect } from 'react-redux'
import TituloModal from '../../../../comum/TituloModal'
import CampoMoeda from '../../../../comum/CampoMoeda'
import PropTypes from 'prop-types'
import { context_httpput, context_httpget } from '../../../../../js/httpRequest'
import { converterParaNumero, showErrorMsg, showSuccessMsg } from '../../../../../js/util'

const defaultState = {
  bdi: '',
  bdiMedioDefinido: false,
  bdiIsEmpty: false
}

class ModalAtribuirBDI extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    onItensAlterados: PropTypes.func.isRequired,
    contexto: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = defaultState
    this.atribuiBDI = this.atribuiBDI.bind(this)
  }

  preencherBdi = () => {
    if (!this.state.bdiMedioDefinido) {
      const { contexto } = this.props
      context_httpget(`curva-abc/bdi-medio?tipoObra=${contexto.obra.tipoObra}`, contexto)
        .then((bdiMedio) => {
          this.setState({ bdi: bdiMedio, bdiMedioDefinido: true})
        })
        .catch(() => {
          showErrorMsg('Não foi possível obter o BDI médio.')
        });
    }    
  }

  handleChange = (event) => {
    const bdi = event.target.value
    this.setState({
      bdi,
      bdiIsEmpty: _.isEmpty(bdi)
    })
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.atribuiBDI()
    }
  }

  fecharModal = () => {
    this.setState(defaultState)
    this.props.close()
  }

  async atribuiBDI () {
    const { bdi } = this.state

    if (_.isEmpty(bdi)) {
      this.setState({
        bdiIsEmpty: true
      })
    } else {
      const { contexto, onItensAlterados } = this.props
      const dados = await context_httpput('curva-abc/atribuir-bdi', contexto, { bdi: converterParaNumero(bdi) })
      this.fecharModal()
      if (dados) {
        onItensAlterados(dados)
        showSuccessMsg('BDI atribuido a todos itens com sucesso')
      }
    }
  }

  render () {
    const { open } = this.props
    const { bdi, bdiIsEmpty } = this.state

    return (
      <Dialog open={open} fullWidth disableEnforceFocus className='mauto w35perc' >
        <TituloModal onClose={this.fecharModal}>
          Atribuir BDI a todos os itens da Curva ABC
        </TituloModal>

        <Divider/>

        <DialogContent className='m10' >
          <CampoMoeda
            name={'bdi'}
            label={`Valor do BDI${bdiIsEmpty ? ' (Preenchimento Obrigatório)' : ''}`}
            value={bdi}
            error={bdiIsEmpty}
            maxLength={6}
            autoFocus={true}
            fullWidth={true}
            margin={'dense'}
            variant={'standard'}
            onChange={(e) => this.handleChange(e)}
            onKeyPress={(e) => this.handleKeyPress(e)}
            onFocus={this.preencherBdi}
          />
        </DialogContent>

        <DialogActions>
          <Button variant='outlined' color='primary' onClick={this.fecharModal}>Cancelar</Button>
          <Button variant='contained' color='primary' onClick={this.atribuiBDI}>Confirmar</Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ contexto }) => ({ contexto })

export default connect(mapStateToProps)(ModalAtribuirBDI)
