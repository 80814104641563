import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import { TIPO_OPERADORES, TIPO_PARAMETRO_EXPRESSAO, TIPO_PARAMETRO_FORMULA, TIPO_PARAMETRO_VALOR_NUMERICO, TIPO_PARAMETRO_VARIAVEL_ORCAMENTO } from '../../../../analise-conteudo'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import CampoVariavelOrcamento from './campos/CampoVariavelOrcamento'
import CampoValorNumerico from './campos/CampoValorNumerico'
import CampoExpressao from './campos/CampoExpressao'
import CampoFormula from './campos/CampoFormula'
import Select from '@material-ui/core/Select'
import SaveIcon from '@material-ui/icons/Save'

export class ParametrosCadastrados extends Component {
  static propTypes = {
    classes: PropTypes.object,
    value: PropTypes.array,
    expressoesDisponiveis: PropTypes.array.isRequired,
    formulasDisponiveis: PropTypes.array.isRequired,
    onParametroChange: PropTypes.func.isRequired,
    onSalvarParametro: PropTypes.func.isRequired,
    onExcluirParametro: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.handleCampoChange = this.handleCampoChange.bind(this)
  }

  operadorParametro = (index, parametro) => {
    const { classes } = this.props
    return (<Select
      value={parametro.operador || ''}
      onChange={(e) => this.handleCampoChange(e, parametro,'operador')}
      size='small'
      variant='outlined'
      classes={{root: classes.textfieldCriterio}}
      renderValue={(value) => value.operator}
    >
      {
        TIPO_OPERADORES.map((it) => (
          <MenuItem key={it.value} value={it}>
            { it.label }
          </MenuItem>
        ))
      }
    </Select>)
  }

  handleCampoChange (event, value, campo) {
    const { onParametroChange } = this.props
    onParametroChange({ ...value, [campo]: event.target.value })
  }

  renderParametro (parametro) {
    const { classes, expressoesDisponiveis, formulasDisponiveis } = this.props
    switch (parametro.tipo) {
      case TIPO_PARAMETRO_VALOR_NUMERICO:
        return <CampoValorNumerico
          classes={classes}
          parametro={parametro}
          onCampoChange={this.handleCampoChange}
        />
      case TIPO_PARAMETRO_VARIAVEL_ORCAMENTO:
        return <CampoVariavelOrcamento
          classes={classes}
          parametro={parametro}
          onCampoChange={this.handleCampoChange}
        />
      case TIPO_PARAMETRO_EXPRESSAO:
        return <CampoExpressao
          classes={classes}
          parametro={parametro}
          expressoesDisponiveis={expressoesDisponiveis}
          onCampoChange={this.handleCampoChange}
        />
      case TIPO_PARAMETRO_FORMULA:
        return <CampoFormula
          classes={classes}
          parametro={parametro}
          formulasDisponiveis={formulasDisponiveis}
          onCampoChange={this.handleCampoChange}
        />
      default: return
    }
  }

  render () {
    const { value, classes, onSalvarParametro, onExcluirParametro } = this.props
    return (
      value.map((it, index) => (
        <div key={`${it.codigo}`} className={classes.cardCriterio}>
          <Paper className={classes.paperCriterio} variant='outlined'>
            <div className={classes.operadorCriterio}>
              {
                index === 0 ? '=' : this.operadorParametro(index, it)
              }
            </div>

            <div className={classes.conteudoCriterio}>
              {
                this.renderParametro(it)
              }

              <div className={classes.acoesCriterio}>
                {
                  it.alterado && <Button color='primary' onClick={() => onSalvarParametro(it)}><SaveIcon fontSize='small'/></Button>
                }
                <Button color='default' onClick={() => onExcluirParametro(it)}>
                  <DeleteIcon fontSize='small'/>
                </Button>
              </div>
            </div>
          </Paper>
        </div>
      ))
    )
  }
}

export default ParametrosCadastrados
