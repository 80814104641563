import { ALTERAR_ORCAMENTO, BUSCAR_ORCAMENTOS, CADASTRAR_ORCAMENTO, CARREGAR_ORCAMENTO_EM_EDICAO, CARREGAR_REFERENCIAS_PRECO_ORCAMENTO_EM_EDICAO, EXCLUIR_ORCAMENTO } from '../actions/types'

const reducerOrcamentos = (state = [], action) => {
  switch (action.type) {
    case BUSCAR_ORCAMENTOS:
      return action.payload.data
    case ALTERAR_ORCAMENTO:
      return {
        ...state,
        orcamentos: state.orcamentos
          ? state.orcamentos.map((e) => (e.cod === action.payload.cod ? action.payload : e))
          : null
      }
    case CADASTRAR_ORCAMENTO:
      return {
        ...state,
        orcamentos: [action.payload].concat(state.orcamentos)
      }
    case EXCLUIR_ORCAMENTO:
      return state.filter((e) => e.cod !== action.payload.cod)
    case CARREGAR_ORCAMENTO_EM_EDICAO:
      return {
        ...state,
        orcamentoEmEdicao: action.payload.data
      }
    case CARREGAR_REFERENCIAS_PRECO_ORCAMENTO_EM_EDICAO:
      return {
        ...state,
        referenciasPrecoOrcamentoEmEdicao: action.payload
      }
    default:
      return state
  }
}

export default reducerOrcamentos
