import React from 'react'
import { Box, Card, CardContent, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import InfoIcon from '@material-ui/icons/Info'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import MenuItem from '@material-ui/core/MenuItem'
import { TIPOS_OPERADORES_TIPOLOGIA_COMBINADA } from '../analise-conteudo'
import TextField from '@material-ui/core/TextField'

const AnaliseConteudoContentCombinadaItem = ({
  classes,
  codigo,
  nome,
  descricao,
  operador,
  primeiraTipologia,
  resultadoPrimeiraTipologia,
  segundaTipologia,
  resultadoSegundaTipologia,
  onExcluirClick,
  onEditarCombinada,
  onChangeOperador
}) => {
  const handleResultadoTipologiaCombinada = () => {
    switch (operador.value) {
      case 'AND':
        return resultadoPrimeiraTipologia.temInconsistencia && resultadoSegundaTipologia.temInconsistencia ? incosistente() : naoIncosistente()
      case 'OR':
        return resultadoPrimeiraTipologia.temInconsistencia || resultadoSegundaTipologia.temInconsistencia ? incosistente() : naoIncosistente()
      default:
        return ''
    }
  }

  const incosistente = () => {
    return (<>
      <InfoIcon size='large' className={classes.alinharIconeResultadoTipologia} />
      <span>A combinação das tipologias resultou-se em uma possível inconsistência.</span>
    </>)
  }

  const naoIncosistente = () => {
    return (<>
      <CheckCircleIcon className={classes.iconePadrao} size='large' />
      <span>Não foram encontrados inconsistências no resultado da combinação.</span>
    </>)
  }

  return (
    <Card elevation={0} className={classes.analiseCardItem}>
      <CardContent style={{ padding: 5 }}>
        <Box display='flex' justifyContent='space-between'>
          <div>
            <Typography variant='subtitle2'>{nome}</Typography>
            <Typography color='textSecondary' className={classes.cardTemaSidebarSubtitle}>{descricao}</Typography>
          </div>
          <Box display='flex' justifyContent='space-between' alignItems='center' style={{ cursor: 'pointer' }}>
            <a data-tip data-for={`excluirID${codigo}`} onClick={onExcluirClick}>
              <DeleteIcon color='action' style={{ marginRight: 5 }} />
            </a>
            <ReactTooltip id={`excluirID${codigo}`} place='top' type='dark' effect='solid'>Excluir</ReactTooltip>

            <a data-tip data-for={`editarID${codigo}`} onClick={onEditarCombinada}>
              <EditIcon color='action' />
            </a>
            <ReactTooltip id={`editarID${codigo}`} place='top' type='dark' effect='solid'>Editar</ReactTooltip>
          </Box>
        </Box>
        <Box mt={2} display='flex' alignItems='center'>
          <Typography className={classes.cardTemaSidebarSubtitle} style={{ marginRight: 10 }}>
            {primeiraTipologia.nome}
          </Typography>
          <TextField
            select
            size='small'
            value={operador}
            onChange={(e) => onChangeOperador(codigo, e.target.value)}
            variant='outlined'
            style={{ marginRight: 10 }}
          >
            {TIPOS_OPERADORES_TIPOLOGIA_COMBINADA.map((it) =>
              (<MenuItem key={it.value} value={it}>
                {it.label}
              </MenuItem>))
            }
          </TextField>
          <Typography className={classes.cardTemaSidebarSubtitle}>
            {segundaTipologia.nome}
          </Typography>
        </Box>
        <Box display='flex' alignItems='center'>
          {handleResultadoTipologiaCombinada()}
        </Box>
      </CardContent>
    </Card>
  )
}

AnaliseConteudoContentCombinadaItem.propTypes = {
  classes: PropTypes.object,
  codigo: PropTypes.number,
  nome: PropTypes.string,
  descricao: PropTypes.string,
  operador: PropTypes.object,
  qtdItens: PropTypes.number,
  valorTotal: PropTypes.string,
  percentual: PropTypes.string,
  primeiraTipologia: PropTypes.object,
  resultadoPrimeiraTipologia: PropTypes.object,
  segundaTipologia: PropTypes.object,
  resultadoSegundaTipologia: PropTypes.object,
  onExcluirClick: PropTypes.func.isRequired,
  onEditarCombinada: PropTypes.func.isRequired,
  onChangeOperador: PropTypes.func.isRequired
}

export default AnaliseConteudoContentCombinadaItem
