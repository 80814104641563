import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import { BUSCAR_UJS } from '../types'

export const url = () => '/api/ujs/codorgao'

const buscarUjs = (cods) => {
  const success = axios.post(url(), cods, SLOW_REQUEST_CONFIG)
  return {
    type: BUSCAR_UJS,
    payload: success
  }
}

export default buscarUjs
