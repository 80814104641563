const styles = (theme) => ({
  switchTema: {
    border: '1px solid #D5D8DC',
    width: 250,
    borderRadius: 4,
    marginLeft: 0,
    background: '#ffffff',
    height: 40
  },
  rootListTransfer: {
    margin: 'auto'
  },
  paperListTransfer: {
    width: 446,
    height: 300,
    overflow: 'auto'
  },
  buttonListTransfer: {
    margin: theme.spacing(0.5, 0)
  },
  tituloListTransfer: {
    display: 'flex'
  },
  labelTipoObra: {
    marginRight: theme.spacing(58)
  },
  colorTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer'
  },
  searchTema: {
   maxWidth: '500px'
  },
  temaTable: {
    border: '1px solid #D5D8DC',
    borderRadius: 0,
  },
  expressaoIndisponivel: {
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    height: 20,
    width: 20,
    marginRight: theme.spacing(1),
    borderRadius: 50,
    display: 'inline',
    backgroundColor: '#bbb'
  },
  expressaoDisponivel: {
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    height: 20,
    width: 20,
    marginRight: theme.spacing(1),
    borderRadius: 50,
    display: 'inline',
    backgroundColor: '#228B22'
  },
  paperDrawer: {
    background: '#f6f8fa',
    width: '70%',
    padding: theme.spacing(4),
    borderLeft: 'solid 4px rgba(67,133,191,1)',
    boxShadow: 'none'
  },
  subtituloAssociarTema: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontWeight: 'inherit'
  },
  accordionAnaliseConteudo: {
    backgroundColor: '#e2e4e6'
  },
  accordionSummaryAnalise: {
    margin: 0
  },
  analiseCardItem: {
    width: '100%',
    marginTop: 5
  },
  cardTemaSidebarSubtitle: {
    fontSize: 14
  },
  regrasTipologiaCombinadas: {
    marginRight: 2
  }
})
export default styles
