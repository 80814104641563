import WarningIcon from '@material-ui/icons/Warning'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import PropTypes from 'prop-types'
import _ from 'lodash'

const IconeLinhaNova = ({ exibir, mensagem, onClick }) => (
  exibir && (
    <Tooltip title={mensagem}>
        <WarningIcon
          onClick={onClick}
          fontSize='small'
          style={{ color: 'rgb(27,105,182)', marginRight: 4, cursor: `${_.isNil(onClick) ? 'default': 'pointer'}` }}  />
    </Tooltip>
  )
)

IconeLinhaNova.propTypes = {
  exibir: PropTypes.bool.isRequired,
  mensagem: PropTypes.string,
  onClick: PropTypes.func
}

export default IconeLinhaNova
