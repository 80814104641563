import React from 'react'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import IconButton from '@material-ui/core/IconButton'
import PropTypes from 'prop-types'

const IconeExibirObservacao = ({ open, setOpen }) => {
  return (
    <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
      {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
    </IconButton>
  )
}

IconeExibirObservacao.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
}

export default IconeExibirObservacao
