import React, { Component } from 'react'
import { Tooltip, withStyles } from '@material-ui/core/'
import { withRouter } from 'react-router-dom'
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined'
import ErrorIcon from '@material-ui/icons/ErrorOutline'
import Link from '@material-ui/core/Link'
import PropTypes from 'prop-types'
import styles from './styles'
import { connect } from 'react-redux'
import { mapStateToPropsContexto } from '../../../../js/util'
import { context_httpget } from '../../../../js/httpRequest'
import _ from 'lodash'

const BotaoNavegacao = ({
  textTooltip,
  textLink,
  onClick,
  quantidade,
  icon,
  classes,
}) => {
  return (
    <Tooltip title={textTooltip}>
      <Link
        color={_.isNil(quantidade) ? 'inherit' : 'primary'}
        className={classes.linkIcon}
        onClick={onClick}
      >
        {icon} {_.isNil(quantidade) ? '...' : quantidade} {textLink}
      </Link>
    </Tooltip>
  )
}

BotaoNavegacao.propTypes = {
  textTooltip: PropTypes.string.isRequired,
  textLink: PropTypes.string.isRequired,
  quantidade: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.any.isRequired,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object,
  resumoOrcamento: PropTypes.object,
}

export { BotaoNavegacao }

class MenuPossiveisErrosInconsistencias extends Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    contexto: PropTypes.object,
    classes: PropTypes.object,
    resumoOrcamento: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      numeroPossiveisErros: null,
      numeroPossiveisInconsistencias: null,
    }
  }

  async componentDidMount() {
    const { contexto, resumoOrcamento } = this.props
    if (contexto && !resumoOrcamento) {
      const resumo = await context_httpget('resumo', contexto, () => {}, true)
      if (resumo) {
        this.setState({
          numeroPossiveisErros: resumo.numeroPossiveisErros,
          numeroPossiveisInconsistencias: resumo.numeroPossiveisInconsistencias,
        })
      }
    } else if (resumoOrcamento) {
      this.setState({
        numeroPossiveisErros: resumoOrcamento.numeroPossiveisErros,
        numeroPossiveisInconsistencias:
          resumoOrcamento.numeroPossiveisInconsistencias,
      })
    }
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate (prevProps, prevState, snapshot) {
    const { resumoOrcamento } = this.props
    if (!_.isNil(resumoOrcamento) && resumoOrcamento !== prevProps.resumoOrcamento) {
      this.setState({
        numeroPossiveisErros: resumoOrcamento.numeroPossiveisErros,
        numeroPossiveisInconsistencias:
        resumoOrcamento.numeroPossiveisInconsistencias
      })
    }
  }

  urlBase = () => {
    const { params } = this.props.match
    return `/empreendimentos/${params.idEmpreendimento}/obras/${params.idObra}/orcamentos/${params.idOrcamento}`
  }

  handleClick = (navegacao) => {
    this.props.history.push(`${this.urlBase()}/${navegacao}`)
  }

  render() {
    const { classes } = this.props
    const { numeroPossiveisErros, numeroPossiveisInconsistencias } = this.props
    return (
      <div className={classes.row}>
        <BotaoNavegacao
          textTooltip="Corrija possíveis erros nos itens de orçamento"
          textLink="Possíveis Erros"
          classes={classes}
          onClick={() => this.handleClick('possiveis-erros')}
          quantidade={numeroPossiveisErros}
          icon={<ReportProblemOutlinedIcon fontSize="small" />}
        />

        <BotaoNavegacao
          textTooltip="Exibe testes pré-definidos no orçamento"
          textLink="Inconsistências"
          classes={classes}
          quantidade={numeroPossiveisInconsistencias}
          onClick={() => this.handleClick('possiveis-inconsistencias')}
          icon={<ErrorIcon fontSize="small" />}
        />
      </div>
    )
  }
}

export default connect(mapStateToPropsContexto)(
  withRouter(withStyles(styles)(MenuPossiveisErrosInconsistencias))
)
