import React from 'react'
import { Collapse, TableCell, TableRow, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import ModalObservacao from './colunas/observacao/ModalObservacao'
import styles from './styles'

const LinhaObservacao = ({
  open,
  colSpan,
  exibirModal,
  abrirModal,
  fecharModal,
  onItemAlterado,
  item,
  isRowOdd,
  classes
}) => {
  return item && (
    <>
      <TableRow className={isRowOdd ? classes.linhaPar : ''}>
        <TableCell className={open ? '' : classes.celulaObservacaoVazia}  />
        <TableCell className={open ? classes.celulaObservacao : classes.celulaObservacaoVazia} colSpan={colSpan}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <div>
              { item.observacao }<IconButton color='primary' onClick={abrirModal}>
                <EditIcon fontSize='small' />
              </IconButton>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
      <ModalObservacao
        exibir={exibirModal}
        fechar={fecharModal}
        onComplete={onItemAlterado}
        item={item}
      />
    </>
  )
}

LinhaObservacao.propTypes = {
  open: PropTypes.bool.isRequired,
  colSpan: PropTypes.number.isRequired,
  abrirEdicaoObservacao: PropTypes.func.isRequired,
  texto: PropTypes.string,
  isRowOdd: PropTypes.bool,
  classes: PropTypes.object,
  item: PropTypes.object,
  exibirModal: PropTypes.bool.isRequired,
  abrirModal: PropTypes.func.isRequired,
  fecharModal: PropTypes.func.isRequired,
  onItemAlterado: PropTypes.func.isRequired
}

export default withStyles(styles)(LinhaObservacao)
