import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody } from '@material-ui/core'
import ItemComposicaoAuxiliarRow from './ItemComposicaoAuxiliarRow'
import CabecalhoComposicaoAuxiliar from './CabecalhoComposicaoAuxiliar'
import { context_httpget } from '../../../../../../js/httpRequest'
import { connect } from 'react-redux'
import { mapStateToPropsContexto } from '../../../../../../js/util'
import Carregando from '../../../../../comum/Carregando'
import _ from 'lodash'

export class ComposicoesAuxiliares extends Component {
  static propTypes = {
    contexto: PropTypes.object,
    composicao: PropTypes.object,
    handleVerComposicaoAuxiliar: PropTypes.func.isRequired,
    classes: PropTypes.object,
    onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      composicoesAuxiliares: null
    }
  }

  componentDidMount () {
    if(this.props.composicao) {
      this.recuperarComposicoesAuxiliares()
    }
  }

  componentDidUpdate (prevProps) {
    if(this.props.composicao !== prevProps.composicao) {
      this.recuperarComposicoesAuxiliares()
    }
  }

  async recuperarComposicoesAuxiliares () {
    const { composicao, contexto } = this.props
    const data = await context_httpget(`precos/composicoes/auxiliares?composicao=${composicao.codigo}`, contexto)
    if (data) {
      this.setState({
        composicoesAuxiliares: data
      })
    }
  }

  renderComposicoes (composicoes, tipo) {
    const { classes, handleVerComposicaoAuxiliar, onAtualizarItemCurvaABCComposicaoDuplicada } = this.props
    return composicoes.map((it) => (
      <ItemComposicaoAuxiliarRow
        key={it.codigo}
        classes={classes}
        composicaoComAuxiliares={it}
        tipoComposicao={tipo}
        handleVerComposicaoAuxiliar={handleVerComposicaoAuxiliar}
        onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
      />
    ))
  }

  render () {
    const { composicao, classes } = this.props
    const { composicoesAuxiliares } = this.state
    if(_.isNil(composicoesAuxiliares)) {
      return <Carregando />
    }
    const {
      atividadesAuxiliares,
      temposFixos,
      transportes
    } = composicoesAuxiliares
    return composicao && (
      <Table className={classes.table} aria-label='simple table'>
        <CabecalhoComposicaoAuxiliar composicao={composicao} />
        <TableBody>
          { this.renderComposicoes(atividadesAuxiliares, 'Atividade Auxiliar') }
          { this.renderComposicoes(temposFixos, 'Tempo Fixo') }
          { this.renderComposicoes(transportes, 'Momento de Transporte') }
        </TableBody>
      </Table>
    )
  }
}

export default connect(mapStateToPropsContexto)(ComposicoesAuxiliares)
