import React from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { converterMoedaBRParaNumero, formatarNumeroParaMoedaBR, handleCampoNumericoChange } from '../../../../../../../../js/util'
import PropTypes from 'prop-types'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'

const FormularioEquipamentoComposicao = ({
  codigo, codigoEmpty, codigoEditavel,
  descricao, descricaoEmpty,
  quantidade, quantidadeEmpty,
  utilizacaoOperativa, utilizacaoOperativaEmpty,
  custoProdutivo, custoProdutivoEmpty,
  custoImprodutivo, custoImprodutivoEmpty,
  handleCampoTextoChange, onSalvar, onCancelar
}) => {
  const handleUtilizacaoOperativaChange = (event) => {
    if (event.target.value.length > 4) return
    handleCampoNumericoChange(event, (e) => handleCampoTextoChange(e, 'utilizacaoOperativa'), 1)
  }

  const utilizacaoImprodutiva = formatarNumeroParaMoedaBR(1 - Number(converterMoedaBRParaNumero(utilizacaoOperativa)), 2, 5)

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSalvar();
    }
  };

  const handleEscKeyPress = (event) => {
    if (event.key === 'Escape') {
      onCancelar();
    }
  };

  React.useEffect(() => {
    document.body.addEventListener('keydown', handleEnterKeyPress);
    document.body.addEventListener('keydown', handleEscKeyPress);
    return () => {
      document.body.removeEventListener('keydown', handleEnterKeyPress);
      document.body.removeEventListener('keydown', handleEscKeyPress);
    };
  }, []);

  return (
    <>
      <Grid container spacing={2}>
      
        <Grid item md={3}>
        <Box mb={2} ml={2} >
          <FormControl variant='outlined'>
            <Typography variant='subtitle1'>Código</Typography >
            <TextField
              variant='outlined'
              value={codigo}
              disabled={!codigoEditavel}
              placeholder='Ex: E9500'
              error={codigoEmpty}
              onChange={(e) => handleCampoTextoChange(e, 'codigo')}
            />
          </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={10}>
        <Box mb={2} ml={2} >
          <FormControl variant='outlined' fullWidth>
            <Typography variant='subtitle1'>Descrição</Typography>
            <TextField
              variant='outlined'
              multiline
              rows={3}
              value={descricao}
              error={descricaoEmpty}
              onChange={(e) => handleCampoTextoChange(e, 'descricao')}
            />
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
        <Box mb={2} ml={2} >
          <FormControl variant='outlined' fullWidth>
            <Typography variant='subtitle1'>Quantidade</Typography>
            <TextField
              variant='outlined'
              value={quantidade}
              error={quantidadeEmpty}
              onChange={(e) => handleCampoNumericoChange(e, (e) => handleCampoTextoChange(e, 'quantidade'))}
            />
          </FormControl>
          </Box>
        </Grid>
        <Grid item xs={12} md={9}/>

        <Grid item xs={12} md={3}>
        <Box mb={2} ml={2} >
          <FormControl variant='outlined' fullWidth>
            <Typography variant='subtitle1'>Utilização operativa</Typography>
            <TextField
              variant='outlined'
              value={utilizacaoOperativa}
              error={utilizacaoOperativaEmpty}
              onChange={handleUtilizacaoOperativaChange}
            />
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl variant='outlined' fullWidth>
            <Typography variant='subtitle1'>Utilização improdutiva</Typography>
            <TextField
              variant='outlined'
              value={utilizacaoImprodutiva}
              disabled
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}/>

        <Grid item xs={12} md={3}>
        <Box mb={2} ml={2} >
          <FormControl variant='outlined' fullWidth>
            <Typography variant='subtitle1'>Custo Produtivo</Typography>
            <TextField
              variant='outlined'
              value={custoProdutivo}
              error={custoProdutivoEmpty}
              onChange={(e) => handleCampoNumericoChange(e, (e) => handleCampoTextoChange(e, 'custoProdutivo'))}
            />
          </FormControl>
          </Box>
        </Grid>

        <Grid item xs={12} md={3}>
          <FormControl variant='outlined' fullWidth>
            <Typography variant='subtitle1'>Custo Improdutivo</Typography>
            <TextField
              variant='outlined'
              value={custoImprodutivo}
              error={custoImprodutivoEmpty}
              onChange={(e) => handleCampoNumericoChange(e, (e) => handleCampoTextoChange(e, 'custoImprodutivo'))}
            />
          </FormControl>
        </Grid>
        
      </Grid>

      <Box mt={3} ml={2}>
        <MesmaLinha>
          <Button variant='contained' color='primary' onClick={onSalvar}>Salvar</Button>
          <Button variant='text' color='primary' onClick={onCancelar}>Cancelar</Button>
        </MesmaLinha>
      </Box>
    </>
  )
}

FormularioEquipamentoComposicao.propTypes = {
  codigo: PropTypes.string.isRequired,
  codigoEmpty: PropTypes.bool.isRequired,
  codigoEditavel: PropTypes.bool.isRequired,
  descricao: PropTypes.string.isRequired,
  descricaoEmpty: PropTypes.bool.isRequired,
  quantidade: PropTypes.string.isRequired,
  quantidadeEmpty: PropTypes.bool.isRequired,
  utilizacaoOperativa: PropTypes.string.isRequired,
  utilizacaoOperativaEmpty: PropTypes.bool.isRequired,
  custoProdutivo: PropTypes.string.isRequired,
  custoProdutivoEmpty: PropTypes.bool.isRequired,
  custoImprodutivo: PropTypes.string.isRequired,
  custoImprodutivoEmpty: PropTypes.bool.isRequired,
  handleCampoTextoChange: PropTypes.func.isRequired,
  onSalvar: PropTypes.func.isRequired,
  onCancelar: PropTypes.func.isRequired
}

export default FormularioEquipamentoComposicao
