import React from 'react'
import { Box, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export const AnaliseConteudoSidebarItem = ({ classes, titulo, subtitulo, value, tipo, seAtiva, onSelecionarItemSidbar }) => {
  return (
    <Card
      elevation={0}
      variant='outlined'
      style={{ marginTop: 4 }}
      className={classNames(classes.rootCardTemaSidebar, { [classes.rootCardTemaAtivo]: seAtiva })}
      onClick={() => onSelecionarItemSidbar(tipo, value)}
    >
      <Box p={1}>
        <Typography variant='subtitle1' gutterBottom>{titulo}</Typography>
        <Typography color='textSecondary' className={classes.cardTemaSidebarSubtitle}>
          {subtitulo}
        </Typography>
      </Box>
    </Card>
  )
}

AnaliseConteudoSidebarItem.propTypes = {
  classes: PropTypes.object,
  titulo: PropTypes.string,
  subtitulo: PropTypes.string,
  value: PropTypes.any,
  tipo: PropTypes.string,
  seAtiva: PropTypes.bool,
  onSelecionarItemSidbar: PropTypes.func.isRequired
}

export default AnaliseConteudoSidebarItem
