const styles = (theme) => ({
  cabecalho: {
    display: 'flex',
    flexDirection: 'column'
  },
  tituloMenu: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media (max-width: 1024px)': {
      flexDirection: 'column',
    }
  },
  menu: {
    alignItems: 'baseline',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    '@media (max-width: 1024px)': {
      justifyContent: 'flex-start'
    }
  },
  expanded: {
    minWidth: 122,
    marginLeft: 24
  },
  menuListas: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    marginTop: '-8px',
    marginBottom: 16
  },
  btnAbasItensPreco: {
    borderRadius: 0,
    fontSize: 14,
    fontWeight: '500',
    marginRight: 4,
    textTransform: 'uppercase',
  },
  btnAbaAtivado: {
    borderRadius: 0,
    boxSizing: 'border-box',
    borderBottom: [[2, 'solid', theme.palette.primary.main]],
    color: theme.palette.primary.main,
    fill: '#4a4a4a',
    marginRight: 8,
    textTransform: 'uppercase',
    letterSpacing: '1.5px'
  },
  imgIconAbaPreco: {
    width: 21,
    height: 21
  },
  txtAbas: {
    fontSize: 14,
    lineHeight: '24px',
    marginLeft: 10,
    letterSpacing: '1.5px'
  }
})
export default styles
