import React, { Component } from 'react'
import IncluirNaAmostraCurvaABC from '../amostra/ToggleIncluirNaAmostraCurvaABC'
import BdiCurvaABC from '../bdi/BdiCurvaABC'
import CodigoServicoCurvaABC from './colunas/CodigoServicoCurvaABC'
import CelulaUnidadeCurvaAbc from './colunas/CelulaUnidadeCurvaAbc'
import CelulaSobreprecoCurvaAbc from '../sobrepreco/CelulaSobreprecoCurvaAbc'
import PropTypes from 'prop-types'
import * as colunas from './colunas/colunas'
import CelulaDescricao from './colunas/CelulaDescricao'
import CelulaDescricaoRef from './colunas/CelulaDescricaoRef'
import CelulaSomenteTexto from './colunas/CelulaSomenteTexto'
import { connect } from 'react-redux'
import CelulaObservacoes from './colunas/CelulaObservacoes'
import LinhaObservacao from './LinhaObservacao'
import TableRow from '@material-ui/core/TableRow'
import { Checkbox, Chip, TableCell, withStyles } from '@material-ui/core'
import styles from './styles'
import MesmaLinha from '../../../../comum/MesmaLinha'
import CelulaValorReferencia from './colunas/CelulaValorReferencia'
import CelulaValorTotalReferencia from './colunas/CelulaValorTotalReferencia'
import { existeObjetoNaLista } from '../../../../../js/util'

export class ItemCurvaABCRow extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    hideLoadingPrecos: PropTypes.bool.isRequired,
    colunasExibir: PropTypes.array,
    onItemAlterado: PropTypes.func.isRequired,
    onExibirDetalhesChange: PropTypes.func.isRequired,
    onExibirDetalhesSobreprecoChange: PropTypes.func.isRequired,
    exibirDetalhes: PropTypes.bool.isRequired,
    metodoCalculo: PropTypes.object,
    mostrarSelecaoLinhas: PropTypes.bool,
    selecionaItem: PropTypes.func,
    itensSelecionados: PropTypes.array,
    classes: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      exibirObservacoes: false,
      exibirModalObservacoes: false
    }
    this.handleSetOpen = this.handleSetOpen.bind(this)
    this.abrirModalObservacoes = this.abrirModalObservacoes.bind(this)
    this.fecharModalObservacoes = this.fecharModalObservacoes.bind(this)
  }

  abrirModalObservacoes () {
    this.setState({ exibirModalObservacoes: true })
  }

  fecharModalObservacoes () {
    this.setState({ exibirModalObservacoes: false })
  }

  exibirColuna (coluna) {
    const { colunasExibir } = this.props
    return existeObjetoNaLista(colunasExibir, coluna)
  }

  handleSetOpen (exibirObservacoes) {
    this.setState({ exibirObservacoes })
  }

  render () {
    const {
      item,
      hideLoadingPrecos,
      onItemAlterado,
      onExibirDetalhesSobreprecoChange,
      colunasExibir,
      metodoCalculo,
      mostrarSelecaoLinhas,
      selecionaItem,
      itensSelecionados,
      classes
    } = this.props

    const { exibirObservacoes, exibirModalObservacoes } = this.state

    const isRowOdd = item.numero % 2 === 0
    const selecionado = itensSelecionados.indexOf(item) !== -1

    return (
      <React.Fragment>
        <TableRow key={item.numero} className={isRowOdd ? classes.linhaPrincipalPar : classes.linhaPrincipal}>
          <TableCell>
            <MesmaLinha>
              {
                mostrarSelecaoLinhas
                && <Checkbox color={'primary'} checked={selecionado} onChange={(e) => selecionaItem(e, item)} />
              }
              {item.numero}
            </MesmaLinha>
          </TableCell>
          <CodigoServicoCurvaABC exibir={this.exibirColuna(colunas.COD_SERVICO)} itemCurvaAbc={item} />
          {this.exibirColuna(colunas.STATUS) &&
          <TableCell>
            {item.analiseConcluida ? <Chip label='Concluída' variant='outlined'  color='primary' size='small'/> : <Chip label='Não analisado' variant='default' color='default' size='small'/>}
          </TableCell>}
          <IncluirNaAmostraCurvaABC exibir={this.exibirColuna(colunas.INCLUIR_NA_AMOSTRA)} itemCurvaAbc={item} onItemAlterado={onItemAlterado} />
          <CelulaUnidadeCurvaAbc exibir={this.exibirColuna(colunas.UNIDADE)} item={item} />
          <CelulaSomenteTexto exibir={this.exibirColuna(colunas.QUANTIDADE)} texto={item.quantidadeFormatado} />
          <CelulaSomenteTexto exibir={this.exibirColuna(colunas.PRECO_UNITARIO)} texto={item.precoUnitarioFormatado} />
          <CelulaSomenteTexto exibir={this.exibirColuna(colunas.PRECO_PARCIAL)} texto={item.precoParcialCalculadoFormatado} />
          <CelulaSomenteTexto exibir={this.exibirColuna(colunas.PERCENTUAL_ACUMULADO)} texto={item.percentualAcumuladoFormatado} />
          <CelulaSomenteTexto exibir={this.exibirColuna(colunas.PERCENTUAL)} texto={item.percentualFormatado} />
          <BdiCurvaABC exibir={this.exibirColuna(colunas.BDI)} itemCurvaAbc={item} onChange={onItemAlterado} />
          <CelulaSomenteTexto exibir={this.exibirColuna(colunas.UF)} texto={item.uf} />
          {
            (item.mes || item.ano)
              ? <CelulaSomenteTexto exibir={this.exibirColuna(colunas.DATA)} texto={item.mes + '/' + item.ano} />
              : <CelulaSomenteTexto exibir={this.exibirColuna(colunas.DATA)} texto={''} />
          }
          {
            this.exibirColuna(colunas.PRECO_UNIT_REF) && (
              <CelulaValorReferencia item={item} hideLoadingPrecos={hideLoadingPrecos} />
            )
          }

          {
            this.exibirColuna(colunas.PRECO_PARCIAL_REF) && (
              <CelulaValorTotalReferencia item={item} hideLoadingPrecos={hideLoadingPrecos} />
            )
          }

          <CelulaSobreprecoCurvaAbc
            exibir={this.exibirColuna(colunas.SOBREPRECO)}
            hideLoadingPrecos={hideLoadingPrecos}
            item={item}
            metodoCalculo={metodoCalculo}
            onExibirDetalhesSobreprecoChange={onExibirDetalhesSobreprecoChange}
          />

          <CelulaObservacoes
            exibir={this.exibirColuna(colunas.OBSERVACAO)}
            open={exibirObservacoes}
            setOpen={this.handleSetOpen}
            item={item}
            onItemAlterado={onItemAlterado}
            abrirModal={this.abrirModalObservacoes}
          />
        </TableRow>

        {
          this.exibirColuna(colunas.DESCRICAO) && (
            <TableRow className={isRowOdd ? classes.linhaPar : ''}>
              <CelulaDescricao exibir={true} item={item} />
            </TableRow>
          )
        }

        {
          this.exibirColuna(colunas.DESCRICAO_REF) && (
            <TableRow className={isRowOdd ? classes.linhaPar : ''}>
              <CelulaDescricaoRef exibir={true} texto={item.descricaoReferencia || ' - '}/>
            </TableRow>
          )
        }

        <LinhaObservacao
          exibirModal={exibirModalObservacoes}
          abrirModal={this.abrirModalObservacoes}
          fecharModal={this.fecharModalObservacoes}
          onItemAlterado={onItemAlterado}
          open={exibirObservacoes}
          colSpan={colunasExibir.length}
          item={item}
          isRowOdd={isRowOdd}
          abrirEdicaoObservacao={this.abrirModalObservacoes}
        />
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ curvaAbc }) => ({
  colunasExibir: curvaAbc.colunasExibir
})

export default connect(mapStateToProps)(withStyles(styles)(ItemCurvaABCRow))
