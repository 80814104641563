import React from 'react'

import { Box, Button, Dialog, DialogContent, DialogTitle, makeStyles, TextField } from '@material-ui/core'

import PropTypes from 'prop-types'

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(4)
  },
  boxConteudo: {
    width: '500px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  boxBotoes: {
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 0
  },
  botaoCancelar: {
    marginRight: theme.spacing(2)
  }
}))

const ModalCadastroEmpreendimento = ({
  click,
  handleDialog,
  handleInput,
  cancelar,
  error
}) => {
  const classes = useStyles()
  return (
    <Dialog open={handleDialog}>
      <DialogTitle>Cadastrar Empreendimento</DialogTitle>
      <DialogContent className={classes.dialog}>
        <Box className={classes.boxConteudo}>
          <TextField
            variant='outlined'
            fullWidth
            onChange={(e) => handleInput(e)}
            label={
              error ? 'Nome é obrigatório' : 'Qual é o nome do empreendimento ?'
            }
            error={error}
          />
          <Box className={classes.boxBotoes}>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => cancelar()}
              className={classes.botaoCancelar}
            >
              Cancelar
            </Button>
            <Button variant='contained' color='primary' onClick={() => click()}>
              Cadastrar
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}

ModalCadastroEmpreendimento.propTypes = {
  click: PropTypes.func,
  handleDialog: PropTypes.bool,
  handleInput: PropTypes.func,
  cancelar: PropTypes.func,
  error: PropTypes.bool
}

export default ModalCadastroEmpreendimento
