import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  tableBody: {
    backgroundColor: '#eee'
  },
  infoSecundaria: {
    fontSize: 12,
    fontWeight: 600
  },
  descricao: {
    fontSize: 12,
  }
}))

export default useStyles
