import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Box, Paper, Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import styles from './styles'
import { formatarData } from '../../js/util'

class CardAcessosRecentes extends Component {

  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    acessos: PropTypes.array
  }

  analisarOrcamento = (acesso) => {
    if(acesso.possuiCurvaAbc) {
      this.props.history.push(`/empreendimentos/${acesso.codEmpreendimento}/obras/${acesso.codObra}/orcamentos/${acesso.codOrcamento}/abc`)
    } else {
      this.props.history.push(`/empreendimentos/${acesso.codEmpreendimento}/obras/${acesso.codObra}/orcamentos/${acesso.codOrcamento}/analisar`)
    }

  }

  render() {
    const {classes, acessos} = this.props

    return (
      acessos && <React.Fragment>
        <div className={classes.cabecalho}>
          <Typography variant='overline' gutterBottom>Acessos recentes</Typography>
        </div>
        <div>
          <div className={classes.cards} datacy-id="lstAcessosRecentes">
            {acessos.map((acesso, i) => (
              <Paper className={classes.cardAcessosRecentes} key={i} onClick={() => this.analisarOrcamento(acesso)} elevation={0} variant='outlined'>
                <div className={classes.cardContent}>
                  <Typography variant='caption' gutterBottom>{acesso.nomeEmpreendimento}</Typography>
                  <Box display='flex' justifyContent='space-between'>
                    <Typography variant='body2' gutterBottom>{acesso.nomeObra}</Typography>
                    <Typography variant={'caption'}>{acesso.siglaUnidade}</Typography>
                  </Box>
                  <span className={classes.itemDestaque} />
                  
                  <Typography variant='subtitle2' gutterBottom>
                    {acesso.descricaoOrcamento.length > 120
                      ? `${acesso.descricaoOrcamento.substring(0, 120)}...`
                      : acesso.descricaoOrcamento
                    }
                  </Typography>
                  <Typography variant={'caption'}>
                    Acessado em {formatarData(acesso.dataHora, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY')} às {formatarData(acesso.dataHora, 'YYYY-MM-DD HH:mm:ss', 'HH:mm')}
                  </Typography>
                </div>
              </Paper>
            ))}
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(withStyles(styles)(CardAcessosRecentes))
