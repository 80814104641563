import React, { Component } from 'react'
import TableCell from '@material-ui/core/TableCell'
import { Button, Typography } from '@material-ui/core'
import MesmaLinha from '../../../comum/MesmaLinha'
import TableRow from '@material-ui/core/TableRow'
import PropTypes from 'prop-types'
import { formatarNumeroParaMoedaBR, mapStateToPropsContexto, seStringVazia, showSuccessMsg } from '../../../../js/util'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import _ from 'lodash'
import { connect } from 'react-redux'
import { context_httppatch, context_httppost } from '../../../../js/httpRequest'

class LinhaTratamentoPossiveisErros extends Component {
  static propTypes = {
    possivelErro: PropTypes.object.isRequired,
    tipo: PropTypes.object.isRequired,
    atualizar: PropTypes.func.isRequired,
    contexto: PropTypes.object,
    encerrado: PropTypes.bool.isRequired,
    personalizar: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      codServico: '',
      codServicoEmpty: false,
      descServico: '',
      descServicoEmpty: false,
      unidade: '',
      unidadeEmpty: false,
      precoUnitario: '',
      precoUnitarioEmpty: false
    }
    this.handleCampoTextoChange = this.handleCampoTextoChange.bind(this)
    this.handleAgruparClick = this.handleAgruparClick.bind(this)
    this.handleIgnorarClick = this.handleIgnorarClick.bind(this)
  }

  handleCampoTextoChange (event, campo) {
    const state = {}
    event.target.value === 'Personalizar'? this.props.personalizar(true, this.props.possivelErro, campo):
      this.props.personalizar(false, this.props.possivelErro)
    if(event.target.value === 'Personalizar'){
      this.setState({descServico:'',codServico:'',unidade:'', precoUnitario:''})
    }else{
      state[campo] = event.target.value
      state[`${campo}Empty`] = seStringVazia(event.target.value)
      this.setState(state)
    }

  }

  async handleAgruparClick () {
    const { possivelErro, tipo, contexto, atualizar } = this.props

    const codServico = this.recuperaCodServico(possivelErro)

    const descServico =
      possivelErro.possiveisDescricoes.length === 1
        ? possivelErro.possiveisDescricoes[0].descServico
        : this.state.descServico

    const unidade =
      possivelErro.possiveisUnidades.length === 1
        ? possivelErro.possiveisUnidades[0].unidade
        : this.state.unidade

    const precoUnitario =
      possivelErro.possiveisPrecosUnitarios.length === 1
        ? possivelErro.possiveisPrecosUnitarios[0].precoUnitarioFormatado
        : this.state.precoUnitario

    const codServicoEmpty = tipo.atributosAgrupamento.includes('codServico') && seStringVazia(codServico)
    const descServicoEmpty = tipo.atributosAgrupamento.includes('descServico') && seStringVazia(descServico)
    const unidadeEmpty = tipo.atributosAgrupamento.includes('unidade') && seStringVazia(unidade)
    const precoUnitarioEmpty = tipo.atributosAgrupamento.includes('precoUnitario') && (_.isNil(precoUnitario) || _.isEmpty(precoUnitario.toString()))

    if([codServicoEmpty, descServicoEmpty, unidadeEmpty, precoUnitarioEmpty].some(Boolean)) {
      this.setState({
        codServicoEmpty,
        descServicoEmpty,
        unidadeEmpty,
        precoUnitarioEmpty
      })
    } else {
      const agrupamento = {
        possivelErro: possivelErro.possivelErro,
        dados: {
          codServico,
          descServico,
          unidade,
          precoUnitario
        }
      }
      const dados = await context_httppost('possiveis-erros/agrupamento', contexto, agrupamento)
      if (dados) {
        atualizar(dados)
        showSuccessMsg('Itens agrupados com sucesso.')
      }
    }
  }

  recuperaCodServico (possivelErro) {
    const codServico =
      possivelErro.possiveisCodigos.length === 1
        ? possivelErro.possiveisCodigos[0].codServico
        : this.state.codServico
    return codServico
  }

  async handleIgnorarClick () {
    const { possivelErro, contexto, atualizar } = this.props
    const dados = await context_httppatch(`/possiveis-erros/${possivelErro.possivelErro.cod}`, contexto, { ignorar: true })
    if (dados) {
      atualizar(dados)
      showSuccessMsg('Ocorrência ignorada.')
    }
  }

  exibirCodigo () {
    const possivelErroTratamento = this.props.possivelErro
    if (possivelErroTratamento.possiveisCodigos.length > 1) {
      return (
        <FormControl variant='outlined' fullWidth>
          <Typography variant='subtitle1'>Selecione o código</Typography>
          <TextField
            select
            variant='outlined'
            value={this.state.codServico}
            error={this.state.codServicoEmpty}
            onChange={(e) => this.handleCampoTextoChange(e, 'codServico')}
          >
            {
              possivelErroTratamento.possiveisCodigos.length > 2?
                <MenuItem key={''} value={'Personalizar'}>{'Personalizar'}</MenuItem> :''
            }
            {
              possivelErroTratamento.possiveisCodigos.map((it) => (
                <MenuItem key={it.codServico} value={it.codServico}>
                  {
                    `${_.isNil(it.codServico) ? 'Não informado ' : it.codServico} (${it.quantidadeItens} ocorrências)`
                  }
                </MenuItem>
              ))
            }
          </TextField>
        </FormControl>
      )
    }
    const codServico = possivelErroTratamento.possiveisCodigos.length === 1
      ? possivelErroTratamento.possiveisCodigos[0].codServico
      : possivelErroTratamento.possivelErro.codServico
    return (
      <Typography>
        {
          seStringVazia(codServico) ? 'Não informado' : codServico
        }
      </Typography>
    )
  }

  exibirDescricao () {
    const possivelErroTratamento = this.props.possivelErro
    if (possivelErroTratamento.possiveisDescricoes.length > 1) {
      return (
        <FormControl variant='outlined' fullWidth style={{maxWidth: 500}}>
          <Typography variant='subtitle1'>Selecione a descrição</Typography>
          <TextField
            select
            variant='outlined'
            value={this.state.descServico}
            error={this.state.descServicoEmpty}
            onChange={(e) => this.handleCampoTextoChange(e, 'descServico')}
          >
            {
              possivelErroTratamento.possiveisDescricoes.length > 1?
                <MenuItem key={''} value={'Personalizar'}>{'Personalizar'}</MenuItem> :''
            }
            {
              possivelErroTratamento.possiveisDescricoes.map((it) => (
                <MenuItem key={it.descServico} value={it.descServico} title={it.descServico}>
                  {_.isNil(it.quantidadeItens) ? it.descServico : `${it.descServico.trim().length > 80?
                    it.descServico.trim().substr(0,80).concat(' ...'):
                    it.descServico.trim()} (${it.quantidadeItens} ocorrências)`}
                </MenuItem>
              ))
            }
          </TextField>
        </FormControl>
      )
    }
    return (
      <Typography>
        {
          possivelErroTratamento.possiveisDescricoes.length > 0
            ? possivelErroTratamento.possiveisDescricoes[0].descServico
            : possivelErroTratamento.possivelErro.descServico
        }
      </Typography>
    )
  }

  exibirUnidade () {
    const possivelErroTratamento = this.props.possivelErro
    if (possivelErroTratamento.possiveisUnidades.length > 1) {
      return (
        <FormControl variant='outlined' fullWidth>
          <Typography variant='subtitle1'>Selecione a unidade</Typography>
          <TextField
            select
            value={this.state.unidade}
            error={this.state.unidadeEmpty}
            onChange={(e) => this.handleCampoTextoChange(e, 'unidade')}
          >
            {
              possivelErroTratamento.possiveisUnidades.length > 2?
                <MenuItem key={''} value={'Personalizar'}>{'Personalizar'}</MenuItem> :''
            }
            {
              possivelErroTratamento.possiveisUnidades.map((it) => (
                <MenuItem key={it.unidade} value={it.unidade}>
                  {_.isNil(it.quantidadeItens) ? it.unidade : `${it.unidade} (${it.quantidadeItens} ocorrências)`}
                </MenuItem>
              ))
            }
          </TextField>
        </FormControl>
      )
    }
    return (
      <Typography>
        {
          possivelErroTratamento.possiveisUnidades.length > 0
            ? possivelErroTratamento.possiveisUnidades[0].unidade
            : possivelErroTratamento.possivelErro.unidade
        }
      </Typography>
    )
  }

  exibirPrecoUnitario () {
    const possivelErroTratamento = this.props.possivelErro
    if (possivelErroTratamento.possiveisPrecosUnitarios.length > 1) {
      return (
        <FormControl variant='outlined' fullWidth>
          <Typography variant='subtitle1'>Selecione o preço</Typography>
          <TextField
            select
            variant='outlined'
            value={this.state.precoUnitario}
            error={this.state.precoUnitarioEmpty}
            onChange={(e) => this.handleCampoTextoChange(e, 'precoUnitario')} >
            {
              possivelErroTratamento.possiveisPrecosUnitarios.length > 2 ?
                <MenuItem key={''} value={'Personalizar'}>{'Personalizar'}</MenuItem> : ''
            }
            {
              possivelErroTratamento.possiveisPrecosUnitarios.map((it) => (
                <MenuItem key={it.valorPrecoUnitario} value={it.precoUnitarioFormatado}>
                  {_.isNil(it.quantidadeItens) ? it.precoUnitarioFormatado : `${it.precoUnitarioFormatado} (${it.quantidadeItens} ocorrências)`}
                </MenuItem>
              ))
            }
          </TextField>
        </FormControl>
      )
    }
    return (
      <Typography>
        {
          possivelErroTratamento.possiveisPrecosUnitarios.length > 0
            ? possivelErroTratamento.possiveisPrecosUnitarios[0].precoUnitarioFormatado
            : possivelErroTratamento.possivelErro.precoUnitarioFormatado
        }
      </Typography>
    )
  }

  render () {
    const { possivelErro, encerrado } = this.props
    return (
      <TableRow>
        <TableCell>
          {this.exibirCodigo()}
        </TableCell>
        <TableCell>
          {this.exibirDescricao()}
        </TableCell>
        <TableCell>
          {this.exibirUnidade()}
        </TableCell>
        <TableCell>
          {this.exibirPrecoUnitario()}
        </TableCell>
        <TableCell>
          <Typography>{formatarNumeroParaMoedaBR(possivelErro.possivelErro.percentual, 2, 5)}%</Typography>
        </TableCell>
        <TableCell>
          <MesmaLinha>
            <Button
              variant='contained'
              color='primary'
              onClick={this.handleAgruparClick}
              style={{ marginRight: 16 }}
              disabled={encerrado}
            >
              Agrupar
            </Button>
            {
              0 === 1 && (
                <Button className='btnIgnorarOcor' color='primary' onClick={this.handleIgnorarClick}>
                  Ignorar
                </Button>
              )
            }
          </MesmaLinha>
        </TableCell>
      </TableRow>
    )
  }
}

export default connect(mapStateToPropsContexto)(LinhaTratamentoPossiveisErros)
