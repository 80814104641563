import React from 'react'
import PropTypes from 'prop-types'
import IconeObservacao from './observacao/IconeObservacao'

const CelulaObservacoes = ({
  exibir,
  item,
  open,
  setOpen,
  onItemAlterado,
  abrirModal,
}) => {
  return exibir && (
    <IconeObservacao
      item={item}
      open={open}
      setOpen={setOpen}
      onItemAlterado={onItemAlterado}
      abrirModal={abrirModal}
    />
  )
}

CelulaObservacoes.propTypes = {
  exibir: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onItemAlterado: PropTypes.func.isRequired,
  item: PropTypes.object,
  abrirModal: PropTypes.func.isRequired,
}

export default CelulaObservacoes
