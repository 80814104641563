import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl, Typography } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import SaveIcon from '@material-ui/icons/Save'
import { connect } from 'react-redux'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../../../styles'
import { context_httppost, context_httpput } from '../../../../../../../js/httpRequest'
import { seStringVazia, showSuccessMsg } from '../../../../../../../js/util'
import TituloVoltar from '../../../../../../comum/TituloVoltar'
import _ from 'lodash'

const DadosBasicosFormula = ({ classes, contexto, formulaAtiva, onFormulaChange, onFecharDrawer }) => {
  // eslint-disable-next-line no-unused-vars
  const [codigo, setCodigo] = useState(null)
  const [nome, setNome] = useState('')
  const [nomeEmpty, setNomeEmpty] = useState(false)
  const [descricao, setDescricao] = useState('')
  const [descricaoEmpty, setDescricaoEmpty] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [codOrcamento, setCodOrcamento] = useState(null)
  const [isEdicaoDadosBasicosReadOnly, setIsEdicaoDadosBasicosReadOnly] = useState(false)

  useEffect(() => {
    formulaAtiva && copiarDadosFormulaParaEstado()
  }, [])

  const copiarDadosFormulaParaEstado = () => {
    if (formulaAtiva) {
      setCodigo(formulaAtiva.codigo)
      setNome(formulaAtiva.nome)
      setDescricao(formulaAtiva.descricao)
      setCodOrcamento(formulaAtiva.codOrcamento)
      setIsEdicaoDadosBasicosReadOnly(true)
    }
  }

  const validarFormulario = () => {
    const nomeEmpty = seStringVazia(nome)
    const descricaoEmpty = seStringVazia(descricao)
    if (nomeEmpty || descricaoEmpty) {
      setNomeEmpty(nomeEmpty)
      setDescricaoEmpty(descricaoEmpty)
      throw 'Campos obrigatórios não preenchidos'
    }
  }

  const handleSalvarFormula = async () => {
    validarFormulario()
    const formula = { nome, descricao }
    const response = _.isNil(formulaAtiva)
      ? await context_httppost('analise-conteudo/formulas', contexto, formula)
      : await context_httpput(`analise-conteudo/formulas/${formulaAtiva.codigo}`, contexto, formula)
    if (response) {
      showSuccessMsg('Dados salvos')
      setIsEdicaoDadosBasicosReadOnly(true)
      onFormulaChange(response)
    }
  }

  return (
    <div className={classes.dadosBasicos}>
      <React.Fragment>
        <TituloVoltar onClick={onFecharDrawer} label='Fórmula' />
        {(isEdicaoDadosBasicosReadOnly && !_.isNil(formulaAtiva)) &&
          <Box mt={2}>
            <Typography variant='h6'>
              {nome}
            </Typography>
            <Typography variant='subtitle1'>
              {descricao}
            </Typography>
            <div>
              <Button
                variant='outlined'
                color='primary'
                onClick={() => setIsEdicaoDadosBasicosReadOnly(false)}
              >
                Editar
              </Button>
            </div>
          </Box>
        }
        {!isEdicaoDadosBasicosReadOnly &&
          <React.Fragment>
            <FormControl variant='outlined' fullWidth>
              <Typography variant='subtitle1'>
                Nome
              </Typography>
              <TextField
                name='nome'
                value={nome}
                error={nomeEmpty}
                onChange={(e) => setNome(e.target.value)}
                variant='outlined'
              />
            </FormControl>
            <Box mt={2}>
              <FormControl variant='outlined' fullWidth>
                <Typography variant='subtitle1'>Descrição</Typography>
                <TextField
                  name='descricao'
                  multiline
                  rows={3}
                  value={descricao}
                  error={descricaoEmpty}
                  onChange={(e) => setDescricao(e.target.value)}
                  variant='outlined'
                />
              </FormControl>
            </Box>
          </React.Fragment>
        }
        {!isEdicaoDadosBasicosReadOnly &&
          <Box mt={3}>
            <Button variant='outlined' color='default' size='large' onClick={() => handleSalvarFormula()}>
              <SaveIcon fontSize='small' style={{ marginRight: 8 }} /> Salvar Fórmula
            </Button>
          </Box>
        }
      </React.Fragment>
    </div>
  )
}

DadosBasicosFormula.propTypes = {
  classes: PropTypes.object,
  contexto: PropTypes.object,
  formulaAtiva: PropTypes.object,
  onFormulaChange: PropTypes.func.isRequired,
  onFecharDrawer: PropTypes.func.isRequired
}

export const mapStateToProps = ({ contexto }) => ({ contexto })
export default connect(mapStateToProps)(withStyles(styles)(DadosBasicosFormula))
