import React, { useEffect, useState } from 'react'
import Template from '../../../layout/Template'
import { Box, Divider, IconButton, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import buscarTiposUnidades from '../../../../actions/temas/buscarTiposUnidades'
import { seStringVazia, showSuccessMsg } from '../../../../js/util'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import StyledSwitch from '../../../comum/StyledSwitch'
import PaperBox from '../../../comum/PaperBox'
import styles from './styles'
import StyledFormControl from '../../../comum/StyledFormControl'
import CampoTipoObra from './CampoTipoObra'
import Button from '@material-ui/core/Button'
import SaveIcon from '@material-ui/icons/Save'
import { context_httpdelete, context_httpget, context_httppost } from '../../../../js/httpRequest'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import StyledDrawer from '../../../comum/StyledDrawer'
import { atualizarListaExpressoesVinculadas, atualizarListaFormulasVinculadas } from '../analise-conteudo/analise-conteudo'
import VincularExpressao from './vincular-expressao/VincularExpressao'
import ExpressoesVinculadas from './vincular-expressao/ExpressoesVinculadas'
import FormulasVinculadas from './vincular-formula/FormulasVinculadas'
import VincularFormula from './vincular-formula/VincularFormula'
import Alert from '@material-ui/lab/Alert'
import Link from '@material-ui/core/Link'
import _ from 'lodash'
import TipologiasCombinadasVinculadas from './vincular-tipologias-combinadas/TipologiasCombinadasVinculadas'
import VincularTipologiaCombinada from './vincular-tipologias-combinadas/VincularTipologiaCombinada'

const Tema = ({
  classes,
  contexto,
  match,
  history,
  buscarTiposUnidades,
  unidadeUsuarioLogado
}) => {
  const [possuiCurvaAbc, setPossuiCurvaAbc] = useState(false)
  const [codigoTema, setCodigoTema] = useState(null)
  const [nome, setNome] = useState('')
  const [nomeEmpty, setNomeEmpty] = useState(false)
  const [descricao, setDescricao] = useState('')
  const [descricaoEmpty, setDescricaoEmpty] = useState(false)
  const [unidade, setUnidade] = useState(null)
  const [disponivel, setDisponivel] = useState(false)
  const [tipoObras, setTipoObras] = useState([])
  const [expressoes, setExpressoes] = useState([])
  const [tipologiasCombinadas, setTipologiasCombinadas] = useState([])
  const [formulas, setFormulas] = useState([])
  const [openDrawerAssociarExpressao, setOpenDrawerAssociarExpressao] = useState(false)
  const [openDrawerAssociarFormula, setOpenDrawerAssociarFormula] = useState(false)
  const [openDrawerAssociarTipologiaCombinada, setOpenDrawerAssociarTipologiaCombinada] = useState(false)

  useEffect(() => {
    buscarTiposUnidades()
  }, [])

  useEffect(() => {
    const verificaSeOrcamentoPossuiCurvaABC = async () => {
      const { possuiCurvaAbc } = await context_httpget('possui-curva-abc', contexto)
      setPossuiCurvaAbc(possuiCurvaAbc)
    }

    contexto && verificaSeOrcamentoPossuiCurvaABC()
  }, [contexto])

  useEffect(() => {
    const { params } = match
    if (params.idTema) {
      contexto && copiarDadosParaEstado(params.idTema)
    }
  }, [possuiCurvaAbc])

  useEffect(() => {
    unidadeUsuarioLogado && setUnidade(unidadeUsuarioLogado)
  }, unidadeUsuarioLogado)

  const copiarDadosParaEstado = async (idTema) => {
    const data = await context_httpget(`analise-conteudo/temas/${idTema}`, contexto)
    if (data) {
      setCodigoTema(data.codigo)
      setNome(data.nome)
      setDescricao(data.descricao)
      setUnidade(data.unidade)
      setDisponivel(data.seDisponivel)
      setTipoObras(data.tipoObras)
      setExpressoes(data.expressoes)
      setFormulas(data.formulas)
      setTipologiasCombinadas(data.tipologiasCombinadas)
    }
  }
  const validarCamposObrigatorios = () => {
    const nomeEmpty = seStringVazia(nome)
    const descricaoEmpty = seStringVazia(descricao)
    if (nomeEmpty || descricaoEmpty) {
      setNomeEmpty(nomeEmpty)
      setDescricaoEmpty(descricaoEmpty)
      throw 'Campos obrigatórios não preenchidos'
    }
  }

  const handleSalvar = async () => {
    validarCamposObrigatorios()
    const tema = {
      codigo: codigoTema,
      nome,
      descricao,
      unidade,
      sePublico: true,
      seDisponivel: disponivel,
      tipoObras: tipoObras.map((it) => it.descricao)
    }
    const response = await context_httppost('analise-conteudo/temas', contexto, tema)
    if (response) {
      setCodigoTema(response.codigo)
      showSuccessMsg('Dados registrados')
    }
  }

  const handleVincularExpressoes = (expressoesData) => {
    !_.isEmpty(expressoesData) &&
      setExpressoes(expressoesData)
  }

  const handleVincularTipologiasCombinadas = (tipologiasCombinadasData) => {
    !_.isEmpty(tipologiasCombinadasData) &&
      setTipologiasCombinadas(tipologiasCombinadasData)
  }

  const handleVincularFormulas = (formulasData) => {
    !_.isEmpty(formulasData) &&
      setFormulas(formulasData)
  }

  const handleDesvincularExpressao = async (codExpressao) => {
    const result = await context_httpdelete(`analise-conteudo/temas/${codigoTema}/expressoes/${codExpressao}/desvincular`, contexto)
    if (result) {
      const novaLista = atualizarListaExpressoesVinculadas(expressoes, result)
      setExpressoes([...novaLista])
      showSuccessMsg('Expressão excluída')
    }
  }

  const handleDesvinculaFormula = async (codFormula) => {
    const result = await context_httpdelete(`analise-conteudo/temas/${codigoTema}/formulas/${codFormula}/desvincular`, contexto)
    if (result) {
      const formulasAtualizadas = atualizarListaFormulasVinculadas(formulas, result.codigosFormulas)
      const expressoesAtualizadas = atualizarListaExpressoesVinculadas(expressoes, result.codigosExpressoes)
      setFormulas([...formulasAtualizadas])
      setExpressoes([...expressoesAtualizadas])
      showSuccessMsg('Fórmula excluída')
    }
  }

  const handleDesvinculaTipologiaCombinada = async (codTipologiaCombinada) => {
    const result = await context_httpdelete(`analise-conteudo/temas/${codigoTema}/tipologias-combinadas/${codTipologiaCombinada}/desvincular`, contexto)
    if (result) {
      setTipologiasCombinadas(tipologiasCombinadas.filter(it => it.codigo !== codTipologiaCombinada))
      showSuccessMsg('Tipologia combinada excluída')
    }
  }

  const handleClickVoltar = () => {
    const { params } = match
    if (params.idTema) {
      history.push(match.url.replace(`gerenciar-temas/${params.idTema}`, 'gerenciar-temas'))
    } else {
      history.push(match.url.replace('/cadastrar', ''))
    }
  }

  const handleCurvaAbcClick = () => {
    const { params } = match
    if (params.idTema) {
      history.push(match.url.replace(`analise-conteudo/gerenciar-temas/${params.idTema}`, 'abc'))
    } else {
      history.push(match.url.replace('analise-conteudo/gerenciar-temas/cadastrar', 'abc'))
    }
  }

  return (
    <Template insidePaper={false}>
      {possuiCurvaAbc
        ? (<PaperBox elevation={0}>
          <Box display='flex' alignItems='center'>
            <IconButton onClick={handleClickVoltar}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant='h6' onClick={handleClickVoltar} className={classes.colorTitle}>
              Configuração de Tema
            </Typography>
          </Box>
          <Box my={2}>
            <Divider />
          </Box>
          <Box mt={2} display={'grid'}>
            <FormControlLabel
              className={classes.switchTema}
              control={
                <StyledSwitch
                  name='tema'
                  size='small'
                  checked={disponivel}
                  onChange={() => setDisponivel(!disponivel)}
                />
              }
              label='Habilitar tema para uso'
            />
            <Box mt={2}>
              <Typography variant='subtitle1'>Nome</Typography>
              <TextField
                name='nome'
                style={{ width: 600 }}
                value={nome}
                error={nomeEmpty}
                onChange={(e) => setNome(e.target.value)}
                variant='outlined'
              />
            </Box>
            <Box mt={2}>
              <Typography variant='subtitle1'>Descrição</Typography>
              <TextField
                style={{ width: 993 }}
                name={'descricao'}
                value={descricao}
                multiline
                rowsMax={5}
                error={descricaoEmpty}
                onChange={(e) => setDescricao(e.target.value)}
                variant='outlined'
              />
            </Box>

            <Box mt={2}>
              <StyledFormControl variant='outlined' fullWidth className={classes.transferList}>
                <CampoTipoObra
                  classes={classes}
                  onTipoObrasSelecionados={setTipoObras}
                  tipoObrasEdicao={tipoObras}
                />
              </StyledFormControl>
            </Box>

            { codigoTema && (
              <Box>
                <ExpressoesVinculadas
                  classes={classes}
                  vincularExpressaoClick={() => setOpenDrawerAssociarExpressao(!openDrawerAssociarExpressao)}
                  onDesvincularExpressao={handleDesvincularExpressao}
                  expressoes={expressoes}
                />
                <FormulasVinculadas
                  classes={classes}
                  onVincularFormula={() => setOpenDrawerAssociarFormula(!openDrawerAssociarFormula)}
                  onDesvinculaFormula={handleDesvinculaFormula}
                  formulas={formulas}
                />
                <TipologiasCombinadasVinculadas
                  classes={classes}
                  onVincular={() => setOpenDrawerAssociarTipologiaCombinada(!openDrawerAssociarTipologiaCombinada)}
                  onDesvincular={handleDesvinculaTipologiaCombinada}
                  tipologiasCombinadas={tipologiasCombinadas}
                />
              </Box>)
            }

            <Box mt={2} mb={2}>
              <Button variant='contained' color='primary' onClick={handleSalvar}>
                <SaveIcon fontSize='small' style={{ marginRight: 4 }} /> Salvar Tema
              </Button>
            </Box>
          </Box>

          <StyledDrawer
            classes={{ paper: classes.paperDrawer }}
            open={openDrawerAssociarExpressao}
            anchor='right'
            onClose={() => setOpenDrawerAssociarExpressao(!openDrawerAssociarExpressao)}
          >
            <VincularExpressao
              classes={classes}
              codigoTema={codigoTema}
              expressoesVinculadas={expressoes}
              onVincularExpressoesAoTema={handleVincularExpressoes}
              onFecharDrawer={() => setOpenDrawerAssociarExpressao(!openDrawerAssociarExpressao)}
            />
          </StyledDrawer>

          <StyledDrawer
            classes={{ paper: classes.paperDrawer }}
            open={openDrawerAssociarFormula}
            anchor='right'
            onClose={() => setOpenDrawerAssociarFormula(!openDrawerAssociarFormula)}
          >
            <VincularFormula
              classes={classes}
              codigoTema={codigoTema}
              formulasVinculadas={formulas}
              onVincularFormulasAoTema={handleVincularFormulas}
              onVincularExpressoesAoTema={handleVincularExpressoes}
              onFecharDrawer={() => setOpenDrawerAssociarFormula(!openDrawerAssociarFormula)}
            />
          </StyledDrawer>

          <StyledDrawer
            classes={{ paper: classes.paperDrawer }}
            open={openDrawerAssociarTipologiaCombinada}
            anchor='right'
            onClose={() => setOpenDrawerAssociarTipologiaCombinada(!openDrawerAssociarTipologiaCombinada)}
          >
            <VincularTipologiaCombinada
              classes={classes}
              codigoTema={codigoTema}
              tipologiasVinculadas={tipologiasCombinadas}
              onVincularFormulasAoTema={handleVincularFormulas}
              onVincularExpressoesAoTema={handleVincularExpressoes}
              onVincularTipologiasCombinadas={handleVincularTipologiasCombinadas}
              onFecharDrawer={() => setOpenDrawerAssociarTipologiaCombinada(!openDrawerAssociarTipologiaCombinada)}
            />
          </StyledDrawer>
        </PaperBox>)
        : (
          <Alert severity='warning'>
            Só é possível visualizar Análise de Conteúdo do Orçamento após <Link onClick={handleCurvaAbcClick}>criar a Curva ABC</Link>.
          </Alert>
        )
      }
    </Template>
  )
}

Tema.propTypes = {
  classes: PropTypes.object,
  contexto: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  buscarTiposUnidades: PropTypes.func,
  unidadeUsuarioLogado: PropTypes.object
}

const mapStateToProps = (state) => ({
  unidadeUsuarioLogado: state.tiposUnidade.unidadeUsuarioLogado,
  contexto: state.contexto
})

export default connect(mapStateToProps, { buscarTiposUnidades })(withStyles(styles)(Tema))
