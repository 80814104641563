import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, InputAdornment, Paper, TextField, Typography } from '@material-ui/core'
import TabelaObras from './TabelaObras'
import buscarObras from '../../actions/obras/buscarObras'
import SearchIcon from '@material-ui/icons/Search'
import { useDispatch, useSelector } from 'react-redux'
import ModalEditarAdicionarObra from './ModalEditarAdicionarObra'
import { useLocation } from 'react-router-dom'
import useStyles from './styles'

const ListaObras = ({ handleDrawer, podeCadastrarObra }) => {
  const classes = useStyles()
  const { state } = useLocation()
  const dispatch = useDispatch()
  const obras = useSelector((state) => state.obras)

  const [inputPesquisa, setInputPesquisa] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [obraSelecionada, setObraSelecionada] = useState(null)

  useEffect(() => {
    state && dispatch(buscarObras(state.cod, true))
  }, [state])

  const list = obras.filter((obra) => inputPesquisa === '' || obra.nome.toLowerCase().includes(inputPesquisa))

  const handleSelecionarObraEdicao = (obra) => {
    setObraSelecionada(obra)
    setOpenDialog(true)
  }

  const handleCadastrar = () => {
    setOpenDialog(true)
    setObraSelecionada(null)
  }

  return (
    <Box className={handleDrawer ? classes.root : classes.withMiniDrawer}>
      <Box mt={3} className={classes.titleAddObraBox}>
        <Typography variant="h6">Obras</Typography>
        <Button
          variant="contained"
          color="primary"
          size="small"
          disabled={!podeCadastrarObra}
          onClick={handleCadastrar}
        >
          Cadastrar obra
        </Button>
      </Box>
      <Paper elevation={0} variant="outlined" className={classes.paper}>
        <Typography variant="subtitle2">{state?.nome}</Typography>
        {obras.length > 0 ? (
          <>
            <Box mb={2}>
              <TextField
                className={classes.input}
                variant="outlined"
                placeholder="Procurar uma obra"
                onChange={(e) => setInputPesquisa(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <TabelaObras
              obras={list}
              onSelecionarObraEdicao={handleSelecionarObraEdicao}
            />
          </>
        ) : null}
      </Paper>

      {openDialog &&
        <ModalEditarAdicionarObra
          openDialog={openDialog}
          onCancelar={() => setOpenDialog(false)}
          empreendimento={state}
          obra={obraSelecionada}
          onOpenDialog={setOpenDialog}
        />}
    </Box>
  )
}

ListaObras.propTypes = {
  handleDrawer: PropTypes.bool,
  podeCadastrarObra: PropTypes.bool,
}

export default ListaObras
