import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Alert } from '@material-ui/lab'

const useStyles = makeStyles(() => ({
  root: {
    width: 500
  }
}))

export default function DescriptionAlerts () {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Alert severity='info'>
        Dica: Use <strong>* (asterisco)</strong> para corresponder a zero ou mais caracteres sem espaço. Por exemplo, tint<strong>*</strong> irá corresponder a qualquer palavra que começa com tint,tais como tinta, tintas e assim por diante.
      </Alert>
    </div>
  )
}
