import React from 'react'
import TextField from '@material-ui/core/TextField'
import { formatarMoedaBR } from '../../js/util'
import PropTypes from 'prop-types'

const CampoMoeda = ({
  name, label, value, error, maxLength, autoFocus, fullWidth,
  margin, variant, onChange, onKeyPress, InputProps, onFocus
}) => {
  const handleChange = (event) => {
    const e = { ...event }
    e.target.value = formatarMoedaBR(event.target.value)
    if (onChange) onChange(e)
  }

  return (
    <TextField
      name={name}
      label={label}
      value={formatarMoedaBR(value)}
      error={error}
      autoFocus={autoFocus}
      fullWidth={fullWidth}
      margin={margin}
      variant={variant}
      onChange={(e) => handleChange(e)}
      onKeyPress={onKeyPress}
      inputProps={{
        maxLength
      }}
      InputProps={InputProps}
      onFocus={onFocus}
    />
  )
}

CampoMoeda.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.bool,
  maxLength: PropTypes.number,
  autoFocus: PropTypes.any,
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  variant: PropTypes.any,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  InputProps: PropTypes.object
}

export default CampoMoeda
