const styles = () => ({
  row: {
    display: 'flex',
    width: '100%',
    padding: '4px',
    alignItems: 'center'
  },
  colSm1: {
    width: '10%',
    minWidth: 110
  },
  colSm2: {
    width: '20%'
  },
  colSm4: {
    width: '40%'
  },
  colSm10: {
    width: '80%'
  },
  estiloTextoNumeroOcorrencias: {
    color: '#000',
    opacity: 0.8,
    textAlign: 'center'
  },
  paddingErros: {
    padding: '1.48rem 0'
  },
  subtituloAcao: {

  },
  btnConcluir: {
    marginTop: 16,
  },
  paperIgnorados: {
    backgroundColor: 'rgba(255, 203, 131, 0.4)',
    borderColor: '#ffcb83',
    borderRadius: 0,
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
    height: 90
  },
  paperOcorrencia: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    backgroundColor: '#f6f7f8'
  }
})

export default styles
