import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import TituloVoltar from '../../../../../../../comum/TituloVoltar'
import AdicionarAtividadeAuxiliar from './AdicionarAtividadeAuxiliar'
import AdicionarComposicaoOrcamento from './AdicionarComposicaoOrcamento'
import PesquisarComposicaoAuxiliarReferenciaPreco from '../PesquisarComposicaoAuxiliarReferenciaPreco'
import { mapStateToPropsContexto, showSuccessMsg } from '../../../../../../../../js/util'
import { context_httppost } from '../../../../../../../../js/httpRequest'
import { connect } from 'react-redux'

const ESTE_ORCAMENTO = 0
const CRIAR = 1
const REFERENCIA_PRECO = 2

class AdicionarComposicaoAuxiliar extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string,
    onDadosAlterados: PropTypes.func,
    onCancelar: PropTypes.func.isRequired,
    contexto: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      modo: 2
    }
  }

  handleVincularComposicao = (data) => {
    const { sistema, uf, mes, ano, codigo, fit } = data
    const { codigoComposicao, contexto, onDadosAlterados } = this.props

    let url = `precos/${sistema}/${uf}/${mes}/${ano}/vincular-referencia-preco-atividade-auxiliar?composicao=${codigoComposicao}&atividade-auxiliar=${codigo}`;

    if (sistema === 'SICRO') {
      url += `&fit=${fit}`;
    }

    context_httppost(url, contexto)
      .then((composicaoComDetalhes) => {
        showSuccessMsg(`Atividade auxiliar ${codigo} vinculada à composição.`)
        onDadosAlterados(composicaoComDetalhes)
      })
      
  }

  render() {
    const {
      codigoComposicao,
      onDadosAlterados,
      onCancelar
    } = this.props

    const { modo } = this.state

    return (
      <>
        <TituloVoltar onClick={onCancelar} label='Vincular atividade auxiliar na composição'/>
        <Box my={2}>
          <TextField variant='outlined' value={modo} select onChange={(e) => this.setState({ modo: e.target.value })}>
            <MenuItem value={REFERENCIA_PRECO}>Pesquisar em referências de preço</MenuItem>
            <MenuItem value={ESTE_ORCAMENTO}>Composições deste orçamento</MenuItem>
            <MenuItem value={CRIAR}>Criar nova composição</MenuItem>
          </TextField>
        </Box>
        {
          modo === CRIAR && (
            <AdicionarAtividadeAuxiliar
              codigoComposicao={codigoComposicao}
              onCancelar={onCancelar}
              onDadosAlterados={onDadosAlterados}
            />
          )
        }
        {
          modo === ESTE_ORCAMENTO && (
            <AdicionarComposicaoOrcamento
              codigoComposicao={codigoComposicao}
              onCancelar={onCancelar}
              onDadosAlterados={onDadosAlterados}
            />
          )
        }
        {
          modo === REFERENCIA_PRECO && (
            <PesquisarComposicaoAuxiliarReferenciaPreco
              onCancelar={onCancelar}
              codigoComposicao={codigoComposicao}
              isPesquisaComposicaoEmTempoFixo={false}
              onVincularComposicao={this.handleVincularComposicao}
            />
          )
        }
      </>
    )
  }
}

export default connect(mapStateToPropsContexto)(AdicionarComposicaoAuxiliar)
