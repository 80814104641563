import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import { colunasDatagridRisco2, rowsDatagridRisco2 } from './analise-risco'
import AnaliseRiscoAjuda from './AnaliseRiscoAjuda'
import StyledDataGrid from '../comum/StyledDataGrid'

const AnaliseRiscoRelatorio = ({ orcamentos, riscos  }) => {
  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)


  return (
    <Box pb={2} mt={3}>
      <Box display='flex' justify='space-between'>
        <Typography variant='overline' gutterBottom>
          Relatório de Risco
        </Typography>
        <AnaliseRiscoAjuda />
      </Box>
      <div style={{ height: 600, width: '100%' }}>
        <StyledDataGrid
          rows={rowsDatagridRisco2(orcamentos, riscos)}
          columns={colunasDatagridRisco2}
          pagination
          disableSelectionOnClick
          disableColumnFilter
          disableColumnMenu
          disableColumnSelector
          page={page}
          pageSize={pageSize}
          headerHeight={75}
          rowHeight={65}
          scrollbarSize={30}
          rowsPerPageOptions={[10, 15, 20]}
          rowCount={riscos.length}
          onPageChange={(newPage) => setPage(newPage)}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        />
      </div>
    </Box>
  )
}

AnaliseRiscoRelatorio.propTypes = {
  orcamentos: PropTypes.array,
  riscos: PropTypes.array
}

export default AnaliseRiscoRelatorio
