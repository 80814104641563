import React from 'react'
import PropTypes from 'prop-types'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Box, Typography } from '@material-ui/core'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import Button from '@material-ui/core/Button'
import _ from 'lodash'
import StyledAccordionSumary from '../../../../../../comum/StyledAccordionSumary'
import StyledAccordion from '../../../../../../comum/StyledAccordion'
import { formatarNumeroParaMoedaBR } from '../../../../../../../js/util'

const ResultadoFormula = ({
  classes,
  value,
  formula,
  possuiParametros,
  exibirOpcaoCriarTipologia,
  onCriarTipologia
}) => {
  const criarTipologia = (event) => {
    event.stopPropagation()
    onCriarTipologia(true)
  }
  return (
    <Box mt={3} className={classes.divResultadosFormulas}>
      <StyledAccordion defaultExpanded>
        <StyledAccordionSumary expandIcon={<ExpandMoreIcon />}>
          <div className={classes.tituloResultados}>
            <Typography variant='subtitle2'>Resultado</Typography>
            { exibirOpcaoCriarTipologia &&
              <Button onClick={(e) => criarTipologia(e)} color='primary' variant='contained'>
                { formula.tipologia ? 'Ver tipologia' : 'Criar tipologia' }
                <ArrowRightAltIcon fontSize='small' />
              </Button>
            }
          </div>
        </StyledAccordionSumary>
        <AccordionDetails className={classes.resultadosFormulas}>
          {[value].map((item) => (
            <div className={classes.cardFormulas} key={formula.codigo}>
              <div className={classes.colunaFormula}>
                {_.isEmpty(value.error) ? (
                  <Typography variant='h6'>
                    {formatarNumeroParaMoedaBR(item.valor, 2, 4)}
                  </Typography>
                ) : <Typography variant='h6'>-</Typography>}
              </div>
              <div className={classes.linhasTexto}>
                <div className={classes.linhaTitulo}>
                  <Typography variant='subtitle1' color='primary'>Expressão da Fórmula</Typography>
                </div>
                {possuiParametros ? (
                  <Typography variant='caption'>
                    {item.formulaGerada}
                  </Typography>
                ) : (
                  <Typography variant='caption'>
                    A fórmula ainda não possui parâmetros cadastrados.
                  </Typography>
                )}
              </div>
            </div>
          ))}
        </AccordionDetails>
      </StyledAccordion>
    </Box>
  )
}

ResultadoFormula.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.object,
  formula: PropTypes.object,
  possuiParametros: PropTypes.bool,
  exibirOpcaoCriarTipologia: PropTypes.bool,
  onCriarTipologia: PropTypes.func
}
export default ResultadoFormula
