import React from 'react'
import { Box, withStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import PropsTypes from 'prop-types'
import styles from '../../../styles'

const FormulaInvalida = ({ classes, mensagem }) =>
  <Box className={classes.formulaError}>
    <div style={{ width: '99%' }}>
      <Alert severity='error'>{mensagem}</Alert>
    </div>
  </Box>

FormulaInvalida.propTypes = {
  classes: PropsTypes.object,
  mensagem: PropsTypes.string.isRequired
}

export default withStyles(styles)(FormulaInvalida)
