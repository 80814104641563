import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import PropTypes from 'prop-types'

import CardEmpreendimento from './CardEmpreendimento'
import Template from '../layout/Template'
import ListaObras from '../obras/ListaObras'

import { connect } from 'react-redux'
import AdicionarEmpreendimento from './AdicionarEmpreendimento'
import buscarEmpreendimentos from '../../actions/empreendimentos/buscarEmpreendimentos'
import cadastrarEmpreendimento from '../../actions/empreendimentos/cadastrarEmpreendimento'
import excluirEmpreendimento from '../../actions/empreendimentos/excluirEmpreendimento'
import alterarEmpreendimento from '../../actions/empreendimentos/alterarEmpreendimento'
import { Box, Divider, Drawer, IconButton, makeStyles } from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CustomScroller from 'react-custom-scroller'
import { replaceSpecialChars } from '../../js/util'
import _ from 'lodash'

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 380,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  miniDrawer: {
    width: 40,
    display: 'flex',
    alignItems: 'center',
    padding: 0,
  },
  gridSidebar: {
    height: '78vh',
    marginLeft: 17,
    marginBottom: 64,
    paddingBottom: 32
  },
  contentBox: {
    backgroundColor: theme.palette.background.default,
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  resetDrawer: {
    overflowY: 'hidden'
  },
  toolbar: theme.mixins.toolbar,
}))

const Empreendimentos = ({
  buscarEmpreendimentos,
  alterarEmpreendimento,
  excluirEmpreendimento,
  empreendimentos,
  cadastrarEmpreendimento,
}) => {
  const [termoDeBusca, setTermoDeBusca] = useState('')
  const [handleDrawer, setHandleDrawer] = useState(true)

  const history = useHistory()
  function useQuery() {
    return new URLSearchParams(useLocation().search)
  }
  let { url } = useRouteMatch()
  let query = useQuery()

  useEffect(() => {
    const buscaEmprendimento = async () => {
      await buscarEmpreendimentos()
    }
    buscaEmprendimento()
  }, [])

  useEffect(() => {
    if (empreendimentos.length > 0) {
      if (!query.get('cod')) {
        history.push({
          pathname: `/empreendimentos/empreendimento?cod=${empreendimentos[0].cod}`,
          state: {
            nome: empreendimentos[0].nome,
            cod: empreendimentos[0]?.cod,
          },
        })
      } else {
        history.push({
          pathname: `/empreendimentos/empreendimento?cod=${query.get('cod')}`,
          state: {
            nome: empreendimentos.filter((el) => el.cod.toString() === query.get('cod'))[0].nome,
            cod: query.get('cod'),
          },
        })
      }
    }
  }, [empreendimentos])

  const handleChangePesquisarEmpreendimento = (e) => {
    setTermoDeBusca(e.target.value)
  }

  const handleBuscarObras = async (id, nomeEmpreendimento) => {
    history.push({
      pathname: `/empreendimentos/empreendimento?cod=${id}`,
      state: { nome: nomeEmpreendimento, cod: id },
    })
  }

  const empreendimentoAdicionado = (data) => {
    cadastrarEmpreendimento(data)
    history.push({
      pathname: `${url}/empreendimento?cod=${data.cod}`,
      state: { nome: data.nome, cod: data.cod },
    })
  }

  const criteriosBusca = (empreendimento) => {
    return (
      replaceSpecialChars(empreendimento.nome)
        .toUpperCase()
        .includes(replaceSpecialChars(termoDeBusca).toUpperCase()) ||
      replaceSpecialChars(empreendimento.nomeUsuarioIncluiu)
        .toUpperCase()
        .includes(replaceSpecialChars(termoDeBusca).toUpperCase())
    )
  }

  const classes = useStyles()
  const list = empreendimentos.filter((item) => criteriosBusca(item))
  const empreendimentoOrdenados = _.sortBy(list, 'loginInclusao')

  return (
    <Template insidePaper={false}>
      <Drawer variant="permanent" anchor="left"
        classes={{
          paper: classes.resetDrawer
        }}
      >
        <div className={classes.toolbar} />
        <Box mt={2} className={handleDrawer ? classes.drawer : classes.miniDrawer}>
          {handleDrawer ? (
            <>
              <Box display="flex" alignItems="flex-start" pt={2} style={{ marginLeft: '-30px' }}>
                <IconButton title="Esconder empreendimentos" color="default" onClick={() => setHandleDrawer(false)} size="small">
                  <ChevronLeftIcon />
                </IconButton>
                <AdicionarEmpreendimento
                  empreendimentoAdicionado={empreendimentoAdicionado}
                  className={classes.addEmpreendimento}
                  change={handleChangePesquisarEmpreendimento}
                />
              </Box>
              <Divider style={{ width: '100%' }} />
              <CustomScroller className={classes.gridSidebar}>
                {empreendimentoOrdenados.map((empreendimento, index) => (
                  <Box key={index} className={classes.content}>
                    <CardEmpreendimento
                      click={handleBuscarObras}
                      empreendimento={empreendimento}
                      excluirEmpreendimento={excluirEmpreendimento}
                      alterarEmpreendimento={alterarEmpreendimento}
                    />
                  </Box>
                ))}
              </CustomScroller>
            </>
          ) : (
            <Box pt={2}>
              <IconButton title="Mostrar empreendimentos" color="default" onClick={() => setHandleDrawer(true)} size="small">
                <ChevronRightIcon color="primary" />
              </IconButton>
            </Box>
          )}
        </Box>
      </Drawer>
      <ListaObras handleDrawer={handleDrawer} podeCadastrarObra={empreendimentos.length} />
    </Template>
  )
}

const mapStateToProps = (state) => ({
  empreendimentos: state.empreendimentos,
})

Empreendimentos.propTypes = {
  buscarEmpreendimentos: PropTypes.func,
  buscarObras: PropTypes.func,
  alterarEmpreendimento: PropTypes.func,
  cadastrarEmpreendimento: PropTypes.func,
  excluirEmpreendimento: PropTypes.func,
  empreendimentos: PropTypes.array,
}

export default connect(mapStateToProps, {
  buscarEmpreendimentos,
  alterarEmpreendimento,
  cadastrarEmpreendimento,
  excluirEmpreendimento,
})(Empreendimentos)
