import React, { Fragment } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Paginacao from '../comum/Paginacao'
import Orcamento from './Orcamento'
import TitleTableCell from '../comum/TitleTableCell'
import PropTypes from 'prop-types'

const ListaOrcamentos = ({
  codEmpreendimento, orcamentos, pagina, registrosPorPagina, duplicar, editar, excluir, onChangePage, onChangeRowsPerPage
}) => (
  <Fragment>
    {orcamentos
      ? (
        <div>
          <Table>
            <TableHead>
              <TableRow>
                <TitleTableCell style={{ width: 'auto' }}>Analisar</TitleTableCell>
                <TitleTableCell width='30%'>Descrição</TitleTableCell>
                <TitleTableCell style={{ width: 'auto' }}>Tipo</TitleTableCell>
                <TitleTableCell align='right' width='12%'>Valor Informado</TitleTableCell>
                <TitleTableCell align='right' width='12%'>Valor Calculado</TitleTableCell>
                <TitleTableCell align='right' width='12%'>Diferença de Cálculo</TitleTableCell>
                <TitleTableCell align='center'>Obs.</TitleTableCell>
                <TitleTableCell>Ações</TitleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {
                orcamentos.slice(pagina * registrosPorPagina, pagina * registrosPorPagina + registrosPorPagina)
                  .map((orcamento) => (
                    <Orcamento key={orcamento.cod} codEmpreendimento={codEmpreendimento}
                      value={orcamento} duplicar={duplicar} editar={editar} excluir={excluir} />
                  ))
              }
            </TableBody>
          </Table>
          <Paginacao
            total={orcamentos.length}
            registrosPorPagina={registrosPorPagina}
            pagina={pagina}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </div>
      )
      : <div />
    }
  </Fragment>
)

ListaOrcamentos.propTypes = {
  codEmpreendimento: PropTypes.number,
  orcamentos: PropTypes.array,
  pagina: PropTypes.number,
  registrosPorPagina: PropTypes.number,
  duplicar: PropTypes.func,
  editar: PropTypes.func,
  excluir: PropTypes.func,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func
}

export default ListaOrcamentos
