import React, { Component } from 'react'
import { Divider, IconButton, Table, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import ResumoCustoTotal from '../../ResumoCustoTotal'
import styles from '../../../styles'
import { formatarNumeroParaMoedaBR, mapStateToPropsContexto, showSuccessMsg } from '../../../../../../../../js/util'
import TableBody from '@material-ui/core/TableBody'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import StyledDrawer from '../../../../../../../comum/StyledDrawer'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import ModalConfirmacao from '../../../../../../../comum/ModalConfirmacao'
import Typography from '@material-ui/core/Typography'
import { context_httpdelete } from '../../../../../../../../js/httpRequest'
import AdicionarTempoFixoComposicao from './AdicionarTempoFixoComposicao'
import EditarTempoFixoComposicao from './EditarTempoFixoComposicao'
import { connect } from 'react-redux'
import Link from '@material-ui/core/Link'

const defaultState = {
  exibirDrawerAdicionar: false,
  tempoFixoEdicao: null,
  tempoFixoExclusao: null
}

class TemposFixosComposicao extends Component {
  static propTypes = {
    classes: PropTypes.object,
    contexto: PropTypes.object,
    composicoesAuxiliares: PropTypes.object.isRequired,
    permitirEdicao: PropTypes.bool,
    codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    detalharComposicao: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = defaultState
    this.handleDadosAlterados = this.handleDadosAlterados.bind(this)
    this.handleConfirmarExclusaoTempoFixo = this.handleConfirmarExclusaoTempoFixo.bind(this)
  }

  handleDadosAlterados() {
    const { onDadosAlterados } = this.props
    this.setState(defaultState)
    onDadosAlterados()
  }

  async handleConfirmarExclusaoTempoFixo () {
    const { codigoComposicao, contexto, onDadosAlterados } = this.props
    const { tempoFixoExclusao } = this.state
    const { codigoTempoFixo, codigoInsumoTempoFixo } = tempoFixoExclusao
    const resultado = await context_httpdelete(`precos/desvincular-tempo-fixo?composicao=${codigoComposicao}&tempo-fixo=${codigoTempoFixo}&insumo=${codigoInsumoTempoFixo}`, contexto)
    if (resultado) {
      this.setState(defaultState)
      showSuccessMsg(`Tempo fixo ${codigoTempoFixo} desvinculado da composição.`)
      onDadosAlterados()
    }
  }

  render () {
    const { exibirDrawerAdicionar, tempoFixoEdicao, tempoFixoExclusao } = this.state
    const { classes, composicoesAuxiliares, permitirEdicao, codigoComposicao, detalharComposicao } = this.props
    return (
      <>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '5%' }} className={classes.tableCell}>E</TableCell>
              <TableCell style={{ width: '40%' }} className={classes.tableCell}>
                Tempo Fixo
                {
                  permitirEdicao && (
                    <Button color='primary' size='small' onClick={() => this.setState({ exibirDrawerAdicionar: true })}>
                      + Adicionar
                    </Button>
                  )
                }
              </TableCell>
              <TableCell style={{ width: '10%' }} className={classes.tableCell}>Código</TableCell>
              <TableCell style={{ width: '10%' }} className={classes.tableCell}>Quantidade</TableCell>
              <TableCell style={{ width: '10%' }} className={classes.tableCell}>Unidade</TableCell>
              <TableCell style={{ width: '10%' }} className={classes.tableCell}>Custo Unitário</TableCell>
              <TableCell align={'right'} className={classes.tableCell}>Custo Unitário</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              composicoesAuxiliares.temposFixos.map((tempoFixoComp) => (
                <TableRow key={tempoFixoComp.codigoTempoFixo}>
                  <TableCell className={classes.tableCell}>
                    {
                      permitirEdicao && (
                        <MesmaLinha>
                          <IconButton size='small' onClick={() => this.setState({ tempoFixoEdicao: tempoFixoComp })} color='primary'>
                            <EditIcon />
                          </IconButton>
                          <IconButton size='small' onClick={() => this.setState({ tempoFixoExclusao: tempoFixoComp })} color='primary'>
                            <DeleteIcon />
                          </IconButton>
                        </MesmaLinha>
                      )
                    }
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {tempoFixoComp.codigoMaterial ? tempoFixoComp.codigoMaterial : tempoFixoComp.codigoComposicaoAuxiliar} - {tempoFixoComp.descricao}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Link onClick={() => detalharComposicao(tempoFixoComp.tempoFixoOrcamento)}>
                      {tempoFixoComp.tempoFixoOrcamento.codigo}
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    { formatarNumeroParaMoedaBR(tempoFixoComp.quantidade, 7, 7) }
                  </TableCell>
                  <TableCell className={classes.tableCell}> {tempoFixoComp.tempoFixoOrcamento.unidade} </TableCell>
                  <TableCell className={classes.tableCell}>
                    { formatarNumeroParaMoedaBR(composicoesAuxiliares.catalogoPrecos[tempoFixoComp.codigoTempoFixo], 2, 5) }
                  </TableCell>
                  <TableCell align='right' className={classes.tableCell}>
                    {
                      formatarNumeroParaMoedaBR(composicoesAuxiliares.catalogoPrecos[tempoFixoComp.codigoTempoFixo] * tempoFixoComp.quantidade, 2, 5)
                    }
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <ResumoCustoTotal classes={classes} titulo='Custo Unitário Total de Tempo Fixo' valorCusto={composicoesAuxiliares.custoUnitarioTotalTempoFixo} />
        <Divider className={classes.divider} />

        {
          exibirDrawerAdicionar && (
            <StyledDrawer
              PaperProps={{ className: classes.drawerEdicao }}
              open
              anchor={'right'}>
              <AdicionarTempoFixoComposicao
                codigoComposicao={codigoComposicao}
                onDadosAlterados={this.handleDadosAlterados}
                onCancelar={() => this.setState({ exibirDrawerAdicionar: false })}
              />
            </StyledDrawer>
          )
        }
        {
          tempoFixoEdicao != null && (
            <StyledDrawer
              PaperProps={{ className: classes.drawerEdicao }}
              open
              anchor='right'>
              <EditarTempoFixoComposicao
                tempoFixo={tempoFixoEdicao}
                codigoComposicao={codigoComposicao}
                onCancelar={() => this.setState({ tempoFixoEdicao: null })}
                onDadosAlterados={this.handleDadosAlterados}
              />
            </StyledDrawer>
          )
        }
        {
          tempoFixoExclusao != null && (
            <ModalConfirmacao
              open
              titulo='Confirmar Exclusão de Tempo Fixo'
              confirmar={this.handleConfirmarExclusaoTempoFixo}
              onClose={() => this.setState({ tempoFixoExclusao: null })}>
              <Typography>
                Deseja realmente desvincular o tempo fixo { tempoFixoExclusao.codigoTempoFixo } - { tempoFixoExclusao.tempoFixoOrcamento.descricao } da composição?
              </Typography>
            </ModalConfirmacao>
          )
        }
      </>
    )
  }
}

export default connect(mapStateToPropsContexto)(withStyles(styles)(TemposFixosComposicao))
