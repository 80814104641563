import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import { getAuthentication, getPerfisUsuario } from '../../../security/securityContext'
import App from '../../App'
import PropTypes from 'prop-types'
import { Button, Divider, Paper, TextField, Typography } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Box from '@material-ui/core/Box'
import Select from 'react-select'
import Paginacao from '../../comum/Paginacao'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TitleTableCell from '../../comum/TitleTableCell'
import TableBody from '@material-ui/core/TableBody'
import { Alert } from '@material-ui/lab'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../../js/operationProgressUtil'
import { showErrorMsg, showSuccessMsg } from '../../../js/util'
import UnidadeJurisdicionadaAutoComplete from '../../empreendimento/UnidadeJurisdicionadaAutoComplete'
import { httpget } from '../../../js/httpRequest'
import styles from '../styles'
import UsuarioPerfil from './UsuarioPerfil'
import { GESTOR_UNIDADE, OPERADOR_UNIDADE, PERFIL, PERFIS_GESTOR_TCU, PERFIS_GESTOR_UJ, SUPERVISOR, UNIDADE_USUARIO_EXTERNO } from '../../../js/perfis'

const ConfigurarPerfil = ({ classes }) => {
  const [perfil, setPerfil] = useState(null)
  const [orgao, setOrgao] = useState(null)
  const [usuarios, setUsuarios] = useState([])
  const [registrosPorPagina, setRegistrosPorPagina] = useState(10)
  const [pagina, setPagina] = useState(0)
  const [perfis, setPerfis] = useState([])
  const [orgaos, setOrgaos] = useState([])
  const [seExibeComboOrgao, setSeExibeComboOrgao] = useState(false)
  const [login, setLogin] = useState(null)
  const [usuario, setUsuario] = useState(null)
  const [pesquisaUsuario, setPesquisaUsuario] = useState(null)
  const [pesquisarOrgao, setPesquisarOrgao] = useState(null)
  const [concederPerfil, setConcederPerfil] = useState(null)
  const [revogarPerfil, setRevogarPerfil] = useState(null)
  const [atualizarUsuarios, setAtualizarUsuarios] = useState(null)
  const [errorUj, setErrorUj] = useState(false)
  const [uj, setUj] = useState(0)

  useEffect(() => {
    usuarioEhGestorTcu() ? setPerfis(PERFIS_GESTOR_TCU) : usuarioEhGestorUj()
      ? setPerfis(PERFIS_GESTOR_UJ) : setPerfis([])
  }, [])

  useEffect(() => {
    const recuperarUJPorPerfil = () => {
      if (getAuthentication().tipoUnidade === UNIDADE_USUARIO_EXTERNO) {
        const idPerfis = getAuthentication().perfis.filter((it) => {
          if (it.length > 6 && it.includes('E')) return it
          return []
        })
        idPerfis.forEach((it) => {
          it.includes('E') && httpget(`/api/uj/perfil?idPerfil=${it}`)
            .then((dados) => {
              orgaos.unshift({ codUnidade: dados.id, nome: dados.nome, sigla: dados.sigla })
              setOrgaos(orgaos)
              const orgaoSelecionado = {
                label: dados.nome + (dados.sigla === null ? '' : (' - ' + dados.sigla)),
                value: dados.id
              }
              setOrgao(orgaoSelecionado)
              setUj(dados.id ? dados.id : 0)
              recuperarUsuariosDePerfilOrgao(orgaoSelecionado, perfil)
            })
            .catch(() => {
              showErrorMsg('Erro ao recuperar Unidade Jurisdicionada do Usuário Externo')
            })
        })
      }
    }

    if (seExibeComboOrgao && usuarioEhGestorTcu()) {
      axios.get('/api/unidades/externas', SLOW_REQUEST_CONFIG).then((ujs) => {
        ujs.status === 200 ? setOrgaos(ujs.data) : setOrgaos([])
      })
    }
    if (seExibeComboOrgao && usuarioEhGestorUj()) {
      recuperarUJPorPerfil()
    }
  }, [seExibeComboOrgao])

  useEffect(() => {
    if (pesquisaUsuario === null) return
    login !== null && login.length > 0 ? axios.get(`/api/perfil/usuario/login/${login}`, SLOW_REQUEST_CONFIG).then(
      (u) => {
        u.data.login && u.status === 200 ? setUsuario(u.data) : setUsuario(null)
        setPesquisaUsuario(null)
        if (u.data === '' && u.status === 200) showErrorMsg('Usuário não localizado')
      }
    ) : setUsuario(null)
  }, [pesquisaUsuario, login])

  useEffect(() => {
    const tipoPerfil = perfil ? perfil.tipo : ''
    switch (tipoPerfil) {
      case 'COMUM':
        axios.get(`/api/perfil/${perfil.value}/usuarios`, SLOW_REQUEST_CONFIG).then(
          (usuarios) => {
            setUsuarios(usuarios.data)
            setAtualizarUsuarios(null)
          }
        )
        break
      case 'ORGAO':
        recuperarUsuariosDePerfilOrgao(orgao, perfil)
        break
      default:
        setUsuarios([])
        setAtualizarUsuarios(null)
    }
  }, [perfil, orgao, atualizarUsuarios, uj])

  useEffect(() => {
    const tipoPerfil = perfil ? perfil.tipo : ''
    switch (tipoPerfil) {
      case 'COMUM':
        if (concederPerfil === null || usuario === null || usuario.login === null) break
        axios.post(`/api/perfil/${perfil.value}/login/${usuario.login}/conceder`, SLOW_REQUEST_CONFIG).then(
          (dto) => {
            if (dto.data.status === 'SUCESSO') showSuccessMsg('Perfil concedido com sucesso.')
            if (dto.data.status === 'ERRO') showErrorMsg('Erro na concessão do perfil: ' + dto.data.mensagem)
            setAtualizarUsuarios(dto.data.status === 'SUCESSO' ? true : null)
          }
        ).catch(() => {
          showErrorMsg('Erro na concessão do perfil.')
        })
        setConcederPerfil(null)
        break
      case 'ORGAO':
        if (concederPerfil === null || orgao === null || login === null) break
        axios.post(`/api/perfil/${perfil.value}/orgao/${orgao.value}/login/${usuario.login}/conceder`, SLOW_REQUEST_CONFIG).then(
          (dto) => {
            if (dto.data.status === 'SUCESSO') showSuccessMsg('Perfil concedido com sucesso.')
            if (dto.data.status === 'ERRO') showErrorMsg('Erro na concessão do perfil: ' + dto.data.mensagem)
            setAtualizarUsuarios(true)
          }
        ).catch(() => {
          showErrorMsg('Erro na concessão do perfil.')
        })
        setConcederPerfil(null)
        break
      default:
        setConcederPerfil(null)
        setUsuarios([])
    }
  }, [concederPerfil, orgao, login, perfil, usuario])

  useEffect(() => {
    const tipoPerfil = perfil ? perfil.tipo : ''
    switch (tipoPerfil) {
      case 'COMUM':
        if (revogarPerfil === null || revogarPerfil.login === null) break
        axios.post(`/api/perfil/${perfil.value}/login/${revogarPerfil.login}/revogar`, SLOW_REQUEST_CONFIG).then(
          () => {
            showSuccessMsg('Perfil revogado com sucesso.')
            setAtualizarUsuarios(true)
          }
        ).catch(() => {
          showErrorMsg('Erro na revogação do perfil.')
        })
        setRevogarPerfil(null)
        break
      case 'ORGAO':
        if (orgao === null || revogarPerfil === null || revogarPerfil.login === null) break
        axios.post(`/api/perfil/${perfil.value}/orgao/${orgao.value}/login/${revogarPerfil.login}/revogar`, SLOW_REQUEST_CONFIG).then(
          () => {
            showSuccessMsg('Perfil revogado com sucesso.')
            setAtualizarUsuarios(true)
          }
        ).catch(() => {
          showErrorMsg('Erro na revogação do perfil.')
        })
        setRevogarPerfil(null)
        break
      default:
        setRevogarPerfil(null)
        setUsuarios([])
    }
  }, [revogarPerfil, orgao, perfil])

  const recuperarUsuariosDePerfilOrgao = async (orgaoSelecionado, perfilSelecionado) => {
    if (orgaoSelecionado === null) return
    const { data } = await axios.get(`/api/perfil/${perfilSelecionado.value}/orgao/${orgaoSelecionado.value}/usuarios`, SLOW_REQUEST_CONFIG)
    if (data) {
      setUsuarios(data)
      setAtualizarUsuarios(null)
    }
  }

  const authentication = getAuthentication()
  if (!authentication || !authentication.isUserAuthenticated) {
    return <Redirect to='/login' />
  }

  const usuarioEhGestorTcu = () =>
    getPerfisUsuario().filter((it) => it === PERFIL.GESTOR_TCU) &&
    getPerfisUsuario().filter((it) => it === PERFIL.GESTOR_TCU).length > 0

  const usuarioEhGestorUj = () =>
    getPerfisUsuario().filter((it) => it === PERFIL.GESTOR_UNID_JURISDICIONADA) &&
    getPerfisUsuario().filter((it) => it === PERFIL.GESTOR_UNID_JURISDICIONADA).length > 0

  const exibeAlerta = (perfil) => {
    return perfil !== null ? <Alert severity='info'>
      <b>{perfil ? perfil.label : ''}:</b> {perfil ? perfil.descricao : ''}
    </Alert> : <div />
  }

  const handleChangeUj = (ujsel) => {
    if (ujsel.id !== 0) setErrorUj(false)
    ujsel.codUnidade = ujsel.id
    if (!orgaos.find((org) => org.codUnidade === ujsel.id)) orgaos.unshift(ujsel)
    setOrgaos(orgaos)
    setOrgao({ label: ujsel.nome + (ujsel.sigla === null ? '' : (' - ' + ujsel.sigla)), value: ujsel.id })
    const id = ujsel.id ? ujsel.id : 0
    setUj(id)
    setPesquisarOrgao(false)
  }

  const handleChangePerfil = (perfilSelecionado) => {
    setPerfil(perfilSelecionado)
    perfilSelecionado.value === GESTOR_UNIDADE.codigo || perfilSelecionado.value === OPERADOR_UNIDADE.codigo
      ? setSeExibeComboOrgao(true) : setSeExibeComboOrgao(false)
    setPagina(0)
    setUsuarios([])
    recuperarUsuariosDePerfilOrgao(orgao, perfilSelecionado)
  }

  const handleChangeOrgao = (orgao) => {
    debugger
    setOrgao(orgao)
    setPagina(0)
    recuperarUsuariosDePerfilOrgao(orgao, perfil)
  }

  const handleStyleSelect = () => {
    return {
      control: (styles) => ({ ...styles, width: '400px' }),
      menu: (styles) => ({ ...styles, width: '400px' }),
      option: (styles) => ({ ...styles, backgroundColor: styles.backgroundColor })
    }
  }

  const handleAtualizarOrcamentoSigiloso = async (e, usuario) => {
    const dto = { loginAutorizado: usuario.login, autorizado: e.target.checked }
    const { data } = await axios.post('/api/perfil/supervisor/visualizar-orcamento-sigiloso', dto, SLOW_REQUEST_CONFIG)
    if (data) {
      setUsuarios(usuarios.map((u) => u.login === usuario.login
        ? { ...u, podeVisualizarOrcamentosSigilosos: data.autorizado }
        : u
      ))
    }
  }

  const exibeColunaOrcamentosSigilosos = perfil && perfil.value === SUPERVISOR.codigo

  return (
    <App noAppToolbar={false}>
      <Box pt={4} mb={2}>
        <Breadcrumbs aria-label='breadcrumb' style={{ marginTop: '-8px' }}>
          <Link color='inherit' href='/'>
            Início
          </Link>
          <Typography color='primary'>Configuração de perfis</Typography>
        </Breadcrumbs>
      </Box>
      <Paper elevation={0} className={classes.paper}>
        <Box pt={2}>
          <Typography variant='h5' gutterBottom>
            Configuração de perfil
          </Typography>
        </Box>
        <Box py={2}>
          <Divider />
        </Box>
        <Typography variant='subtitle2' gutterBottom>
          1. Qual tipo de perfil deseja atribuir?
        </Typography>
        <Select
          classNamePrefix='select'
          placeholder='Selecione o Perfil desejado'
          options={perfis.map((perf) => ({ label: perf.nome, value: perf.codigo, descricao: perf.descricao, tipo: perf.tipo }))}
          onChange={(p) => handleChangePerfil(p)}
          value={perfil}
          styles={handleStyleSelect()}
        />
        <Box my={2}>
          { perfil !== null && exibeAlerta(perfil) }
        </Box>

        {seExibeComboOrgao && !pesquisarOrgao &&
          <Box my={2}>
            <Typography variant='subtitle2' gutterBottom>
              1.1 Selecione um órgão da lista
            </Typography>
            <Box my={2} display='flex'>
              <Select
                classNamePrefix='select'
                placeholder='Selecione o órgão '
                options={orgaos.map((orgao) => ({ label: orgao.nome + (orgao.sigla === null ? '' : (' - ' + orgao.sigla)), value: orgao.codUnidade }))}
                onChange={(o) => handleChangeOrgao(o)}
                value={orgao}
                styles={handleStyleSelect()}
              />
              { usuarioEhGestorTcu() && seExibeComboOrgao && !pesquisarOrgao &&
                <Box m={1}>
                  <Button
                    variant='text'
                    color='primary'
                    onClick={() => { setPesquisarOrgao(true) }}
                  >
                    Não encontrei o órgão na lista
                  </Button>
                </Box>
              }
            </Box>
          </Box>
        }
        { seExibeComboOrgao && pesquisarOrgao &&
          <Box my={2}>
            <Typography variant='subtitle2' color='primary' gutterBottom>
              Digite o nome da unidade para pesquisar e selecionar uma opção
            </Typography>
            <Box my={2} display='flex'>
              <Box className={classes.boxLimiter}>
                <UnidadeJurisdicionadaAutoComplete
                  change={handleChangeUj}
                  error={errorUj}
                />
              </Box>
              <Box m={1}>
                <Button variant='text' color='primary'
                  onClick={() => setPesquisarOrgao(false)}>
                  Cancelar pesquisa
                </Button>
              </Box>
            </Box>
          </Box>
        }
        <Box my={2}>
          <Typography variant='subtitle2' gutterBottom>
            2. Informe um login cadastrado no TCU para encontrar o nome do usuário:
          </Typography>
          <TextField
            fullWidth
            variant='outlined'
            className={classes.boxLimiter}
            placeholder='Digite o login do usuário. Ex X12345666644'
            onChange={(ev) => setLogin(ev.target.value)}
          >
            {login}
          </TextField>
          <Box my={1}>
            <Button
              variant='contained'
              size='small'
              color='primary'
              onClick={() => setPesquisaUsuario(true)}
            >
              Pesquisar usuário
            </Button>
          </Box>
          <Box my={2}>
            <Typography variant='subtitle2' gutterBottom>
              3. Verifique o nome do usuário e conceda o perfil
            </Typography>
            <Typography gutterBottom>
              Usuário: {usuario ? usuario.nomeCompleto : 'Informe um tipo de perfil e usuário'}
            </Typography>
            <Box my={2}>
              <Button
                variant='contained'
                color='secondary'
                size='large'
                disabled={!usuario || usuario.login === null || !perfil}
                onClick={() => { setConcederPerfil(true) }}
              >
                Conceder perfil
              </Button>
            </Box>
          </Box>
        </Box>
      </Paper>

      <Paper elevation={0} className={classes.paperTable}>
        <Box pt={2}>
          <Typography variant='h6' gutterBottom>
            Usuários com esse perfil
          </Typography>
        </Box>
        <Box py={2}>
          <Divider />
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TitleTableCell>Nome</TitleTableCell>
              <TitleTableCell>Login</TitleTableCell>
              <TitleTableCell>Email</TitleTableCell>
              { exibeColunaOrcamentosSigilosos && <TitleTableCell>Visualizar orçamentos sigilosos?</TitleTableCell> }
              <TitleTableCell>Ações</TitleTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {usuarios.slice(pagina * registrosPorPagina, pagina * registrosPorPagina + registrosPorPagina)
              .map((usuario) => (
                <UsuarioPerfil
                  key={usuario.login}
                  usuario={usuario}
                  exibeColunaOrcamentosSigilosos={exibeColunaOrcamentosSigilosos}
                  onClick={() => setRevogarPerfil({ login: usuario.login })}
                  onAtualizarOrcamentoSigiloso={handleAtualizarOrcamentoSigiloso}
                />
              ))
            }
          </TableBody>
        </Table>
        <Paginacao
          total={usuarios.length}
          registrosPorPagina={registrosPorPagina}
          pagina={pagina}
          onChangePage={(e, pag) => setPagina(pag)}
          onChangeRowsPerPage={(e) => setRegistrosPorPagina(e.target.value)}
        />
      </Paper>
    </App>
  )
}

ConfigurarPerfil.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(ConfigurarPerfil)
