import React, { useState } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import PropTypes from 'prop-types'
import TitleTableCell from '../../comum/TitleTableCell'
import Paginacao from '../../comum/Paginacao'
import ItemUnidadeConfigurada from './ItemUnidadeConfigurada'

const TabelaUnidadesConfiguradas = ({ unidades, onDesvincular }) => {
  const [pagina, setPagina] = useState(0)
  const [registrosPorPagina, setRegistrosPorPagina] = useState(10)
  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TitleTableCell>Unidade Interna</TitleTableCell>
            <TitleTableCell>Unidades Externas</TitleTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { unidades.slice(pagina * registrosPorPagina, pagina * registrosPorPagina + registrosPorPagina).map((unidade) => (
            <ItemUnidadeConfigurada
              key={unidade.unidadeInterna.codUnidade}
              unidade={unidade}
              onDesvincular={onDesvincular}
            />
          ))}
        </TableBody>
      </Table>
      <Paginacao
        total={unidades.length}
        registrosPorPagina={registrosPorPagina}
        pagina={pagina}
        onChangePage={(e, pag) => setPagina(pag)}
        onChangeRowsPerPage={(e) => setRegistrosPorPagina(e.target.value)}
      />
    </React.Fragment>
  )
}

TabelaUnidadesConfiguradas.propTypes = {
  unidades: PropTypes.array,
  onDesvincular: PropTypes.func.isRequired
}

export default TabelaUnidadesConfiguradas
