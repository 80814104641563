import { withStyles } from '@material-ui/core/styles'
import TableContainer from '@material-ui/core/TableContainer'

const StyledTableContainerAdicionar = withStyles(() => ({
  root: {
    maxHeight: '80vh',
     marginTop: 16, 
     marginBottom: 16,
     '& > table': {
      border: 'solid 1px #ccc',
      borderRadius: 4,
    }
  },
}))(TableContainer);

export default StyledTableContainerAdicionar
