import React from 'react'
import PropTypes from 'prop-types'
import { formatarNumeroParaMoedaBR } from '../../../../../../js/util'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../styles'

const ResumoCustoTotal = ({ classes, titulo, valorCusto }) => {
  return (
    <>
      <div className={classes.resumo}>
        <b className={classes.marginTitle}>{titulo}</b>
        <b className={classes.lastColumn}>{formatarNumeroParaMoedaBR(valorCusto, 2, 2)}</b>
      </div>
    </>
  )
}

ResumoCustoTotal.propTypes = {
  valorCusto: PropTypes.number.isRequired,
  classes: PropTypes.object,
  titulo: PropTypes.string.isRequired
}

export default withStyles(styles)(ResumoCustoTotal)
