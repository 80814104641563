import React, { Component, Fragment } from 'react'
import { Link, TableCell, TableRow } from '@material-ui/core'
import PropTypes from 'prop-types'
import { converterMoedaBRParaNumero, formatarNumeroParaMoedaBR, handleCampoNumericoChange, mapStateToPropsContexto, seStringVazia, showSuccessMsg } from '../../../../../js/util'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import TextField from '@material-ui/core/TextField'
import MesmaLinha from '../../../../comum/MesmaLinha'
import { context_httpget, context_httpput } from '../../../../../js/httpRequest'
import { connect } from 'react-redux'
import ModalComposicoes from '../composicoes/ModalComposicoes'

class ItemEquipamentoRow extends Component {
  static propTypes = {
    equipamento: PropTypes.object.isRequired,
    contexto: PropTypes.object,
    onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.func
  }
  constructor (props) {
    super(props)
    this.state = {
      editando: false,
      descricao: '',
      descricaoEmpty: false,
      custoProdutivo: '',
      custoProdutivoEmpty: false,
      custoImprodutivo: '',
      custoImprodutivoEmpty: false,
      modalComposicoes: false,
      composicoes: null
    }
    this.handleCancelarEdicao = this.handleCancelarEdicao.bind(this)
    this.handleSalvarEdicao = this.handleSalvarEdicao.bind(this)
  }

  componentDidMount () {
    if (this.props.equipamento) {
      this.copiarDadosParaEstado()
    }
  }

  copiarDadosParaEstado() {
    const { equipamento } = this.props
    this.setState({
      descricao: equipamento.equipamentoOrcamento.descricao,
      descricaoEmpty: false,
      custoProdutivo: formatarNumeroParaMoedaBR(equipamento.equipamentoOrcamento.custoProdutivo, 2, 4),
      custoProdutivoEmpty: false,
      custoImprodutivo: formatarNumeroParaMoedaBR(equipamento.equipamentoOrcamento.custoImprodutivo, 2, 4),
      custoImprodutivoEmpty: false
    })
  }

  handleCampoTextoChange (event, campo) {
    const state = {}
    state[campo] = event.target.value
    state[`${campo}Empty`] = seStringVazia(event.target.value)
    this.setState(state)
  }

  handleCancelarEdicao () {
    this.copiarDadosParaEstado()
    this.setState({ editando: false })
  }

  async handleSalvarEdicao () {
    const { descricao, custoProdutivo, custoImprodutivo } = this.state
    const descricaoEmpty = seStringVazia(descricao)
    const custoProdutivoEmpty = seStringVazia(custoProdutivo)
    const custoImprodutivoEmpty = seStringVazia(custoImprodutivo)

    if([descricaoEmpty, custoProdutivoEmpty, custoImprodutivoEmpty].some(Boolean)) {
      this.setState({
        descricaoEmpty, custoProdutivoEmpty, custoImprodutivoEmpty
      })
    } else {
      const { equipamento, contexto } = this.props
      const dados = {
        descricao,
        custoProdutivo: converterMoedaBRParaNumero(custoProdutivo),
        custoImprodutivo: converterMoedaBRParaNumero(custoImprodutivo)
      }
      const ok = await context_httpput(`precos/equipamentos/${equipamento.equipamentoOrcamento.codigo}`, contexto, dados)
      if (ok) {
        showSuccessMsg('Dados do equipamento alterados com sucesso.')
        this.setState({ editando: false })
      }
    }
  }

  abrirModalComposicoes () {
    const { contexto, equipamento } = this.props

    context_httpget(`precos/composicoes/equipamento/${equipamento.equipamentoOrcamento.codigo}`, contexto)
      .then((composicoes) => {
        this.setState({
          composicoes,
          modalComposicoes: true
        })
      })
  }

  fecharModalComposicoes () {
    this.setState({ modalComposicoes: false })
  }

  render () {
    const { equipamento, onAtualizarItemCurvaABCComposicaoDuplicada } = this.props
    const {
      editando,
      descricao,
      descricaoEmpty,
      custoProdutivo,
      custoProdutivoEmpty,
      custoImprodutivo,
      custoImprodutivoEmpty,
      modalComposicoes,
      composicoes
    } = this.state

    return(
      <Fragment>
        <TableRow key={equipamento.equipamentoOrcamento.codigo}>
          <TableCell>
            { equipamento.equipamentoOrcamento.sistemaReferencia }
          </TableCell>
          <TableCell>
            {equipamento.equipamentoOrcamento.mes}/{equipamento.equipamentoOrcamento.ano}
          </TableCell>
          <TableCell>
            {equipamento.equipamentoOrcamento.codigo}
          </TableCell>
          <TableCell>
            {
              editando ? (
                <TextField
                  fullWidth
                  variant='outlined'
                  value={descricao}
                  error={descricaoEmpty}
                  onChange={(e) => this.handleCampoTextoChange(e, 'descricao')}
                />
              ) : descricao
            }
          </TableCell>
          <TableCell>
            {
              editando ? (
                <TextField
                  variant='outlined'
                  value={custoProdutivo}
                  error={custoProdutivoEmpty}
                  onChange={(e) => handleCampoNumericoChange(e, (e) => this.handleCampoTextoChange(e, 'custoProdutivo'))}
                />
              ) : custoProdutivo
            }
          </TableCell>
          <TableCell>
            {
              editando ? (
                <TextField
                  variant='outlined'
                  value={custoImprodutivo}
                  error={custoImprodutivoEmpty}
                  onChange={(e) => handleCampoNumericoChange(e, (e) => this.handleCampoTextoChange(e, 'custoImprodutivo'))}
                />
              ) : formatarNumeroParaMoedaBR(custoImprodutivo, 2, 4)
            }
          </TableCell>
          <TableCell>
            <Link display='inline' color='primary' onClick={() => this.abrirModalComposicoes()}>
              {equipamento.qtdComposicoes} composiç{equipamento.qtdComposicoes === 1 ? 'ão' : 'ões'}
            </Link>
          </TableCell>
          <TableCell align='center'>
            {
              editando ? (
                <MesmaLinha>
                  <IconButton color='primary' onClick={this.handleSalvarEdicao}>
                    <DoneIcon />
                  </IconButton>
                  <IconButton color='primary' onClick={this.handleCancelarEdicao}>
                    <ClearIcon />
                  </IconButton>
                </MesmaLinha>
              ) : (
                <IconButton color='primary' onClick={() => this.setState({ editando: true })}>
                  <EditIcon />
                </IconButton>
              )
            }
          </TableCell>
        </TableRow>

        <ModalComposicoes
          open={modalComposicoes}
          close={() => this.fecharModalComposicoes()}
          titulo={`Composições do equipamento: ${equipamento.equipamentoOrcamento.codigo} - ${equipamento.equipamentoOrcamento.descricao}`}
          composicoes={composicoes}
          onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
        />
      </Fragment>
    )
  }
}

export default connect(mapStateToPropsContexto)(ItemEquipamentoRow)
