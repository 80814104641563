import React from 'react'
import { InputAdornment, TextField } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import PropTypes from 'prop-types'

const Botao = ({ onConfirm }) => (
  <IconButton id='btnBuscar' onClick={onConfirm}>
    <SearchIcon />
  </IconButton>
)

Botao.propTypes = {
  onConfirm: PropTypes.func
}

const Input = ({
  placeholder, onChange, onKeyPress, onClick, value, adornment
}) => (
  <TextField id='inpBuscar' placeholder={ placeholder } variant='outlined'
             onChange={onChange} onKeyPress={onKeyPress} onClick={onClick}
             value={value}
             style={{marginRight: 12}}
             InputProps={{
              endAdornment:
                <InputAdornment position='end'>
                  {adornment}
                </InputAdornment>
             }}
  />
)

Input.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  onClick: PropTypes.func,
  adornment: PropTypes.element,
  value: PropTypes.string
}

const Buscar = ({
  placeholder, onChange, invertido, onConfirm, onKeyPress, onClick, value
}) => (
  <React.Fragment>
    {
      invertido
        ? <React.Fragment>
          <Input placeholder={placeholder} onChange={onChange} onKeyPress={onKeyPress} onClick={onClick} value={value} adornment={<Botao onConfirm={onConfirm} />}/>
        </React.Fragment>
        : <React.Fragment>
          <Input placeholder={placeholder} onChange={onChange} onKeyPress={onKeyPress} onClick={onClick} value={value} adornment={<Botao onConfirm={onConfirm} />}/>
        </React.Fragment>
    }
  </React.Fragment>
)

Buscar.propTypes = {
  placeholder: PropTypes.string,
  invertido: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onKeyPress: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.string
}

Buscar.defaultProps = {
  invertido: false
}

export default Buscar