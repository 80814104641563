import React, { Component } from 'react'
import { Button, Menu } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import MenuItem from '@material-ui/core/MenuItem'
import { TIPO_CRITERIO_EXPRESSAO, TIPO_CRITERIO_NEGACAO_EXPRESSAO, TIPO_NENHUM_DESSES_TERMOS, TIPO_QUALQUER_DESSES_TERMOS, TIPO_TODOS_ESSES_TERMOS } from '../../../../analise-conteudo'
import PropTypes from 'prop-types'

class BotaoAdicionarCriterio extends Component {
  static propTypes = {
    onAdicionarCriterioClick: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose() {
    this.setState({ anchorEl: null })
  }

  handleMenuClick(tipo) {
    const { onAdicionarCriterioClick } = this.props
    this.handleClose()
    onAdicionarCriterioClick(tipo)
  }

  render() {
    const { anchorEl } = this.state
    return (
      <>
        <Button aria-controls='fade-menu' aria-haspopup='true' onClick={this.handleClick} color='primary' variant='text'>
          + Novo Critério
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          TransitionComponent={Fade}>
          <MenuItem onClick={() => this.handleMenuClick(TIPO_TODOS_ESSES_TERMOS)}>+ {TIPO_TODOS_ESSES_TERMOS.label}</MenuItem>
          <MenuItem onClick={() => this.handleMenuClick(TIPO_QUALQUER_DESSES_TERMOS)}>+ {TIPO_QUALQUER_DESSES_TERMOS.label}</MenuItem>
          <MenuItem onClick={() => this.handleMenuClick(TIPO_NENHUM_DESSES_TERMOS)}>+ {TIPO_NENHUM_DESSES_TERMOS.label}</MenuItem>
          <MenuItem onClick={() => this.handleMenuClick(TIPO_CRITERIO_EXPRESSAO)}>+ {TIPO_CRITERIO_EXPRESSAO.label}</MenuItem>
          <MenuItem onClick={() => this.handleMenuClick(TIPO_CRITERIO_NEGACAO_EXPRESSAO)}>+ {TIPO_CRITERIO_NEGACAO_EXPRESSAO.label}</MenuItem>
        </Menu>
      </>
    )
  }
}


export default BotaoAdicionarCriterio
