import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Link from '@material-ui/core/Link'
import { truncateString } from '../../js/util'
import PropTypes from 'prop-types'

const BreadCrumbLink = ({ to, desc, isItem }) => {
  const size = 30

  return (
    <Tooltip title={desc.length > size ? desc : ''}>
      <Link color='inherit' href={to} >
        { isItem ? truncateString(desc, size) : desc }
      </Link>
    </Tooltip>
  )
}

BreadCrumbLink.propTypes = {
  to: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  isItem: PropTypes.bool,
  color: PropTypes.string
}

export default BreadCrumbLink
