import { pageHeaderAndFooter, round, tableFoot } from './RelatorioComposicaoPdf'

export function dadosAtividadeAuxiliar (doc, detalhes, titulo) {
  const tableHeaderAtividadesAuxiliares = [
    { dataKey: 'descricao', title: 'D - Atividade Auxiliar' },
    { dataKey: 'quantidade', title: 'Quantidade' },
    { dataKey: 'unidade', title: 'Unidade' },
    { dataKey: 'precoUnitario', title: 'Preço Unitário' },
    { dataKey: 'custoUnitario', title: 'Custo Unitário' }
  ]

  const tableDataAtividadesAuxiliares = []
  detalhes.composicoesAuxiliares.atividadesAuxiliares.forEach((it) => {
    tableDataAtividadesAuxiliares.push({
      descricao: it.codigoAtividadeAuxiliar + ' - ' + it.atividadeAuxiliar.descricao,
      quantidade: round(it.quantidade, 4),
      unidade: it.atividadeAuxiliar.unidade,
      precoUnitario: round(detalhes.composicoesAuxiliares.catalogoPrecos[it.codigoAtividadeAuxiliar], 4),
      custoUnitario: round(it.quantidade * detalhes.composicoesAuxiliares.catalogoPrecos[it.codigoAtividadeAuxiliar], 4)
    })
  })
  doc.autoTable(tableHeaderAtividadesAuxiliares, tableDataAtividadesAuxiliares, {
    startY: doc.lastAutoTable.finalY,
    tableWidth: 'auto',
    margin: { top: 20 },
    tableLineColor: 255,
    tableLineWidth: 1,
    fontSize: 9,
    styles: { halign: 'right', cellPadding: 1 },
    headStyles: {
      fillColor: [200, 200, 200],
      textColor: [0, 0, 0],
      descricao: {
        overflow: 'linebreak',
        cellWidth: 200,
        valign: 'middle',
        halign: 'left',
        fontStyle: 'bold'
      }
    },
    columnStyles: {
      halign: 'right',
      descricao: {
        overflow: 'linebreak',
        cellWidth: 350,
        valign: 'middle',
        halign: 'left',
        fontStyle: 'bold'
      }
    },
    didDrawPage: (data) => pageHeaderAndFooter(doc, data, titulo),
    willDrawCell: function (data) {
      if (data.column.index === 0 && data.cell.section === 'head') {
        data.cell.styles.halign = 'left'
      }
    }
  })

  tableFoot([{
    label: 'Custo Total de Atividades Auxiliares',
    value: round(detalhes.composicoesAuxiliares.custoTotalAtividadesAuxiliares, 2)
  }],
  doc)
  tableFoot([{
    label: '                            Subtotal',
    value: round(detalhes.subtotal, 2)
  }],
  doc)
}
