import React, { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import AddBoxIcon from '@material-ui/icons/AddBox'
import PropTypes from 'prop-types'

class AdicionarObservacao extends Component {
  static propTypes = {
    abrirModal: PropTypes.func.isRequired
  }

  render () {
    const { abrirModal } = this.props
    return (
      <>
        <IconButton color='primary' onClick={abrirModal}>
          <AddBoxIcon />
        </IconButton>

      </>
    )
  }
}

export default AdicionarObservacao
