import React from 'react'
import { Link, TableCell, TableRow } from '@material-ui/core'
import PropTypes from 'prop-types'
import { formatarNumeroParaMoedaBR } from '../../../../js/util'

const ItemCurvaAbcInsumoRow = ({ item, onItemMemoriaCalculoChange }) => {
  return (
    <React.Fragment>
      <TableRow hover>
        <TableCell>{item.tipo}</TableCell>
        <TableCell>
          <Link onClick={() => onItemMemoriaCalculoChange(item)}>
            {item.codigoServico}
          </Link>
        </TableCell>
        <TableCell>{item.descricao}</TableCell>
        <TableCell>{item.unidade}</TableCell>
        <TableCell> {formatarNumeroParaMoedaBR(item.quantidadeOperativaTotal, 2, 2)}</TableCell>
        <TableCell>
          {item.tipo === 'Equipamento' ? formatarNumeroParaMoedaBR(item.quantidadeImprodutivaTotal, 2, 2) : '-'}
        </TableCell>
        <TableCell>{formatarNumeroParaMoedaBR(item.precoParcialTotal, 2, 2)}</TableCell>
        <TableCell>{formatarNumeroParaMoedaBR(item.percentualTotal, 2, 2)}</TableCell>
      </TableRow>
    </React.Fragment>
  )
}

ItemCurvaAbcInsumoRow.propTypes = {
  item: PropTypes.object.isRequired,
  onItemMemoriaCalculoChange: PropTypes.func.isRequired
}

export default ItemCurvaAbcInsumoRow
