import React from 'react'
import Typography from '@material-ui/core/Typography/Typography'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/styles'
import BreadCrumbLink from './BreadCrumbLink'

export const styles = (theme) => ({
  breadcrumb: {
    display: 'flex',
    alignItems: 'baseline',
    marginTop: 8
  },
  voltar: {
    marginRight: theme.spacing(1),
    cursor: 'pointer'
  },
  breadcrumb_margin: {
    marginLeft: theme.spacing(1)
  },
  brdcrumb_orc: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    '& li:last-child > p': {
      color: theme.palette.primary.main,
      textDecoration: 'none'
    }
  }

})

const inicio = () => {
  return <BreadCrumbLink key='inicio' to='/' desc='Início' />
}

const breadCrumbEmpreendimentoItem = (empreendimento) => {
  return <BreadCrumbLink key={empreendimento} to={`/empreendimentos/empreendimento?cod=${empreendimento.cod}`}
    desc={empreendimento.nome} isItem />
}

const breadCrumbObraItem = (empreendimento, obra) => {
  if (!_.isNil(obra)) {
    return <BreadCrumbLink to={`/empreendimentos/${empreendimento.cod}/obras/${obra.cod}/orcamentos`} desc={'Orçamentos'} isItem />
  }
}

const breadCrumbOrcamentoItem = (empreendimento, obra, orcamento) => {
  if (!_.isNil(orcamento) && !_.isNil(orcamento.cod)) {
    if (orcamento.possuiCurvaAbc) {
      return <BreadCrumbLink to={`/empreendimentos/${empreendimento.cod}/obras/${obra.cod}/orcamentos/${orcamento.cod}/abc`} desc={orcamento.descricao} isItem />
    } else {
      return <BreadCrumbLink to={`/empreendimentos/${empreendimento.cod}/obras/${obra.cod}/orcamentos/${orcamento.cod}/analisar`} desc={orcamento.descricao} isItem />
    }
  }
}

const breadCrumbAnalisarOrcamento = (empreendimento, obra, orcamento, url) => {
  return url.endsWith('/analisar') &&
    (<Typography style={{ fontWeight: 500 }}>Análise de Orçamento</Typography>)
}

const breadCrumbPossiveisErros = (empreendimento, obra, orcamento, url) => {
  return url.endsWith('possiveis-erros') &&
    (<Typography style={{ fontWeight: 500 }}>Tratamento de erros</Typography>)
}

const breadCrumbPossiveisInconsistencias = (empreendimento, obra, orcamento, url) => {
  return url.endsWith('possiveis-inconsistencias') &&
    (<Typography style={{ fontWeight: 500 }}>Possíveis Inconsistências</Typography>)
}

const breadCrumbCurvaAbc = (empreendimento, obra, orcamento, url) => {
  return url.endsWith('abc') &&
    (<Typography style={{ fontWeight: 500 }}>Curva ABC</Typography>)
}

const breadCrumbHistoricoOrcamento = (empreendimento, obra, orcamento, url) => {
  return url.endsWith('historico-orcamento') &&
    (<Typography style={{ fontWeight: 500 }}>Log de atividades</Typography>)
}

const breadCrumbPrecos = (empreendimento, obra, orcamento, url) => {
  return url.endsWith('precos') &&
    (<Typography style={{ fontWeight: 500 }}>Preços</Typography>)
}

const breadCrumbAnaliseConteudo = (empreendimento, obra, orcamento, url) => {
  return url.endsWith('analise-conteudo') && (<Typography style={{ fontWeight: 500 }}>Análise de Conteúdo</Typography>)
}

const BreadCrumb = ({ contexto, match, classes }) => {
  if (!contexto || !contexto.empreendimento) {
    return null
  }
  const { url } = match
  const { empreendimento, obra, orcamento } = contexto

  return (
    <div className={classes.breadcrumb}>
      <Breadcrumbs datacy-id='migalha' separator='›' aria-label='breadcrumb' className={classes.brdcrumb_orc}>
        {inicio()}
        {breadCrumbEmpreendimentoItem(empreendimento)}
        {breadCrumbObraItem(empreendimento, obra)}
        {breadCrumbOrcamentoItem(empreendimento, obra, orcamento)}
        {breadCrumbAnalisarOrcamento(empreendimento, obra, orcamento, url)}
        {breadCrumbPossiveisErros(empreendimento, obra, orcamento, url)}
        {breadCrumbPossiveisInconsistencias(empreendimento, obra, orcamento, url)}
        {breadCrumbCurvaAbc(empreendimento, obra, orcamento, url)}
        {breadCrumbHistoricoOrcamento(empreendimento, obra, orcamento, url)}
        {breadCrumbPrecos(empreendimento, obra, orcamento, url)}
        {breadCrumbAnaliseConteudo(empreendimento, obra, orcamento, url)}
      </Breadcrumbs>
    </div>
  )
}

BreadCrumb.propTypes = {
  contexto: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object,
  classes: PropTypes.object
}

const mapStateToProps = (state) => ({ contexto: state.contexto })

export const BreadCrumbTeste = BreadCrumb
export default connect(mapStateToProps)(withRouter(withStyles(styles)(BreadCrumb)))
