import React, { Component, forwardRef } from 'react'
import { Button, FormControl, Menu, MenuItem, Typography, withStyles } from '@material-ui/core'
import fileDownload from 'js-file-download'
import { connect } from 'react-redux'
import IconeMenuAtribuirBDI from './bdi/IconeMenuAtribuirBDI'
import IconeMenuIncluirNaAmostra from './amostra/IconeMenuIncluirNaAmostra'
import PropTypes from 'prop-types'
import ModalResumoSobreprecoCurvaAbc from './sobrepreco/ModalResumoSobreprecoCurvaAbc'
import SelecionarColunas from './itens/colunas/SelecionarColunas'
import styles from './styles'
import ModalExcluirCurvaAbc from './ModalExcluirCurvaAbc'
import ModalIncluirNaAmostra from './ModalIncluirNaAmostra'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Fade from '@material-ui/core/Fade'
import ModalLimparAmostraCurvaAbc from './ModalLimparAmostraCurvaAbc'
import { colunas } from './itens/colunas/colunas'
import { existeObjetoNaLista, formatarNumeroParaMoedaBR } from '../../../../js/util'
import _ from 'lodash'
import { temSobrepreco } from './curva-abc'

export class MenuCurvaABC extends Component {
  static propTypes = {
    contexto: PropTypes.object,
    itensCurvaAbc: PropTypes.array.isRequired,
    onItensAlterados: PropTypes.func,
    onChange: PropTypes.func,
    valor: PropTypes.number,
    onCurvaAbcExcluida: PropTypes.func.isRequired,
    metodoCalculoSobrepreco: PropTypes.object.isRequired,
    classes: PropTypes.object,
    colunasExibir: PropTypes.array,
    onChangeMetodoCalculoSobrepresso: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      modalResumoSobreprecoAtivo: false,
      modalExcluirCurvaAbcAtivo: false,
      modalLimparAmostraAtivo: false,
      modalIncluirNaAmostra: false,
      anchorEl: null,
    }
    this.abrirResumoSobrepreco = this.abrirResumoSobrepreco.bind(this)
    this.fecharModalResumoSobrepreco =
      this.fecharModalResumoSobrepreco.bind(this)
    this.exportarPlanilha = this.exportarPlanilha.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  fecharModalResumoSobrepreco() {
    this.setState({ modalResumoSobreprecoAtivo: false })
  }

  abrirResumoSobrepreco() {
    this.setState({ modalResumoSobreprecoAtivo: true })
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose() {
    this.setState({ anchorEl: null })
  }

  abrirModalExcluirCurvaAbc = () =>
    this.setState({ modalExcluirCurvaAbcAtivo: true })

  fecharModalExcluirCurvaAbc = () =>
    this.setState({ modalExcluirCurvaAbcAtivo: false })

  abrirModalLimparAmostra = () =>
    this.setState({ modalLimparAmostraAtivo: true })

  fecharModalLimparAmostra = () =>
    this.setState({ modalLimparAmostraAtivo: false })

  abrirModalIncluirNaAmostra = () => {
    this.setState({
      modalIncluirNaAmostraAtivo: true,
      modalLimparAmostraAtivo: false,
    })
  }

  fecharModalIncluirNaAmostra = () =>
    this.setState({ modalIncluirNaAmostraAtivo: false })

   exportarPlanilha = () => {
    const { colunasExibir, itensCurvaAbc, metodoCalculoSobrepreco } = this.props;

    const colunasArquivo = [
      'COD_SERVICO',
      'STATUS',
      'INCLUIR_NA_AMOSTRA',
      'DESCRICAO',
      'UNIDADE',
      'QUANTIDADE',
      'PRECO_UNITARIO',
      'PRECO_PARCIAL',
      'PERCENTUAL_ACUMULADO',
      'PERCENTUAL',
      'BDI',
      'UF',
      'DATA',
      'PRECO_COM_BDI',
      'PRECO_PARCIAL_REF',
      'SOBREPRECO',
      'DESCRICAO_REF',
      'OBSERVACAO',
    ];

    const headerRow = colunasArquivo.map((column) => column.replace('_', ' ')).join(';') + '\n';

    const linhas = itensCurvaAbc.map((item) => {
      console.log(item)
      return colunasArquivo.map((column) => {
        switch (column) {
          case 'COD_SERVICO':
            return item.codigoServico;
          case 'STATUS':
            return item.analiseConcluida ? 'Sim' : 'Não';
          case 'INCLUIR_NA_AMOSTRA':
            return item.incluirNaAmostra ? 'Sim' : 'Não';
          case 'DESCRICAO':
            return item.descricaoOrcamento;
          case 'UNIDADE':
            return item.unidadeOrcamento;
          case 'QUANTIDADE':
            return item.quantidadeFormatado;
          case 'PRECO_UNITARIO':
            return item.precoUnitarioFormatado;
          case 'PRECO_PARCIAL':
            return item.precoParcialCalculadoFormatado;
          case 'PERCENTUAL_ACUMULADO':
            return item.percentualAcumuladoFormatado;
          case 'PERCENTUAL':
            return item.percentualFormatado;
          case 'BDI':
            return formatarNumeroParaMoedaBR(item.bdi, 2, 2);
            case 'UF':
            return item.uf;
          case 'DATA':
            const dataItem = item.mes && item.ano ? `${item.mes}/${item.ano}` : '-';
            return dataItem;
          case 'PRECO_COM_BDI':
           // return formatarNumeroParaMoedaBR(item.valorReferenciaComBdiFormatado,2,2);
            return item.valorReferenciaComBdiFormatado;
          case 'PRECO_PARCIAL_REF':
            return formatarNumeroParaMoedaBR(item.valorTotalReferenciaComBdi,2,2);
          case 'SOBREPRECO':
            return temSobrepreco(item.valorSobrepreco, metodoCalculoSobrepreco)
              ? formatarNumeroParaMoedaBR(item.valorSobrepreco, 2, 2)
              : '-';
          case 'DESCRICAO_REF':
            return _.isNil(item.descricaoReferencia) ? '' : item.descricaoReferencia;
          default:
            return item[column.toLowerCase()] || '';
        }
      }).join(';') + '\n';
    });

    const csvData = headerRow + linhas.join('');

    fileDownload(new Blob(['\ufeff', csvData]), 'curva-abc.csv', 'text/csv');
  }


  render() {
    let {
      itensCurvaAbc,
      onItensAlterados,
      onCurvaAbcExcluida,
      metodoCalculoSobrepreco,
      classes,
      onChangeMetodoCalculoSobrepresso,
    } = this.props
    const {
      modalResumoSobreprecoAtivo,
      modalExcluirCurvaAbcAtivo,
      modalLimparAmostraAtivo,
      modalIncluirNaAmostraAtivo,
    } = this.state
    const { anchorEl } = this.state

    // eslint-disable-next-line react/display-name
    const Icone = forwardRef((props, ref) => (
      <IconeMenuIncluirNaAmostra
        onClick={() => {
          this.abrirModalIncluirNaAmostra()
        }}
        innerRef={ref}
        {...props}
      />
    ))

    return (
      itensCurvaAbc && (
        <div className={classes.menuCurvaABC}>
          <FormControl variant="outlined" style={{ marginRight: 16 }}>
            <SelecionarColunas />
          </FormControl>
          <FormControl variant="outlined">
            <Button
              aria-controls="menu-opcoes"
              aria-haspopup="true"
              onClick={this.handleClick}
              variant="outlined"
            >
              <Typography className={classes.txtBtn}>
                Opções <ArrowDropDownIcon />
              </Typography>
            </Button>
            <Menu
              id="menu-opcoes"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
              TransitionComponent={Fade}
              onClick={() => this.handleClose()}
            >
              <Icone />
              <IconeMenuAtribuirBDI
                itensCurvaAbc={itensCurvaAbc}
                onItensAlterados={onItensAlterados}
              />
              <MenuItem
                value="check"
                onClick={this.abrirResumoSobrepreco}
                className={classes.itemMenuOpcoes}
              >
                <Typography className={classes.tituloItem}>
                  Resumo sobrepreço <br />
                  <span className={classes.subtituloItem}>
                    Exibe um resumo da análise de sobrepreço
                  </span>
                </Typography>
              </MenuItem>
              <MenuItem
                value="check"
                onClick={this.exportarPlanilha}
                className={classes.itemMenuOpcoes}
              >
                <Typography className={classes.tituloItem}>
                  Baixar <br />
                  <span className={classes.subtituloItem}>
                    Exportar planilha da curva ABC
                  </span>
                </Typography>
              </MenuItem>
              <MenuItem
                value="check"
                onClick={() => this.abrirModalExcluirCurvaAbc()}
                className={classes.itemMenuOpcoes}
              >
                <Typography className={classes.tituloItem}>
                  Excluir curva ABC para editar <br />
                  <span className={classes.subtituloItem}>
                    Exclui a curva a ABC para permitir edição do orçamento
                  </span>
                </Typography>
              </MenuItem>
              <MenuItem
                value="check"
                onClick={() => this.abrirModalLimparAmostra()}
                className={classes.itemMenuOpcoes}
              >
                <Typography className={classes.tituloItem}>
                  Limpar amostra <br />
                  <span className={classes.subtituloItem}>
                    Remove todos os itens que estão atualmente incluídos na
                    amostra
                  </span>
                </Typography>
              </MenuItem>
            </Menu>
          </FormControl>

          <ModalResumoSobreprecoCurvaAbc
            ativo={modalResumoSobreprecoAtivo}
            fechar={this.fecharModalResumoSobrepreco}
            metodoCalculoSobrepreco={metodoCalculoSobrepreco}
            onItensAlterados={onItensAlterados}
            onChangeMetodoCalculoSobrepresso={onChangeMetodoCalculoSobrepresso}
          />

          <ModalExcluirCurvaAbc
            open={modalExcluirCurvaAbcAtivo}
            close={() => this.fecharModalExcluirCurvaAbc()}
            onCurvaAbcExcluida={onCurvaAbcExcluida}
          />

          <ModalLimparAmostraCurvaAbc
            open={modalLimparAmostraAtivo}
            close={() => this.fecharModalLimparAmostra()}
            onItensAlterados={onItensAlterados}
          />

          <ModalIncluirNaAmostra
            open={Boolean(modalIncluirNaAmostraAtivo)}
            close={() => this.fecharModalIncluirNaAmostra()}
            onChange={onItensAlterados}
          />
        </div>
      )
    )
  }
}

export const mapStateToProps = ({ contexto, curvaAbc }) => ({
  contexto,
  colunasExibir: curvaAbc.colunasExibir,
})

export default connect(mapStateToProps)(withStyles(styles)(MenuCurvaABC))
