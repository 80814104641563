import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { context_httpget } from '../../../../js/httpRequest'
import Template from '../../../layout/Template'
import Cabecalho from '../orcamento-bruto/Cabecalho'
import { Box, Collapse, IconButton, InputAdornment, Link, Paper, Table, TableContainer, Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import styles from './styles'
import _ from 'lodash'
import CabecalhoTabelaCurvaAbcInsumo from './CabecalhoTabelaCurvaAbcInsumo'
import CorpoTabelaCurvaAbcInsumo from './CorpoTabelaCurvaAbcInsumo'
import { CODE_NUMBER_BACKSPACE_EVENT, replaceSpecialChars } from '../../../../js/util'
import Paginacao from '../../../comum/Paginacao'
import ModalMemoriaCalculo from './memoriaCalculo/ModalMemoriaCalculo'
import TextField from '@material-ui/core/TextField'
import CloseIcon from '@material-ui/icons/Close'
import ModalResumoCurvaAbcInsumo from './resumo/ModalResumoCurvaAbcInsumo'

const CurvaABCInsumoCriada = ({ classes, contexto }) => {
  const [termoBusca, setTermoBusca] = useState('')
  const [insumos, setInsumos] = useState([])
  const [insumosCopia, setInsumosCopia] = useState([])
  const [pagina, setPagina] = useState(0)
  const [registrosPorPagina, setRegistrosPorPagina] = useState(50)
  const [openModalResumo, setOpenModalResumo] = useState(false)
  const [itemMemoriaCalculoSelecionado, setItemMemoriaCalculoSelecionado] = useState(null)

  useEffect(() => {
    const recuperarInsumos = async () => {
      const insumos = await context_httpget('curva-abc-insumos', contexto)
      setInsumos(insumos)
      setInsumosCopia(insumos)
    }
    contexto && recuperarInsumos()
  }, [contexto])

  const handleBuscar = () => {
    if (_.isEmpty(termoBusca)) {
      handleLimparBusca()
    } else {
      const itensFiltrados = insumosCopia.filter((item) => criteriosBusca(item))
      setInsumos(itensFiltrados)
    }
  }

  const criteriosBusca = (item) => {
    return (
      replaceSpecialChars(item.codigoServico)
        .toUpperCase()
        .includes(replaceSpecialChars(termoBusca).toUpperCase()) ||
      replaceSpecialChars(item.tipo)
        .toUpperCase()
        .includes(replaceSpecialChars(termoBusca).toUpperCase()) ||
      replaceSpecialChars(item.descricao)
        .toUpperCase()
        .includes(replaceSpecialChars(termoBusca).toUpperCase())
    )
  }

  const handleKeyPress = (event) => {
    if (event.keyCode === CODE_NUMBER_BACKSPACE_EVENT) {
      setTermoBusca(termoBusca)
      handleBuscar()
    } else {
      handleBuscar()
    }
  }

  const handleLimparBusca = () => {
    setTermoBusca('')
    setInsumos(insumosCopia)
  }

  const handleItemMemoriaCalculoChange = (item) => {
    setItemMemoriaCalculoSelecionado(item)
  }

  return (
    <Template insidePaper={false}>
      <section>
        <div className='cabecalhoGeral'>
          <Paper elevation={0} variant='outlined' className={classes.paper}>
            <Cabecalho
              titulo='Curva ABC de Insumos'
            />
            <Collapse in timeout='auto' unmountOnExit>
              <Box display='flex' alignItems='center' mb={2}>
                <TextField
                  name='termoBusca'
                  label='Filtrar'
                  placeholder='Digite parte do Tipo, Código ou Descrição'
                  style={{ width: 700}}
                  value={termoBusca}
                  onChange={(e) => setTermoBusca(e.target.value)}
                  onKeyUp={(e) => handleKeyPress(e)}
                  variant='outlined'
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position='end'>
                        <IconButton color='primary' onClick={() => handleLimparBusca()}>
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                  }}
                />
                <Box px={3}><Typography variant='caption'>|</Typography></Box>
                <Link color='primary' variant='button' onClick={() => setOpenModalResumo(true)} >
                  Visão geral
                </Link>
              </Box>
            </Collapse>
          </Paper>
        </div>
        <div className={classes.rootTableCurvaAbcInsumo}>
          <Paper className={classes.paperTableCurvaAbcInsumo}>
            <TableContainer>
              <Table
                size={'medium'}
                aria-labelledby='tableTitle'
                aria-label='enhanced table'
              >
                <CabecalhoTabelaCurvaAbcInsumo />
                <CorpoTabelaCurvaAbcInsumo
                  insumos={insumos}
                  pagina={pagina}
                  registrosPorPagina={registrosPorPagina}
                  onItemMemoriaCalculoChange={handleItemMemoriaCalculoChange}
                />
              </Table>
            </TableContainer>
            <Paginacao
              total={insumos.length}
              registrosPorPagina={registrosPorPagina}
              pagina={pagina}
              onChangePage={(e, pag) => { setPagina(pag) }}
              onChangeRowsPerPage={(e) => setRegistrosPorPagina(e.target.value)}
            />
          </Paper>
        </div>
      </section>
      {itemMemoriaCalculoSelecionado &&
        <ModalMemoriaCalculo
          open={Boolean(itemMemoriaCalculoSelecionado)}
          close={() => setItemMemoriaCalculoSelecionado(null)}
          memorias={itemMemoriaCalculoSelecionado.memoriaCalculos}
        />
      }
      <ModalResumoCurvaAbcInsumo
        open={openModalResumo}
        close={() => setOpenModalResumo(false)}
        insumos={insumos}
      />
    </Template>
  )
}

CurvaABCInsumoCriada.propTypes = {
  classes: PropTypes.object,
  contexto: PropTypes.object
}

export const mapStateToProps = ({ contexto }) => ({ contexto })

export default connect(mapStateToProps)(withStyles(styles)(CurvaABCInsumoCriada))
