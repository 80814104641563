import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import axios from 'axios';
import IconButton from '@material-ui/core/IconButton';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import { converterMoedaBRParaNumero, cursorPointer, showSuccessMsg } from '../../../../js/util';
import { SLOW_REQUEST_CONFIG } from '../../../../js/operationProgressUtil';
import PropTypes from 'prop-types';
import IconeLinhaNova from '../../../comum/IconeLinhaNova';
import TextField from '@material-ui/core/TextField';
import MesmaLinha from '../../../comum/MesmaLinha';
import IconeErro from '../../../comum/IconeErro';
import StyledTableRow from '../../../comum/StyledTableRow';
import HttpsIcon from '@material-ui/icons/Https';

const estiloCelulaLinhas = {
  fontSize: '0.75em'
}

const estiloCelulaErro = {
  boxShadow: 'inset 4px 0 rgba(244,67,54, 0.7)',
  fontSize: '0.75em',
  textAlign: 'left'
}

const estiloLinhaOrcamento = {
  color: 'rgba(67,133,191,1)',
  fontWeight: '500'
}

class ItemOrcamentoRow extends Component {
  static propTypes = {
    idEmpreendimento: PropTypes.number.isRequired,
    idObra: PropTypes.number.isRequired,
    idOrcamento: PropTypes.number.isRequired,
    excluir: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    onItemAlterado: PropTypes.func.isRequired,
    contador: PropTypes.number.isRequired,
    podeEditar: PropTypes.bool.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      itemEmEdicao: null
    }

    this.editar = this.editar.bind(this);
    this.handleAttributeChange = this.handleAttributeChange.bind(this);
    this.salvarEdicao = this.salvarEdicao.bind(this);
    this.cancelarEdicao = this.cancelarEdicao.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  cancelarEdicao () {
    this.setState({ itemEmEdicao: null });
  }

  converterValoresMonetarios = () => {
    const { itemEmEdicao } = this.state;
    itemEmEdicao.quantidade = converterMoedaBRParaNumero(itemEmEdicao.quantidade);
    itemEmEdicao.precoUnitario = converterMoedaBRParaNumero(itemEmEdicao.precoUnitario);
    itemEmEdicao.precoParcial = converterMoedaBRParaNumero(itemEmEdicao.precoParcial);
    this.setState({ itemEmEdicao });
  }

  urlItem (item) {
    const { idEmpreendimento, idObra, idOrcamento } = this.props;
    return `/api/empreendimentos/${idEmpreendimento}/obras/${idObra}/orcamentos/${idOrcamento}/itens/${item.cod}`;
  }

  async salvarEdicao () {
    this.converterValoresMonetarios();
    const { itemEmEdicao } = this.state;
    const resumo = (await axios.put(this.urlItem(itemEmEdicao), itemEmEdicao, SLOW_REQUEST_CONFIG)).data;
    this.setState({ itemEmEdicao: null });
    this.props.onItemAlterado(resumo);
    showSuccessMsg('Dados registrados com sucesso.');
  }

  handleAttributeChange (event, attributeName) {
    const itemEmEdicao = _.clone(this.state.itemEmEdicao);
    itemEmEdicao[attributeName] = event.target.value;
    this.setState({ itemEmEdicao });
  }

  editar () {
    this.setState({
      itemEmEdicao: _.clone(this.props.item)
    });
  }

  acoes () {
    const { item, excluir, podeEditar } = this.props;

    const { itemEmEdicao } = this.state;

    return itemEmEdicao ? (
        <TableCell align='center'>
          <IconButton color='primary' variant='outlined'
                      onClick={this.cancelarEdicao}>
            <CancelIcon fontSize='small' style={cursorPointer}/>
          </IconButton>
          <IconButton color='primary' variant='outlined' onClick={this.salvarEdicao}>
            <DoneIcon fontSize='small' style={cursorPointer}/>
          </IconButton>
        </TableCell>
      )

      : (
        <TableCell align='center'>
          {
            podeEditar ? (
              <>
                <IconButton color='primary' variant='outlined' onClick={this.editar} size='small'>
                  <EditIcon fontSize='small'/>
                </IconButton>
                <IconButton color='default' variant='outlined' size='small'
                            onClick={() => excluir(item)}>
                  <DeleteIcon fontSize='small'/>
                </IconButton>
              </>
            ) : (
              <Tooltip title='Não é possível editar ou excluir itens pois já há uma curva ABC gerada para o orçamento'>
                <HttpsIcon/>
              </Tooltip>
            )
          }
        </TableCell>
      )
  }

  descricao () {
    const { item } = this.props;

    return (
      <TableCell>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {item.admLocal
          && <Tooltip title='Administração Local'>
            <Chip label='AD' color='default' size='small' style={{ marginRight: 2, marginLeft: 2 }}/>
          </Tooltip>
          }
          {this.conteudoCampo('descServico')}
        </div>
      </TableCell>
    )
  }

  conteudoCampo (nomeAtributo) {
    const { item } = this.props;
    const { itemEmEdicao } = this.state;
    return itemEmEdicao ? (
      <TextField
        fullWidth
        multiline
        inputRef={input => { this.inputField = input; }}
        value={itemEmEdicao[nomeAtributo] || ''}
        onChange={(e) => this.handleAttributeChange(e, nomeAtributo)}
        onKeyPress={this.handleKeyPress}
        onKeyDown={this.handleKeyDown}
      />
    ) : (<>{ item[nomeAtributo] }</>)
  }

  campoDesc (nomeAtributo) {
    return (
      <TableCell>
        {
          this.conteudoCampo(nomeAtributo)
        }
      </TableCell>
    )
  }

  campo (nomeAtributo) {
    return (
      <TableCell align='right'>
        {
          this.conteudoCampo(nomeAtributo)
        }
      </TableCell>
    )
  }

  temDiferencaPrecoPositiva () {
    const { item } = this.props;
    return item.valoresCalculados.diferencaPreco > 0.001;
  }

  temDiferencaPrecoNegativa () {
    const { item } = this.props;
    return item.valoresCalculados.diferencaPreco < -0.001;
  }

  temDiferencaPreco () {
    return this.temDiferencaPrecoPositiva() || this.temDiferencaPrecoNegativa();
  }

  diferencaPreco () {
    const { item } = this.props;
    let estilo = { color: this.temDiferencaPreco() ? 'red' : 'black' };
    return (
      <TableCell>
        <MesmaLinha>
          <IconeErro exibir={this.temDiferencaPreco()} mensagem='Diferença de Cálculo'/>
          <Typography style={estilo}>
            {item.valoresCalculados.diferencaPrecoFormatada}
          </Typography>
        </MesmaLinha>
      </TableCell>
    )
  }

  numeroLinhaOriginal () {
    const { item } = this.props;
    const { contador } = this.props;
    const estilo = !item.orcamentario ? estiloCelulaErro : estiloCelulaLinhas;
    return (
      <TableCell style={estilo}>
        <MesmaLinha>
          {contador} |&nbsp;
          {_.isNil(item.numeroLinhaOriginal)
            ? <IconeLinhaNova exibir={_.isNil(item.numeroLinhaOriginal)} mensagem='Item não faz parte do arquivo de orçamento original.'/>
            : <Tooltip title={'Linha na planilha'}>
              <span style={estiloLinhaOrcamento}> {item.numeroLinhaOriginal}</span>
            </Tooltip>
          }
        </MesmaLinha>
      </TableCell>
    )
  }

  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.salvarEdicao();
    }
  }

  handleKeyDown(event) {
    if (event.key === 'Escape') {
      this.cancelarEdicao();
    }
  }

  render () {
    return (
      <>
        <StyledTableRow>
          {this.numeroLinhaOriginal()}

          {this.campoDesc('codServico')}

          {this.descricao()}

          {this.campo('unidade')}

          {this.campo('quantidade')}

          {this.campo('precoUnitario')}

          {this.campo('precoParcial')}

          {this.diferencaPreco()}

          {this.acoes()}

        </StyledTableRow>

      </>
    )
  }
}

export default ItemOrcamentoRow;
