export const PERFIL = {
  DESENVOLVEDOR: 'P317S1',
  DESENV_ATUALIZADOR: 'P317S2',
  GESTOR_TCU: 'P317S3',
  GESTOR_UNID_JURISDICIONADA: 'P317S4',
  OPERADOR: 'P317S5',
  OPERADOR_TCU: 'P317S6'
}

export const UNIDADE_USUARIO_EXTERNO = 'EXTERNO'
export const GESTOR_UNIDADE = { label: 'GESTOR_UNIDADE_JURISDICIONADA', codigo: 4 }
export const OPERADOR_UNIDADE = { label: 'OPERADOR_UNIDADE_JURISDICIONADA', codigo: 5 }
export const GESTOR = { label: 'GESTOR_TCU', codigo: 3 }
export const OPERADOR = { label: 'OPERADOR_TCU', codigo: 6 }
export const CONSULTA = { label: 'CONSULTA_TCU', codigo: 7 }
export const SUPERVISOR = { label: 'SUPERVISOR_TCU', codigo: 8 }

export const PERFIS_GESTOR_TCU = [
  {
    codigo: GESTOR_UNIDADE.codigo,
    tipo: 'ORGAO',
    nome: 'Gestor de unidade jurisdicionada',
    label: GESTOR_UNIDADE.label,
    descricao: 'Perfil concedido aos gestores das unidades jurisdicionadas'
  },
  {
    codigo: OPERADOR_UNIDADE.codigo,
    tipo: 'ORGAO',
    nome: 'Operador de unidade jurisdicionada',
    label: OPERADOR_UNIDADE.label,
    descricao: 'Perfil concedido aos operadores das unidades jurisdicionadas',
  },
  {
    codigo: GESTOR.codigo,
    tipo: 'COMUM',
    nome: 'Gestor do TCU',
    label: GESTOR.label,
    descricao: 'Perfil concedido aos gestores do sistema SAO no TCU'
  },
  {
    codigo: OPERADOR.codigo,
    tipo: 'COMUM',
    nome: 'Operador de TCU',
    label: OPERADOR.label,
    descricao: 'Perfil concedido aos operadores do TCU'
  },
  {
    codigo: CONSULTA.codigo,
    tipo: 'COMUM',
    nome: 'Consulta TCU',
    label: CONSULTA.label,
    descricao: 'Perfil concedido aos usuários do TCU que necessitam apenas consultar o sistema.'
  },
  {
    codigo: SUPERVISOR.codigo,
    tipo: 'COMUM',
    nome: 'Supervisor TCU',
    label: SUPERVISOR.label,
    descricao: 'Supervisiona orçamentos de órgãos e unidades jurisdicionadas'
  }
]

export const PERFIS_GESTOR_UJ = [
  {
    codigo: GESTOR_UNIDADE.codigo,
    tipo: 'ORGAO',
    nome: 'Gestor de unidade jurisdicionada',
    label: GESTOR_UNIDADE.label,
    descricao: 'Perfil concedido aos gestores das unidades jurisdicionadas'
  },
  {
    codigo: OPERADOR_UNIDADE.codigo,
    tipo: 'ORGAO',
    nome: 'Operador de unidade jurisdicionada',
    label: OPERADOR_UNIDADE.label,
    descricao: 'Perfil concedido aos operadores das unidades jurisdicionadas'
  }
]
