import React, { Component, Fragment } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import { context_httpget } from '../../../../../js/httpRequest'
import _ from 'lodash'
import { formatarNumeroParaMoedaBR, paginar } from '../../../../../js/util'
import styles from '../styles'
import Paginacao from '../../../../comum/Paginacao'
import ItemTransporteRow from './ItemTransporteRow'
import Grid from '@material-ui/core/Grid'
import BuscarComPaginacao from '../../../../comum/BuscarComPaginacao'
import TableSortLabel from '@material-ui/core/TableSortLabel'

const colunaCodigoInsumo = 'codigoInsumo'
const colunaCodigoComposicaoTransporte = 'codigoComposicaoTransporte'
const colunaDescricaoInsumo = 'descricaoInsumo'
const colunaDescricaoComposicaoTransporte = 'descricaoComposicaoTransporte'

export class Transportes extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    contexto: PropTypes.object.isRequired,
    onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      transportes: [],
      transportesCopia: [],
      termoBusca: '',
      colunaOrdenacao: '',
      direcaoOrdenacao: 'desc',
      totalRegistrosPorPagina: 20,
      apartirDoRegistro: 0,
      pagina: 0,
      totalResultadosElastic: 0,
      campoFiltro:'all'
    }
    this.recuperarTransportes = this.recuperarTransportes.bind(this)
  }

  componentDidMount () {
    if (!_.isNil(this.props.contexto)) {
      this.recuperarTransportes()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.termoBusca !== this.state.termoBusca) {
      this.buscar()
    }
  }

  async recuperarTransportes() {
    const { contexto } = this.props
    const data = await context_httpget('precos/composicoes/transportes/resumo', contexto)
    if (data) {
      this.setState({
        transportes: data,
        transportesCopia: data
      })
    }
  }

  ordernarTransportes = () => {
    const { transportes, colunaOrdenacao, direcaoOrdenacao } = this.state

    const transportesOrdenados = _.orderBy(transportes, [colunaOrdenacao], [direcaoOrdenacao])

    this.setState({
      transportes: transportesOrdenados
    })
  }

  handleChangeOrdenacaoColuna = (event, coluna) => {
    const { colunaOrdenacao, direcaoOrdenacao } = this.state

    let direcao
    if (colunaOrdenacao === coluna) {
      direcao = direcaoOrdenacao === 'desc' ? 'asc' : 'desc'
    } else {
      direcao = 'asc'
    }

    this.setState({
      colunaOrdenacao: coluna,
      direcaoOrdenacao: direcao
    }, () => this.ordernarTransportes())
  }

  handleChangeFilter = (event) => {
    const { value } = event.target
    this.setState({ campoFiltro: value })
  }

  handleChange = (event) => {
    const { value } = event.target
    this.setState({ termoBusca: value })
  }

  handleKeyPress = (event) => {
    const { key } = event

    if (key === 'Enter') {
      this.buscar()
    }
  }

  buscar = () => {
    const { termoBusca, transportesCopia, campoFiltro } = this.state

    if (_.isEmpty(termoBusca)) {
      this.setState({ transportes: transportesCopia })
    } else {
      const transportesFiltrados = transportesCopia.filter((transporte) => {
        return 'all' === campoFiltro ? transporte.tipoInsumo.toUpperCase().includes(termoBusca.toUpperCase())
          || transporte.codigoInsumo.toUpperCase().includes(termoBusca.toUpperCase())
          || transporte.descricaoInsumo.toUpperCase().includes(termoBusca.toUpperCase())
          || transporte.unidadeInsumo.toUpperCase().includes(termoBusca.toUpperCase())
          || transporte.tipoRodovia.toUpperCase().includes(termoBusca.toUpperCase())
          || transporte.codigoComposicaoTransporte.toUpperCase().includes(termoBusca.toUpperCase())
          || transporte.descricaoComposicaoTransporte.toUpperCase().includes(termoBusca.toUpperCase())
          || transporte.quantidadeOcorrencias.toString().includes(termoBusca.toUpperCase())
          || formatarNumeroParaMoedaBR(transporte.distancia, 2, 4).includes(termoBusca):
          'tipoRodovia' === campoFiltro ? transporte[campoFiltro].toString().toUpperCase() === termoBusca.toUpperCase():
            transporte[campoFiltro].toString().toUpperCase().includes(termoBusca.toUpperCase())
      })

      this.setState({
        transportes: transportesFiltrados,
        pagina: 0
      })
    }
  }

  render () {
    const { classes, onAtualizarItemCurvaABCComposicaoDuplicada } = this.props
    const { transportes, colunaOrdenacao, direcaoOrdenacao, pagina, totalRegistrosPorPagina, campoFiltro } = this.state

    return (
      <Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <BuscarComPaginacao
              placeholder='Buscar transporte'
              onChange={(e) => this.handleChange(e)}
              onKeyPress={(e) => this.handleKeyPress(e)}
              onClick={() => this.buscar()}
              disable={false}
              filterOptions={[
                { label:'Tipo insumo' ,value: 'tipoInsumo' },
                { label:'Código insumo' ,value: 'codigoInsumo' },
                { label:'Descrição insumo' ,value: 'descricaoInsumo' },
                { label:'Unidade insumo' ,value: 'unidadeInsumo' },
                { label:'Tipo transporte' ,value: 'tipoRodovia' },
                { label:'Código transporte' ,value: 'codigoComposicaoTransporte' },
                { label:'Descrição transporte' ,value: 'descricaoComposicaoTransporte' },
                { label:'Quantidade composições' ,value: 'quantidadeOcorrencias' }]}
              filterField={campoFiltro}
              onChangeFilter={(e) => this.handleChangeFilter(e)}
            />
          </Grid>
        </Grid>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell colSpan={4} style={{ borderBottom: '2px solid rgba(67,133,191,1)' }}>Insumo</TableCell>
              <TableCell colSpan={3} style={{ borderBottom: '2px solid rgba(0, 0, 0, 0.3)' }}>Serviço de Transporte</TableCell>
            </TableRow>
            <TableRow>
              <TableCell> Tipo </TableCell>
              <TableCell>
                <TableSortLabel
                  active={ colunaOrdenacao ===  colunaCodigoInsumo}
                  direction={ direcaoOrdenacao }
                  onClick={(e) => this.handleChangeOrdenacaoColuna(e, colunaCodigoInsumo) }
                >
                  Código
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={ colunaOrdenacao ===  colunaDescricaoInsumo}
                  direction={ direcaoOrdenacao }
                  onClick={(e) => this.handleChangeOrdenacaoColuna(e, colunaDescricaoInsumo) }
                >
                  Descrição
                </TableSortLabel>
              </TableCell>
              <TableCell> Unidade </TableCell>
              <TableCell> Tipo </TableCell>
              <TableCell>
                <TableSortLabel
                  active={ colunaOrdenacao ===  colunaCodigoComposicaoTransporte}
                  direction={ direcaoOrdenacao }
                  onClick={(e) => this.handleChangeOrdenacaoColuna(e, colunaCodigoComposicaoTransporte) }
                >
                  Código
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={ colunaOrdenacao ===  colunaDescricaoComposicaoTransporte}
                  direction={ direcaoOrdenacao }
                  onClick={(e) => this.handleChangeOrdenacaoColuna(e, colunaDescricaoComposicaoTransporte) }
                >
                  Descrição
                </TableSortLabel>
              </TableCell>
              <TableCell> Aparece em </TableCell>
              <TableCell> DMT Padrão </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {paginar(transportes, pagina, totalRegistrosPorPagina).map(transporte => (
              <ItemTransporteRow
                key={`${transporte.codigoComposicaoTransporte}_${transporte.codigoInsumo}_${transporte.tipoRodovia}`}
                transporte={transporte}
                onDadosAlterados={this.recuperarTransportes}
                onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
              />
            ))}
            {_.isEmpty(transportes) && <td colSpan="100%"><Alert style={{marginTop: 4, marginBottom: 4}} severity='warning'>Nenhum item foi encontrado.</Alert></td>}
          </TableBody>
        </Table>
        <Paginacao
          total={transportes.length}
          registrosPorPagina={totalRegistrosPorPagina}
          pagina={pagina}
          onChangePage={(e, pag) => { this.setState({ pagina: pag }) }}
          onChangeRowsPerPage={(e) => (this.setState({ totalRegistrosPorPagina: e.target.value })) }
        />
      </Fragment>
    )
  }
}

export default withStyles(styles)(Transportes)
