import React, { Component } from 'react'
import TablePagination from '@material-ui/core/TablePagination'
import PropTypes from 'prop-types'

class Paginacao extends Component {
  static propTypes = {
    total: PropTypes.number.isRequired,
    registrosPorPagina: PropTypes.number.isRequired,
    pagina: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onChangeRowsPerPage: PropTypes.func.isRequired
  }
  render () {
    const {
      total, registrosPorPagina, pagina, onChangePage, onChangeRowsPerPage
    } = this.props
    return (
      <TablePagination
        rowsPerPageOptions={[10, 20, 50]}
        component='div'
        count={total}
        rowsPerPage={registrosPorPagina}
        page={pagina}
        backIconButtonProps={{
          'aria-label': 'Página Anterior'
        }}
        nextIconButtonProps={{
          'aria-label': 'Próxima Página'
        }}
        labelRowsPerPage='Registros por página'
        labelDisplayedRows={({ from, to, count }) => (`${from}-${to}${count > 0 ? ` de ${count}` : ''}`)}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    )
  }
}

export default Paginacao
