import React, { useState } from 'react'
import Popover from '@material-ui/core/Popover'
import PropTypes from 'prop-types'
import { Box, Button, Paper, TextField } from '@material-ui/core'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import _ from 'lodash'
import { showSuccessMsg } from '../../js/util'

export default function FeedbackPopover ({ openFeedback, onclose, anchorEl }) {
  const [avaliacao, setAvaliacao] = useState('')
  const [avaliacaoEmpty, setAvaliacaoEmpty] = useState(false)

  const handleSalvarFeedback = async () => {
    if (_.isEmpty(avaliacao)) {
      setAvaliacaoEmpty(true)
      return
    }
    const data = await axios.post('/api/feedback', { avaliacao }, SLOW_REQUEST_CONFIG)
    if (data) {
      onclose()
      setAvaliacao('')
      setAvaliacaoEmpty(false)
      showSuccessMsg('Obrigado por avaliar nosso sistema.')
    }
  }

  return (
    <Popover
      open={openFeedback}
      onClose={onclose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}>
      <Paper style={{ width: '500px' }}>
        <Box p={3}>
          <TextField
            name='avaliacao'
            value={avaliacao}
            fullWidth
            multiline
            onChange={(e) => setAvaliacao(e.target.value)}
            placeholder='Como foi a sua experiência? Digite suas sugestões, críticas e elogios.'
            variant='outlined'
            rows={5}
            error={avaliacaoEmpty}
            helperText={avaliacaoEmpty ? 'Campo obrigatório' : ''}
            inputProps={{ maxLength: 500, 'aria-label': 'naked' }}
          />
          <Box
            width={1}
            pt={1}
            display='flex'
            justifyContent='flex-end'
            style={{ gap: '16px' }}>
            <Button onClick={onclose}>Cancelar</Button>
            <Button variant='contained' size='medium' color='primary' onClick={handleSalvarFeedback}>
              Enviar
            </Button>
          </Box>
        </Box>
      </Paper>
    </Popover>
  )
}

FeedbackPopover.propTypes = {
  openFeedback: PropTypes.bool.isRequired,
  onclose: PropTypes.func.isRequired,
  anchorEl: PropTypes.bool
}
