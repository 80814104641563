import React from 'react'
import { Box, Card, CardContent, Typography } from '@material-ui/core'
import ReactTooltip from 'react-tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import _ from 'lodash'
import PropTypes from 'prop-types'
import RegraDescritivaTipologia from '../../analise-conteudo/content/comum/tipologia/RegraDescritivaTipologia'

const LinhaItemTipologiaCombinadaVinculada = ({
  classes,
  codigo,
  nome,
  descricao,
  tipologia,
  onDesvincular
}) => {
  const { tipologia1, tipologia2, tipoTipologia1, tipoTipologia2 } = tipologia
  return (
    <React.Fragment>
      <Card elevation={0} className={classes.analiseCardItem}>
        <CardContent style={{ padding: 5 }}>
          <Box display='flex' justifyContent='space-between'>
            <div>
              <Typography variant='subtitle2'>
                {nome}
              </Typography>
              <Typography color='textSecondary' className={classes.cardTemaSidebarSubtitle}>
                {descricao}
              </Typography>
            </div>
            <Box display='flex' justifyContent='space-between' alignItems='center' style={{ cursor: 'pointer' }}>
              <a data-tip data-for={`excluirID${codigo}`} onClick={() => onDesvincular(codigo)}>
                <DeleteIcon color='action' style={{ marginRight: 5 }} />
              </a>
              <ReactTooltip id={`excluirID${codigo}`} place='top' type='dark' effect='solid'>Excluir</ReactTooltip>
            </Box>
          </Box>

          {/* tipologia combinada */}
          {!_.isNil(tipologia) && !_.isNil(tipologia1) && !_.isNil(tipologia2) && (
            <Box>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.cardTemaSidebarSubtitle} color='primary' style={{ marginRight: 5 }}>
                  {tipologia1.nome}
                </Typography>
                <RegraDescritivaTipologia
                  classes={classes}
                  tipoTipologia={tipoTipologia1}
                  tipologia={tipologia1}
                />
              </div>

              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Typography className={classes.cardTemaSidebarSubtitle} color='primary' style={{ marginRight: 5 }}>
                  {tipologia2.nome}
                </Typography>
                <RegraDescritivaTipologia
                  classes={classes}
                  tipoTipologia={tipoTipologia2}
                  tipologia={tipologia2}
                />
              </div>
            </Box>)
          }
        </CardContent>
      </Card>
    </React.Fragment>
  )
}

LinhaItemTipologiaCombinadaVinculada.propTypes = {
  classes: PropTypes.object,
  codigo: PropTypes.number,
  nome: PropTypes.string,
  descricao: PropTypes.string,
  tipologia: PropTypes.object,
  onDesvincular: PropTypes.func.isRequired
}

export default LinhaItemTipologiaCombinadaVinculada
