import React from 'react'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import Divider from '@material-ui/core/Divider/Divider'
import { DialogActions } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import TituloModal from './TituloModal'
import PropTypes from 'prop-types'

const style = { marginTop: 10 }

const ModalConfirmacao = ({
  open, titulo, onClose, children, minHeight, confirmar, disabled
}) => {
  const appliedStyle = minHeight ? { ...style, minHeight } : style

  return (
    <Dialog id='mdlConfirmacao' open={open} fullWidth disableEnforceFocus>
      <TituloModal onClose={onClose}>
        {titulo}
      </TituloModal>

      <Divider />

      <DialogContent style={appliedStyle}>
        {children}
      </DialogContent>

      <DialogActions>
        <Button id='btnCancelar' variant='outlined' color='primary' onClick={onClose}>Cancelar</Button>
        <Button id='btnConfirmar' variant='contained' color='primary' onClick={confirmar} disabled={disabled}>Confirmar</Button>
      </DialogActions>
    </Dialog>
  )
}

ModalConfirmacao.propTypes = {
  open: PropTypes.bool.isRequired,
  titulo: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  minHeight: PropTypes.number,
  confirmar: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default ModalConfirmacao
