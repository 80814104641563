import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Button, withStyles } from '@material-ui/core'

import PropTypes from 'prop-types'
import styles from './styles'
import axios from 'axios'
import fileDownload from 'js-file-download'

const BotaoNavegacao = ({
  text, onClick, classes, variant
}) => {

  return (
    <Button variant={variant} color='default' onClick={onClick} className={classes.btnPrincipal}>{text}</Button>
  )
}

BotaoNavegacao.propTypes = {
  text: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object
}

export { BotaoNavegacao }

class MenuBtnPrincipal extends Component {
  static propTypes = {
    match: PropTypes.object,
    history: PropTypes.object,
    classes: PropTypes.object,
    possuiABC: PropTypes.bool,
  }

  urlBase = () => {
    const { params } = this.props.match
    return `/empreendimentos/${params.idEmpreendimento}/obras/${params.idObra}/orcamentos/${params.idOrcamento}`
  }

  handleClick = (navegacao) => {
    this.props.history.push(`${this.urlBase()}/${navegacao}`)
  }

  async handleBaixarArquivoOriginal(relatorio) {
    const { data } = await axios.get(`/api${this.urlBase()}/relatorio`, { responseType: 'blob' })
    fileDownload(
      new Blob([data]),
      `${relatorio}.docx`
    )
  }


  render () {
    const { classes } = this.props

    return (
      <div>
        <BotaoNavegacao
          text='Dados Brutos'
          classes={classes}
          size={'small'}
          variant={'text'}
          onClick={() => this.handleClick('analisar')}
        />
        <BotaoNavegacao
          text='Comparar Orçamento'
          classes={classes}
          size={'small'}
          variant={'text'}
          onClick={() => this.handleClick('comparar')}
        />

        <BotaoNavegacao
            text='Curva ABC'
            classes={classes}
            size={'small'}
            variant={'text'}
            onClick={() => this.handleClick('abc')}
          />

        <BotaoNavegacao
            text='Curva ABC de Insumo'
            classes={classes}
            size={'small'}
            variant={'text'}
            onClick={() => this.handleClick('abc-insumo')}
          />

        <BotaoNavegacao
          text='Análise de conteúdo'
          classes={classes}
          size={'small'}
          variant={'text'}
          onClick={() => this.handleClick('analise-conteudo')}
        />

        <BotaoNavegacao
          text='Histórico'
          classes={classes}
          size={'small'}
          variant={'text'}
          onClick={() => this.handleClick('historico-orcamento')}
        />

        <BotaoNavegacao
          text='Preços'
          classes={classes}
          size={'small'}
          variant={'text'}
          onClick={() => this.handleClick('precos')}
        />

        <BotaoNavegacao
          text='Relatório'
          classes={classes}
          size={'small'}
          variant={'text'}
          onClick={() => this.handleBaixarArquivoOriginal('Relatorio')}
          display={(process.env.NODE_ENV === 'production')? 'none' :''}
        />
      </div>
    )
  }
}

export default withRouter(withStyles(styles)(MenuBtnPrincipal))
