import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import styles from '../styles'

const ReferenciaPreco = ({ classes }) => {
  return (
    <div className={classes.grupoPreco}>
      <Typography variant='h6' gutterBottom>Referência de preços </Typography>
      <Typography variant='body2'>Adicione as referências de preços que compõem esse orçamento</Typography>
    </div>
  )
}

ReferenciaPreco.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(ReferenciaPreco)