import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import TituloVoltar from '../../../../../../../comum/TituloVoltar'
import FormularioAdicionarMaterialComposicao from './FormularioAdicionarMaterialComposicao'
import AdicionarMaterialComposicaoSelecionarDoOrcamento from './AdicionarMaterialComposicaoSelecionarDoOrcamento'
import AdicionarMaterialComposicaoPesquisarEmReferenciasDePrecos from './AdicionarMaterialComposicaoPesquisarEmReferenciasDePrecos'

const ESTE_ORCAMENTO = 0
const CRIAR = 1
const REFERENCIA_PRECO = 2

class AdicionarMaterialComposicao extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      modo: 2
    }
  }

  render() {
    const { codigoComposicao, onDadosAlterados, onCancelar } = this.props
    const { modo } = this.state

    return (
      <>
        <TituloVoltar onClick={onCancelar} label='Vincular material a composição'/>
        <Box my={2}>
          <TextField variant='outlined' value={modo} select onChange={(e) => this.setState({ modo: e.target.value })} style={{minWidth: 500}}>
            <MenuItem value={REFERENCIA_PRECO}>Pesquisar em referências de preço</MenuItem>
            <MenuItem value={ESTE_ORCAMENTO}>Materiais deste orçamento</MenuItem>
            <MenuItem value={CRIAR}>Criar novo material</MenuItem>
          </TextField>
        </Box>

        {
          modo === CRIAR && (
            <FormularioAdicionarMaterialComposicao
              codigoComposicao={codigoComposicao}
              onCancelar={onCancelar}
              onDadosAlterados={onDadosAlterados}
            />
          )
        }
        {
          modo === ESTE_ORCAMENTO && (
            <AdicionarMaterialComposicaoSelecionarDoOrcamento
              codigoComposicao={codigoComposicao}
              onCancelar={onCancelar}
              onDadosAlterados={onDadosAlterados}
            />
          )
        }
        {
          modo === REFERENCIA_PRECO && (
            <AdicionarMaterialComposicaoPesquisarEmReferenciasDePrecos
              codigoComposicao={codigoComposicao}
              onCancelar={onCancelar}
              onDadosAlterados={onDadosAlterados}
              isPesquisaMateriaisEmTempoFixo={false}
            />
          )
        }
      </>
    )
  }
}

export default AdicionarMaterialComposicao
