import { colunas } from './colunas'

const exibicaoColunas = 'exibicaoColunas'

export const salvarExibicaoColunas = (colunas) => {
  try {
    localStorage.setItem(exibicaoColunas, JSON.stringify(colunas))
    return colunas
  } catch (err) {
    console.log(err) // eslint-disable-line no-console
    throw err
  }
}

export const removerExibicaoColunas = () => {
  try {
    localStorage.removeItem(exibicaoColunas)
    return exibicaoColunas
  } catch (err) {
    console.log(err) // eslint-disable-line no-console
    throw err
  }
}

export const recuperarExibicaoColunas = () => {
  try {
    const colunasExibir = localStorage.getItem(exibicaoColunas)
    return colunasExibir ? JSON.parse(colunasExibir) : { colunasExibir: colunas.filter(it => it.exibirInicio) }
  } catch (err) {
    console.log(err) // eslint-disable-line no-console
    throw err
  }
}
