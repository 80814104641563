import { withStyles } from '@material-ui/core'
import { DataGrid } from '@material-ui/data-grid'

const StyledDataGrid = withStyles({
  root: {
    '& .MuiDataGrid-renderingZone': {
      maxHeight: 'none !important'
    },
    '& .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      whiteSpace: 'normal',
      fontSize: '12px'
    },
    '& .MuiDataGrid-row': {
      maxHeight: 'none !important'
    }
  }
})(DataGrid)

export default StyledDataGrid
