import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import { converterMoedaBRParaNumero, formatarNumeroParaMoedaBR, handleCampoNumericoChange, mapStateToPropsContexto, seStringVazia, showSuccessMsg } from '../../../../../../../../js/util'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import TituloVoltar from '../../../../../../../comum/TituloVoltar'
import { context_httpput } from '../../../../../../../../js/httpRequest'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

class EditarComposicaoAuxiliar extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string.isRequired,
    atividadeAuxiliar: PropTypes.object.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired,
    contexto: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      quantidade: '',
      quantidadeEmpty: false
    }
    this.handleSalvarClick = this.handleSalvarClick.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  componentDidMount () {
    if (this.props.atividadeAuxiliar) {
      this.copiarDadosParaEdicao()
    }
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.atividadeAuxiliar !== this.props.atividadeAuxiliar) {
      this.copiarDadosParaEdicao()
    }
  }

  copiarDadosParaEdicao() {
    const { atividadeAuxiliar } = this.props
    this.setState({
      quantidade: formatarNumeroParaMoedaBR(atividadeAuxiliar.quantidade, 7, 7)
    })
  }

  handleCampoTextoChange (event, campo) {
    const state = {}
    state[campo] = event.target.value
    state[`${campo}Empty`] = seStringVazia(event.target.value)
    this.setState(state)
  }

  async handleSalvarClick () {
    const { quantidade } = this.state
    const quantidadeEmpty = seStringVazia(quantidade)

    if (quantidadeEmpty) {
      this.setState({ quantidadeEmpty })
    } else {
      const dadosSalvar = { quantidade: converterMoedaBRParaNumero(quantidade) }
      const { contexto, codigoComposicao, onDadosAlterados, atividadeAuxiliar } = this.props
      const url = `precos/editar-atividade-auxiliar?composicao=${codigoComposicao}&atividade-auxiliar=${atividadeAuxiliar.codigoAtividadeAuxiliar}`
      const resultado = await context_httpput(url, contexto, dadosSalvar)
      if (resultado) {
        showSuccessMsg('Dados da atividade auxiliar na composição alterados com sucesso.')
        onDadosAlterados()
      }
    }
  }

  
  handleKeyDown(event) {
    if (event.key === 'Enter') {
      this.handleSalvarClick()
    } else if (event.key === 'Escape') {
      this.props.onCancelar()
    }
  }

  render () {
    const { atividadeAuxiliar, onCancelar } = this.props
    const { quantidade, quantidadeEmpty } = this.state
    return (
      <div>
        <TituloVoltar onClick={onCancelar} label='Editar Atividade Auxiliar da Composição'/>
        <Box mt={2}>
          <Typography variant='overline'>
            { atividadeAuxiliar.codigoAtividadeAuxiliar } - { atividadeAuxiliar.atividadeAuxiliar.descricao }
          </Typography>
        </Box>
        <Box mt={2}>
          <FormControl variant='outlined'>
            <Typography variant='subtitle1'>Quantidade</Typography>
            <TextField
              variant='outlined'
              value={quantidade}
              error={quantidadeEmpty}
              onChange={(e) => handleCampoNumericoChange(e, (e) => this.handleCampoTextoChange(e, 'quantidade'))}
            />
          </FormControl>
        </Box>

        <Box mt={3}>
          <MesmaLinha mt={4}>
            <Button variant='contained' color='primary' onClick={this.handleSalvarClick}>Salvar</Button>
            <Button variant='text' color='primary' onClick={onCancelar}>Cancelar</Button>
          </MesmaLinha>
        </Box>
      </div>
    )
  }
}

export default connect(mapStateToPropsContexto)(EditarComposicaoAuxiliar)
