import React, { Component, Fragment } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { context_httpget } from '../../../../../js/httpRequest'
import _ from 'lodash'
import ItemEquipamentoRow from './ItemEquipamentoRow'
import { formatarNumeroParaMoedaBR, paginar } from '../../../../../js/util'
import Paginacao from '../../../../comum/Paginacao'
import Grid from '@material-ui/core/Grid'
import BuscarComPaginacao from '../../../../comum/BuscarComPaginacao'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import AdicionarEquipamentoAvulso from '../../relatorios/precos/composicao/insumos/equipamentos/AdicionarEquipamentoAvulso'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import StyledDrawer from '../../../../comum/StyledDrawer'

const colunaCodigo = 'equipamentoOrcamento.codigo'
const colunaDescricao = 'equipamentoOrcamento.descricao'

export class Equipamentos extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    contexto: PropTypes.object.isRequired,
    onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.func
  }

  state = {
    equipamentos: [],
    equipamentosCopia: [],
    termoBusca: '',
    colunaOrdenacao: '',
    direcaoOrdenacao: 'desc',
    totalRegistrosPorPagina: 20,
    apartirDoRegistro: 0,
    pagina: 0,
    campoFiltro: 'all',
    exibirDrawerAdicionarEquipamento: false,
  }

  componentDidMount () {
    if (!_.isNil(this.props.contexto)) {
      this.recuperarEquipamentos()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.termoBusca !== this.state.termoBusca) {
      this.buscar()
    }
  }

  async recuperarEquipamentos() {
    const { contexto } = this.props
    const data = await context_httpget('precos/insumos/equipamentos', contexto)
    if (data) {
      this.setState({
        equipamentos: data,
        equipamentosCopia: data
      })
    }
  }

  ordernarEquipamentos = () => {
    const { equipamentos, colunaOrdenacao, direcaoOrdenacao } = this.state

    const equipamentosOrdenados = _.orderBy(equipamentos, [colunaOrdenacao], [direcaoOrdenacao])

    this.setState({
      equipamentos: equipamentosOrdenados
    })
  }

  handleChangeOrdenacaoColuna = (event, coluna) => {
    const { colunaOrdenacao, direcaoOrdenacao } = this.state

    let direcao
    if (colunaOrdenacao === coluna) {
      direcao = direcaoOrdenacao === 'desc' ? 'asc' : 'desc'
    } else {
      direcao = 'asc'
    }

    this.setState({
      colunaOrdenacao: coluna,
      direcaoOrdenacao: direcao
    }, () => this.ordernarEquipamentos())
  }

  handleChangeFilter = (event) => {
    const { value } = event.target
    this.setState({ campoFiltro: value })
  }

  handleChange = (event) => {
    const { value } = event.target
    this.setState({ termoBusca: value })
  }

  handleKeyPress = (event) => {
    const { key } = event

    if (key === 'Enter') {
      this.buscar()
    }
  }

  handleChangeDrawerAdicionarEquipamento = () => this.setState({ exibirDrawerAdicionarEquipamento: !this.state.exibirDrawerAdicionarEquipamento })


  buscar = () => {
    const { termoBusca, equipamentosCopia, campoFiltro } = this.state

    if (_.isEmpty(termoBusca)) {
      this.setState({ equipamentos: equipamentosCopia })
    } else {
      const equipamentosFiltrados = equipamentosCopia.filter((equipamento) => {
        return 'all' === campoFiltro ? equipamento.equipamentoOrcamento.sistemaReferencia.toUpperCase().includes(termoBusca.toUpperCase())
          || `${equipamento.equipamentoOrcamento.mes}/${equipamento.equipamentoOrcamento.ano}`.includes(termoBusca.toUpperCase())
          || equipamento.equipamentoOrcamento.codigo.toUpperCase().includes(termoBusca.toUpperCase())
          || equipamento.equipamentoOrcamento.descricao.toUpperCase().includes(termoBusca.toUpperCase())
          || formatarNumeroParaMoedaBR(equipamento.equipamentoOrcamento.custoProdutivo, 2, 4).includes(termoBusca)
          || formatarNumeroParaMoedaBR(equipamento.equipamentoOrcamento.custoImprodutivo, 2, 4).includes(termoBusca):
          'mes/ano' === campoFiltro ? `${equipamento.equipamentoOrcamento.mes}/${equipamento.equipamentoOrcamento.ano}`.includes(termoBusca.toUpperCase()):
            'qtdComposicoes' === campoFiltro ?  equipamento[campoFiltro].toString().toUpperCase().includes(termoBusca.toUpperCase()):
              equipamento.equipamentoOrcamento[campoFiltro].toString().toUpperCase().includes(termoBusca.toUpperCase())
      })

      this.setState({
        equipamentos: equipamentosFiltrados,
        pagina: 0
      })
    }
  }


  render () {
    const { classes, onAtualizarItemCurvaABCComposicaoDuplicada } = this.props
    const { equipamentos, colunaOrdenacao, direcaoOrdenacao, pagina, totalRegistrosPorPagina, campoFiltro, exibirDrawerAdicionarEquipamento } = this.state

    return(
      <Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <BuscarComPaginacao
              placeholder='Buscar equipamento'
              onChange={(e) => this.handleChange(e)}
              onKeyPress={(e) => this.handleKeyPress(e)}
              onClick={() => this.buscar()}
              disable={false}
              filterOptions={[
                { label:'Sistema de Referência' ,value: 'sistemaReferencia' },
                { label:'Data referência' ,value: 'mes/ano' },
                { label:'Código' ,value: 'codigo' },
                { label:'Descrição' ,value: 'descricao' },
                { label:'Qtd composições' ,value: 'qtdComposicoes' }]}
              filterField={campoFiltro}
              onChangeFilter={(e) => this.handleChangeFilter(e)}
            />
          </Grid>
          <Grid item xs={12} md={9} container justify="flex-end">
            <Box mr={2} mt={3}>
              <Button
                variant="outlined"
                color="default"
                onClick={() => this.handleChangeDrawerAdicionarEquipamento()}>
              
                Adicionar Equipamento
              </Button>
            </Box>
          </Grid>
              </Grid>


        
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Referência</TableCell>
              <TableCell align='left'>Data Referência</TableCell>
              <TableCell>
                <TableSortLabel
                  active={ colunaOrdenacao ===  colunaCodigo}
                  direction={ direcaoOrdenacao }
                  onClick={(e) => this.handleChangeOrdenacaoColuna(e, colunaCodigo) }
                >
                  Código
                </TableSortLabel>
              </TableCell>
              <TableCell align='left'>
                <TableSortLabel
                  active={ colunaOrdenacao ===  colunaDescricao}
                  direction={ direcaoOrdenacao }
                  onClick={(e) => this.handleChangeOrdenacaoColuna(e, colunaDescricao) }
                >
                  Descrição
                </TableSortLabel>
              </TableCell>
              <TableCell align='left'>Custo Produtivo (R$/h)</TableCell>
              <TableCell align='left'>Custo improdutivo (R$/h)</TableCell>
              <TableCell> Aparece em </TableCell>
              <TableCell align='center'>Editar</TableCell>
              { 1+1===3 && (<TableCell align='right'>Ver Composição</TableCell>) }
            </TableRow>
          </TableHead>
          <TableBody>
            {paginar(equipamentos, pagina, totalRegistrosPorPagina).map((equipamento, index) => (
              <ItemEquipamentoRow
                key={equipamento.equipamentoOrcamento.codigo + index}
                equipamento={equipamento}
                onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
              />
            ))}
            {_.isEmpty(equipamentos) && <td colSpan="100%"><Alert style={{marginTop: 4, marginBottom: 4}} severity='warning'>Nenhum item foi encontrado.</Alert></td>}
          </TableBody>
        </Table>
        <Paginacao
          total={equipamentos.length}
          registrosPorPagina={totalRegistrosPorPagina}
          pagina={pagina}
          onChangePage={(e, pag) => { this.setState({ pagina: pag }) }}
          onChangeRowsPerPage={(e) => (this.setState({ totalRegistrosPorPagina: e.target.value })) }
        />

        {
          exibirDrawerAdicionarEquipamento && (
           
            <StyledDrawer
            PaperProps={{ className: classes.drawerEdicao }}
            open={true}
            anchor={'right'}>
            <AdicionarEquipamentoAvulso
              
              onDadosAlterados={this.handleDadosAlterados}
              onCancelar={() => this.setState({ exibirDrawerAdicionarEquipamento: false })}
            />
          </StyledDrawer>
            
          )
        }

      </Fragment>
    )
  }
}

export default (withRouter(Equipamentos))
