import React, { Component } from 'react'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import Tooltip from '@material-ui/core/Tooltip'
import InfoIcon from '@material-ui/icons/Info'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import StyledTableRow from '../comum/StyledTableRow'
import iconAnalise from '../../img/IconAnaliseOrc.svg'
import PropTypes from 'prop-types'
import { showAlertMsg } from '../../js/util'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import fileDownload from 'js-file-download'

class Orcamento extends Component {
  static propTypes = {
    value: PropTypes.object,
    codEmpreendimento: PropTypes.number,
    editar: PropTypes.func,
    duplicar: PropTypes.func,
    excluir: PropTypes.func,
    history: PropTypes.object,
  }

  constructor(props) {
    super(props);
    this.handleAnalisarClick = this.handleAnalisarClick.bind(this)
  }

  handleAnalisarClick() {
    const { value, history, codEmpreendimento } = this.props
    if (!value.concluido) {
      history.push(`/empreendimentos/${codEmpreendimento}/obras/${value.codObra}/orcamentos/${value.cod}`)
      showAlertMsg('Por favor, conclua o cadastro do orçamento')
    } else {
      if (value.possuiCurvaAbc) {
        history.push(`/empreendimentos/${codEmpreendimento}/obras/${value.codObra}/orcamentos/${value.cod}/abc`)
      } else {
        history.push(`/empreendimentos/${codEmpreendimento}/obras/${value.codObra}/orcamentos/${value.cod}/analisar`)
      }
    }
  }

  async handleBaixarArquivoOriginal(orcamento) {
    const { codEmpreendimento, value } = this.props
    const { data } = await axios.get(`/api/empreendimentos/${codEmpreendimento}/obras/${value.codObra}/orcamentos/${value.cod}/arquivo`, SLOW_REQUEST_CONFIG)
    const arquivo = data.map(it => it === 'EM_BRANCO' ? '' : it).join('\n')
    fileDownload(
      new Blob(['\ufeff', arquivo]),
      orcamento.nomeArquivo,
      'text/csv'
    )
  }

  render() {
    const { value, editar, excluir, duplicar } = this.props
    return (
      <StyledTableRow>
        <TableCell>
          <Tooltip title={value.sigiloso ? 'Sigiloso' : ''} placement='right-start'>
            <Button color='primary' variant='text'
              className={!value.sigiloso ? 'btn45' : 'btn45 backGrey'}
              onClick={this.handleAnalisarClick}>
              <img src={iconAnalise} alt={'Análise de Orçamento'} />
            </Button>
          </Tooltip>
        </TableCell>

        <TableCell>
            <Typography
              style={{ wordWrap: 'break-word', maxWidth: '500px' }}
              title={value.descricao} 
            >
              {value.descricao && value.descricao.slice(0, 400)}{value.numeroFase ? ' (Fase ' + value.numeroFase + ')' : ''}
            </Typography>
          </TableCell>

        <TableCell>
          <Typography>
            {
              value.tipoOrcamento ?
                value.tipoOrcamento
                  .replace('EDITAIS_LICITACOES', 'Editais/Licitações')
                  .replace('CONTRATOS_ADITIVOS', 'Contratos/Aditivos')
                  .replace('MEDICOES_FATURAS', 'Medições/Faturas')
                : ' - '
            }
          </Typography>
        </TableCell>

        <TableCell align='right'>
          <Typography>{value.valoresCalculados.valorInformadoFormatado}</Typography>
        </TableCell>

        <TableCell align='right'>
          <Typography>{value.valoresCalculados.valorCalculadoFormatado}</Typography>
        </TableCell>

        <TableCell align='right'>
          <Typography style={{ color: value.valoresCalculados.diferencaPreco < -0.001 ? 'red' : 'black' }}>
            {value.valoresCalculados.diferencaPrecoFormatada}
          </Typography>
        </TableCell>

        <TableCell align='center'>
          <Tooltip title={value.observacao || '-'}>
            <InfoIcon fontSize='small'/>
          </Tooltip>
        </TableCell>

        <TableCell>
          <IconButton color='primary' size='small' onClick={() => duplicar(value)}>
            <Tooltip title='Duplicar esse orçamento'>
              <FileCopyIcon fontSize='small' />
            </Tooltip>
          </IconButton>
          <IconButton color='primary' style={{marginX: 6}} size='small' onClick={() => this.handleBaixarArquivoOriginal(value)}>
            <Tooltip title='Baixar arquivo original'>
              <CloudDownloadIcon fontSize='small' />
            </Tooltip>
          </IconButton>
          <IconButton color='primary' style={{marginX: 6}} size='small' onClick={() => editar(value)}>
            <Tooltip title='Editar'>
              <EditIcon fontSize='small' />
            </Tooltip>
          </IconButton>
          <IconButton color='primary' size='small' onClick={() => excluir(value)}>
            <Tooltip title='Excluir'>
              <DeleteIcon fontSize='small' />
            </Tooltip>
          </IconButton>
        </TableCell>

      </StyledTableRow>
    )
  }
}

export default withRouter(Orcamento)
