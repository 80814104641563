import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import _ from 'lodash'
import {
  CRITERIO_COMPARACAO_NAO_PODE_SER,
  criterioComparacao,
  PERSONALIZADA,
  TIPO_COMPARACAO_ENTRE,
  TIPO_COMPARACAO_IGUAL_A,
  TIPO_COMPARACAO_SUPERIOR_A,
  TIPO_MEDIDA_DISPERSAO_DESVIO_PADRAO,
  TIPO_MEDIDA_DISPERSAO_SELECIONE,
  tipoComparacao,
  TIPOLOGIA_EXPRESSAO_PERSONALIZADA,
  TIPOLOGIA_EXPRESSAO_TEMATICA,
  TIPOLOGIA_INCIDENCIA_PERCENTUAL,
  tipoMedidaDispersao,
  variavelAnalise
} from '../../../../analise-conteudo'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import { withRouter } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import RegraTipologiaExpressao from './RegraTipologiaExpressao'
import { context_httpput } from '../../../../../../../../js/httpRequest'
import { seStringVazia, showSuccessMsg } from '../../../../../../../../js/util'
import styles from '../../../../styles'

const defaultState = {
  variavelAnalise: TIPOLOGIA_INCIDENCIA_PERCENTUAL,
  criterioComparacao: CRITERIO_COMPARACAO_NAO_PODE_SER,
  tipoComparacao: TIPO_COMPARACAO_SUPERIOR_A,
  valorComparacao: '',
  valorComparacaoEmpty: false,
  nome: '',
  nomeEmpty: false,
  descricao: '',
  descricaoEmpty: false,
  observacao: '',
  valorComparacaoEntre: '',
  valorComparacaoEntreEmpty: false,
  valorMedidaDispersao: '',
  valorMedidaDispersaoEmpty: false,
  tipoMedidaDispersao: TIPO_MEDIDA_DISPERSAO_SELECIONE
}

class ModalCriarTipologiaExpressao extends Component {
  static propTypes = {
    isModeReadOnly: PropTypes.bool,
    expressao: PropTypes.object,
    tipoAnaliseSelecionada: PropTypes.string.isRequired,
    onToggleModal: PropTypes.func.isRequired,
    onAtualizarExpressaoTipologia: PropTypes.func.isRequired,
    contexto: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object
  }
  constructor (props) {
    super(props)
    this.state = { ...defaultState }
    this.handleValidarCampoEntre = this.handleValidarCampoEntre.bind(this)
    this.handleConfirmar = this.handleConfirmar.bind(this)
    this.handleCampoTipologiaChange = this.handleCampoTipologiaChange.bind(this)
    this.handleCampoTipoComparacao = this.handleCampoTipoComparacao.bind(this)
  }

  componentDidMount () {
    if(this.props.expressao && !_.isNil(this.props.expressao.tipologia)) {
      this.copiarDadosExpressaoParaEstado()
    } else {
      this.setState({ ...defaultState, nome: `Verificação de ${this.props.expressao.nome}` })
    }
  }

  componentDidUpdate (prevProps) {
    if(prevProps.expressao !== this.props.expressao) {
      this.copiarDadosExpressaoParaEstado()
    }
  }

  copiarDadosExpressaoParaEstado() {
    const { expressao } = this.props
    this.setState({
      variavelAnalise: variavelAnalise(expressao.tipologia),
      criterioComparacao: criterioComparacao(expressao.tipologia),
      tipoComparacao: tipoComparacao(expressao.tipologia),
      valorComparacao: expressao.tipologia.valorComparacao.toString().replace('.', ','),
      tipoMedidaDispersao: tipoMedidaDispersao(expressao.tipologia),
      nome: expressao.tipologia.nome,
      descricao: expressao.tipologia.descricao,
      observacao: expressao.tipologia.observacao,
      valorComparacaoEntre: !_.isNil(expressao.tipologia.valorComparacaoEntre) ? expressao.tipologia.valorComparacaoEntre.toString().replace('.', ',') : '0',
      valorMedidaDispersao: !_.isNil(expressao.tipologia.valorMedidaDispersao) ? expressao.tipologia.valorMedidaDispersao.toString().replace('.', ',') : '0'
    })
  }

  validarFormulario() {
    const { valorComparacao, nome, descricao, valorComparacaoEntre, tipoComparacao, valorMedidaDispersao, tipoMedidaDispersao } = this.state
    const valorComparacaoEmpty = seStringVazia(valorComparacao)
    const nomeEmpty = seStringVazia(nome)
    const descricaoEmpty = seStringVazia(descricao)
    const valorComparacaoEntreEmpty = seStringVazia(valorComparacaoEntre) && tipoComparacao === TIPO_COMPARACAO_ENTRE
    const valorMedidaDispersaoEmpty = seStringVazia(valorMedidaDispersao) && tipoMedidaDispersao === TIPO_MEDIDA_DISPERSAO_DESVIO_PADRAO
    if (valorComparacaoEmpty || nomeEmpty || descricaoEmpty || valorComparacaoEntreEmpty || valorMedidaDispersaoEmpty) {
      this.setState({
        valorComparacaoEmpty,
        nomeEmpty,
        descricaoEmpty,
        valorComparacaoEntreEmpty,
        valorMedidaDispersaoEmpty
      })
      // eslint-disable-next-line no-throw-literal
      throw 'Formulário inválido. Campos obrigatórios não preenchidos'
    }
  }

  handleValidarCampoEntre() {
    const { valorComparacao, valorComparacaoEntre, tipoComparacao } = this.state
    if(tipoComparacao === TIPO_COMPARACAO_ENTRE && Number(valorComparacao) > Number(valorComparacaoEntre)) {
      this.setState({ valorComparacaoEmpty: true })
    } else {
      this.setState({ valorComparacaoEmpty: false })
    }
  }

  handleCampoChange(event, campo) {
    const state = {}
    state[campo] = event.target.value
    state[`${campo}Empty`] = seStringVazia(event.target.value)
    this.setState(state)
  }

  async handleConfirmar() {
    const {
      variavelAnalise,
      criterioComparacao,
      tipoComparacao,
      valorComparacao,
      valorComparacaoEntre,
      nome,
      descricao,
      observacao,
      tipoMedidaDispersao,
      valorMedidaDispersao
    } = this.state

    const { expressao, contexto, onAtualizarExpressaoTipologia } = this.props

    this.validarFormulario()
    const tipologia = {
      variavelAnalise: variavelAnalise.value,
      criterioComparacao: criterioComparacao.value,
      tipoComparacao: tipoComparacao.value,
      valorComparacao: valorComparacao.replace(',', '.'),
      valorComparacaoEntre: valorComparacaoEntre.replace(',', '.'),
      nome: nome,
      descricao: descricao,
      observacao: observacao
    }
    if(!_.isNil(tipoMedidaDispersao.value)) {
      tipologia.tipoMedidaDispersao = tipoMedidaDispersao.value
      tipologia.valorMedidaDispersao = valorMedidaDispersao.replace(',', '.')
    }
    const resultado = await context_httpput(`analise-conteudo/expressoes/${expressao.codigo}/tipologia?tipoAnalise=${this.definirTipoTipologia()}`, contexto, tipologia)
    if (resultado) {
      showSuccessMsg('Dados registrados')
      onAtualizarExpressaoTipologia(false, resultado)
    }
  }

  definirTipoTipologia = () => this.props.tipoAnaliseSelecionada === PERSONALIZADA ? TIPOLOGIA_EXPRESSAO_PERSONALIZADA : TIPOLOGIA_EXPRESSAO_TEMATICA

  handleCampoTipologiaChange(event, campo) {
    const valorCampo = event.target.value
    if(!_.isNil(valorCampo)) {
      const state = {}
      state[campo] = valorCampo
      state[`${campo}Empty`] = _.isEmpty(valorCampo)
      this.setState(state)
    }
  }

  handleCampoTipoComparacao(event, campo) {
    const state = {}
    state[campo] = event.target.value
    if(event.target.value !== TIPO_COMPARACAO_IGUAL_A.value){
      state['valorComparacaoEntre'] = '0'
      state['valorMedidaDispersao'] = '0'
      state['tipoMedidaDispersao'] = TIPO_MEDIDA_DISPERSAO_SELECIONE
    }
    this.setState(state)
  }

  render () {
    const { expressao, onToggleModal, isModeReadOnly } = this.props
    const {
      variavelAnalise,
      criterioComparacao,
      tipoComparacao,
      valorComparacao,
      valorComparacaoEmpty,
      nome,
      nomeEmpty,
      descricao,
      descricaoEmpty,
      observacao,
      valorComparacaoEntre,
      valorComparacaoEntreEmpty,
      tipoMedidaDispersao,
      valorMedidaDispersao,
      valorMedidaDispersaoEmpty
    } = this.state
    return (
      <Dialog open={Boolean(expressao)} maxWidth='lg' fullWidth>
        <DialogTitle>
          {_.isNil(expressao.tipologia) ? 'Criar Tipologia' : 'Alterar Tipologia'}
        </DialogTitle>
        <DialogContent>
          <Box my={2}>
            <Typography variant='overline'><strong>Expressão:</strong> { expressao.nome }</Typography>
          </Box>
          <Box my={2}>
            <FormControl fullWidth>
              <Typography gutterBottom>Nome da tipologia: </Typography>
              <TextField
                variant='outlined'
                value={nome || ''}
                error={nomeEmpty}
                onChange={(e) => this.handleCampoChange(e, 'nome')}
              />
            </FormControl>
          </Box>
          <Box my={2}>
            <Typography gutterBottom>Descrição: </Typography>
            <TextField
              variant='outlined'
              multiline
              rows={3}
              value={descricao || ''}
              error={descricaoEmpty}
              fullWidth
              inputProps={{ maxLength: 1000, 'aria-label': 'naked' }}
              onChange={(e) => this.handleCampoChange(e, 'descricao')}
            />
          </Box>
          <Box my={2}>
            <FormControl fullWidth>
              <Typography gutterBottom>Observação: </Typography>
              <TextField
                variant='outlined'
                multiline
                rows={3}
                value={observacao || ''}
                inputProps={{ maxLength: 2000, 'aria-label': 'naked' }}
                onChange={(e) => this.handleCampoChange(e, 'observacao')}
              />
            </FormControl>
          </Box>

          <RegraTipologiaExpressao
            variavelAnalise={variavelAnalise}
            criterioComparacao={criterioComparacao}
            tipoComparacao={tipoComparacao}
            valorComparacao={valorComparacao}
            valorComparacaoEmpty={valorComparacaoEmpty}
            tipoMedidaDispersao={tipoMedidaDispersao}
            valorMedidaDispersao={valorMedidaDispersao}
            valorMedidaDispersaoEmpty={valorMedidaDispersaoEmpty}
            valorComparacaoEntre={valorComparacaoEntre}
            valorComparacaoEntreEmpty={valorComparacaoEntreEmpty}
            onCampoChange={this.handleCampoTipologiaChange}
            onCampoTipoComparacaoChange={this.handleCampoTipoComparacao}
            onValidarCampoEntre={this.handleValidarCampoEntre}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onToggleModal(false)}>
            Cancelar
          </Button>
          <Button variant='contained' color='primary' onClick={(e) => this.handleConfirmar(e)} disabled={isModeReadOnly}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const mapStateToProps = ({ contexto }) => ({ contexto })

export default connect(mapStateToProps)(withRouter((withStyles(styles)(ModalCriarTipologiaExpressao))))
