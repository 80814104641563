import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/Warning'
import ModalConfirmacao from '../comum/ModalConfirmacao'
import PropTypes from 'prop-types'

const ModalExcluirOrcamento = ({ orcamento, fechar, confirmar }) => (
  <ModalConfirmacao
    open={orcamento != null}
    onClose={fechar}
    confirmar={confirmar}
    titulo={<Fragment>Excluir orçamento <WarningIcon color='inherit' className='vaMid'  /></Fragment>}>
    <Typography align='justify'>
      Confirma a exclusão do orçamento?
    </Typography>
  </ModalConfirmacao>
)

ModalExcluirOrcamento.propTypes = {
  orcamento: PropTypes.object,
  fechar: PropTypes.func,
  confirmar: PropTypes.func
}

export default ModalExcluirOrcamento