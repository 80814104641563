import React, { useState } from 'react'
import { Box, Card, CardContent, Grid, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Pagination } from '@material-ui/lab'
import _ from 'lodash'
import { formatarData, paginar } from '../../../../js/util'
import Button from '@material-ui/core/Button'
import EditIcon from '@material-ui/icons/Edit'

const ListarTemas = ({ temas, classes, onEditar }) => {
  const registrosPorPagina = 10
  const [page, setPage] = useState(1)
  const numeroDePaginas = Math.ceil(temas.length / registrosPorPagina)

  return (
    <React.Fragment>
      <Box mt={4}>
        <Box mb={2}>
          <Grid container spacing={1} wrap='nowrap'>
            <Grid item md={6}>
              <Typography variant='subtitle2'>Nome</Typography>
            </Grid>
            <Grid item md={2}>
              <Typography variant='subtitle2'>Unidade</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant='subtitle2'>Última Atualização</Typography>
            </Grid>
            <Grid item md={1} />
          </Grid>
        </Box>
        {paginar(temas, page - 1, registrosPorPagina).map((tema) => (
          <React.Fragment key={tema.codigo}>
            <Card elevation={0} className={classes.temaTable}>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item md={6} style={{ display: 'flex' }}>
                    <span className={tema.seDisponivel ? classes.expressaoDisponivel : classes.expressaoIndisponivel} />
                    <Typography variant='body1'>
                      {tema.nome}
                    </Typography>
                  </Grid>
                  <Grid item md={2}>
                    <Typography variant='body1'>
                      {tema.unidade.sigla}
                    </Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography variant='body1'>
                      {_.isNil(tema.dataAlteracao)
                        ? '-'
                        : formatarData(tema.dataAlteracao, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm').concat(` - ${tema.loginAlteracao}`)}
                    </Typography>
                  </Grid>
                  <Grid item md={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button color='primary' variant='outlined' className='btn36 mr8' onClick={() => onEditar(tema)}>
                      <EditIcon color='inherit' />
                    </Button>
                  </Grid>
                  <Grid item md={12}>
                    <Typography variant='body2' display='block'>{tema.descricao}</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </React.Fragment>
        ))}
      </Box>
      <Box mt={4}>
        <Pagination
          count={numeroDePaginas}
          defaultPage={1}
          page={page}
          onChange={(event, page) => setPage(page)}
          variant='outlined'
          shape='rounded'
        />
      </Box>
    </React.Fragment>
  )
}

ListarTemas.propTypes = {
  classes: PropTypes.object,
  temas: PropTypes.array,
  onEditar: PropTypes.func.isRequired
}

export default ListarTemas
