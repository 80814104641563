import React from 'react'
import { TableCell, TableHead, TableRow } from '@material-ui/core'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import PropTypes from 'prop-types'
import { seProducao } from '../../../../../js/util'

const colunaCodServico = 'codigo'
const colunaDescricao = 'descricao'

const CabecalhoComposicao = ({ colunaOrdenacao, direcaoOrdenacao, handleChange }) => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>
          <TableSortLabel
            active={colunaOrdenacao === colunaCodServico}
            direction={direcaoOrdenacao}
            onClick={(e) => handleChange(e, colunaCodServico)}
          >
            Cód. Serviço
          </TableSortLabel>
        </TableCell>
        <TableCell align='left'>
          <TableSortLabel
            active={colunaOrdenacao === colunaDescricao}
            direction={direcaoOrdenacao}
            onClick={(e) => handleChange(e, colunaDescricao)}
          >
            Descrição
          </TableSortLabel>
        </TableCell>
        <TableCell align='right'>Data Referência</TableCell>
        <TableCell align='right'>Auxiliar</TableCell>
        <TableCell align='right'>Excluir</TableCell>
      </TableRow>
    </TableHead>
  )
}

CabecalhoComposicao.propTypes = {
  classes: PropTypes.object,
  colunaOrdenacao: PropTypes.string,
  direcaoOrdenacao: PropTypes.string,
  handleChange: PropTypes.func
}

export default CabecalhoComposicao
