import React from 'react'
import { withStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import ResumoArquivo from './ResumoArquivo'
import styles from '../styles'

const DetalhesArquivo = (props) => {
  const { classes, arquivo } = props
  return (
    <>
      <Typography className={classes.nomeArquivo}>
        <span>{ arquivo.nome }</span><span>{arquivo.tamanho} bytes</span>
      </Typography>
      <ResumoArquivo resumo={arquivo.resumo} />
    </>
  )
}

DetalhesArquivo.propTypes = {
  classes: PropTypes.object,
  arquivo: PropTypes.object.isRequired
}

export default withStyles(styles)(DetalhesArquivo)