import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ReactTooltip from 'react-tooltip'
import ListItemText from '@material-ui/core/ListItemText'
import moment from 'moment'
import Divider from '@material-ui/core/Divider'

const ItensVinculadosExcluir = ({
  values,
  iconLabel: LabelIcon,
  iconText,
  showSecondaryTitle
}) => {
  return (
    <React.Fragment>
      {values.map((item) => (
        <Box key={item.codigo}>
          <ListItem key={item.codigo}>
            <ListItemIcon>
              <a data-tip data-for={`iconID${item.codigo}`}>
                <LabelIcon />
              </a>
              <ReactTooltip id={`iconID${item.codigo}`} place='top' type='dark' effect='solid'>
                {iconText}
              </ReactTooltip>
            </ListItemIcon>
            <ListItemText
              key={item.codigo}
              primary={`${item.nome}`}
              secondary={
                showSecondaryTitle
                  ? <Typography variant='caption'>Criada por: {item.loginInclusao} no dia {moment(item.dataInclusao, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY à\\s HH:mm')}</Typography>
                  : ''
              }
            />
          </ListItem>
          <Divider variant='inset' component='li' style={{ marginLeft: 0 }} />
        </Box>
      ))
      }
    </React.Fragment>
  )
}

ItensVinculadosExcluir.propTypes = {
  values: PropTypes.array,
  iconLabel: PropTypes.elementType.isRequired,
  iconText: PropTypes.string.isRequired,
  showSecondaryTitle: PropTypes.bool.isRequired
}

export default ItensVinculadosExcluir
