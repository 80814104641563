import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionDetails, Box, Button, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import StyledAccordionSumary from '../../../../comum/StyledAccordionSumary'
import SimplePopover from '../../../../comum/SimplePopover'
import NenhumResultadoEncontrado from '../../analise-conteudo/content/comum/NenhumResultadoEncontrado'
import LinhaItemVinculado from './LinhaItemVinculado'

const ExpressoesVinculadas = ({
  classes,
  expressoes,
  vincularExpressaoClick,
  onDesvincularExpressao
}) => {
  const [expandedPanel, setExpandedPanel] = useState(true)

  const handleChangePanel = (panel) => (event, newExpanded) => {
    setExpandedPanel(newExpanded ? panel : false)
  }

  const vincularExpressao = (event) => {
    event.stopPropagation()
    vincularExpressaoClick()
  }

  return (
    <Box mt={2}>
      <Accordion
        className={classes.accordionAnaliseConteudo}
        square
        expanded={expandedPanel === true}
        onChange={handleChangePanel(true)}
      >
        <StyledAccordionSumary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='accordionExpressoesTematicas'
          className={classes.accordionSummaryAnalise}
        >
          <Box display='flex' alignItems='center'>
            <Typography variant='subtitle2' className={classes.tituloAccordionAnaliseConteudo}>Expressões</Typography>
            <SimplePopover>
              Conjunto de critérios de pesquisa combinados logicamente para identificar ocorrências de determinados padrões nos itens de orçamento.
              Uma expressão pode ser utilizada para definir uma tipologia a ser considerada nas inconsistências do orçamento.
            </SimplePopover>
            <Box display='flex'>
              <Button variant='text' color='primary' style={{ marginRight: 5 }} onClick={(e) => vincularExpressao(e)}>
                <AddIcon fontSize='small' color='primary' /> Adicionar
              </Button>
            </Box>
          </Box>
        </StyledAccordionSumary>
        <AccordionDetails style={{ display: 'grid' }}>
          {expressoes.length === 0 && <NenhumResultadoEncontrado titulo='Nenhuma expressão encontrada' subtitulo='Vincule expressões personalizadas' />}
          {expressoes.map(expressao => (
            <LinhaItemVinculado
              key={expressao.codExpressao}
              classes={classes}
              codigo={expressao.codExpressao}
              nome={expressao.nomeExpressao}
              descricao={expressao.descricaoExpressao}
              tipologia={expressao.tipologia}
              isSubItem={expressao.subExpressao}
              onDesvincular={onDesvincularExpressao}
            />)
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

ExpressoesVinculadas.propTypes = {
  classes: PropTypes.object,
  expressoes: PropTypes.array.isRequired,
  vincularExpressaoClick: PropTypes.func.isRequired,
  onDesvincularExpressao: PropTypes.func.isRequired
}
export default ExpressoesVinculadas
