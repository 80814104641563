import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import ComboBox from '../../comum/ComboBox'
import { SISTEMAS_REFERENCIAS } from '../../../js/util'
import PropTypes from 'prop-types'

const Sistemas = ({ value, empty, onChange }) => (
  <ComboBox
    fullWidth={false}
    label='Sistema'
    value={value}
    empty={empty}
    onChange={onChange}
  >
    {
      SISTEMAS_REFERENCIAS.map((sistema) => (
        <MenuItem value={sistema.nome} key={sistema.nome}>
          <Typography>{sistema.nome}</Typography>
        </MenuItem>
      ))
    }
  </ComboBox>
)

Sistemas.propTypes = {
  value: PropTypes.string.isRequired,
  empty: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Sistemas
