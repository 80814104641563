import React, { Component } from 'react'
import { Box, Select, Typography } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { METODOSCALCULOSOBREPRECO } from '../../../../../js/util'
import PropTypes from 'prop-types'
import { context_httpput } from '../../../../../js/httpRequest'
import { connect } from 'react-redux'

class SelecaoMetodoCalculoSobrepreco extends Component {
  static propTypes = {
    valor: PropTypes.string,
    onChange: PropTypes.func,
    contexto: PropTypes.object,
    onChangeMetodoCalculoSobrepresso: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props);
    this.mudarMetodoCalculo = this.mudarMetodoCalculo.bind(this)
  }

  async mudarMetodoCalculo (evento) {
    const { valor, contexto } = this.props
    const novoMetodo = evento.target.value
    if (valor !== novoMetodo) {
      const metodo = METODOSCALCULOSOBREPRECO.find(it => it.sigla === novoMetodo)
      this.props.onChangeMetodoCalculoSobrepresso(evento.target.name, metodo)
      context_httpput('metodo-calculo-sobrepreco', contexto, novoMetodo)
        .then(() => this.props.onChange())
    }
  }

  render () {
    const { valor } = this.props
    return (
      <Box my={3}>
        <FormControl fullWidth>
          <Typography variant='subtitle2'> Método de Cálculo de Sobrepreço </Typography>
          <Select
            name={'metodoCalculoSobrepreco'}
            inputProps={{
              id: 'metodo'
            }}
            variant='outlined'
            value={valor}
            onChange={(e) => this.mudarMetodoCalculo(e)}>
            {
              METODOSCALCULOSOBREPRECO.map((met, i) => (
                <MenuItem key={i} value={met.sigla}>{met.descricao} - {met.sigla}</MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </Box>
    )
  }
}

const mapStateToProps = ({ contexto }) => ({ contexto })

export default connect(mapStateToProps)(SelecaoMetodoCalculoSobrepreco)
