import 'jspdf-autotable'
import _ from 'lodash'
import { pageHeaderAndFooter } from '../../precos/composicao/pdf/RelatorioComposicaoPdf'
import { iconCheck, iconInfo, resultadoTipologiaCombinada } from '../relatorio-analise-conteudo'

let count = 2
const tipologiasCombinadasMap = new Map()
export function tabelaTipologiasCombinadas (doc, tipologiasCombinadas, { posicaoX, posicaoY }) {
  const tableHeadTipologiasCombinadas = [[{ content: 'TIPOLOGIAS COMBINADAS' }]]
  const tableBodyTipologiasCombinadas = []

  tipologiasCombinadas.forEach((tipologiaCombinada) => {
    const regraTipologia = `    ${tipologiaCombinada.nomePrimeiraTipologia} ${tipologiaCombinada.operador} ${tipologiaCombinada.nomeSegundaTipologia}: ${resultadoTipologiaCombinada(tipologiaCombinada).descricao}`
    const linha = [{ content: tipologiaCombinada.nome, styles: { fontStyle: 'bold' } }]
    const linha2 = [{ content: tipologiaCombinada.descricao }]
    const linha3 = [{ content: regraTipologia }]
    tipologiasCombinadasMap.set(regraTipologia, resultadoTipologiaCombinada(tipologiaCombinada).possuiInconsistencia)
    tableBodyTipologiasCombinadas.push(linha, linha2, linha3)
  })

  if (_.isEmpty(tipologiasCombinadas)) {
    tableBodyTipologiasCombinadas.push([{ content: 'Nenhuma tipologia combinada encontrada' }])
  }

  posicaoY += 20
  doc.autoTable(
    {
      body: tableBodyTipologiasCombinadas,
      head: tableHeadTipologiasCombinadas,
      startY: posicaoY,
      tableWidth: 'auto',
      theme: 'plain',
      margin: {
        top: 20,
        right: 30,
        left: 30
      },
      bodyStyles: {
        fillColor: [245, 245, 245],
        textColor: [70, 70, 70],
        cellPadding: {
          top: 3,
          left: 5,
          right: 5,
          bottom: 3
        },
        tableLineColor: [210, 210, 210],
        tableLineWidth: 1
      },
      headStyles: {
        fillColor: [210, 210, 210],
        textColor: [70, 70, 70],
        0: {
          valign: 'middle',
          halign: 'left'
        }
      },
      didDrawPage: (data) => pageHeaderAndFooter(doc, data, 'Relatório de Análise Conteúdo'),
      didDrawCell: function (data) {
        if (data.column.index === 0 && data.cell.section === 'body' && data.row.index === count) {
          const possuiInconsistencia = tipologiasCombinadasMap.get(data.cell.raw.content)
          const textPos = data.cell
          doc.addImage(possuiInconsistencia ? iconInfo : iconCheck, textPos.x, textPos.y, 11, 11)
          count += 3
        }
      },
      willDrawCell: function (data) {
        if (data.column.index === 0 && data.cell.section === 'head') {
          data.cell.styles.halign = 'left'
        }
      }
    })
  return { posicaoX, posicaoY: doc.lastAutoTable.finalY }
}
