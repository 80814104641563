import React from 'react'
import Typography from '@material-ui/core/Typography'
import PropsTypes from 'prop-types'
import { Box, Card, CardContent } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

const NenhumResultadoEncontrado = ({ titulo, subtitulo }) => {
  return (
    <div>
      <Card elevation={0}>
        <CardContent style={{ padding: 5 }}>
          <Typography variant='subtitle2'>{titulo}</Typography>
          <Box display='flex' alignItems='center'>
            <ErrorOutlineIcon color='action' fontSize='small' style={{ marginRight: 5 }} />
            <Typography variant='body2' color='textSecondary' style={{ fontSize: 14 }}>{subtitulo}</Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  )
}

NenhumResultadoEncontrado.propTypes = {
  titulo: PropsTypes.string,
  subtitulo: PropsTypes.string
}

export default NenhumResultadoEncontrado
