import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import { getAuthentication } from '../security/securityContext'
import App from './App'
import PropTypes from 'prop-types'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core'
import LabelIcon from '@material-ui/icons/Label'

const styles = (theme) => ({
  containerWhite: {
    backgroundColor: '#fff',
    borderRadius: 4,
    margin: '20px 0 0',
    padding: 30,
    width: '60%'
  },
  tituloSobre: {
    color: '#1C519B',
    marginBottom: 20,
  },
  texto: {
    fontSize: 18,
    lineHeight: '28px',
    color: 'rgba(74, 74, 74, 0.87)'
  },
  iconeLista: {
    background: 'none',
    color: theme.palette.primary.main,
  },
  subTitulo: {
    color: '#4A4A4A',
    fontSize: 20,
    margin: '10px 0'
  }
})

class Sobre extends Component {
  static propTypes = {
    classes: PropTypes.object
  }

  render () {
    const authentication = getAuthentication()
    if (!authentication || !authentication.isUserAuthenticated) {
      return <Redirect to='/login'/>
    }
    const { classes } = this.props
    return (
      <App noAppToolbar={false}>

        <div className={classes.containerWhite}>
          <Typography variant='h4' className={classes.tituloSobre}>O que é o SAO?</Typography>
          <Typography variant='span' className={classes.texto}>O SAO é uma ferramenta de auxílio a Análise de Orçamentos da Administração Pública. As principais funcionalidades incluem:</Typography>
          <List>
              <ListItem>
                <ListItemAvatar>
                  <Avatar className={classes.iconeLista}>
                    <LabelIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>Avaliação e melhoria da descrição, código, unidade e preço dos serviços existentes em uma planilha para fins de agrupamentos e Geração de sua Curva ABC (análise de Pareto);</ListItemText>
              </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar className={classes.iconeLista}>
                  <LabelIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Geração da Curva ABC (análise de Pareto) de serviços;</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar className={classes.iconeLista}>
                  <LabelIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Identificação de inconsistências orçamentárias (erro de cálculo, uso de verbas, busca por expressão regular de serviços antieconômicos ou em duplicidade etc.);</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar className={classes.iconeLista}>
                  <LabelIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Auxílio a identificação de Sobrepreço;</ListItemText>
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Avatar className={classes.iconeLista}>
                  <LabelIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Análise de risco de conjuntos de orçamentos (maior quantidade e relevância de inconsistências).</ListItemText>
            </ListItem>
          </List>
          <Typography variant='span' className={classes.texto}>OBS: O SAO não foi concebido para elaboração de orçamentos. Para esse fim, utilize ferramentas especializadas.</Typography>
          <Typography variant='h6' className={classes.subTitulo}>Quem pode usar o SAO?</Typography>
          <Typography variant='span' className={classes.texto}>O SAO foi desenvolvido especialmente para auxílio nas auditorias do Tribunal de Contas da União. Dessa forma, todos os auditores ou prestadores de serviços/estagiários possuem permissão de uso para os empreendimentos cadastrados na sua unidade.</Typography>
        </div>

      </App>
    )
  }
}

export default withStyles(styles)(Sobre)
