import React from 'react'
import PropTypes from 'prop-types'
import { formatarNumeroParaMoedaBR, SISTEMAS_REFERENCIAS } from '../../../../../../../js/util'
import { Divider } from '@material-ui/core'

const ResumoTabelaMaoDeObra = ({
  classes,
  insumos,
  sistemaReferencia
}) => {
  const adicionalMaoDeObra = () => insumos.adicionalMaoDeObra / 100 * insumos.custoHorarioTotalMaosDeObra
  return (
    <>
      <div className={classes.resumo}>
        <b className={classes.marginTitle} >Custo Horário Total de Mão de Obra</b>
        <b className={classes.lastColumn}>{formatarNumeroParaMoedaBR(insumos.custoHorarioTotalMaosDeObra, 2, 4)}</b>
      </div>
      {sistemaReferencia === SISTEMAS_REFERENCIAS[0].nome &&
        <div className={classes.resumo}>
          <b className={classes.marginTitle}>Adc.M.O. - Ferramentas: {insumos.adicionalMaoDeObra}%</b>
          <b className={classes.lastColumn}>{formatarNumeroParaMoedaBR(adicionalMaoDeObra(), 2, 4)}</b>
        </div>
      }
      <div className={classes.resumo}>
        <b className={classes.marginTitle}>Custo Horário Total de Execução</b>
        <b className={classes.lastColumn}>{formatarNumeroParaMoedaBR(insumos.custoHorarioTotalExecucao, 2, 4)}</b>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.resumo}>
        <b className={classes.marginTitle}>Custo Unitário de Execução</b>
        <b className={classes.lastColumn}>{formatarNumeroParaMoedaBR(insumos.custoUnitarioExecucao, 2, 4)}</b>
      </div>
      <div className={classes.resumo}>
        <b className={classes.marginTitle}>Custo do FIC ({formatarNumeroParaMoedaBR(insumos.valorFIC, 2, 8)})</b>
        <b className={classes.lastColumn}>{formatarNumeroParaMoedaBR(insumos.custoFIC, 2, 6)}</b>
      </div>
      <div className={classes.resumo}>
        <b className={classes.marginTitle}>Custo do FIT ({formatarNumeroParaMoedaBR(insumos.valorFIT/100, 2, 8)})</b>
        <b className={classes.lastColumn}>{formatarNumeroParaMoedaBR(insumos.custoFIT, 2, 6)}</b>
      </div>
    </>
  )
}

ResumoTabelaMaoDeObra.propTypes = {
  insumos: PropTypes.object.isRequired,
  sistemaReferencia: PropTypes.string,
  classes: PropTypes.object
}

export default ResumoTabelaMaoDeObra
