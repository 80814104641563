import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { context_httpget } from '../../../../../../../js/httpRequest'
import DadosBasicosFormula from './DadosBasicosFormula'
import Parametros from './parametros/Parametros'
import withStyles from '@material-ui/core/styles/withStyles'
import { connect } from 'react-redux'
import styles from '../../../styles'
import FormulaInvalida from './FormulaInvalida'
import ResultadoFormula from './ResultadoFormula'

const Formula = ({
  classes,
  contexto,
  formulaAtiva,
  tipoAnaliseSelecionada,
  onFormulaChange,
  onCriarTipologia,
  onFecharDrawer
}) => {
  const [resultado, setResultado] = useState({})
  const [parametros, setParametros] = useState([])
  const [expressoesDisponiveis, setExpressoesDisponiveis] = useState([])
  const [formulasDisponiveis, setFormulasDisponiveis] = useState([])

  useEffect(() => {
    formulaAtiva && executarFormula()
  }, [formulaAtiva])

  useEffect(() => {
    onFormulaChange(formulaAtiva)
  }, [resultado, parametros])

  const executarFormula = async () => {
    const { resultado, parametros, expressoesDisponiveis, formulasDisponiveis } = await context_httpget(`analise-conteudo/formulas/${formulaAtiva.codigo}/play?tipoAnalise=${tipoAnaliseSelecionada}`, contexto)
    setResultado(resultado)
    setParametros(parametros)
    setExpressoesDisponiveis(expressoesDisponiveis)
    setFormulasDisponiveis(formulasDisponiveis)
  }

  const handleAtualizarResultadoFormulaChange = (formulaAtiva, resultado, parametros) => {
    setResultado(resultado)
    setParametros(parametros)
  }

  return (
    <React.Fragment>
      <DadosBasicosFormula
        classes={classes}
        formulaAtiva={formulaAtiva}
        onFormulaChange={onFormulaChange}
        onFecharDrawer={onFecharDrawer}
      />
      {formulaAtiva && (
        <React.Fragment>
          <Parametros
            formula={formulaAtiva}
            parametros={parametros}
            expressoesDisponiveis={expressoesDisponiveis}
            formulasDisponiveis={formulasDisponiveis}
            onFormulaChange={handleAtualizarResultadoFormulaChange}
            tipoAnaliseSelecionada={tipoAnaliseSelecionada}
          />
          {!_.isEmpty(resultado.error) && <FormulaInvalida mensagem={resultado.error} />}
          <ResultadoFormula
            classes={classes}
            value={resultado}
            formula={formulaAtiva}
            possuiParametros={parametros.length > 0}
            exibirOpcaoCriarTipologia
            onCriarTipologia={onCriarTipologia}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

Formula.propTypes = {
  classes: PropTypes.object,
  tipoAnaliseSelecionada: PropTypes.string.isRequired,
  formulaAtiva: PropTypes.object,
  contexto: PropTypes.object,
  onFecharDrawer: PropTypes.func,
  onFormulaChange: PropTypes.func.isRequired,
  onCriarTipologia: PropTypes.func.isRequired
}

const mapStateToProps = ({ contexto }) => ({ contexto })
export default connect(mapStateToProps)(withStyles(styles)(Formula))
