import React from 'react'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import RelatorioComposicao from './RelatorioComposicao'
import PropTypes from 'prop-types'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const ModalComposicaoAuxiliar = ({ composicao, fechar, onAtualizarItemCurvaABCComposicaoDuplicada }) => {
  return composicao && (
    <Dialog
      open
      scroll='body'
      fullWidth
      maxWidth='xl'
      onClose={fechar}
      aria-labelledby='form-dialog-title'>

      <IconButton onClick={fechar} style={{ float: 'right' }}>
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <RelatorioComposicao
          composicao={composicao}
          permitirEdicao
          onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
        />
      </DialogContent>

      <DialogActions>
        <Button variant='outlined' onClick={fechar}>Fechar</Button>
      </DialogActions>
    </Dialog>
  )
}

ModalComposicaoAuxiliar.propTypes = {
  composicao: PropTypes.object,
  fechar: PropTypes.func.isRequired,
  onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.func
}

export default ModalComposicaoAuxiliar
