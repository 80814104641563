import React, { Component } from 'react'
import TableCell from '@material-ui/core/TableCell'
import styles from '../styles'
import PropTypes from 'prop-types'
import MesmaLinha from '../../../../comum/MesmaLinha'
import IconeErro from '../../../../comum/IconeErro'
import { CircularProgress, withStyles } from '@material-ui/core'
import { temSobrepreco } from '../curva-abc'
import { formatarNumeroParaMoedaBR } from '../../../../../js/util'

class CelulaSobreprecoCurvaAbc extends Component {
  static propTypes = {
    onExibirDetalhesSobreprecoChange: PropTypes.func.isRequired,
    hideLoadingPrecos: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    exibir: PropTypes.bool.isRequired,
    metodoCalculo: PropTypes.object,
    classes: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (event) {
    event.preventDefault()
    const { item, onExibirDetalhesSobreprecoChange  } = this.props
    onExibirDetalhesSobreprecoChange(item)
  }

  valorExibir (valorSobrepreco) {
    const { metodoCalculo } = this.props
    return temSobrepreco(valorSobrepreco, metodoCalculo)
      ? formatarNumeroParaMoedaBR(valorSobrepreco, 2, 2)
      : ' - '
  }

  render () {
    const { item, classes, exibir, metodoCalculo, hideLoadingPrecos } = this.props
    return exibir && (
      <TableCell align='right'>
        {
          item && (
            <MesmaLinha className={temSobrepreco ? classes.erroSublinhado : ''}>
              {
                hideLoadingPrecos
                  ? (
                    <>
                      <IconeErro exibir={temSobrepreco(item.valorSobrepreco, metodoCalculo)} mensagem='Sobrepreço' onClick={this.handleClick}/>
                      { this.valorExibir(item.valorSobrepreco) }
                    </>
                    )
                  : <CircularProgress size={20} />
              }
            </MesmaLinha>
          )
        }
      </TableCell>
    )
  }
}

export default withStyles(styles)(CelulaSobreprecoCurvaAbc)
