import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core'

const headCells = [
  { id: 'codServico', numeric: false, disablePadding: false, label: 'Composição', sort: true },
  { id: 'unidade', numeric: false, disablePadding: false, label: 'Unidade', sort: false },
  { id: 'descricao', numeric: false, disablePadding: false, label: 'Descrição', sort: true },
  { id: 'ocorrencia', numeric: false, disablePadding: false, label: 'Ocorrência', sort: true },
  { id: 'valorImpacto', numeric: false, disablePadding: false, label: 'Impacto (R$)', sort: true },
  { id: 'orc', numeric: false, disablePadding: false, label: 'Orç', sort: false },
  { id: 'quantidade', numeric: true, disablePadding: false, label: 'Quantidade', sort: false },
  { id: 'preco_unitario', numeric: true, disablePadding: false, label: 'Preço unitário', sort: false },
  { id: 'vl_total', numeric: true, disablePadding: false, label: 'Valor total', sort: false },
  { id: 'percent_preco_unitario', numeric: true, disablePadding: false, label: 'Dif %Preço unitário', sort: false },
]

const TableHeadCompararOrcamento = ({ onRequestSort, order, orderBy }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property)
  }

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.sort ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>) : headCell.label
            }
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

TableHeadCompararOrcamento.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
}

export default TableHeadCompararOrcamento
