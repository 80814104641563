import React from 'react'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import styles from '../../styles'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'

const CampoQuantidadeParametrica = ({
  classes,
  quantidadeParametrica,
  quantidadeParametricaEmpty,
  onQuantidadeParametricaChange,
  disabled
}) => {
  return (
    <FormControl variant='outlined'>
      <Typography variant='subtitle1'>Quantidade Paramétrica</Typography>
      <TextField
        disabled={disabled}
        variant='outlined'
        className={classes.campoFormulario}
        value={quantidadeParametrica || ''}
        onChange={onQuantidadeParametricaChange}
        error={quantidadeParametricaEmpty}
      />
    </FormControl>
  )
}

CampoQuantidadeParametrica.propTypes = {
  classes: PropTypes.object,
  quantidadeParametrica: PropTypes.string,
  quantidadeParametricaEmpty: PropTypes.bool,
  onQuantidadeParametricaChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default withStyles(styles)(CampoQuantidadeParametrica)
