import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import { BUSCAR_TIPOS_OBRA } from '../types'

const buscarTiposObra = () => async (dispatch) => {
  const success = await axios.get('/api/empreendimentos/obras/tipos', SLOW_REQUEST_CONFIG)
  return dispatch({
    type: BUSCAR_TIPOS_OBRA,
    payload: success
  })
}

export default buscarTiposObra
