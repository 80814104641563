import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { authenticateSiga, recuperarUrlSiga } from '../../actions/authenticationJwtActions'
import styles from './LoginStyles'
import Proptypes from 'prop-types'

export class LoginSiga extends Component {
  static propTypes = {
    recuperarUrlSiga: Proptypes.func,
    authentication: Proptypes.object,
    authenticateSiga: Proptypes.func,
  }
  componentDidMount() {
    this.props.recuperarUrlSiga().then((url) => {
      const { isUserAuthenticated } = this.props.authentication
      if (!isUserAuthenticated && url.data) {
        this.props.authenticateSiga(url.data)
      }
    })
  }

  render() {
    const { from } = { from: { pathname: '/' } }
    const { isUserAuthenticated } = this.props.authentication

    return isUserAuthenticated ? <Redirect to={from} /> : null
  }
}

const materialUIEnhance = withStyles(styles)(LoginSiga)

const mapStateToProps = ({ authentication, parametrosGlobais }) => ({
  authentication,
  parametrosGlobais,
})

export default connect(mapStateToProps, { authenticateSiga, recuperarUrlSiga })(
  materialUIEnhance
)
