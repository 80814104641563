import { BUSCAR_TIPOS_UNIDADE_USUARIO } from '../actions/types'

const reducerTiposUnidade = (state = [], action) => {
  switch (action.type) {
    case BUSCAR_TIPOS_UNIDADE_USUARIO:
      return action.payload.data
    default:
      return state
  }
}

export default reducerTiposUnidade
