import React, { createContext, useContext, useState } from 'react'
import PropTypes from 'prop-types'

export const CompositionDefaultValues = {
  compositionState: {
    codigo: '',
    codigoEmpty: false,
    descricao: '',
    descricaoEmpty: false,
    unidade: '',
    unidadeEmpty: false,
    producao: '',
    producaoEmpty: false,
    fic: '',
    ficEmpty: false,
    fit: '',
    fitEmpty: false,
    mes: '',
    mesEmpty: false,
    ano: '',
    anoEmpty: false,
    uf: '',
    ufEmpty: false,
    sistemaReferencia: 'PESQUISA',
    observacao: '',
    duplicarComposicao: false,
    precoUnitarioOrcamento: ''
  },
  setDefaultState: () => null,
  changeField: () => null,
  onDadosAlterados: () => {},
  onComposicaoAdicionada: () => {},
  onAtualizarItemCurvaABCComposicaoDuplicada: () => {}
}

export const CompositionContext = createContext(
  CompositionDefaultValues
)

const CompositionProvider = ({ children, onDadosAlterados, onComposicaoAdicionada, onAtualizarItemCurvaABCComposicaoDuplicada }) => {
  const [defaultState, setDefaultState] = useState({
    codigo: '',
    codigoEmpty: false,
    descricao: '',
    descricaoEmpty: false,
    unidade: '',
    unidadeEmpty: false,
    producao: '',
    producaoEmpty: false,
    fic: '',
    ficEmpty: false,
    fit: '',
    fitEmpty: false,
    mes: '',
    mesEmpty: false,
    ano: '',
    anoEmpty: false,
    uf: '',
    ufEmpty: false,
    sistemaReferencia: 'PESQUISA',
    observacao: '',
    duplicarComposicao: false,
    precoUnitarioOrcamento: ''
  })

  const changeField = (param, value) => {
    setDefaultState(prevState => ({
      ...prevState,
      [param]: value
    }))
  }

  return (
    <CompositionContext.Provider value={{
      compositionState: defaultState,
      setDefaultState,
      changeField,
      onDadosAlterados,
      onComposicaoAdicionada,
      onAtualizarItemCurvaABCComposicaoDuplicada
    }}>
      {children}
    </CompositionContext.Provider>
  )
}

CompositionProvider.propTypes = {
  children: PropTypes.node,
  onDadosAlterados: PropTypes.func,
  onComposicaoAdicionada: PropTypes.func,
  onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.func
}

const useComposition = () => useContext(CompositionContext)

export { CompositionProvider, useComposition }
