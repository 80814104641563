import React, { Component } from 'react'
import { connect } from 'react-redux'
import Template from '../../../layout/Template'
import PropTypes from 'prop-types'
import Carregando from '../../../comum/Carregando'
import _ from 'lodash'
import { context_httpget, context_httppost } from '../../../../js/httpRequest'
import Cabecalho from './Cabecalho'
import MenuOrcamentoBruto from './MenuOrcamentoBruto'
import BuscarComPaginacao from '../../../comum/BuscarComPaginacao'
import Adicionar from '../../../comum/Adicionar'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import { urlItens, urlPesquisa } from './analisar-orcamento'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TitleTableCell from '../../../comum/TitleTableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TabelaItensOrcamento from './TabelaItensOrcamento'
import Paginacao from '../../../comum/Paginacao'
import { showSuccessMsg } from '../../../../js/util'
import limparFiltroAnalise from '../../../../actions/orcamentos/analise/limparFiltroAnaliseOrcamento'
import { Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'

export class AnalisarOrcamento extends Component {
  static propTypes = {
    contexto: PropTypes.object,
    filtroAnalise: PropTypes.object,
    limparFiltroAnalise: PropTypes.func
  }

  constructor (props) {
    super(props)
    this.state = {
      itens: null,
      resumoOrcamento: null,
      termoBusca: '',
      itemPesquisado: false,
      pagina: 0,
      registrosPorPagina: 20,
      paginaBackend: 0,
      colunaOrdenacao: '',
      direcaoOrdenacao: ''
    }
    this.handleItemPesquisa = this.handleItemPesquisa.bind(this)
    this.handleKeyPressSearch = this.handleKeyPressSearch.bind(this)
    this.limparPesquisa = this.limparPesquisa.bind(this)
    this.adicionarLinha = this.adicionarLinha.bind(this)
    this.handleColunaQuantidadeClick = this.handleColunaQuantidadeClick.bind(this)
    this.handleColunaPrecoUnitarioClick = this.handleColunaPrecoUnitarioClick.bind(this)
    this.handleColunaValorParcialClick = this.handleColunaValorParcialClick.bind(this)
    this.handleColunaDiferencaCalculoClick = this.handleColunaDiferencaCalculoClick.bind(this)
    this.pesquisarItemOrcamento = this.pesquisarItemOrcamento.bind(this)
    this.handleItemAlterado = this.handleItemAlterado.bind(this)
    this.handleItemExcluido = this.handleItemExcluido.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleRowsPerPageChange = this.handleRowsPerPageChange.bind(this)
  }

  componentDidMount () {
    if (!_.isNil(this.props.contexto) && !_.isNil(this.props.contexto.orcamento)) {
      const filtroDescricao = this.props.filtroAnalise.descricao
      if(!_.isNil(filtroDescricao)) {
        this.setState({ termoBusca: filtroDescricao }, this.pesquisarItemOrcamento)
        this.props.limparFiltroAnalise()
      } else {
        this.recuperarItensOrcamento()
      }
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.contexto !== this.props.contexto) {
      this.recuperarItensOrcamento()
    }
  }

  async recuperarItensOrcamento () {
    const { contexto } = this.props
    const { itens, paginaBackend, colunaOrdenacao, direcaoOrdenacao } = this.state
    const itensComResumo = await context_httpget(urlItens(paginaBackend, colunaOrdenacao, direcaoOrdenacao), contexto)

    const itensRetornados = _.isNil(itens) ? itensComResumo.itens : itens.concat(itensComResumo.itens)

    this.setState({
      itens: itensRetornados,
      resumoOrcamento: itensComResumo.resumoOrcamento,
      paginaBackend: paginaBackend + 1
    })
  }

  totalItens () {
    return this.state.resumoOrcamento.totalItens
  }

  valorGlobal () {
    return this.state.resumoOrcamento.valoresCalculados.valorCalculadoFormatado
  }

  numeroPossiveisErros() {
    return this.state.resumoOrcamento.numeroPossiveisErros
  }

  numeroPossiveisInconsistencias = () => this.state.resumoOrcamento.numeroPossiveisInconsistencias

  direcaoOrdenacao() {
    return this.state.direcaoOrdenacao || 'asc'
  }

  async pesquisarItens () {
    const { termoBusca, itens, pagina, paginaBackend } = this.state
    const { contexto } = this.props
    const itensComResumo = await context_httpget(urlPesquisa(paginaBackend, termoBusca), contexto)

    const itensRetornados = pagina === 0 ? itensComResumo.itens : itens.concat(itensComResumo.itens)

    this.setState({
      itens: itensRetornados,
      resumoOrcamento: itensComResumo.resumoOrcamento,
      itemPesquisado: true,
      paginaBackend: paginaBackend + 1
    })
  }

  temPesquisa () {
    const { termoBusca } = this.state
    return !(termoBusca === undefined || _.isEmpty(termoBusca))
  }

  ordenandoPorQuantidade () {
    return this.state.colunaOrdenacao === 'quantidade'
  }

  ordenandoPorPrecoUnitario() {
    return this.state.colunaOrdenacao === 'precoUnitario'
  }

  ordenandoPorValorParcial() {
    return this.state.colunaOrdenacao === 'valorParcial'
  }

  ordenandoPorDiferencaCalculo() {
    return this.state.colunaOrdenacao === 'diferencaCalculo'
  }

  limparPesquisa () {
    this.setState({
      itens: [],
      pagina: 0,
      registrosPorPagina: 10,
      colunaOrdenacao: '',
      direcaoOrdenacao: '',
      termoBusca: '',
      itemPesquisado: false,
      paginaBackend: 0
    }, () => this.recuperarItensOrcamento())
  }

  pesquisarItemOrcamento () {
    if(this.temPesquisa()) {
      this.setState({ pagina: 0, paginaBackend: 0 }, () => this.pesquisarItens())
    } else {
      this.setState({ itemPesquisado: false }, () => this.recuperarItensOrcamento())
    }
  }

  async adicionarLinha () {
    const { contexto } = this.props
    const item = await context_httppost('itens/em-branco', contexto, {}, ()=>({}))
    this.setState({
      itens: [item].concat(this.state.itens),
      resumoOrcamento: { ...this.state.resumoOrcamento, totalItens: this.totalItens() + 1 }
    })
  }

  handleItemPesquisa (event) {
    this.setState({ termoBusca: event.target.value })
  }

  handleKeyPressSearch (event) {
    if (event.key === 'Enter') {
      this.pesquisarItemOrcamento()
    }
  }

  definirDirecaoOrdenacao (colunaOrdenacao) {
    let { direcaoOrdenacao } = this.state
    if (_.isEmpty(direcaoOrdenacao) || this.state.colunaOrdenacao !== colunaOrdenacao) {
      direcaoOrdenacao = 'desc'
    } else {
      direcaoOrdenacao = direcaoOrdenacao === 'desc' ? 'asc' : 'desc'
    }
    return direcaoOrdenacao
  }

  handleItemOrdenacao (colunaOrdenacao) {
    let direcaoOrdenacao = this.definirDirecaoOrdenacao(colunaOrdenacao)
    this.setState({
      itens: [],
      pagina: 0,
      paginaBackend: 0,
      direcaoOrdenacao,
      colunaOrdenacao
    }, () => this.recuperarItensOrcamento())
  }

  handleColunaQuantidadeClick() {
    this.handleItemOrdenacao('quantidade')
  }

  handleColunaPrecoUnitarioClick() {
    this.handleItemOrdenacao('precoUnitario')
  }

  handleColunaValorParcialClick() {
    this.handleItemOrdenacao('valorParcial')
  }

  handleColunaDiferencaCalculoClick() {
    this.handleItemOrdenacao('diferencaCalculo')
  }

  handlePageChange(e, pag) {
    const { itens, registrosPorPagina, itemPesquisado } = this.state

    if ((registrosPorPagina * pag) === itens.length) {
      if (itemPesquisado) {
        this.setState({ pagina: 0 }, () => this.pesquisarItens())
      } else {
        this.recuperarItensOrcamento()
      }
    }

    this.setState({ pagina: pag })
  }

  handleRowsPerPageChange(e) {
    this.setState({ pagina: 0, registrosPorPagina: e.target.value })
  }

  handleItemAlterado (resumo) {
    const itens = this.state.itens.map(
      (i) => (i.cod === resumo.item.cod ? resumo.item : i)
    )

    this.setState({ itens, resumoOrcamento: resumo.resumoOrcamento })
  }

  handleItemExcluido (item, resumoOrcamento) {
    const itens = this.state.itens.filter(
      (i) => i.cod !== item.cod
    )
    this.setState({ itens, resumoOrcamento })
    showSuccessMsg('Item excluído com sucesso.')
  }

  render () {
    const {
      itens, termoBusca, itemPesquisado, registrosPorPagina, pagina, resumoOrcamento
    } = this.state

    const { contexto } = this.props

    if (_.isNil(contexto) || _.isNil(itens)) {
      return <Carregando />
    }
    return (
      <Template>
        <Cabecalho
          numeroPossiveisErros={this.numeroPossiveisErros()}
          numeroPossiveisInconsistencias={this.numeroPossiveisInconsistencias()}
          titulo='Dados Brutos'
        />
        <MenuOrcamentoBruto />
        <Box mb={2} style={{display: 'flex', alignItems: 'flex-end'}}>
          <BuscarComPaginacao
            placeholder='Buscar Item de Orçamento'
            value={termoBusca}
            onChange={this.handleItemPesquisa}
            onKeyPress={this.handleKeyPressSearch}
            disable={itemPesquisado}
            clear={this.limparPesquisa}
            onClick={this.pesquisarItemOrcamento}
          />

          <FormControl variant='outlined' style={{ marginLeft: 4, marginRight: 16 }}>
            <Typography variant='subtitle2' htmlFor='visualizar'>Valor Global</Typography>
            <TextField
              value={`R$ ${this.valorGlobal()}`}
              margin='none'
              disabled={true}
              InputProps={{
                readOnly: true,
              }}
              variant='outlined'
            />
          </FormControl>

          <Adicionar
            label='Adicionar linha'
            onClick={this.adicionarLinha}
            disabled={resumoOrcamento.possuiCurvaAbc}
          />

        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TitleTableCell style={{ width: 90 }}>Linhas</TitleTableCell>
              <TitleTableCell>Cód. Serviço</TitleTableCell>
              <TitleTableCell>Descrição</TitleTableCell>
              <TitleTableCell align='right'>Unidade</TitleTableCell>
              <TitleTableCell align='right'>
                <TableSortLabel
                  active={this.ordenandoPorQuantidade()}
                  direction={this.direcaoOrdenacao()}
                  onClick={this.handleColunaQuantidadeClick}>
                  Quantidade
                </TableSortLabel>
              </TitleTableCell>
              <TitleTableCell align='right'>
                <TableSortLabel
                  active={this.ordenandoPorPrecoUnitario()}
                  direction={this.direcaoOrdenacao()}
                  onClick={this.handleColunaPrecoUnitarioClick}>
                  Preço Unitário
                </TableSortLabel>
              </TitleTableCell>
              <TitleTableCell  align='right'>
                <TableSortLabel
                  active={this.ordenandoPorValorParcial()}
                  direction={this.direcaoOrdenacao()}
                  onClick={this.handleColunaValorParcialClick}>
                  Valor Parcial
                </TableSortLabel>
              </TitleTableCell>
              <TitleTableCell>
                <TableSortLabel
                  active={this.ordenandoPorDiferencaCalculo()}
                  direction={this.direcaoOrdenacao()}
                  onClick={this.handleColunaDiferencaCalculoClick}>
                  Diferença de Cálculo
                </TableSortLabel>
              </TitleTableCell>
              <TitleTableCell align='center' width='75px'>Ações</TitleTableCell>
            </TableRow>
          </TableHead>
          <TabelaItensOrcamento
            itens={itens}
            idEmpreendimento={contexto.empreendimento.cod}
            podeEditar={!resumoOrcamento.possuiCurvaAbc}
            idObra={contexto.obra.cod}
            idOrcamento={contexto.orcamento.cod}
            pagina={pagina}
            registrosPorPagina={registrosPorPagina}
            onItemAlterado={this.handleItemAlterado}
            onItemExcluido={this.handleItemExcluido}
          />
        </Table>
        <Paginacao
          pagina={pagina}
          registrosPorPagina={registrosPorPagina}
          total={this.totalItens()}
          onChangePage={this.handlePageChange}
          onChangeRowsPerPage={this.handleRowsPerPageChange}
        />
      </Template>
    )
  }
}

export const mapStateToProps = ({ contexto, filtroAnalise }) => ({
  contexto, filtroAnalise
})

export default connect(mapStateToProps, { limparFiltroAnalise })(AnalisarOrcamento)
