import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import _ from 'lodash'
import IconButton from '@material-ui/core/IconButton'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

import styles from './UserMenuStyles'
import PropTypes from 'prop-types'

const UserMenu = ({ classes, authentication, userMenuAnchorEl, onUserMenuClose, onUserMenuOpen }) => {
  const handleUserLogout = () => {
    onUserMenuClose('/logout')
  }

  const userMenuId = 'user-menu'
  return authentication && authentication.isUserAuthenticated && (
    <div className={classes.row}>
      <IconButton
        id='ibtnUserMenu'
        aria-owns={userMenuAnchorEl ? userMenuId : null}
        aria-haspopup='true'
        color='primary'
        onClick={onUserMenuOpen}
      >
        <Avatar className={classes.avatar}>
          {_.toUpper(authentication.nomeUsuario[0])}
        </Avatar>
      </IconButton>
      <Menu
        id={userMenuId}
        anchorEl={userMenuAnchorEl}
        open={Boolean(userMenuAnchorEl)}
        onClose={onUserMenuClose}
        className={classes.userMenu}
      >
        <Card elevation={0}>
          <CardContent>
            <Typography variant='subtitle2' color='textSecondary'>
              {authentication.nomeSimples}
            </Typography>
          </CardContent>
          <CardActions className={classes.row}>
            <Button
              id='btnSair'
              fullWidth
              color='primary'
              variant='contained'
              onClick={handleUserLogout}
            >
              Sair
            </Button>
          </CardActions>
        </Card>
      </Menu>
    </div>
  )
}

UserMenu.propTypes = {
  classes: PropTypes.object,
  authentication: PropTypes.object,
  userMenuAnchorEl: PropTypes.any,
  onUserMenuClose: PropTypes.func.isRequired,
  onUserMenuOpen: PropTypes.func.isRequired
}

const materialUIEnhanced = withStyles(styles)(UserMenu)
const mapStateToProps = ({ authentication }) => ({ authentication })
export default connect(mapStateToProps)(materialUIEnhanced)
