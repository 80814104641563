import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import TitleTableCell from '../../../../comum/TitleTableCell'

const CabecalhoDetalheItemRelatorioHistoricoOrcamento = () => (
  <TableHead>
    <TableRow>
      <TitleTableCell >N°.</TitleTableCell>
      <TitleTableCell >Cod. Sço.</TitleTableCell>
      <TitleTableCell align='inherit'>Desc. Sço.</TitleTableCell>
      <TitleTableCell >Und.</TitleTableCell>
      <TitleTableCell >Qtd.</TitleTableCell>
      <TitleTableCell align='right'>Preço Un.</TitleTableCell>
      <TitleTableCell align='right'>Valor Parcial</TitleTableCell>
    </TableRow>
  </TableHead>
)

export default CabecalhoDetalheItemRelatorioHistoricoOrcamento