import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, Typography } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

const CampoFormula = ({ classes, parametro, formulasDisponiveis, onCampoChange }) => (
  <FormControl variant='outlined'>
    <Typography variant='subtitle2'>{parametro.tipo.label}</Typography>
    <Select
      value={parametro.codigoFormulaParametro || ''}
      onChange={(e) => onCampoChange(e, parametro, 'codigoFormulaParametro')}
      size='small'
      classes={{ root: classes.textfieldCriterio }}
    >
      {
        formulasDisponiveis.map((it) => (
          <MenuItem key={it.codigo} value={it.codigo}>
            {it.nome}
          </MenuItem>
        ))
      }
      {(formulasDisponiveis.length === 0) && <MenuItem value=''>Nenhuma fórmula cadastrada</MenuItem>}
    </Select>
  </FormControl>
)

CampoFormula.propTypes = {
  classes: PropTypes.object,
  parametro: PropTypes.object.isRequired,
  formulasDisponiveis: PropTypes.array.isRequired,
  onCampoChange: PropTypes.func.isRequired
}

export default CampoFormula
