import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Dialog, DialogActions, DialogContent, Divider, IconButton, List, ListItem, ListItemText, Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import CloseIcon from '@material-ui/icons/Close'
import { showErrorMsg, showSuccessMsg } from '../../../../../../js/util'
import { context_httpdelete } from '../../../../../../js/httpRequest'
import _ from 'lodash'
import { useSelector } from 'react-redux'

const ExcluirComposicao = ({ openDialogExcluirComposicao, composicao, onCancelar, onAtualizarComposicoes }) => {
  const { codigo, descricao } = composicao
  const contexto = useSelector((state) => state.contexto)
  const [composicoesVinculadas, setComposicoesVinculadas] = useState([])

  const handleExcluir = async (codigo) => {
    try {
      const response = await context_httpdelete(`precos/composicoes/excluir?codigo=${codigo}`, contexto)
      if (_.isEmpty(response)) {
        onAtualizarComposicoes(codigo)
        showSuccessMsg(`Composição ${codigo} excluída com sucesso`)
      } else {
        setComposicoesVinculadas(response)
      }
    } catch (e) {
      showErrorMsg(`Não foi possível excluir a composição ${codigo}`)
    }
  }

  return (
    <>
      <Dialog open={openDialogExcluirComposicao} PaperProps={{ sx: { width: '60%' } }}>
        <Box p={3} display='flex' justifyContent='space-between'>
          <Typography variant='h6'>Excluir composição</Typography>
          <IconButton
            onClick={onCancelar}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Typography variant='h6'>
            {codigo}
          </Typography>
          <Typography variant='body2' color='textSecondary'>
            {descricao}
          </Typography>
          {composicoesVinculadas.length > 0 &&
            (<>
              <Box my={1}>
                <Divider />
              </Box>
              <Box my={1}>
                <Alert severity='warning'>
                  Não é possível excluir esta composição pois a mesma encontra-se vinculada a outras composições. Listamos abaixo todos locais encontrados.
                </Alert>
              </Box>
              <Box mt={3}>
                <Typography variant='h5' gutterBottom>Lista de composições</Typography>
              </Box>
              <List dense>
                { composicoesVinculadas.map((it) => (
                  <ListItem disablePadding>
                    <ListItemText primary={`${it.composicaoPrincipal} - ${it.subComposicao} - ${it.tipo}`} secondary={it.descricao} />
                  </ListItem>
                ))}
              </List>
            </>)
          }
        </DialogContent>
        <DialogActions>
          {_.isEmpty(composicoesVinculadas) ? (
            <Button
              variant='contained'
              color='primary'
              onMouseUp={() => {}}
              onClick={() => handleExcluir(codigo)}
            >
              Excluir composição
            </Button>
          ) : (
            <Button
              variant='contained'
              color='primary'
              onClick={onCancelar}
            >
              Voltar
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

ExcluirComposicao.propTypes = {
  openDialogExcluirComposicao: PropTypes.bool,
  composicao: PropTypes.object.isRequired,
  onAtualizarComposicoes: PropTypes.func.isRequired,
  onCancelar: PropTypes.func.isRequired
}

export default ExcluirComposicao
