import { pageHeaderAndFooter, round, tableFoot } from './RelatorioComposicaoPdf'

export function dadosMaoDeObra (doc, dados, titulo, sistemaReferencia) {
  const adicionalMaoDeObra = (insumos) => insumos.adicionalMaoDeObra / 100 * insumos.custoHorarioTotalMaosDeObra
  const detalhes = dados.detalhes
  const tableHeaderMaoDeObra = [
    { dataKey: 'descricao', title: 'B - Mão de obra' },
    { dataKey: 'quantidade', title: 'Quantidade' },
    { dataKey: 'unidade', title: 'Unidade' },
    { dataKey: 'custo', title: 'Custo' },
    { dataKey: 'custoHorarioTotal', title: 'Custo Horário Total' }
  ]

  const tableDataMaoDeObra = []
  detalhes.insumos.maosDeObra.forEach((it) => {
    tableDataMaoDeObra.push({
      descricao: it.codigoMaoDeObra + ' - ' + it.maoDeObraOrcamento.descricao,
      quantidade: round(it.quantidade, 2),
      unidade: it.maoDeObraOrcamento.unidade,
      custo: round(it.maoDeObraOrcamento.custo, 4),
      custoHorarioTotal: round(it.custoHorarioTotal, 4)
    })
  })
  doc.autoTable(tableHeaderMaoDeObra, tableDataMaoDeObra, {
    startY: doc.lastAutoTable.finalY,
    tableWidth: 'auto',
    margin: { top: 20 },
    tableLineColor: 255,
    tableLineWidth: 1,
    fontSize: 9,
    styles: { halign: 'right', cellPadding: 1 },
    headStyles: {
      fillColor: [200, 200, 200],
      textColor: [0, 0, 0]
    },
    columnStyles: {
      descricao: {
        overflow: 'linebreak',
        cellWidth: 350,
        valign: 'middle',
        halign: 'left',
        fontStyle: 'bold'
      }
    },
    didDrawPage: (data) => pageHeaderAndFooter(doc, data, titulo),
    willDrawCell: function (data) {
      if (data.column.index === 0 && data.cell.section === 'head') {
        data.cell.styles.halign = 'left'
      }
    }
  })

  const adicionalMaoDeObraFerramentas = () => {
    if (sistemaReferencia === 'SICRO2') {
      return {
        label: `Adc.M.O. - Ferramentas: ${detalhes.insumos.adicionalMaoDeObra}%`,
        value: round(adicionalMaoDeObra(detalhes.insumos), 4)
      }
    }
  }

  const custoHorarioTotalMaoDeObra = (detalhes) => {
    return {
      label: 'Custo Horário Total de Mão de Obra',
      value: round(detalhes.insumos.custoHorarioTotalMaosDeObra, 4)
    }
  }

  const custoHorarioTotalExecucao = (detalhes) => {
    return {
      label: 'Custo Horário Total de Execução',
      value: round(detalhes.insumos.custoHorarioTotalExecucao, 4)
    }
  }

  const resumo = () => {
    const array = []
    array.push(custoHorarioTotalMaoDeObra(detalhes))
    sistemaReferencia === 'SICRO2' && array.push(adicionalMaoDeObraFerramentas(detalhes))
    array.push(custoHorarioTotalExecucao(detalhes))
    return array
  }

  tableFoot(resumo(), doc)

  tableFoot([{
    label: 'Custo Unitário de Mão de Obra',
    value: round(detalhes.insumos.custoUnitarioExecucao, 4)
  }, {
    label: 'Custo do FIC(' + dados.value.fic.toString() + ')',
    value: round(dados.value.fic * detalhes.insumos.custoUnitarioExecucao, 2)
  }, {
    label: 'Custo do FIT(' + dados.value.fit.toString() + ')',
    value: round(dados.value.fit * detalhes.insumos.custoUnitarioExecucao, 2)
  }],
  doc)
}
