import React from 'react'
import SaveIcon from '@material-ui/icons/Save'
import { Tooltip } from '@material-ui/core'
import PropTypes from 'prop-types'

const IconeSalvar = ({
  textTooltip, color
}) => (
  <Tooltip title={textTooltip}>
    <SaveIcon color={color}/>
  </Tooltip>
)

IconeSalvar.propTypes = {
  textTooltip: PropTypes.string.isRequired,
  color: PropTypes.string
}

export default IconeSalvar
