import StyledTableRow from '../../comum/StyledTableRow'
import TableCell from '@material-ui/core/TableCell'
import { Button, Checkbox, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import PropTypes from 'prop-types'
import React from 'react'

function UsuarioPerfil ({ usuario, exibeColunaOrcamentosSigilosos, onClick, onAtualizarOrcamentoSigiloso }) {
  return <StyledTableRow>
    <TableCell>
      <Typography>
        {usuario.nomeCompleto ? usuario.nomeCompleto.toUpperCase() : ''}
      </Typography>
    </TableCell>
    <TableCell>
      <Typography>
        {usuario.login ? usuario.login.toUpperCase() : ''}
      </Typography>
    </TableCell>
    <TableCell align='left'>
      <Typography className='txtboldblue'>
        {usuario.email ? usuario.email.toLowerCase() : ''}
      </Typography>
    </TableCell>
    {exibeColunaOrcamentosSigilosos && <TableCell align='left'>
      <Checkbox
        checked={usuario.podeVisualizarOrcamentosSigilosos}
        color='primary'
        onChange={(e) => onAtualizarOrcamentoSigiloso(e, usuario)}
      />
    </TableCell>}
    <TableCell className='dsptbcellw10pc'>
      <Button
        color='primary'
        variant='outlined'
        className='btn36'
        onClick={onClick}
      >
        <DeleteIcon />
      </Button>
    </TableCell>
  </StyledTableRow>
}

UsuarioPerfil.propTypes = {
  usuario: PropTypes.any,
  exibeColunaOrcamentosSigilosos: PropTypes.bool,
  onAtualizarOrcamentoSigiloso: PropTypes.func,
  onClick: PropTypes.func
}

export default (UsuarioPerfil)
