import React, { useState } from 'react'
import { Accordion, AccordionDetails, Box, Button, Typography } from '@material-ui/core'
import StyledAccordionSumary from '../../../../comum/StyledAccordionSumary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SimplePopover from '../../../../comum/SimplePopover'
import AddIcon from '@material-ui/icons/Add'
import NenhumResultadoEncontrado from '../../analise-conteudo/content/comum/NenhumResultadoEncontrado'
import PropTypes from 'prop-types'
import LinhaItemTipologiaCombinadaVinculada from './LinhaItemTipologiaCombinadaVinculada'

const TipologiasCombinadasVinculadas = ({ classes, tipologiasCombinadas, onVincular, onDesvincular }) => {
  const [expandedPanel, setExpandedPanel] = useState(true)

  const handleChangePanel = (panel) => (event, newExpanded) => {
    setExpandedPanel(newExpanded ? panel : false)
  }

  const handleVincularTipologiaCombinada = (event) => {
    event.stopPropagation()
    onVincular()
  }

  return (
    <Box mt={2}>
      <Accordion
        square
        className={classes.accordionAnaliseConteudo}
        expanded={expandedPanel === true}
        onChange={handleChangePanel(true)}
      >
        <StyledAccordionSumary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='accordionTipologiasCombinadas'
          className={classes.accordionSummaryAnalise}
        >
          <Box display='flex' alignItems='center'>
            <Typography variant='subtitle2' className={classes.tituloAccordionAnaliseConteudo}>
              Tipologias Combinadas
            </Typography>
            <SimplePopover>
              Uma breve descrição sobre a tipologia combinada
            </SimplePopover>

            <Box display='flex'>
              <Button
                variant='text'
                color='primary'
                onClick={(e) => handleVincularTipologiaCombinada(e)}
                style={{ marginRight: 5 }}
              >
                <AddIcon fontSize='small' color='primary' /> Adicionar
              </Button>
            </Box>
          </Box>
        </StyledAccordionSumary>
        <AccordionDetails style={{ display: 'grid' }}>
          {tipologiasCombinadas.length === 0 &&
            <NenhumResultadoEncontrado
              titulo='Nenhuma tipologia combinada encontrada'
              subtitulo='Cadastre tipologias combinadas personalizadas'
            />
          }
          {tipologiasCombinadas.map(tipologiaCombinada => (
            <LinhaItemTipologiaCombinadaVinculada
              key={tipologiaCombinada.codigo}
              classes={classes}
              codigo={tipologiaCombinada.codigo}
              nome={tipologiaCombinada.nome}
              descricao={tipologiaCombinada.descricao}
              tipologia={tipologiaCombinada}
              onDesvincular={onDesvincular}
            />))
          }
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

TipologiasCombinadasVinculadas.propTypes = {
  classes: PropTypes.object,
  tipologiasCombinadas: PropTypes.array,
  onVincular: PropTypes.func.isRequired,
  onDesvincular: PropTypes.func.isRequired
}

export default TipologiasCombinadasVinculadas
