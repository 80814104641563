import React from 'react'
import Dialog from '@material-ui/core/Dialog/Dialog'
import DialogContent from '@material-ui/core/DialogContent/DialogContent'
import Divider from '@material-ui/core/Divider/Divider'
import { DialogActions } from '@material-ui/core'
import TituloModal from './TituloModal'
import PropTypes from 'prop-types'

const style = { marginTop: 10 }

const ModalTelaCheia = ({
  open, titulo, onClose, children, minHeight, Actions
}) => {
  const appliedStyle = minHeight ? { ...style, minHeight } : style

  return <Dialog fullWidth maxWidth='xl' open={open} disableEnforceFocus>
    <TituloModal onClose={onClose}>
      {titulo}
    </TituloModal>

    <Divider />

    <DialogContent style={appliedStyle}>
      {children}
    </DialogContent>

    <Divider />

    <DialogActions>
      {Actions}
    </DialogActions>

  </Dialog>
}

ModalTelaCheia.propTypes = {
  open: PropTypes.bool.isRequired,
  titulo: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  minHeight: PropTypes.number,
  Actions: PropTypes.node
}

export default ModalTelaCheia