import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import { sessionExpired } from '../actions/authenticationJwtActions'
import { isAuthenticated } from '../security/securityContext'

// eslint-disable-next-line react/display-name
const renderComponent = (isUserAuthenticated, Comp) => (props) => (
  isUserAuthenticated
    ? (<Comp {...props} />)
    : (<Redirect
      to={{
        pathname: '/login',
        state: { from: props.location }
      }}
    />)
)

const AuthenticatedRoute = ({ isUserAuthenticated, component, ...rest }) => {
  if (isAuthenticated()) {
    return (<Route {...rest} render={renderComponent(isUserAuthenticated, component)} />)
  } else {
    sessionExpired()
    return <Redirect to='/login'/>
  }
}

const mapStateToProps = (state) => {
  const {
    authentication: { isUserAuthenticated }
  } = state
  return {
    isUserAuthenticated
  }
}

export default connect(mapStateToProps, null)(AuthenticatedRoute)
