import React, { useState } from 'react'
import { TableBody, TableRow } from '@material-ui/core'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import Paginacao from '../../../../comum/Paginacao'
import Paper from '@material-ui/core/Paper'
import TitleTableCell from '../../../../comum/TitleTableCell'
import { makeStyles } from '@material-ui/core/styles'
import MemoriaCalculoRow from './MemoriaCalculoRow'
import { paginar } from '../../../../../js/util'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    boxShadow: 'none'
  },
  table: {
    minWidth: 890
  },
  tableWrapper: {
    overflowX: 'auto'
  }
}))

const TabelaMemoriaCalculo = ({ memorias }) => {
  const [pagina, setPagina] = useState(0)
  const [registrosPorPagina, setRegistrosPorPagina] = useState(50)
  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      <div className={classes.tableWrapper}>
        <Table className={classes.table} aria-label='custom pagination table'>
          <TableHead>
            <TableRow>
              <TitleTableCell>Código</TitleTableCell>
              <TitleTableCell>Composição fonte</TitleTableCell>
              <TitleTableCell>Fórmula quantidade</TitleTableCell>
              <TitleTableCell>Quantidade</TitleTableCell>
              <TitleTableCell>Quantidade Improdutiva</TitleTableCell>
              <TitleTableCell>Fórmula preço</TitleTableCell>
              <TitleTableCell>Preço</TitleTableCell>
              <TitleTableCell>Tipo</TitleTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              paginar(memorias, pagina, registrosPorPagina).map((item) => (
                <MemoriaCalculoRow
                  key={item.codigoServico}
                  item={item}
                />
              ))}
          </TableBody>
        </Table>
        <Paginacao
          pagina={pagina}
          registrosPorPagina={registrosPorPagina}
          total={memorias.length}
          onChangePage={(e, pag) => setPagina(pag)}
          onChangeRowsPerPage={(e) => setRegistrosPorPagina(e.target.value)}
        />
      </div>
    </Paper>
  )
}

TabelaMemoriaCalculo.propTypes = {
  memorias: PropTypes.array.isRequired
}

export default TabelaMemoriaCalculo
