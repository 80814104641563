import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import _ from 'lodash'
import ItemVincularFormula from './ItemVincularFormula'
import TitleTableCell from '../../../../comum/TitleTableCell'
import Paginacao from '../../../../comum/Paginacao'

const TabelaVincularFormulas = ({
  classes,
  formulas,
  formulasSelecionadas,
  onSelecionarFormula
}) => {
  const [pagina, setPagina] = useState(0)
  const [registrosPorPagina, setRegistrosPorPagina] = useState(10)

  const handleFormulaIsChecked = (formula) =>
    formulasSelecionadas.map(
      (it) => _.isNil(it.codFormulaOriginal) ? it.codFormula : it.codFormulaOriginal
    ).indexOf(formula.codFormula) !== -1

  const handleDesabilitarFormula = (formula) => formulasSelecionadas.some(exp => exp.codFormulaOriginal === formula.codFormula)

  return (
    <React.Fragment>
      <Table>
        <TableHead>
          <TableRow>
            <TitleTableCell width='5%' />
            <TitleTableCell width='25%'>Nome</TitleTableCell>
            <TitleTableCell width='25%'>Orçamento</TitleTableCell>
            <TitleTableCell width='25%'>Obra</TitleTableCell>
            <TitleTableCell align='right'>Empreendimento</TitleTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { formulas.slice(pagina * registrosPorPagina, pagina * registrosPorPagina + registrosPorPagina).map((formula) => (
            <ItemVincularFormula
              key={formula.codFormula}
              classes={classes}
              formula={formula}
              isDisabled={handleDesabilitarFormula(formula)}
              isChecked={handleFormulaIsChecked(formula)}
              onSelecionarFormula={onSelecionarFormula}
            />
          ))
          }
        </TableBody>
      </Table>
      <Paginacao
        total={formulas.length}
        registrosPorPagina={registrosPorPagina}
        pagina={pagina}
        onChangePage={(e, pag) => setPagina(pag)}
        onChangeRowsPerPage={(e) => setRegistrosPorPagina(e.target.value)}
      />
    </React.Fragment>
  )
}

TabelaVincularFormulas.propTypes = {
  classes: PropTypes.object,
  formulas: PropTypes.array,
  formulasSelecionadas: PropTypes.array,
  onSelecionarFormula: PropTypes.func.isRequired
}

export default TabelaVincularFormulas
