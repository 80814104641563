import { pageHeaderAndFooter, round } from './RelatorioComposicaoPdf'

export function dadosEquipamento (doc, detalhes, titulo) {
  const tableHeadEquipamentos = [[
    { content: 'A - Equipamento' },
    { content: 'Quantidade' },
    { content: 'Utilização Operativa' },
    { content: 'Utilização Improdutiva' },
    { content: 'Custo Operacional Operativo' },
    { content: 'Custo Operacional Improdutivo' },
    { content: 'Custo Horário' }
  ]]

  const tableBodyEquipamentos = []
  detalhes.insumos.equipamentos.forEach((equipamento) => {
    const linha = [
      equipamento.codigoEquipamento + ' - ' + equipamento.equipamentoOrcamento.descricao,
      round(equipamento.quantidade, 2),
      round(equipamento.utilizacaoOperativa, 2),
      round(equipamento.utilizacaoImprodutiva, 2),
      round(equipamento.equipamentoOrcamento.custoProdutivo, 4),
      round(equipamento.equipamentoOrcamento.custoImprodutivo, 4),
      round(equipamento.custoHorarioTotal, 4)
    ]
    tableBodyEquipamentos.push(linha)
  })

  doc.autoTable(
    {
      body: tableBodyEquipamentos,
      head: tableHeadEquipamentos,
      startY: doc.lastAutoTable.finalY + 3,
      tableWidth: 'auto',
      fontSize: 9,
      margin: { top: 20 },
      tableLineColor: 255,
      tableLineWidth: 1,
      styles: { halign: 'right', cellPadding: 1 },
      headStyles: {
        fillColor: [200, 200, 200],
        textColor: [0, 0, 0],
        0: {
          valign: 'middle',
          halign: 'left',
          fontStyle: 'bold'
        },
        4: {
          cellWidth: 95
        }
      },
      columnStyles: {
        0: {
          overflow: 'linebreak',
          cellWidth: 350,
          valign: 'middle',
          halign: 'left',
          fontStyle: 'bold'
        },
        4: {
          cellWidth: 95
        }
      },
      didDrawPage: (data) => pageHeaderAndFooter(doc, data, titulo),
      willDrawCell: function (data) {
        if (data.column.index === 0 && data.cell.section === 'head') {
          data.cell.styles.halign = 'left'
        }
      }
    })

  doc.autoTable(
    {
      body: [['Custo Horário Total de Equipamentos',
        round(detalhes.insumos.custoHorarioTotalEquipamentos, 2)]],
      startY: doc.lastAutoTable.finalY,
      startX: 500,
      theme: 'plain',
      tableWidth: 'auto',
      columnStyles: {
        0: {
          overflow: 'linebreak',
          valign: 'middle',
          halign: 'right',
          fontStyle: 'bold',
          fontSize: 9,
          cellPadding: 1
        },
        1: {
          overflow: 'linebreak',
          valign: 'middle',
          halign: 'right',
          fontStyle: 'bold',
          fontSize: 9,
          cellWidth: 100,
          cellPadding: 1
        }
      }
    })
  doc.line(40, doc.lastAutoTable.finalY + 10, 800, doc.lastAutoTable.finalY + 10)
}
