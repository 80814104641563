import React, { useState } from 'react'
import { Box, Card, CardContent, FormControlLabel, Link, Typography } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import PropTypes from 'prop-types'
import _ from 'lodash'
import RegraInconsistenciaTipologia from './comum/tipologia/RegraInconsistenciaTipologia'
import { connect } from 'react-redux'
import { context_httpget, context_httpput } from '../../../../../js/httpRequest'
import ModalInconsistencia from '../../possiveis-inconsistencias/ModalInconsistencia'
import ReactTooltip from 'react-tooltip'
import StyledSwitch from '../../../../comum/StyledSwitch'
import { PERSONALIZADA, TEMATICA } from '../analise-conteudo'
import { hasAutorities } from '../../../../../security/securityContext'
import { PERFIL } from '../../../../../js/perfis'

const AnaliseConteudoContentItem = ({
  classes,
  tipoAnaliseSelecionada,
  codigo,
  nome,
  descricao,
  seDisponivel,
  qtdInsumos,
  qtdItensOrcamentarios,
  valorTotal,
  percentual,
  tipologia,
  tipologiaPossuiInconsistencia,
  formulaGerada,
  contexto,
  onEditarChange,
  onExcluirClick,
  onAtualizarTipologia,
  onAtualizarDisponibilidade
}) => {
  const [openModalInconsistencia, setOpenModalInconsistencia] = useState(false)

  const recuperarDados = async () => {
    const tipo = _.isNil(formulaGerada) ? 'expressoes' : 'formulas'
    const response = await context_httpget(`analise-conteudo/${tipo}/${codigo}?tipoAnalise=${tipoAnaliseSelecionada}`, contexto)
    if (response) {
      onEditarChange(response)
    }
  }

  const handleAtualizarDisponibilidade = async (seDisponivel) => {
    const tipo = _.isNil(formulaGerada) ? 'expressoes' : 'formulas'
    await context_httpput(`analise-conteudo/${tipo}/${codigo}/disponibilidade?seDisponivel=${seDisponivel}`, contexto)
    onAtualizarDisponibilidade(seDisponivel, codigo)
  }

  return (
    <React.Fragment>
      <Card elevation={0} className={classes.analiseCardItem}>
        <CardContent style={{ padding: 4 }}>
          <Box display='flex' justifyContent='space-between'>
            <div>
              <Typography variant='subtitle2'>
                {nome}
              </Typography>
              <Typography color='textSecondary' className={classes.cardTemaSidebarSubtitle}>
                {descricao}
              </Typography>
            </div>
            {(hasAutorities([PERFIL.GESTOR_TCU]) || tipoAnaliseSelecionada === PERSONALIZADA) &&
              <Box display='flex' justifyContent='space-between' alignItems='center' style={{ cursor: 'pointer' }}>
                {tipologia && tipoAnaliseSelecionada === TEMATICA &&
                  <React.Fragment>
                    <a data-tip data-for={`descricaoID${codigo}`}>
                      <FormControlLabel
                        style={{ marginRight: 4 }}
                        label={''}
                        control={
                          <StyledSwitch
                            color='primary'
                            size='small'
                            name='disponivel'
                            onChange={() => handleAtualizarDisponibilidade(!seDisponivel)}
                            checked={seDisponivel}
                          />
                        }
                      />
                    </a>
                    <ReactTooltip id={`descricaoID${codigo}`} place='top' type='dark' effect='solid'>
                      {seDisponivel ? 'Inativar' : 'Ativar'}
                    </ReactTooltip>
                  </React.Fragment>
                }

                <a data-tip data-for={`excluirID${codigo}`}>
                  <DeleteIcon color='action' style={{ marginRight: 4 }} onClick={onExcluirClick} />
                </a>
                <ReactTooltip id={`excluirID${codigo}`} place='top' type='dark' effect='solid'>Excluir</ReactTooltip>

                <a data-tip data-for={`editarID${codigo}`}>
                  <EditIcon color='action' onClick={recuperarDados} />
                </a>
                <ReactTooltip id={`editarID${codigo}`} place='top' type='dark' effect='solid'>Editar</ReactTooltip>
              </Box>
            }
          </Box>
          <Box display='flex' flexWrap='wrap'>
            {!_.isNil(qtdInsumos) &&
              <Link color='primary' onClick={() => setOpenModalInconsistencia(true)} style={{ textDecoration: 'none' }}>
                <Typography color='primary' className={classes.cardTemaSidebarSubtitle} style={{ marginRight: 5 }}>
                  Insumos e serviços: {qtdInsumos}
                </Typography>
              </Link>
            }

            {!_.isNil(qtdItensOrcamentarios) &&
              <Typography className={classes.cardTemaSidebarSubtitle} style={{ marginRight: 4 }}>
                | Quantidade total de itens: {qtdItensOrcamentarios} |
              </Typography>
            }

            <Typography className={classes.cardTemaSidebarSubtitle} style={{ marginRight: 4 }}>
              Valor total: {_.isNil(formulaGerada) ? 'R$' : ''} {valorTotal} |
            </Typography>

            {!_.isNil(formulaGerada) &&
              <Typography className={classes.cardTemaSidebarSubtitle} style={{ marginRight: 4 }}>
                Expressão: {formulaGerada}
              </Typography>
            }

            {!_.isNil(percentual) &&
              <Typography className={classes.cardTemaSidebarSubtitle}>
                Percentual: {percentual}% do valor do orçamento
              </Typography>
            }
          </Box>

          {/* expressão */}
          {!_.isNil(tipologia) && !_.isNil(tipologiaPossuiInconsistencia) && _.isNil(formulaGerada) &&
            <RegraInconsistenciaTipologia
              classes={classes}
              tipoAnaliseSelecionada={tipoAnaliseSelecionada}
              tipologia={tipologia}
              possuiInconsistencia={tipologiaPossuiInconsistencia}
              tipoTipologia='EXPRESSAO'
              codigoExpressaoOuFormula={codigo}
              onAtualizarTipologia={onAtualizarTipologia}
            />
          }

          {/* formulas */}
          {!_.isNil(tipologia) && !_.isNil(tipologiaPossuiInconsistencia) && !_.isNil(formulaGerada) &&
            <RegraInconsistenciaTipologia
              classes={classes}
              tipoAnaliseSelecionada={tipoAnaliseSelecionada}
              tipologia={tipologia}
              possuiInconsistencia={tipologiaPossuiInconsistencia}
              tipoTipologia='FORMULA'
              codigoExpressaoOuFormula={codigo}
              onAtualizarTipologia={onAtualizarTipologia}
            />
          }
        </CardContent>
      </Card>
      {openModalInconsistencia &&
        <ModalInconsistencia
          open={openModalInconsistencia}
          close={() => setOpenModalInconsistencia(false)}
          inconsistencia={{ url: `tipologias-de-analise/expressoes/${codigo}`, titulo: nome }}
        />
      }
    </React.Fragment>
  )
}

AnaliseConteudoContentItem.propTypes = {
  classes: PropTypes.object,
  tipoAnaliseSelecionada: PropTypes.string.isRequired,
  codigo: PropTypes.number,
  nome: PropTypes.string,
  descricao: PropTypes.string,
  seDisponivel: PropTypes.bool,
  qtdInsumos: PropTypes.number,
  qtdItensOrcamentarios: PropTypes.number,
  valorTotal: PropTypes.string,
  percentual: PropTypes.string,
  tipologia: PropTypes.object,
  tipologiaPossuiInconsistencia: PropTypes.bool,
  formulaGerada: PropTypes.string,
  contexto: PropTypes.object,
  onEditarChange: PropTypes.func,
  onExcluirClick: PropTypes.func,
  onAtualizarTipologia: PropTypes.func.isRequired,
  onAtualizarDisponibilidade: PropTypes.func
}

export const mapStateToProps = ({ contexto }) => ({
  contexto
})

export default connect(mapStateToProps)(AnaliseConteudoContentItem)
