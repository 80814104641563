import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { getAuthentication } from '../../security/securityContext'
import App from '../App'
import PropTypes from 'prop-types'
import { httpget } from '../../js/httpRequest'
import _ from 'lodash'
import Carregando from '../comum/Carregando'
import styles from './styles'
import HomePrimeiroAcesso from './HomePrimeiroAcesso'
import TabPanel from '../comum/TabPanel'
import Container from '@material-ui/core/Container'
import ReferenciaPreco from './srp/ReferenciaPreco'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import CardAcessosRecentes from './CardAcessosRecentes'
import ListaPesquisaGeral from './ListaPesquisaGeral'
import { showErrorMsg } from '../../js/util'
import BreadCrumbHome from './BreadCrumbHome'

class Home extends Component {
  static propTypes = {
    classes: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      activeTab: 0,
      ultimosAcessos: null,
      termoBusca: '',
      itensPesquisaGeral: null
    }
    this.handleChange = this.handleChange.bind(this)
  }

  async componentDidMount () {
    const ultimosAcessos = await httpget('/api/historico-acesso/orcamento')
    if (ultimosAcessos) {
      this.setState({ ultimosAcessos })
    }
  }

  handleChange (event, activeTab) {
    this.setState({
      activeTab,
      itensPesquisaGeral: null
    })
  }

  handleChangePesquisar = (event) => this.setState({ termoBusca: event.target.value })

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.pesquisar()
    }
  }

  pesquisar = () => {
    const { termoBusca } = this.state

    if (!_.isEmpty(termoBusca)) {
      httpget(`/api/pesquisa-geral?busca=${termoBusca}`)
        .then((data) => {
          this.setState({
            itensPesquisaGeral: data
          })
        })
        .catch(() => showErrorMsg('Erro ao realizar a pesquisa geral.'))
    }
  }

  verTodos = () => {
    httpget('/api/pesquisa-geral')
      .then((data) => {
        this.setState({
          itensPesquisaGeral: data
        })
      })
      .catch(() => showErrorMsg('Erro ao realizar a pesquisa geral.'))
  }

  exibirPrimeiroAcesso () {
    const { ultimosAcessos } = this.state
    return _.isEmpty(ultimosAcessos)
  }

  render () {
    const authentication = getAuthentication()
    if (!authentication || !authentication.isUserAuthenticated) {
      return <Redirect to="/login"/>
    }

    const { activeTab, ultimosAcessos, itensPesquisaGeral } = this.state
    const { classes } = this.props

    if (_.isNil(ultimosAcessos)) {
      return <Carregando/>
    }

    return (
      <App noAppToolbar={false}>
        {
          this.exibirPrimeiroAcesso()
            ? <Box pt={3}>
              <HomePrimeiroAcesso/>
            </Box>
            : (
              <React.Fragment>
                <Box mb={2} p={2} pb={4} style={{ borderBottom: 'solid 1px #ccc'}}>
                  <Container>
                  <div className={classes.headerHome}>
                    <Typography className={classes.tituloPrincipal} variant="h6">SAO - Sistema de Análise de Orçamentos</Typography>
                    {
                    !this.exibirPrimeiroAcesso() && (
                      <Button datacy-id="btnListarEmpreendimentos" component={Link}
                              to={'/empreendimentos'}
                              variant="contained" color="primary" href="#contained-buttons"
                              className={classes.botaoHome}>
                        Listar todos os Empreendimentos
                      </Button>
                      )
                    }
                  </div>
                  {!_.isNil(itensPesquisaGeral) && <BreadCrumbHome/>}

                <Paper elevation={2}>
                  <Tabs
                    datacy-id="menuPrincipalHome"
                    value={activeTab}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={this.handleChange}>
                    <Tab label="Pesquisa Geral" tabIndex={0}/>
                    <Tab label="Referência de preço" tabIndex={1}/>
                  </Tabs>

                  <TabPanel value={activeTab} index={0}>
                    <div>
                      <TextField
                        placeholder={'Exemplo: Nome da Obra'}
                        variant={'outlined'}
                        onChange={(e) => this.handleChangePesquisar(e)}
                        onKeyPress={(e) => this.handleKeyPress(e)}
                        className={classes.inputPesquisaGeral}
                      />
                    </div>
                    <div className={classes.botoesPesquisa}>
                      <Button variant={'outlined'} color={'primary'} onClick={() => this.pesquisar()}>Pesquisar</Button>
                      <Button color={'primary'} onClick={() => this.verTodos()}>Ver todos</Button>
                    </div>
                  </TabPanel>

                  <TabPanel value={activeTab} index={1}>
                    <ReferenciaPreco/>
                  </TabPanel>

                </Paper>
                </Container>
                </Box>
                <Container>
                <Box>
                  {_.isNil(itensPesquisaGeral) ? <CardAcessosRecentes acessos={ultimosAcessos}/> : <ListaPesquisaGeral itens={itensPesquisaGeral}/>}
                </Box>
              </Container>
              </React.Fragment>

            )
        }

      </App>
    )
  }
}

export default withStyles(styles)(Home)
