import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { jsPDF as Jspdf } from 'jspdf'
import { context_httpget, context_httppost } from '../../../../../js/httpRequest'
import { connect } from 'react-redux'
import { dadosOrcamentoPDF } from './orcamento/DadosOrcamentoPDF'
import { dadosTemaPDF } from './tema/DadosTemas'
import FormData from 'form-data'

const RelatorioAnaliseConteudo = ({ contexto, gerarRelatorio, nomeRelatorio, onAdicionarNovoArquivo }) => {
  const [analiseConteudo, setAnaliseConteudo] = useState(null)

  useEffect(() => {
    const recuperarAnaliseConteudo = async () => {
      const analiseConteudo = await context_httpget('analise-conteudo', contexto)
      setAnaliseConteudo(analiseConteudo)
    }

    if (contexto) {
      gerarRelatorio && recuperarAnaliseConteudo()
    }
  }, [gerarRelatorio])

  useEffect(() => {
    analiseConteudo && gerarPDF()
  }, [analiseConteudo])

  const gerarPDF = () => {
    const doc = new Jspdf('p', 'pt', 'a4')
    const posicaoOrcamento = dadosOrcamentoPDF(doc, analiseConteudo.orcamento)
    dadosTemaPDF(doc, analiseConteudo.temas, posicaoOrcamento)
    doc.save(`${nomeRelatorio}.pdf`)
    salvarArquivo(doc)
  }

  const salvarArquivo = async (doc) => {
    const blob = doc.output('blob')
    const file = new File([blob], `${nomeRelatorio}.pdf`)
    const formData = new FormData()
    formData.append('file', file)
    const arquivo = await context_httppost('analise-conteudo/arquivos', contexto, formData)
    onAdicionarNovoArquivo(arquivo)
  }

  return (<div />)
}

RelatorioAnaliseConteudo.propTypes = {
  contexto: PropTypes.object,
  nomeRelatorio: PropTypes.string.isRequired,
  gerarRelatorio: PropTypes.bool.isRequired,
  onAdicionarNovoArquivo: PropTypes.func.isRequired
}

export const mapStateToProps = ({ contexto }) => ({ contexto })

export default connect(mapStateToProps)(RelatorioAnaliseConteudo)
