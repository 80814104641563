import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { CAMPO_FILTRO_FORMULAS, CAMPO_FORMULA_POR_UNIDADE, CAMPO_NOME_DA_FORMULA, sleepAutocomplete } from '../../../analise-conteudo'
import axios from 'axios'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import _ from 'lodash'
import { SLOW_REQUEST_CONFIG } from '../../../../../../../js/operationProgressUtil'
import { context_httppost } from '../../../../../../../js/httpRequest'
import { showSuccessMsg, textNormalize } from '../../../../../../../js/util'
import CampoFiltro from '../../comum/campos/CampoFiltro'
import CampoUnidades from '../../comum/campos/CampoUnidades'
import CampoEmpreendimentos from '../../comum/campos/CampoEmpreendimentos'
import CampoOrcamentos from '../../comum/campos/CampoOrcamentos'
import CampoBusca from '../../comum/campos/CampoBusca'
import PaperBox from '../../../../../../comum/PaperBox'
import TabelaVincularFormulas from '../../comum/vincular-formula/TabelaVincularFormulas'
import buscarOrcamentos from '../../../../../../../actions/orcamentos/buscarOrcamentos'
import buscarTiposUnidades from '../../../../../../../actions/temas/buscarTiposUnidades'

const ImportarFormula = ({ classes, contexto, buscarTiposUnidades, onRecarregarFormulas, onFecharDrawer }) => {
  const [filtro, setFiltro] = useState(CAMPO_FORMULA_POR_UNIDADE)
  const [unidade, setUnidade] = useState(null)
  const [unidadeEmpty] = useState(false)
  const [empreendimento, setEmpreendimento] = useState(null)
  const [empreendimentos, setEmpreendimentos] = useState(null)
  const [orcamentos, setOrcamentos] = useState([])
  const [orcamento, setOrcamento] = useState(null)
  const [formulas, setFormulas] = useState([])
  const [formulasFilter, setFormulasFilter] = useState([])
  const [formulasSelecionadas, setFormulasSelecionadas] = useState([])
  const [termo, setTermo] = useState('')
  const [carregandoOrcamentos, setCarregandoOrcamentos] = useState(false)

  useEffect(() => {
    contexto && buscarTiposUnidades()
  }, [contexto])

  useEffect(() => {
    const buscarEmpreendimentosPorUnidade = async () => {
      const { data } = await axios.get(`/api/empreendimentos/unidade/${unidade.cod}`, SLOW_REQUEST_CONFIG)
      setEmpreendimentos(data)
    }

    if (unidade && empreendimentos === null) {
      unidade && buscarEmpreendimentosPorUnidade()
    }
  }, [unidade])

  useEffect(() => {
    const buscarOrcamentos = async () => {
      setCarregandoOrcamentos(true)
      if (contexto.empreendimento) {
        const { data } = await axios.get(`/api/empreendimentos/${contexto.empreendimento.cod}/orcamentos`, SLOW_REQUEST_CONFIG)
        await sleepAutocomplete(1e2)
        setOrcamentos(data)
        setCarregandoOrcamentos(false)
      }
    }

    empreendimento && buscarOrcamentos()
  }, [empreendimento])

  useEffect(() => {
    const buscarFormulas = async (empreendimento, orcamento) => {
      if (empreendimento && orcamento) {
        const { data } = await axios.get(`/api/empreendimentos/${empreendimento.cod}/orcamentos/${orcamento.cod}/analise-conteudo/formulas`, SLOW_REQUEST_CONFIG)
        if (data) {
          setFormulas(data)
          setFormulasFilter(data)
        }
      } else {
        setFormulas([])
        setTermo('')
      }
    }

    orcamento && buscarFormulas(empreendimento, orcamento)
  }, [orcamento])

  const opcoesImportacoes = () => {
    const opcoes = [...CAMPO_FILTRO_FORMULAS]
    opcoes.shift()
    return opcoes
  }

  const handleCampoFiltro = (valor) => {
    setFiltro(valor)
    setFormulasFilter([])
    setTermo('')
  }

  const handleCampoUnidade = (unidade) => {
    if (unidade) {
      setUnidade(unidade)
    }
  }

  const handleBuscarFormulasPeloTermo = async () => {
    const { data } = await axios.get(`/api/formulas?termo=${termo}`, SLOW_REQUEST_CONFIG)
    if (data) {
      setFormulasFilter(data)
    }
  }

  const handleSelecionarFormula = (event, formula) => {
    const formulaVinculo = {
      codFormula: formula.codFormula,
      nomeFormula: formula.nomeFormula,
      descricaoFormula: formula.descricaoFormula
    }
    if (event.target.checked) {
      setFormulasSelecionadas([...formulasSelecionadas, formulaVinculo])
    } else {
      const novaLista = formulasSelecionadas.filter(it => it.codFormula !== formula.codFormula)
      setFormulasSelecionadas(novaLista)
    }
  }

  const salvarImportarFormulas = async () => {
    const novasFormulasSelecionadas = formulasSelecionadas.filter(it => _.isNil(it.codOrcamento))
    const formulasImportadas = await context_httppost('analise-conteudo/formulas/importar', contexto, novasFormulasSelecionadas)
    if (formulasImportadas) {
      showSuccessMsg('Dados registrados')
      onRecarregarFormulas()
      onFecharDrawer()
    }
  }

  const handleFiltrarFormulasPorTermo = () => {
    if (termo.length) {
      const novaLista = formulas.filter((formula) => textNormalize(formula.nomeFormula).toLowerCase().search(textNormalize(termo).toLowerCase()) !== -1)
      setFormulasFilter(novaLista)
    } else {
      setFormulasFilter(formulas)
    }
  }

  return (
    <React.Fragment>
      <Typography variant='h6' style={{ marginBottom: 20 }}>
        Importar Fórmula
      </Typography>
      <CampoFiltro
        filtro={filtro}
        values={opcoesImportacoes()}
        onFiltroChange={handleCampoFiltro}
      />
      { filtro === CAMPO_FORMULA_POR_UNIDADE && (
        <React.Fragment>
          <CampoUnidades
            unidade={unidade}
            unidadeEmpty={unidadeEmpty}
            unidadeUsuarioLogadoPreSelecionada
            onUnidadeChange={handleCampoUnidade}
          />
          <CampoEmpreendimentos
            empreendimentos={empreendimentos === null ? [] : empreendimentos}
            empreendimento={empreendimento}
            onCampoEmpreendimento={setEmpreendimento}
          />
          <CampoOrcamentos
            orcamentos={orcamentos}
            onCampoOrcamento={setOrcamento}
            carregandoOrcamentos={carregandoOrcamentos}
          />
        </React.Fragment>)
      }

      { filtro === CAMPO_NOME_DA_FORMULA && (
        <CampoBusca
          value={termo}
          name={'termo'}
          placeholder={'Digite o nome da fórmula'}
          buttonName={'Pesquisar'}
          onCampoChange={setTermo}
          onClick={handleBuscarFormulasPeloTermo}
        />)
      }

      <PaperBox style={{ marginTop: 15 }}>
        { filtro !== CAMPO_NOME_DA_FORMULA && (
          <CampoBusca
            value={termo}
            name={'termo'}
            placeholder={'Filtrar por nome'}
            buttonName={'Filtrar'}
            onCampoChange={setTermo}
            onClick={handleFiltrarFormulasPorTermo}
          />)
        }

        <Typography variant='h6' className={classes.subtituloAssociarTema}>
          Fórmulas - Marque o que deseja associar ao tema
        </Typography>
        <TabelaVincularFormulas
          classes={classes}
          formulas={formulasFilter}
          formulasSelecionadas={formulasSelecionadas}
          onSelecionarFormula={handleSelecionarFormula}
        />
      </PaperBox>
      <Box mt={2}>
        <Button
          variant='contained'
          color='primary'
          style={{ marginRight: 10 }}
          onClick={salvarImportarFormulas}
        >
          Importar
        </Button>
        <Button
          variant='outlined'
          color='primary'
          onClick={onFecharDrawer}
        >
          Cancelar
        </Button>
      </Box>
    </React.Fragment>
  )
}

ImportarFormula.propTypes = {
  classes: PropTypes.object,
  contexto: PropTypes.object,
  buscarTiposUnidades: PropTypes.func,
  onRecarregarFormulas: PropTypes.func.isRequired,
  onFecharDrawer: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  contexto: state.contexto,
  orcamentos: state.orcamentos
})
export default connect(mapStateToProps, { buscarOrcamentos, buscarTiposUnidades })(ImportarFormula)
