import { FILTRAR_ANALISE_ORCAMENTO, LIMPAR_FILTRO_ANALISE_ORCAMENTO } from '../actions/types'

const reducerFiltroAnaliseOrcamento = (state = {}, action) => {
  switch (action.type) {
    case FILTRAR_ANALISE_ORCAMENTO:
      return { descricao: action.payload }
    case LIMPAR_FILTRO_ANALISE_ORCAMENTO:
      return { descricao: '' }
    default:
      return state
  }
}

export default reducerFiltroAnaliseOrcamento
