import React, { useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem, Paper, TextField, Typography, withStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import TabelaReferenciaPreco from './TabelaReferenciaPreco'
import { context_httpget, context_httppost, context_httpput, httpget } from '../../../../../js/httpRequest'
import { seStringVazia, showErrorMsg, showSuccessMsg, SISTEMAS_REFERENCIAS, UFS } from '../../../../../js/util'
import _ from 'lodash'
import styles from './style'
import Draggable from 'react-draggable'
import { CAMPO_REFERENCIA_PRECO, CAMPOS_CRITERIO } from '../../analise-conteudo/analise-conteudo'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import Select from '@material-ui/core/Select'

const ModalReferenciaPrecolDraggable = (props) => {
  return (
    <Draggable
      handle='#draggable-dialog-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  )
}

const ModalReferenciaPreco = ({ open, close, itemCurvaAbc, onChange, classes }) => {
  const criterioDefault = () => {
    return [{
      campo: CAMPOS_CRITERIO[0],
      texto: itemCurvaAbc.descricaoOrcamento,
      sistemaRef: null,
      uf: null,
      periodo: '',
      periodos: []
    }]
  }

  const contexto = useSelector((state) => state.contexto)
  const [insumos, setInsumos] = useState([])
  const [insumoSelecionado, setInsumoSelecionado] = useState(null)
  const [criterios, setCriterios] = useState(criterioDefault)
  const [criterioReferenciaOrcamento, setCriterioReferenciaOrcamento] = useState(null)

  const getSizeCriterioReferenciaPreco = () => criterios.filter(it => it.campo.value === CAMPO_REFERENCIA_PRECO.value).length

  const criarCriterioReferenciaPrecoOrcamentoDefault = async () => {
    const { sigla, uf, mes, ano } = await context_httpget('referencias-preco/principal', contexto)
    const periodos = await httpget(`/api/referencias-preco/periodos/${sigla}`)
    const criterio = {
      campo: CAMPO_REFERENCIA_PRECO,
      texto: '',
      sistemaRef: sigla,
      uf,
      periodo: periodos.find(it => it.ano === ano && it.mes === mes),
      periodos: periodos
    }
    setCriterioReferenciaOrcamento(criterio)
  }

  const handleBuscarReferenciaPreco = () => {
    validarCriterios()
    context_httppost('itens/referencias-preco/busca', contexto, getCriterios())
      .then((data) => {        
        setInsumos(data)
        setInsumoSelecionado(null)
      })
      .catch(() => showErrorMsg('Erro ao buscar referências de preço'))
  }

  useEffect(() => {
    if (open) {
      handleBuscarReferenciaPreco()
      criarCriterioReferenciaPrecoOrcamentoDefault()
    }
  }, [open])

  const recuperarPeriodos = async (sistemaRef, indexCampo) => {
    const periodos = await httpget(`/api/referencias-preco/periodos/${sistemaRef}`)
    const criteriosAtualizados = criterios.map((it, index) => index === indexCampo ? { ...it, sistemaRef, periodos, uf: '' } : it)
    setCriterios(criteriosAtualizados)
  }

  const getCriterios = () => criterios.map(it => {
    if (getSizeCriterioReferenciaPreco() === 0) {
      return { campo: it.campo.value, texto: it.texto }
    } else {
      return { campo: it.campo.value,
        texto: it.texto,
        referencia: {
          sistema: it.sistemaRef,
          uf: it.uf,
          mes: it.periodo.mes,
          ano: it.periodo.ano
        }
      }
    }
  })

  const validarCriterios = () => {
    if (_.isEmpty(criterios)) {
      const msg = 'Pelo menos um critério deve ser informado'
      showErrorMsg(msg)
      throw msg // eslint-disable-next-line no-throw-literal
    }

    if (getSizeCriterioReferenciaPreco() > 1) {
      const msg = 'Informe apenas uma referência de preço.'
      showErrorMsg(msg)
      throw msg // eslint-disable-next-line no-throw-literal
    }

    criterios.forEach((crit) => {
      if (seStringVazia(crit.texto) && getSizeCriterioReferenciaPreco() === 0) {
        const msg = `É necessário informar um valor para o campo '${crit.campo.label}'.`
        showErrorMsg(msg)
        throw msg // eslint-disable-next-line no-throw-literal
      }
    })
  }

  const handleNovoCriterio = () => {
    const novo = [
      ...criterios,
      { campo: CAMPOS_CRITERIO[0],
        texto: '',
        sistemaRef: null,
        uf: null,
        periodo: '',
        periodos: []
      }
    ]
    setCriterios(novo)
  }

  const selecionarInsumo = (insumo) => {
    setInsumoSelecionado(insumo)
  }

  const handleConfirmarReferenciaPreco = () => {
    if (_.isNil(insumoSelecionado)) {
      showErrorMsg('É obritatório selecionar uma referência de preço.')
    } else {
      let promise

      if (getSizeCriterioReferenciaPreco() === 0) {
        promise = context_httpput(`curva-abc/itens/${itemCurvaAbc.id}/codigo-servico`, contexto, { codigoServico: insumoSelecionado.codigo })
      } else {
        const { sistemaRef, uf, periodo } = criterios.find(it => it.campo === CAMPO_REFERENCIA_PRECO)
        const data = { codigoServico: insumoSelecionado.codigo, referenciaPrecoNaoVinculadoAoOrcamento: { sistema: sistemaRef, uf, mes: periodo.mes, ano: periodo.ano } }
        promise = context_httpput(`curva-abc/itens/${itemCurvaAbc.id}/codigo-servico/referencia-nao-vinculada`, contexto, data)
      }

      promise.then((data) => {
        const novoItem = {
          ...data,
          percentual: itemCurvaAbc.percentual,
          percentualFormatado: itemCurvaAbc.percentualFormatado,
          percentualAcumulado: itemCurvaAbc.percentualAcumulado,
          percentualAcumuladoFormatado: itemCurvaAbc.percentualAcumuladoFormatado
          
        }
        showSuccessMsg('Referência de preço selecionada com sucesso.')
        onChange(itemCurvaAbc, novoItem)
      })
      
    }
  }

  const handleRemoverCriterio = (index) => {
    setCriterios(criterios.filter((_, indexCriterio) => index !== indexCriterio))
  }

  const handleChange = (campo, valor, index) => {
    setCriterios(criterios.map((it, indexMap) => {
      return index === indexMap
        ? { ...it, [campo]: valor }
        : it
    }))

    if (valor.value === CAMPO_REFERENCIA_PRECO.value) {
      const criteriosAtualizados = criterios.map((it, indexCriterio) => (indexCriterio === index ? criterioReferenciaOrcamento : it))
      setCriterios(criteriosAtualizados)
    }

    if (campo === 'sistemaRef') {
      recuperarPeriodos(valor, index)
    }
  }

  const closeModal = () => {
    setCriterios(criterioDefault())
    close()
  }

  const camposBusca = [...CAMPOS_CRITERIO, CAMPO_REFERENCIA_PRECO]
  return (
    <Dialog
      open={open}
      onClose={closeModal}
      maxWidth='lg'
      fullWidth
      aria-labelledby='draggable-dialog-title'
      PaperComponent={ModalReferenciaPrecolDraggable}
    >
      <DialogTitle style={{ cursor: 'move' }} id='draggable-dialog-title'>
        <div>
          <Typography variant='h5' className={classes.tituloModal}>
            ASSOCIAR SERVIÇO
          </Typography>
        </div>
        <div>
          <Typography variant='subtitle2'>
            Utilize a busca para encontrar o serviço relacionado ao item da curva ABC
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        {
          criterios.map((criterio, index) => (
            <Box key={index} display='flex' alignItems='center' mt={2} mb={2}>
              <TextField
                select
                label='Buscar'
                name='campo'
                value={criterio.campo || ''}
                style={{ width: 200, marginRight: 10 }}
                onChange={(e) => handleChange(e.target.name, e.target.value, index)}
                variant='outlined'
              >
                {camposBusca.map((it) =>
                  (<MenuItem key={it.value} value={it}>{it.label}</MenuItem>))
                }
              </TextField>
              {criterio.campo === CAMPO_REFERENCIA_PRECO &&
                <React.Fragment>
                  <FormControl variant='outlined'>
                    <InputLabel id='sistemaRef'>Sistema</InputLabel>
                    <Select
                      labelId='sistemaRef'
                      name='sistemaRef'
                      value={criterio.sistemaRef || ''}
                      onChange={(e) => handleChange(e.target.name, e.target.value, index)}
                      label='Sistema'
                      style={{ width: '150px', marginRight: 10 }}
                    >
                      {
                        SISTEMAS_REFERENCIAS.map((s) => (
                          <MenuItem key={s.nome} value={s.nome}>
                            <Typography>{s.nome}</Typography>
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                  <FormControl variant='outlined'>
                    <InputLabel id='uf'>Uf</InputLabel>
                    <Select
                      labelId='uf'
                      name='uf'
                      value={criterio.uf || ''}
                      onChange={(e) => handleChange(e.target.name, e.target.value, index)}
                      label='Uf'
                      style={{ width: '150px', marginRight: 10 }}
                    >
                      {
                        UFS.map((u) => (
                          <MenuItem key={u.sigla} value={u.sigla}>
                            <Typography>{u.sigla}</Typography>
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                  <FormControl variant='outlined'>
                    <InputLabel id='periodo'>Período</InputLabel>
                    <Select
                      labelId='periodo'
                      name='periodo'
                      value={criterio.periodo}
                      onChange={(e) => handleChange(e.target.name, e.target.value, index)}
                      label='Período'
                      style={{ width: '200px', marginRight: 10 }}
                    >
                      {
                        criterio.periodos.map((p) => (
                          <MenuItem key={p.referencia} value={p}>
                            <Typography>{p.referencia}</Typography>
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </React.Fragment>
              }
              {criterio.campo !== CAMPO_REFERENCIA_PRECO &&
                <TextField
                  name='texto'
                  value={criterio.texto}
                  fullWidth
                  onChange={(e) => handleChange(e.target.name, e.target.value, index)}
                  variant='outlined'
                />
              }
              <Button color='primary' variant='text' className='btn36' onClick={() => handleRemoverCriterio(index)}>
                <Tooltip title='Excluir'>
                  <DeleteIcon fontSize='small' />
                </Tooltip>
              </Button>
            </Box>
          ))
        }

        <Box display='flex' justifyContent='space-between' my={2} mb={4}>
          <Button aria-controls='fade-menu' aria-haspopup='true' onClick={handleNovoCriterio} color='primary' variant='text' size='small'>
            + Novo Critério
          </Button>
          <Button variant='outlined' color='primary' size='small' onClick={handleBuscarReferenciaPreco}>
            Pesquisar
          </Button>
        </Box>

        {_.isEmpty(insumos) ? (
          <Alert
            id='msgInsumosVazio'
            severity='warning'
            style={{ marginTop: 4, marginBottom: 4 }}
          >
            Não foram encontrado serviços com esta descrição.
          </Alert>
        ) : (
          <TabelaReferenciaPreco insumos={insumos} onSelect={selecionarInsumo} />
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={closeModal}>Cancelar</Button>
        <Button
          variant='contained'
          onClick={() => handleConfirmarReferenciaPreco()}
          color='primary'
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ModalReferenciaPreco.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  itemCurvaAbc: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  classes: PropTypes.object
}

export default withStyles(styles)(ModalReferenciaPreco)
