import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import HelpIcon from '@material-ui/icons/Help';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '80ch',
    backgroundColor: theme.palette.background.paper,
  },
  typography: {
    padding: theme.spacing(2),
  },
  inline: {
    display: 'inline',
  },
}));

export default function AnaliseRiscoAjuda() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box mx={2}>
      <Button aria-describedby={id} color='primary' variant='text' size='small' onClick={handleClick}>
        <HelpIcon fontSize='small' />Orientações
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List className={classes.root}>
          <ListItem>
              <ListItemText
              primary="Risco 1"
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    Relativo a erros facilmente aferíveis e corrigíveis (sempre positivo) 
                  </Typography>
                  {' | Diferença entre valor global informado e calculado|) + valor total da inconsistência “Possível diferença de preço em serviços idênticos” + valor total da inconsistência “Erro de cálculo” (módulo) '}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider/>
          <ListItem>
              <ListItemText
              primary="Risco 2"
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    BDI
                  </Typography>
                  {' | ((BDI do orçamento – BDI máximo (terceiro quartil) por tipo de obra) * Valor global do orçamento - calculado) considerar apenas se BDI do orçamento for superior ao BDI máximo, caso contrário considerar risco igual a zero'}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider/>
          <ListItem>
              <ListItemText
              primary="Risco 3"
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    Resultado Possível Sobrepreço ou Medição Indevida (Acórdão 2622/2013)
                  </Typography>
                  {' | Valor total do sobrepreço da amostra da curva abc'}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider/>
          <ListItem>
              <ListItemText
              primary="Risco 4"
              secondary={
                <React.Fragment>
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="textPrimary"
                  >
                    Análise de conteúdo
                  </Typography>
                  {' | Valor total de todas as inconsistências apontadas na análise de conteúdo que não passaram no critério da tipologia '}
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
      </Popover>
    </Box>
  );
}
