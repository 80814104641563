import React, { Component } from 'react'
import { MenuItem, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import styles from '../styles'

export class IconeMenuIncluirNaAmostra extends Component {
  static propTypes = {
    contexto: PropTypes.object,
    classes: PropTypes.object,
    onClick: PropTypes.func,
  }

  render() {
    const { classes, onClick } = this.props
    return (
      <MenuItem
        value="check"
        onClick={onClick}
        className={classes.itemMenuOpcoes}
      >
        <Typography className={classes.tituloItem}>
          Incluir na amostra <br />
          <span className={classes.subtituloItem}>
            Inclui automaticamente na amostra todos os itens que possuem uma
            referência de preço válida
          </span>
        </Typography>
      </MenuItem>
    )
  }
}

const mapStateToProps = ({ contexto }) => ({ contexto })

export default connect(mapStateToProps)(
  withStyles(styles)(IconeMenuIncluirNaAmostra)
)
