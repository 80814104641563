import React, { Component } from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../styles'
import _ from 'lodash'
import Button from '@material-ui/core/Button'
import axios from 'axios'
import Sistemas from './Sistemas'
import UFs from './UFs'
import Periodos from './Periodos'
import TiposInsumo from './TiposInsumo'
import TermoBusca from './TermoBusca'
import { SLOW_REQUEST_CONFIG } from '../../../js/operationProgressUtil'
import ListaInsumos from './ListaInsumos'
import { showErrorMsg } from '../../../js/util'
import MenuSistemaReferencia from './MenuSistemaReferencia'
import PropTypes from 'prop-types'
import { httpget } from '../../../js/httpRequest'

const defaultState = {
  sistema: 'SICRO',
  sistemaEmpty: false,
  uf: '',
  ufEmpty: false,
  periodo: '',
  periodoEmpty: false,
  tipoInsumo: '',
  tipoInsumoEmpty: false,
  termoBusca: '',
  insumos: [],
  totalItens: 0,
  pagina: 0,
  registrosPorPagina: 10,
  periodos: [],
  temPesquisa: false
}

class ReferenciaPreco extends Component {

  static propTypes = {
    classes: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = defaultState
  }

  componentDidMount() {
    this.recuperarPeriodos()
  }

  componentDidUpdate(prevProps, prevState) {
    this.validarAtributo(prevState, 'sistema')
    this.validarAtributo(prevState, 'uf')
    this.validarAtributo(prevState, 'periodo')
    this.validarAtributo(prevState, 'tipoInsumo')
  }

  recuperarPeriodos = () => {
    const { sistema } = this.state
    axios.get(`/api/referencias-preco/periodos/${sistema}`, SLOW_REQUEST_CONFIG)
      .then((resp) => {
        resp.data.sort((a, b) => b.mes - a.mes).sort((a, b) => b.ano - a.ano)
        this.setState({
          periodos: resp.data
        })
      })
      .catch((e) => showErrorMsg(e + 'Erro ao recuperar períodos'))
  }

  validarAtributo = (prevState, attr) => {
    if (prevState[attr] !== this.state[attr]) {
      this.validarPreenchimentoAtributo(attr)
    }
  }

  validarPreenchimentoAtributo = (attr) => {
    const state = {}
    const empty = _.isEmpty(this.state[attr])
    state[`${attr}Empty`] = empty
    this.setState(state)
    return !empty
  }

  handleChange = (e, attr) => {
    const state = {}
    state[attr] = e.target.value
    this.setState(state)
  }

  handleCampoSistemaChange = (e, attr) => {
    const state = {}
    state[attr] = e.target.value
    this.setState(state, () => { this.recuperarPeriodos() })
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.pesquisar()
    }
  }

  validar = () => {
    // eslint-disable-next-line no-bitwise
    return (
      this.validarPreenchimentoAtributo('sistema')
      & this.validarPreenchimentoAtributo('uf')
      & this.validarPreenchimentoAtributo('periodo')
      & this.validarPreenchimentoAtributo('tipoInsumo')
    )
  }

  pesquisar = () => {
    if (this.validar()) {
      this.setState({
        insumos: [],
        pagina: 0
      }, () => this.recuperarInsumos())
    }
  }

  recuperarInsumos = () => {
    const { sistema, uf, periodo, tipoInsumo, termoBusca, insumos } = this.state
    const from = _.isEmpty(insumos) ? 0 : insumos.length

    httpget(`/api/insumos/${sistema}/${uf}/${periodo.mes}/${periodo.ano}/${tipoInsumo}?busca=${termoBusca}&from=${from}&size=1000`)
      .then((data) => {
        const insumosCatalogos = _.isEmpty(data.insumosCatalogos) ? data.insumosCatalogos : insumos.concat(data.insumosCatalogos)
        this.setState({
          insumos: insumosCatalogos,
          totalItens: data.totalItens,
          temPesquisa: true
        })
      })
      .catch(() => showErrorMsg('Erro ao pesquisar referência de preços'))
  }

  handlePageChange = (e, pag) => {
    const { insumos, registrosPorPagina } = this.state

    if ((registrosPorPagina * pag) === insumos.length) {
      this.recuperarInsumos()
    }

    this.setState({ pagina: pag })
  }

  handleRowsPerPageChange = (e) => this.setState({ pagina: 0, registrosPorPagina: e.target.value })

  render() {
    const {classes} = this.props
    const {
      sistema, sistemaEmpty,
      uf, ufEmpty,
      periodo, periodoEmpty,
      tipoInsumo, tipoInsumoEmpty,
      termoBusca,
      insumos,
      pagina, registrosPorPagina, periodos, totalItens, temPesquisa
    } = this.state

    return (
      <React.Fragment>
        <div className='gridReferencia'>
          <Sistemas
            value={sistema}
            empty={sistemaEmpty}
            onChange={(e) => this.handleCampoSistemaChange(e, 'sistema')}
          />

          <UFs
            value={uf}
            empty={ufEmpty}
            onChange={(e) => this.handleChange(e, 'uf')}
          />

          <Periodos
            value={periodo}
            empty={periodoEmpty}
            onChange={(e) => this.handleChange(e, 'periodo')}
            periodos={periodos}
          />

          <TiposInsumo
            value={tipoInsumo}
            empty={tipoInsumoEmpty}
            onChange={(e) => this.handleChange(e, 'tipoInsumo')}
          />

          <TermoBusca
            value={termoBusca}
            onChange={(e) => this.handleChange(e, 'termoBusca')}
            onKeyPress={(e) => this.handleKeyPress(e)}
          />
        </div>

        <div className={classes.botoesPesquisa}>
          <Button variant={'outlined'} color={'primary'} onClick={() => this.pesquisar()}>Pesquisar</Button>

          {insumos.length === 0
            ? null
            : (<MenuSistemaReferencia
                insumos={insumos}
                totalItens={totalItens}
                pesquisa={{sistema, uf, periodo, tipoInsumo, termoBusca}}
              />
            )
          }
        </div>

        {
          !temPesquisa ? null : (
            <ListaInsumos
              pagina={pagina}
              registrosPorPagina={registrosPorPagina}
              onChangePage={(e, pag) => this.handlePageChange(e, pag)}
              onChangeRowsPerPage={(e) => this.handleRowsPerPageChange(e)}
              insumos={insumos}
              totalItens={totalItens}
              tipoInsumo={tipoInsumo}
            />
            )
        }
      </React.Fragment>
    )
  }
}

export default withStyles(styles)(ReferenciaPreco)
