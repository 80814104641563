import React from 'react'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TableRow from '@material-ui/core/TableRow'
import { Checkbox } from '@material-ui/core'
import ReactTooltip from 'react-tooltip'

const ItemVincularTipologiaCombinada = ({
  classes,
  tipologia,
  isChecked,
  isDisabled,
  onSelecionarTipologia
}) => {
  return (
    <React.Fragment>
      <TableRow>
        <TableCell width={'5%'} rowSpan={2} className={classes.tabelaExpressao}>
          <a data-tip data-for={`descricaoID${tipologia.tipologiaCombinada.codigo}`}>
            <Checkbox
              color={'primary'}
              checked={isChecked}
              disabled={isDisabled}
              onChange={(e) => onSelecionarTipologia(e, tipologia.tipologiaCombinada.codigo)}
            />
          </a>
          {isDisabled && (
            <ReactTooltip
              id={`descricaoID${tipologia.tipologiaCombinada.codigo}`}
              place='right'
              type='dark'
              effect='solid'
            >
              <span>Esta tipologia combinada já está vinculada ao tema</span>
            </ReactTooltip>
          )}
        </TableCell>
        <TableCell width='20%'>
          <Typography>{tipologia.tipologiaCombinada.nome}</Typography>
        </TableCell>
        <TableCell width='20%'>
          <Typography>{tipologia.nomeOrcamento}</Typography>
        </TableCell>
        <TableCell width='20%'>
          <Typography>{tipologia.nomeObra}</Typography>
        </TableCell>
        <TableCell align='right' width='20%'>
          <Typography>{tipologia.nomeEmpreendimento}</Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={4}>
          <strong>Descrição: </strong> {tipologia.descricao}
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

ItemVincularTipologiaCombinada.propTypes = {
  classes: PropTypes.object,
  tipologia: PropTypes.object,
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onSelecionarTipologia: PropTypes.func.isRequired
}
export default ItemVincularTipologiaCombinada
