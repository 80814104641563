import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TableCell from '@material-ui/core/TableCell'
import Typography from '@material-ui/core/Typography'
import TableRow from '@material-ui/core/TableRow'
import { Checkbox } from '@material-ui/core'
import ReactTooltip from 'react-tooltip'

class ItemVincularExpressoes extends Component {
  static propTypes = {
    classes: PropTypes.object,
    expressao: PropTypes.object,
    isChecked: PropTypes.bool,
    isDisabled: PropTypes.bool,
    onSelecionarExpressao: PropTypes.func.isRequired,
  }

  render () {
    const {
      classes,
      expressao,
      isChecked,
      isDisabled,
      onSelecionarExpressao
    } = this.props
    return (
      <React.Fragment>
        <TableRow>
          <TableCell width={'5%'} rowSpan={2} className={classes.tabelaExpressao}>
            <a data-tip data-for={`descricaoID${expressao.codExpressao}`}>
              <Checkbox
                color={'primary'}
                checked={isChecked}
                disabled={isDisabled}
                onChange={(e) => onSelecionarExpressao(e, expressao)}
                />
              </a>
              { isDisabled && <ReactTooltip  id={`descricaoID${expressao.codExpressao}`} place='right' type='dark' effect='solid'>
                <span>Esta expressão já está vinculada ao tema</span>
              </ReactTooltip> }
          </TableCell>
          <TableCell width={'20%'}>
            <Typography>{expressao.nomeExpressao}</Typography>
          </TableCell>
          <TableCell width={'20%'}>
            <Typography>{expressao.nomeOrcamento}</Typography>
          </TableCell>
          <TableCell width={'20%'}>
            <Typography>{expressao.nomeObra}</Typography>
          </TableCell>
          <TableCell align='right' width={'20%'}>
            <Typography>{expressao.nomeEmpreendimento}</Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={4}>
            <strong>Descrição: </strong> {expressao.descricaoExpressao}
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }
}

export default ItemVincularExpressoes
