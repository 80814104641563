import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { converterMoedaBRParaNumero, formatarNumeroParaMoedaBR, handleCampoNumericoChange, mapStateToPropsContexto, seStringVazia, showErrorMsg, showSuccessMsg } from '../../../../../../../../js/util'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import TituloVoltar from '../../../../../../../comum/TituloVoltar'
import { context_httpget, context_httppost } from '../../../../../../../../js/httpRequest'
import { connect } from 'react-redux'
import _ from 'lodash'
import FormControl from '@material-ui/core/FormControl'
import InsumoAdicionar from '../tempos-fixos/InsumoAdicionar'
import ComposicaoAdicionarAlterarCodigoTransporte from '../tempos-fixos/ComposicaoAdicionarAlterarCodigoTransporte'
import { TIPO_INSUMO_COMPOSICAO_DESTE_ORCAMENTO, TIPO_INSUMO_COMPOSICAO_REFERENCIA_PRECO, TIPO_INSUMO_MATERIAL_DESTE_ORCAMENTO } from '../composicoesAuxiliares'
import PesquisarComposicaoReferenciaPreco from '../../PesquisarComposicaoReferenciaPreco'


const OPCOES_TRANSPORTE = [
  TIPO_INSUMO_COMPOSICAO_DESTE_ORCAMENTO,
  TIPO_INSUMO_COMPOSICAO_REFERENCIA_PRECO,
]

const defaultState = {
  tipoInsumo: TIPO_INSUMO_MATERIAL_DESTE_ORCAMENTO.value,
  tipoTransporte: TIPO_INSUMO_COMPOSICAO_DESTE_ORCAMENTO.value,
  materiais: [],
  composicoes: [],
  insumo: null,
  insumoEmpty: false,
  transporte: null,
  transporteEmpty: false,
  quantidade: '',
  quantidadeEmpty: false,
  descricao: '',
  descricaoEmpty: false,
  unidade: '',
  unidadeEmpty: false,
  dmt: '',
  dmtEmpty: false,
  tipo: '',
  tipoEmpty: false,
  sugestoesDmtPadrao: [],
  fit: 0.0,
}

class EditarCodigoTransporte extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired,
    contexto: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = defaultState
    this.handleChange = this.handleChange.bind(this)
    this.handleSalvarClick = this.handleSalvarClick.bind(this)
    
  }


  componentDidMount() {
    if (!_.isNil(this.props.contexto)) {
      this.recuperarOpcoesTransporte()
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contexto !== this.props.contexto) {
      this.recuperarOpcoesTransporte()
    }
  }

  async recuperarOpcoesTransporte() {
    const { contexto, codigoComposicao } = this.props
    const data = await context_httpget(`precos/transportes/opcoes?composicao=${codigoComposicao}`, contexto)
    console.log(data)
    if (data) {
      this.setState({
        materiais: data.materiais,
        composicoes: data.composicoes
      })
    }
  }

  handleChangeInsumo = (value, campo) => {
    this.setState({
      tipo: '',
      unidade: '',
      dmt: '',
      sugestoesDmtPadrao: [],
      transporte: null
    })
    this.handleChange(value, campo)
  }


  handleCampoTextoChange(event, campo) {
    this.handleChange(event.target.value, campo)
  }

  handleChange(value, campo) {
    const state = {}
    state[campo] = value
    state[`${campo}Empty`] = _.isNil(value) || _.isEmpty(value)
    this.setState(state)
  }


  async handleSalvarClick() {
    
    
    const { insumo, quantidade, descricao, unidade, dmt, transporte, tipoInsumo, tipoTransporte, tipo, fit } = this.state
    const {transporteSelecionado} = this.props
   
      const dadosSalvar = {
        
        transporte: {
          codigo: transporte.codigo,
          sistema: transporte.sistema || transporte.sistemaReferencia,
          uf: transporte.uf,
          mes: transporte.mes,
          ano: transporte.ano,
          descricao: transporte.descricao,
          codigoAntigo: transporteSelecionado.codigoComposicaoTransporte,
          codigoInsumo: transporteSelecionado.codigoInsumo,
          tipoRodovia: transporteSelecionado.tipoRodovia,
        },
        fit: fit
      }

      const { contexto, codigoComposicao, onDadosAlterados } = this.props
      
      console.log(dadosSalvar.transporte)
      const resultado = await context_httppost('precos/transportes/editar-codigo-transporte', contexto, dadosSalvar.transporte)
      
      if (resultado) {
        showSuccessMsg('Código de Transporte alterado com sucesso.')

        window.location.reload();
       
      }
}

  render() {
    const { onCancelar } = this.props
    const {
      tipoInsumo,
      tipoTransporte,
      materiais,
      composicoes,
      insumo,
      insumoEmpty,
      quantidade,
      quantidadeEmpty,
      descricao,
      descricaoEmpty,
      unidade,
      unidadeEmpty,
      dmt,
      dmtEmpty,
      transporte,
      transporteEmpty,
      tipo,
      tipoEmpty,
      sugestoesDmtPadrao
    } = this.state

    return (
      <>
      <TituloVoltar onClick={onCancelar} label='Voltar para Transportes' />

      <Box mt={4} ml={2}>
        <Typography variant='overline'>Informe a composição de transporte:</Typography>
        </Box>

        <Box mb={2} ml={2} mr={15}>
          <TextField
            fullWidth
            value={tipoTransporte}
            onChange={(e) => this.handleCampoTextoChange(e, 'tipoTransporte')}
            variant='outlined'
            select
          >
            {OPCOES_TRANSPORTE.map((it) => (
              <MenuItem key={it.value} fullWidth value={it.value}>{it.label}</MenuItem>
            ))}
          </TextField>
        </Box>

        <Box mb={2} ml={2} >
          <ComposicaoAdicionarAlterarCodigoTransporte
            tipoComposicao={tipoTransporte}
            composicao={transporte}
            composicaoEmpty={transporteEmpty}
            handleChange={this.handleChange}
            nomeAtributo='transporte'
            composicoes={composicoes}
          />
        </Box>
      

      <Box mt={3} ml={2}>
        <MesmaLinha>
          <Button color='primary' variant='contained' onClick={this.handleSalvarClick}>Salvar</Button>
          <Button color='primary' onClick={onCancelar}>Cancelar</Button>
        </MesmaLinha>
      </Box>
    </>
    )
  }
}

export default connect(mapStateToPropsContexto)(EditarCodigoTransporte)
