import React from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import BuildIcon from '@material-ui/icons/Build'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ReactTooltip from 'react-tooltip'
import Expressoes from '../personalizadas/expressoes/Expressoes'
import Formulas from '../personalizadas/formulas/Formulas'
import { TEMATICA } from '../../analise-conteudo'
import { hasAutorities } from '../../../../../../security/securityContext'
import TipologiasCombinadas from '../personalizadas/tipologiasCombinadas/TipologiasCombinadas'
import { PERFIL } from '../../../../../../js/perfis'

const Tematicas = ({
  classes,
  temaAtivo,
  expressoes,
  formulas,
  tipologiasCombinadas,
  contexto,
  onRecarregarExpressoes,
  onRemoverExpressaoDoEstado,
  onRecarregarFormulas,
  onRemoverFormulaDoEstado,
  onRecarregarTipologiaCombinadas,
  onRemoverTipologiaCombinadaDoEstado,
  onAtualizarDisponibilidadeExpressao,
  onAtualizarDisponibilidadeFormula,
  onChangeOperadorTipologiaCombinada
}) => {
  const history = useHistory()
  const handleClickGerenciarTemas = () =>
    history.push(`/empreendimentos/${contexto.obra.codEmpreendimento}/obras/${contexto.obra.cod}/orcamentos/${contexto.orcamento.cod}/analise-conteudo/gerenciar-temas/${temaAtivo.codigo}`)

  return (
    <React.Fragment>
      <Box>
        <Box display='flex' justifyContent='space-between' alignItems='flex-end'>
          <Typography variant='subtitle1' gutterBottom>
            {temaAtivo.nome}
          </Typography>
          {hasAutorities([PERFIL.GESTOR_TCU]) && (
            <Button variant='outlined' size='small' onClick={handleClickGerenciarTemas}><BuildIcon fontSize='small' />Gerenciar Tema</Button>
          )}
        </Box>
        <Typography color='textSecondary' className={classes.cardTemaSidebarSubtitle}>
          {temaAtivo.descricao}
        </Typography>
        <ReactTooltip id={`configurarID${temaAtivo.codigo}`} place='top' type='dark' effect='solid'>Configurar tema em uma nova janela</ReactTooltip>
      </Box>
      <Expressoes
        classes={classes}
        expressoes={expressoes}
        tipoAnaliseSelecionada={TEMATICA}
        onRecarregarExpressoes={onRecarregarExpressoes}
        onRemoverExpressaoDoEstado={onRemoverExpressaoDoEstado}
        onAtualizarDisponibilidadeExpressao={onAtualizarDisponibilidadeExpressao}
      />
      <Formulas
        classes={classes}
        formulas={formulas}
        tipoAnaliseSelecionada={TEMATICA}
        onRecarregarFormulas={onRecarregarFormulas}
        onRemoverFormulaDoEstado={onRemoverFormulaDoEstado}
        onAtualizarDisponibilidadeFormula={onAtualizarDisponibilidadeFormula}
      />
      <TipologiasCombinadas
        classes={classes}
        tipologiasCombinadas={tipologiasCombinadas}
        tipoAnaliseSelecionada={TEMATICA}
        onChangeOperador={onChangeOperadorTipologiaCombinada}
        onRecarregarTipologiaCombinadas={onRecarregarTipologiaCombinadas}
        onRemoverTipologiaCombinadaDoEstado={onRemoverTipologiaCombinadaDoEstado}
      />
    </React.Fragment>
  )
}

Tematicas.propTypes = {
  classes: PropTypes.object,
  temaAtivo: PropTypes.object,
  expressoes: PropTypes.array,
  formulas: PropTypes.array,
  tipologiasCombinadas: PropTypes.array,
  contexto: PropTypes.object,
  onRecarregarExpressoes: PropTypes.func.isRequired,
  onRecarregarFormulas: PropTypes.func.isRequired,
  onRecarregarTipologiaCombinadas: PropTypes.func.isRequired,
  onRemoverExpressaoDoEstado: PropTypes.func.isRequired,
  onRemoverFormulaDoEstado: PropTypes.func.isRequired,
  onRemoverTipologiaCombinadaDoEstado: PropTypes.func.isRequired,
  onAtualizarDisponibilidadeExpressao: PropTypes.func.isRequired,
  onAtualizarDisponibilidadeFormula: PropTypes.func.isRequired,
  onChangeOperadorTipologiaCombinada: PropTypes.func.isRequired
}

export const mapStateToProps = ({ contexto }) => ({
  contexto
})

export default connect(mapStateToProps)(Tematicas)
