import { tabelaExpressoes } from './TabelaExpressoes'
import { tabelaFormulas } from './TabelaFormulas'
import { tabelaTipologiasCombinadas } from './TabelaTipologiasCombinadas'

export function dadosOrcamentoPDF (doc, orcamento) {
  let posicaoY = 40
  const posicaoX = 30
  doc.setFontSize(12)
  doc.setDrawColor(200, 200, 200)
  doc.text('Verificações específicas deste orçamento ', posicaoX, posicaoY)

  posicaoY += 15
  doc.setFontSize(10)
  doc.setDrawColor(200, 200, 200)
  doc.text('Conjuto de fórmulas e expressões que verifica vários tipos de quantitativos e taxas em orçamentos da Seinfra.', posicaoX, posicaoY)
  const posicaoTabelaExpressoes = tabelaExpressoes(doc, orcamento.expressoes, posicaoX, posicaoY)
  const posicaoTabelaFormulas = tabelaFormulas(doc, orcamento.formulas, posicaoTabelaExpressoes)
  return tabelaTipologiasCombinadas(doc, orcamento.combinadas, posicaoTabelaFormulas)
}
