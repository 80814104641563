import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import PropTypes from 'prop-types'
import { CAMPO_CODIGO, CAMPO_DESCRICAO, CAMPO_UNIDADE } from '../../../../analise-conteudo'

const CampoCriterio = ({ onChange, value }) => (
  <TextField select value={value} onChange={onChange} size='small'>
    <MenuItem value={CAMPO_DESCRICAO}>{CAMPO_DESCRICAO.label}</MenuItem>
    <MenuItem value={CAMPO_UNIDADE}>{CAMPO_UNIDADE.label}</MenuItem>
    <MenuItem value={CAMPO_CODIGO}>{CAMPO_CODIGO.label}</MenuItem>
  </TextField>
)

CampoCriterio.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired
}

export default CampoCriterio
