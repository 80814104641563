import React from 'react'
import _ from 'lodash'
import { CircularProgress, TableCell } from '@material-ui/core'
import PropTypes from 'prop-types'
import { formatarNumeroParaMoedaBR } from '../../../../../../js/util'

const CelulaValorTotalReferencia = ({ item, hideLoadingPrecos }) => {
  if (_.isNil(item.sistemaReferencia)) return <TableCell align='right'>-</TableCell>
  return (
    <TableCell align='right'>
      {
        hideLoadingPrecos
          ? formatarNumeroParaMoedaBR(item.valorTotalReferenciaComBdi, 2, 2)
          : <CircularProgress size={20} />
      }
    </TableCell>
  )
}

CelulaValorTotalReferencia.propTypes = {
  item: PropTypes.object.isRequired,
  hideLoadingPrecos: PropTypes.bool.isRequired
}

export default CelulaValorTotalReferencia
