import React from 'react'
import ReactFileReader from 'react-file-reader'
import Button from '@material-ui/core/Button'
import PublishIcon from '@material-ui/icons/Publish'
import DetalhesArquivo from './DetalhesArquivo'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import FormData from 'form-data'
import { SLOW_REQUEST_CONFIG } from '../../../../js/operationProgressUtil'

export const textoBotao = (arquivo) => (arquivo == null ? 'Carregar Arquivo' : 'Alterar Arquivo')

const Arquivo = ({ arquivo, onArquivoCarregado }) => {
  const getFiles = async (file) => {
    const { infected } = await rodarAntivirus(file)
    if (!infected) {
      onArquivoCarregado(file)
    }
  }

  const rodarAntivirus = async (file) => {
    const formData = new FormData()
    formData.append('file', file)
    const { data } = await axios.post('/api/validar-arquivo', formData, SLOW_REQUEST_CONFIG)
    return data
  }

  return (
    <div>
      <Typography variant='subtitle1'>Arquivo de orçamento</Typography>
      <ReactFileReader handleFiles={(files) => getFiles(files[0])} fileTypes={['.csv', '.txt']}>
        <Button variant='contained' color='primary' startIcon={<PublishIcon />}>
          { textoBotao(arquivo) }
        </Button>
      </ReactFileReader>
      {
        arquivo != null && <DetalhesArquivo arquivo={arquivo} />
      }
    </div>
  )
}

Arquivo.propTypes = {
  onArquivoCarregado: PropTypes.func,
  arquivo: PropTypes.object
}

export default Arquivo
