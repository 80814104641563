import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import { BUSCAR_TIPOS_ORCAMENTOS } from '../types'

const buscarTiposOrcamentos = () => async (dispatch) => {
  const success = await axios.get('/api/orcamentos/tipos', SLOW_REQUEST_CONFIG)
  return dispatch({
    type: BUSCAR_TIPOS_ORCAMENTOS,
    payload: success
  })
}

export default buscarTiposOrcamentos
