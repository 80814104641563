import React, { Component } from 'react'
import { Accordion, AccordionDetails, Box, Button, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import AnaliseConteudoContentItem from '../../AnaliseConteudoContentItem'
import { calcularQuantidadeTotalItensOrcamentarios, calcularTotalItensOrcamentarios, calcularTotalPercentual, calcularTotalValorParcial, PERSONALIZADA, TEMATICA } from '../../../analise-conteudo'
import { formatarNumeroParaMoedaBR } from '../../../../../../../js/util'
import PropTypes from 'prop-types'
import StyledDrawer from '../../../../../../comum/StyledDrawer'
import StyledAccordionSumary from '../../../../../../comum/StyledAccordionSumary'
import Expressao from './Expressao'
import ModalExcluirExpressao from './ModalExcluirExpressao'
import ModalCriarTipologiaExpressao from './tipologia/ModalCriarTipologiaExpressao'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import SimplePopover from '../../../../../../comum/SimplePopover'
import ImportarExpressao from './ImportarExpressao'
import NenhumResultadoEncontrado from '../../comum/NenhumResultadoEncontrado'
import { hasAutorities } from '../../../../../../../security/securityContext'
import { PERFIL } from '../../../../../../../js/perfis'

class Expressoes extends Component {
  static propTypes = {
    classes: PropTypes.object,
    expressoes: PropTypes.array,
    tipoAnaliseSelecionada: PropTypes.string.isRequired,
    onRecarregarExpressoes: PropTypes.func.isRequired,
    onRemoverExpressaoDoEstado: PropTypes.func.isRequired,
    onAtualizarDisponibilidadeExpressao: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.state = {
      expandedPanel: true,
      expressaoAtiva: null,
      expressaoExcluir: null,
      expressoesVinculadasExcluir: [],
      formulasVinculadasExcluir: [],
      tipologiasCombinadaVinculadasExcluir: [],
      openModalCriarTipologia: false,
      openDrawerAdicionarExpressao: false,
      openDrawerImportarExpressao: false
    }
    this.handleExpressaoChange = this.handleExpressaoChange.bind(this)
    this.handleEditarExpressaoChange = this.handleEditarExpressaoChange.bind(this)
    this.handleExpressoesEFormulasVinculadasChange = this.handleExpressoesEFormulasVinculadasChange.bind(this)
    this.handleAtualizarExpressaoTipologia = this.handleAtualizarExpressaoTipologia.bind(this)
    this.handleToggleDrawerImportar = this.handleToggleDrawerImportar.bind(this)
    this.handleFecharDrawer = this.handleFecharDrawer.bind(this)
    this.handleFecharModal = this.handleFecharModal.bind(this)
    this.handleToggleModalTipologia = this.handleToggleModalTipologia.bind(this)
  }

  handleOpenDrawer(e, openDrawerAdicionarExpressao) {
    e.stopPropagation()
    this.setState({
      expressaoAtiva: null,
      openDrawerAdicionarExpressao
    })
  }

  handleFecharDrawer = () => {
    const { onRecarregarExpressoes } = this.props
    this.setState({ openDrawerAdicionarExpressao: !this.state.openDrawerAdicionarExpressao })
    onRecarregarExpressoes()
  }

  handleExpressaoChange(expressaoAtiva) {
    this.setState({
      expressaoAtiva
    })
  }

  handleEditarExpressaoChange(expressaoAtiva) {
    this.setState({
      expressaoAtiva,
      openDrawerAdicionarExpressao: true
    })
  }

  handleFecharModal() {
    this.setState({
      expressaoExcluir: null,
      expressoesVinculadasExcluir: [],
      formulasVinculadasExcluir: [],
      tipologiasCombinadaVinculadasExcluir: []
    })
  }

  handleExpressoesEFormulasVinculadasChange(expressoes, formulas, combinadas) {
    this.setState({
      expressoesVinculadasExcluir: expressoes,
      formulasVinculadasExcluir: formulas,
      tipologiasCombinadaVinculadasExcluir: combinadas
    })
  }

  handleOnCompleteModalExcluirExpressao = () => {
    const { expressaoExcluir } = this.state
    const { onRemoverExpressaoDoEstado } = this.props
    this.setState({
      expressaoExcluir: null
    }, () => onRemoverExpressaoDoEstado(expressaoExcluir))
  }

  handleToggleModalTipologia(toggle) {
    this.setState({ openModalCriarTipologia: toggle })
  }

  handleAtualizarExpressaoTipologia(toggle, expressao) {
    const { onRecarregarExpressoes } = this.props
    this.setState({
      openModalCriarTipologia: toggle,
      expressaoAtiva: expressao
    }, () => onRecarregarExpressoes())
  }

  handleChangePanel = (panel) => (event, newExpanded) => {
    this.setState({ expandedPanel: newExpanded ? panel : false })
  }

  handleToggleDrawerImportar(event) {
    const { openDrawerImportarExpressao } = this.state
    if (event)
      event.stopPropagation()
    this.setState({
      openDrawerImportarExpressao: !openDrawerImportarExpressao
    })
  }

  render() {
    const { classes, expressoes, tipoAnaliseSelecionada, onRecarregarExpressoes, onAtualizarDisponibilidadeExpressao } = this.props
    const {
      expandedPanel,
      expressaoAtiva,
      openDrawerAdicionarExpressao,
      expressoesVinculadasExcluir,
      formulasVinculadasExcluir,
      tipologiasCombinadaVinculadasExcluir,
      expressaoExcluir,
      openModalCriarTipologia,
      openDrawerImportarExpressao
    } = this.state
    return (
      <React.Fragment>
        <Box mt={2}>
          <Accordion
            className={classes.accordionAnaliseConteudo}
            square
            expanded={expandedPanel === true}
            onChange={this.handleChangePanel(true)}>
            <StyledAccordionSumary
              expandIcon={<ExpandMoreIcon />}
              aria-controls='panel1a-content'
              id='accordionExpressoes'
              className={classes.accordionSummaryAnalise}
            >
              <Box display='flex' alignItems='center'>
                <Typography variant='subtitle2' className={classes.tituloAccordionAnaliseConteudo}>Expressões</Typography>
                <SimplePopover>
                  Conjunto de critérios de pesquisa combinados logicamente para identificar ocorrências de determinados padrões nos itens de orçamento.
                  Uma expressão pode ser utilizada para definir uma tipologia a ser considerada nas inconsistências do orçamento.
                </SimplePopover>

                {tipoAnaliseSelecionada === PERSONALIZADA &&
                  <Box display='flex'>
                    <Button variant='text' color='primary' onClick={(e) => this.handleOpenDrawer(e, true)} style={{ marginRight: 5 }}>
                      <AddIcon fontSize='small' color='primary' /> Adicionar
                    </Button>
                    <Button variant={'text'} color='primary' onClick={(e) => this.handleToggleDrawerImportar(e)}>
                      <CloudUploadIcon fontSize='small' color='primary' style={{ marginRight: 5 }} /> Importar
                    </Button>
                  </Box>
                }
              </Box>
            </StyledAccordionSumary>
            <AccordionDetails style={{ display: 'grid' }}>
              {expressoes.length === 0 && <NenhumResultadoEncontrado titulo='Nenhuma expressão encontrada' subtitulo='Cadastre expressões personalizadas' />}
              {expressoes.map(expressao => (
                <AnaliseConteudoContentItem
                  key={expressao.codigo}
                  classes={classes}
                  tipoAnaliseSelecionada={tipoAnaliseSelecionada}
                  codigo={expressao.codigo}
                  nome={expressao.nome}
                  descricao={expressao.descricao}
                  seDisponivel={expressao.seDisponivel}
                  qtdInsumos={calcularTotalItensOrcamentarios(expressao.resultado)}
                  qtdItensOrcamentarios={formatarNumeroParaMoedaBR(calcularQuantidadeTotalItensOrcamentarios(expressao.resultado), 2, 3)}
                  valorTotal={formatarNumeroParaMoedaBR(calcularTotalValorParcial(expressao.resultado), 2, 3)}
                  percentual={formatarNumeroParaMoedaBR(calcularTotalPercentual(expressao.resultado), 2, 3)}
                  tipologia={expressao.tipologia}
                  tipologiaPossuiInconsistencia={expressao.tipologiaPossuiInconsistencia}
                  onEditarChange={this.handleEditarExpressaoChange}
                  onExcluirClick={() => this.setState({ expressaoExcluir: expressao })}
                  onAtualizarTipologia={this.handleAtualizarExpressaoTipologia}
                  onAtualizarDisponibilidade={onAtualizarDisponibilidadeExpressao}
                />
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
        <StyledDrawer
          classes={{ paper: classes.paperDrawer }}
          open={openDrawerAdicionarExpressao}
          anchor={'right'}
          onClose={this.handleFecharDrawer}>
          <Expressao
            expressaoAtiva={expressaoAtiva}
            tipoAnaliseSelecionada={tipoAnaliseSelecionada}
            onFecharDrawer={this.handleFecharDrawer}
            onExpressaoChange={this.handleExpressaoChange}
            onCriarTipologia={this.handleToggleModalTipologia}
          />
        </StyledDrawer>
        <StyledDrawer
          classes={{ paper: classes.paperImportarDrawer }}
          open={openDrawerImportarExpressao}
          anchor={'right'}
          onClose={(e) => this.handleToggleDrawerImportar(e)}>

          <ImportarExpressao
            classes={classes}
            onRecarregarExpressoes={onRecarregarExpressoes}
            onFecharDrawer={this.handleToggleDrawerImportar}
          />
        </StyledDrawer>
        <ModalExcluirExpressao
          expressao={expressaoExcluir}
          expressoesVinculadas={expressoesVinculadasExcluir}
          formulasVinculadas={formulasVinculadasExcluir}
          tipologiasCombinadas={tipologiasCombinadaVinculadasExcluir}
          onExpressoesEFormulasVinculadasChange={this.handleExpressoesEFormulasVinculadasChange}
          fechar={this.handleFecharModal}
          onComplete={() => this.handleOnCompleteModalExcluirExpressao()}
        />
        {openModalCriarTipologia && (
          <ModalCriarTipologiaExpressao
            isModeReadOnly={!hasAutorities([PERFIL.GESTOR_TCU]) && tipoAnaliseSelecionada === TEMATICA}
            expressao={expressaoAtiva}
            tipoAnaliseSelecionada={tipoAnaliseSelecionada}
            onToggleModal={this.handleToggleModalTipologia}
            onAtualizarExpressaoTipologia={this.handleAtualizarExpressaoTipologia}
          />)
        }
      </React.Fragment>
    )
  }
}

export default Expressoes
