import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import React from 'react'
import PropTypes from 'prop-types'

const Adicionar = ({ label, onClick, disabled }) => (
  <Box>
    <Button
      disabled={Boolean(disabled)}
      variant='contained'
      color='primary'
      onClick={onClick}>
      { label }
    </Button>
  </Box>
)

Adicionar.displayName = 'Adicionar'
Adicionar.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default Adicionar
