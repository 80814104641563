import React from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { formatarNumeroParaMoedaBR } from '../../../../js/util'
import Box from '@material-ui/core/Box'
import DoneIcon from '@material-ui/icons/Done'
import WarningIcon from '@material-ui/icons/Warning'
import styles from '../styles'
import ModalTelaCheia from '../../../comum/ModalTelaCheia'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'

const ResumoArquivo = (props) => {
  const { classes, resumo } = props

  const [open, setOpen] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }

  const linhasTotais = resumo.itensOrcamento
  const linhasNaoOrcamentarias = linhasTotais.filter((linha) => linha.erros.length > 0)

  const itensOrcamentarios = () => (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Linha do Erro</TableCell>
          <TableCell align="left">Linha Original</TableCell>
          <TableCell align="left">Erros encontrados</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {linhasNaoOrcamentarias.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
          <TableRow key={index}>
            <TableCell component="th" scope="row">
              {item.numero}
            </TableCell>
            <TableCell align="left">{item.linhaOriginal}</TableCell>
            <TableCell align="left">
              <ul className='ulResArq' >
                {(item.erros).map((error) => (
                  <li key={`${error}`}>{error}</li>
                ))}
              </ul>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100]}
            colSpan={3}
            labelRowsPerPage={'Linhas por página'}
            labelDisplayedRows={
              ({ from, to, count }) => `${from}-${to} de ${count}`
            }
            count={linhasNaoOrcamentarias.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </TableRow>
      </TableFooter>
    </Table>
  )

  return (
    <Box mt={2}>
      <Typography variant='subtitle2'>
        Conteúdo do arquivo
      </Typography>
      <div className={classes.resumo}>
        <Typography >
          Valor global informado: R$ {formatarNumeroParaMoedaBR(resumo.valorGlobalInformado, 2, 3)}
        </Typography>
        <Typography>
          Valor global calculado: R$ {formatarNumeroParaMoedaBR(resumo.valorGlobalCalculado, 2, 4)}
        </Typography>
        <Typography>
          <span className={classes.linhasOrcamentarias}>Linhas orçamentárias: { resumo.numeroLinhasOrcamentarias }</span>
          <span className={classes.doneIcon}><DoneIcon fontSize='small'/></span>
        </Typography>
        <Typography>
          <span className={classes.linhasNaoOrcamentarias}>Linhas não orçamentárias: { resumo.numeroLinhasNaoOrcamentarias }</span>
          <span className={classes.warningIcon} onClick={handleOpen} style={{cursor: 'pointer'}}><WarningIcon fontSize='small'/></span>
        </Typography>
        <ModalTelaCheia open={open} titulo='Linhas não-orçamentárias' onClose={handleClose}>
          <Typography>{itensOrcamentarios()}</Typography>
        </ModalTelaCheia>
      </div>
    </Box>
  )
}

ResumoArquivo.propTypes = {
  classes: PropTypes.object,
  resumo: PropTypes.object.isRequired
}

export default withStyles(styles)(ResumoArquivo)
