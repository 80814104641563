import React from 'react'
import { Select } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const porcentagensVisualizar = [
  {
    value: 60
  },
  {
    value: 70
  },
  {
    value: 80
  },
  {
    value: 85
  },
  {
    value: 90
  },
  {
    value: 95
  },
  {
    value: 100
  }
]

const VisualizarCurvaAbc = ({valor, onChange}) => {
  return (
    <FormControl variant='outlined'>
      <Select className='select-mt-visualizar'
              inputProps={{
                id: 'visualizar-porcentagem'
              }}
              style={{ height: 44 }}
              value={valor}
              onChange={onChange}>
        {
          porcentagensVisualizar.map((porcentagem, i) => (
            <MenuItem key={i} value={porcentagem.value}>{porcentagem.value}% da curva</MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

VisualizarCurvaAbc.propTypes = {
  valor: PropTypes.number,
  onChange: PropTypes.func,
  contexto: PropTypes.object
}

const mapStateToProps = ({contexto}) => ({contexto})

export default connect(mapStateToProps)(VisualizarCurvaAbc)
