import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import { getAuthentication } from '../../../security/securityContext'
import App from '../../App'
import PropTypes from 'prop-types'
import { Paper, Typography } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import styles from '../styles'
import Tab from '@material-ui/core/Tab'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { FormControl, Select, InputLabel, MenuItem, ButtonGroup, Chip, Alert } from '@mui/material'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TitleTableCell from '../../comum/TitleTableCell'
import TableBody from '@material-ui/core/TableBody'
import Paginacao from '../../comum/Paginacao'
import TableCell from '@material-ui/core/TableCell'
import StyledTableRow from '../../comum/StyledTableRow'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../../js/operationProgressUtil'
import ModalDetalharPeriodo from './ModalDetalharPeriodo'
import Button from '@material-ui/core/Button'

const ConfigurarCarga = ({ classes }) => {
  const [sistemaReferencia, setSistemaReferencia] = useState('Sicro')
  const [status, setStatus] = React.useState('')
  const [periodoSelecao, setPeriodoSelecao] = React.useState('')
  const [periodo, setPeriodo] = React.useState(null)
  const [registrosPorPagina, setRegistrosPorPagina] = useState(10)
  const [pagina, setPagina] = useState(0)
  const [periodos, setPeriodos] = useState([])
  const [exibeModal, setExibeModal] = useState(false)

  const estiloSituacao = new Map([['Completa', 'success'], ['Rejeitada', 'error'],
    ['Em andamento', 'warning'], ['Novo período', 'primary']])

  useEffect(() => {
    axios.get(`/api/referencias-preco/periodos/${sistemaReferencia}/historico`.toLowerCase(), SLOW_REQUEST_CONFIG).then((periodos) => {
      periodos.status === 200 ? setPeriodos(periodos.data) : setPeriodos([])
      setExibeModal(false)
    })
  }, [sistemaReferencia]
  )

  useEffect(() => {
    setExibeModal(true)
  }, [periodo])

  const handleChangeSistema = (ev, newValue) => {
    setSistemaReferencia(newValue)
    setPagina(0)
  }
  const handleChangeStatus = (event) => {
    setStatus(event.target.value)
    if (event.target.value === '') {
      axios.get(`/api/referencias-preco/periodos/${sistemaReferencia}/historico`.toLowerCase(), SLOW_REQUEST_CONFIG).then((periodos) => {
        periodos.status === 200 ? setPeriodos(periodos.data) : setPeriodos([])
      })
      return
    }
    axios.get(`/api/referencias-preco/periodos/${sistemaReferencia}/historico`.toLowerCase(), SLOW_REQUEST_CONFIG).then(
      (periodos) => {
        periodos.status === 200 ? setPeriodos(periodos.data.filter(p => (p.situacao === event.target.value))) : setPeriodos([])
        setPagina(0)
      })
  }

  const handleChangePeriodo = (event) => {
    setPeriodoSelecao(event.target.value)

    if (event.target.value === '') {
      axios.get(`/api/referencias-preco/periodos/${sistemaReferencia}/historico`.toLowerCase(), SLOW_REQUEST_CONFIG).then((periodos) => {
        periodos.status === 200 ? setPeriodos(periodos.data) : setPeriodos([])
      })
      return
    }
    axios.get(`/api/referencias-preco/periodos/${sistemaReferencia}/historico`.toLowerCase(), SLOW_REQUEST_CONFIG).then(
      (periodos) => {
        periodos.status === 200
          ? setPeriodos(periodos.data
            .filter(p => (p.ano === event.target.value.ano))
            .filter(p => (p.mes === event.target.value.mes)))
          : setPeriodos([])
        setPagina(0)
      })
  }

  const authentication = getAuthentication()
  if (!authentication || !authentication.isUserAuthenticated) {
    return <Redirect to='/login' />
  }

  const urlSiteSicro = () => {
    return 'https://www.gov.br/dnit/pt-br/assuntos/planejamento-e-pesquisa/' +
        'custos-e-pagamentos/custos-e-pagamentos-dnit/sistemas-de-custos/sicro/' +
        'relatorios-sicro/relatorios-sicro'
  }

  const urlSiteSinapi = () => {
    return 'https://www.caixa.gov.br/site/paginas/downloads.aspx'
  }



  const ufsComRestricao = (p) => {
    const ufs = []
    p.ufsMaterial.filter(uf => (uf.second === 0))
      .map((it) => {
        if (!ufs.includes(it.first)) { ufs.push(it.first) }
      })
    p.ufsMaoDeObra.filter(uf => (uf.second === 0)).map((it) => {
      if (!ufs.includes(it.first)) { ufs.push(it.first) }
    })
    p.ufsEquipamento.filter(uf => (uf.second === 0)).map((it) => {
      if (!ufs.includes(it.first)) { ufs.push(it.first) }
    })
    p.ufsComposicao.filter(uf => (uf.second === 0)).map((it) => {
      if (!ufs.includes(it.first)) { ufs.push(it.first) }
    })
    p.ufsEncargosSociais.filter(uf => (uf.second === 0)).map((it) => {
      if (!ufs.includes(it.first)) { ufs.push(it.first) }
    })
    return ufs.toString()
  }

  const insumosFaltantes = (p) => {
    const insumos = []
    if (p.ufsMaterial.length === 0) { insumos.push(' materiais') }
    if (p.ufsMaoDeObra.length === 0) { insumos.push(' mãos de obra') }
    if (p.ufsEquipamento.length === 0) { insumos.push(' equipamentos') }
    if (p.ufsComposicao.length === 0) { insumos.push(' composições') }
    if (sistemaReferencia.toUpperCase() === 'SINAPI' && p.ufsEncargosSociais.length === 0) { insumos.push(' encargos sociais') }
    return insumos.toString()
  }

  const ufsArquivosComRestricao = (p) => {
    const todasUfs = ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MG', 'MS', 'MT',
      'PA', 'PB', 'PE', 'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO']
    const ufs = []
    const mapa = p.mapaArquivos
    // const mapa = new Map()
    // mapa.set('AC', ['A', 'B', 'C', 'D', 'E'])
    // mapa.set('AL', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('AM', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('AP', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('BA', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('CE', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('DF', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('ES', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('GO', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('MA', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('MG', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('MS', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('MT', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('PA', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('PB', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('PE', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('PI', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('PR', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('RJ', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('RN', ['A', 'B', 'C', 'D', 'E'])
    // mapa.set('RO', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('RR', ['A', 'B', 'C', 'D', 'E'])
    // mapa.set('RS', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('SC', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('SE', ['A', 'B', 'C', 'D', 'E', 'F'])
    // mapa.set('SP', ['A', 'B', 'C', 'D', 'E', 'F'])
    // //mapa.set('TO', ['A', 'B', 'C', 'D', 'E', 'F'])

    // console.log(mapa)
    if (mapa.keys === undefined) {
      return ufs
    }
    todasUfs.map(uf => {
      if (!Array.from(mapa.keys()).includes(uf)) { ufs.push(uf) }
    })
    Array.from(mapa.entries()).map(
      e => {
        if (e[1].length < 6) {
          ufs.push(e[0])
        }
      }
    )
    // console.log(ufs)

    return ufs.sort().toString()
  }

  const detalharPeriodo = (p) => {
    axios.get(`/api/referencias-preco/periodos/${sistemaReferencia}/${p.ano}/${p.mes}`.toLowerCase(), SLOW_REQUEST_CONFIG).then((periodo) => {
      periodo.status === 200 ? setPeriodo(periodo.data) : setPeriodo(null)
    })
  }

  function montaTituloTela () {
    return <Box pt={4} mb={2}>
      <Breadcrumbs aria-label='breadcrumb' style={{ marginTop: '-8px' }}>
        <Link color='inherit' href='/'>
          Início
        </Link>
        <Typography color='primary'>Relatório de carga</Typography>
      </Breadcrumbs>
    </Box>
  }

  function montaCaixasDeFiltragem() {
    return (
      <Paper elevation={0} variant='outlined' className={classes.paperTable}>
        <Box mb={2}>
          <Box display='flex' justifyContent='space-between'>
            <Typography variant='h6'>Filtros</Typography>
            <ButtonGroup variant='outlined' size='small' aria-label='outlined primary button group'>
              <Link className={classes.links} href={urlSiteSicro()} underline="none" target='_blank' rel='noreferrer'>
                Ir para o site do SICRO
              </Link>
              <Link className={classes.links} href={urlSiteSinapi()} underline="none" target='_blank' rel='noreferrer'>
                Ir para o site do SINAPI
              </Link>
            </ButtonGroup>
          </Box>
          <Box>
            <FormControl sx={{ m: 1, minWidth: 220 }}>
              <InputLabel id='label'>Situação</InputLabel>
              <Select
                labelId='labelSelect'
                id='isSelect'
                value={status}
                label='Status'
                onChange={handleChangeStatus}
              >
                <MenuItem value=''>
                  <em>--------------</em>
                </MenuItem>
                <MenuItem value={'Completa'}>Completa</MenuItem>
                <MenuItem value={'Rejeitada'}>Rejeitada</MenuItem>
                <MenuItem value={'Em andamento'}>Em andamento</MenuItem>
                <MenuItem value={'Novo período'}>Novo período</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ m: 1, minWidth: 220 }}>
              <InputLabel id='label'>Mês/Ano</InputLabel>
              <Select
                labelId='labelSelect'
                id='selectperiodo'
                value={periodoSelecao}
                label='Período'
                onChange={handleChangePeriodo}
              >
                <MenuItem value=''>
                  <em>------------</em>
                </MenuItem>
                {periodos.map(p => (
                  <MenuItem value={p} key={p.mes.toString().padStart(2, '0') + p.ano.toString()}>
                    {p.mes.toString().padStart(2, '0') + '/' + p.ano.toString()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Paper>
    );
  }
  

  function montaTabPanel () {
    return <Paper elevation={0}>
      <Table>
        <TableHead>
          <TableRow>
            <TitleTableCell>Mês/ano</TitleTableCell>
            <TitleTableCell>Situação</TitleTableCell>
            <TitleTableCell>Pendências</TitleTableCell>
            <TitleTableCell align='center'>Ações</TitleTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {periodos.slice(pagina * registrosPorPagina, pagina * registrosPorPagina + registrosPorPagina)
            .map((p) => (
              <StyledTableRow key={p.ano.toString() + p.mes.toString()}>
                <TableCell>
                  <Typography variant={'inherit'}>
                    {p.mes.toString().padStart(2, 0)}/{p.ano}
                  </Typography>
                </TableCell>
                <TableCell align='left'>
                  <Chip label={p.situacao}
                    color={estiloSituacao.get(p.situacao)} variant='outlined' size='small' />
                </TableCell>
                <TableCell align='left'>
                  {insumosFaltantes(p).length !== 0 && p.situacao !== 'Completa' &&
                    <Alert severity='error' variant='string'>Informação de insumos faltantes: {insumosFaltantes(p)}</Alert>
                  }
                  {ufsComRestricao(p).length !== 0 && p.situacao !== 'Completa' &&
                    <Alert severity='error' variant='string'>Não foi possível concluir o carregamento dos índices das UFs: {ufsComRestricao(p)}</Alert>
                  }
                  {ufsComRestricao(p).length === 0 && insumosFaltantes(p).length === 0 && p.situacao !== 'Completa' &&
                    <Alert severity='success' variant='string'>Índices carregados</Alert>
                  }
                  {ufsArquivosComRestricao(p).length !== 0 && p.situacao !== 'Completa' &&
                    <Alert severity='error' variant='string'>Erro na baixa de arquivos nas UFs: {ufsArquivosComRestricao(p)}</Alert>
                  }
                  {ufsArquivosComRestricao(p).length === 0 && p.situacao !== 'Completa' &&
                    <Alert severity='success' variant='string'>Todos arquivos foram baixados</Alert>
                  }
                </TableCell>
                <TableCell align='center'>
                  <Button variant='text' color='primary' size='small' onClick={() => detalharPeriodo(p)}>Detalhar</Button>
                </TableCell>
              </StyledTableRow>
            ))}
        </TableBody>
      </Table>
      <Paginacao
        total={periodos.length}
        registrosPorPagina={registrosPorPagina}
        pagina={pagina}
        onChangePage={(e, pag) => setPagina(pag)}
        onChangeRowsPerPage={(e) => setRegistrosPorPagina(e.target.value)}
      />
    </Paper>
  }

  return (
    <App noAppToolbar={false}>
      {montaTituloTela()}
      <Box my={2}>
        {montaCaixasDeFiltragem()}
      </Box>
      <Paper elevation={0} variant='outlined'>
        <Box py={2} px={2}>
          <Typography variant='h6'>Lista de períodos carregados</Typography>
        </Box>
        <TabContext value={sistemaReferencia}>
          <Divider />
          <Box>
            <TabList onChange={handleChangeSistema} aria-label='lab API tabs example'>
              <Tab label='Sicro' value='Sicro' />
              <Tab label='Sinapi' value='Sinapi' />
            </TabList>
          </Box>
          <Divider />
          <TabPanel value='Sicro'>
            {montaTabPanel()}
          </TabPanel>
          <TabPanel value='Sinapi'>
            {montaTabPanel()}
          </TabPanel>
        </TabContext>
      </Paper>
      {exibeModal && periodo &&
        <ModalDetalharPeriodo seExibe={exibeModal} onConfirmar={() => setExibeModal(false)}
          sistema={sistemaReferencia} periodo={periodo} />
      }
    </App>
  )
}

ConfigurarCarga.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(ConfigurarCarga)
