import React, { Component } from 'react'
import { Button, Menu } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import { TIPO_PARAMETRO_EXPRESSAO, TIPO_PARAMETRO_FORMULA, TIPO_PARAMETRO_VALOR_NUMERICO, TIPO_PARAMETRO_VARIAVEL_ORCAMENTO } from '../../../../analise-conteudo'

class BotaoAdicionarParametro extends Component {
  static propTypes = {
    onAdicionarParametro: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      anchorEl: null
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
  }

  handleClick(event) {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose() {
    this.setState({ anchorEl: null })
  }

  handleMenuClick(tipo) {
    const { onAdicionarParametro } = this.props
    this.handleClose()
    onAdicionarParametro(tipo)
  }

  render() {
    const { anchorEl } = this.state
    return (
      <>
        <Button aria-controls='fade-menu' aria-haspopup='true' onClick={this.handleClick} color='primary' variant='text'>
          + Novo Parâmetro
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
          TransitionComponent={Fade}>
          <MenuItem onClick={() => this.handleMenuClick(TIPO_PARAMETRO_VALOR_NUMERICO)}>{TIPO_PARAMETRO_VALOR_NUMERICO.label}</MenuItem>
          <MenuItem onClick={() => this.handleMenuClick(TIPO_PARAMETRO_VARIAVEL_ORCAMENTO)}>{TIPO_PARAMETRO_VARIAVEL_ORCAMENTO.label}</MenuItem>
          <MenuItem onClick={() => this.handleMenuClick(TIPO_PARAMETRO_EXPRESSAO)}>{TIPO_PARAMETRO_EXPRESSAO.label}</MenuItem>
          <MenuItem onClick={() => this.handleMenuClick(TIPO_PARAMETRO_FORMULA)}>{TIPO_PARAMETRO_FORMULA.label}</MenuItem>
        </Menu>
      </>
    )
  }
}


export default BotaoAdicionarParametro
