import React, { Component } from 'react'
import Template from '../../../../../layout/Template'
import Typography from '@material-ui/core/Typography/Typography'
import ComposicoesAuxiliares from './ComposicoesAuxiliares'
import PropTypes from 'prop-types'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

export class ComposicaoAuxiliar extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    handleVerComposicaoAuxiliar: PropTypes.func,
    composicao: PropTypes.object,
    onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.func
  }

  state = {
    possuiSubComposicao: false,
    subComposicao: null,
    historicoNavegacaoComposicao: []
  }

  constructor (props) {
    super(props);
    this.handleVerComposicaoAuxiliar = this.handleVerComposicaoAuxiliar.bind(this)
    this.handleVoltarParaComposicaoAnterior = this.handleVoltarParaComposicaoAnterior.bind(this)
  }

  handleVerComposicaoAuxiliar(subComposicao){
    this.setState({
      possuiSubComposicao: true,
      subComposicao: subComposicao
    })
    this.state.historicoNavegacaoComposicao.push(subComposicao)
  }

  handleVoltarParaComposicaoAnterior() {
    const histComposicoesSize = this.state.historicoNavegacaoComposicao.length
    if (histComposicoesSize === 0) {
      this.voltarParaComposicaoPrincipal()
    } else if (histComposicoesSize === 1) {
      this.voltarParaComposicaoAuxiliarPrincipal()
    } else {
      this.voltarParaComposicaoAuxiliarAnterior()
    }
  }

  voltarParaComposicaoPrincipal(){
    const { handleVerComposicaoAuxiliar, composicao } = this.props
    handleVerComposicaoAuxiliar(composicao, false)
  }

  voltarParaComposicaoAuxiliarPrincipal(){
    const { historicoNavegacaoComposicao } = this.state
    this.setState({
      possuiSubComposicao: false,
      subComposicao: historicoNavegacaoComposicao[0]
    })
    historicoNavegacaoComposicao.pop()
  }

  voltarParaComposicaoAuxiliarAnterior(){
    const { historicoNavegacaoComposicao } = this.state
    historicoNavegacaoComposicao.pop()
    let composicaoAnterior = historicoNavegacaoComposicao.slice(-1)[0]
    this.setState({
      possuiSubComposicao: true,
      subComposicao: composicaoAnterior
    })
  }

  handleRenderComposicaoAuxiliar(subComposicao) {
    const { classes } = this.props
    return (
      <Template>
        <Typography variant='h6' className={classes.voltar} onClick={this.handleVoltarParaComposicaoAnterior}>
          <span><ArrowBackIcon /> Composicão Auxiliar </span></Typography>
        <ComposicoesAuxiliares
          composicao={subComposicao}
          classes={this.props.classes}
          handleVerComposicaoAuxiliar={this.handleVerComposicaoAuxiliar}
        />
      </Template>
    )
  }

  render () {
    const { possuiSubComposicao, subComposicao } = this.state
    const { classes, composicao, onAtualizarItemCurvaABCComposicaoDuplicada } = this.props
    if(possuiSubComposicao){
      return (this.handleRenderComposicaoAuxiliar(subComposicao))
    }

    return (
      <Template>
        <Typography variant="h6" className={classes.voltar} onClick={this.handleVoltarParaComposicaoAnterior}>
          <ArrowBackIcon /> Composicão Auxiliar </Typography>
        <ComposicoesAuxiliares
          composicao={composicao}
          classes={classes}
          handleVerComposicaoAuxiliar={this.handleVerComposicaoAuxiliar}
          onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
        />
      </Template>
    )
  }
}

export default ComposicaoAuxiliar
