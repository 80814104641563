import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import PropTypes from 'prop-types'
import { FormControl, Typography, withStyles } from '@material-ui/core'
import styles from '../../styles'

const CampoUnidadeParametrica = ({
  unidadeParametrica,
  unidadeParametricaEmpty,
  handleUnidadeParametricaChange,
  classes
}) => {
  return (
    <FormControl error={unidadeParametricaEmpty} variant='outlined' className={classes.campoFormulario}>
      <Typography variant='subtitle1' htmlFor='unidade-parametrica'>Unidade Paramétrica</Typography>
      <Select
        inputProps={{
          id: 'unidade-parametrica'
        }}
        value={unidadeParametrica || ''}
        onChange={handleUnidadeParametricaChange}
        placeholder='Selecione a unidade'>
        <MenuItem value=''>
          <em>Selecione a unidade </em>
        </MenuItem>
        <MenuItem value='NAO_SE_APLICA'>Não se aplica</MenuItem>
        <MenuItem value='HA'>ha</MenuItem>
        <MenuItem value='KG'>KG</MenuItem>
        <MenuItem value='M'>m</MenuItem>
        <MenuItem value='M2'>m2</MenuItem>
        <MenuItem value='M3'>m3</MenuItem>
        <MenuItem value='M3H'>m3/hora</MenuItem>
        <MenuItem value='MW'>MW</MenuItem>
        <MenuItem value='KM'>Km</MenuItem>
        <MenuItem value='LS'>l/s</MenuItem>
        <MenuItem value='PECA'>Peça</MenuItem>
        <MenuItem value='PERCENTUAL'>Percentual</MenuItem>
        <MenuItem value='QUANTIDADE'>Quantidade</MenuItem>
        <MenuItem value='TONELADA'>Tonelada</MenuItem>
      </Select>
    </FormControl>
  )
}

CampoUnidadeParametrica.propTypes = {
  unidadeParametrica: PropTypes.string,
  unidadeParametricaEmpty: PropTypes.bool,
  handleUnidadeParametricaChange: PropTypes.func.isRequired,
  classes: PropTypes.object
}

export default withStyles(styles)(CampoUnidadeParametrica)
