import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import TitleTableCell from '../../../../comum/TitleTableCell'

const CabecalhoTabelaReferenciaPreco = () => (
  <TableHead>
    <TableRow>
      <TitleTableCell>Descrição</TitleTableCell>
      <TitleTableCell>Cod. Serviço</TitleTableCell>
      <TitleTableCell>Unidade</TitleTableCell>
      <TitleTableCell>Preço</TitleTableCell>
      <TitleTableCell>Referência</TitleTableCell>
      <TitleTableCell>Estado</TitleTableCell>
      <TitleTableCell>Data</TitleTableCell>
    </TableRow>
  </TableHead>
)

export default CabecalhoTabelaReferenciaPreco
