import React from 'react'
import Typography from '@material-ui/core/Typography'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import MesmaLinha from './MesmaLinha'
import PropTypes from 'prop-types'

const TituloVoltar = ({ label, onClick }) => (
    <div onClick={onClick} style={{cursor: 'pointer', marginBottom: 16}}>
        <MesmaLinha>
            <ArrowBackIcon  style={{marginRight: 4}} />
            <Typography variant='h6'>{ label }</Typography>
        </MesmaLinha>
    </div>
)

TituloVoltar.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default TituloVoltar
