import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import PropTypes from 'prop-types'

const CelulaDescricaoRef = ({ exibir, texto }) => {
  return exibir && <React.Fragment>
      <TableCell style={{borderBottom: 'none'}}/>
      <TableCell style={{borderBottom: 'none' , borderLeft: '4px solid rgb(224 224 224)', color: '#4a4a4a'}} colSpan='100%'>
        {/* <strong>Referência: </strong>  */}
        {texto}</TableCell>
  </React.Fragment>
}

CelulaDescricaoRef.propTypes = {
  exibir: PropTypes.bool.isRequired,
  texto: PropTypes.string.isRequired
}

export default CelulaDescricaoRef
