import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { useComposition } from '../../../../../../hooks/useComposition';
import { context_httpget, context_httppost } from '../../../../../../js/httpRequest';
import PropTypes from 'prop-types';
import { mapStateToPropsContexto, showAlertMsg, showSuccessMsg } from '../../../../../../js/util';
import { Drawer, withStyles } from '@material-ui/core';
import styles from '../styles';
import TituloVoltar from '../../../../../comum/TituloVoltar';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, TextField } from '@material-ui/core';
import StyledTableContainerAdicionar from '../../../../../comum/StyledTableContainerAdicionar';
import Grid from '@material-ui/core/Grid';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TableSortLabel from '@material-ui/core/TableSortLabel';

class ComposicoesOrcamentos extends Component {
  
  static propTypes = {
    match: PropTypes.object,
    updatedContexto: PropTypes.object,
    history: PropTypes.array,
    classes: PropTypes.object,
    value: PropTypes.object.isRequired,
    onCancelar: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.state = {
      composicaoSelecionada: null,
      termoBusca: '',
      tipoFiltro: 'codigo', 
      sortField: 'codigo',
      sortOrder: 'asc',
    };
  }
 
  handleSearchTypeChange = (event) => {
    this.setState({ tipoFiltro: event.target.value });
  };

  handleRowComposicaoOrcamentoClick = (composicao) => {
    const { composicaoSelecionada } = this.state;
    
    if (composicaoSelecionada !== composicao) {
      this.setState({ composicaoSelecionada: composicao })
    }
  };

  handleConfirmarClick = () => {
    const { composicaoSelecionada } = this.state;
    
    if (_.isNil(composicaoSelecionada)) {
      showAlertMsg('Por favor, selecione uma composição.');
      return;
    }
    this.handleImportarComposicao(composicaoSelecionada)
  };

  handleImportarComposicao = async (composicaoSelecionada) => {
    const { contexto, updatedContexto } = this.props;
    const [mes, ano] = composicaoSelecionada.periodo.split('/');
    
    const composicaoImportada = { ...composicaoSelecionada, codComposicao: composicaoSelecionada.codigo, 
      codOrcamentoSelecionado: updatedContexto.orcamento.cod, mes: mes, ano: ano };
    
    await context_httppost('precos/importar-composicao-outro-orcamento', contexto, composicaoImportada);
    showSuccessMsg(`Composição ${composicaoImportada.codComposicao} adicionada com sucesso.`);
    
  };

  handleInputChange = (event) => {
    const termoBusca = event.target.value;
    this.setState({ termoBusca }, () => { 
      this.handleBuscarComposicoesNoOrcamento()})
  }

  handleBuscarComposicoesNoOrcamento = () => {
    const { termoBusca, tipoFiltro } = this.state;
    const { composicaoOrcamentoSelecionado } = this.props;
  
    if (termoBusca === '') {
      this.setState({ composicaoOrcamentoSelecionado: composicaoOrcamentoSelecionado });
    } else {
      const filteredData = composicaoOrcamentoSelecionado.filter((item) =>
        item[tipoFiltro].toLowerCase().includes(termoBusca.toLowerCase())
      );
      this.setState({ composicaoOrcamentoSelecionado: filteredData });
    }
  };

  handleSort = (field) => {
    const { sortField, sortOrder } = this.state;
    const newSortOrder = sortField === field ? (sortOrder === 'asc' ? 'desc' : 'asc') : 'asc';
    this.setState({ sortField: field, sortOrder: newSortOrder });
  };

  render() {
    const { composicaoOrcamentoSelecionado, isOpen, classes, onClose, updatedContexto } = this.props;
    const { composicaoSelecionada, termoBusca, tipoFiltro, sortField, sortOrder } = this.state;

    const sortedComposicoes = composicaoOrcamentoSelecionado.slice().sort((a, b) => {
      const valueA = a[sortField];
      const valueB = b[sortField];

      if (valueA < valueB) {
        return sortOrder === 'asc' ? -1 : 1;
      }

      if (valueA > valueB) {
        return sortOrder === 'asc' ? 1 : -1;
      }

      return 0;
    });

    const filteredComposicoes = termoBusca
      ? sortedComposicoes.filter(item => item[tipoFiltro].toLowerCase().includes(termoBusca.toLowerCase()))
      : sortedComposicoes; 


    return (
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Composições do Orçamento: {updatedContexto.orcamento.cod}</DialogTitle>

      <Box my={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item >
            <FormControl variant="outlined" style={{ marginLeft: '24px' }}>
              <Typography variant="subtitle2" htmlFor="visualizar">
                Tipo Filtro
              </Typography>
              <Select className="select-mt-visualizar" value={tipoFiltro} onChange={this.handleSearchTypeChange}>
                <MenuItem value="codigo">Código</MenuItem>
                <MenuItem value="descricao">Descrição</MenuItem>
                <MenuItem value="sistemaReferencia">Sistema de Referência</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <Typography variant="subtitle2" htmlFor="visualizar">
              Termo
            </Typography>
            <TextField label="Pesquisar" name="texto" value={termoBusca} fullWidth onChange={this.handleInputChange} variant="outlined" />
          </Grid>
        </Grid>
      </Box>

      <DialogContent>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel active={sortField === 'codigo'} direction={sortField === 'codigo' ? sortOrder : 'asc'} onClick={() => this.handleSort('codigo')}>
                  Código
                  
                </TableSortLabel>
              </TableCell>

              <TableCell>
                <TableSortLabel active={sortField === 'descricao'} direction={sortField === 'descricao' ? sortOrder : 'asc'} onClick={() => this.handleSort('descricao')}>
                  Descrição
                  
                </TableSortLabel>
              </TableCell>

              <TableCell>
                Período
              </TableCell>

              <TableCell>
                <TableSortLabel active={sortField === 'sistema'} direction={sortField === 'sistema' ? sortOrder : 'asc'} onClick={() => this.handleSort('sistema')}>
                  Sistema
                  
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredComposicoes.length > 0 ? (
              filteredComposicoes.map((item) => {
                const estiloCelula = composicaoSelecionada && composicaoSelecionada.codigo === item.codigo ? { color: 'white' } : {};
                return (
                  <TableRow key={item.codigo} className={composicaoSelecionada && composicaoSelecionada.codigo === item.codigo ? classes.linhaItemSelecionada : classes.linhaItem} style={{ textAlign: 'right' }} onClick={() => this.handleRowComposicaoOrcamentoClick(item)}>
                    <TableCell style={estiloCelula}>{item.codigo}</TableCell>
                    <TableCell style={estiloCelula}>{item.descricao}</TableCell>
                    <TableCell style={estiloCelula}>{item.periodo}</TableCell>
                    <TableCell style={estiloCelula}>{item.sistemaReferencia}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <p><em>Não há composições para serem importadas. Pesquise outro termo ou acesse outro orçamento</em></p>
            )}
          </TableBody>
        </Table>
      </DialogContent>

        <DialogActions>
          <Button color='primary' variant='contained' onClick={this.handleConfirmarClick}>
            Confirmar
          </Button>
          <Button onClick={onClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      
    );
  }
}

ComposicoesOrcamentos.propTypes = {
  composicaoOrcamentoSelecionado: PropTypes.arrayOf(
    PropTypes.shape({
      codigo: PropTypes.string.isRequired,
      descricao: PropTypes.string.isRequired,
      periodo: PropTypes.string.isRequired,
      qtdeAtividadeAuxiliar: PropTypes.number.isRequired,
      qtdeTempoFixo: PropTypes.number.isRequired,
      qtdeTransporte: PropTypes.number.isRequired,
      sistemaReferencia: PropTypes.string.isRequired,
    })
  ),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updatedContexto: PropTypes.object.isRequired,
};

export default connect(mapStateToPropsContexto)(withStyles(styles)(ComposicoesOrcamentos));
