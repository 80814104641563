import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import ComboBox from '../../comum/ComboBox'
import PropTypes from 'prop-types'

const TIPOS_INSUMO = [
  { nome: 'COMPOSICAO', descricao: 'Composição' },
  { nome: 'EQUIPAMENTO', descricao: 'Equipamento' },
  { nome: 'MAO_DE_OBRA', descricao: 'Mão de Obra' },
  { nome: 'MATERIAL', descricao: 'Material' }
]

const TiposInsumo = ({ value, empty, onChange }) => (
  <ComboBox
    fullWidth={false}
    label='Tipo Insumo'
    value={value}
    empty={empty}
    onChange={onChange}>

    {
      TIPOS_INSUMO.map((tipoInsumo) => (
        <MenuItem value={tipoInsumo.nome} key={tipoInsumo.nome}>
          <Typography>{tipoInsumo.descricao}</Typography>
        </MenuItem>
      )
      )
    }
  </ComboBox>
)

TiposInsumo.propTypes = {
  value: PropTypes.string.isRequired,
  empty: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired
}

export default TiposInsumo
