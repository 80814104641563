import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, Box, Button, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AddIcon from '@material-ui/icons/Add'
import AnaliseConteudoContentItem from '../../AnaliseConteudoContentItem'
import { formatarNumeroParaMoedaBR } from '../../../../../../../js/util'
import PropTypes from 'prop-types'
import SimplePopover from '../../../../../../comum/SimplePopover'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import StyledDrawer from '../../../../../../comum/StyledDrawer'
import StyledAccordionSumary from '../../../../../../comum/StyledAccordionSumary'
import Formula from './Formula'
import ModalExcluirFormula from './ModalExcluirFormula'
import { PERSONALIZADA, TEMATICA } from '../../../analise-conteudo'
import ModalCriarTipologiaFormula from './tipologia/ModalCriarTipologiaFormula'
import ImportarFormula from './ImportarFormula'
import NenhumResultadoEncontrado from '../../comum/NenhumResultadoEncontrado'
import { hasAutorities } from '../../../../../../../security/securityContext'
import { PERFIL } from '../../../../../../../js/perfis'

const Formulas = ({
  classes,
  tipoAnaliseSelecionada,
  formulas,
  onRecarregarFormulas,
  onRemoverFormulaDoEstado,
  onAtualizarDisponibilidadeFormula
}) => {
  const [expandedPanel, setExpandedPanel] = useState(true)
  const [formulaAtiva, setFormulaAtiva] = useState(null)
  const [formulaExcluir, setFormulaExcluir] = useState(null)
  const [formulaFoiExcluida, setFormulaFoiExcluida] = useState(false)
  const [formulasVinculadasExcluir, setFormulasVinculadasExcluir] = useState([])
  const [tipologiasCombinadaVinculadasExcluir, setTipologiasCombinadaVinculadasExcluir] = useState([])
  const [openDrawerAdicionaFormula, setOpenDrawerAdicionaFormula] = useState(false)
  const [openModalCriarTipologia, setOpenModalCriarTipologia] = useState(false)
  const [drawerImportarFormula, setDrawerImportarFormula] = useState(false)

  useEffect(() => {
    formulaAtiva && onRecarregarFormulas()
  }, [formulaAtiva])

  useEffect(() => {
    formulaFoiExcluida && removerFormulaDoEstado()
  }, [formulaFoiExcluida])

  const removerFormulaDoEstado = () => {
    onRemoverFormulaDoEstado(formulaExcluir)
    setFormulaFoiExcluida(false)
  }

  const handleOpenDrawer = (e, openDrawer) => {
    e.stopPropagation()
    setFormulaAtiva(null)
    setOpenDrawerAdicionaFormula(openDrawer)
  }

  const handleChangePanel = (panel) => (event, newExpanded) => {
    setExpandedPanel(newExpanded ? panel : false)
  }

  const handleFecharDrawer = () => {
    setOpenDrawerAdicionaFormula(!openDrawerAdicionaFormula)
    onRecarregarFormulas()
  }

  const handleFormulaChange = (formulaAtiva) => {
    setFormulaAtiva(formulaAtiva)
  }

  const handleToggleModalTipologia = (toggle) => {
    setOpenModalCriarTipologia(toggle)
  }

  const handleEditarFormulaChange = (formula) => {
    setFormulaAtiva(formula)
    setOpenDrawerAdicionaFormula(true)
  }

  const handleAtualizarFormulaTipologia = (toggle, formula) => {
    setFormulaAtiva(formula)
    setOpenModalCriarTipologia(toggle)
  }

  const handleFormulasVinculadasChange = (formulas, combinadas) => {
    setFormulasVinculadasExcluir(formulas)
    setTipologiasCombinadaVinculadasExcluir(combinadas)
  }

  const handleOnCompleteModalExcluirFormula = () => {
    setFormulaFoiExcluida(true)
    setFormulaExcluir(null)
  }

  const handleFecharModalExcluirFormula = () => {
    setFormulaExcluir(null)
    setFormulasVinculadasExcluir([])
    setTipologiasCombinadaVinculadasExcluir([])
  }

  const handleToggleDrawerImportar = (event) => {
    if (event) { event.stopPropagation() }
    setDrawerImportarFormula(!drawerImportarFormula)
  }

  return (
    <React.Fragment>
      <Box mt={2}>
        <Accordion
          className={classes.accordionAnaliseConteudo}
          square
          expanded={expandedPanel === true}
          onChange={handleChangePanel(true)}
        >
          <StyledAccordionSumary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1a-content'
            id='accordionFormulas'
            className={classes.accordionSummaryAnalise}
          >
            <Box display='flex' alignItems='center'>
              <Typography variant='subtitle2' className={classes.tituloAccordionAnaliseConteudo}>Fórmulas</Typography>
              <SimplePopover>
                Operações numéricas que utilizam variáveis do orçamento e valores de expressões de busca.
                Uma fórmula pode ser utilizada para definir uma tipologia a ser considerada nas inconsistências do orçamento.
              </SimplePopover>

              {tipoAnaliseSelecionada === PERSONALIZADA &&
              <Box display='flex'>
                <Button
                  variant='text'
                  color='primary'
                  onClick={(e) => handleOpenDrawer(e, true)}
                  style={{ marginRight: 5 }}
                >
                  <AddIcon fontSize='small' color='primary' /> Adicionar
                </Button>
                <Button
                  variant='text'
                  color='primary'
                  onClick={(e) => handleToggleDrawerImportar(e)}
                >
                  <CloudUploadIcon fontSize='small' color='primary' style={{ marginRight: 5 }} /> Importar
                </Button>
              </Box>
              }
            </Box>
          </StyledAccordionSumary>
          <AccordionDetails style={{ display: 'grid' }}>
            {formulas.length === 0 && <NenhumResultadoEncontrado titulo='Nenhuma fórmula encontrada' subtitulo='Cadastre fórmulas personalizadas' />}
            {formulas.map(formula => (
              <AnaliseConteudoContentItem
                key={formula.codigo}
                classes={classes}
                tipoAnaliseSelecionada={tipoAnaliseSelecionada}
                codigo={formula.codigo}
                nome={formula.nome}
                descricao={formula.descricao}
                seDisponivel={formula.seDisponivel}
                valorTotal={formatarNumeroParaMoedaBR(formula.valor, 2, 3)}
                tipologia={formula.tipologia}
                tipologiaPossuiInconsistencia={formula.tipologiaPossuiInconsistencia}
                formulaGerada={formula.formulaGerada}
                onEditarChange={handleEditarFormulaChange}
                onExcluirClick={() => setFormulaExcluir(formula)}
                onAtualizarTipologia={handleAtualizarFormulaTipologia}
                onAtualizarDisponibilidade={onAtualizarDisponibilidadeFormula}
              />
            ))}
          </AccordionDetails>
        </Accordion>
      </Box>
      <StyledDrawer
        classes={{ paper: classes.paperDrawer }}
        open={openDrawerAdicionaFormula}
        anchor='right'
        onClose={handleFecharDrawer}>
        <Formula
          formulaAtiva={formulaAtiva}
          tipoAnaliseSelecionada={tipoAnaliseSelecionada}
          onFecharDrawer={handleFecharDrawer}
          onFormulaChange={handleFormulaChange}
          onCriarTipologia={handleToggleModalTipologia}
        />
      </StyledDrawer>
      <StyledDrawer
        classes={{ paper: classes.paperImportarDrawer }}
        open={drawerImportarFormula}
        anchor='right'
        onClose={handleToggleDrawerImportar}>
        <ImportarFormula
          classes={classes}
          onRecarregarFormulas={onRecarregarFormulas}
          onFecharDrawer={handleToggleDrawerImportar}
        />
      </StyledDrawer>
      <ModalExcluirFormula
        formula={formulaExcluir}
        formulasVinculadas={formulasVinculadasExcluir}
        tipologiasCombinadas={tipologiasCombinadaVinculadasExcluir}
        fechar={handleFecharModalExcluirFormula}
        onFormulasVinculadasChange={handleFormulasVinculadasChange}
        onComplete={handleOnCompleteModalExcluirFormula}
      />
      { openModalCriarTipologia === true && (
        <ModalCriarTipologiaFormula
          formula={formulaAtiva}
          isModeReadOnly={!hasAutorities([PERFIL.GESTOR_TCU]) && tipoAnaliseSelecionada === TEMATICA}
          tipoAnaliseSelecionada={tipoAnaliseSelecionada}
          onChangeTipologia={handleAtualizarFormulaTipologia}
          onToggleModal={handleToggleModalTipologia}
        />)
      }
    </React.Fragment>
  )
}

Formulas.propTypes = {
  classes: PropTypes.object,
  tipoAnaliseSelecionada: PropTypes.string.isRequired,
  formulas: PropTypes.array,
  onRecarregarFormulas: PropTypes.func.isRequired,
  onRemoverFormulaDoEstado: PropTypes.func.isRequired,
  onAtualizarDisponibilidadeFormula: PropTypes.func
}

export default Formulas
