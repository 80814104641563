import axios from 'axios'
import { ALTERAR_ORCAMENTO } from '../types'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import { showErrorMsg } from '../../js/util'

const alterarOrcamento = (orcamento, arquivo, idEmpreendimento, idObra) => async (dispatch) => {
  await axios.put(`/api/empreendimentos/${idEmpreendimento}/obras/${idObra}/orcamentos`,
    { ...orcamento, arquivo }, SLOW_REQUEST_CONFIG)
    .then((resp) => {
      dispatch({
        type: ALTERAR_ORCAMENTO,
        payload: resp.data
      })
    })
    .catch((e) => {
      showErrorMsg(`Erro inesperado: ${e.message}`)
    })
}

export default alterarOrcamento
