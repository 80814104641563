import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, TableBody, TableCell, TableRow, TextField } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import RelatorioAnaliseConteudo from '../../../relatorios/analise-conteudo/RelatorioAnaliseConteudo'
import { context_httpdelete, context_httpget } from '../../../../../../js/httpRequest'
import { connect } from 'react-redux'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TitleTableCell from '../../../../../comum/TitleTableCell'
import { formatarData, paginar, showErrorMsg, showSuccessMsg, urlContexto } from '../../../../../../js/util'
import Paginacao from '../../../../../comum/Paginacao'
import DeleteIcon from '@material-ui/icons/Delete'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import _ from 'lodash'
import Tooltip from '@material-ui/core/Tooltip'
import fileDownload from 'js-file-download'
import axios from 'axios'

const ModalRelatorioAnaliseConteudo = ({ openDialog, closeDialog, contexto }) => {
  const [nomeRelatorio, setNomeRelatorio] = useState(null)
  const [nomeRelatorioEmpty, setNomeRelatorioEmpty] = useState(false)
  const [gerarRelatorio, setGerarRelatorio] = useState(false)
  const [arquivos, setArquivos] = useState([])
  const [pagina, setPagina] = useState(0)
  const [registrosPorPagina, setRegistrosPorPagina] = useState(10)

  useEffect(() => {
    const recuperarArquivos = async () => {
      const arquivos = await context_httpget('analise-conteudo/arquivos', contexto)
      setArquivos(arquivos)
    }

    contexto && recuperarArquivos()
  }, [])

  const handleGerarRelatorio = () => {
    if (_.isNil(nomeRelatorio)) {
      setNomeRelatorioEmpty(true)
      showErrorMsg('Campo obrigatório não preenchido')
      throw 'Campo obrigatório não preenchido'
    }
    setGerarRelatorio(true)
  }

  const handleAdicionarNovoArquivo = (arquivo) => {
    setArquivos([...arquivos, arquivo])
    limparCampos()
  }

  const limparCampos = () => {
    setNomeRelatorioEmpty(false)
    setNomeRelatorio(null)
    setGerarRelatorio(false)
  }

  const download = async (arquivo) => {
    const header = { responseType: 'arraybuffer' }
    const { hash, nome } = arquivo
    const { data } = await axios.get(`/api${urlContexto(contexto)}/analise-conteudo/arquivos/download/${hash}`, header)
    fileDownload(
      new Blob([data]),
      `${nome}.pdf`,
      'application/pdf'
    )
  }

  const excluirArquivo = async (arquivo) => {
    const { hash, codigo } = arquivo
    const response = await context_httpdelete(`analise-conteudo/arquivos/${hash}`, contexto)
    if (response) {
      setArquivos(arquivos.filter((it) => it.codigo !== codigo))
      showSuccessMsg('Arquivo excluído')
    }
  }

  return (
    <React.Fragment>
      <Dialog open={openDialog} fullWidth>
        <DialogTitle>
          Relatório de análise conteúdo
        </DialogTitle>
        <DialogContent>
          <Box my={2} display='flex' justifyContent='space-between'>
            <TextField
              value={nomeRelatorio}
              label='Digite um nome para o relatório'
              variant='outlined'
              fullWidth
              style={{ marginRight: '5px' }}
              error={nomeRelatorioEmpty}
              required onChange={(e) => setNomeRelatorio(e.target.value)}
            />
            <Button
              variant='contained'
              color='primary'
              style={{ width: '160px' }}
              onClick={() => handleGerarRelatorio()}
            >
              Gerar Relatório
            </Button>
          </Box>
          <Box my={2}>
            <React.Fragment>
              <Table>
                <TableHead>
                  <TableRow>
                    <TitleTableCell>Nome</TitleTableCell>
                    <TitleTableCell>Data Geração</TitleTableCell>
                    <TitleTableCell>Login Inclusão</TitleTableCell>
                    <TitleTableCell align='right'>Ação</TitleTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    paginar(arquivos, pagina, registrosPorPagina).map((item) => (
                      <TableRow hover key={item.codigo}>
                        <TableCell>{item.nome}</TableCell>
                        <TableCell>{formatarData(item.dataInclusao, 'YYYY-MM-DD HH:mm:ss', 'DD/MM/YYYY HH:mm')}</TableCell>
                        <TableCell>{item.loginInclusao}</TableCell>
                        <TableCell align='right'>
                          <Button color='primary' variant='text' className='btn36' onClick={() => download(item)}>
                            <Tooltip title='Baixar'>
                              <CloudDownloadIcon fontSize='small' />
                            </Tooltip>
                          </Button>
                          <Button color='primary' variant='text' className='btn36' onClick={() => excluirArquivo(item)}>
                            <Tooltip title='Excluir'>
                              <DeleteIcon fontSize='small' />
                            </Tooltip>
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              <Paginacao
                pagina={pagina}
                registrosPorPagina={registrosPorPagina}
                total={arquivos.length}
                onChangePage={(e, pag) => setPagina(pag)}
                onChangeRowsPerPage={(e) => setRegistrosPorPagina(e.target.value)}
              />
            </React.Fragment>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant='contained' color='primary' onClick={closeDialog}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <RelatorioAnaliseConteudo
        nomeRelatorio={nomeRelatorio}
        gerarRelatorio={gerarRelatorio}
        onAdicionarNovoArquivo={handleAdicionarNovoArquivo}
      />
    </React.Fragment>
  )
}

ModalRelatorioAnaliseConteudo.propTypes = {
  contexto: PropTypes.object,
  openDialog: PropTypes.bool.isRequired,
  closeDialog: PropTypes.bool.isRequired
}

export const mapStateToProps = ({ contexto }) => ({ contexto })

export default connect(mapStateToProps)(ModalRelatorioAnaliseConteudo)
