import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import ErrorBoundary from './components/layout/ErrorBoundary'
import AppRoutes from './routes/AppRoutes'
import store from './store/configureStore'
import styles, { theme } from './RootStyles'
import { ItemProvider } from './hooks/useItem'

const Root = (props) => (
  <MuiThemeProvider theme={theme}>
    <div className={props.classes.root}>
      <CssBaseline />
      <ErrorBoundary>
        <Provider store={store}>
          <ItemProvider>
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          </ItemProvider>
        </Provider>
      </ErrorBoundary>
    </div>
  </MuiThemeProvider>
)

Root.propTypes = {
  classes: PropTypes.object.isRequired
}

export { Root }

export default withStyles(styles)(Root)
