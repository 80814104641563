import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core';
import styles from '../styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { context_httpget, context_httppost } from '../../../../../../js/httpRequest'
import { mapStateToPropsContexto } from '../../../../../../js/util';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info' 
import BigNumber from 'bignumber.js';
 
class ImportarComposicaoViaCSV extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onCancelar: PropTypes.func.isRequired,
    onImportarComposicao: PropTypes.func.isRequired,
    contexto: PropTypes.object
  };
 
  state = {
    fileUploaded: false,
    content: ''
  };
 
  handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) return;
 
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      this.setState({ content, fileUploaded: true });
    };
    reader.readAsText(file, 'windows-1252');
  };
 
  montarArquivo = () => {
    const { content } = this.state;
    if (!content) return; // Check if content is available
    const rows = content.split('\n');
   
   
    const composicoes = [];
    
    // Iterate over each row
    for (let i = 1; i < rows.length; i++) {
      // Check if the row is empty or contains only whitespace characters
      if (!rows[i].trim()) {
        continue;
      }
 
      const values = rows[i].split(';'); // Split by semicolons
      
 
      const parseBigDecimal = value => {
        if (!value) return null;
        const parsedValue = value.trim().replace(',', '.');
        return parsedValue ? new BigNumber(parsedValue) : null;
      };

      const composicao = {
        codigoServico: values[0]?.trim().toUpperCase() || '', // Código do serviço
        descricaoServico: values[1]?.trim() || '', // Descrição do serviço
        unidadeServico: values[2]?.trim().toUpperCase() || '', // Unidade do serviço
        producaoEquipe: parseBigDecimal(values[3]), // Produção da equipe
        fic: parseBigDecimal(values[4]), // FIC
        fit: parseBigDecimal(values[5]), // FIT
        mes: values[6]?.trim().toUpperCase() || '', // Mês
        ano: values[7]?.trim().toUpperCase() || '', // Ano
        uf: values[8]?.trim().toUpperCase() || '', // UF
        tipoInsumo: this.transformTipoInsumo(values[9]?.trim().toUpperCase()) || '', // Tipo do Insumo
        codigoInsumo: values[10]?.trim().toUpperCase() || '', // Código do Insumo
        descricaoInsumo: values[11]?.trim() || '', // Descrição do Insumo
        unidadeInsumo: values[12]?.trim().toUpperCase() || '', // Unidade do insumo
        quantidadeInsumo: parseBigDecimal(values[13]), // Quantidade do insumo
        precoUnitario: parseBigDecimal(values[14]), // Preço Unitário
        codigoInsumoTransportado: values[15]?.trim().toUpperCase() || '',
        precoUnitarioImprodutivo: parseBigDecimal(values[16]), // Preço Unitário Improdutivo
        percentualUtilizacaoOperativo: parseBigDecimal(values[17]), // % de utilização operativo
        tipoPavimento: values[18]?.trim().toUpperCase() || '', // Tipo de pavimento
        sistemaReferencia: 'PESQUISA'
      };
 
      composicoes.push(composicao);
    }
 
    return composicoes;

  };

   transformTipoInsumo = (tipoInsumo) => {
    if (tipoInsumo === 'MÃO DE OBRA' || tipoInsumo === 'MÃO-DE-OBRA' || tipoInsumo === 'MAO-DE-OBRA') {
      return 'MAO DE OBRA';
    }
    return tipoInsumo;
  };
 
  salvarArquivo = async (event) => {
    event.preventDefault(); 
    const composicoes = this.montarArquivo();
    if (!composicoes) return; 
    const { contexto } = this.props;
   
    

    try {
      const response = await context_httppost('precos/importar-composicao-via-csv', contexto, composicoes);
  

     
    const uploadedFileName = document.getElementById('contained-button-file').files[0].name;
    const resultFileName = uploadedFileName.replace('.csv', '_RESULTADO.csv');

     
let csvContent = 'data:text/csv;charset=UTF-8,\ufeff';
const columnHeaders = [
  'Código Serviço',
  'Descrição Serviço',
  'Unidade Serviço',
  'Produção da equipe',
  'FIC',
  'FIT',
  'Mês',
  'Ano',
  'UF',
  'Tipo Insumo',
  'Código Insumo',
  'Descrição Insumo',
  'Unidade Insumo',
  'Quantidade Insumo',
  'Preço Unitário',
  'Código Insumo Transportado',
  'Preço Unitário Improdutivo (Equipamento)',
  '% Utilização Operativo (Equipamento)',
  'Tipo Pavimento (Transporte)',
  'Sistema Referência',
  'Status Composição',
  'Status Insumos'
];
csvContent += columnHeaders.join(';') + '\r\n';

response.forEach((item) => {
  
  const formattedItem = {
    ...item,
    codigoServico: item.codigoServico ? item.codigoServico.toLocaleString('pt-BR') : '',
    descricaoServico: item.descricaoServico ? item.descricaoServico.toLocaleString('pt-BR') : '',
    producaoEquipe: item.producaoEquipe ? item.producaoEquipe.toLocaleString('pt-BR') : '',
    fic: item.fic ? item.fic.toLocaleString('pt-BR') : '',
    fit: item.fit ? item.fit.toLocaleString('pt-BR') : '',
    quantidadeInsumo: item.quantidadeInsumo ? item.quantidadeInsumo.toLocaleString('pt-BR') : '',
    precoUnitario: item.precoUnitario ? item.precoUnitario.toLocaleString('pt-BR') : '',
    precoUnitarioImprodutivo: item.precoUnitarioImprodutivo ? item.precoUnitarioImprodutivo.toLocaleString('pt-BR') : '',
    percentualUtilizacaoOperativo: item.percentualUtilizacaoOperativo ? item.percentualUtilizacaoOperativo.toLocaleString('pt-BR') : '',

  };

  const row = Object.values(formattedItem).join(';');
  csvContent += row + '\r\n';
});



const encodedUri = encodeURI(csvContent);

const link = document.createElement('a');
link.setAttribute('href', encodedUri);
link.setAttribute('download', resultFileName);
document.body.appendChild(link); 
link.click(); 

  } catch (error) {
      console.error('Error:', error);
      
  }

  };
 
  render() {
    const { classes, onCancelar } = this.props;
    const { fileUploaded } = this.state;
 
    return (
      <div>
        <Typography variant="overline" gutterBottom>
          2. Importar Composição via CSV
        </Typography>

        <Box display="flex" alignItems="center">
        <Box mr={1}>
          <input
            accept=".csv"
            className={classes.input}
            id="contained-button-file"
            type="file"
            onChange={this.handleFileUpload}
          />
        </Box>
        <Tooltip
          title={
            <Typography>
              O arquivo deverá conter as seguintes colunas, em ordem:<br />
              - Código do serviço;<br />
              - Descrição do serviço;<br />
              - Unidade do serviço;<br />
              - Produção da equipe;<br />
              - FIC;<br />
              - FIT;<br />
              - Mês;<br />
              - Ano;<br />
              - UF;<br />
              - Tipo do Insumo (Material, Equipamento, Mao de Obra, Atividade Auxiliar, Momento de transporte, Tempo fixo);<br />
              - Código do Insumo;<br />
              - Descrição do Insumo;<br />
              - Unidade do insumo;<br />
              - Quantidade do insumo;<br />
              - Preço Unitário;<br />
              - Código Insumo Transportado;<br />
              - Preço Unitário Improdutivo;<br />
              - % de utilização operativo;<br />
              - Tipo de pavimento (Para insumos de momento de transporte: RP,LN,P, FE).
            </Typography>
          }
        >
          <InfoIcon fontSize="small" />
        </Tooltip>
      </Box>

 
        <Box mt={2}>
          <label htmlFor="contained-button-file">
            <Button
            variant="contained"
            color="primary"
            component="span"
            onClick={this.salvarArquivo}
            disabled={!fileUploaded}
            >
              Salvar arquivo
            </Button>
            <Button color="default" onClick={onCancelar}>
            Cancelar
          </Button>
          </label>
        </Box>
       
      </div>
    );
  }
}
 
export default connect(mapStateToPropsContexto)(withStyles(styles)(ImportarComposicaoViaCSV))