import { ALTERAR_EMPREENDIMENTO, BUSCAR_EMPREENDIMENTOS, CADASTRAR_EMPREENDIMENTO, EXCLUIR_EMPREENDIMENTO } from '../actions/types'

export const reducerEmpreendimentos = (state = [], action) => {
  switch (action.type) {
    case BUSCAR_EMPREENDIMENTOS:
      return action.payload.data
    case ALTERAR_EMPREENDIMENTO:
      return state.map((e) => (e.cod === action.payload.cod ? action.payload : e))
    case EXCLUIR_EMPREENDIMENTO:
      return state.filter((e) => e.cod !== action.payload.cod)
    case CADASTRAR_EMPREENDIMENTO:
      return [action.payload].concat(state)
    default:
      return state
  }
}

export default reducerEmpreendimentos
