import { CARREGAR_ORCAMENTO_EM_EDICAO } from '../types'

const mudarPropriedadesOrcamentoEmEdicao = (orcamento, novasPropriedades) => (dispatch) => {
  dispatch({
    type: CARREGAR_ORCAMENTO_EM_EDICAO,
    payload: {
      ...orcamento,
      ...novasPropriedades
    }
  })
}

export default mudarPropriedadesOrcamentoEmEdicao
