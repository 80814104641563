import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from './operationProgressUtil'
import _ from 'lodash'

const tratarErro = (error, onError) => {
  if(_.isNil(onError)) {
    throw error
  } else {
    onError(error)
  }
}

const retorna = (result) => {
  if (result && result.status === 200) {
    return typeof (result.data) === 'boolean' ? result.data : result.data || true
  } else {
    return false
  }
}

const montarUrl = (url, contexto) => {
  const { empreendimento, obra, orcamento } = contexto
  return `/api/empreendimentos/${empreendimento.cod}/obras/${obra.cod}/orcamentos/${orcamento.cod}/${url}`
}

export const context_httpget = async (url, contexto, onError, hideLoading) => {
  return await httpget(montarUrl(url, contexto), onError, hideLoading)
}

export const context_httppost = async (url, contexto, data, onError) => {
  return await httppost(montarUrl(url, contexto), data, onError)
}

export const context_httpput = async (url, contexto, data, onError) => {
  return await httpput(montarUrl(url, contexto), data, onError)
}

export const context_httppatch = async (url, contexto, data, onError) => {
  return await httppatch(montarUrl(url, contexto), data, onError)
}

export const context_httpdelete = async (url, contexto, onError) => {
  return await httpdelete(montarUrl(url, contexto), onError)
}

export const httpget = async (url, onError, hideLoading) => {
  const result = await axios.get(url, hideLoading ? {} : SLOW_REQUEST_CONFIG).catch((error) => tratarErro(error, onError))
  return retorna(result)
}

export const httppost = async (url, data, onError) => {
  const result = await axios.post(url, data, SLOW_REQUEST_CONFIG).catch((error) => tratarErro(error, onError))
  return retorna(result)
}

export const httpput = async (url, data, onError) => {
  const result = await axios.put(url, data, SLOW_REQUEST_CONFIG).catch((error) => tratarErro(error, onError))
  return retorna(result)
}

export const httppatch = async (url, data, onError) => {
  const result = await axios.patch(url, data, SLOW_REQUEST_CONFIG).catch((error) => tratarErro(error, onError))
  return retorna(result)
}

export const httpdelete = async (url, onError) => {
  const result = await axios.delete(url, SLOW_REQUEST_CONFIG).catch((error) => tratarErro(error, onError))
  return retorna(result)
}

