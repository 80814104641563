import _ from 'lodash'
import { converterMoedaBRParaNumero, formatarNumeroParaMoedaBR } from '../../../../js/util'

export const MENU_COMPOSICOES = 'MENU_COMPOSICOES'
export const MENU_COMPOSICOES_AVULSAS = 'MENU_COMPOSICOES_AVULSAS'
export const MENU_EQUIPAMENTOS = 'MENU_EQUIPAMENTOS'
export const MENU_MAOS_DE_OBRA = 'MENU_MAOS_DE_OBRA'
export const MENU_MATERIAIS = 'MENU_MATERIAIS'
export const MENU_TRANSPORTES = 'MENU_TRANSPORTES'

export const possuiSubComposicao = (composicao) => {
  return composicao.qtdeAtividadeAuxiliar > 0 || composicao.qtdeTempoFixo > 0 || composicao.qtdeTransporte > 0
}

export const isMesmoCodigoTransporte = (item, tipo, valor) => {
  switch (tipo) {
    case 'dmtLn':
      return item.dmtLn ? item.dmtLn.codigo === valor.dmtLn.codigo : false
    case 'dmtRp':
      return item.dmtRp ? item.dmtRp.codigo === valor.dmtRp.codigo : false
    case 'dmtP':
      return item.dmtP ? item.dmtP.codigo === valor.dmtP.codigo : false
    case 'dmtFe':
      return item.dmtFe ? item.dmtFe.codigo === valor.dmtFe.codigo : false
    default:
      return null
  }
}

export const handleRemoverTransportesDuplicados = (transportes, tipoTransporte) => {
  return transportes.filter((valor, indice, array) => {
    return array.findIndex(item => {
      return isMesmoCodigoTransporte(item, tipoTransporte, valor)
    }) === indice
  })
}

export const handleRemoverTemposFixosDuplicados = (temposFixos) => {
  return temposFixos.filter((valor, indice, array) => {
    return array.findIndex(item => {
      return item.tempoFixo.codigo === valor.tempoFixo.codigo
    }) === indice
  })
}

export const handleRemoverItensDuplicadosPaginado = (lista, listaPaginada) => {
  const itensNaoDuplicados = []
  listaPaginada.forEach(it => {
    const itemDuplicado = lista.some(tr => tr.codigo === it.codigo)
    if (!itemDuplicado) {
      itensNaoDuplicados.push(it)
    }
  })
  return itensNaoDuplicados
}

export const calcularValorBDI = (itemCurvaAbc, custoUnitarioDiretoTotal) => {
  if (!_.isNil(itemCurvaAbc)) {
    const valor = formatarNumeroParaMoedaBR((itemCurvaAbc.bdi / 100) * custoUnitarioDiretoTotal, 2, 2)
    return converterMoedaBRParaNumero(valor)
  } else {
    return 0
  }
}
