import React from 'react'
import { TableCell, TableRow } from '@material-ui/core'
import PropTypes from 'prop-types'
import { formatarNumeroParaMoedaBR } from '../../../../../js/util'

const MemoriaCalculoRow = ({ item }) => {
  return (
    <TableRow hover>
      <TableCell> {item.codigoServico} </TableCell>
      <TableCell> {item.hierarquiaComposicoes} </TableCell>
      <TableCell> = {item.formulaQuantidade} </TableCell>
      <TableCell> {item.quantidadeOperativa} </TableCell>
      <TableCell> {item.tipo === 'Equipamento' ? formatarNumeroParaMoedaBR(item.quantidadeImprodutiva, 2, 2) : '-'} </TableCell>
      <TableCell> = {item.formulaPreco} </TableCell>
      <TableCell> {formatarNumeroParaMoedaBR(item.preco, 2, 2)} </TableCell>
      <TableCell> {item.tipo} </TableCell>
    </TableRow>
  )
}

MemoriaCalculoRow.propTypes = {
  item: PropTypes.object.isRequired
}

export default MemoriaCalculoRow
