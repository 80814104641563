import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import { mapStateToPropsContexto, replaceSpecialChars, showAlertMsg, showSuccessMsg, buscarPalavrasEmTexto } from '../../../../../../js/util'
import { context_httpget, context_httppost } from '../../../../../../js/httpRequest'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core'
import styles from '../styles'
import MesmaLinha from '../../../../../comum/MesmaLinha'
import StyledTableContainerAdicionar from '../../../../../comum/StyledTableContainerAdicionar'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import BuscarComPaginacao from '../../../../../comum/BuscarComPaginacao'
import ListaOrcamentos from '../../../../ListaOrcamentos'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'
import axios from 'axios'
import ComposicoesOrcamentos from './ComposicoesOrcamentos'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Autocomplete from '@mui/material/Autocomplete';

class AdicionarComposicaoOutroOrcamento extends Component {
  static propTypes = {
    match: PropTypes.object,
    contexto: PropTypes.object,
    buscarOrcamentos: PropTypes.func,
    history: PropTypes.array,
    excluirOrcamento: PropTypes.func,
    duplicarOrcamento: PropTypes.func,
    classes: PropTypes.object,
    value: PropTypes.object.isRequired,
    onCancelar: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props)
    this.state = {
        empreendimento: {},
        obra: {},
        empreendimentos: [],
        empreendimentoSelecionado: null,
        obraSelecionada: null,
        obras: [],
        orcamentos: [],
        orcamentoDados: [],
        orcamentosBusca: [],
        pagina: 0,
        registrosPorPagina: 10,
        termoBusca: '',
        cadastrando: false,
        orcamentoSelecionado: null,
        isComposicoesDrawerOpen: false,
    }
    this.handleConfirmarClick = this.handleConfirmarClick.bind(this)
    this.handleComposicoesDrawerClose = this.handleComposicoesDrawerClose.bind(this);
  }

  componentDidMount () {
    if (!_.isNil(this.props.contexto)) {
      this.buscarEmpreendimentos()
    }
  }

   buscarOrcamentos = async (empreendimentoEscolhido, obraSelecionada) => {
    const { contexto } = this.props
    const codEmpreendimento = contexto.empreendimento.cod
    const codObra = contexto.obra.cod

    const orcamentoDados = await axios.get(`/api/empreendimentos/${empreendimentoEscolhido.cod}/obras/${obraSelecionada.cod}/orcamentos`)

    if (orcamentoDados) {
      this.setState({
        orcamentos: orcamentoDados.data,
        orcamentoDados: orcamentoDados.data
      })
      
    }
  
  }

  buscarEmpreendimentos = async () => {
    try {
      const empreendimentos = await axios.get('/api/empreendimentos');
      this.setState({ empreendimentos: empreendimentos.data });
    } catch (error) {
      console.error('Error fetching empreendimentos:', error);
    }
    
  }

  buscarObras = async (empreendimentoSelecionado) => {
      const obras = await axios.get(`/api/empreendimentos/${empreendimentoSelecionado.cod}/obras`);
      this.setState({ obras: obras.data });
  }

  handleEmpreendimentoChange = (event, empreendimentoSelecionado) => {
    this.setState({ empreendimentoSelecionado: empreendimentoSelecionado, obraSelecionada: null });
    if (empreendimentoSelecionado) {
      this.buscarObras(empreendimentoSelecionado)
    } else {
      this.setState({ obras: [] });
    }
  };

  handleObraChange = (event, obraSelecionada) => {
    this.setState({ obraSelecionada: obraSelecionada, orcamentoSelecionado: null });
    const empreendimentoSelecionado = this.state
    const empreendimentoEscolhido = empreendimentoSelecionado.empreendimentoSelecionado
    
    if (obraSelecionada) {
      this.buscarOrcamentos(empreendimentoEscolhido, obraSelecionada)
    } else {
      this.setState({ orcamentos: [] });
    }
  };

  handleOrcamentoChange = (event, orcamentoSelecionado) => {
    this.setState({ orcamentoSelecionado: orcamentoSelecionado });
  };

  handleChange = (event) => {
    const termoBusca = event.target.value;
    this.setState({ termoBusca }, () => {
      this.handleBuscarOrcamento();
    });
  }

  handleKeyPress = (event) => {
    const { key } = event

    if (key === 'Enter') {
      this.buscar()
    }
  }

  handleRowOrcamentoClick (codigoOrcamento) {
    const { orcamentoSelecionado } = this.state
    if (orcamentoSelecionado !== codigoOrcamento) {
      this.setState({ orcamentoSelecionado: codigoOrcamento })
    }
  }

  async handleConfirmarClick () {
    const { orcamentoSelecionado, empreendimentoSelecionado, obraSelecionada } = this.state;
    const pesquisa = {empreendimentoSelecionado, orcamentoSelecionado, obraSelecionada}
    console.log(orcamentoSelecionado)
    console.log(empreendimentoSelecionado)
    console.log(obraSelecionada)

  if (_.isNil(pesquisa)) {
    showAlertMsg('Por favor, selecione um orçamento para vincular como atividade auxiliar.');
    return;
    }
    try {
      const composicaoOrcamentoSelecionado = await this.recuperarComposicoes(pesquisa);
      console.log(composicaoOrcamentoSelecionado)
      this.setState({
        isComposicoesDrawerOpen: true,
        composicaoOrcamentoSelecionado: composicaoOrcamentoSelecionado,
        
      });
    } catch (error) {
      console.error('Error fetching composicoes:', error);
    }
  }

  async recuperarComposicoes(pesquisa) {
    const { contexto } = this.props;
    const updatedContexto = {
      ...contexto,
      orcamento: {
        ...contexto.orcamento,
        cod: pesquisa.orcamentoSelecionado.cod,
      },
      empreendimento: {
        ...contexto.empreendimento,
        cod: pesquisa.empreendimentoSelecionado.cod,
      },
      obra: {
        ...contexto.obra,
        cod: pesquisa.obraSelecionada.cod,
      },
    };

    const data = await context_httpget('precos/composicoes', updatedContexto);
    this.setState({ updatedContexto });
    return data;
  }

  handleComposicoesDrawerClose() {
    this.setState({
      isComposicoesDrawerOpen: false,
      orcamentoSelecionado: null,
    });
  }

  handleBuscarOrcamento = () => {
    const { orcamentos, termoBusca, orcamentoDados } = this.state;
    if (termoBusca === '') {
      this.setState({ orcamentos: orcamentoDados });
    } else {
      const filteredData = orcamentoDados.filter((item) =>
      item.descricao.toLowerCase().includes(termoBusca.toLowerCase())
      );

      this.setState({ orcamentos: filteredData });
    }
  };

  handleCancelar = () => {
    window.location.reload();
  }

  render() {
    const { classes, onCancelar } = this.props;
    const { orcamentosBusca, pagina, registrosPorPagina, termoBusca } = this.state;
    const {
      empreendimentoSelecionado,
      obraSelecionada,
      orcamentoSelecionado,
      empreendimentos,
      obras,
      orcamentos,
    } = this.state;
    const { isComposicoesDrawerOpen, composicaoOrcamentoSelecionado, updatedContexto, contexto } = this.state;

    return (
      <div>
             
             <Box mb={1}>
                {/* Autocomplete empreendimentos */}
                <Autocomplete
                  options={empreendimentos}
                  getOptionLabel={(option) => option.nome} 
                  value={empreendimentoSelecionado}
                  onChange={this.handleEmpreendimentoChange}
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Pesquisar empreendimentos"
                      variant="outlined"
                      
                    />
                  )}
                />
                
              </Box>

              <Box mb={1}>
                {/* Autocomplete obras */}
                {empreendimentoSelecionado && (
                  <Autocomplete
                    options={obras}
                    getOptionLabel={(option) => option.nome} 
                    value={obraSelecionada}
                    onChange={this.handleObraChange}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Pesquisar obras"
                        variant="outlined"
                        
                      />
                    )}
                  />
                )}
              </Box>
             
              <Box mb={1}>
              {/* Autocomplete orcamentos */}
             {obraSelecionada && (
             <Autocomplete
                options={orcamentos}
                getOptionLabel={(option) => (option.descricao.length > 50 ? option.descricao.substring(0, 50) + '...' : option.descricao)}
                value={orcamentoSelecionado}
                onChange={this.handleOrcamentoChange}  
                fullWidth         
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Pesquisar orçamentos"
                    variant="outlined"
                    
                  />
                )}
              />
              )}
              
              </Box>

        <MesmaLinha>
          <Button color='primary' variant='contained' onClick={this.handleConfirmarClick}>Confirmar</Button>
          <Button color='primary' onClick={this.handleCancelar}>Cancelar</Button>
        </MesmaLinha>

        {isComposicoesDrawerOpen && (
            <ComposicoesOrcamentos
                isOpen={isComposicoesDrawerOpen}
                composicaoOrcamentoSelecionado={composicaoOrcamentoSelecionado}
                updatedContexto = {updatedContexto}
                contexto = {contexto}
                onClose={this.handleComposicoesDrawerClose}
        />
        )}

        </div>
    )
  }
}

export default connect(mapStateToPropsContexto)(withStyles(styles)(AdicionarComposicaoOutroOrcamento))
