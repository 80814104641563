import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import {
  CRITERIOS_COMPARACAO_TIPOLOGIA,
  TIPO_COMPARACAO_ENTRE,
  TIPO_COMPARACAO_IGUAL_A,
  TIPO_MEDIDA_DISPERSAO_DESVIO_PADRAO,
  TIPOS_COMPARACAO_TIPOLOGIA,
  TIPOS_MEDIDA_DISPERSAO
} from '../../../analise-conteudo'
import { withStyles } from '@material-ui/core'
import { handleCampoNumericoChange } from '../../../../../../../js/util'
import styles from '../../../styles'

const RegrasTipologiaComum = ({
  classes,
  tipoComparacao,
  criterioComparacao,
  valorComparacao,
  valorComparacaoEmpty,
  valorComparacaoEntre,
  valorComparacaoEntreEmpty,
  tipoMedidaDispersao,
  valorMedidaDispersao,
  valorMedidaDispersaoEmpty,
  onCampoChange,
  onCampoTipoComparacaoChange,
  onValidarCampoEntre
}) => {
  return (
    <React.Fragment>
      <TextField
        select
        value={criterioComparacao}
        onChange={(e) => onCampoChange(e, 'criterioComparacao')}
        variant='outlined'
        className={classes.marginCriterios}
      >
        {CRITERIOS_COMPARACAO_TIPOLOGIA.map((it) =>
          (<MenuItem key={it.value} value={it}>{it.label}</MenuItem>))
        }
      </TextField>

      <TextField
        select
        value={tipoComparacao}
        onChange={(e) => onCampoTipoComparacaoChange(e, 'tipoComparacao')}
        variant='outlined'
        className={classes.marginCriterios}
      >
        {TIPOS_COMPARACAO_TIPOLOGIA.map((it) =>
          (<MenuItem key={it.value} value={it}>{it.label}</MenuItem>))
        }
      </TextField>

      <TextField
        value={valorComparacao}
        error={valorComparacaoEmpty}
        variant='outlined'
        className={classes.marginCriterios}
        onChange={(e) => handleCampoNumericoChange(e, e => onCampoChange(e, 'valorComparacao'))}
        onKeyUp={onValidarCampoEntre}
      />

      { tipoComparacao === TIPO_COMPARACAO_ENTRE &&
        <React.Fragment>
          <Typography gutterBottom style={{ marginRight: 5 }}> e </Typography>
          <TextField
            value={valorComparacaoEntre}
            error={valorComparacaoEntreEmpty}
            onChange={(e) => handleCampoNumericoChange(e, e => onCampoChange(e, 'valorComparacaoEntre'))}
            onKeyUp={onValidarCampoEntre}
            variant='outlined'
          />
        </React.Fragment>
      }

      {tipoComparacao === TIPO_COMPARACAO_IGUAL_A &&
        <TextField
          select
          value={tipoMedidaDispersao}
          onChange={(e) => onCampoChange(e, 'tipoMedidaDispersao')}
          variant='outlined'
          className={classes.marginCriterios}
        >
          {TIPOS_MEDIDA_DISPERSAO.map((it) =>
            (<MenuItem key={it.value} value={it}>{it.label}</MenuItem>))
          }
        </TextField>
      }

      {tipoMedidaDispersao === TIPO_MEDIDA_DISPERSAO_DESVIO_PADRAO &&
        <TextField
          value={valorMedidaDispersao}
          error={valorMedidaDispersaoEmpty}
          onChange={(e) => handleCampoNumericoChange(e, e => onCampoChange(e, 'valorMedidaDispersao'))}
          variant='outlined'
          style={{ width: '10%' }}
        />
      }
    </React.Fragment>
  )
}

RegrasTipologiaComum.propTypes = {
  classes: PropTypes.object,
  tipoComparacao: PropTypes.object.isRequired,
  criterioComparacao: PropTypes.object.isRequired,
  valorComparacao: PropTypes.string.isRequired,
  valorComparacaoEmpty: PropTypes.bool.isRequired,
  valorComparacaoEntre: PropTypes.string,
  valorComparacaoEntreEmpty: PropTypes.bool,
  tipoMedidaDispersao: PropTypes.object,
  valorMedidaDispersao: PropTypes.string,
  valorMedidaDispersaoEmpty: PropTypes.bool,
  onCampoChange: PropTypes.func.isRequired,
  onCampoTipoComparacaoChange: PropTypes.func,
  onValidarCampoEntre: PropTypes.func.isRequired
}

export default withStyles(styles)(RegrasTipologiaComum)
