import React, { useState } from 'react'
import Box from '@material-ui/core/Box'
import Select from 'react-select'
import { defaultStyleReactSelect, errorStyleReactSelect } from '../../../../../../../../js/util'
import { TIPO_INSUMO_COMPOSICAO_DESTE_ORCAMENTO, TIPO_INSUMO_COMPOSICAO_REFERENCIA_PRECO, TIPO_INSUMO_MATERIAL_DESTE_ORCAMENTO, TIPO_INSUMO_MATERIAL_REFERENCIA_PRECO } from '../composicoesAuxiliares'
import PropsTypes from 'prop-types'
import AdicionarMaterialComposicaoPesquisarEmReferenciasDePrecos from '../../insumos/materiais/AdicionarMaterialComposicaoPesquisarEmReferenciasDePrecos'
import _ from 'lodash'
import StyledDrawer from '../../../../../../../comum/StyledDrawer'
import { Button, Typography, withStyles } from '@material-ui/core'
import styles from '../../../styles'
import PesquisarComposicaoAuxiliarReferenciaPreco from '../PesquisarComposicaoAuxiliarReferenciaPreco'

const InsumoAdicionar = ({
  classes,
  tipoInsumo,
  insumo,
  insumoEmpty,
  materiais,
  composicoes,
  handleChange
}) => {
  const [exibirDrawerAdicionar, setExibirDrawerAdicionar] = useState(false)
  const [materialReferenciaPreco, setMaterialReferenciaPreco] = useState(null)
  const [composicaoReferenciaPreco, setComposicaoReferenciaPreco] = useState(null)

  const handleChangeMaterialReferenciaPrecoSelecionado = (material) => {
    setMaterialReferenciaPreco(material)
    handleChange(material, 'insumo')
  }

  const handleChangeComposicaoReferenciaPrecoSelecionado = (composicao) => {
    setComposicaoReferenciaPreco(composicao)
    handleChange(composicao, 'insumo')
  }

  return (
    <>
      {
        tipoInsumo === TIPO_INSUMO_MATERIAL_DESTE_ORCAMENTO.value && (
          <Box mt={1}>
            <Select
              datacy-id='material'
              id='material'
              classNamePrefix='select'
              placeholder={`Selecione o material${insumoEmpty ? ' (Preenchimento obrigatório)' : ''}`}
              options={materiais.map((material) => ({ ...material, label: `${material.codigo} - ${material.descricao}` }))}
              onChange={insumoMaterial => handleChange(insumoMaterial, 'insumo')}
              styles={insumoEmpty ? errorStyleReactSelect : defaultStyleReactSelect}
              value={insumo}
            />
          </Box>
        )
      }

      {
        tipoInsumo === TIPO_INSUMO_COMPOSICAO_DESTE_ORCAMENTO.value && (
          <Box mt={1}>
            <Select
              datacy-id='composicaoAux'
              id='composicaoAux'
              classNamePrefix='select'
              placeholder={`Selecione a composição${insumoEmpty ? ' (Preenchimento obrigatório)' : ''}`}
              options={composicoes.map((composicao) => ({ ...composicao, label: `${composicao.codigo} - ${composicao.descricao}` }))}
              onChange={composicao => handleChange(composicao, 'insumo')}
              styles={insumoEmpty ? errorStyleReactSelect : defaultStyleReactSelect}
              value={insumo}
            />
          </Box>
        )
      }

      {
        tipoInsumo === TIPO_INSUMO_MATERIAL_REFERENCIA_PRECO.value && (
          <Box mt={1}>
            {!_.isNil(materialReferenciaPreco) &&
              <Typography variant='button' gutterBottom style={{ marginRight: 10 }}>
                {`${materialReferenciaPreco.codigo} - ${materialReferenciaPreco.descricao}`}
              </Typography>
            }
            <Button
              color='primary'
              variant='outlined'
              size='small'
              onClick={() => setExibirDrawerAdicionar(true)}
            >
              Selecionar material
            </Button>
            {
              exibirDrawerAdicionar && (
                <StyledDrawer
                  PaperProps={{ className: classes.drawerEdicao }}
                  open
                  anchor='right'>
                  <AdicionarMaterialComposicaoPesquisarEmReferenciasDePrecos
                    codigoComposicao={null}
                    onCancelar={() => setExibirDrawerAdicionar(false)}
                    isPesquisaMateriaisEmTempoFixo
                    onChangeMaterialSelecionado={handleChangeMaterialReferenciaPrecoSelecionado}
                  />
                </StyledDrawer>
              )
            }
          </Box>
        )
      }

      {
        tipoInsumo === TIPO_INSUMO_COMPOSICAO_REFERENCIA_PRECO.value && (
          <Box mt={1}>
            {!_.isNil(composicaoReferenciaPreco) &&
            <Typography variant='button' gutterBottom style={{ marginRight: 10 }}>
              {`${composicaoReferenciaPreco.codigo} - ${composicaoReferenciaPreco.descricao}`}
            </Typography>
            }
            <Button
              color='primary'
              variant='outlined'
              size='small'
              onClick={() => setExibirDrawerAdicionar(true)}
            >
              Selecionar composição
            </Button>
            {
              exibirDrawerAdicionar && (
                <StyledDrawer
                  PaperProps={{ className: classes.drawerEdicao }}
                  open
                  anchor='right'>
                  <PesquisarComposicaoAuxiliarReferenciaPreco
                    codigoComposicao={null}
                    onCancelar={() => setExibirDrawerAdicionar(false)}
                    isPesquisaComposicaoEmTempoFixo
                    onChangeComposicaoSelecionada={handleChangeComposicaoReferenciaPrecoSelecionado}
                  />
                </StyledDrawer>
              )
            }
          </Box>
        )
      }
    </>
  )
}

InsumoAdicionar.propTypes = {
  classes: PropsTypes.object,
  tipoInsumo: PropsTypes.string,
  insumo: PropsTypes.object,
  insumoEmpty: PropsTypes.bool,
  materiais: PropsTypes.array,
  composicoes: PropsTypes.array,
  handleChange: PropsTypes.func.isRequired
}

export default withStyles(styles)(InsumoAdicionar)
