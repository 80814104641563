import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import styles from './styles'
import _ from 'lodash'
import Carregando from '../../../comum/Carregando'
import CurvaAbcInsumoCriada from './CurvaAbcInsumoCriada'
import CurvaAbcInsumoNaoCriada from './CurvaAbcInsumoNaoCriada'
import Alert from '@material-ui/lab/Alert'
import Link from '@material-ui/core/Link'
import Template from '../../../layout/Template'

const CurvaABCInsumo = ({ contexto, history, match }) => {
  const handleCurvaAbcClick = () => history.push(match.url.replace('abc-insumo', 'abc'))
  console.log(contexto.orcamento.possuiCurvaAbcInsumo)
  if (_.isNil(contexto)) {
    return <Carregando />
  }

  return (
    <React.Fragment>
      <CurvaAbcInsumoCriada resumoOrcamento={{}} />
    </React.Fragment>
  );

}

CurvaABCInsumo.propTypes = {
  contexto: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object
}

export const mapStateToProps = ({ contexto }) => ({ contexto })

export default connect(mapStateToProps)(withStyles(styles)(CurvaABCInsumo))
