import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import StyledDrawer from '../../../../../comum/StyledDrawer';
import styles from '../styles';
import PropTypes from 'prop-types';
import { useComposition } from '../../../../../../hooks/useComposition';
import PesquisarComposicaoReferenciaPreco from './PesquisarComposicaoReferenciaPreco';
import { mapStateToPropsContexto } from '../../../../../../js/util';
import { connect } from 'react-redux';
import { context_httpget, context_httppost } from '../../../../../../js/httpRequest';
import { showSuccessMsg } from '../../../../../../js/util';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import TituloVoltar from '../../../../../comum/TituloVoltar';
import AdicionarComposicaoOutroOrcamento from './AdicionarComposicaoOutroOrcamento';
import ImportarComposicaoViaCSV from './ImportarComposicaoViaCSV';


const OUTRO_ORCAMENTO = 0
const REFERENCIA_PRECO = 1
const VIA_ARQUIVO_CSV = 2

const ImportarComposicao = ({ classes, value, onCancelar, contexto }) => {
  const { setDefaultState, onComposicaoAdicionada } = useComposition();
  const [selectedOption, setSelectedOption] = useState(REFERENCIA_PRECO);

  useEffect(() => {
    const carregarDados = () => {
      setDefaultState((prevState) => ({
        ...prevState,
        codigo: value.codigoServico,
        descricao: value.descricaoOrcamento,
        unidade: value.unidadeOrcamento,
        observacao: value.observacao,
        precoUnitarioOrcamento: value.precoUnitarioOrcamento,
      }));
    };
    carregarDados();
  }, []);

  const handleImportarComposicao = async (composicaoSelecionada) => {

    const composicaoImportada = { ...composicaoSelecionada, sistemaReferencia: composicaoSelecionada.sistema, codComposicao: composicaoSelecionada.codigo };

    await context_httppost('precos/importar-composicao', contexto, composicaoImportada);
    showSuccessMsg(`Composição ${composicaoImportada.codComposicao} adicionada com sucesso.`);
    onComposicaoAdicionada(composicaoSelecionada.codigo);
  };

  const handleVoltarClick = () => {
    // Refresh the page when "Voltar" button is clicked
    window.location.reload();
  };

  return (
    
<StyledDrawer PaperProps={{ className: classes.drawerEdicao }} open anchor="right">
  <TituloVoltar onClick={handleVoltarClick} label='Voltar'/>
  <Box my={2}>
  <TextField variant='outlined' select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
      <MenuItem value={REFERENCIA_PRECO}>Pesquisar em referências de preço</MenuItem>
      <MenuItem value={OUTRO_ORCAMENTO}>Composições de outro orçamento</MenuItem>
      <MenuItem value={VIA_ARQUIVO_CSV}>Importar via arquivo .csv</MenuItem>
    </TextField>
  </Box>

  {selectedOption === OUTRO_ORCAMENTO && (
    <AdicionarComposicaoOutroOrcamento
      onCancelar={onCancelar}
    />
  )}

  {selectedOption === REFERENCIA_PRECO && (
    <PesquisarComposicaoReferenciaPreco
      onCancelar={onCancelar}
      isPesquisaComposicaoEmTempoFixo={false}
      onImportarComposicao={handleImportarComposicao}
    />
  )}

{selectedOption === VIA_ARQUIVO_CSV && (
    <ImportarComposicaoViaCSV
      onCancelar={onCancelar}
      isPesquisaComposicaoEmTempoFixo={false}
      onImportarComposicao={handleImportarComposicao}
    />
  )}
</StyledDrawer>
  
  );
};

ImportarComposicao.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.object.isRequired,
  onCancelar: PropTypes.func.isRequired,
};

export default connect(mapStateToPropsContexto)(withStyles(styles)(ImportarComposicao));
