import React, { Component, Fragment } from 'react'
import { Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { context_httpget } from '../../../../../js/httpRequest'
import _ from 'lodash'
import { formatarNumeroParaMoedaBR, paginar } from '../../../../../js/util'
import ItemMaterialRow from './ItemMaterialRow'
import Paginacao from '../../../../comum/Paginacao'
import Grid from '@material-ui/core/Grid'
import  Box  from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import StyledDrawer from '../../../../comum/StyledDrawer'
import BuscarComPaginacao from '../../../../comum/BuscarComPaginacao'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import styles from '../styles'
import AdicionarMaterialAvulso from '../../relatorios/precos/composicao/insumos/materiais/AdicionarMaterialAvulso'

const colunaCodigo = 'materialOrcamento.codigo'
const colunaDescricao = 'materialOrcamento.descricao'

export class Materiais extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    contexto: PropTypes.object.isRequired,
    onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.object
  }

  state = {
    materiais: [],
    materiaisCopia: [],
    termoBusca: '',
    colunaOrdenacao: '',
    direcaoOrdenacao: 'desc',
    totalRegistrosPorPagina: 20,
    apartirDoRegistro: 0,
    pagina: 0,
    totalResultadosElastic: 0,
    campoFiltro: 'all',
    exibirDrawerAdicionarMateriais: false,
  }

  componentDidMount () {
    if (!_.isNil(this.props.contexto)) {
      this.recuperarMateriais()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.termoBusca !== this.state.termoBusca) {
      this.buscar()
    }
  }

  async recuperarMateriais() {
    const { contexto } = this.props

    const data = await context_httpget('precos/insumos/materiais', contexto)
    if (data) {
      this.setState({
        materiais: data,
        materiaisCopia: data
      })
    }
  }

  ordernarMateriais = () => {
    const { materiais, colunaOrdenacao, direcaoOrdenacao } = this.state

    const materiaisOrdenados = _.orderBy(materiais, [colunaOrdenacao], [direcaoOrdenacao])

    this.setState({
      materiais: materiaisOrdenados
    })
  }

  handleChangeOrdenacaoColuna = (event, coluna) => {
    const { colunaOrdenacao, direcaoOrdenacao } = this.state

    let direcao
    if (colunaOrdenacao === coluna) {
      direcao = direcaoOrdenacao === 'desc' ? 'asc' : 'desc'
    } else {
      direcao = 'asc'
    }

    this.setState({
      colunaOrdenacao: coluna,
      direcaoOrdenacao: direcao
    }, () => this.ordernarMateriais())
  }

  handleChangeFilter = (event) => {
    const { value } = event.target
    this.setState({ campoFiltro: value })
  }

  handleChange = (event) => {
    const { value } = event.target
    this.setState({ termoBusca: value })
  }

  handleKeyPress = (event) => {
    const { key } = event

    if (key === 'Enter') {
      this.buscar()
    }
  }

  handleChangeDrawerAdicionarMateriais = () => this.setState({ exibirDrawerAdicionarMateriais: !this.state.exibirDrawerAdicionarMateriais })

  buscar = () => {
    const { termoBusca, materiaisCopia, campoFiltro } = this.state

    if (_.isEmpty(termoBusca)) {
      this.setState({ materiais: materiaisCopia })
    } else {
      const materiaisFiltrados = materiaisCopia.filter((material) => {
        return 'all' === campoFiltro ?material.materialOrcamento.sistemaReferencia.toUpperCase().includes(termoBusca.toUpperCase())
          || `${material.materialOrcamento.mes}/${material.materialOrcamento.ano}`.includes(termoBusca.toUpperCase())
          || material.materialOrcamento.codigo.toUpperCase().includes(termoBusca.toUpperCase())
          || material.materialOrcamento.descricao.toUpperCase().includes(termoBusca.toUpperCase())
          || material.materialOrcamento.unidade.toUpperCase().includes(termoBusca.toUpperCase())
          || formatarNumeroParaMoedaBR(material.materialOrcamento.precoUnitario, 2, 5).includes(termoBusca):
          'mes/ano' === campoFiltro ? `${material.materialOrcamento.mes}/${material.materialOrcamento.ano}`.includes(termoBusca.toUpperCase()):
          'qtdComposicoes' === campoFiltro ?  material[campoFiltro].toString().toUpperCase().includes(termoBusca.toUpperCase()):
              material.materialOrcamento[campoFiltro].toString().toUpperCase().includes(termoBusca.toUpperCase())
      })

      this.setState({
        materiais: materiaisFiltrados,
        pagina: 0
      })
    }
  }

  render () {
    const { classes, onAtualizarItemCurvaABCComposicaoDuplicada } = this.props
    const { materiais, colunaOrdenacao, direcaoOrdenacao, pagina, totalRegistrosPorPagina, campoFiltro, exibirDrawerAdicionarMateriais } = this.state

    return(
      <Fragment>
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <BuscarComPaginacao
              placeholder='Buscar material'
              onChange={(e) => this.handleChange(e)}
              onKeyPress={(e) => this.handleKeyPress(e)}
              onClick={() => this.buscar()}
              disable={false}
              filterOptions={[
                { label:'Sistema de Referência' ,value: 'sistemaReferencia' },
                { label:'Data referência' ,value: 'mes/ano' },
                { label:'Código' ,value: 'codigo' },
                { label:'Descrição' ,value: 'descricao' },
                { label:'Unidade' ,value: 'unidade' },
                { label:'Qtd composições' ,value: 'qtdComposicoes' }]}
              filterField={campoFiltro}
              onChangeFilter={(e) => this.handleChangeFilter(e)}
            />
          </Grid>

          <Grid item xs={12} md={9} container justify="flex-end">
            <Box mr={2} mt={3}>
              <Button
                variant="outlined"
                color="default"
                onClick={() => this.handleChangeDrawerAdicionarMateriais()}>
              
                Adicionar Materiais/Serviços
              </Button>
            </Box>
          </Grid>

        </Grid>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='left'>Referência</TableCell>
              <TableCell align='left'>Data Referência</TableCell>
              <TableCell>
                <TableSortLabel
                  active={ colunaOrdenacao ===  colunaCodigo}
                  direction={ direcaoOrdenacao }
                  onClick={(e) => this.handleChangeOrdenacaoColuna(e, colunaCodigo) }
                >
                  Código
                </TableSortLabel>
              </TableCell>
              <TableCell align='left'>
                <TableSortLabel
                  active={ colunaOrdenacao ===  colunaDescricao}
                  direction={ direcaoOrdenacao }
                  onClick={(e) => this.handleChangeOrdenacaoColuna(e, colunaDescricao) }
                >
                  Descrição
                </TableSortLabel>
              </TableCell>
              <TableCell align='left'>Unidade</TableCell>
              <TableCell align='left'>Preço Unitário (R$)</TableCell>
              <TableCell> Aparece em </TableCell>
              <TableCell align='center'>Editar</TableCell>
              { 1+1 === 3 && (<TableCell align='right'>Ver Composição</TableCell>) }
            </TableRow>
          </TableHead>
          <TableBody>
            {paginar(materiais, pagina, totalRegistrosPorPagina).map((material) => (
              <ItemMaterialRow
                key={material.materialOrcamento.codigo}
                material={material}
                onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
              />
            ))}
            {_.isEmpty(materiais) && <td colSpan="100%"><Alert style={{marginTop: 4, marginBottom: 4}} severity='warning'>Nenhum item foi encontrado.</Alert></td>}
          </TableBody>
        </Table>
        <Paginacao
          total={materiais.length}
          registrosPorPagina={totalRegistrosPorPagina}
          pagina={pagina}
          onChangePage={(e, pag) => { this.setState({ pagina: pag }) }}
          onChangeRowsPerPage={(e) => (this.setState({ totalRegistrosPorPagina: e.target.value })) }
        />

{
          exibirDrawerAdicionarMateriais && (
           
            <StyledDrawer
            PaperProps={{ className: classes.drawerEdicao }}
            open={true}
            anchor={'right'}>
            <AdicionarMaterialAvulso
              
              onDadosAlterados={this.handleDadosAlterados}
              onCancelar={() => this.setState({ exibirDrawerAdicionarMateriais: false })}
            />
          </StyledDrawer>
            
          )
        }

      </Fragment>
    )
  }
}

export default (withStyles(styles)(withRouter(Materiais)))
