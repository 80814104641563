import { tipoOperadorTipologiaCombinada } from '../../analise-conteudo/analise-conteudo'

export const iconCheck = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGTSURBVDhPnZSxS8NAFMbTtBBoi1LQElBRbF1KB8VO1imLdtWlW0dBwcFB/wcFneq/oEtdHNSlUx0ECwriYhGLS1ChKFUIVOJ3eecZk1yk/pbe93hf7t67d43Ytq0E0fp4eu9ZiZiWjad5yIfXfPX2eGQ2G507rh3mU1NL+uz0wBjX3/yYu5/Wfrt++nxD0s/icH513EhGNa6FGc6N20MclaIyUMJuriz8Kv1UH+p/OhNRDTk4HddkRp1nL9LTEgtD+YOZlUw8jbqQT0FmrpmXJGTAuZUpYRFxpMhn5vNOi0Qg5HQ35Vrs7C61MjKHwrhw8DsBJK3Vbs+iEPIqo8W9XFn4A50EudRkjKeiZ2gGLoP8IU5ALnbPxsUOhcDmZAnDYFqvujYoc+LTx4V1LFjDiqmsE2Rs359g/xAnEHPKzMt6gQQBf81sypxA5DMzvoQKSRPVtnTgkPlrZ7A2YWB6aB0CcpDJhedVYcJD5hR7whnwqgSYW0yfe+bQW5wTdYa9Zw+oGZOA++zjn6QveMP+g6J8AfMV4O4kx5gNAAAAAElFTkSuQmCC\n'
export const iconInfo = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAVCAYAAACpF6WWAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADzSURBVDhPtZQ7DsIwEAW5KS20cAgOQU1DkwYKKnoaGhASR4AWGpqFIbYcL+sPUjLSk5J4PYo3dkZSy+3iLsqkpY+7yHElsp2LrMch3POc8QS29NTEolTOnzqDX+lhaQtSoV4RS687e2IpzOsQpPTImkD2i7Ylm5k9Tjo9DlKabxUThIDcGifMdwRp7i344gibqT1OqHG00tzS/8nr+dW1Uja2VeRTs3ziDsiAUl7bKvKplUbLh9yHqpEy3xGkg2yp0ubnnOufi08zSWx+6P2Yenr/oXjooSXQ8b1W2FKgRzRf7wrued7poSYt1WQkmnppNSJv3gP53Tbuj+8AAAAASUVORK5CYII=\n'

export const resultadoTipologiaCombinada = ({ operador, primeiraTipologiaPossuiInconsistencia, segundaTipologiaPossuiInconsistencia }) => {
  switch (tipoOperadorTipologiaCombinada(operador).value) {
    case 'AND':
      return primeiraTipologiaPossuiInconsistencia && segundaTipologiaPossuiInconsistencia ? incosistente() : naoIncosistente()
    case 'OR':
      return primeiraTipologiaPossuiInconsistencia || segundaTipologiaPossuiInconsistencia ? incosistente() : naoIncosistente()
    default:
      return ''
  }
}

const incosistente = () => {
  return { descricao: 'A combinação das tipologias resultou-se em uma possível inconsistência', possuiInconsistencia: true }
}
const naoIncosistente = () => {
  return { descricao: 'Não foram encontrados inconsistências no resultado da combinação', possuiInconsistencia: false }
}
