import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import NenhumCriterioCadastrado from './NenhumCriterioCadastrado'
import CriteriosCadastrados from './CriteriosCadastrados'
import BotaoAdicionarCriterio from './BotaoAdicionarCriterio'
import { connect } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { converterCriterio, urlCriterio } from '../../../../analise-conteudo'
import { context_httpdelete, context_httppost, context_httpput } from '../../../../../../../../js/httpRequest'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import styles from '../../../../styles'
import { showSuccessMsg } from '../../../../../../../../js/util'

class Criterios extends Component {
  static propTypes = {
    expressao: PropTypes.object,
    criterios: PropTypes.array.isRequired,
    onExpressaoChange: PropTypes.func.isRequired,
    expressoesDisponiveis: PropTypes.array.isRequired,
    contexto: PropTypes.object,
    classes: PropTypes.object,
    tipoAnaliseSelecionada: PropTypes.string.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      todosCriterios: []
    }
    this.handleAdicionarCriterio = this.handleAdicionarCriterio.bind(this)
    this.handleCriterioChange = this.handleCriterioChange.bind(this)
    this.handleCriterioExcluido = this.handleCriterioExcluido.bind(this)
    this.handleSalvarCriterio = this.handleSalvarCriterio.bind(this)
  }

  componentDidUpdate (prevProps) {
    const { criterios } = this.props
    if(prevProps.criterios !== criterios) {
      this.atualizarCriterios()
    }
  }

  atualizarCriterios() {
    const { criterios } = this.props
    this.setState({
      todosCriterios: criterios.map(it => converterCriterio(it))
    })
  }

  async handleOperadorExpressaoChange(event) {
    const { expressao, contexto, tipoAnaliseSelecionada, onExpressaoChange } = this.props
    const operador = event.target.value
    const expressaoExecutada = await context_httpput(`analise-conteudo/expressoes/${expressao.codigo}/operador?tipoAnalise=${tipoAnaliseSelecionada}`, contexto, { operador })
    if(expressaoExecutada) {
      const { resultado } = expressaoExecutada
      onExpressaoChange({...expressao, operador}, resultado)
    }
  }

  handleCriterioChange(criterioNovo) {
    const { todosCriterios } = this.state
    const substituirCriterio = (criterio) => {
      return criterio.codigo === criterioNovo.codigo
        ? { ...criterioNovo, alterado: true }
        : criterio
    }
    const novaLista = todosCriterios.map(substituirCriterio)
    this.setState({ todosCriterios: novaLista })
  }

  async handleCriterioExcluido(criterio) {
    const { expressao, contexto, tipoAnaliseSelecionada } = this.props
    const expressaoExecutada = await context_httpdelete(urlCriterio(expressao, criterio, tipoAnaliseSelecionada), contexto)
    if (expressaoExecutada) {
      const { resultado } = expressaoExecutada
      const { todosCriterios } = this.state
      this.setState({
        todosCriterios: todosCriterios.filter((it) => it.codigo !== criterio.codigo)
      })
      showSuccessMsg('Critério excluído')
      this.props.onExpressaoChange(expressao, resultado)
    }
  }

  async handleSalvarCriterio (criterio) {
    const { expressao, contexto, tipoAnaliseSelecionada } = this.props
    const dados = { codigo: criterio.codigo, campo: criterio.campo ? criterio.campo.value : null, texto: criterio.texto, codigoExpressaoCriterio: criterio.codigoExpressaoCriterio }
    const expressaoExecutada = await context_httpput(urlCriterio(expressao, criterio, tipoAnaliseSelecionada), contexto, dados)
    if (expressaoExecutada) {
      const { resultado } = expressaoExecutada
      const { todosCriterios } = this.state
      const substituirCriterio = (it) => {
        return it.codigo === criterio.codigo
          ? { ...criterio, alterado: false }
          : it
      }
      const novaLista = todosCriterios.map(substituirCriterio)
      this.setState({ todosCriterios: novaLista })
      this.props.onExpressaoChange(expressao, resultado)
    }
  }

  async handleAdicionarCriterio (tipo) {
    const { expressao, contexto } = this.props
    const criterio = await context_httppost(`analise-conteudo/expressoes/${expressao.codigo}/criterios`, contexto, { tipo: tipo.value })
    const { todosCriterios } = this.state
    this.setState({
      todosCriterios: todosCriterios.concat(converterCriterio(criterio))
    })
  }

  operadorExpressao() {
    const { classes, expressao } = this.props
    return (
      <MesmaLinha>
        <Typography>Os critérios dessa expressão serão combinados utilizando o operador:</Typography>
        <TextField
          value={expressao.operador}
          onChange={(e) => this.handleOperadorExpressaoChange(e)}
          variant='outlined'
          size='small'
          className={classes.operadorExpressao}
          select
        >
          <MenuItem value='E'>
            { 'E' }
          </MenuItem>
          <MenuItem value='OU'>
            { 'OU' }
          </MenuItem>
        </TextField>
      </MesmaLinha>
    )
  }

  render () {
    const { classes, expressoesDisponiveis, expressao } = this.props
    const { todosCriterios } = this.state
    return (
      <div className={classes.divCriterios}>
        { this.operadorExpressao() }
        {
          Boolean(todosCriterios) && (
            todosCriterios.length === 0
              ? <NenhumCriterioCadastrado />
              : (
                <>
                  <CriteriosCadastrados
                    value={todosCriterios}
                    expressoesDisponiveis={expressoesDisponiveis}
                    operadorExpressao={expressao.operador}
                    onCriterioChange={this.handleCriterioChange}
                    onCriterioExcluido={this.handleCriterioExcluido}
                    onSalvarCriterioClick={this.handleSalvarCriterio}
                  />
                </>
              )
          )
        }
        <BotaoAdicionarCriterio onAdicionarCriterioClick={this.handleAdicionarCriterio} />
      </div>
    )
  }
}

const mapStateToProps = ({ contexto }) => ({
  contexto
})

export default connect(mapStateToProps)(withStyles(styles)(Criterios))
