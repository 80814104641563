const styles = (theme) => ({
  root: {
    flexGrow: 1
  },
  gridSidebar: {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    height: 'calc(100vh - 280px)'
  },
  sidebarAcoes: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  dadosBasicos: {
    padding: 12,
    paddingTop: 12,
    borderBottom: 'none'
  },
  sidebarCollapseLine1: {
    height: 1,
    width: '32px',
    background: theme.palette.primary.main
  },
  sidebarCollapseLine2: {
    height: 1,
    minWidth: '10%',
    width: '100%',
    background: theme.palette.primary.main
  },
  spaceIconCollapseSidebar: {
    border: [[1, 'solid', theme.palette.primary.main]]
  },
  rootCardTemaSidebar: {
    height: 110,
    width: '100%',
    minWidth: 300,
    maxHeight: 120,
    marginBottom: 8,
    paddingLeft: 6,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(241,248,252)'
    }
  },
  rootCardTemaAtivo: {
    border: [[1, 'solid', theme.palette.primary.main]],
    borderLeft: [[8, 'solid', theme.palette.primary.main]],
    boxSizing: 'border-box',
  },
  cardTemaSidebarSubtitle: {
    fontSize: 14
  },
  cardItemAtivo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
    height: 48,
    border: [[1, 'solid', theme.palette.gray.main]],
    borderLeft: [[4, 'solid', theme.palette.primary.main]],
    borderRadius: 2,
    padding: 4,
    marginBottom: 6,
    backgroundColor: 'rgb(241,248,252)'
  },
  cardItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
    height: 48,
    border: [[1, 'solid', theme.palette.gray.main]],
    borderRadius: 2,
    padding: 4,
    marginBottom: 6,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(241,248,252)'
    }
  },
  tipoObrasChipTema: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  tipoObrasChip: {
    marginRight: 3,
    marginLeft: 3
  },
  accordionAnaliseConteudo: {
    backgroundColor: '#e2e4e6',
  },
  accordionSummaryAnalise: {
    margin: 0
  },
  tituloAccordionAnaliseConteudo: {
    fontWeight: '500',
    fontSize: 16,
    lineHeight: 1.2
  },
  analiseCardItem: {
    width: '100%',
    marginTop: 5
  },
  alinharIconeResultadoTipologia: {
    color: '#ffaa5f',
    marginRight: theme.spacing(1),
  },
  iconePadrao: {
    color: '#41c88a',
    marginRight: theme.spacing(1)
  },
  paperDrawer: {
    background: '#f6f8fa',
    width: '70%',
    padding: theme.spacing(1),
    borderLeft: [[4, 'solid', theme.palette.primary.main]],
    boxShadow: 'none'
  },
  label: {
    backgroundColor: 'white'
  },
  tituloPagina: {
    borderBottom: '1px solid #C4C4C4',
    paddingBottom: 16
  },
  tituloOrcamento: {
    backgroundColor: theme.palette.primary.main,
    borderRight: '1px solid #4a4a4a',
    borderTopLeftRadius: 4,
    color: '#fff',
    fontWeight: 500,
    fontSize: '0.8rem',
    padding: '0 8px',
    lineHeight: '1rem'
  },
  tituloRefPreco: {
    backgroundColor: '#f4f5f8',
    borderTopRightRadius: 4,
    color: '#4a4a4a',
    fontWeight: 500,
    fontSize: '0.8rem',
    padding: '0 8px'
  },
  tituloExpressoes: {
    fontWeight: '500'
  },
  cardCriterio: {
    display: 'flex',
    marginBottom: 8
  },
  botaoAdicionar: {
    paddingLeft: 0
  },
  botaoPesquisar: {
    marginTop: 8
  },
  boxDelete: {
    width: 24,
    height: 24
  },
  chipParametro: {
    marginBottom: 4
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'minmax(200px, 2fr) minmax(400px, 5fr)',
    height: '100%',
    marginTop: '-24px',
    marginLeft: '-24px'
  },
  subTituloExpressaoCadastrando: {
    color: theme.palette.primary.main,
    fontSize: '0.7rem',
    fontWeight: 'bold'
  },
  divExpressaoOuTipologiaAtiva: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
    height: 48,
    border: [[1, 'solid', theme.palette.gray.main]],
    borderLeft: [[4, 'solid', theme.palette.primary.main]],
    borderRadius: 2,
    padding: 4,
    marginBottom: 6,
    backgroundColor: 'rgb(241,248,252)'
  },
  divExpressaoOuTipologia: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
    height: 48,
    border: [[1, 'solid', theme.palette.gray.main]],
    borderRadius: 2,
    padding: 4,
    marginBottom: 6,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgb(241,248,252)'
    }
  },
  expressaoCadastrando: {
    border: [[1, 'solid', theme.palette.gray.main]],
    borderRadius: 2,
    height: 48,
    padding: 4
  },
  nomeExpressaoOuTipologia: {
    color: theme.palette.gray.main
  },
  paperCriterio: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    backgroundColor: 'rgb(245,245,245)'
  },
  operadorCriterio: {
    width: 80,
    minWidth: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontWeight: 'bold',
    backgroundColor: '#fff'
  },
  botaoAcaoExpressao: {
    cursor: 'pointer',
    opacity: 0.8,
    color: 'rgb(137,137,137)',
    margin: 4,
    '&:hover': {
      opacity: 1
    }
  },
  campoFormulaExpressao: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  labelHorizontal: {
    marginRight: 4
  },
  conteudoCriterio: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 8,
    width: '100%'
  },
  acoesCriterio: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 160
  },
  textfieldCriterio: {
    backgroundColor: '#fff',
    borderRadius: 0
  },
  tabelaResultados: {
    marginTop: 8
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)',
    marginLeft: 'auto'
  },
  titulo: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 8,
    width: '90%',
    '& button': {
      marginRight: '-16px'
    }
  },
  todasExpressoes: {
    padding: 8,
    paddingRight: 16,
    borderRight: '1px solid #C4C4C4'
  },
  divCriterios: {
    minHeight: 400,
    overflowY: 'auto',
    borderTop: '1px solid #C4C4C4',
    padding: 12,
    borderBottom: 'none'
  },
  divResultados: {
    padding: 12,
    borderTop: '1px solid #C4C4C4'
  },
  divTitulo: {
    marginBottom: 12,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  operadorExpressao: {
    margin: 8
  },
  tituloResultados: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  botaoDisponivel: {
    background: 'rgb(242,153,74,1)',
    color: '#fff',
    '&:hover': {
      background: 'rgb(242,153,74,1)',
      color: '#fff',
      boxShadow: 'none'
    },
    '&:active': {
      background: 'rgb(242,153,74,1)',
      color: '#fff',
      borderColor: '#005cbf'
    },
    '&:focus': {
      background: 'rgb(242,153,74,1)',
      color: '#fff'
    }
  },
  pesquisaOrcamento: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  botaoPesquisarOrcamento: {
    height: 46,
    marginLeft: theme.spacing(2),
    padding: '15px 20px'
  },
  subtituloAssociarTema: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    fontWeight: 'inherit'
  },
  comboExpressao: {
    backgroundColor: '#fff',
    width: 550
  },
  tabelaExpressao: {
    borderRight: '1px solid #ccc'
  },
  expressaoVinculadas: {
    border: '1px solid #ccc',
    padding: 10,
    marginBottom: 10
  },
  nenhumResultado: {
    marginLeft: 10,
    marginTop: -3
  },
  expressaoIndisponivel: {
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    height: 20,
    width: 20,
    marginRight: theme.spacing(1),
    borderRadius: 50,
    display: 'inline',
    backgroundColor: '#bbb'
  },
  expressaoDisponivel: {
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24)',
    height: 20,
    width: 20,
    marginRight: theme.spacing(1),
    borderRadius: 50,
    display: 'inline',
    backgroundColor: '#228B22'
  },
  textFildExpressoes: {
    borderRadius: 0,
    marginRight: theme.spacing(2)
  },
  divParametros: {
    maxHeight: 400,
    overflowY: 'auto',
    borderTop: '1px solid #C4C4C4',
    padding: 12,
    borderBottom: '1px solid #C4C4C4'
  },
  resultadosFormulas: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0
  },
  cardFormulas: {
    borderBottom: 'solid #ccc 1px',
    position: 'relative',
    flexDirection: 'row',
    display: 'flex',
    width: '100%'
  },
  colunaFormula: {
    borderRight: 'solid #ccc 1px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginRight: 8,
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 6
  },
  linhasTexto: {
    display: 'flex',
    alignItems: 'space-around',
    flexDirection: 'column',
    width: '100%'
  },
  linhaTitulo: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  divResultadosFormulas: {
    width: '99%',
    marginTop: 20,
    marginLeft: 13
  },
  treeTipologias: {
    height: 'auto',
    flexGrow: 1,
    maxWidth: 400
  },
  dadosBasicosTipologia: {
    padding: 12,
    paddingTop: 0,
    borderBottom: '1px solid #C4C4C4'
  },
  iconDeleteItens: {
    cursor: 'pointer',
    display: 'flex'
  },
  treeItem: {
    padding: 3
  },
  notificacaoTipologia: {
    padding: 12,
    marginTop: -13
  },
  formulaError: {
    display: 'flex',
    marginLeft: 11,
    marginTop: 10,
    marginBottom: -10,
    width: '100%'
  },
  marginCriterios: {
    marginRight: 8
  },
  marginTextExecutarTema: {
    marginBottom: theme.spacing(1)
  },
  textUnidade: {
    color: 'rgba(67,133,191,1)',
    marginBottom: theme.spacing(1)
  },
  searchTemasPublicos: {
    marginRight: 12,
    width: '100%',
    maxWidth: '90%'
  },
  regrasTipologiaCombinadas: {
    marginRight: 2
  },
  paperImportarDrawer: {
    background: '#f6f8fa',
    width: 900,
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3)
  },
  colorTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer'
  },
  buttonShow: {
    marginTop: 4,
    padding: '0 2px',
    marginLeft: 16
  }
})

export default styles
