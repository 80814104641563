import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'

const StyledFormControl = withStyles(() => ({
  root: {
    marginBottom: 24
  },
}))(FormControl);

export default StyledFormControl
