const drawerWidth = 240

export default (theme) => ({
  flex: {
    flex: 1
  },
  menuButton: {
    marginRight: 36
  },
  link: {
    textDecoration: 'none',
    cursor: 'pointer',
    '&:visited': {
      color: '#054672'
    }
  },
  hide: {
    display: 'none'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: 64,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: '#ffffff',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.001), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.01)'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  appBarShiftLeft: {
    marginLeft: drawerWidth
  },
  feedbackAppToolBar: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '.8rem',
    lineHeight: '1.25em'
  }
})
