import React from 'react'
import InfoIcon from '@material-ui/icons/Info'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Box, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { getTipoCombinacaoTipologia, METODO_ANALISE_EXPRESSAO } from '../../../../../../../js/util'
import { context_httpget } from '../../../../../../../js/httpRequest'
import Link from '@material-ui/core/Link'
import _ from 'lodash'
import { connect } from 'react-redux'
import RegraDescritivaTipologia from './RegraDescritivaTipologia'

const RegraInconsistenciaTipologia = ({
  classes,
  tipoAnaliseSelecionada,
  tipologia,
  tipoTipologia,
  possuiInconsistencia,
  codigoExpressaoOuFormula,
  onAtualizarTipologia,
  contexto
}) => {
  const recuperarDados = async (isTipologiaExpressao) => {
    const tipo = isTipologiaExpressao ? 'expressoes' : 'formulas'
    const response = await context_httpget(`analise-conteudo/${tipo}/${codigoExpressaoOuFormula}?tipoAnalise=${tipoAnaliseSelecionada}`, contexto)
    if (response) {
      onAtualizarTipologia(true, response)
    }
  }

  const isTipologiaExpressao = getTipoCombinacaoTipologia(tipoTipologia) === METODO_ANALISE_EXPRESSAO

  return (
    <React.Fragment>
      <Box display='flex' flexWrap='wrap'>
        <Box display='flex' alignItems='center'>
          {possuiInconsistencia
            ? <InfoIcon color='secondary' size='small' className={classes.alinharIconeResultadoTipologia} />
            : <CheckCircleIcon className={classes.iconePadrao} size='small' />
          }

          <Link color='primary' onClick={() => recuperarDados(isTipologiaExpressao)} style={{ textDecoration: 'none' }}>
            <Typography className={classes.cardTemaSidebarSubtitle} color='primary' style={{ marginRight: 4 }}>
              {tipologia.nome}
            </Typography>
          </Link>
        </Box>

        <RegraDescritivaTipologia
          classes={classes}
          tipoTipologia={tipoTipologia}
          tipologia={tipologia}
        />
      </Box>
      {!_.isEmpty(tipologia.observacao) &&
        <Box display='flex' flexWrap='wrap'>
          <Typography variant='caption'>
            Observação: {tipologia.observacao}
          </Typography>
        </Box>
      }
    </React.Fragment>
  )
}

RegraInconsistenciaTipologia.propTypes = {
  classes: PropTypes.object,
  tipoAnaliseSelecionada: PropTypes.string.isRequired,
  codigoExpressaoOuFormula: PropTypes.number,
  tipologia: PropTypes.object,
  tipoTipologia: PropTypes.string,
  possuiInconsistencia: PropTypes.bool,
  onAtualizarTipologia: PropTypes.func,
  contexto: PropTypes.object
}

export const mapStateToProps = ({ contexto }) => ({ contexto })
export default connect(mapStateToProps)(RegraInconsistenciaTipologia)
