import React, { Component } from 'react'
import _ from 'lodash'
import { context_httpget, context_httppost } from '../../../../../../../../js/httpRequest'
import { formatarNumeroParaMoedaBR, mapStateToPropsContexto, showAlertMsg, showSuccessMsg } from '../../../../../../../../js/util'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { withStyles } from '@material-ui/core'
import styles from '../../../styles'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import StyledTableContainerAdicionar from '../../../../../../../comum/StyledTableContainerAdicionar'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

class AdicionarEquipamentoComposicaoSelecionarDoOrcamento extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired,
    contexto: PropTypes.object,
    classes: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      equipamentos: [],
      equipamentoSelecionado: null,
      termoBusca: '',
      filteredData: null,
      data: [],
      orderBy: 'descricao',
      order: 'asc',
    }
    this.handleConfirmarClick = this.handleConfirmarClick.bind(this)
    
  }

  componentDidMount () {
    if (!_.isNil(this.props.contexto)) {
      this.recuperarEquipamentos()
    }
  }

  async recuperarEquipamentos() {
    const { contexto } = this.props
    const data = await context_httpget('precos/insumos/equipamentos', contexto)
    if (data) {
      this.setState({
        equipamentos: data,
        data
      })
    }
  }

  handleRowEquipamentoClick (codigoEquipamento) {
    const { equipamentoSelecionado } = this.state
    if (equipamentoSelecionado !== codigoEquipamento) {
      this.setState({ equipamentoSelecionado: codigoEquipamento })
    }
  }

  async handleConfirmarClick () {
    const { equipamentoSelecionado } = this.state
    if (_.isNil(equipamentoSelecionado)) {
      showAlertMsg('Por favor, selecione um equipamento para vincular à composição.')
      return
    }
    const { codigoComposicao, contexto, onDadosAlterados } = this.props
    const resultado = await context_httppost(`precos/vincular-equipamento?composicao=${codigoComposicao}&equipamento=${equipamentoSelecionado}`, contexto)
    if (resultado) {
      showSuccessMsg(`Equipamento ${equipamentoSelecionado} vinculado à composição.`)
      onDadosAlterados()
    }

  }

  handleInputChange = (event) => {
    const termoBusca = event.target.value;
    this.setState({ termoBusca }, () => { 
      this.handleBuscarEquipamentoNoOrcamento()})
  }

  handleBuscarEquipamentoNoOrcamento = () => {
    const { termoBusca, data } = this.state;
    if (termoBusca === '') {
      this.setState({ equipamentos: data });
    } else {
      const filteredData = data.filter((item) =>
        item.equipamentoOrcamento.descricao.toLowerCase().includes(termoBusca.toLowerCase())
      );
      this.setState({ equipamentos: filteredData });
    }
  };

  handleSortRequest = (property) => {
    const { order, orderBy } = this.state
    const isAsc = orderBy === property && order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc'});
    this.setState({ orderBy: property });    
  };

  render() {
    const { classes, onCancelar } = this.props
    const { equipamentos, equipamentoSelecionado, termoBusca, order, orderBy } = this.state

    const equipamentosSorted = equipamentos.sort((a, b) => {
      if (order === 'asc') {
        return a.equipamentoOrcamento[orderBy].localeCompare(b.equipamentoOrcamento[orderBy]);
      } else {
        return b.equipamentoOrcamento[orderBy].localeCompare(a.equipamentoOrcamento[orderBy]);
      }
    });

    return (
      <>
        <Box my={2}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              label="Pesquisar"
              name="texto"
              value={termoBusca}
              fullWidth
              style={{ width: 500, marginRight: 10 }}
              onChange={this.handleInputChange}
              onBlur={this.handleBuscarEquipamentoNoOrcamento}
              variant="outlined"
            />
          </div>
        </Box>
        <StyledTableContainerAdicionar>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Referência</TableCell>
                <TableCell>Data Referência</TableCell>
                <TableCell>
                  <TableSortLabel 
                    active={orderBy === 'codigo'} 
                    direction={order} 
                    onClick={() => this.handleSortRequest('codigo')}>
                    Código
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                <TableSortLabel 
                    active={orderBy === 'descricao'} 
                    direction={order} 
                    onClick={() => this.handleSortRequest('descricao')}>
                    Descrição
                  </TableSortLabel>
                </TableCell>
                <TableCell>Custo Produtivo (R$/h)</TableCell>
                <TableCell>Custo improdutivo (R$/h)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {equipamentosSorted.map((it) => {
                const estiloCelula = equipamentoSelecionado === it.equipamentoOrcamento.codigo ? { color: 'white' } : {};
                return (
                  <TableRow
                    key={it.equipamentoOrcamento.codigo}
                    className={equipamentoSelecionado === it.equipamentoOrcamento.codigo ? classes.linhaItemSelecionada : classes.linhaItem}
                    onClick={() => this.handleRowEquipamentoClick(it.equipamentoOrcamento.codigo)}
                  >
                    <TableCell style={estiloCelula}>{it.equipamentoOrcamento.sistemaReferencia}</TableCell>
                    <TableCell style={estiloCelula}>
                      {it.equipamentoOrcamento.mes}/{it.equipamentoOrcamento.ano}
                    </TableCell>
                    <TableCell style={estiloCelula}>{it.equipamentoOrcamento.codigo}</TableCell>
                    <TableCell style={estiloCelula}>{it.equipamentoOrcamento.descricao}</TableCell>
                    <TableCell style={estiloCelula}>{formatarNumeroParaMoedaBR(it.equipamentoOrcamento.custoProdutivo, 2, 4)}</TableCell>
                    <TableCell style={estiloCelula}>{formatarNumeroParaMoedaBR(it.equipamentoOrcamento.custoImprodutivo, 2, 4)}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </StyledTableContainerAdicionar>

        <MesmaLinha>
          <Button color="primary" variant="contained" onClick={this.handleConfirmarClick}>
            Confirmar
          </Button>
          <Button color="primary" onClick={onCancelar}>
            Cancelar
          </Button>
        </MesmaLinha>
      </>
    );
  }
}

export default connect(mapStateToPropsContexto)(withStyles(styles)(AdicionarEquipamentoComposicaoSelecionarDoOrcamento))
