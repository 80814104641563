import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Box, Button, Typography } from '@material-ui/core'
import BuildIcon from '@material-ui/icons/Build'
import AssignmentIcon from '@material-ui/icons/Assignment'
import PropTypes from 'prop-types'
import Expressoes from './expressoes/Expressoes'
import Formulas from './formulas/Formulas'
import Dicas from '../comum/Dicas'
import TipologiasCombinadas from './tipologiasCombinadas/TipologiasCombinadas'
import { PERSONALIZADA } from '../../analise-conteudo'
import { hasAutorities } from '../../../../../../security/securityContext'
import ModalRelatorioAnaliseConteudo from './ModalRelatorioAnaliseConteudo'
import { PERFIL } from '../../../../../../js/perfis'

const Personalizadas = ({
  classes,
  expressoes,
  formulas,
  tipologiasCombinadas,
  onRecarregarExpressoes,
  onRemoverExpressaoDoEstado,
  onRecarregarFormulas,
  onRemoverFormulaDoEstado,
  onRecarregarTipologiaCombinadas,
  onRemoverTipologiaCombinadaDoEstado,
  onChangeOperadorTipologiaCombinada
}) => {
  const history = useHistory()
  const location = useLocation()
  const [openModalRelatorio, setOpenModalRelatorio] = useState(false)

  const handleClickGerenciarTemas = () => history.push(`${location.pathname}/gerenciar-temas`)
  return (
    <React.Fragment>
      <Box>
        <div>
          <Box display='flex' justifyContent='space-between' alignItems='flex-end'>
            <Typography variant='subtitle1' gutterBottom>
              Verificações específicas deste orçamento
            </Typography>
            {hasAutorities([PERFIL.GESTOR_TCU]) && (
              <div>
                <Button variant='outlined' color='default' size='small' onClick={handleClickGerenciarTemas} style={{ marginRight: 5 }}>
                  <BuildIcon fontSize='small' />Gerenciar Temas
                </Button>
                <Button variant='outlined' color='default' size='small' onClick={() => setOpenModalRelatorio(true)}>
                  <AssignmentIcon fontSize='small' />Relatórios
                </Button>
              </div>
            )}
          </Box>
          <Typography color='textSecondary' className={classes.cardTemaSidebarSubtitle} gutterBottom>
            Conjuto de fórmulas e expressões que verifica vários tipos de quantitativos e taxas em orçamentos da Seinfra.
          </Typography>
          <Dicas />
        </div>
      </Box>
      <Expressoes
        classes={classes}
        expressoes={expressoes}
        tipoAnaliseSelecionada={PERSONALIZADA}
        onRecarregarExpressoes={onRecarregarExpressoes}
        onRemoverExpressaoDoEstado={onRemoverExpressaoDoEstado}
      />
      <Formulas
        classes={classes}
        formulas={formulas}
        tipoAnaliseSelecionada={PERSONALIZADA}
        onRecarregarFormulas={onRecarregarFormulas}
        onRemoverFormulaDoEstado={onRemoverFormulaDoEstado}
      />
      <TipologiasCombinadas
        classes={classes}
        tipologiasCombinadas={tipologiasCombinadas}
        tipoAnaliseSelecionada={PERSONALIZADA}
        onChangeOperador={onChangeOperadorTipologiaCombinada}
        onRecarregarTipologiaCombinadas={onRecarregarTipologiaCombinadas}
        onRemoverTipologiaCombinadaDoEstado={onRemoverTipologiaCombinadaDoEstado}
      />
      <ModalRelatorioAnaliseConteudo
        openDialog={openModalRelatorio}
        closeDialog={() => setOpenModalRelatorio(false)}
      />
    </React.Fragment>
  )
}

Personalizadas.propTypes = {
  classes: PropTypes.object,
  expressoes: PropTypes.array,
  formulas: PropTypes.array,
  tipologiasCombinadas: PropTypes.array,
  onRecarregarExpressoes: PropTypes.func.isRequired,
  onRemoverExpressaoDoEstado: PropTypes.func.isRequired,
  onRecarregarFormulas: PropTypes.func.isRequired,
  onRemoverFormulaDoEstado: PropTypes.func.isRequired,
  onRecarregarTipologiaCombinadas: PropTypes.func.isRequired,
  onRemoverTipologiaCombinadaDoEstado: PropTypes.func.isRequired,
  onChangeOperadorTipologiaCombinada: PropTypes.func.isRequired
}

export default Personalizadas
