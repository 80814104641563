import React from 'react'
import PropTypes from 'prop-types'
import Cabecalho from '../orcamento-bruto/Cabecalho'
import Alert from '@material-ui/lab/Alert'
import { withStyles } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { context_httppost } from '../../../../js/httpRequest'
import { showSuccessMsg } from '../../../../js/util'
import { connect } from 'react-redux'
import styles from './styles'

const CurvaAbcInsumoNaoCriada = ({ contexto }) => {
  const handleCriar = async () => {
    const curvaAbcComResumo = await context_httppost('curva-abc-insumo', contexto)
    if (curvaAbcComResumo) {
      showSuccessMsg('Curva ABC de insumo criada')
      window.location.reload(false)
    }
  }

  return (
    <React.Fragment>
      <Cabecalho
        titulo='Curva ABC de insumos'
      />
      {
        <Alert severity='info'>
          A curva ABC de insumo ainda não foi gerada para este orçamento.
          <Button variant='contained' color='primary' onClick={handleCriar} style={{ marginLeft: 10 }}>
            Gerar agora
          </Button>
        </Alert>
      }
    </React.Fragment>
  )
}

CurvaAbcInsumoNaoCriada.propTypes = {
  contexto: PropTypes.object
}

export const mapStateToProps = ({ contexto }) => ({ contexto })
export default connect(mapStateToProps)(withStyles(styles)(CurvaAbcInsumoNaoCriada))
