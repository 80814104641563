import { tabelaExpressoes } from '../orcamento/TabelaExpressoes'
import { tabelaFormulas } from '../orcamento/TabelaFormulas'
import { tabelaTipologiasCombinadas } from '../orcamento/TabelaTipologiasCombinadas'

export function dadosTemaPDF (doc, temas, { posicaoX, posicaoY }) {
  temas.forEach(tema => {
    posicaoY += 20
    doc.setFontSize(12)
    doc.setDrawColor(200, 200, 200)
    doc.text(tema.nomeTema, posicaoX, posicaoY)

    posicaoY += 15
    doc.setFontSize(10)
    doc.setDrawColor(200, 200, 200)
    doc.text(tema.descricaoTema, posicaoX, posicaoY, { maxWidth: 500 })
    const posicaoTabelaExpressoes = tabelaExpressoes(doc, tema.expressoes, posicaoX, posicaoY)
    const posicaoTabelaFormulas = tabelaFormulas(doc, tema.formulas, posicaoTabelaExpressoes)
    const posicaoTabelaCombinadas = tabelaTipologiasCombinadas(doc, tema.combinadas, posicaoTabelaFormulas)
    posicaoY = posicaoTabelaCombinadas.posicaoY
  })
}
