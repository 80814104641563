import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/Warning'
import ModalConfirmacao from '../../../comum/ModalConfirmacao'
import PropTypes from 'prop-types'

const ModalExcluirItemOrcamento = ({ item, fechar, confirmar }) => (
  <ModalConfirmacao
    open={item != null}
    onClose={fechar}
    confirmar={confirmar}
    titulo={<Fragment>Excluir item de orçamento <WarningIcon color='inherit' className='vaMid'  /></Fragment>}
  >
    <Typography align='justify'>
      Confirma a exclusão do item de orçamento?
    </Typography>
  </ModalConfirmacao>
)

ModalExcluirItemOrcamento.propTypes = {
  item: PropTypes.object,
  fechar: PropTypes.func.isRequired,
  confirmar: PropTypes.func.isRequired
}

export default ModalExcluirItemOrcamento