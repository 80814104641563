import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import Template from '../../../layout/Template'
import Cabecalho from '../orcamento-bruto/Cabecalho'
import styles from './styles'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import Carregando from '../../../comum/Carregando'
import { context_httpget } from '../../../../js/httpRequest'
import Composicoes from './composicoes/Composicoes'
import ComposicoesAvulsas from './composicoes/ComposicoesAvulsas'
import MenuPreco from './MenuPreco'
import ComposicaoAuxiliar from './composicoes/auxiliar/ComposicaoAuxiliar'
import { MENU_COMPOSICOES, MENU_COMPOSICOES_AVULSAS, MENU_EQUIPAMENTOS, MENU_MAOS_DE_OBRA, MENU_MATERIAIS, MENU_TRANSPORTES } from './preco'
import { Equipamentos } from './equipamentos/Equipamentos'
import { MaosDeObra } from './maos-de-obra/MaosDeObra'
import { Materiais } from './materiais/Materiais'
import { Transportes } from './transportes/Transportes'
import Link from '@material-ui/core/Link'
import ExcluirComposicao from '../relatorios/precos/composicao/ExcluirComposicao'

export class Precos extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    contexto: PropTypes.object,
    history: PropTypes.object,
    match: PropTypes.object
  }

  state = {
    composicoes: null,
    composicoesAvulsas: null,
    totalRegistrosPorPagina: 20,
    apartirDoRegistro: 0,
    pagina: 0,
    menuSelecionado: MENU_COMPOSICOES,
    renderComposicaoAuxiliar: false,
    openDialogExcluirComposicao: false,
    composicaoSelecionada: null,
    resumoOrcamento: ''
  }

  constructor (props) {
    super(props)
    this.handleSetMenuSelecionado = this.handleSetMenuSelecionado.bind(this)
    this.handleVerComposicaoAuxiliar = this.handleVerComposicaoAuxiliar.bind(this)
    this.handleExcluirComposicao = this.handleExcluirComposicao.bind(this)
    this.handleAtualizarComposicoes = this.handleAtualizarComposicoes.bind(this)
    this.handleAtualizarComposicoesAvulsas = this.handleAtualizarComposicoesAvulsas.bind(this)
    this.handleCurvaAbcClick = this.handleCurvaAbcClick.bind(this)
    this.handleRowsPerPageChange = this.handleRowsPerPageChange.bind(this)
    this.handlePage = this.handlePage.bind(this)
    this.handleAtualizarItemCurvaABCComposicaoDuplicada = this.handleAtualizarItemCurvaABCComposicaoDuplicada.bind(this)
  }

  componentDidMount () {
    if (!_.isNil(this.props.contexto)) {
      this.recuperarComposicoes()
      this.recuperarComposicoesAvulsas()
      this.recuperarResumo()
    }

    const historyLocationState = this.props.history.location.state
    if (!_.isNil(historyLocationState) && !_.isNil(historyLocationState.menuSelecionado)) {
      this.handleSetMenuSelecionado(this.props.history.location.state.menuSelecionado)
    }
  }

  async recuperarResumo() {
    const { contexto } = this.props
    const resumoOrcamento = await context_httpget('resumo', contexto, () => {}, true)
    if (resumoOrcamento) {
      this.setState({
        resumoOrcamento
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.contexto !== this.props.contexto) {
      this.recuperarComposicoes()
      this.recuperarComposicoesAvulsas()
      this.recuperarResumo()
    }
  }

  async recuperarComposicoes(codigo) {
    const { contexto } = this.props
    const data = await context_httpget('precos/composicoes', contexto)
    if (data) {
      this.setState({
        composicoes: data
      })
    }

    if (codigo && !(data.some(item => item.codigo === codigo))) {
      this.handleSetMenuSelecionado('MENU_COMPOSICOES_AVULSAS')
    }
  }

  async recuperarComposicoesAvulsas(codigo) {
    const { contexto } = this.props
    const data = await context_httpget('precos/composicoes-avulsas', contexto)
    if (data) {
      this.setState({
        composicoesAvulsas: data
      })
    }

    if (codigo && !(data.some(item => item.codigo === codigo))) {
      this.handleSetMenuSelecionado('MENU_COMPOSICOES')
    }
  }

  handleAtualizarItemCurvaABCComposicaoDuplicada = (codigo) => {
    this.recuperarComposicoes(codigo)
    this.recuperarComposicoesAvulsas(codigo)
  }

  handleSetMenuSelecionado(menu) {
    this.setState({
      menuSelecionado: menu
    })
  }

  handleVerComposicaoAuxiliar(composicao, rederComposicaoAuxiliar) {
    this.setState({
      renderComposicaoAuxiliar: rederComposicaoAuxiliar,
      composicaoSelecionada: composicao
    })
  }

  handleExcluirComposicao(composicao, openDialogExcluirComposicao) {
    this.setState({
      openDialogExcluirComposicao: openDialogExcluirComposicao,
      composicaoSelecionada: composicao
    })
  }

  handleRowsPerPageChange(e) {
    this.setState({ pagina: 0, totalRegistrosPorPagina: e.target.value })
  }

  handlePage(page) {
    this.setState({ pagina: page })
  }

  handleCurvaAbcClick() {
    const { history, match } = this.props
    history.push(match.url.replace('precos', 'abc'))
  }

  async handleAtualizarComposicoes(codComposicao) {
    // const { composicoes } = this.state
    // this.setState({
    //   openDialogExcluirComposicao: false,
    //   composicoes: composicoes.filter(it => it.codigo !== codComposicao)
    // })
    const { contexto } = this.props
    const data = await context_httpget('precos/composicoes', contexto)
    if (data) {
      this.setState({
        openDialogExcluirComposicao: false,
        composicoes: data
      })
    }  
  }

  async handleAtualizarComposicoesAvulsas(codComposicao) {
    // const { composicoesAvulsas } = this.state
    // this.setState({
    //   openDialogExcluirComposicao: false,
    //   composicoesAvulsas: composicoesAvulsas.filter(it => it.codigo !== codComposicao)
    // })
    const { contexto } = this.props
    const data = await context_httpget('precos/composicoes-avulsas', contexto)
    if (data) {
      this.setState({
        openDialogExcluirComposicao: false,
        composicoesAvulsas: data
      })
    }      
  }

  render () {
    const { classes, contexto } = this.props
    const {
      composicoes,
      composicoesAvulsas,
      pagina,
      totalRegistrosPorPagina,
      renderComposicaoAuxiliar,
      openDialogExcluirComposicao,
      composicaoSelecionada,
      menuSelecionado,
      resumoOrcamento
    } = this.state

    if (_.isNil(composicoes)) {
      return <Carregando />
    }

    if (!contexto.orcamento.possuiCurvaAbc) {
      return (
        <Template>
          Só é possível visualizar os Preços do Orçamento após <Link onClick={this.handleCurvaAbcClick}>criar a Curva ABC</Link>.
        </Template>
      )
    }

    if(renderComposicaoAuxiliar) {
      return (
        <ComposicaoAuxiliar
          classes={classes}
          composicao={composicaoSelecionada}
          handleVerComposicaoAuxiliar={this.handleVerComposicaoAuxiliar}
          onAtualizarItemCurvaABCComposicaoDuplicada={this.handleAtualizarItemCurvaABCComposicaoDuplicada}
        />
      )
    }

    return (
      <Template>
        <Cabecalho 
          titulo='Preços'
          resumoOrcamento={resumoOrcamento}
          numeroPossiveisErros={resumoOrcamento.numeroPossiveisErros}
          numeroPossiveisInconsistencias={resumoOrcamento.numeroPossiveisInconsistencias}
        />
        <MenuPreco
          menuSelecionado={menuSelecionado}
          handleMenuSelecionado={this.handleSetMenuSelecionado}
        />
        { menuSelecionado === MENU_COMPOSICOES && (
          <>
            {openDialogExcluirComposicao &&
              <ExcluirComposicao
                openDialogExcluirComposicao={openDialogExcluirComposicao}
                composicao={composicaoSelecionada}
                onAtualizarComposicoes={this.handleAtualizarComposicoes}
                onCancelar={() => this.setState({ openDialogExcluirComposicao: false })}
              />
            }
            <Composicoes
              composicoes={composicoes}
              pagina={pagina}
              handleVerComposicaoAuxiliar={this.handleVerComposicaoAuxiliar}
              onExcluirComposicao={this.handleExcluirComposicao}
              registrosPorPagina={totalRegistrosPorPagina}
              onChangePage={(e, pag) => { this.setState({ pagina: pag }) }}
              onChangeRowsPerPage={this.handleRowsPerPageChange}
              handleAtualizarPagina={this.handlePage}
              onAtualizarItemCurvaABCComposicaoDuplicada={this.handleAtualizarItemCurvaABCComposicaoDuplicada}
            />
          </>
          )
        }

        { menuSelecionado === MENU_COMPOSICOES_AVULSAS && (
          <>
            {openDialogExcluirComposicao &&
              <ExcluirComposicao
                openDialogExcluirComposicao={openDialogExcluirComposicao}
                composicao={composicaoSelecionada}
                onAtualizarComposicoes={this.handleAtualizarComposicoesAvulsas}
                onCancelar={() => this.setState({ openDialogExcluirComposicao: false })}
              />
            }
            <ComposicoesAvulsas
              composicoes={composicoesAvulsas}
              pagina={pagina}
              handleVerComposicaoAuxiliar={this.handleVerComposicaoAuxiliar}
              onExcluirComposicao={this.handleExcluirComposicao}
              registrosPorPagina={totalRegistrosPorPagina}
              onChangePage={(e, pag) => { this.setState({ pagina: pag }) }}
              onChangeRowsPerPage={this.handleRowsPerPageChange}
              handleAtualizarPagina={this.handlePage}
              onAtualizarItemCurvaABCComposicaoDuplicada={this.handleAtualizarItemCurvaABCComposicaoDuplicada}
            />
          </>
          )
        }

        { menuSelecionado === MENU_EQUIPAMENTOS && <Equipamentos classes={classes} contexto={contexto} onAtualizarItemCurvaABCComposicaoDuplicada={this.handleAtualizarItemCurvaABCComposicaoDuplicada} /> }
        { menuSelecionado === MENU_MAOS_DE_OBRA && <MaosDeObra classes={classes} contexto={contexto} onAtualizarItemCurvaABCComposicaoDuplicada={this.handleAtualizarItemCurvaABCComposicaoDuplicada} /> }
        { menuSelecionado === MENU_MATERIAIS && <Materiais classes={classes} contexto={contexto} onAtualizarItemCurvaABCComposicaoDuplicada={this.handleAtualizarItemCurvaABCComposicaoDuplicada} /> }
        { menuSelecionado === MENU_TRANSPORTES && <Transportes classes={classes} contexto={contexto} onAtualizarItemCurvaABCComposicaoDuplicada={this.handleAtualizarItemCurvaABCComposicaoDuplicada} /> }
        {_.isEmpty(contexto) && <td colSpan="100%"><Alert style={{marginTop: 4, marginBottom: 4}} severity='warning'>Problema no carregamento de itens ou não há itens a exibir</Alert></td>}
      </Template>
    )
  }
}

export const mapStateToProps = ({ contexto }) => ({ contexto })
export default connect(mapStateToProps)(withRouter(withStyles(styles)(Precos)))
