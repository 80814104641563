import React from 'react'
import { Box, Card, CardContent, Typography } from '@material-ui/core'
import ReactTooltip from 'react-tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import _ from 'lodash'
import PropTypes from 'prop-types'
import RegraDescritivaTipologia from '../../analise-conteudo/content/comum/tipologia/RegraDescritivaTipologia'
import { METODO_ANALISE_EXPRESSAO, METODO_ANALISE_FORMULA } from '../../../../../js/util'

const LinhaItemVinculado = ({
  classes,
  codigo,
  nome,
  descricao,
  tipologia,
  isSubItem,
  onDesvincular
}) => {
  return (
    <React.Fragment>
      <Card elevation={0} className={classes.analiseCardItem}>
        <CardContent style={{ padding: 5 }}>
          <Box display='flex' justifyContent='space-between'>
            <div>
              <Typography variant='subtitle2'>
                {nome}
              </Typography>
              <Typography color='textSecondary' className={classes.cardTemaSidebarSubtitle}>
                {descricao}
              </Typography>
            </div>
            { !isSubItem && (
              <Box display='flex' justifyContent='space-between' alignItems='center' style={{ cursor: 'pointer' }}>
                <a data-tip data-for={`excluirID${codigo}`} onClick={() => onDesvincular(codigo)}>
                  <DeleteIcon color='action' style={{ marginRight: 5 }} />
                </a>
                <ReactTooltip id={`excluirID${codigo}`} place='top' type='dark' effect='solid'>Excluir</ReactTooltip>
              </Box>)
            }
          </Box>

          {/* expressão */}
          {!_.isNil(tipologia) && !_.isNil(tipologia.variavelAnalise) && (
            <Box display='flex' alignItems='center'>
              <Typography className={classes.cardTemaSidebarSubtitle} color='primary' style={{ marginRight: 5 }}>
                {tipologia.nome}
              </Typography>
              <RegraDescritivaTipologia
                classes={classes}
                tipoTipologia={METODO_ANALISE_EXPRESSAO}
                tipologia={tipologia}
              />
            </Box>)
          }

          {/* formulas */}
          {!_.isNil(tipologia) && _.isNil(tipologia.variavelAnalise) && (
            <Box display='flex' alignItems='center'>
              <Typography className={classes.cardTemaSidebarSubtitle} color='primary' style={{ marginRight: 5 }}>
                {tipologia.nome}
              </Typography>
              <RegraDescritivaTipologia
                classes={classes}
                tipoTipologia={METODO_ANALISE_FORMULA}
                tipologia={tipologia}
              />
            </Box>)
          }
        </CardContent>
      </Card>
    </React.Fragment>
  )
}

LinhaItemVinculado.propTypes = {
  classes: PropTypes.object,
  codigo: PropTypes.number,
  nome: PropTypes.string,
  descricao: PropTypes.string,
  tipologia: PropTypes.object,
  isSubItem: PropTypes.bool,
  onDesvincular: PropTypes.func.isRequired
}

export default LinhaItemVinculado
