import _ from 'lodash'
import currency from 'currency.js'
import moment from 'moment'
import store from '../store/configureStore'
import { showMessage } from '../actions/messagesActions'

export const INFO = 'info'
export const ALERT = 'alert'
export const SUCCESS = 'success'
export const ERROR = 'error'
export const ERROR_CANCEL_PROMISE = 'ERROR_CANCEL_PROMISE'

export const METODO_ANALISE_EXPRESSAO = 'EXPRESSAO'
export const METODO_ANALISE_FORMULA = 'FORMULA'
export const METODO_ANALISE_TIPOLOGIA = 'TIPOLOGIA'

export const ANALISE_CONTEUDO_PERSONALIZADA = 'PERSONALIZADA'
export const ANALISE_CONTEUDO_TEMA = 'TEMATICA'
export const CODE_NUMBER_BACKSPACE_EVENT = 8
export const CODE_NUMBER_ENTER_EVENT = 13

export const REGISTROS_POR_PAGINA_BACKEND = 100

export const buscarPalavrasEmTexto = (texto, palavrasBusca) => {
  const palavrasTexto = _.words(texto)
  return palavrasBusca.some((palavraBusca) => palavrasTexto.some((palavraTexto) => palavraTexto.includes(palavraBusca)))
}

export const urlAnaliseOrcamento = (codEmpreendimento, codObra, codOrcamento, possuiCurvaAbc) => {
  if (possuiCurvaAbc) {
    return `/empreendimentos/${codEmpreendimento}/obras/${codObra}/orcamentos/${codOrcamento}/abc`
  } else {
    return `/empreendimentos/${codEmpreendimento}/obras/${codObra}/orcamentos/${codOrcamento}/analisar`
  }
}

const currencyConfig = { separator: '.', decimal: ',', precision: 4 }

export const converterParaNumero = (valorNaoFormatado) => {
  if (valorNaoFormatado) {
    const cur = currency(valorNaoFormatado, currencyConfig)
    if (cur) return cur.value
  }
  return valorNaoFormatado
}

export const paginar = (registros, pagina, registrosPorPagina) => {
  return registros.slice(pagina * registrosPorPagina, pagina * registrosPorPagina + registrosPorPagina)
}

export const cursorPointer = {
  cursor: 'pointer'
}

export const safeIsNaN = (value) => Number.isNaN(Number(value))

export const url = ({ idEmpreendimento, idObra, idOrcamento }) => `/api/empreendimentos/${idEmpreendimento}/obras/${idObra}/orcamentos/${idOrcamento}` // eslint-disable-line max-len

const showMsg = (type, msgText) => store.dispatch(showMessage({ type, msgText }))
export const showErrorMsg = (message) => showMsg(ERROR, message)
export const showAlertMsg = (message) => showMsg(ALERT, message)
export const showSuccessMsg = (message) => showMsg(SUCCESS, message)

export const isArrayBuffer = (value) => (value && value instanceof ArrayBuffer)

export const arrayBufferToJson = (ab) => (JSON.parse(new TextDecoder('utf8').decode(ab)))

export const computarPagina = (pagina, registrosPorPagina, totalRegistros) => {
  const razao = totalRegistros / registrosPorPagina
  const fatorMultiplicacao = Math.floor(pagina / razao)
  return pagina - (razao * fatorMultiplicacao)
}

export const SISTEMAS_REFERENCIAS = [
  { nome: 'SICRO2' },
  { nome: 'SICRO' },
  { nome: 'SINAPI' }
]

export const UFS = [
  { nome: 'Acre', sigla: 'AC' },
  { nome: 'Alagoas', sigla: 'AL' },
  { nome: 'Amapá', sigla: 'AP' },
  { nome: 'Amazonas', sigla: 'AM' },
  { nome: 'Bahia', sigla: 'BA' },
  { nome: 'Ceará', sigla: 'CE' },
  { nome: 'Distrito Federal', sigla: 'DF' },
  { nome: 'Espírito Santo', sigla: 'ES' },
  { nome: 'Goiás', sigla: 'GO' },
  { nome: 'Maranhão', sigla: 'MA' },
  { nome: 'Mato Grosso', sigla: 'MT' },
  { nome: 'Mato Grosso do Sul', sigla: 'MS' },
  { nome: 'Minas Gerais', sigla: 'MG' },
  { nome: 'Pará', sigla: 'PA' },
  { nome: 'Paraíba', sigla: 'PB' },
  { nome: 'Paraná', sigla: 'PR' },
  { nome: 'Pernambuco', sigla: 'PE' },
  { nome: 'Piauí', sigla: 'PI' },
  { nome: 'Rio de Janeiro', sigla: 'RJ' },
  { nome: 'Rio Grande do Norte', sigla: 'RN' },
  { nome: 'Rio Grande do Sul', sigla: 'RS' },
  { nome: 'Rondônia', sigla: 'RO' },
  { nome: 'Roraima', sigla: 'RR' },
  { nome: 'Santa Catarina', sigla: 'SC' },
  { nome: 'São Paulo', sigla: 'SP' },
  { nome: 'Sergipe', sigla: 'SE' },
  { nome: 'Tocantins', sigla: 'TO' }
]

export const MESES = [
  { numero: 1, nome: 'Janeiro' },
  { numero: 2, nome: 'Fevereiro' },
  { numero: 3, nome: 'Março' },
  { numero: 4, nome: 'Abril' },
  { numero: 5, nome: 'Maio' },
  { numero: 6, nome: 'Junho' },
  { numero: 7, nome: 'Julho' },
  { numero: 8, nome: 'Agosto' },
  { numero: 9, nome: 'Setembro' },
  { numero: 10, nome: 'Outubro' },
  { numero: 11, nome: 'Novembro' },
  { numero: 12, nome: 'Dezembro' }
]

export const recuperarMes = (mes) => MESES.find((m) => m.numero === mes || m.nome === mes)

export const MESES_ANO_ATUAL = () => {
  const mesAtual = new Date().getMonth() + 1
  return MESES.filter((mes) => mes.numero <= mesAtual)
}

export const ANOS_COMPOSICAO = () => {
  const anos = []
  const anoInicio = 1999
  const anoAtual = new Date().getFullYear()

  for (let cont = anoInicio; cont < anoAtual; cont++) {
    anos.push(cont + 1)
  }

  return anos.sort((anoA, anoB) => anoB === anoA ? 0 : anoB > anoA ? 1 : -1)
}

export const formatarUF = (sigla) => {
  const uf = UFS.find(uf => uf.sigla === sigla)
  return `${uf.nome}/${uf.sigla}`
}

export const COMPOSICAO = { nome: 'COMPOSICAO', descricao: 'Composição' }
export const EQUIPAMENTO = { nome: 'EQUIPAMENTO', descricao: 'Equipamento' }
export const MAO_DE_OBRA = { nome: 'MAO_DE_OBRA', descricao: 'Mão de Obra' }
export const MATERIAL = { nome: 'MATERIAL', descricao: 'Material' }

export const formatarNumeroParaMoedaBR = (value, minDecimalDigit, maxDecimalDigit) => {
  let valueFormat = value
  valueFormat = Number(valueFormat)
  if (!Number.isNaN(valueFormat)) {
    return valueFormat.toLocaleString('pt-BR', {
      minimumFractionDigits: minDecimalDigit, maximumFractionDigits: maxDecimalDigit
    })
  }
  return value
}

export const converterMoedaBRParaNumero = (value) => {
  const valorConvertido = value && Number(value.replace(/\./g, '').replace(',', '.'))
  return safeIsNaN(valorConvertido) ? value : valorConvertido
}

export const alterarSeparadorDecimal = (valor, { separadorAtual, separadorNovo }) =>
  !_.isNil(valor) ? valor.toString().replace(separadorAtual, separadorNovo) : separadorAtual

export const formatarMoedaBR = (valor) => {
  if (!valor) return valor
  let v = valor.toString().replace(/\D/g, '')
  v = `${(v / 100).toFixed(2)}`
  v = v.replace('.', ',')
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,')
  v = v.replace(/(\d)(\d{3}),/g, '$1.$2,')
  return v
}

export const contemSomenteNumerosPositivos = (valor) => {
  const partes = valor.toString().split(',')
  const qtdePartes = partes.length
  return !valor.startsWith(',') && qtdePartes < 3 && partes.filter(
    (it) => !isNaN(it) && !it.includes('-') && !it.includes('.')
  ).length === qtdePartes
}

export const handleCampoNumericoChange = (event, callback, max) => {
  if (seStringVazia(event.target.value) ||
    (contemSomenteNumerosPositivos(event.target.value) && (_.isNil(max) || Number(converterMoedaBRParaNumero(event.target.value)) <= max))) {
    callback(event)
  }
}

export const formatarData = (data, formatoVelho, formatoNovo) => moment(data, formatoVelho).format(formatoNovo)

export const METODOSCALCULOSOBREPRECO = [
  { id: 'LIMITACAO_PRECO_UNITARIO', sigla: 'MLPU', descricao: 'Limitação dos Preços Unitários Ajustados' },
  { id: 'LIMITACAO_PRECO_GLOBAL', sigla: 'MLPG', descricao: 'Limitação do Preço Global' }
]

export const emptyFunction = () => void 0

export const truncateString = (text, size) => text.length > size ? `${text.substring(0, size)}...` : text

export const seStringVazia = (valor) => _.isNil(valor) || _.isEmpty(valor.trim())
export const seObjectVazio = (object) => _.isNil(object) || _.isEmpty(object)

export const mapStateToPropsContexto = ({ contexto }) => ({ contexto })

export const textNormalize = (value) => !_.isNil(value) ? value.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : ''

export const errorStyleReactSelect = {
  control: (styles) => ({ ...styles, borderColor: 'red' }),
  placeholder: (styles) => ({ ...styles, color: 'red' }),
  option: (styles, state) => ({ ...styles, backgroundColor: state.isSelected ? styles.backgroundColor : '' }),
  menu: provided => ({ ...provided, zIndex: 9999, marginTop: 0 })
}

export const defaultStyleReactSelect = { menu: provided => ({ ...provided, zIndex: 9999, marginTop: 0 }) }

export const
  urlContexto = (contexto) => `/empreendimentos/${contexto.empreendimento.cod}/obras/${contexto.obra.cod}/orcamentos/${contexto.orcamento.cod}`

export const objetosIguais = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2)

export const existeObjetoNaLista = (lista, objeto) => (
  lista.some((item) => objetosIguais(item, objeto))
)

export const replaceSpecialChars = (text) => (
  text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
)

export const getTipoCombinacaoTipologia = (tipo) => replaceSpecialChars(tipo).toUpperCase()

export function arrayToCSV (text) {
  let p = ''; let row = ['']; const ret = [row]; let i = 0; let r = 0; let s = !0; let l
  for (l of text) {
    if (l === '"') {
      if (s && l === p) row[i] += l
      s = !s
    } else if (l === ';' && s) l = row[++i] = ''
    else if (l === '\n' && s) {
      if (p === '\r') row[i] = row[i].slice(0, -1)
      row = ret[++r] = [l = '']; i = 0
    } else row[i] += l
    p = l
  }
  return Object.values(ret[0])
}

export function seProducao () {
  return (window.location.href.includes('producao') || window.location.href.includes('apps'))
}
