import React, { Component } from 'react'
import { Divider, IconButton, Table, TableCell, TableHead, TableRow } from '@material-ui/core'
import ResumoCustoTotal from '../../ResumoCustoTotal'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../../../styles'
import { formatarNumeroParaMoedaBR, mapStateToPropsContexto, showSuccessMsg } from '../../../../../../../../js/util'
import TableBody from '@material-ui/core/TableBody'
import ModalConfirmacao from '../../../../../../../comum/ModalConfirmacao'
import Typography from '@material-ui/core/Typography'
import { context_httpdelete } from '../../../../../../../../js/httpRequest'
import { connect } from 'react-redux'
import EditarComposicaoAuxiliar from './EditarComposicaoAuxiliar'
import AdicionarComposicaoAuxiliar from './AdicionarComposicaoAuxiliar'
import Button from '@material-ui/core/Button'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import StyledDrawer from '../../../../../../../comum/StyledDrawer'

import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import Link from '@material-ui/core/Link'

const defaultState = {
  exibirDrawerAdicionar: false,
  atividadeAuxiliarEdicao: null,
  atividadeAuxiliarExclusao: null
}

class AtividadesAuxiliaresComposicao extends Component {
  static propTypes = {
    composicoesAuxiliares: PropTypes.object.isRequired,
    codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    detalharComposicao: PropTypes.func.isRequired,
    subtotal: PropTypes.number.isRequired,
    permitirEdicao: PropTypes.bool,
    classes: PropTypes.object,
    contexto: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = defaultState
    this.handleDadosAlterados = this.handleDadosAlterados.bind(this)
    this.handleConfirmarExclusaoAtividadeAuxiliar = this.handleConfirmarExclusaoAtividadeAuxiliar.bind(this)
  }

  handleDadosAlterados() {
    const { onDadosAlterados } = this.props
    this.setState(defaultState)
    onDadosAlterados()
  }

  async handleConfirmarExclusaoAtividadeAuxiliar () {
    const { codigoComposicao, contexto, onDadosAlterados } = this.props
    const { atividadeAuxiliarExclusao } = this.state
    const { codigoAtividadeAuxiliar } = atividadeAuxiliarExclusao
    const resultado = await context_httpdelete(`precos/desvincular-atividade-auxiliar?composicao=${codigoComposicao}&atividade-auxiliar=${codigoAtividadeAuxiliar}`, contexto)
    if (resultado) {
      this.setState(defaultState)
      showSuccessMsg(`Atividade auxiliar ${codigoAtividadeAuxiliar} desvinculada da composição.`)
      onDadosAlterados()
    }
  }

  render() {
    const { classes, composicoesAuxiliares, codigoComposicao, permitirEdicao, subtotal, detalharComposicao } = this.props
    const { exibirDrawerAdicionar, atividadeAuxiliarEdicao, atividadeAuxiliarExclusao } = this.state
    return (
      <>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '5%' }} className={classes.tableCell}>D</TableCell>
              <TableCell style={{ width: '40%' }} className={classes.tableCell}>
                Atividade Auxiliar
                {
                  permitirEdicao && (
                    <Button color='primary' size='small' onClick={() => this.setState({ exibirDrawerAdicionar: true })}>
                      + Adicionar
                    </Button>
                  )
                }
              </TableCell>
              <TableCell style={{ width: '15%' }} className={classes.tableCell}>Quantidade</TableCell>
              <TableCell style={{ width: '15%' }} className={classes.tableCell}>Unidade</TableCell>
              <TableCell style={{ width: '15%' }} className={classes.tableCell}>Custo Unitário</TableCell>
              <TableCell align={'right'} className={classes.tableCell}>Custo Unitário</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              composicoesAuxiliares.atividadesAuxiliares.map((item) => (
                <TableRow key={item.atividadeAuxiliar.codigo}>
                  <TableCell className={classes.tableCell}>
                    {
                      permitirEdicao && (
                        <MesmaLinha>
                          <IconButton size='small' onClick={() => this.setState({ atividadeAuxiliarEdicao: item })} color='primary'>
                            <EditIcon />
                          </IconButton>
                          <IconButton size='small' onClick={() => this.setState({ atividadeAuxiliarExclusao: item })} color='primary'>
                            <DeleteIcon />
                          </IconButton>
                        </MesmaLinha>
                      )
                    }
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Link onClick={() => detalharComposicao(item.atividadeAuxiliar)}>
                      {item.atividadeAuxiliar.codigo} - {item.atividadeAuxiliar.descricao}
                    </Link>
                  </TableCell>
                  <TableCell className={classes.tableCell}>{formatarNumeroParaMoedaBR(item.quantidade, 7, 7)}</TableCell>
                  <TableCell className={classes.tableCell}> {item.atividadeAuxiliar.unidade} </TableCell>
                  <TableCell className={classes.tableCell}>
                    { formatarNumeroParaMoedaBR(composicoesAuxiliares.catalogoPrecos[item.codigoAtividadeAuxiliar], 2, 5) }
                  </TableCell>
                  <TableCell align={'right'} className={classes.tableCell}>
                    { formatarNumeroParaMoedaBR(composicoesAuxiliares.catalogoPrecos[item.codigoAtividadeAuxiliar] * item.quantidade, 2, 5) }
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
        <ResumoCustoTotal classes={classes} titulo='Custo Total de Atividades Auxiliares' valorCusto={composicoesAuxiliares.custoTotalAtividadesAuxiliares} />
        <Divider className={classes.divider} />
        <ResumoCustoTotal classes={classes} titulo='Subtotal' valorCusto={subtotal} />
        <Divider className={classes.divider} />
        {
          exibirDrawerAdicionar && (
            <StyledDrawer
              PaperProps={{ className: classes.drawerEdicao }}
              open={true}
              anchor={'right'}>
              <AdicionarComposicaoAuxiliar
                codigoComposicao={codigoComposicao}
                onDadosAlterados={this.handleDadosAlterados}
                isPesquisaComposicaoEmTempoFixo={false}
                onCancelar={() => this.setState({ exibirDrawerAdicionar: false })}
              />
            </StyledDrawer>
          )
        }
        {
          atividadeAuxiliarEdicao != null && (
            <StyledDrawer
              PaperProps={{ className: classes.drawerEdicao }}
              open={true}
              anchor={'right'}>
              <EditarComposicaoAuxiliar
                atividadeAuxiliar={atividadeAuxiliarEdicao}
                codigoComposicao={codigoComposicao}
                onCancelar={() => this.setState({ atividadeAuxiliarEdicao: null })}
                onDadosAlterados={this.handleDadosAlterados}
              />
            </StyledDrawer>
          )
        }
        {
          atividadeAuxiliarExclusao != null && (
            <ModalConfirmacao
              open
              titulo='Confirmar Exclusão de Atividade Auxiliar'
              confirmar={this.handleConfirmarExclusaoAtividadeAuxiliar}
              onClose={() => this.setState({ atividadeAuxiliarExclusao: null })}>
              <Typography>
                Deseja realmente desvincular a atividade auxiliar { atividadeAuxiliarExclusao.codigoAtividadeAuxiliar } - { atividadeAuxiliarExclusao.atividadeAuxiliar.descricao } da composição?
              </Typography>
            </ModalConfirmacao>
          )
        }
      </>
    )
  }
}

export default connect(mapStateToPropsContexto)(withStyles(styles)(AtividadesAuxiliaresComposicao))
