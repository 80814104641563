import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import { BUSCAR_OBRAS } from '../types'

export const url = (idEmpreendimento) =>
  `/api/empreendimentos/${idEmpreendimento}/obras`

const buscarObras =
  (idEmpreendimento, hideReload = false) =>
  async (dispatch) => {
    const success = await axios.get(
      url(idEmpreendimento),
      hideReload ? {} : SLOW_REQUEST_CONFIG
    )
    return dispatch({
      type: BUSCAR_OBRAS,
      payload: success,
    })
  }

export default buscarObras
