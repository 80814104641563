import { applyMiddleware, compose, createStore } from 'redux'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import thunk from 'redux-thunk'
import rootReducer from '../reducers'

const configureStoreProd = (initialState) => {
  const middlewares = [
    thunk
  ]

  return createStore(rootReducer, initialState, compose(
    applyMiddleware(...middlewares)
  ))
}

const configureStoreDev = (initialState) => {
  const middlewares = [
    reduxImmutableStateInvariant(),
    thunk
  ]

  // Habilitação do Redux Dev Tools...
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  return createStore(rootReducer, initialState, composeEnhancers(
    applyMiddleware(...middlewares)
  ))
}

const configureStore = process.env.NODE_ENV === 'production' ? configureStoreProd : configureStoreDev

export default configureStore()
