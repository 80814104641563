import React, { Component } from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import _ from 'lodash'
import Button from '@material-ui/core/Button'
import { withRouter } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import styles from '../styles'
import recuperarReferenciasPrecosOrcamentoEmEdicao from '../../../../actions/orcamentos/recuperarReferenciasPrecosOrcamentoEmEdicao'
import salvarReferenciaPrecoOrcamento from '../../../../actions/orcamentos/salvarReferenciaPrecoOrcamento'
import excluirReferenciaPrecoOrcamento from '../../../../actions/orcamentos/excluirReferenciaPrecoOrcamento'
import {formatarMoedaBR, contemSomenteNumerosPositivos, showAlertMsg, showErrorMsg, SISTEMAS_REFERENCIAS, UFS } from '../../../../js/util'
import validarAtributo from '../../../../js/formulario'
import { httpget } from '../../../../js/httpRequest'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Badge from '@material-ui/core/Badge'
import Tooltip from '@material-ui/core/Tooltip'
import Alert from '@mui/material/Alert'
import CancelIcon from '@material-ui/icons/Cancel'
import HelpIcon from '@material-ui/icons/Help'
import moment from 'moment'
import ModalExcluirReferenciaPreco from '../../ModalExcluirReferenciaPreco'

const defaultState = {
  sistema: '',
  sistemaEmpty: false,
  periodos: [],
  periodo: '',
  periodoEmpty: false,
  referenciaExclusao: null,
  referenciaEmpty: false,
  ufs: [],
  uf: '',
  ufEmpty: false,
  encargosSociais: '',
  encargosSociaisEmpty: false,
  fatorInterferenciaTrafego: '0',
  fatorInterferenciaTrafegoEmpty: false,
  encargos: '',
  referenciaTouched: true
}

export class ReferenciaPrecos extends Component {
  static propTypes = {
    classes: PropTypes.object,
    orcamento: PropTypes.object.isRequired,
    referenciasOrcamento: PropTypes.array,
    recuperarReferenciasPrecosOrcamentoEmEdicao: PropTypes.func,
    excluirReferenciaPrecoOrcamento: PropTypes.func,
    salvarReferenciaPrecoOrcamento: PropTypes.func,
    idEmpreendimento: PropTypes.number.isRequired,
    idObra: PropTypes.number.isRequired,
    onChangeSistemaReferencia: PropTypes.func,
    encargosSociaisEdital: PropTypes.number,
  }

  constructor(props) {
    super(props)
    this.state = defaultState
    this.handleChange = this.handleChange.bind(this)
    this.handleUFChange = this.handleUFChange.bind(this)
    this.handleEncargosSociaisChange =
      this.handleEncargosSociaisChange.bind(this)
    this.handleFatorInterferenciaTrafegoChange =
      this.handleFatorInterferenciaTrafegoChange.bind(this)
    this.abrirModalExcluir = this.abrirModalExcluir.bind(this)
    this.fecharModalExcluir = this.fecharModalExcluir.bind(this)
    this.handleCampoSistemaChange=this.handleCampoSistemaChange.bind(this)
    this.excluir = this.excluir.bind(this)
    this.validar = this.validar.bind(this)
    this.salvar = this.salvar.bind(this)
  }

  async componentDidMount() {
    const { idEmpreendimento, idObra, orcamento } = this.props
    this.props.recuperarReferenciasPrecosOrcamentoEmEdicao(
      idEmpreendimento,
      idObra,
      orcamento.cod
    )
  }

  async componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.orcamento, this.props.orcamento)) {
      const { idEmpreendimento, idObra, orcamento } = this.props
      this.props.recuperarReferenciasPrecosOrcamentoEmEdicao(
        idEmpreendimento,
        idObra,
        orcamento.cod
      )
    }
  }

  recuperarPeriodos() {
    if (!_.isEmpty(this.state.sistema)) {
      return httpget(`/api/referencias-preco/periodos/${this.state.sistema}`)
        .then((periodos) => this.setState({ periodos }))
        .catch(() => showErrorMsg('Erro ao recuperar períodos'))
    }
  }

  recuperarEncargo() {
    const { sistema, periodo, uf } = this.state
    if ((sistema != '') && (!this.ehSicro3(sistema)) && (periodo != '') && (uf != '')) {
      httpget(`/api/referencias-preco/encargo-social/${sistema}/${uf}/${periodo.mes}/${periodo.ano}`)
        .then((encargos) => this.setState({ encargos }))
        .catch(() => showErrorMsg('Erro ao recuperar encargo social'))
    }
  }

  handleCampoSistemaChange = (event) => {
    this.setState({ sistema: event.target.value, referenciaTouched: true }, () =>
      this.recuperarPeriodos()
    )
    this.props.onChangeSistemaReferencia(event)
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value, referenciaTouched: true })
  }

  handleUFChange(event) {
    const uf = event.target.value
    const ufEmpty = _.isEmpty(uf)
    this.setState({
      uf,
      ufEmpty,
      referenciaTouched: true
    })
  }

  handleEncargosSociaisChange(event) {
    if (contemSomenteNumerosPositivos(event.target.value)) {
      this.setState({
        encargosSociais: event.target.value,
      })
    }
  }

  handleFatorInterferenciaTrafegoChange(event) {
    if (contemSomenteNumerosPositivos(event.target.value)) {
      this.setState({
        fatorInterferenciaTrafego: event.target.value,
      })
    }
  }

  abrirModalExcluir(referenciaSelecionada) {
    this.setState({ referenciaExclusao: referenciaSelecionada })
  }

  fecharModalExcluir() {
    this.setState({ referenciaExclusao: null })
  }

  excluir() {
    const { idEmpreendimento, idObra, orcamento } = this.props
    this.props.excluirReferenciaPrecoOrcamento(
      this.state.referenciaExclusao.cod,
      idEmpreendimento,
      idObra,
      orcamento.cod
    )
    this.limparCampos()
    this.fecharModalExcluir()
  }

  validarAtributo(nomeAtributo) {
    const state = validarAtributo(this.state, nomeAtributo)
    this.setState(state)
    return !state[`${nomeAtributo}Empty`]
  }

  recuperaReferencia() {
    return this.props.referenciasOrcamento.find(
      (referencia) => referencia.cod === this.state.cod
    )
  }

  validar = () => {
    return (
      this.validarAtributo('sistema') &&
      this.validarAtributo('uf') &&
      this.validarAtributo('periodo') &&
      (this.validarAtributo('encargosSociais') ||
        this.validarAtributo('fatorInterferenciaTrafego'))
    )
  }


  salvar() {

    if (this.validar()) {
      const referencia = this.recuperaReferencia()
      const refState = {
        ...this.state,
        ordemRelevancia: this.props.referenciasOrcamento.length + 1,
      }
      console.log(this.props.encargosSociaisEdital)
      console.log(this.state.sistema)
      if( refState.sistema !== 'SICRO' &&
        (this.props.encargosSociaisEdital === null || this.props.encargosSociaisEdital.trim() === '' ||
          this.props.encargosSociaisEdital === 0 || this.props.encargosSociaisEdital === undefined)){
        showAlertMsg('Preencha o campo encargos sociais do edital')
        return
      }
      const { idEmpreendimento, idObra, orcamento } = this.props
      const response = this.props.salvarReferenciaPrecoOrcamento(
        referencia,
        refState,
        idEmpreendimento,
        idObra,
        orcamento.cod
      )
      if (response) {
        this.limparCampos()
      }
    }
  }

  campoEncargosSociais() {
    const { classes } = this.props
    const { encargosSociais, encargosSociaisEmpty, sistema } = this.state
    if (!this.ehSicro3(sistema)) {
      return (
        <FormControl variant="outlined">
          <Typography variant="subtitle1">
            Encargos Sociais Horários (%)
            <Tooltip title="Encargos sociais horários paradigma para importação em porcentagem" placement="top-start">
              <HelpIcon color='primary' fontSize='small'/>
            </Tooltip>
          </Typography>
          <TextField
            className={classes.campoFormulario}
            variant="outlined"
            value={encargosSociais}
            onChange={this.handleEncargosSociaisChange}
            error={encargosSociaisEmpty}
          />
        </FormControl>
      )
    }
    return ''
  }

  campoEncargoMaximoMinimo() {
    const { sistema, periodo, uf, encargos, referenciaTouched } = this.state
    if ((sistema != '') && (!this.ehSicro3(sistema)) && (periodo != '') && (uf != '') && (referenciaTouched)) {
      this.recuperarEncargo()
      this.setState({ referenciaTouched: false })
    }
    if ((sistema != '') && (!this.ehSicro3(sistema)) && (periodo != '') && (uf != '') && (encargos != '')) {
      return (
        <Alert icon={false} severity="info">
          <Box display={'flex'}>
            <ul>
              <li>Encargo Social Máximo (%): {formatarMoedaBR(encargos.valorOnerado)}</li>
              <li>Encargo Social Mínimo (%): {formatarMoedaBR(encargos.valorDesonerado)}</li>
            </ul>
            <Tooltip title="Percentual máximo e mínimo de Encargos Sociais do Sistema de Referência selecionado" placement="top-start">
              <HelpIcon color='primary' fontSize='small'/>
            </Tooltip>
          </Box>
        </Alert>
      )
    }
    return ''
  }

  campoFatorInterferenciaTrafego() {
    const { classes } = this.props
    const {
      fatorInterferenciaTrafego,
      fatorInterferenciaTrafegoEmpty,
      sistema,
    } = this.state
    return (
      this.ehSicro3(sistema) && (
        <FormControl variant="outlined">
          <Typography variant="subtitle1">
            Fator de Interferência de Tráfego (FIT)
          </Typography>
          <TextField
            variant="outlined"
            className={classes.campoFormulario}
            value={fatorInterferenciaTrafego}
            onChange={this.handleFatorInterferenciaTrafegoChange}
            error={fatorInterferenciaTrafegoEmpty}
          />
        </FormControl>
      )
    )
  }

  tagsReferencias() {
    const { referenciasOrcamento } = this.props
    if (referenciasOrcamento && referenciasOrcamento.length) {
      return (
        <div>
          <Typography variant="h6">Referências selecionadas</Typography>
          <Box diplay="flex">
            {referenciasOrcamento.map((referenciaOrcamento) => (
              <Badge
                overlap="rectangular"
                key={referenciaOrcamento.cod}
                badgeContent={
                  <CancelIcon
                    onClick={() => this.abrirModalExcluir(referenciaOrcamento)}
                  />
                }
                className="m2percent"
              >
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => this.carregaReferencia(referenciaOrcamento)}
                  style={
                    referenciaOrcamento.ordemRelevancia > 1
                      ? { backgroundColor: '#ffc107', color: '#212529' }
                      : { backgroundColor: '' }
                  }
                >
                  {referenciaOrcamento.sigla} - {referenciaOrcamento.uf}
                  <br />
                  {moment(
                    referenciaOrcamento.dataReferencia,
                    'YYYY-MM-DD'
                  ).format('MM/YYYY')}
                  {!this.ehSicro3(referenciaOrcamento.sigla) ? (
                    <div>
                      <br /> Encargos - {referenciaOrcamento.encargosSociais} %
                    </div>
                  ) : (
                    ''
                  )}
                </Button>
              </Badge>
            ))}
          </Box>
        </div>
      )
    }
    return ''
  }

  carregaReferencia(referenciaSelecionada) {
    const sistema = SISTEMAS_REFERENCIAS.find(
      (it) => it.nome === referenciaSelecionada.sigla
    ).nome
    this.setState(
      {
        cod: referenciaSelecionada.cod,
        sistema,
        uf: UFS.find((it) => it.sigla === referenciaSelecionada.uf).sigla,
        encargosSociais: referenciaSelecionada.encargosSociais
          .toString()
          .replace('.', ','),
        fatorInterferenciaTrafego:
          referenciaSelecionada.fatorInterferenciaTrafego
            .toString()
            .replace('.', ','),
      },
      () => this.carregarPeriodos(referenciaSelecionada, sistema)
    )
  }

  carregarPeriodos(referenciaSelecionada, sistema) {
    httpget(`/api/referencias-preco/periodos/${sistema}`).then((periodos) => {
      const periodo = periodos.find((it) => {
        const mes = it.mes.toString().length === 1 ? `0${it.mes}` : it.mes
        return `${it.ano}-${mes}-01` === referenciaSelecionada.dataReferencia
      })
      this.setState({
        periodos,
        periodo,
      })
    })
  }

  ehSicro3 = (ref) => ref === 'SICRO'

  limparCampos = () => {
    this.setState({
      cod: '',
      sistema: '',
      sistemaEmpty: false,
      uf: '',
      ufEmpty: false,
      periodo: '',
      periodoEmpty: false,
      encargosSociais: '0,00',
      encargosSociaisEmpty: false,
      fatorInterferenciaTrafego: '0,00000',
      fatorInterferenciaTrafegoEmpty: false,
    })
  }

  render() {
    const { orcamento, classes } = this.props
    const {
      cod,
      sistema,
      sistemaEmpty,
      uf,
      ufEmpty,
      periodos,
      periodo,
      periodoEmpty,
    } = this.state

    if (orcamento.cod) {
      return (
        <div className={classes.grupoPreco}>
          <div className={classes.grupoReferencia}>
            <FormControl
              error={sistemaEmpty}
              variant="outlined"
              className={classes.campoFormulario}
            >
              <Typography htmlFor="referencia">Referência</Typography>
              <Select
                inputProps={{ id: 'referencia' }}
                name="sistema"
                error={sistemaEmpty}
                value={sistema}
                onChange={(e) => this.handleCampoSistemaChange(e)}
              >
                <MenuItem value="">
                  <em>---</em>
                </MenuItem>
                {SISTEMAS_REFERENCIAS.map((s) => (
                  <MenuItem key={s.nome} value={s.nome}>
                    <Typography>{s.nome}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className={classes.campoFormulario}
              error={ufEmpty}
              variant="outlined"
            >
              <Typography
                variant="subtitle1"
                htmlFor="estado"
                className={classes.label}
              >
                Estado
              </Typography>
              <Select
                inputProps={{ id: 'estado' }}
                name={'uf'}
                error={ufEmpty}
                value={uf}
                onChange={(e) => this.handleUFChange(e)}
              >
                <MenuItem value="">
                  <em>---</em>
                </MenuItem>
                {UFS.map((uf) => (
                  <MenuItem key={uf.sigla} value={uf.sigla}>
                    <Typography>{uf.nome}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl
              className={classes.campoFormulario}
              error={periodoEmpty}
              variant="outlined"
            >
              <Typography
                variant="subtitle1"
                htmlFor="periodo"
              >
                Data de Referência
              </Typography>
              <Select
                inputProps={{ id: 'periodo' }}
                name={'periodo'}
                error={periodoEmpty}
                value={periodo}
                onChange={(e) => this.handleChange(e)}
              >
                <MenuItem value="">
                  <em>---</em>
                </MenuItem>
                {periodos.map((p) => (
                  <MenuItem key={p.referencia} value={p}>
                    <Typography>{p.referencia}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className={classes.doisCamposFormulario}>
            <div className={classes.doisCamposFormulario}>
              {this.campoEncargosSociais()}
              {this.campoFatorInterferenciaTrafego()}
            </div>
          </div>
          <div className={classes.doisCamposFormulario}>
            {this.campoEncargoMaximoMinimo()}
          </div>

          <Button
            variant="contained"
            color="primary"
            onClick={this.salvar}
            className={classes.botaoAdicionarReferencia}
          >
            {cod ? 'Salvar referência' : 'Adicionar referência'}
          </Button>

          {this.tagsReferencias()}

          <ModalExcluirReferenciaPreco
            referenciaPreco={this.state.referenciaExclusao}
            fechar={this.fecharModalExcluir}
            confirmar={this.excluir}
          />
        </div>
      )
    }
    return ''
  }
}

const mapStateToProps = (state) => {
  const { referenciasPrecoOrcamentoEmEdicao } = state.orcamentos
  return {
    referenciasOrcamento: referenciasPrecoOrcamentoEmEdicao,
  }
}

const mapActionsToProps = {
  recuperarReferenciasPrecosOrcamentoEmEdicao,
  salvarReferenciaPrecoOrcamento,
  excluirReferenciaPrecoOrcamento,
}

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(withStyles(styles)(ReferenciaPrecos)))
