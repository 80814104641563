import React, { Fragment } from 'react'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import ModalConfirmacao from '../comum/ModalConfirmacao'
import PropTypes from 'prop-types'
import { formatarNumeroParaMoedaBR } from '../../js/util'

const ModalExcluirReferenciaPreco = ({ referenciaPreco, fechar, confirmar }) => (
  <ModalConfirmacao
    open={referenciaPreco != null}
    onClose={fechar}
    confirmar={confirmar}
    titulo={<Fragment>
              Confirma a exclusão da Referência de Preços?
            </Fragment>}
    >
    <Typography align='justify'>
      <React.Fragment>
        {referenciaPreco != null
          && <label>
            {referenciaPreco.sigla} - {referenciaPreco.uf}
            <br/>
            {moment(referenciaPreco.dataReferencia).format('MM/YYYY')}
            <br/>
            {
              referenciaPreco.sigla === 'SICRO' ?
                (`Fator de Interferência de Tráfego (FIT): ${formatarNumeroParaMoedaBR(referenciaPreco.fatorInterferenciaTrafego, 5, 5)}`) // eslint-disable-line max-len
                :
                (`Encargos Sociais: ${referenciaPreco.encargosSociais}%`)
            }
          </label>
        }
      </React.Fragment>
    </Typography>
  </ModalConfirmacao>
)

ModalExcluirReferenciaPreco.propTypes = {
  referenciaPreco: PropTypes.object,
  fechar: PropTypes.func,
  confirmar: PropTypes.func
}

export default ModalExcluirReferenciaPreco
