import React, { Component } from 'react'
import { TableBody, TableCell, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import styles from './style'
import StyledTableRowDestaque from '../../../../comum/StyledTableRowDestaque'
import { paginar } from '../../../../../js/util'

class CorpoTabelaReferenciaPreco extends Component {

  static propTypes = {
    insumos: PropTypes.array.isRequired,
    onSelect: PropTypes.func.isRequired,
    classes: PropTypes.object,
    registrosPorPagina: PropTypes.number,
    pagina: PropTypes.number
  }

  constructor(props) {
    super(props)
    this.state = {
      linhaSelecionada: -1
    }
  }

  key = (insumo) => (
    `${insumo.codigo} - ${insumo.mes} - ${insumo.ano} - ${insumo.uf}`
  )

  selecionarInsumo = (insumo, index) => {
    const {onSelect} = this.props
    this.setState({
      linhaSelecionada: index
    })
    onSelect(insumo)
  }

  isLinhaSelecionada = (index) => this.state.linhaSelecionada === index

  render() {
    const { insumos, classes, pagina, registrosPorPagina } = this.props

    return (
      <TableBody>
        { paginar(insumos, pagina, registrosPorPagina).map((insumo, index) => (
            <StyledTableRowDestaque
              className={!this.isLinhaSelecionada(index) ? classes.linhaItem : ''}
              key={this.key(insumo)}
              onClick={() => this.selecionarInsumo(insumo, index)}
            >
              <TableCell className={this.isLinhaSelecionada(index) ? classes.linhaItemSelecionada : ''}>
                {insumo.descricao}
              </TableCell>
              <TableCell className={this.isLinhaSelecionada(index) ? classes.linhaItemSelecionada : ''}>
                {insumo.codigo}
              </TableCell>
              <TableCell className={this.isLinhaSelecionada(index) ? classes.linhaItemSelecionada : ''}>
                {insumo.unidade}
              </TableCell>
              <TableCell align='right' className={this.isLinhaSelecionada(index) ? classes.linhaItemSelecionada : ''}>
                {insumo.precoFormatado}
              </TableCell>
              <TableCell align='right' className={this.isLinhaSelecionada(index) ? classes.linhaItemSelecionada : ''}>
                {insumo.sistema}
              </TableCell>
              <TableCell align='right' className={this.isLinhaSelecionada(index) ? classes.linhaItemSelecionada : ''}>
                {insumo.uf}
              </TableCell>
              <TableCell align='right' className={this.isLinhaSelecionada(index) ? classes.linhaItemSelecionada : ''}>
                {insumo.mes}/{insumo.ano}
              </TableCell>
            </StyledTableRowDestaque>
          ))
        }
      </TableBody>
    )
  }
}

export default withStyles(styles)(CorpoTabelaReferenciaPreco)
