import React, { Component } from 'react'
import Template from '../../../layout/Template'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import BuscarComPaginacao from '../../../comum/BuscarComPaginacao'
import MenuCurvaABC from './MenuCurvaABC'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { context_httpget, context_httpput } from '../../../../js/httpRequest'
import { ERROR_CANCEL_PROMISE, SISTEMAS_REFERENCIAS, UFS, formatarNumeroParaMoedaBR, mapStateToPropsContexto, replaceSpecialChars, showErrorMsg, urlContexto } from '../../../../js/util'
import { connect } from 'react-redux'
import Carregando from '../../../comum/Carregando'
import Cabecalho from '../orcamento-bruto/Cabecalho'
import { withStyles } from '@material-ui/core'
import styles from './styles'
import Collapse from '@material-ui/core/Collapse'
import Paper from '@material-ui/core/Paper'
import VisualizarCurvaAbc from './VisualizarCurvaAbc'
import SelecaoLinhasCurvaAbc from './SelecaoLinhasCurvaAbc'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import CabecalhoTabelaCurvaABC from './itens/CabecalhoTabelaCurvaABC'
import CorpoTabelaCurvaABC from './itens/CorpoTabelaCurvaABC'
import TableContainer from '@material-ui/core/TableContainer'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import axios from 'axios'
import SelecionarSistemas from './itens/SelecionarSistemas'
import SelecionarUfs from './itens/SelecionarUfs'
import SelecionarPeriodos from './itens/SelecionarPeriodos'
import SelecionarStatus from './itens/SelecionarStatus'

let source = axios.CancelToken.source()

export class CurvaAbcCriada extends Component {
  static propTypes = {
    resumoOrcamento: PropTypes.object.isRequired,
    onCurvaAbcExcluida: PropTypes.func.isRequired,
    contexto: PropTypes.object,
    classes: PropTypes.object,
    colapsable: PropTypes.bool,
  }

  constructor(props) {
    super(props)
    source = axios.CancelToken.source()

    this.state = {
      termoBusca: '',
      itensComBusca: false,
      itens: null,
      itensCopy: null,
      detalhesCalculoSobrepreco: null,
      metodoCalculoSobrepreco: null,
      cabecalhoExpandido: true,
      percentualVisualizar: null,
      exibirSomenteSobrepreco: false,
      exibirSomenteLinhasSelecionadas: false,
      percentualLinhasSelecionadas: 0,
      habilitarSelecaoLinhas: false,
      itensSelecionados: [],
      totalPrecoParcialItensSelecionados: 0,
      itensCarregados: [],
      expanded: true,
      sistemasExibir: [...SISTEMAS_REFERENCIAS, { nome: 'PESQUISA' }, { nome: 'Indefinido' }],
      ufsOrcamento: [...UFS, { nome: 'Indefinida', sigla: 'NA' }],
      ufsExibir: [...UFS, { nome: 'Indefinida', sigla: 'NA' }],
      periodosOrcamento: null,
      periodosExibir: [{ mes: 0, ano: 0, chave: 'Indefinido' }],
      statusExibir: [{ nome: 'Concluída' }, { nome: 'Não analisado' }]
    }
    this.handleAtualizacaoDados = this.handleAtualizacaoDados.bind(this)
    this.handleItemAtualizado = this.handleItemAtualizado.bind(this)
    this.selecionarItem = this.selecionarItem.bind(this)
    this.selecionarTodosItens = this.selecionarTodosItens.bind(this)
    this.handleColapseIconClick = this.handleColapseIconClick.bind(this)

  }

  componentDidMount() {
    if (!_.isNil(this.props.contexto)) {
      this.recuperarCurvaAbc()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.contexto !== this.props.contexto) {
      this.recuperarCurvaAbc()
    }

    if (prevState.habilitarSelecaoLinhas !== this.state.habilitarSelecaoLinhas) {
      this.setState({
        itensSelecionados: [],
        percentualLinhasSelecionadas: 0,
        totalPrecoParcialItensSelecionados: 0,
      })
    }

    if (prevState.exibirSomenteSobrepreco !== this.state.exibirSomenteSobrepreco || prevState.exibirSomenteLinhasSelecionadas !== this.state.exibirSomenteLinhasSelecionadas) {
      this.filtrarSelecaoChecado()
    }
  }

  // eslint-disable-next-line class-methods-use-this
  componentWillUnmount() {
    if (source) {
      source.cancel(ERROR_CANCEL_PROMISE)
    }
  }

  async recuperarCurvaAbc() {
    const { contexto } = this.props
    const curvaAbc = await context_httpget('curva-abc', contexto)
    const periodosOrcamento = await this.recuperarPeriodosOrcamento(curvaAbc.itens)
    this.setState({
      itens: curvaAbc.itens,
      itensCopy: curvaAbc.itens,
      metodoCalculoSobrepreco: curvaAbc.metodoCalculoSobrepreco,
      percentualVisualizar: curvaAbc.percentualVisualizar,
      termoBusca: '',
      itensComBusca: false,
      cabecalhoExpandido: true,
      itensCarregados: curvaAbc.itens.filter((it) => _.isNil(it.sistemaReferencia)).map((it) => it.id),
      periodosOrcamento: periodosOrcamento,
      periodosExibir: periodosOrcamento
    })
    await this.recuperarPrecos(curvaAbc.itens)
  }

  async recuperarPrecos(itens) {
    const idsItens = itens.filter((it) => !_.isNil(it.sistemaReferencia)).map((it) => it.id)
    const pageSize = 50
    const chunks = new Array(Math.ceil(idsItens.length / pageSize)).fill().map(() => idsItens.splice(0, pageSize))
    await Promise.all(chunks.map((ids) => this.recuperarPrecosPorComposicao(ids, pageSize)))
  }

  async recuperarPrecosPorComposicao(idsItens, pageSize) {
    const { contexto } = this.props
    const mapa = (await axios.post(`/api${urlContexto(contexto)}/curva-abc/precos?pageSize=${pageSize}`, idsItens, { cancelToken: source.token })).data

    const itensCarregados = []
    const itensAtualizados = this.state.itens.map((it) => {
      if (mapa.map(itemMapa => itemMapa.id).includes(it.id)) {
        const item = mapa.find(item => item.id === it.id)
        itensCarregados.push(item)

        return item
      } else {
        return it
      }

    })

    this.setState({
      itens: itensAtualizados,
      itensCarregados: [...this.state.itensCarregados, ...itensCarregados.map(it => it.id)]
    })
  }

  async recuperarPeriodosOrcamento(itens) {
    const periodosOrcamento = []
    const periodosUnicos = new Set()
    let temPeriodoNulo = false

    itens.forEach(item => {
      const mes = item.mes || 0
      const ano = item.ano || 0

      if (mes === 0 || ano === 0) {
        temPeriodoNulo = true
      } else {
        const chave = mes < 10 ? `0${mes}/${ano}` : `${mes}/${ano}`

        if (!periodosUnicos.has(chave)) {
          periodosOrcamento.push({ mes, ano, chave });
          periodosUnicos.add(chave);
        }
      }
    })

    periodosOrcamento.sort((a, b) => {
      if (a.ano !== b.ano) {
        return a.ano - b.ano
      }
      return a.mes - b.mes
    })

    if (temPeriodoNulo) {
      periodosOrcamento.push({ mes: 0, ano: 0, chave: 'Indefinido' })
    }

    return periodosOrcamento
  }

  buscar() {
    const { termoBusca, itensCopy } = this.state
    if (_.isEmpty(termoBusca)) {
      this.limparBusca()
    } else {
      const itensFiltrados = itensCopy.filter((item) => this.criteriosBusca(item))
      this.setState({
        itens: itensFiltrados,
        itensComBusca: true,
      }, () => this.recuperarPrecos(itensFiltrados))
    }
  }

  criteriosBusca(item) {
    const { termoBusca } = this.state

    return (
      replaceSpecialChars(item.codigoServico)
        .toUpperCase()
        .includes(replaceSpecialChars(termoBusca).toUpperCase()) ||
      replaceSpecialChars(item.descricaoOrcamento)
        .toUpperCase()
        .includes(replaceSpecialChars(termoBusca).toUpperCase()) ||
      item.bdi.toString().includes(termoBusca.toUpperCase()) ||
      replaceSpecialChars(item.unidadeOrcamento)
        .toUpperCase()
        .includes(replaceSpecialChars(termoBusca).toUpperCase()) ||
      item.quantidadeFormatado.includes(termoBusca.toUpperCase()) ||
      item.precoUnitarioFormatado.includes(termoBusca.toUpperCase()) ||
      item.precoParcialCalculadoFormatado.includes(termoBusca.toUpperCase()) ||
      item.percentualAcumuladoFormatado.includes(termoBusca.toUpperCase()) ||
      item.percentualFormatado.includes(termoBusca.toUpperCase()) ||
      item.valorReferenciaComBdiFormatado.includes(termoBusca.toUpperCase()) ||
      item.precoParcialReferenciaFormatado.includes(termoBusca.toUpperCase()) ||
      item.valorSobreprecoFormatado.includes(termoBusca.toUpperCase()) ||
      replaceSpecialChars(item.observacao)
        .toUpperCase()
        .includes(replaceSpecialChars(termoBusca).toUpperCase())
    )
  }

  limparBusca = () => {
    const { itensCopy } = this.state
    this.setState({
      itens: itensCopy,
      termoBusca: '',
      itensComBusca: false,
      itensCarregados: itensCopy.filter((it) => _.isNil(it.sistemaReferencia)).map((it) => it.id)
    }, () => this.recuperarPrecos(itensCopy))
  }

  handleChangeBuscar = (event) => {
    const { value } = event.target
    this.setState({ termoBusca: value })
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.buscar()
    }
  }

  handleChangeVisualizar = (event) => {
    const { contexto } = this.props
    const percentualVisualizar = event.target.value

    context_httpput('percentual-visualizar', contexto, percentualVisualizar)
      .then((data) => {
        this.setState({
          percentualVisualizar,
          exibirSomenteSobrepreco: false,
          exibirSomenteLinhasSelecionadas: false,
          habilitarSelecaoLinhas: false,
          itensCarregados: []
        })
        this.handleAtualizacaoDados(data)
      })
      .catch(() => showErrorMsg('Erro ao alterar o percentual de visualizar'))
  }

  handleChangeSelecaoLinhas = (event) => {
    this.setState({
      [event.target.name]: event.target.checked
    })
  }

  handleAtualizacaoDados() {
    this.setState({ termoBusca: '', itensComBusca: false })
    this.recuperarCurvaAbc()
  }

  handleItemAtualizado(itemOriginal, novoItem) {
    const novosItens = this.state.itens.map((it) => {
      if (it === itemOriginal) {
        return novoItem
      }
      return it
    })
    this.setState({ itens: novosItens })
  }

  itensAExibir() {
    const { itens, sistemasExibir, periodosExibir, statusExibir } = this.state

    return itens.filter(item => {
        const sistemaFilter = item.sistemaReferencia === null ? 'Indefinido' : item.sistemaReferencia
        //const ufFilter = item.uf === null ? 'NA' : item.uf
        const periodoFilter = (item.mes === null || item.ano === null) ? 'Indefinido' : (item.mes < 10 ? `0${item.mes}/${item.ano}` : `${item.mes}/${item.ano}`)
        const statusFilter = (item.analiseConcluida) ? 'Concluída' : 'Não analisado'

        return sistemasExibir.some(sistema => sistema.nome === sistemaFilter) &&
               //ufsExibir.some(uf => uf.sigla === ufFilter) &&
               periodosExibir.some(periodo => periodo.chave === periodoFilter) &&
               statusExibir.some(status => status.nome === statusFilter)
    })
  }


  filtrarSelecaoChecado = () => {
    const {
      exibirSomenteSobrepreco,
      exibirSomenteLinhasSelecionadas,
      itens,
      itensSelecionados,
    } = this.state

    let itensFiltrados = []

    if (exibirSomenteSobrepreco && !exibirSomenteLinhasSelecionadas) {
      itensFiltrados = this.filtrarItensComSobrepreco(itens)
    } else if (!exibirSomenteSobrepreco && exibirSomenteLinhasSelecionadas) {
      itensFiltrados = this.filtrarItensSelecionados(itensSelecionados)
    } else if (exibirSomenteSobrepreco && exibirSomenteLinhasSelecionadas) {
      itensFiltrados = this.filtrarItensSelecionados(itensSelecionados)
    }

    if (!exibirSomenteSobrepreco && !exibirSomenteLinhasSelecionadas) {
      this.setState({ itensSelecionados: [] }, () => this.recuperarCurvaAbc())
    }
    this.setState({ itens: itensFiltrados })
  }

  temSobrepreco(item) {
    const { metodoCalculoSobrepreco } = this.state
    return (item.temSobreprecoPositivo || (Boolean(metodoCalculoSobrepreco) && metodoCalculoSobrepreco.consideraSobreprecoNegativo && item.temSobreprecoNegativo))
  }

  filtrarItensComSobrepreco = (itens) => itens.filter((item) => this.temSobrepreco(item))

  filtrarItensSelecionados = (itensSelecionados) => {
    const { exibirSomenteSobrepreco } = this.state
    if (exibirSomenteSobrepreco) {
      return this.filtrarItensComSobrepreco(itensSelecionados)
    } else {
      return itensSelecionados
    }
  }

  selecionarItem = (event, item) => {
    const {
      itensSelecionados,
      percentualLinhasSelecionadas,
      totalPrecoParcialItensSelecionados,
    } = this.state

    if (itensSelecionados.indexOf(item) === -1) {
      const percentualSomado = percentualLinhasSelecionadas + item.percentual
      const precoParcialSomado = totalPrecoParcialItensSelecionados + item.precoParcialCalculado
      this.setState({
        itensSelecionados: itensSelecionados.concat(item),
        percentualLinhasSelecionadas: percentualSomado,
        totalPrecoParcialItensSelecionados: precoParcialSomado,
      })
    } else {
      const outrosItensSelecionados = itensSelecionados.filter((i) => i !== item)
      const percentualSubtraido = percentualLinhasSelecionadas - item.percentual
      const precoParcialSubtraido = totalPrecoParcialItensSelecionados - item.precoParcialCalculado
      this.setState({
        itensSelecionados: outrosItensSelecionados,
        percentualLinhasSelecionadas: percentualSubtraido,
        totalPrecoParcialItensSelecionados: precoParcialSubtraido,
      })
    }
  }

  selecionarTodosItens = (event) => {
    const { itens } = this.state
    if (event.target.checked) {
      const percentualSomado = itens.reduce((acumulador, item) => acumulador + item.percentual, 0)
      const precoParcialSomado = itens.reduce((acumulador, item) => acumulador + item.precoParcialCalculado, 0)
      this.setState({
        itensSelecionados: itens,
        percentualLinhasSelecionadas: percentualSomado,
        totalPrecoParcialItensSelecionados: precoParcialSomado,
      })
    } else {
      this.setState({
        itensSelecionados: [],
        percentualLinhasSelecionadas: 0,
        totalPrecoParcialItensSelecionados: 0,
      })
    }
  }

  todosItensSelecionados = () => {
    const { itensSelecionados } = this.state
    return this.itensAExibir().length === itensSelecionados.length
  }

  handleChangeMetodoCalculoSobrepresso = (name, valor) => this.setState({ [name]: valor })

  handleColapseIconClick() {
    this.setState({ expanded: !this.state.expanded })
    this.setState({
      cabecalhoExpandido: !this.state.cabecalhoExpandido,
    })
  }

  handleFiltrarSistema = (sistema) => {
    const { sistemasExibir } = this.state
    const sistemaIndex = sistemasExibir.findIndex(item => item.nome === sistema.nome);

    if (sistemaIndex !== -1) {
      const novosSistemasExibir = sistemasExibir.filter((item, index) => index !== sistemaIndex);
      this.setState({ sistemasExibir: novosSistemasExibir })
    } else {
      const novosSistemasExibir = [...sistemasExibir, sistema]
      this.setState({ sistemasExibir: novosSistemasExibir })
    }
  }

  handleFiltrarUfs = (UF) => {
    const { ufsExibir } = this.state
    const ufIndex = ufsExibir.findIndex(item => item.sigla === UF.sigla)

    if (ufIndex !== -1) {
      const novasUfsExibir = ufsExibir.filter((item, index) => index !== ufIndex);
      this.setState({ ufsExibir: novasUfsExibir })
    } else {
      const novasUfsExibir = [...ufsExibir, UF]
      this.setState({ ufsExibir: novasUfsExibir })
    }
  }

  handleUfsOrcamento = () => {
    const { ufsOrcamento, itens } = this.state
    const ufsOrcamentoAtualizadas = ufsOrcamento.filter(uf => itens.some(item => {
        if (item.uf === null) {
          return ('NA' === uf.sigla)
        } else {
          return (item.uf === uf.sigla)
        }
      })
    )
    return ufsOrcamentoAtualizadas
  }

  handleFiltrarPeriodos = (periodo) => {
    const { periodosExibir } = this.state
    const periodosIndex = periodosExibir.findIndex(item => item.chave === periodo.chave)

    if (periodosIndex !== -1) {
      const novosPeriodosExibir = periodosExibir.filter((item, index) => index !== periodosIndex);
      this.setState({ periodosExibir: novosPeriodosExibir })
    } else {
      const novosPeriodosExibir = [...periodosExibir, periodo]
      this.setState({ periodosExibir: novosPeriodosExibir })
    }
  }

  handleFiltrarStatus = (status) => {
    const { statusExibir } = this.state
    const statusIndex = statusExibir.findIndex(item => item.nome === status.nome);

    if (statusIndex !== -1) {
      const novosStatusExibir = statusExibir.filter((item, index) => index !== statusIndex);
      this.setState({ statusExibir: novosStatusExibir })
    } else {
      const novosStatusExibir = [...statusExibir, status]
      this.setState({ statusExibir: novosStatusExibir })
    }
  }

  render() {
    const {
      itens,
      metodoCalculoSobrepreco,
      termoBusca,
      itensComBusca,
      cabecalhoExpandido,
      percentualVisualizar,
      exibirSomenteSobrepreco,
      exibirSomenteLinhasSelecionadas,
      percentualLinhasSelecionadas,
      habilitarSelecaoLinhas,
      totalPrecoParcialItensSelecionados,
      itensSelecionados,
      itensCarregados
    } = this.state
    const { resumoOrcamento, onCurvaAbcExcluida, classes={} } = this.props
    const { expanded } = this.state


    if (_.isNil(itens)) {
      return <Carregando />
    }

    const CollapseFilter = <div>
      {expanded && (
        <IconButton
          onClick={this.handleColapseIconClick}
          variant='outlined'
          size='small'
          title='Esconder filtros'
          aria-expanded={expanded}
        >
          <ArrowDropUpIcon />
        </IconButton>
      )}
      {!expanded && (
        <IconButton
          onClick={this.handleColapseIconClick}
          variant='outlined'
          size='small'
          title='Mostrar filtros'
          aria-expanded={expanded}
        >
          <ArrowDropDownIcon />
        </IconButton>)}
    </div>


    return (
      <Template insidePaper={false}>
        <section className={classes.secaoCurvaABC}>
          <div className="cabecalhoGeral">
            <Paper elevation={0} variant="outlined" className={classes.paper}>
              <Cabecalho
                onColapse={() =>
                  this.setState({
                    cabecalhoExpandido: !this.state.cabecalhoExpandido,
                  })
                }
                colapsable={true}
                componente={CollapseFilter}
                resumoOrcamento={resumoOrcamento}
                numeroPossiveisErros={resumoOrcamento.numeroPossiveisErros}
                numeroPossiveisInconsistencias={
                  resumoOrcamento.numeroPossiveisInconsistencias
                }
                titulo="Curva ABC"
              />
              <Collapse in={cabecalhoExpandido} timeout="auto" unmountOnExit>
                <Box display={'flex'} alignItems={'center'} gridGap={16} flexWrap={'wrap'}>
                  <BuscarComPaginacao
                    placeholder="Filtrar por parte da descrição ou valores"
                    value={termoBusca}
                    onChange={(e) => this.handleChangeBuscar(e)}
                    onKeyPress={(e) => this.handleKeyPress(e)}
                    onClick={() => this.buscar()}
                    clear={() => this.limparBusca()}
                    disable={itensComBusca}
                  />
                  <VisualizarCurvaAbc
                    valor={percentualVisualizar}
                    onChange={(e) => this.handleChangeVisualizar(e)}
                  />
                  <SelecionarSistemas
                    sistemasExibir={this.state.sistemasExibir}
                    filtrarSistema={this.handleFiltrarSistema}
                  />
                  <SelecionarUfs
                    ufsExibir={this.state.ufsExibir}
                    ufsOrcamento={this.handleUfsOrcamento()}
                    filtrarUfs={this.handleFiltrarUfs}
                  />
                  <SelecionarPeriodos
                    periodosExibir={this.state.periodosExibir}
                    periodosOrcamento={this.state.periodosOrcamento}
                    filtrarPeriodos={this.handleFiltrarPeriodos}
                  />
                  <SelecionarStatus
                    statusExibir={this.state.statusExibir}
                    filtrarStatus={this.handleFiltrarStatus}
                  />
                  <MenuCurvaABC
                    itensCurvaAbc={itens}
                    onItensAlterados={this.handleAtualizacaoDados}
                    onCurvaAbcExcluida={onCurvaAbcExcluida}
                    metodoCalculoSobrepreco={metodoCalculoSobrepreco}
                    onChangeMetodoCalculoSobrepresso={this.handleChangeMetodoCalculoSobrepresso}
                  />
                  <SelecaoLinhasCurvaAbc
                    exibirSomenteSobrepreco={exibirSomenteSobrepreco}
                    exibirSomenteLinhasSelecionadas={exibirSomenteLinhasSelecionadas}
                    percentualLinhasSelecionadas={percentualLinhasSelecionadas}
                    habilitarSelecaoLinhas={habilitarSelecaoLinhas}
                    onChange={(e) => this.handleChangeSelecaoLinhas(e)}
                  />
                </Box>
              </Collapse>
            </Paper>
          </div>
          {habilitarSelecaoLinhas && (
            <Typography
              variant="subtitle2"
              gutterBottom
              className={classes.totalItensSelecionados}
            >
              Total itens selecionados: R${' '}
              {formatarNumeroParaMoedaBR(
                totalPrecoParcialItensSelecionados,
                2,
                3
              )}
            </Typography>
          )}
          <Paper elevation={0} variant="outlined" className={classes.paperABC}>
            <Box
              pt={1}
              className={
                cabecalhoExpandido
                  ? classes.boxLimite
                  : classes.boxLimiteCabecalhoRecolhido
              }
            >
              <TableContainer className={classes.tabelaCaixa}>
                <Table
                  stickyHeader
                  aria-label="sticky table"
                  size="small"
                  className={classes.tabelaABC}
                >
                  <CabecalhoTabelaCurvaABC
                    mostrarSelecaoLinhas={habilitarSelecaoLinhas}
                    selecionarTodosItens={this.selecionarTodosItens}
                    todosItensSelecionados={this.todosItensSelecionados()}
                  />
                  <CorpoTabelaCurvaABC
                    itens={this.itensAExibir()}
                    itensCarregados={itensCarregados}
                    metodoCalculo={metodoCalculoSobrepreco}
                    onItemAlterado={this.handleItemAtualizado}
                    mostrarSelecaoLinhas={habilitarSelecaoLinhas}
                    selecionaItem={this.selecionarItem}
                    itensSelecionados={itensSelecionados}
                  />
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </section>
      </Template>
    )
  }
}

export default connect(mapStateToPropsContexto)(
  withStyles(styles)(CurvaAbcCriada)
)
