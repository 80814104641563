import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import { converterMoedaBRParaNumero, formatarNumeroParaMoedaBR, mapStateToPropsContexto, seStringVazia, showSuccessMsg } from '../../../../../js/util';
import { context_httpput } from '../../../../../js/httpRequest';
import { connect } from 'react-redux'
import EditarMaterialComposicao from '../../relatorios/precos/composicao/insumos/materiais/EditarMaterialComposicao';

const RelatorioMaterial = ({ material, contexto }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedMaterial, setEditedMaterial] = useState({ ...material });
  const [initialValues, setInitialValues] = useState({ ...material });

  const handleEdit = () => {
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setEditedMaterial({ ...material });
  };

  const handleSave = async () => {
   
    const { quantidade, descricao, unidade, precoUnitario } = editedMaterial;

    console.log(typeof(precoUnitario))

    const precoUnitarioChanged = precoUnitario !== initialValues.precoUnitario;
    
    const convertedprecoUnitario = precoUnitarioChanged
    ? converterMoedaBRParaNumero(precoUnitario.toString())
    : precoUnitario;

    console.log(typeof(convertedprecoUnitario))

      const dadosSalvar = {
        quantidade: converterMoedaBRParaNumero(quantidade),
        descricao,
        unidade,
        precoUnitario: convertedprecoUnitario,
        //precoUnitario,
      };
      
      const url = `precos/materiais/${editedMaterial.codigo}`;
      const resultado = await context_httpput(url, contexto, dadosSalvar);
      if (resultado) {
        showSuccessMsg('Dados do material na composição alterados com sucesso.');
        setEditMode(false);
        window.location.reload()
      }
    
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name !== 'codigo') {
      setEditedMaterial((prevMaterial) => ({
        ...prevMaterial,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    setInitialValues({ ...material });
  }, [material]);

  return (
    <div>
      
      {editMode ? (
        <div>
          <TextField
            name="codigo"
            label="Código"
            value={editedMaterial.codigo}
            onChange={handleChange}
            InputProps={{ readOnly: true }} 
          />
          <TextField
            name="descricao"
            label="Descrição"
            value={editedMaterial.descricao}
            onChange={handleChange}
            style={{ width: '100%' }}
          />
          <TextField
            name="unidade"
            label="Unidade"
            value={editedMaterial.unidade}
            onChange={handleChange}
          />
          <TextField
            name="precoUnitario"
            label="Preço Unitário"
            value={editedMaterial.precoUnitario.toLocaleString('pt-BR')}
            onChange={handleChange}
          />
          <IconButton color="primary" onClick={handleSave}>
            <DoneIcon />
          </IconButton>
          <IconButton color="primary" onClick={handleCancelEdit}>
            <ClearIcon />
          </IconButton>
        </div>
      ) : (
          
      <div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">Material</Typography>
            <IconButton color="primary" onClick={handleEdit} style={{ marginLeft: '8px' }}>
              <EditIcon />
            </IconButton>
      </div>
          
          <Typography>Código: {material.codigo}</Typography>
          <Typography>Descrição: {material.descricao}</Typography>
          <Typography>Unidade: {material.unidade}</Typography>
          <Typography>Preço Unitário: {material.precoUnitario.toLocaleString('pt-BR')}</Typography>
          
        </div>
      )}
    </div>
  );
};

RelatorioMaterial.propTypes = {
  material: PropTypes.object.isRequired,
  onMaterialChange: PropTypes.func.isRequired,
  contexto: PropTypes.object.isRequired,
};

export default connect(mapStateToPropsContexto)(RelatorioMaterial)
