import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  breadcrumbTop: {
    marginTop: '-8px'
  },
  formControl: {
    margin: theme.spacing(1),
  },
  titlePage: {
    backgroundColor: '#e6f0f9',
    border: [[1, 'solid', '#072a4a']],
    borderRadius: 2,
    color: '#072a4a',
    fontWeight: 500,
    padding: 4,
    textTransform: 'uppercase'
  },
  stepContainer: {
    display: 'flex'
  },
  stepActive: {
    borderTop: [[4, 'solid', theme.palette.primary.main]],
    paddingTop: 12,
    marginRight: 16,
    width: 220
  },
  stepInactive: {
    borderTop: [[4, 'solid']],
    paddingTop: 12,
    marginRight: 16,
    color: 'gray',
    opacity: 0.5,
    width: 220
  },
  stepTitle: {
    fontSize: 20,
    textTransform: 'uppercase'
  },
  containerPesquisa: {
    backgroundColor: theme.palette.background.default,
    '& > input': {
      backgroundColor: '#fff',
    }
  },
  boxInput: {
    backgroundColor: '#fff',
  },
  containerNextStep: {
    backgroundColor: '#fff',
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 8,
  },
  containerFilter: {
    height: '78vh',
  },
  containerThemes: {
    backgroundColor: theme.palette.background.default,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
  },
  btnAddTheme: {
    marginRight: 16
  },
  boxRelative: {
    position: 'relative',
    height: '100%'
  }
}))

export default useStyles
