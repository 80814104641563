import React, { Component } from 'react'
import { mapStateToPropsContexto, MATERIAL, paginar, recuperarMes, seStringVazia, seObjectVazio, showAlertMsg, showErrorMsg, showSuccessMsg, SISTEMAS_REFERENCIAS, UFS } from '../../../../../../../../js/util'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core'
import styles from '../../../styles'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import { context_httpget, context_httppost, httpget } from '../../../../../../../../js/httpRequest'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import StyledFormControl from '../../../../../../../comum/StyledFormControl'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import _ from 'lodash'
import StyledTableContainerAdicionar from '../../../../../../../comum/StyledTableContainerAdicionar'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import Paginacao from '../../../../../../../comum/Paginacao'

const defaultState = {
  sistema: '',
  sistemaEmpty: false,
  uf: '',
  ufEmpty: false,
  periodo: '',
  periodoEmpty: false,
  termoBusca: '',
  materiais: [],
  materialSelecionado: null,
  totalItens: 0,
  pagina: 0,
  registrosPorPagina: 10,
  periodos: [],
  temPesquisa: false
}

class AdicionarMaterialComposicaoPesquisarEmReferenciasDePrecos extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string,
    onDadosAlterados: PropTypes.func,
    onCancelar: PropTypes.func.isRequired,
    isPesquisaMateriaisEmTempoFixo: PropTypes.bool,
    onChangeMaterialSelecionado: PropTypes.func,
    contexto: PropTypes.object,
    classes: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.state = defaultState
  }

  componentDidMount () {
    this.recuperarReferenciaPrecoPrincipalOrcamento()
  }

  recuperarReferenciaPrecoPrincipalOrcamento = async () => {
    const { contexto } = this.props
    const referenciaPreco = await context_httpget('referencias-preco/principal', contexto)
    if(referenciaPreco) {
      this.setState({
        sistema: referenciaPreco.sigla,
        uf: referenciaPreco.uf
      }, async () => {
        await this.recuperarPeriodos()
        this.setState({ periodo: this.state.periodos.find(it => it.referencia === `${recuperarMes(referenciaPreco.mes).nome}/${referenciaPreco.ano}`) })
      })
    }
  }

  recuperarPeriodos = async () => {
    const periodos = await httpget(`/api/referencias-preco/periodos/${this.state.sistema}`)
    this.setState({ periodos })
  }

  handleChange = (event) => {
    const { name, value } = event.target
    const state = {}
    state[name] = value
    state[`${name}Empty`] = name === 'periodo' ? seObjectVazio(value) : seStringVazia(value)
    this.setState(state)
  }

  handleChangeCampoSistema = (event) => {
    this.setState({'sistema': event.target.value, 'periodo': undefined, 'periodoEmpty': true, 'temPesquisa': false}, () => this.recuperarPeriodos())
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.pesquisar()
    }
  }

  handleRowClick(material) {
    const { materialSelecionado } = this.state
    const { onChangeMaterialSelecionado, isPesquisaMateriaisEmTempoFixo } = this.props
    if (materialSelecionado !== material.codigo) {
      this.setState({materialSelecionado: material.codigo})
      isPesquisaMateriaisEmTempoFixo && onChangeMaterialSelecionado(material)
    }
  }

  handlePageChange = (e, pag) => {
    const { materiais, registrosPorPagina } = this.state

    if ((registrosPorPagina * pag) === materiais.length) {
      this.recuperarMateriais()
    }

    this.setState({pagina: pag})
  }

  handleRowsPerPageChange = (e) => this.setState({pagina: 0, registrosPorPagina: e.target.value})

  pesquisar = () => {
    const { sistema, uf, periodo } = this.state

    const sistemaEmpty = seStringVazia(sistema)
    const ufEmpty = seStringVazia(uf)
    const periodoEmpty = seObjectVazio(periodo)

    const empties = [sistemaEmpty, ufEmpty, periodoEmpty]

    if (empties.some(Boolean)) {
      this.setState({
        sistemaEmpty, ufEmpty, periodoEmpty
      })
    } else {
      this.setState({
        materiais: [],
        pagina: 0
      }, () => this.recuperarMateriais())
    }
  }

  recuperarMateriais = () => {
    const { sistema, uf, periodo, termoBusca, materiais } = this.state

    const from = _.isEmpty(materiais) ? 0 : materiais.length

    httpget(`/api/insumos/${sistema}/${uf}/${periodo.mes}/${periodo.ano}/${MATERIAL.nome}?busca=${encodeURIComponent(termoBusca)}&from=${from}&size=1000`)
      .then((data) => {
        const insumosCatalogos = _.isEmpty(data.insumosCatalogos) ? data.insumosCatalogos : materiais.concat(data.insumosCatalogos)
        this.setState({
          materiais: insumosCatalogos,
          totalItens: data.totalItens,
          temPesquisa: true
        })
      })
      .catch(() => showErrorMsg('Erro ao pesquisar referência de preços'))
  }

  confirmarVinculacao = () => {
    const { materialSelecionado, sistema, uf, periodo } = this.state

    if (_.isNil(materialSelecionado)) {
      showAlertMsg('Por favor, selecione um material para vincular à composição.')
      return
    }

    const { codigoComposicao, contexto, onDadosAlterados } = this.props

    context_httppost(`precos/${sistema}/${uf}/${periodo.mes}/${periodo.ano}/vincular-referencia-preco-material?composicao=${codigoComposicao}&material=${materialSelecionado}`, contexto)
      .then((composicaoComDetalhes) => {
        showSuccessMsg(`Material ${materialSelecionado} vinculado à composição.`)
        onDadosAlterados(composicaoComDetalhes)
      })
  }

  render() {
    const { classes, onCancelar, isPesquisaMateriaisEmTempoFixo } = this.props
    const {
      sistema,
      sistemaEmpty,
      uf,
      ufEmpty,
      periodos,
      periodo,
      periodoEmpty,
      termoBusca,
      temPesquisa,
      materiais,
      materialSelecionado,
      pagina,
      registrosPorPagina,
      totalItens
    } = this.state

    return (
      <div>
        <Typography variant='overline' gutterBottom>1.Pesquisar em referências de preço</Typography>

        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Typography className={classes.lblDadosBasicos}>Sistema:</Typography>
            <StyledFormControl variant='outlined' className={classes.formControl}>
              <Select
                name={'sistema'}
                error={sistemaEmpty}
                value={sistema}
                onChange={(e) => this.handleChangeCampoSistema(e)}>
                {
                  SISTEMAS_REFERENCIAS.map((s) => (
                    <MenuItem key={s.nome} value={s.nome}>
                      <Typography>{s.nome}</Typography>
                    </MenuItem>
                  ))
                }
              </Select>
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography className={classes.lblDadosBasicos}>UF:</Typography>
            <StyledFormControl variant='outlined' className={classes.formControl}>
              <Select
                name={'uf'}
                error={ufEmpty}
                value={uf}
                onChange={(e) => this.handleChange(e)}>
                {
                  UFS.map((u) => (
                    <MenuItem key={u.sigla} value={u.sigla}>
                      <Typography>{u.nome}</Typography>
                    </MenuItem>
                  ))
                }
              </Select>
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography className={classes.lblDadosBasicos}>Período:</Typography>
            <StyledFormControl variant='outlined' className={classes.formControl}>
              <Select
                name={'periodo'}
                error={periodoEmpty}
                value={periodo}
                onChange={(e) => this.handleChange(e)}>
                {
                  periodos.map((p) => (
                    <MenuItem key={p.referencia} value={p}>
                      <Typography>{p.referencia}</Typography>
                    </MenuItem>
                  ))
                }
              </Select>
            </StyledFormControl>
          </Grid>

          <Grid item xs={12} md={9}>
            <Typography className={classes.lblDadosBasicos}>Código ou Descrição (opcional):</Typography>
            <StyledFormControl variant='outlined' className={classes.formControl}>
              <TextField
                name={'termoBusca'}
                multiline
                rows={3}
                value={termoBusca}
                onChange={(e) => this.handleChange(e)}
                onKeyPress={(e) => this.handleKeyPress(e)}
                variant='outlined'
              />
            </StyledFormControl>
          </Grid>
        </Grid>

        <MesmaLinha>
          <Button variant={'outlined'} color={'primary'} onClick={() => this.pesquisar()}>Pesquisar</Button>
        </MesmaLinha>

        {
          temPesquisa && (
            <StyledTableContainerAdicionar>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Unidade</TableCell>
                    <TableCell>Preço Unitário (R$)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    paginar(materiais, pagina, registrosPorPagina).map((it) => {
                      const estiloCelula = materialSelecionado === it.codigo ? {color: 'white'} : {}
                      return (
                        <TableRow
                          key={it.codigo}
                          className={materialSelecionado === it.codigo ? classes.linhaItemSelecionada : classes.linhaItem}
                          onClick={() => this.handleRowClick(it)}>
                          <TableCell style={estiloCelula}>{it.codigo}</TableCell>
                          <TableCell style={estiloCelula}>{it.descricao}</TableCell>
                          <TableCell style={estiloCelula}>{it.unidade}</TableCell>
                          <TableCell style={estiloCelula}>{it.precoFormatado}</TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
              <Paginacao
                total={totalItens}
                registrosPorPagina={registrosPorPagina}
                pagina={pagina}
                onChangePage={(e, pag) => this.handlePageChange(e, pag)}
                onChangeRowsPerPage={(e) => this.handleRowsPerPageChange(e)}
              />
            </StyledTableContainerAdicionar>
          )
        }

        <Box my={3}>
          <Divider/>
        </Box>

        <Box mt={3}>
          {isPesquisaMateriaisEmTempoFixo
            ? (<MesmaLinha>
                <Button color='primary' variant='contained' onClick={onCancelar}>Selecionar</Button>
                <Button color='primary' onClick={onCancelar}>Cancelar</Button>
              </MesmaLinha>)
            : (<MesmaLinha>
                 <Button color='primary' variant='contained' onClick={() => this.confirmarVinculacao()}>Confirmar</Button>
                 <Button color='primary' onClick={onCancelar}>Cancelar</Button>
               </MesmaLinha>)
          }
        </Box>
      </div>
    )
  }
}

export default connect(mapStateToPropsContexto)(withStyles(styles)(AdicionarMaterialComposicaoPesquisarEmReferenciasDePrecos))
