import React from 'react'
import styles from '../../styles'
import { withStyles } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import FormControl from '@material-ui/core/FormControl'
import Typography from '@material-ui/core/Typography'

const CampoEncargosSociais = ({
  classes,
  encargosSociais,
  encargosSociaisEmpty,
  onEncargosSociaisChange
}) => {
  return (
    <FormControl variant='outlined'>
      <Typography variant='subtitle1'>Encargos sociais do edital (%)</Typography>
      <TextField
        className={classes.campoFormulario}
        variant='outlined'
        value={encargosSociais && encargosSociais !== undefined ? encargosSociais : ''}
        onChange={onEncargosSociaisChange}
        error={encargosSociaisEmpty}
      />
    </FormControl>
  )
}

CampoEncargosSociais.propTypes = {
  classes: PropTypes.object,
  encargosSociais: PropTypes.string,
  encargosSociaisEmpty: PropTypes.bool,
  onEncargosSociaisChange: PropTypes.func.isRequired
}

export default withStyles(styles)(CampoEncargosSociais)
