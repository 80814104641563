import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import { getAuthentication } from '../../../security/securityContext'
import App from '../../App'
import PropTypes from 'prop-types'
import { Paper, Typography } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import styles from '../styles'
import Tab from '@material-ui/core/Tab'
import { TabContext, TabList, TabPanel } from '@material-ui/lab'
import { FormControl, Select, InputLabel, MenuItem, Chip } from '@mui/material'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TitleTableCell from '../../comum/TitleTableCell'
import TableBody from '@material-ui/core/TableBody'
import Paginacao from '../../comum/Paginacao'
import TableCell from '@material-ui/core/TableCell'
import StyledTableRow from '../../comum/StyledTableRow'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../../js/operationProgressUtil'

const loadPeriodos = () => {
  var currentYear = new Date().getFullYear()
  var currentMonth = new Date().getMonth() + 1
  var periodosValidos = []
  for (var ano = 2020; ano <= currentYear; ano++) {
    for (var mes = 1; mes <= 12; mes++) {
      var periodo = {}
      periodo.mesAno = mes + '/' + ano
      periodo.anoMes = ano + '-' + mes
      periodosValidos.push(periodo)
      if (ano === currentYear && mes === currentMonth) {
        break
      }
    }
  }
  return periodosValidos.toReversed()
}

const TabPanelEstatisticas = ( { estatisticas, pagina, setPagina, registrosPorPagina, setRegistrosPorPagina, classes }) => {
  return <Paper elevation={0}>
    <Table>
      <TableHead>
        <TableRow>
          <TitleTableCell>Mês/ano</TitleTableCell>
          <TitleTableCell>Unidade</TitleTableCell>
          <TitleTableCell>Logins</TitleTableCell>
          <TitleTableCell>Acessos <br/>a Orçamentos</TitleTableCell>
          <TitleTableCell>Empreendimentos<br/> Criados</TitleTableCell>
          <TitleTableCell>Empreendimentos<br/> Excluidos</TitleTableCell>
          <TitleTableCell>Obras<br/> Criadas</TitleTableCell>
          <TitleTableCell>Obras<br/> Excluidas</TitleTableCell>
          <TitleTableCell>Orçamentos<br/> Criados</TitleTableCell>
          <TitleTableCell>Orçamentos<br/> Excluidos</TitleTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {estatisticas.slice(pagina * registrosPorPagina, pagina * registrosPorPagina + registrosPorPagina)
          .filter(p => p.periodo)
          .map((p, i) => (
            <StyledTableRow key={i}>
              <TableCell>
                {p.periodo}
              </TableCell>
              <TableCell align='left'>
                {p.siglaUnidade} ({p.codUnidade})
              </TableCell>
              <TableCell align='left'>
                {p.numLogins}
              </TableCell>
              <TableCell align='left'>
                {p.numAcessosOrcamento}
              </TableCell>
              <TableCell align='left'>
                {p.numEmpreencimentosCriados}
              </TableCell>
              <TableCell align='left'>
                {p.numEmpreencimentosExcluidos}
              </TableCell>
              <TableCell align='left'>
                {p.numObrasCriadas}
              </TableCell>
              <TableCell align='left'>
                {p.numObrasExcluidas}
              </TableCell>
              <TableCell align="left">
                <Box className={classes.relatorioDadosOrcamentos}>
                  {p.numOrcamentosCriados}
                  <Box display="flex" flexDirection="column">
                    <Typography variant='caption' className={classes.relatorioDadosOrcamentosDetalhes}>({p.numOrcamentosSigilososCriados} sigilosos)</Typography>
                    <Typography variant='caption' className={classes.relatorioDadosOrcamentosDetalhes}>({p.numOrcamentosComCurvaAbcCriados} c/ curva ABC)</Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell align='left'>
              <Box className={classes.relatorioDadosOrcamentos}>
                {p.numOrcamentosExcluidos}
                <Box display="flex" flexDirection="column">
                  <Typography variant='caption' className={classes.relatorioDadosOrcamentosDetalhes}>({p.numOrcamentosSigilososExcluidos} sigilosos)</Typography>
                  <Typography variant='caption' className={classes.relatorioDadosOrcamentosDetalhes}>({p.numOrcamentosComCurvaAbcExcluidos} c/ curva ABC)</Typography>
                </Box>
              </Box>
              </TableCell>
            </StyledTableRow>
          ))}
      </TableBody>
    </Table>
    <Paginacao
      total={estatisticas.length}
      registrosPorPagina={registrosPorPagina}
      pagina={pagina}
      onChangePage={(e, pag) => setPagina(pag)}
      onChangeRowsPerPage={(e) => setRegistrosPorPagina(e.target.value)}
    />
  </Paper>
}

const TabPanelObras = ({estatisticas, pagina, setPagina, registrosPorPagina, setRegistrosPorPagina }) => {
  return <Paper elevation={0}>
    <Table>
      <TableHead>
        <TableRow>
          <TitleTableCell>Obra</TitleTableCell>
          <TitleTableCell>Tipo</TitleTableCell>
          <TitleTableCell>Empreendimento</TitleTableCell>
          <TitleTableCell>Data Inclusão</TitleTableCell>
          <TitleTableCell>Data Exclusão</TitleTableCell>
          <TitleTableCell>Orçamentos Criados</TitleTableCell>
          <TitleTableCell>Orçamentos Excluídos</TitleTableCell>\
        </TableRow>
      </TableHead>
      <TableBody>
        {estatisticas.slice(pagina * registrosPorPagina, pagina * registrosPorPagina + registrosPorPagina)
          .filter(p => p.nomeObra)
          .map((p, i) => (
            <StyledTableRow key={i}>
              <TableCell>
                {p.nomeObra}
              </TableCell>
              <TableCell align='left'>
                {p.tipoObra}
              </TableCell>
              <TableCell align='left'>
                {p.nomeEmpreendimento}
              </TableCell>
              <TableCell align='left'>
                {p.dtHoraInclusao}
              </TableCell>
              <TableCell align='left'>
                {p.dtHoraExclusao}
              </TableCell>
              <TableCell align='left'>
                {p.orcamentosCriadosObra}
              </TableCell>
              <TableCell align='left'>
                {p.orcamentosExcluidosObra}
              </TableCell>
            </StyledTableRow>
          ))}
      </TableBody>
    </Table>
    <Paginacao
      total={estatisticas.length}
      registrosPorPagina={registrosPorPagina}
      pagina={pagina}
      onChangePage={(e, pag) => setPagina(pag)}
      onChangeRowsPerPage={(e) => setRegistrosPorPagina(e.target.value)}
    />
  </Paper>
}

const RelatorioGerencial = ({ classes }) => {
  const [tipoEstatistica, setTipoEstatistica] = useState('estatisticas')
  const [periodoSelecao, setPeriodoSelecao] = React.useState('')
  const [registrosPorPagina, setRegistrosPorPagina] = useState(10)
  const [pagina, setPagina] = useState(0)
  var periodos = loadPeriodos()
  const [estatisticas, setEstatisticas] = useState([])

  useEffect(() => {
    if (periodoSelecao === '') {
      axios.get(`/api/relatoriogerencial/${tipoEstatistica}/`.toLowerCase(), SLOW_REQUEST_CONFIG).then((estatisticas) => {
        estatisticas.status === 200 ? setEstatisticas(estatisticas.data) : setEstatisticas([])
        setPagina(0)
      })
      return
    }
    axios.get(`/api/relatoriogerencial/${tipoEstatistica}/${periodoSelecao}`.toLowerCase(), SLOW_REQUEST_CONFIG).then(
      (estatisticas) => {
        estatisticas.status === 200
          ? setEstatisticas(estatisticas.data)
          : setEstatisticas([])
        setPagina(0)
      })
  }, [tipoEstatistica, periodoSelecao])

  const handleChangeSistema = (ev, newValue) => {
    setTipoEstatistica(newValue)
    setPagina(0)
  }

  const handleChangePeriodo = (event) => {
    setPeriodoSelecao(event.target.value)
  }

  const authentication = getAuthentication()
  if (!authentication || !authentication.isUserAuthenticated) {
    return <Redirect to='/login' />
  }

  function montaTituloTela () {
    return <Box pt={4} mb={2}>
      <Breadcrumbs aria-label='breadcrumb' style={{ marginTop: '-8px' }}>
        <Link color='inherit' href='/'>
          Início
        </Link>
        <Typography color='primary'>Relatório gerencial</Typography>
      </Breadcrumbs>
    </Box>
  }

  function montaCaixasDeFiltragem () {
    loadPeriodos()
    return <Box>
      <Box>
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='h6' gutterBottom>Filtros</Typography>
        </Box>
        <Box>
          <FormControl sx={{ mt: 1, minWidth: 180 }}>
            <InputLabel id='label'>Mês/Ano</InputLabel>
            <Select
              labelId='labelSelect'
              id='selectperiodo'
              value={periodoSelecao || periodos[0].anoMes}
              label='Período'
              onChange={handleChangePeriodo}
            >
              {periodos.map((p, i) =>
                <MenuItem value={p.anoMes} key={p} >
                  {p.mesAno}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  }

  return (
    <App noAppToolbar={false}>
      {montaTituloTela()}
      <Paper elevation={0} variant='outlined'>
        <Box p={2} mb={2}>
          {montaCaixasDeFiltragem()}
        </Box>
        <Box py={2} px={2}>
          <Typography variant='h6'>Relatório de uso do SAO</Typography>
        </Box>
        <TabContext value={tipoEstatistica}>
          <Divider />
          <Box>
            <TabList onChange={handleChangeSistema} aria-label='lab API tabs example'>
              <Tab label='Estatisticas' value='estatisticas' />
              <Tab label='Obras' value='obras' />
            </TabList>
          </Box>
          <Divider />
          <TabPanel value='estatisticas'>
            <TabPanelEstatisticas
              estatisticas = {estatisticas}
              pagina = {pagina}
              classes={classes}
              setPagina = {setPagina}
              registrosPorPagina = {registrosPorPagina}
              setRegistrosPorPagina = {setRegistrosPorPagina} />
          </TabPanel>
          <TabPanel value='obras'>
            <TabPanelObras
              estatisticas = {estatisticas}
              pagina = {pagina}
              setPagina = {setPagina}
              registrosPorPagina = {registrosPorPagina}
              setRegistrosPorPagina = {setRegistrosPorPagina} />
          </TabPanel>
        </TabContext>
      </Paper>
    </App>
  )
}

RelatorioGerencial.propTypes = {
  classes: PropTypes.object
}

export default withStyles(styles)(RelatorioGerencial)
