import React, { useState, useEffect } from 'react';
import { withStyles, Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableHead, TableRow, TableCell, TableBody, TextField, Select, InputLabel, FormControl, Checkbox, ListItemText, Typography } from '@material-ui/core';
import axios from 'axios';
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil';
import Paginacao from '../comum/Paginacao';
import MenuItem from '@material-ui/core/MenuItem'
import { showSuccessMsg } from '../../js/util';
import { context_httpget } from '../../js/httpRequest';
import { mapStateToPropsContexto } from '../../js/util';
import { connect } from 'react-redux'
import styles from '../orcamento/analisar/precos/styles';


const FeedbackListModal = ({ open, onClose }) => {
  const [feedbacks, setFeedbacks] = useState([]);
  const [originalFeedbacks, setOriginalFeedbacks] = useState([]);
  const [pagina, setPagina] = useState(0);
  const [registrosPorPagina, setRegistrosPorPagina] = useState(10);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const [availableYears, setAvailableYears] = useState([]);
  const [selectAllMonths, setSelectAllMonths] = useState(false);
  const [selectAllYears, setSelectAllYears] = useState(false);
  const [updatedFeedbacks, setUpdatedFeedbacks] = useState([]);
  const [filteredModifiedFeedbacks, setFilteredModifiedFeedbacks] = useState([]);
  const [tratadoFilter, setTratadoFilter] = useState('');


  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/api/feedback', SLOW_REQUEST_CONFIG);
        let data = response.data;
       
        data.sort((a, b) => {
          const dateA = new Date(...a.dataInclusao);
          const dateB = new Date(...b.dataInclusao);
          return dateA - dateB;
        });

        setFeedbacks(data);
        setOriginalFeedbacks(data);

        const years = data.map(feedback => new Date(feedback.dataInclusao[0], 0).getFullYear());
        const uniqueYears = [...new Set(years)];

        uniqueYears.sort((a, b) => b - a);

        setAvailableYears(uniqueYears);

} catch (error) {
  console.error('Error fetching feedbacks:', error);
}

};

if (open) {
  fetchData();
}
}, [open]); 


  const formatDate = (dateArray) => {
    const [year, month, day, hour, minute, second] = dateArray;
    return new Date(year, month - 1, day, hour, minute, second).toLocaleString();
  };

  const handleChangePage = (event, newPage) => {
    setPagina(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRegistrosPorPagina(parseInt(event.target.value, 10));
    setPagina(0);
  };

  const handleSearch = () => {
    const filteredFeedbacks = originalFeedbacks.filter(feedback => {
      const [year, month] = feedback.dataInclusao.slice(0, 2);
      const isYearIncluded = selectedYears.length === 0 || selectedYears.includes(year);
      const isMonthIncluded = selectedMonths.length === 0 || selectedMonths.includes(month);
      const isTratadoMatch = tratadoFilter === '' || feedback.foiTratado === tratadoFilter;
      return isYearIncluded && isMonthIncluded && isTratadoMatch;
    });
    setFeedbacks(filteredFeedbacks);
  };

  const handleClear = () => {
    setSelectedMonths([]);
    setSelectedYears([]);
    setSelectAllMonths(false);
    setSelectAllYears(false);
    setFeedbacks(originalFeedbacks);
  };

  const handleToggleAllMonths = () => {
    if (!selectAllMonths) {
      setSelectedMonths([...Array(12).keys()].map(month => month + 1));
    } else {
      setSelectedMonths([]);
    }
    setSelectAllMonths(!selectAllMonths);
  };

  const handleToggleAllYears = () => {
    if (!selectAllYears) {
      setSelectedYears(availableYears);
    } else {
      setSelectedYears([]);
    }
    setSelectAllYears(!selectAllYears);
  };

  const monthNames = [
    'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
    'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
  ];

  const handleCheckboxChange = (index) => {
    const updatedFeedbacksCopy = [...updatedFeedbacks];
    const feedback = feedbacks[index];
    feedback.foiTratado = feedback.foiTratado === 'S' ? 'N' : 'S';
    updatedFeedbacksCopy[index] = feedback;
    // Filter out undefined elements
    const filteredFeedbacks = updatedFeedbacksCopy.filter(item => item !== undefined);
    
    setUpdatedFeedbacks(filteredFeedbacks);
    setFilteredModifiedFeedbacks(filteredFeedbacks); // Set the filtered modified feedbacks
    
  };
  
  // Inside handleSave function
  const handleSave = async () => {
    try {
      // Send filteredModifiedFeedbacks to the backend
      await axios.put('/api/feedback', filteredModifiedFeedbacks, SLOW_REQUEST_CONFIG);
      
      showSuccessMsg('Feedbacks atualizados com sucesso')
      
      onClose()
      setFilteredModifiedFeedbacks([]);
      setUpdatedFeedbacks([]);
    } catch (error) {
      console.error('Error updating feedbacks:', error);
    }
  };

  const handleClose = () => {
    onClose();
    setFilteredModifiedFeedbacks([]);
    setUpdatedFeedbacks([]);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Feedbacks dos usuários</DialogTitle>
      <DialogContent dividers>
        {/* Filter inputs */}
        <FormControl style={{ marginRight: 10, minWidth: 200 }}>
          <InputLabel shrink>
            Mês
          </InputLabel>
          <Select
            multiple
            value={selectedMonths}
            onChange={(e) => setSelectedMonths(e.target.value)}
            renderValue={(selected) => selected.join(', ')}
            displayEmpty
          >
            <MenuItem key="selectAllMonths" value="selectAllMonths">
              <Checkbox checked={selectAllMonths} onChange={handleToggleAllMonths} />
              <ListItemText primary="Todos" />
            </MenuItem>
            {monthNames.map((month, index) => (
              <MenuItem key={index + 1} value={index + 1}>
                <Checkbox checked={selectedMonths.indexOf(index + 1) > -1} />
                <ListItemText primary={month} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ marginRight: 10, minWidth: 200 }}>
          <InputLabel shrink>
            Ano
          </InputLabel>
          <Select
            multiple
            value={selectedYears}
            onChange={(e) => setSelectedYears(e.target.value)}
            renderValue={(selected) => selected.join(', ')}
            displayEmpty
          >
            <MenuItem key="selectAllYears" value="selectAllYears">
              <Checkbox checked={selectAllYears} onChange={handleToggleAllYears} />
              <ListItemText primary="Todos" />
            </MenuItem>
            {availableYears.map(year => (
              <MenuItem key={year} value={year}>
                <Checkbox checked={selectedYears.indexOf(year) > -1} />
                <ListItemText primary={year} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl style={{ marginRight: 10, minWidth: 200 }}>
          <InputLabel shrink>
            Tratado
          </InputLabel>
          <Select
            value={tratadoFilter}
            onChange={(e) => setTratadoFilter(e.target.value)}
            displayEmpty
          >
            <MenuItem value="">Todos</MenuItem>
            <MenuItem value="S">Sim</MenuItem>
            <MenuItem value="N">Não</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={handleSearch} style={{ marginLeft: 10 }}>
          Filtrar
        </Button>
        <Button variant="outlined" color="default" onClick={handleClear} style={{ marginLeft: 10 }}>
          Limpar Filtro
        </Button>

        {/* Table with feedbacks */}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell>Avaliação</TableCell>
              <TableCell>Data de Inclusão</TableCell>
              <TableCell>Login de Inclusão</TableCell>
              <TableCell>Tratado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {feedbacks.slice(pagina * registrosPorPagina, pagina * registrosPorPagina + registrosPorPagina).map((feedback, index) => (
              <TableRow key={index}>
                <TableCell>{feedback.cod}</TableCell>
                <TableCell>{feedback.avaliacao}</TableCell>
                <TableCell>{formatDate(feedback.dataInclusao)}</TableCell>
                <TableCell>{feedback.loginInclusao}</TableCell>
                <Checkbox
                checked={feedback.foiTratado === 'S'} // Check if foiTratado is 'S' to pre-check the checkbox
                onChange={() => handleCheckboxChange(index)}
                color="primary"
                inputProps={{ 'aria-label': 'checkbox' }}
              />
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {/* Pagination */}
        <Paginacao
          total={feedbacks.length}
          registrosPorPagina={registrosPorPagina}
          pagina={pagina}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Fechar
        </Button>
        <Button onClick={handleSave} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

//export default FeedbackListModal;
export default connect(mapStateToPropsContexto)(
  withStyles(styles)(FeedbackListModal)
)