import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import { converterMoedaBRParaNumero, handleCampoNumericoChange, mapStateToPropsContexto, seStringVazia, showSuccessMsg, showErrorMsg } from '../../../../../../../../js/util'
import MenuItem from '@material-ui/core/MenuItem'
import Box from '@material-ui/core/Box'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import TituloVoltar from '../../../../../../../comum/TituloVoltar'
import { context_httpget, context_httppost } from '../../../../../../../../js/httpRequest'
import { connect } from 'react-redux'
import InsumoAdicionar from './InsumoAdicionar'
import _ from 'lodash'
import ComposicaoAdicionar from './ComposicaoAdicionar'
import FormControl from '@material-ui/core/FormControl'
import { TIPO_INSUMO_COMPOSICAO_DESTE_ORCAMENTO, TIPO_INSUMO_COMPOSICAO_REFERENCIA_PRECO, TIPO_INSUMO_MATERIAL_DESTE_ORCAMENTO, TIPO_INSUMO_MATERIAL_REFERENCIA_PRECO } from '../composicoesAuxiliares'

const OPCOES_INSUMO = [
  TIPO_INSUMO_MATERIAL_DESTE_ORCAMENTO,
  TIPO_INSUMO_COMPOSICAO_DESTE_ORCAMENTO,
  TIPO_INSUMO_MATERIAL_REFERENCIA_PRECO,
  TIPO_INSUMO_COMPOSICAO_REFERENCIA_PRECO
]

const OPCOES_TEMPO_FIXO = [
  TIPO_INSUMO_COMPOSICAO_DESTE_ORCAMENTO,
  TIPO_INSUMO_COMPOSICAO_REFERENCIA_PRECO
]

const defaultState = {
  tipoInsumo: TIPO_INSUMO_MATERIAL_REFERENCIA_PRECO.value,
  tipoTempoFixo: TIPO_INSUMO_COMPOSICAO_REFERENCIA_PRECO.value,
  materiais: [],
  composicoes: [],
  insumo: null,
  insumoEmpty: false,
  tempoFixo: null,
  tempoFixoEmpty: false,
  quantidade: '',
  quantidadeEmpty: false,
  descricao: '',
  descricaoEmpty: false,
  fit: 0.0
}

class AdicionarTempoFixoComposicao extends Component {
  static propTypes = {
    onCancelar: PropTypes.func.isRequired,
    contexto: PropTypes.object,
    codigoComposicao: PropTypes.string,
    onDadosAlterados: PropTypes.func,
  }

  constructor (props) {
    super(props)
    this.state = defaultState
    this.handleChange = this.handleChange.bind(this)
    this.handleSalvarClick = this.handleSalvarClick.bind(this)
  }

  componentDidMount () {
    if (!_.isNil(this.props.contexto)) {
      this.recuperarOpcoesTempoFixo()
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.contexto !== this.props.contexto) {
      this.recuperarOpcoesTempoFixo()
    }
  }

  async recuperarOpcoesTempoFixo() {
    const { contexto, codigoComposicao } = this.props
    const data = await context_httpget(`precos/tempos-fixos/opcoes?composicao=${codigoComposicao}`, contexto)
    if (data) {
      this.setState({
        materiais: data.materiais,
        composicoes: data.composicoes
      })
    }
  }

  handleCampoTextoChange (event, campo) {
    this.handleChange(event.target.value, campo)
  }

  handleChange(value, campo) {
    const state = {}
    state[campo] = value
    state[`${campo}Empty`] = _.isNil(value) || _.isEmpty(value)
    this.setState(state)
  }

  async handleSalvarClick () {
    const { insumo, quantidade, descricao, tempoFixo, tipoInsumo, tipoTempoFixo, fit } = this.state
    const insumoEmpty = insumo === null
    const quantidadeEmpty = seStringVazia(quantidade)
    const descricaoEmpty = seStringVazia(descricao)
    const tempoFixoEmpty = tempoFixo === null

    if([insumoEmpty, quantidadeEmpty, descricaoEmpty, tempoFixoEmpty].some(Boolean)) {
      this.setState({
        insumoEmpty,
        quantidadeEmpty,
        descricaoEmpty,
        tempoFixoEmpty
      })
    } else {


      const { contexto, codigoComposicao, onDadosAlterados } = this.props

      if (tempoFixo.codigo === codigoComposicao) {
        showErrorMsg('O código da composição selecionada é igual ao código da composição atual. Selecione uma composição de Transporte')
        return; 
      }

      const dadosSalvar = {
        origemInsumo: tipoInsumo,
        insumo: {
          codigo: insumo.codigo,
          sistema: insumo.sistema || insumo.sistemaReferencia,
          uf: insumo.uf,
          mes: insumo.mes,
          ano: insumo.ano
        },
        quantidade: converterMoedaBRParaNumero(quantidade),
        descricao,
        origemTempoFixo: tipoTempoFixo,
        tempoFixo: {
          codigo: tempoFixo.codigo,
          sistema: tempoFixo.sistema || tempoFixo.sistemaReferencia,
          uf: tempoFixo.uf,
          mes: tempoFixo.mes,
          ano: tempoFixo.ano
        },
        fit: fit
      }
      
      const resultado = await context_httppost(`precos/tempos-fixos/adicionar?composicao=${codigoComposicao}`, contexto, dadosSalvar)
      if (resultado) {
        showSuccessMsg('Tempo fixo criado e incluído na composição.')
        onDadosAlterados()
      }
    }
  }

  render () {
    const { onCancelar, codigoComposicao } = this.props
    const {
      tipoInsumo,
      tipoTempoFixo,
      materiais,
      composicoes,
      insumo,
      insumoEmpty,
      quantidade,
      quantidadeEmpty,
      descricao,
      descricaoEmpty,
      tempoFixo,
      tempoFixoEmpty
    } = this.state
    return (
      <>
        <TituloVoltar onClick={onCancelar} label='Vincular Tempo Fixo na Composição' />
        <Box mt={2}>
          <Typography variant='overline'>1. Informe o insumo:</Typography>

          <Box mt={1}>
            <TextField
              fullWidth
              value={tipoInsumo}
              onChange={(e) => this.handleCampoTextoChange(e, 'tipoInsumo')}
              variant='outlined'
              select>
              {
                OPCOES_INSUMO.map((it) => (
                  <MenuItem key={it.value} value={it.value}>{it.label}</MenuItem>
                ))
              }
            </TextField>

            <InsumoAdicionar
              tipoInsumo={tipoInsumo}
              insumo={insumo}
              insumoEmpty={insumoEmpty}
              handleChange={this.handleChange}
              materiais={materiais}
              composicoes={composicoes}
            />

            <Box mt={2}>
              <FormControl variant='outlined'>
                <Typography variant='subtitle1'>Quantidade</Typography>
                <TextField
                  variant='outlined'
                  value={quantidade}
                  error={quantidadeEmpty}
                  onChange={(e) => handleCampoNumericoChange(e, (e) => this.handleCampoTextoChange(e, 'quantidade'))}
                />
              </FormControl>
            </Box>

            <Box mt={2}>
              <FormControl variant='outlined' fullWidth>
                <Typography variant='subtitle1'>Descrição</Typography>
                <TextField
                  variant='outlined'
                  value={descricao}
                  error={descricaoEmpty}
                  onChange={(e) => this.handleCampoTextoChange(e, 'descricao')}
                />
              </FormControl>
            </Box>

          </Box>
        </Box>
        <Box mt={4}>
          <Typography variant='overline'>2. Informe a composição de tempo fixo (transporte):</Typography>

          <Box mt={1}>
            <TextField
              fullWidth
              value={tipoTempoFixo}
              onChange={(e) => this.handleCampoTextoChange(e, 'tipoTempoFixo')}
              variant='outlined'
              select>
              {
                OPCOES_TEMPO_FIXO.map((it) => (
                  <MenuItem key={it.value} value={it.value}>{it.label}</MenuItem>
                ))
              }
            </TextField>

            <ComposicaoAdicionar
              tipoComposicao={tipoTempoFixo}
              composicao={tempoFixo}
              composicaoEmpty={tempoFixoEmpty}
              handleChange={this.handleChange}
              nomeAtributo='tempoFixo'
              composicoes={composicoes}
              codigoComposicao={codigoComposicao}
            />
          </Box>
        </Box>

        <Box mt={3}>
          <MesmaLinha>
            <Button color='primary' variant='contained' onClick={this.handleSalvarClick}>Salvar</Button>
            <Button color='primary' onClick={onCancelar}>Cancelar</Button>
          </MesmaLinha>
        </Box>
      </>
    )
  }
}

export default connect(mapStateToPropsContexto)(AdicionarTempoFixoComposicao)
