import React, { Component } from 'react'
import { MenuItem, Typography, withStyles } from '@material-ui/core'
import ModalAtribuirBDI from './ModalAtribuirBDI'
import PropTypes from 'prop-types'
import styles from '../styles'

const defaultState = {
  modalAtribuirBDI: false
}

class IconeMenuAtribuirBDI extends Component {
  static propTypes = {
    onItensAlterados: PropTypes.func,
    classes: PropTypes.object
  }
  constructor (props) {
    super(props)
    this.state = defaultState
    this.abrirModal = this.abrirModal.bind(this)
    this.fecharModal = this.fecharModal.bind(this)
  }

  abrirModal () {
    this.setState({ modalAtribuirBDI: true })
  }

  fecharModal () {
    this.setState({ modalAtribuirBDI: false })
  }

  render () {
    const { onItensAlterados, classes } = this.props
    const { modalAtribuirBDI } = this.state

    return (
      <div>
        <MenuItem value='check' onClick={this.abrirModal} className={classes.itemMenuOpcoes}>
          <Typography className={classes.tituloItem}>Atribuir %BDI <br />
            <span className={classes.subtituloItem}>Atribui um percentual de BDI a todos os itens da curva ABC</span>
          </Typography>
        </MenuItem>
        <ModalAtribuirBDI
          open={modalAtribuirBDI}
          close={this.fecharModal}
          onItensAlterados={onItensAlterados}
        />
      </div>
    )
  }
}

export default (withStyles(styles)(IconeMenuAtribuirBDI))
