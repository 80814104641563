import TableCell from '@material-ui/core/TableCell'
import React from 'react'
import StyledTableRow from '../../comum/StyledTableRow'
import PropTypes from 'prop-types'

const Insumo = ({
  value
}) => (
  <StyledTableRow>
    <TableCell >{ value.tipo.descricao }</TableCell>
    <TableCell >{ value.codigo }</TableCell>
    <TableCell>{ value.descricao }</TableCell>
    <TableCell align='right'>{ value.unidade }</TableCell>
    <TableCell align='right'>{ value.precoFormatado }</TableCell>
  </StyledTableRow>
)

Insumo.propTypes = {
  value: PropTypes.object
}

export default Insumo
