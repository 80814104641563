import React, { Component } from 'react'
import { FormControl, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SaveIcon from '@material-ui/icons/Save'
import styles from '../../../styles'
import { seStringVazia, showSuccessMsg } from '../../../../../../../js/util'
import { context_httppost, context_httpput } from '../../../../../../../js/httpRequest'
import _ from 'lodash'
import TituloVoltar from '../../../../../../comum/TituloVoltar'

const defaultState = {
  nome: '',
  nomeEmpty: false,
  descricao: '',
  descricaoEmpty: false,
  isEdicaoDadosBasicosReadOnly: false
}

class DadosBasicos extends Component {
  constructor (props) {
    super(props)
    this.state = defaultState
  }

  componentDidMount () {
    if (this.props.expressaoAtiva) {
      this.copiarDadosExpressaoParaEstado()
    }
  }

  copiarDadosExpressaoParaEstado () {
    const { expressaoAtiva } = this.props
    if (expressaoAtiva) {
      this.setState({
        nome: expressaoAtiva.nome,
        operador: expressaoAtiva.operador,
        descricao: expressaoAtiva.descricao,
        isEdicaoDadosBasicosReadOnly: true
      })
    }
  }

  handleNomeExpressaoChange (event) {
    const nome = event.target.value
    this.setState({
      nome,
      nomeEmpty: seStringVazia(nome)
    })
  }

  handleDescricaoExpressaoChange (event) {
    const descricao = event.target.value
    this.setState({
      descricao,
      descricaoEmpty: seStringVazia(descricao)
    })
  }

  async handleSalvarExpressao () {
    this.validarFormulario()
    const { contexto, tipoAnaliseSelecionada, expressaoAtiva, onExpressaoChange } = this.props
    const { nome, descricao } = this.state
    const expressao = { nome, descricao }
    const response = _.isNil(expressaoAtiva)
      ? await context_httppost('analise-conteudo/expressoes', contexto, expressao)
      : await context_httpput(`analise-conteudo/expressoes/${expressaoAtiva.codigo}?tipoAnalise=${tipoAnaliseSelecionada}`, contexto, expressao)
    if (response) {
      showSuccessMsg('Dados salvos')
      this.setState({ isEdicaoDadosBasicosReadOnly: true })
      onExpressaoChange(response)
    }
  }

  validarFormulario () {
    const { nome, descricao } = this.state
    const nomeEmpty = seStringVazia(nome)
    const descricaoEmpty = seStringVazia(descricao)
    if (nomeEmpty || descricaoEmpty) {
      this.setState({
        nomeEmpty,
        descricaoEmpty
      })
      // eslint-disable-next-line no-throw-literal
      throw 'Formulário inválido. Campos obrigatórios não preenchidos'
    }
  }

  render () {
    const { classes, expressaoAtiva, onFecharDrawer } = this.props
    const {
      nome,
      nomeEmpty,
      descricao,
      descricaoEmpty,
      isEdicaoDadosBasicosReadOnly
    } = this.state

    return (
      <div className={classes.dadosBasicos}>
        <React.Fragment>
          <TituloVoltar onClick={onFecharDrawer} label='Expressão de Busca' />
          { (isEdicaoDadosBasicosReadOnly && !_.isNil(expressaoAtiva)) &&
            <Box mt={2}>
              <Typography variant='h6'>{nome}</Typography>
              <Typography variant='subtitle1'>{descricao}</Typography>
              <div>
                <Button variant='outlined' color='primary' onClick={() => this.setState({ isEdicaoDadosBasicosReadOnly: false })}>Editar</Button>
              </div>
            </Box>
          }

          {!isEdicaoDadosBasicosReadOnly &&
            <React.Fragment>
              <FormControl variant='outlined' fullWidth>
                <Typography variant='subtitle1'>Nome</Typography>
                <TextField
                  value={nome}
                  error={nomeEmpty}
                  onChange={(e) => this.handleNomeExpressaoChange(e)}
                  variant='outlined'
                />
              </FormControl>

              <Box mt={2}>
                <FormControl variant='outlined' fullWidth>
                  <Typography variant='subtitle1'>Descrição</Typography>
                  <TextField
                    multiline
                    rows={3}
                    value={descricao}
                    error={descricaoEmpty}
                    onChange={(e) => this.handleDescricaoExpressaoChange(e)}
                    variant='outlined'
                  />
                </FormControl>
              </Box>
            </React.Fragment>
          }

          {!isEdicaoDadosBasicosReadOnly &&
            <Box mt={3}>
              <Button variant='outlined' size='large' color='default' onClick={() => this.handleSalvarExpressao()}>
                <SaveIcon fontSize='small' style={{ marginRight: 8 }} /> Salvar Expressão
              </Button>
            </Box>
          }
        </React.Fragment>
      </div>
    )
  }
}

const mapStateToProps = ({ contexto }) => ({
  contexto
})

DadosBasicos.propTypes = {
  classes: PropTypes.object,
  contexto: PropTypes.object,
  expressaoAtiva: PropTypes.object,
  tipoAnaliseSelecionada: PropTypes.string.isRequired,
  onExpressaoChange: PropTypes.func.isRequired,
  onFecharDrawer: PropTypes.func.isRequired
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(DadosBasicos)))
