import axios from 'axios'
import { CADASTRAR_ORCAMENTO } from '../types'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import { showSuccessMsg } from '../../js/util'

const cadastrarOrcamento = (orcamento, idEmpreendimento, idObra) => async (dispatch) => {
  const { data } = await axios.post(`/api/empreendimentos/${idEmpreendimento}/obras/${idObra}/orcamentos`, { ...orcamento }, SLOW_REQUEST_CONFIG)
  dispatch({
    type: CADASTRAR_ORCAMENTO,
    payload: data
  })
  showSuccessMsg('Orçamento cadastrado com sucesso.')
  return data.cod
}

export default cadastrarOrcamento
