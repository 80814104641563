import React, { useState } from 'react'
import Table from '@material-ui/core/Table'
import CabecalhoTabelaPesquisarEmPrecos from './CabecalhoTabelaPesquisarEmPrecos'
import CorpoTabelaPesquisarEmPrecos from './CorpoTabelaPesquisarEmPrecos'
import PropTypes from 'prop-types'
import Paginacao from '../../../../comum/Paginacao'


const TabelaReferenciaPesquisarEmPrecos = ({ insumos, onSelect }) => {
  const [pagina, setPagina] = useState(0)
  const [registrosPorPagina, setRegistrosPorPagina] = useState(30)
  const [sortField, setSortField] = useState('codigo');
  const [sortOrder, setSortOrder] = useState('asc');

  const handleSort = (field) => {
    setSortField(field);
    setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const sortedInsumos = insumos.slice().sort((a, b) => {
    const valueA = a[sortField];
    const valueB = b[sortField];
  
    if (valueA < valueB) {
      return sortOrder === 'asc' ? -1 : 1;
    }
  
    if (valueA > valueB) {
      return sortOrder === 'asc' ? 1 : -1;
    }
  
    return 0;
  });

  return (
    <div>
      <Table>
        <CabecalhoTabelaPesquisarEmPrecos onSort={handleSort} sortField={sortField} sortOrder={sortOrder} />
        <CorpoTabelaPesquisarEmPrecos
          insumos={sortedInsumos}
          onSelect={onSelect}
          pagina={pagina}
          registrosPorPagina={registrosPorPagina}
        />
      </Table>
      <Paginacao
        total={insumos.length}
        registrosPorPagina={registrosPorPagina}
        pagina={pagina}
        onChangePage={(e, pag) => { setPagina(pag) }}
        onChangeRowsPerPage={(e) => setRegistrosPorPagina(e.target.value)}
      />
    </div>
  )
}

TabelaReferenciaPesquisarEmPrecos.propTypes = {
  insumos: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired
}

export default TabelaReferenciaPesquisarEmPrecos
