import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, TableCell, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import InfoIcon from '@material-ui/icons/Info'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import StyledAccordionSumary from '../../../../components/comum/StyledAccordionSumary'
import StyledAccordion from '../../../../components/comum/StyledAccordion'
import { withStyles } from '@material-ui/styles'
import styles from './styles'
import ModalInconsistencia from './ModalInconsistencia'
import { formatarNumeroParaMoedaBR } from '../../../../js/util'
import { context_httpget } from '../../../../js/httpRequest'
import { connect } from 'react-redux'
import SimplePopover from '../../../comum/SimplePopover'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'

import { getAuthentication } from '../../../../security/securityContext'
import { PERFIL } from '../../../../js/perfis'

const Tipologias = ({
  value,
  titulo,
  classes,
  contexto,
  onAtualizarTipologiaCustoZero
}) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [inconsistenciaSelecionada, setInconsistenciaSelecionada] = useState({})

  useEffect(() => {
    if (onAtualizarTipologiaCustoZero) {
      handleRecuperarInconsistenciaCustoZero()
    }
  }, [])


  const handleOpen = (inconsistencia) => {
    setInconsistenciaSelecionada(inconsistencia)
    setOpen(true)
  }

  const handleClose = () => {
    setInconsistenciaSelecionada({})
    setOpen(false)
  }

  const isFormulaInvalida = (inconsistencia) => isInconsistenciaFormula(inconsistencia) && inconsistencia.possuiErroNaFormula
  const isInconsistenciaExpressao = (inconsistencia) => inconsistencia.tipoPossivelInconsistencia === 'TIPOLOGIA_EXPRESSAO'
  const isInconsistenciaFormula = (inconsistencia) => inconsistencia.tipoPossivelInconsistencia === 'TIPOLOGIA_FORMULA'
  const isInconsistenciaTipologiaCombinada = (inconsistencia) => !_.isNil(inconsistencia.segundaPossivelInconsistenciaTipologiaCombinada)
  const isInconsistenciaCurvaAbcCustoIgualZero = (inconsistencia) => inconsistencia.titulo === 'Itens da Curva ABC com composições que possuem custo igual a zero' && _.isNil(inconsistencia.quantidadeItens)

  const handleRecuperarInconsistenciaCustoZero = async () => {
    setLoading(true)
    const resultado = await context_httpget('possiveis-inconsistencias/tipologias-de-analise/servicos_insumos_custo_igual_zero/executar', contexto, null, true)
    if (resultado) {
      onAtualizarTipologiaCustoZero(resultado)
      setLoading(false)
    }
  }

  const usuarioEhDesenvolvedor = () => {
    const perfis = getAuthentication().perfis.filter((it) => {
      if (it.includes(PERFIL.DESENVOLVEDOR) || it.includes(PERFIL.DESENV_ATUALIZADOR)) return it
    })
    return perfis.length > 0
  }

  const colunaInfoItensInconsistentes = (inconsistencia) => {
    return (
      <React.Fragment>
        {!isInconsistenciaCurvaAbcCustoIgualZero(inconsistencia)
          ? <>
            {!isInconsistenciaFormula(inconsistencia) && !isInconsistenciaTipologiaCombinada(inconsistencia)
              ? <div className={classes.colunaAnalise} onClick={() => handleOpen(inconsistencia)}>
                {inconsistencia.quantidadeItens > 0 && (_.isNil(inconsistencia.temInconsistencia) || inconsistencia.temInconsistencia)
                  ? <InfoIcon className={'icon_alert'} size='large' />
                  : <CheckCircleIcon className={classes.iconePadrao} size='large' />
                }
                <div>
                  {inconsistencia.quantidadeItens > 0
                    ? <Link>
                      <Typography color='primary' variant='overline'>
                        {inconsistencia.quantidadeItens === 1 ? '1 item' : `${inconsistencia.quantidadeItens} itens`}
                      </Typography>
                    </Link>
                    : <Typography variant='overline'> 0 itens </Typography>
                  }
                </div>
              </div>
              : colunaInfoFormulasETipologiasCombinadasInconsistentes(inconsistencia)
            }
          </>
          : <div className={classes.colunaAnalise}>
            {!loading
              ? <Box display='flex' alignItems='center'>
                <Button color='primary' size='small' className={classes.margin} onClick={handleRecuperarInconsistenciaCustoZero}>Executar</Button>
                <SimplePopover>
                  Esta operação pode levar alguns minutos para ser executada.
                </SimplePopover>
              </Box>
              : <CircularProgress size={20} />
            }
          </div>
        }
      </React.Fragment>
    )
  }

  const colunaResumoItens = (inconsistencia) => {
    const inconsistenciaFormula = isInconsistenciaFormula(inconsistencia)
    return (
      <div className={classes.linhasTexto}>
        <div className={classes.colunaResumo1}>
          <Typography variant='subtitle1' color='primary' onClick={() => handleOpen(inconsistencia)}>{inconsistencia.titulo}</Typography>
          {usuarioEhDesenvolvedor() && inconsistencia.criteriosExecucaoExpressao && (<SimplePopover>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell align='left'>Operador</TableCell>
                  <TableCell align='left'>Critério (campo|texto|tipo)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inconsistencia.criteriosExecucaoExpressao?.map((criterio, index) => (<TableRow key={index}>
                  <TableCell align='left'>{inconsistencia.operadorExpressao}</TableCell>
                  <TableCell align='left'>{criterio.campo}|{criterio.texto}|{criterio.tipo}</TableCell>
                </TableRow>))}
              </TableBody>
            </Table>
          </SimplePopover>)}
        </div>
        <div>
          {((inconsistencia.quantidadeItens > 0 && inconsistencia.valorTotalQuantidadeItens && inconsistencia.valorTotalQuantidadeItens > 0) || inconsistenciaFormula) &&
            (isFormulaInvalida(inconsistencia)
              ? <Typography variant='caption' >Fórmula Inválida</Typography>
              : <ul className={classes.listaResultadoTipologia}>
                {!inconsistenciaFormula &&
                  <li>
                    <b>{inconsistencia.valorTotalQuantidadeItens ? 'Quantidade: ' : ''}</b>
                    {inconsistencia.valorTotalQuantidadeItens ? formatarNumeroParaMoedaBR(inconsistencia.valorTotalQuantidadeItens, 2, 3) : ''}
                  </li>
                }

                <li>
                  <b>{inconsistencia.unidades ? 'Unidade: ' : ''}</b>
                  {inconsistencia.unidades && inconsistencia.unidades.map((it) => it.concat(' '))}
                </li>

                <li title={inconsistencia.textoExplicativoValor}>
                  <b>{inconsistencia.valor ? 'Valor: ' : ''} </b>
                  {inconsistencia.valor} {inconsistencia.percentual ? `(${inconsistencia.percentual})` : ''}
                </li>
              </ul>
            )
          }
        </div>

        {!_.isNil(inconsistencia.regraDaTipologia) &&
          <Typography variant='caption' color='primary'>{inconsistencia.regraDaTipologia}</Typography>
        }

        <Box mt={1}>
          <Typography variant='caption'>
            <b>Descrição: </b>{inconsistencia.descricao}
          </Typography>
        </Box>

        {!_.isEmpty(inconsistencia.observacao) &&
          <Typography variant='caption'>
            <b>Observação: </b>{inconsistencia.observacao}
          </Typography>
        }

        {!_.isNil(inconsistencia.descricaoErroFormula) &&
          <Typography variant='caption' className={classes.possivelInconsitenciaErro}>
            {inconsistencia.descricaoErroFormula}
          </Typography>
        }

        {!_.isEmpty(inconsistencia.aviso) &&
          <Typography variant='caption' className={classes.possivelInconsitenciaErro}>
            <b>Aviso: {inconsistencia.aviso}</b>
          </Typography>
        }
      </div>
    )
  }

  const colunaInfoFormulasETipologiasCombinadasInconsistentes = (inconsistencia) => {
    if (isInconsistenciaTipologiaCombinada(inconsistencia)) {
      return <div className={classes.colunaAnaliseFormulaCombinada} onClick={() => handleOpen(inconsistencia)}>
        {(_.isNil(inconsistencia.temInconsistencia) || inconsistencia.temInconsistencia)
          ? <InfoIcon className={'icon_alert'} size='large' />
          : <CheckCircleIcon className={classes.iconePadrao} size='large' />
        }
        <Typography variant='overline'> Combinada </Typography>
      </div>
    } else if (isInconsistenciaFormula(inconsistencia)) {
      return <div className={classes.colunaAnaliseFormulaCombinada} onClick={() => handleOpen(inconsistencia)}>
        {(_.isNil(inconsistencia.temInconsistencia) || inconsistencia.temInconsistencia)
          ? <InfoIcon className={'icon_alert'} size='large' />
          : <CheckCircleIcon className={classes.iconePadrao} size='large' />
        }
        <Typography variant='overline'> Fórmula </Typography>
      </div>
    }   
  }

  const qtdItensTipologia = value.map((it) => ({
    quantidadeItens: it.quantidadeItens,
    temInconsistencia: it.temInconsistencia
  }))

  const numeroInconsistencias = _.sumBy(qtdItensTipologia.map((t) => t.quantidadeItens > 0 && (_.isNil(t.temInconsistencia) || t.temInconsistencia) ? 1 : 0))

  const detalheTipologiasCombinada = (inconsistencia) => {
    return <>
      <ul className={classes.listaResultadoTipologia}>
        <li>
          <b>Tipologia: </b>{inconsistencia.titulo}
        </li>
        {isInconsistenciaExpressao(inconsistencia) &&
          <>
            <li>
              <b>{inconsistencia.valorTotalQuantidadeItens ? 'Quantidade: ' : ''}</b>
              {inconsistencia.valorTotalQuantidadeItens ? formatarNumeroParaMoedaBR(inconsistencia.valorTotalQuantidadeItens, 2, 3) : ''}
            </li>
            <li>
              <b>{inconsistencia.unidades ? 'Unidade: ' : ''}</b>
              {inconsistencia.unidades && inconsistencia.unidades.map((it) => it.concat(' '))}
            </li>
          </>
        }
        <li title={inconsistencia.textoExplicativoValor}>
          <b>{inconsistencia.valor ? 'Valor: ' : ''} </b>
          {inconsistencia.valor} {inconsistencia.percentual ? `(${inconsistencia.percentual})` : ''}
        </li>
      </ul>
    </>
  }

  const colunaResumoItensCombinada = (inconsistencia) => {
    return (
      <div className={classes.linhasTexto}>
        <Box display='flex'alignItems='center'>
          <Typography variant='subtitle1' color='primary' onClick={() => handleOpen(inconsistencia)}>
            {inconsistencia.nomeTipologiaCombinada}
          </Typography>
          <Typography variant='caption'>
            {inconsistencia.titulo} e {inconsistencia.segundaPossivelInconsistenciaTipologiaCombinada.titulo}
          </Typography>
          <SimplePopover>
            <div className={classes.colunaResumo2}>
              <div>{detalheTipologiasCombinada(inconsistencia)}</div>
              <div>{detalheTipologiasCombinada(inconsistencia.segundaPossivelInconsistenciaTipologiaCombinada)}</div>
            </div>
          </SimplePopover>
        </Box>
        <div className={classes.colunaResumo1}>
          <Typography variant='caption' color='primary'>
            {inconsistencia.regraDaTipologia} <b>e</b> {inconsistencia.segundaPossivelInconsistenciaTipologiaCombinada.regraDaTipologia}
          </Typography>
        </div>
        <div className={classes.colunaResumo1}>
          <Typography variant='caption'>
            <b>Descrição: </b>{inconsistencia.descricaoTipologiaCombinada}
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <StyledAccordion defaultExpanded>
      <StyledAccordionSumary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='subtitle2'>{titulo} ({numeroInconsistencias} Possíve{numeroInconsistencias === 1 ? 'l' : 'is'} Inconsistência{numeroInconsistencias === 1 ? '' : 's'})</Typography>
      </StyledAccordionSumary>
      <AccordionDetails className={classes.listagem}>
        {value.map((inconsistencia) => (
          <div className={classes.cardInconsistencia} key={inconsistencia.titulo}>
            {colunaInfoItensInconsistentes(inconsistencia)}
            {isInconsistenciaTipologiaCombinada(inconsistencia)
              ? colunaResumoItensCombinada(inconsistencia)
              : colunaResumoItens(inconsistencia)
            }
          </div>
        ))}
        {(value && value.length < 1) && <Typography className={classes.semTipologia}>Nenhuma tipologia ativa</Typography>}
      </AccordionDetails>
      <ModalInconsistencia
        open={open}
        close={handleClose}
        inconsistencia={inconsistenciaSelecionada}
      />
    </StyledAccordion>
  )
}

Tipologias.propTypes = {
  value: PropTypes.array.isRequired,
  titulo: PropTypes.string.isRequired,
  classes: PropTypes.object,
  contexto: PropTypes.object,
  onAtualizarTipologiaCustoZero: PropTypes.func
}

const mapStateToProps = ({ contexto }) => ({ contexto })
export default connect(mapStateToProps)(withStyles(styles)(Tipologias))
