import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { withStyles } from '@material-ui/core'
import styles from '../../../styles'
import { formatarNumeroParaMoedaBR } from '../../../../../../../js/util'
import { calcularTotalItensOrcamentarios, calcularTotalPercentual, calcularTotalValorParcial } from '../../../analise-conteudo'
import Button from '@material-ui/core/Button'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

class ResultadoExpressao extends Component {
  static propTypes = {
    value: PropTypes.array,
    expressao: PropTypes.object,
    classes: PropTypes.object,
    onCriarTipologia: PropTypes.func
  }

  textoResultados() {
    const { value } = this.props
    const foramEncontradas = value.length === 1 ? 'Foi encontrada' : 'Foram encontradas'
    const ocorrencias = value.length === 1 ? 'ocorrência' : 'ocorrências'
    const totalItensOrcamentarios = calcularTotalItensOrcamentarios(value)
    const orcamentarios = totalItensOrcamentarios === 1 ? 'item orçamentário' : 'itens orçamentários'
    return `${foramEncontradas} ${value.length} ${ocorrencias}, totalizando ${totalItensOrcamentarios} ${orcamentarios}.`
  }

  render () {
    const { value, expressao, classes, onCriarTipologia } = this.props
    return value && (
      <div className={classes.divResultados}>
        <div className={classes.tituloResultados}>
          <Typography variant='h6'>Resultados</Typography>
          <Button onClick={() => onCriarTipologia(true)} color='primary' variant='contained'>
            {
              expressao.tipologia ? 'Ver tipologia' : 'Criar tipologia'
            }
            <ArrowRightAltIcon fontSize='small'/>
          </Button>
        </div>
          { this.textoResultados() }
          {
            value.length === 0
              ? <Typography><em>Observe os critérios aplicados</em></Typography>
              : (
                <Table className={classes.tabelaResultados}>
                  <TableHead>
                    <TableRow>
                      <TableCell/>
                      <TableCell className={classes.tituloOrcamento} colSpan={3}>
                        Orçamento
                      </TableCell>
                      <TableCell className={classes.tituloRefPreco} colSpan={2}>
                        Referência de Preço
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Código do serviço</TableCell>
                      <TableCell>Descrição</TableCell>
                      <TableCell>Valor Parcial</TableCell>
                      <TableCell>Unidade</TableCell>
                      <TableCell>Descrição</TableCell>
                      <TableCell>Unidade</TableCell>
                      <TableCell>Percentual</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      value.map((it) => (
                        <TableRow key={it.id}>
                          <TableCell>{it.codigoServico}</TableCell>
                          <TableCell>{it.descricaoOrcamento}</TableCell>
                          <TableCell>{it.precoParcialCalculadoFormatado}</TableCell>
                          <TableCell>{it.unidadeOrcamento.replace(/"/g, '')}</TableCell>
                          <TableCell>{it.descricaoReferencia}</TableCell>
                          <TableCell>{it.unidadeReferencia}</TableCell>
                          <TableCell>{formatarNumeroParaMoedaBR(it.percentual, 2, 3)}%</TableCell>
                        </TableRow>
                      ))
                    }
                    <TableRow>
                      <TableCell />
                      <TableCell align={'right'}><strong>Totais:</strong></TableCell>
                      <TableCell>
                        <strong>{ formatarNumeroParaMoedaBR(calcularTotalValorParcial(value), 2, 3) }</strong>
                      </TableCell>
                      <TableCell />
                      <TableCell />
                      <TableCell />
                      <TableCell><strong>
                        { formatarNumeroParaMoedaBR(calcularTotalPercentual(value), 2, 3) }%</strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )
          }
      </div>
    )
  }
}

export default withStyles(styles)(ResultadoExpressao)
