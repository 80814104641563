import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import App from '../App'
import PropTypes from 'prop-types'
import BreadCrumb from '../comum/BreadCrumb'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    borderColor: '#ced2d9',
    textAlign: 'left',
    height: 'auto',
    margin: '8px 0'
  }
})

const renderContent = (insidePaper, classes, children) => {
  if (insidePaper === undefined) {
    insidePaper = true
  }

  return insidePaper ? (
    <Paper className={classes.paper} elevation={0} variant='outlined'>
      {children}
    </Paper>
  ) : (
    <div>
      {children}
    </div>
  )
}

const Template = ({ classes, insidePaper, children, BreadCrumbPersonalizado, ocultarBreadCrumb }) => (
  <App noAppToolbar={false}>
    <div className={classes.root}>
      <Grid container spacing={10}>
        <Grid item xs={12}>
          {!ocultarBreadCrumb && (BreadCrumbPersonalizado ? <BreadCrumbPersonalizado /> : <BreadCrumb />)}
          {renderContent(insidePaper, classes, children)}
        </Grid>
      </Grid>
    </div>
  </App>
)

Template.propTypes = {
  insidePaper: PropTypes.bool,
  classes: PropTypes.object,
  children: PropTypes.node,
  BreadCrumbPersonalizado: PropTypes.object,
  ocultarBreadCrumb: PropTypes.bool
}

Template.propDefaults = {
  insidePaper: true
}

export default withStyles(styles)(Template)
