import React from 'react'
import Arquivo from './Arquivo'
import styles from '../styles'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import InformacoesGerais from './InformacoesGerais'
import ReferenciaPreco from './ReferenciaPreco'

const Orientacoes = (props) => {
  const { classes, orcamentoCriado } = props
  return (
    <div className={classes.caixaApoio}>
      <Arquivo />
      { orcamentoCriado && <InformacoesGerais /> }
      { orcamentoCriado && <ReferenciaPreco /> }
    </div>
  )
}

Orientacoes.propTypes = {
  classes: PropTypes.object,
  orcamentoCriado: PropTypes.bool.isRequired
}

export default withStyles(styles)(Orientacoes)
