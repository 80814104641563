export default {
  card: {
    width: 376,
    height: 468,
    borderRadius: 20,
    paddingTop: 65,
    margin: '10px 0'
  },
  title: {
    marginBottom: 16,
    fontSize: 14
  },
  loginForm: {
    marginBottom: 13,
    marginTop: 8
  },
  titleBar: {
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #E0E0E0',
    paddingBottom: 4
  },
  logoTCU: {
    width: 250,
    marginBottom: 25
  },
  rowLogIn: {
    justifyContent: 'flex-end',
    paddingTop: 12,
    paddingRight: 24
  }

}
