import { pageHeaderAndFooter, round, tableFoot } from './RelatorioComposicaoPdf'

export function dadosTempoFixo (doc, detalhes, titulo) {
  const tableHeaderTempoFixo = [
    { dataKey: 'descricao', title: 'E - Tempo Fixo' },
    { dataKey: 'codigo', title: 'Código' },
    { dataKey: 'quantidade', title: 'Quantidade' },
    { dataKey: 'unidade', title: 'Unidade' },
    { dataKey: 'precoUnitario', title: 'Preço Unitário' },
    { dataKey: 'custoUnitario', title: '   Custo Unitário' }
  ]

  const tableDataTempoFixo = []
  detalhes.composicoesAuxiliares.temposFixos.forEach((tempoFixo) => {
    tableDataTempoFixo.push({
      descricao: (tempoFixo.codigoMaterial ? tempoFixo.codigoMaterial : tempoFixo.codigoComposicaoAuxiliar) + ' - ' + tempoFixo.descricao,
      codigo: tempoFixo.tempoFixoOrcamento.codigo,
      quantidade: round(tempoFixo.quantidade, 4),
      unidade: tempoFixo.tempoFixoOrcamento.unidade,
      precoUnitario: round(detalhes.composicoesAuxiliares.catalogoPrecos[tempoFixo.tempoFixoOrcamento.codigo], 5),
      custoUnitario: round(tempoFixo.quantidade * detalhes.composicoesAuxiliares.catalogoPrecos[tempoFixo.tempoFixoOrcamento.codigo], 5)
    })
  })
  doc.autoTable(tableHeaderTempoFixo, tableDataTempoFixo, {
    startY: doc.lastAutoTable.finalY,
    tableWidth: 'auto',
    margin: { top: 20 },
    tableLineColor: 255,
    tableLineWidth: 1,
    fontSize: 9,
    styles: { halign: 'right', cellPadding: 1 },
    headStyles: {
      fillColor: [200, 200, 200],
      textColor: [0, 0, 0],
      descricao: {
        overflow: 'linebreak',
        cellWidth: 350,
        valign: 'middle',
        halign: 'center',
        fontStyle: 'bold'
      }
    },
    columnStyles: {
      descricao: {
        overflow: 'linebreak',
        cellWidth: 350,
        valign: 'middle',
        halign: 'left',
        fontStyle: 'bold'
      }
    },
    didDrawPage: (data) => pageHeaderAndFooter(doc, data, titulo),
    willDrawCell: function (data) {
      if (data.column.index === 0 && data.cell.section === 'head') {
        data.cell.styles.halign = 'left'
      }
    }
  })

  tableFoot([{
    label: 'Custo Unitário Total de Tempo Fixo',
    value: round(detalhes.composicoesAuxiliares.custoUnitarioTotalTempoFixo, 2)
  }],
  doc)
}
