import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography, withStyles } from '@material-ui/core/'
import { mapStateToPropsContexto } from '../../../../js/util'
import MenuPossiveisErrosInconsistencias from '../Menus/MenuPossiveisErrosInconsistencias'
import MenuBtnPrincipal from '../Menus/MenuBtnPrincipal'
import PropTypes from 'prop-types'
import styles from '../styles'

class Cabecalho extends Component {
  static propTypes = {
    titulo: PropTypes.string.isRequired,
    classes: PropTypes.object,
    contexto: PropTypes.object,
    onColapse: PropTypes.func,
    resumoOrcamento: PropTypes.object,
    componente: PropTypes.element
  }

  constructor(props) {
    super(props)
    this.state = {
      expanded: true,
      resumoOrcamento: null,
    }
  }

  render() {
    const { classes, titulo, contexto, resumoOrcamento, componente = '', numeroPossiveisErros, numeroPossiveisInconsistencias } = this.props

    const { expanded } = this.state

    return (
      <div className={classes.cabecalho}>
        <div className={classes.tituloMenu}>
          <Typography variant="h6">Análise de Orçamento - {titulo}</Typography>
          <div className={classes.menu}>
            <MenuBtnPrincipal
              title={titulo}
              possuiABC={contexto.orcamento.possuiCurvaAbc}
              expanded={expanded}
              onExpandIconClick={this.handleColapseIconClick}
            />
            {componente}
          </div>
        </div>
        {true && (
          <MenuPossiveisErrosInconsistencias
            resumoOrcamento={resumoOrcamento}
            numeroPossiveisErros={numeroPossiveisErros}
            numeroPossiveisInconsistencias={numeroPossiveisInconsistencias}
          />
        )}
      </div>
    )
  }
}

export default connect(mapStateToPropsContexto)(withStyles(styles)(Cabecalho))
