import { FILTRAR_ANALISE_ORCAMENTO } from '../../types'

const filtrarAnaliseOrcamento = (descricao) => (dispatch) => {
  dispatch({
    type: FILTRAR_ANALISE_ORCAMENTO,
    payload: descricao
  })
}

export default filtrarAnaliseOrcamento
