import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Box, FormControl, InputAdornment, Popover, Table, TableBody, TextField, withStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import PrintIcon from '@material-ui/icons/Print'
import ItemComposicaoRow from './ItemComposicaoRow'
import { mapStateToPropsContexto, paginar, showErrorMsg, showAlertMsg } from '../../../../../js/util'
import CabecalhoComposicao from './CabecalhoComposicao'
import Paginacao from '../../../../comum/Paginacao'
import _ from 'lodash'
import BuscarComPaginacao from '../../../../comum/BuscarComPaginacao'
import styles from '../styles'
import Adicionar from '../../../../comum/Adicionar'
import CriarComposicao from '../../relatorios/precos/composicao/CriarComposicao'
import Button from '@material-ui/core/Button'
import RelatorioComposicaoPdf from '../../relatorios/precos/composicao/pdf/RelatorioComposicaoPdf'
import { context_httpget } from '../../../../../js/httpRequest'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import MesmaLinha from '../../../../comum/MesmaLinha'
import { CompositionProvider } from '../../../../../hooks/useComposition'
import ImportarComposicao from '../../relatorios/precos/composicao/ImportarComposicao'
import AtualizarReferencia from '../AtualizarReferencia'

export class Composicoes extends Component {
  
  constructor (props) {
    super(props)
    this.state = {
      composicoes: props.composicoes,
      composicoesCopia: props.composicoes,
      termoBusca: '',
      colunaOrdenacao: '',
      direcaoOrdenacao: 'desc',
      exibirDrawerCriarComposicao: false,
      exibirDrawerImportarComposicao: false,
      exibirDrawerAtualizarReferencia: false,
      imprime: false,
      imprimindo: false,
      detalhesComposicao: [],
      progressoImpressao: '',
      tituloRelatorio: '',
      anchorPopover: null,
      campoFiltro: 'all'
    }
    this.handleChangeOrdenacaoColuna = this.handleChangeOrdenacaoColuna.bind(this)
    this.onChangeDadosAlterados = this.onChangeDadosAlterados.bind(this)
    this.carregarDetalhesComposicao = this.carregarDetalhesComposicao.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    const { composicoes } = this.props
    
    if(prevProps.composicoes !== composicoes) {
      this.setState({
        composicoes: composicoes,
        composicoesCopia: composicoes
      })
    }

    if (prevState.termoBusca !== this.state.termoBusca) {
      this.buscar()
    }
  }

  ordernarComposicoes = () => {
    const { composicoes, colunaOrdenacao, direcaoOrdenacao } = this.state

    const composicoesOrdenadas = _.orderBy(composicoes, [colunaOrdenacao], [direcaoOrdenacao])

    this.setState({
      composicoes: composicoesOrdenadas
    })
  }

  handleChangeOrdenacaoColuna = (event, coluna) => {
    const { colunaOrdenacao, direcaoOrdenacao } = this.state

    let direcao
    if (colunaOrdenacao === coluna) {
      direcao = direcaoOrdenacao === 'desc' ? 'asc' : 'desc'
    } else {
      direcao = 'asc'
    }

    this.setState({
      colunaOrdenacao: coluna,
      direcaoOrdenacao: direcao
    }, () => this.ordernarComposicoes())
  }

  handleChangeDrawerCriarComposicao = () => this.setState({ exibirDrawerCriarComposicao: !this.state.exibirDrawerCriarComposicao })

  handleChangeDrawerImportarComposicao = () => this.setState({ exibirDrawerImportarComposicao: !this.state.exibirDrawerImportarComposicao })

  handleChangeDrawerAtualizarReferencia = () => this.setState({ exibirDrawerAtualizarReferencia: !this.state.exibirDrawerAtualizarReferencia })

  handleChangeFilter = (event) => {
    const { value } = event.target
    this.setState({ campoFiltro: value })
  }

  handleChange = (event) => {
    const { value } = event.target
    this.setState({ termoBusca: value })
  }

  handleKeyPress = (event) => {
    const { key } = event

    if (key === 'Enter') {
      this.buscar()
    }
  }

  onChangeDadosAlterados = (codigo) => {
    const { onAtualizarItemCurvaABCComposicaoDuplicada } = this.props

    this.handleChangeDrawerCriarComposicao()
    onAtualizarItemCurvaABCComposicaoDuplicada(codigo)
  }

  onComposicaoAdicionada = (codigo) => {
    const { onAtualizarItemCurvaABCComposicaoDuplicada } = this.props

    this.handleChangeDrawerImportarComposicao()
    onAtualizarItemCurvaABCComposicaoDuplicada(codigo)
  }

  onReferenciaAtualizada = () => {
    this.handleChangeDrawerAtualizarReferencia()
    window.location.reload()
  }

  novaComposicao = () => ({ codigoServico: '', descricaoOrcamento: '', unidadeOrcamento: '', observacao: '', precoUnitarioOrcamento: 0})

  buscar = () => {
    const { termoBusca, composicoesCopia, campoFiltro } = this.state

    if (_.isEmpty(termoBusca)) {
      this.setState({ composicoes: composicoesCopia })
    } else {
      const { handleAtualizarPagina } = this.props

      const composicoesFiltrados = composicoesCopia.filter((composicao) => {
        return 'all' === campoFiltro ? composicao.codigo.toUpperCase().includes(termoBusca.toUpperCase())
          || composicao.descricao.toUpperCase().includes(termoBusca.toUpperCase())
          || composicao.periodo.toUpperCase().includes(termoBusca.toUpperCase()):
          'tipoRodovia' === campoFiltro ? composicao[campoFiltro].toString().toUpperCase() === termoBusca.toUpperCase():
            composicao[campoFiltro].toString().toUpperCase().includes(termoBusca.toUpperCase())
      })

      this.setState({ composicoes: composicoesFiltrados })
      handleAtualizarPagina(0)
    }
  }

  montaNomeArquivo() {
    const { contexto } = this.props
    return 'Composicoes' +
      '_'+ contexto.empreendimento.cod +
      '_'+ contexto.obra.cod + '_' + contexto.orcamento.cod +
      '_'+ contexto.empreendimento.nome +
      '_'+ contexto.obra.nome +
      '_'+ contexto.orcamento.descricao
  }

  async carregarDetalhesComposicao() {
    let dados = new Array(0)
    const { contexto } = this.props

    this.setState({
      imprime: false,
      imprimindo: true,
      progressoImpressao: ' (0%)'
    })

    const composicoes = await context_httpget('precos/hierarquia-flat', contexto, null, true)
    const chunkSize = 500;
    let completedPromises = 0;

    for (let i = 0; i < composicoes.length; i += chunkSize) {
      const chunk = composicoes.slice(i, i + chunkSize);
      const promises = chunk.map(it => context_httpget(`precos/composicoes/detalhes?composicao=${it}`, contexto, null, true))

      try {
        const results = await Promise.all(promises.map((promise) => {  
          return promise.then(result => {
            completedPromises++
  
            this.setState({
              imprime: false,
              progressoImpressao: ' ('
                .concat((completedPromises/composicoes.length*100).toFixed(0)).concat('%)')
            })      
  
            return result
          })
        }))
  
        results.forEach(data => {
          dados.push({value: data.composicao, detalhes: data.detalhes, itemCurvaAbc: data.itemCurvaAbc})
        })        
  
      } catch (error) {
        showErrorMsg('Erro ao imprimir composições')
        this.setState({
          imprime: false,
          imprimindo: false,
          progressoImpressao: ''
        })
      }
    }
    
    if (completedPromises === composicoes.length) {
      this.setState({
        imprime: true,
        imprimindo: false,
        detalhesComposicao: dados,
        progressoImpressao: ''
      })
    }
  }

  handleClickPopover = (event) => {
    const { contexto } = this.props
    this.setState({
      anchorPopover: event.currentTarget,
      tituloRelatorio: 'Composições: '.concat( contexto.obra.nome )
        .concat(' - Orçamento - ').concat(contexto.orcamento.descricao).length < 100?
        'Composições: '.concat( contexto.obra.nome )
          .concat(' - Orçamento - ').concat(contexto.orcamento.descricao):
        'Composições: '.concat( contexto.obra.nome )
          .concat(' - Orçamento - ').concat(contexto.orcamento.descricao).substr(0,97).concat('...')
    })
  }

  handleClosePopover = () => {
    this.setState({
      anchorPopover: null
    })
  }

  render() {
    const { classes, pagina, registrosPorPagina, onChangePage, onChangeRowsPerPage, onExcluirComposicao, handleVerComposicaoAuxiliar, onAtualizarItemCurvaABCComposicaoDuplicada } = this.props
    const { composicoes, colunaOrdenacao, direcaoOrdenacao, exibirDrawerImportarComposicao, exibirDrawerCriarComposicao, exibirDrawerAtualizarReferencia, campoFiltro } = this.state

    return (
      <Fragment>
        <Box display='flex' justifyContent='space-between' alignItems='flex-end' my={2}>
          <BuscarComPaginacao
              placeholder='Buscar composição'
              onChange={(e) => this.handleChange(e)}
              onKeyPress={(e) => this.handleKeyPress(e)}
              onClick={() => this.buscar()}
              disable={false}
              filterOptions={[
                { label:'Código serviço' ,value: 'codigo' },
                { label:'Descrição' ,value: 'descricao' },
                { label:'Data referência' ,value: 'periodo' }]}
              filterField={campoFiltro}
              onChangeFilter={(e) => this.handleChangeFilter(e)}
          />
          <Box display='flex'>
            <Box mr={2}>
              <Button 
                variant="outlined"
                color="default"
                onClick={() => this.handleChangeDrawerAtualizarReferencia()}
              >
                Atualizar Referência
              </Button>
            </Box>
            <Box mr={2}>
              <Button 
                variant="outlined"
                color="default"
                onClick={() => this.handleChangeDrawerImportarComposicao()}>
                Importar Composição
              </Button>
            </Box>
            <Box mr={2}>
              <Button
                variant="outlined"
                color="default"
                onClick={this.handleClickPopover}
              >
                Imprimir Composições {this.state.progressoImpressao}
              </Button>
              <Popover
                id={'idPopover'}
                open={Boolean(this.state.anchorPopover)}
                anchorEl={this.state.anchorPopover}
                onClose={this.handleClosePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Box p={2}>
                  <FormControl>
                    <Typography variant='subtitle2'
                                htmlFor='visualizar'>Nome do relatório
                    </Typography>
                    <MesmaLinha>
                      <TextField
                        value={this.state.tituloRelatorio.length > 100?
                          this.state.tituloRelatorio.substr(0,100) : this.state.tituloRelatorio}
                        onChange={(event)=>{
                          this.setState({tituloRelatorio: event.target.value.substring(0,100)})
                        }}
                        multiline={true}
                        rowsMax={4}

                        variant='outlined'
                        style={{ marginRight: 12, width: 550, maxLength: 12}}
                        InputProps={{ maxLength: 12 ,
                          endAdornment:
                            <InputAdornment position='end'>
                              <Button
                                variant='contained'
                                color='primary'
                                title="imprimir"
                                onClick={() => {
                                  this.handleClosePopover()
                                  if (!this.state.imprimindo) {
                                    this.carregarDetalhesComposicao()
                                  } else {
                                    showAlertMsg('Por favor aguarde. Impressão em andamento.')
                                  }}}>
                                <PrintIcon/>
                              </Button>
                            </InputAdornment>
                        }}
                      />
                    </MesmaLinha>
                  </FormControl>
                </Box>

              </Popover>
            </Box>
            <Adicionar
              label='Nova composição'
              onClick={() => this.handleChangeDrawerCriarComposicao()}
            />
          </Box>
        </Box>
        {this.state.imprime && (
          <RelatorioComposicaoPdf
            items={this.state.detalhesComposicao}
            nomeArquivo={this.montaNomeArquivo()}
            titulo={this.state.tituloRelatorio}
            onPrint={() => this.setState({imprime: false})}
          />
        )}
        <Table className={classes.table} aria-label='simple table'>
          <CabecalhoComposicao
            colunaOrdenacao={colunaOrdenacao}
            direcaoOrdenacao={direcaoOrdenacao}
            handleChange={this.handleChangeOrdenacaoColuna}
          />
          <TableBody>
            {paginar(composicoes, pagina, registrosPorPagina).map((it) => (
              <ItemComposicaoRow
                key={it.codigo}
                composicaoComAuxiliares={it}
                onExcluirComposicao={onExcluirComposicao}
                handleVerComposicaoAuxiliar={handleVerComposicaoAuxiliar}
                onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
              />
            ))}
            {_.isEmpty(composicoes) && <td colSpan="100%"><Alert style={{marginTop: 4, marginBottom: 4}} severity='warning'>Nenhum item foi encontrado.</Alert></td>}
          </TableBody>
        </Table>
        <Paginacao
          total={composicoes.length}
          registrosPorPagina={registrosPorPagina}
          pagina={pagina}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
        {
          exibirDrawerAtualizarReferencia && (
            <CompositionProvider >
              <AtualizarReferencia
                onReferenciaAtualizada={() => this.onReferenciaAtualizada()}
                onCancelar={() => this.handleChangeDrawerAtualizarReferencia()}
              />
            </CompositionProvider>
          )
        }
        {
          exibirDrawerImportarComposicao && (
            <CompositionProvider onComposicaoAdicionada={this.onComposicaoAdicionada}>
              <ImportarComposicao
                value={this.novaComposicao()}
                onComposicaoAdicionada={this.onComposicaoAdicionada}
                onCancelar={() => this.handleChangeDrawerImportarComposicao()}
              />
            </CompositionProvider>
          )
        }
        {
          exibirDrawerCriarComposicao && (
            <CompositionProvider onDadosAlterados={this.onChangeDadosAlterados}>
              <CriarComposicao
                value={this.novaComposicao()}
                onDadosAlterados={this.onChangeDadosAlterados}
                onCancelar={() => this.handleChangeDrawerCriarComposicao()}
              />
            </CompositionProvider>
          )
        }
      </Fragment>)
  }
}

Composicoes.propTypes = {
  composicoes: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  pagina: PropTypes.number.isRequired,
  registrosPorPagina: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  handleAtualizarPagina: PropTypes.func.isRequired,
  handleVerComposicaoAuxiliar: PropTypes.func.isRequired,
  onExcluirComposicao: PropTypes.func.isRequired,
  onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.func,
  contexto: PropTypes.object
}

export default connect(mapStateToPropsContexto)(
  withStyles(styles)(Composicoes)
)

