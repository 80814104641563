import React, { Component } from 'react'
import { Button, Typography, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'
import { MENU_COMPOSICOES, MENU_COMPOSICOES_AVULSAS, MENU_EQUIPAMENTOS, MENU_MAOS_DE_OBRA, MENU_MATERIAIS, MENU_TRANSPORTES } from './preco'
import styles from '../styles'
import iconComposicao from '../../../../img/composicao.svg'
import iconComposicaoAvulsa from '../../../../img/composicaoAvulsa.svg'
import iconMaoObra from '../../../../img/mao-de-obra.svg'
import iconEquipamento from '../../../../img/equipamento.svg'
import iconMateriais from '../../../../img/materiais.svg'
import iconTransporte from '../../../../img/transporte.svg'

export class MenuPreco extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    menuSelecionado: PropTypes.string.isRequired,
    handleMenuSelecionado: PropTypes.func.isRequired
  }

  componentDidMount() {
    const { handleMenuSelecionado } = this.props;
    const selectedMenu = localStorage.getItem('selectedMenu');
    if (selectedMenu) {
      handleMenuSelecionado(selectedMenu);
    }
  }

  handleAtivarMenu = (nome) => {
    const { handleMenuSelecionado } = this.props;
    handleMenuSelecionado(nome);
    localStorage.setItem('selectedMenu', nome);
  }

  render () {
    const { classes, menuSelecionado } = this.props
    return (
      <div className={classes.menuListas}>
        <Button variant='text' className={menuSelecionado === MENU_COMPOSICOES ? classes.btnAbaAtivado : classes.btnAbasItensPreco} onClick={() => this.handleAtivarMenu(MENU_COMPOSICOES)}>
          <img className={classes.imgIconAbaPreco} src={iconComposicao} alt={'Composições'}/>
          <Typography className={classes.txtAbas}>Composições</Typography>
        </Button>
        <Button variant='text' className={menuSelecionado === MENU_COMPOSICOES_AVULSAS ? classes.btnAbaAtivado : classes.btnAbasItensPreco} onClick={() => this.handleAtivarMenu(MENU_COMPOSICOES_AVULSAS)}>
          <img className={classes.imgIconAbaPreco} src={iconComposicaoAvulsa} alt={'Composições Avulsas'}/>
          <Typography className={classes.txtAbas}>Composições Avulsas</Typography>
        </Button>
        <Button variant='text' className={menuSelecionado === MENU_MAOS_DE_OBRA ? classes.btnAbaAtivado : classes.btnAbasItensPreco} onClick={() => this.handleAtivarMenu(MENU_MAOS_DE_OBRA)}>
          <img className={classes.imgIconAbaPreco} src={iconMaoObra} alt={'Mão de Obra'}/>
          <Typography className={classes.txtAbas}>Mãos de Obra</Typography>
        </Button>
        <Button variant='text' className={menuSelecionado === MENU_MATERIAIS ? classes.btnAbaAtivado : classes.btnAbasItensPreco} onClick={() => this.handleAtivarMenu(MENU_MATERIAIS)}>
          <img className={classes.imgIconAbaPreco} src={iconMateriais} alt={'Materiais'}/>
          <Typography className={classes.txtAbas}>Materiais/Serviços</Typography>
        </Button>
        <Button variant='text' className={menuSelecionado === MENU_EQUIPAMENTOS ? classes.btnAbaAtivado : classes.btnAbasItensPreco} onClick={() => this.handleAtivarMenu(MENU_EQUIPAMENTOS)}>
          <img className={classes.imgIconAbaPreco} src={iconEquipamento} alt={'Equipamentos'}/>
          <Typography className={classes.txtAbas}>Equipamentos</Typography>
        </Button>
        <Button variant='text' className={menuSelecionado === MENU_TRANSPORTES ? classes.btnAbaAtivado : classes.btnAbasItensPreco} onClick={() => this.handleAtivarMenu(MENU_TRANSPORTES)}>
          <img className={classes.imgIconAbaPreco} src={iconTransporte} alt={'Transporte'}/>
          <Typography className={classes.txtAbas}>QR Transporte</Typography>
        </Button>
      </div>
    )
  }
}

export default withStyles(styles)(MenuPreco)
