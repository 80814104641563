import React, { Component } from 'react'
import TableCell from '@material-ui/core/TableCell'
import PropTypes from 'prop-types'
import IconeErro from '../../../../../comum/IconeErro'
import MesmaLinha from '../../../../../comum/MesmaLinha'
import styles from '../../styles'
import withStyles from '@material-ui/core/styles/withStyles'

class CelulaUnidadeCurvaAbc extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    exibir: PropTypes.bool.isRequired,
    classes: PropTypes.object
  }

  mensagemUnidadeDiferente () {
    const { item } = this.props
    return item && `Unidade de Referência: ${item.unidadeReferencia}`
  }

  render () {
    const { item, classes, exibir } = this.props

    const unidadeDiferente = item.unidadeDiferenteReferenciaPreco
    const className = unidadeDiferente ? classes.erroSublinhado : ''

    return exibir && item && (
      <TableCell>
        <MesmaLinha className={className}>
          <IconeErro exibir={unidadeDiferente} mensagem={this.mensagemUnidadeDiferente()} />
          <p className={classes.textoUnidadeABC}>{item.unidadeOrcamento.replace(/"/g, '')}</p>
        </MesmaLinha>
      </TableCell>
    )
  }
}

export default withStyles(styles)(CelulaUnidadeCurvaAbc)
