import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../styles'
import { context_httpget, context_httpput } from '../../../../../js/httpRequest'
import Personalizadas from './personalizadas/Personalizadas'
import Tematicas from './tematicas/Tematicas'
import CustomScroller from 'react-custom-scroller'
import { connect } from 'react-redux'
import _ from 'lodash'
import { PERSONALIZADA, TEMATICA } from '../analise-conteudo'

const AnaliseConteudoContent = ({ classes, contexto, temaAtivo, personalizadaAtiva }) => {
  const [expressoes, setExpressoes] = useState(null)
  const [formulas, setFormulas] = useState(null)
  const [tipologiasCombinadas, setTipologiasCombinadas] = useState(null)

  useEffect(() => {
    const recuperarPersonalizadas = async () => {
      const { expressoes, formulas, combinadas } = await context_httpget('analise-conteudo/personalizadas/play', contexto)
      setExpressoes(expressoes)
      setFormulas(formulas)
      setTipologiasCombinadas(combinadas)
    }

    if (contexto && _.isNil(temaAtivo)) {
      recuperarPersonalizadas()
    }
  }, [contexto, personalizadaAtiva])

  useEffect(() => {
    const recuperarTema = async () => {
      const { expressoes, formulas, combinadas } = await context_httpget(`analise-conteudo/tematicas/${temaAtivo.codigo}/play`, contexto)
      setExpressoes(expressoes)
      setFormulas(formulas)
      setTipologiasCombinadas(combinadas)
    }

    if (contexto && personalizadaAtiva === false) {
      recuperarTema()
    }
  }, [temaAtivo])

  const handleRemoverExpressaoDoEstado = (expressaoExcluir) =>
    setExpressoes(expressoes.filter((it) => it.codigo !== expressaoExcluir.codigo))

  const handleRecarregarExpressoes = async () => {
    const expressoes = personalizadaAtiva
      ? await context_httpget(`analise-conteudo/expressoes/play?tipoAnalise=${PERSONALIZADA}`, contexto, null, true)
      : await context_httpget(`analise-conteudo/expressoes/play?codTema=${temaAtivo.codigo}&tipoAnalise=${TEMATICA}`, contexto, null, true)
    setExpressoes(expressoes)
  }

  const handleRecarregarFormulas = async () => {
    const formulas = personalizadaAtiva
      ? await context_httpget(`analise-conteudo/formulas/play?tipoAnalise=${PERSONALIZADA}`, contexto, null, true)
      : await context_httpget(`analise-conteudo/formulas/play?codTema=${temaAtivo.codigo}&tipoAnalise=${TEMATICA}`, contexto, null, true)
    setFormulas(formulas)
  }

  const handleRemoverFormulaDoEstado = (formulaExcluir) =>
    setFormulas(formulas.filter((it) => it.codigo !== formulaExcluir.codigo))

  const handleRecarregarTipologiaCombinadas = async () => {
    const tipologiasCombinadas = personalizadaAtiva
      ? await context_httpget(`analise-conteudo/tipologias-combinadas/play?tipoAnalise=${PERSONALIZADA}`, contexto, null, true)
      : await context_httpget(`analise-conteudo/tipologias-combinadas/play?codTema=${temaAtivo.codigo}&tipoAnalise=${TEMATICA}`, contexto, null, true)
    setTipologiasCombinadas(tipologiasCombinadas)
  }

  const handleRemoverTipologiaCombinadaDoEstado = (combinadaExcluir) =>
    setTipologiasCombinadas(tipologiasCombinadas.filter((it) => it.codigo !== combinadaExcluir.codigo))

  const handleAtualizarDisponibilidadeExpressao = (disponivel, codigo) =>
    setExpressoes(expressoes.map((it) => it.codigo === codigo ? { ...it, seDisponivel: disponivel } : it))

  const handleAtualizarDisponibilidadeFormula = (disponivel, codigo) =>
    setFormulas(formulas.map((it) => it.codigo === codigo ? { ...it, seDisponivel: disponivel } : it))

  const handleChangeOperadorTipologiaCombinada = async (codTipologiaCombinada, operador) => {
    const tipoAnalise = personalizadaAtiva ? PERSONALIZADA : TEMATICA
    const operadorData = { operador: operador.label.toUpperCase() }
    const tipologia = await context_httpput(`analise-conteudo/tipologias-combinadas/${codTipologiaCombinada}/operador?tipoAnalise=${tipoAnalise}`, contexto, operadorData)
    setTipologiasCombinadas(
      tipologiasCombinadas.map((it) =>
        it.codigo === codTipologiaCombinada
          ? { it, ...tipologia }
          : it
      )
    )
  }

  return (
    <CustomScroller className={classes.gridSidebar}>
      <Box p={2}>
        {personalizadaAtiva && (
          <Personalizadas
            classes={classes}
            expressoes={expressoes != null ? expressoes : []}
            formulas={formulas != null ? formulas : []}
            tipologiasCombinadas={tipologiasCombinadas != null ? tipologiasCombinadas : []}
            onRecarregarExpressoes={handleRecarregarExpressoes}
            onRemoverExpressaoDoEstado={handleRemoverExpressaoDoEstado}
            onRecarregarFormulas={handleRecarregarFormulas}
            onRemoverFormulaDoEstado={handleRemoverFormulaDoEstado}
            onRecarregarTipologiaCombinadas={handleRecarregarTipologiaCombinadas}
            onRemoverTipologiaCombinadaDoEstado={handleRemoverTipologiaCombinadaDoEstado}
            onChangeOperadorTipologiaCombinada={handleChangeOperadorTipologiaCombinada}
          />
        )}
        {temaAtivo && (
          <Tematicas
            classes={classes}
            temaAtivo={temaAtivo}
            expressoes={expressoes != null ? expressoes : []}
            formulas={formulas != null ? formulas : []}
            tipologiasCombinadas={tipologiasCombinadas != null ? tipologiasCombinadas : []}
            onRecarregarExpressoes={handleRecarregarExpressoes}
            onRecarregarFormulas={handleRecarregarFormulas}
            onRecarregarTipologiaCombinadas={handleRecarregarTipologiaCombinadas}
            onRemoverExpressaoDoEstado={handleRemoverExpressaoDoEstado}
            onRemoverFormulaDoEstado={handleRemoverFormulaDoEstado}
            onRemoverTipologiaCombinadaDoEstado={handleRemoverTipologiaCombinadaDoEstado}
            onAtualizarDisponibilidadeExpressao={handleAtualizarDisponibilidadeExpressao}
            onAtualizarDisponibilidadeFormula={handleAtualizarDisponibilidadeFormula}
            onChangeOperadorTipologiaCombinada={handleChangeOperadorTipologiaCombinada}
          />
        )}
      </Box>
    </CustomScroller>
  )
}

AnaliseConteudoContent.propTypes = {
  classes: PropTypes.object,
  contexto: PropTypes.object,
  temaAtivo: PropTypes.object,
  personalizadaAtiva: PropTypes.bool
}

export const mapStateToProps = ({ contexto }) => ({ contexto })
export default connect(mapStateToProps)(withStyles(styles)(AnaliseConteudoContent))
