import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/styles'
import StyledDrawer from '../../../../../comum/StyledDrawer'
import styles from '../styles'
import FormularioDadosBasicosComposicao from './FormularioDadosBasicosComposicao'
import PropTypes from 'prop-types'
import { useComposition } from '../../../../../../hooks/useComposition'

const CriarComposicao = ({ classes, value, onCancelar }) => {
  const { setDefaultState } = useComposition()

  useEffect(() => {
    const carregarDados = () => {
      setDefaultState(prevState => ({
        ...prevState,
        codigo: value.codigoServico,
        descricao: value.descricaoOrcamento,
        unidade: value.unidadeOrcamento,
        observacao: value.observacao,
        precoUnitarioOrcamento: value.precoUnitarioOrcamento
      }))
    }
    carregarDados()
  }, [])

  return (
    <div>
      <StyledDrawer
        PaperProps={{ className: classes.drawerEdicao }}
        open
        anchor='right'
      >
        <FormularioDadosBasicosComposicao
          codigoEditavel
          criarComposicao
          idItemCurvaAbc={value.id}
          closeDrawerEdicao={onCancelar}
        />
      </StyledDrawer>
    </div>
  )
}

CriarComposicao.propTypes = {
  classes: PropTypes.object,
  value: PropTypes.object.isRequired,
  onCancelar: PropTypes.func.isRequired
}

export default withStyles(styles)(CriarComposicao)
