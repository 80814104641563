export const getMenuItems = () => [
  { label: 'Menu item 1', path: '/' },
  { label: 'Menu item 2', path: '/' },
  { label: 'Menu item N', path: '/' }
]

let browserHistory

export const setBrowserHistory = (history) => { browserHistory = history }

export const getBrowserHistory = () => browserHistory

export const codRecursoComputacional = 201

export const authenticationUrl = process.env.NODE_ENV === 'production'
  ? 'http://autenticacao-rest-prod.cloudapps.tcu.gov.br/auth/token-jwt'
  : 'http://autenticacao-rest.desenv.rancher.tcu.gov.br/auth/token-jwt'

export const loginPorTokenLoginIntegradoUrl = '/api/auth'

export const createDataToAuthentication = (username, password) => ({
  codRecursoComputacional,
  username,
  password
})
