import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import axios from 'axios'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import { CAMPO_FILTRO_TIPOLOGIAS_COMBINADAS, CAMPO_NOME_DA_TIPOLOGIA_COMBINADA, CAMPO_TIPOLOGIA_COMBINADA_POR_UNIDADE, sleepAutocomplete } from '../../analise-conteudo/analise-conteudo'
import { SLOW_REQUEST_CONFIG } from '../../../../../js/operationProgressUtil'
import CampoFiltro from '../../analise-conteudo/content/comum/campos/CampoFiltro'
import CampoUnidades from '../../analise-conteudo/content/comum/campos/CampoUnidades'
import CampoEmpreendimentos from '../../analise-conteudo/content/comum/campos/CampoEmpreendimentos'
import CampoOrcamentos from '../../analise-conteudo/content/comum/campos/CampoOrcamentos'
import CampoBusca from '../../analise-conteudo/content/comum/campos/CampoBusca'
import buscarOrcamentos from '../../../../../actions/orcamentos/buscarOrcamentos'
import PaperBox from '../../../../comum/PaperBox'
import { showSuccessMsg, textNormalize } from '../../../../../js/util'
import TabelaVincularTipologiasCombinadas from './TabelaVincularTipologiasCombinadas'
import { context_httppost } from '../../../../../js/httpRequest'

const VincularTipologiaCombinada = ({
  classes,
  contexto,
  codigoTema,
  tipologiasVinculadas,
  onVincularFormulasAoTema,
  onVincularExpressoesAoTema,
  onVincularTipologiasCombinadas,
  onFecharDrawer
}) => {
  const [filtro, setFiltro] = useState(CAMPO_TIPOLOGIA_COMBINADA_POR_UNIDADE)
  const [unidade, setUnidade] = useState(null)
  const [empreendimento, setEmpreendimento] = useState(null)
  const [empreendimentos, setEmpreendimentos] = useState(null)
  const [tipologiasCombinadas, setTipologiasCombinadas] = useState([])
  const [tipologiasCombinadasFilter, setTipologiasCombinadasFilter] = useState([])
  const [orcamentos, setOrcamentos] = useState([])
  const [tipologiasCombinadasSelecionadas, setTipologiasCombinadasSelecionadas] = useState([])
  const [orcamento, setOrcamento] = useState(null)
  const [termo, setTermo] = useState('')
  const [carregandoOrcamentos, setCarregandoOrcamentos] = useState(false)

  useEffect(() => {
    buscarTipologiasCombinadas(empreendimento, orcamento)
    setTipologiasCombinadasSelecionadas(tipologiasVinculadas.map(it => it.codigo))
  }, [contexto])

  useEffect(() => {
    const buscarEmpreendimentosPorUnidade = async () => {
      const { data } = await axios.get(`/api/empreendimentos/unidade/${unidade.cod}`, SLOW_REQUEST_CONFIG)
      setEmpreendimentos(data)
    }

    if (unidade && empreendimentos === null) {
      unidade && buscarEmpreendimentosPorUnidade()
    }
  }, [unidade])

  useEffect(() => {
    const buscarOrcamentos = async () => {
      setCarregandoOrcamentos(true)
      if (empreendimento) {
        const { data } = await axios.get(`/api/empreendimentos/${empreendimento.cod}/orcamentos`, SLOW_REQUEST_CONFIG)
        await sleepAutocomplete(1e2)
        setOrcamentos(data)
        setCarregandoOrcamentos(false)
      }
    }

    empreendimento && buscarOrcamentos()
  }, [empreendimento])

  useEffect(() => {
    orcamento && buscarTipologiasCombinadas(empreendimento, orcamento)
  }, [orcamento])

  const buscarTipologiasCombinadas = async (empreendimento, orcamento) => {
    if (empreendimento && orcamento) {
      const { data } = await axios.get(`/api/empreendimentos/${empreendimento.cod}/obras/${orcamento.codObra}/orcamentos/${orcamento.cod}/analise-conteudo/tipologias-combinadas`, SLOW_REQUEST_CONFIG)
      setTipologiasCombinadas(data)
      setTipologiasCombinadasFilter(data)
    } else {
      setTipologiasCombinadas([])
      setTermo('')
    }
  }

  const handleBuscarTipologiaCombinadaPeloTermo = async () => {
    const { data } = await axios.get(`/api/tipologias-combinadas?termo=${termo}`, SLOW_REQUEST_CONFIG)
    if (data) {
      setTipologiasCombinadasFilter(data)
    }
  }

  const handleFiltrarTipologiaCombinadaPorTermo = () => {
    if (termo.length) {
      setTipologiasCombinadasFilter(
        tipologiasCombinadas.filter(
          (tipologia) => textNormalize(tipologia.nome).toLowerCase().search(textNormalize(termo).toLowerCase()) !== -1
        )
      )
    } else {
      setTipologiasCombinadasFilter(tipologiasCombinadas)
    }
  }

  const handleVincular = async () => {
    const tipologias = tipologiasCombinadasSelecionadas.filter((it) => tipologiasVinculadas.map((tipologia) => tipologia.codigo).indexOf(it) === -1)
    const { expressoes, formulas, tipologiasCombinadas } = await context_httppost(`analise-conteudo/temas/${codigoTema}/tipologias-combinadas/vincular`, contexto, tipologias)
    if (expressoes || formulas || tipologiasCombinadas) {
      showSuccessMsg('Dados registrados')
      onVincularExpressoesAoTema(expressoes)
      onVincularFormulasAoTema(formulas)
      onVincularTipologiasCombinadas(tipologiasCombinadas)
      onFecharDrawer()
    }
  }
  const handleSelecionarTipologiaCombinada = (event, codTipologiaCombinada) => {
    if (event.target.checked) {
      setTipologiasCombinadasSelecionadas([
        ...tipologiasCombinadasSelecionadas,
        codTipologiaCombinada
      ])
    } else {
      setTipologiasCombinadasSelecionadas(
        tipologiasCombinadasSelecionadas.filter(codigo => codigo !== codTipologiaCombinada)
      )
    }
  }

  const handleCampoFiltro = (valor) => {
    setFiltro(valor)
    setTermo('')
    setTipologiasCombinadasFilter([])
  }

  const handleCampoUnidade = (unidade) => {
    if (unidade) {
      setUnidade(unidade)
    }
  }

  return (
    <React.Fragment>
      <Typography variant='h6' style={{ marginBottom: 20 }}>
        Vincular tipologias combinadas ao tema
      </Typography>
      <CampoFiltro
        filtro={filtro}
        values={CAMPO_FILTRO_TIPOLOGIAS_COMBINADAS}
        onFiltroChange={handleCampoFiltro}
      />

      { filtro === CAMPO_TIPOLOGIA_COMBINADA_POR_UNIDADE && (
        <React.Fragment>
          <CampoUnidades
            unidade={unidade}
            unidadeEmpty={false}
            unidadeUsuarioLogadoPreSelecionada
            onUnidadeChange={handleCampoUnidade}
          />

          <CampoEmpreendimentos
            empreendimentos={empreendimentos === null ? [] : empreendimentos}
            empreendimento={empreendimento}
            onCampoEmpreendimento={setEmpreendimento}
          />

          <CampoOrcamentos
            orcamentos={orcamentos}
            onCampoOrcamento={setOrcamento}
            carregandoOrcamentos={carregandoOrcamentos}
          />
        </React.Fragment>)
      }

      { filtro === CAMPO_NOME_DA_TIPOLOGIA_COMBINADA && (
        <CampoBusca
          value={termo}
          name='termo'
          placeholder='Digite o nome da tipologia combinada'
          buttonName='Pesquisar'
          onCampoChange={setTermo}
          onClick={handleBuscarTipologiaCombinadaPeloTermo}
        />)
      }

      <PaperBox style={{ marginTop: 15 }}>
        { filtro !== CAMPO_NOME_DA_TIPOLOGIA_COMBINADA && (
          <CampoBusca
            classes={classes}
            value={termo}
            name='termo'
            placeholder='Filtrar por nome'
            buttonName='Filtrar'
            onCampoChange={setTermo}
            onClick={handleFiltrarTipologiaCombinadaPorTermo}
          />)
        }

        <Typography variant='h6' className={classes.subtituloAssociarTema}>
          Tipologias Combinadas - Marque o que deseja associar ao tema
        </Typography>
        <TabelaVincularTipologiasCombinadas
          classes={classes}
          tipologiasCombinadas={tipologiasCombinadasFilter}
          tipologiasSelecionadas={tipologiasCombinadasSelecionadas}
          onSelecionarTipologia={handleSelecionarTipologiaCombinada}
        />
      </PaperBox>

      <Box mt={2}>
        <Button
          variant='contained'
          color='primary'
          style={{ marginRight: 10 }}
          onClick={() => handleVincular()}
        >
          Associar ao Tema
        </Button>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => onFecharDrawer()}
        >
          Cancelar
        </Button>
      </Box>
    </React.Fragment>
  )
}

VincularTipologiaCombinada.propTypes = {
  classes: PropTypes.object,
  contexto: PropTypes.object,
  codigoTema: PropTypes.number,
  tipologiasVinculadas: PropTypes.array,
  onVincularFormulasAoTema: PropTypes.func.isRequired,
  onVincularExpressoesAoTema: PropTypes.func.isRequired,
  onVincularTipologiasCombinadas: PropTypes.func.isRequired,
  onFecharDrawer: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  contexto: state.contexto,
  orcamentos: state.orcamentos
})
export default connect(mapStateToProps, { buscarOrcamentos })(withRouter(VincularTipologiaCombinada))
