import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import { converterMoedaBRParaNumero, formatarNumeroParaMoedaBR, handleCampoNumericoChange, mapStateToPropsContexto, seStringVazia, showSuccessMsg } from '../../../../../../../../js/util'
import { context_httpput } from '../../../../../../../../js/httpRequest'
import Box from '@material-ui/core/Box'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import { connect } from 'react-redux'
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation'
import TituloVoltar from '../../../../../../../comum/TituloVoltar'
import Grid from '@material-ui/core/Grid'
import PesquisarComposicaoAuxiliarReferenciaPreco from '../PesquisarComposicaoAuxiliarReferenciaPreco'
import Link from '@material-ui/core/Link'
import StyledDrawer from '../../../../../../../comum/StyledDrawer'
import { withStyles } from '@material-ui/core'
import _ from 'lodash'
import styles from '../../../styles'

class EditarTransporteComposicao extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string.isRequired,
    transporte: PropTypes.object.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired,
    contexto: PropTypes.object,
    classes: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      descricao: '',
      descricaoEmpty: false,
      quantidade: '',
      quantidadeEmpty: false,
      unidade: '',
      unidadeEmpty: false,
      dmt: '',
      dmtEmpty: false,
      openDrawerServicoTransporte: false,
      servicoTransporte: null
    }
    this.handleSalvarClick = this.handleSalvarClick.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
  }

  componentDidMount () {
    if (this.props.transporte) {
      this.copiarDadosParaEdicao()
    }
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.transporte !== this.props.transporte) {
      this.copiarDadosParaEdicao()
    }
  }

  handleKeyDown(event) {
    if (event.key === 'Enter') {
      this.handleSalvarClick()
    } else if (event.key === 'Escape') {
      this.props.onCancelar()
    }
  }

  copiarDadosParaEdicao() {
    const { transporte } = this.props
    const { composicaoTransporte } = transporte
    this.setState({
      descricao: transporte.descricao,
      quantidade: formatarNumeroParaMoedaBR(transporte.quantidade, 7, 7),
      unidade: transporte.unidade,
      dmt: transporte.dmt ? formatarNumeroParaMoedaBR(transporte.dmt, 2, 5) : '0,00',
      servicoTransporte: {
        codigo: composicaoTransporte.codigo,
        uf: composicaoTransporte.uf,
        mes: composicaoTransporte.mes,
        ano: composicaoTransporte.ano,
        sistema: composicaoTransporte.sistemaReferencia,
      }
    })
  }

  handleCampoTextoChange (event, campo) {
    const state = {}
    state[campo] = event.target.value
    state[`${campo}Empty`] = seStringVazia(event.target.value)
    this.setState(state)
  }

  async handleSalvarClick () {
    const { descricao, quantidade, unidade, dmt, servicoTransporte } = this.state
    const descricaoEmpty = seStringVazia(descricao)
    const quantidadeEmpty = seStringVazia(quantidade)
    const unidadeEmpty = seStringVazia(unidade)
    const dmtEmpty = seStringVazia(dmt)

    if ([descricaoEmpty, quantidadeEmpty, unidadeEmpty, dmtEmpty].some(Boolean)) {
      this.setState({ descricaoEmpty, quantidadeEmpty, unidadeEmpty, dmtEmpty })
    } else {
      const { contexto, codigoComposicao, onDadosAlterados, transporte } = this.props
      const dados = {
        transporte,
        descricao,
        quantidade: converterMoedaBRParaNumero(quantidade),
        unidade,
        dmt: converterMoedaBRParaNumero(dmt),
        servicoTransporte: (servicoTransporte && servicoTransporte.sistema === 'SICRO2') ? this.getServicoTransporte(servicoTransporte) : null
      }

      const url = `precos/editar-transporte?composicao=${codigoComposicao}`
      const resultado = await context_httpput(url, contexto, dados)
      if (resultado) {
        showSuccessMsg('Dados do transporte na composição alterados com sucesso.')
        onDadosAlterados()
      }
    }
  }

  getServicoTransporte = (servicoTransporte) => {
    return {
      codigo: servicoTransporte.codigo,
      uf: servicoTransporte.uf,
      mes: servicoTransporte.mes,
      ano: servicoTransporte.ano,
      sistema: servicoTransporte.sistema,
    }
  }

  handleVincularComposicao = (data) => {
  
    this.setState({
      openDrawerServicoTransporte: false,
      servicoTransporte: data
    })
  }

  render () {
    const { codigoComposicao, transporte, onCancelar, classes } = this.props

    const {
      descricao,
      descricaoEmpty,
      quantidade,
      quantidadeEmpty,
      unidade,
      unidadeEmpty,
      dmt,
      dmtEmpty,
      openDrawerServicoTransporte,
      servicoTransporte
    } = this.state

    return (
      <div>
        <TituloVoltar onClick={onCancelar} label='Editar Transporte da Composição'/>
        <Box mt={2}>
          <Typography variant='overline'>{ transporte.codigoInsumoTransportado } - { transporte.descricao }</Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item md={11}>
            <FormControl variant='outlined' fullWidth>
              <Typography variant='subtitle1'>Descrição</Typography>
              <TextField
                variant='outlined'
                multiline
                rows={3}
                value={descricao}
                error={descricaoEmpty}
                onChange={(e) => this.handleCampoTextoChange(e, 'descricao')}
              />
            </FormControl>
          </Grid>

          <Grid item md={3}>
            <FormControl variant='outlined'>
              <Typography variant='subtitle1'>Quantidade</Typography>
              <TextField
                variant='outlined'
                value={quantidade}
                error={quantidadeEmpty}
                onChange={(e) => handleCampoNumericoChange(e, (e) => this.handleCampoTextoChange(e, 'quantidade'))}
              />
            </FormControl>
          </Grid>

          <Grid item md={3}>
            <FormControl variant='outlined'>
              <Typography variant='subtitle1'>Unidade</Typography>
              <TextField
                variant='outlined'
                value={unidade}
                error={unidadeEmpty}
                onChange={(e) => this.handleCampoTextoChange(e, 'unidade')}
              />
            </FormControl>
          </Grid>

          <Grid item md={2}>
            <FormControl variant='outlined'>
              <Typography variant='subtitle1'>DMT</Typography>
              <TextField
                variant='outlined'
                value={dmt}
                error={dmtEmpty}
                onChange={(e) => handleCampoNumericoChange(e, (e) => this.handleCampoTextoChange(e, 'dmt'))}
              />
            </FormControl>
          </Grid>

          { transporte.composicaoTransporte.sistemaReferencia === 'SICRO2' && (
              <Grid item md={3}>
                <Typography variant='subtitle1'>
                  Código Serviço Transporte: <b>{_.isNil(servicoTransporte) ? transporte.composicaoTransporte.codigo : servicoTransporte.codigo}</b>
                </Typography>
                <Link
                  color='primary'
                  onClick={() => this.setState({ openDrawerServicoTransporte: true })}
                  style={{ alignItems: 'center', display: 'flex' }}
                >
                  <EmojiTransportationIcon /> Informar serviço transporte
                </Link>
              </Grid>
            )
          }
        </Grid>

        <Box mt={3}>
          <MesmaLinha mt={4}>
            <Button variant='contained' color='primary' onClick={this.handleSalvarClick}>Salvar</Button>
            <Button variant='text' color='primary' onClick={onCancelar}>Cancelar</Button>
          </MesmaLinha>
        </Box>

        {
          openDrawerServicoTransporte && (
            <StyledDrawer
              PaperProps={{ className: classes.drawerEdicao }}
              open
              anchor='right'>
              <PesquisarComposicaoAuxiliarReferenciaPreco
                codigoComposicao={codigoComposicao}
                onCancelar={() => this.setState({openDrawerServicoTransporte: false})}
                onVincularComposicao={this.handleVincularComposicao}
              />
            </StyledDrawer>
          )
        }
      </div>
    )
  }
}

export default connect(mapStateToPropsContexto)(withStyles(styles)(EditarTransporteComposicao))
