const styles = () => ({
  editarOrcamentoBox: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr'
  },

  caixaApoio: {
    paddingTop: 16,
    paddingBottom: 16,
    marginRight: 24
  },

  grupoDadosIniciais: {
    minHeight: 490,
    position: 'relative',
    '& hr': {
      position: 'absolute',
      bottom: 0,
      left: '-24px',
      width: 'calc(100% + 40px)'
    }
  },

  grupoInfoGeral: {
    position: 'relative',
    padding: '16px 0 24px',
    '& hr': {
      position: 'absolute',
      bottom: 0,
      left: '-24px',
      width: 'calc(100% + 40px)'
    }
  },

  grupoInfoGeral2: {
    position: 'relative',
    padding: '10px 0 0',
    minHeight: 397,
    '& hr': {
      position: 'absolute',
      bottom: 0,
      left: '-24px',
      width: 'calc(100% + 40px)'
    }
  },

  hackgrupoInfoGeral: {
    minHeight: 365
  },

  grupoPreco: {
    height: 460,
    position: 'relative',
    paddingTop: 16
  },

  grupoReferencia: {
    display: 'flex',
    gap: 12,
    flexWrap: 'wrap',
    alignItems: 'flex-end'
  },

  textoInstrucoes: {
    marginTop: 12
  },

  campoFormulario: {
    marginBottom: 12,
    minWidth: 250,
    maxWidth: '90%'
  },

  botaoSalvar: {
    float: 'right'
  },

  botaoSalvarBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 24,
    width: '100%'
  },

  doisCamposFormulario: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 510
  },

  label: {
    background: 'white'
  },
  nomeArquivo: {
    width: 500,
    background: 'rgb(231,231,231)',
    marginTop: 12,
    display: 'flex',
    padding: '2px 4px',
    justifyContent: 'space-between'
  },

  botaoAdicionarReferencia: {
    marginTop: 12,
    marginBottom: 12
  },

  // Resumo arquivo
  resumo: {
    borderStyle: 'solid',
    borderColor: '#C4C4C4',
    borderWidth: 1,
    borderLeftWidth: 3,
    padding: 8,
    marginTop: 4,
    marginBottom: 12,
    width: 400
  },
  doneIcon: {
    verticalAlign: 'middle',
    color: 'green'
  },
  warningIcon: {
    verticalAlign: 'middle',
    color: '#f70',
    fontSize: 10
  },
  linhasNaoOrcamentarias: {
    color: '#f70'
  },
  linhasOrcamentarias: {
    color: 'green'
  }
})

export default styles
