const styles = () => ({
  tituloModal: {
    fontSize: '1.4rem',
    fontFamily: 'Roboto',
    fontWeight: 400,
    letterSpacing: '0.00735em',
    color: '#fff',
    marginBottom: 0
  },
  linhaItem: {
    backgroundColor: 'white',
    cursor: 'pointer',
    '&:hover': {
      background: 'rgba(67, 133, 191, 0.3)'
    }
  },
  linhaItemSelecionada: {
    backgroundColor: '#4a4a4a',
    color: '#fff !important',
    fontWeight: 'bold',
    cursor: 'pointer'
  }
})

export default styles
