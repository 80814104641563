import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import styles from './MessagesBarStyles'
import { dismissMessage } from '../../actions/messagesActions'

const MessagesBar = (props) => {
  const { message } = props.messages
  if(_.isNil(message) || _.isNil(!message.msgText)) {
    return null
  }
  const { classes } = props

  const open = Boolean(message)
  const className = open ? classes[message.type] : null
  const msgText = open ? message.msgText : null
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={open}
      autoHideDuration={3000}
      onClose={props.dismissMessage}
      message={<span id='message-id' className={className}>{msgText}</span>}
      action={[
        <IconButton
          key='close'
          aria-label='Close'
          color='inherit'
          style={{ padding: 8 }}
          onClick={props.dismissMessage}>
          <CloseIcon className={classes.close} />
        </IconButton>
      ]}
    />
  )
}

MessagesBar.propTypes = {
  classes: PropTypes.object,
  messages: PropTypes.object.isRequired,
  dismissMessage: PropTypes.func
}

const materialUIEnhance = withStyles(styles)(MessagesBar)

const mapStateToProps = ({ messages }) => ({ messages })

export default connect(mapStateToProps, { dismissMessage })(materialUIEnhance)
