import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import MesmaLinha from '../../../../../../../comum/MesmaLinha'
import { VARIAVEIS_ANALISE_TIPOLOGIA } from '../../../../analise-conteudo'
import styles from '../../../../styles'
import RegrasTipologiaComum from '../../../comum/tipologia/RegrasTipologiaComum'

const RegraTipologiaExpressao = ({
  classes,
  variavelAnalise,
  tipoComparacao,
  criterioComparacao,
  valorComparacao,
  valorComparacaoEmpty,
  valorComparacaoEntre,
  valorComparacaoEntreEmpty,
  tipoMedidaDispersao,
  valorMedidaDispersao,
  valorMedidaDispersaoEmpty,
  onCampoChange,
  onCampoTipoComparacaoChange,
  onValidarCampoEntre
}) => {
  return (
    <Box my={2}>
      <Typography gutterBottom>Regra da tipologia</Typography>
      <MesmaLinha>
        <TextField
          select
          value={variavelAnalise}
          onChange={(e) => onCampoChange(e, 'variavelAnalise')}
          variant='outlined'
          className={classes.marginCriterios}
        >
          {VARIAVEIS_ANALISE_TIPOLOGIA.map((it) =>
            (<MenuItem key={it.value} value={it}>{it.label}</MenuItem>))
          }
        </TextField>

        <RegrasTipologiaComum
          tipoComparacao={tipoComparacao}
          criterioComparacao={criterioComparacao}
          valorComparacao={valorComparacao}
          valorComparacaoEmpty={valorComparacaoEmpty}
          valorComparacaoEntre={valorComparacaoEntre}
          valorComparacaoEntreEmpty={valorComparacaoEntreEmpty}
          tipoMedidaDispersao={tipoMedidaDispersao}
          valorMedidaDispersao={valorMedidaDispersao}
          valorMedidaDispersaoEmpty={valorMedidaDispersaoEmpty}
          onCampoChange={onCampoChange}
          onCampoTipoComparacaoChange={onCampoTipoComparacaoChange}
          onValidarCampoEntre={onValidarCampoEntre}
        />
      </MesmaLinha>
    </Box>
  )
}

RegraTipologiaExpressao.propTypes = {
  classes: PropTypes.object,
  variavelAnalise: PropTypes.object.isRequired,
  tipoComparacao: PropTypes.object.isRequired,
  criterioComparacao: PropTypes.object.isRequired,
  valorComparacao: PropTypes.string.isRequired,
  valorComparacaoEmpty: PropTypes.bool.isRequired,
  valorComparacaoEntre: PropTypes.string,
  valorComparacaoEntreEmpty: PropTypes.bool,
  tipoMedidaDispersao: PropTypes.object,
  valorMedidaDispersao: PropTypes.string,
  valorMedidaDispersaoEmpty: PropTypes.bool,
  onCampoChange: PropTypes.func.isRequired,
  onCampoTipoComparacaoChange: PropTypes.func,
  onValidarCampoEntre: PropTypes.func.isRequired
}

export default withStyles(styles)(RegraTipologiaExpressao)
