import React, { useState } from 'react'
import { Link, TableCell, TableRow } from '@material-ui/core'
import { possuiSubComposicao } from '../../preco'
import { ReactComponent as Tree } from '../../../../../../img/treeTr.svg'
import PropTypes from 'prop-types'
import StyledDrawer from '../../../../../comum/StyledDrawer'
import RelatorioComposicao from '../../../relatorios/precos/composicao/RelatorioComposicao'

const ItemComposicaoAuxiliarRow = ({ composicaoComAuxiliares, handleVerComposicaoAuxiliar, tipoComposicao, classes, onAtualizarItemCurvaABCComposicaoDuplicada }) => {
  const [openDrawer, setOpenDrawer] = useState({ right: false })

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return
    }
    setOpenDrawer({ ...openDrawer, [anchor]: open })
  }

  return (
    <>
      <TableRow key={composicaoComAuxiliares.codigo}>
        <TableCell component='th' scope='row' >
          <span className={classes.iconeComTexto}> <Tree />{composicaoComAuxiliares.codigo}</span>
        </TableCell>
        <TableCell align='left'>
          <Link align='left' onClick={toggleDrawer('right', true)}> {composicaoComAuxiliares.descricao} </Link>
        </TableCell>
        <TableCell align='right'>{composicaoComAuxiliares.periodo}</TableCell>
        <TableCell align='right'>{tipoComposicao}</TableCell>
        { possuiSubComposicao(composicaoComAuxiliares)
          ? (
            <TableCell align='right'>
              <Link onClick={() => handleVerComposicaoAuxiliar(composicaoComAuxiliares)} style={{ textDecoration: 'none' }}> Ver </Link>
            </TableCell>)
          : (<TableCell align='right' />) }
      </TableRow>
      <StyledDrawer
        open={openDrawer['right']}
        anchor={'right'}
        onClose={toggleDrawer('right', false)}>
        <RelatorioComposicao
          permitirEdicao
          composicao={composicaoComAuxiliares}
          onAtualizarItemCurvaABCComposicaoDuplicada={onAtualizarItemCurvaABCComposicaoDuplicada}
        />
      </StyledDrawer>
    </>
  )
}

ItemComposicaoAuxiliarRow.propTypes = {
  composicaoComAuxiliares: PropTypes.object.isRequired,
  handleVerComposicaoAuxiliar: PropTypes.func.isRequired,
  tipoComposicao: PropTypes.string.isRequired,
  classes: PropTypes.object,
  onAtualizarItemCurvaABCComposicaoDuplicada: PropTypes.func
}

export default ItemComposicaoAuxiliarRow
