import React, { useEffect, useState } from 'react'
import { Box, Button, Divider, Paper, Typography } from '@material-ui/core'
import useStyles from './styles'
import Grid from '@material-ui/core/Grid'
import AnaliseRiscoRelatorio from './AnaliseRiscoRelatorio'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import Temas from './AnaliseRiscoTemas'
import Link from '@material-ui/core/Link'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { showSuccessMsg } from '../../js/util'
import { rowsDatagridRisco2 } from './analise-risco'

const AnaliseRiscoEtapa2 = ({ orcamentos, onChangeEtapa }) => {
  const classes = useStyles()
  const [riscos, setRiscos] = useState([])
  const [temas, setTemas] = useState([])
  const [podeGerarRelatorio, setPodeGerarRelatorio] = useState(false)
  const [temasSelecionados, setTemasSelecionados] = useState([])

  useEffect(() => {
    const obterTemas = async () => {
      const { data } = await axios.get('/api/temas', SLOW_REQUEST_CONFIG)
      const temasAdicionais = data.map((it) => {
        it.title = it.nome
        return it
      })
      setTemas(temasAdicionais)
    }
    obterTemas()
  }, [setTemas])

  const handleExecutarAnaliseDeRisco = async () => {
    const filtro = {
      codigosOrcamentos: orcamentos.map((it) => it.codigo),
      codigosTemas: temasSelecionados.map((it) => it.codigo)
    }
    const { data } = await axios.post('/api/analise-risco/calcular-riscos', filtro, SLOW_REQUEST_CONFIG)
    if (data) {
      setRiscos(data)
      setPodeGerarRelatorio(true)
    }
  }

  const handleConcluir = async () => {
    const filtro = {
      codigosOrcamentos: orcamentos.map((it) => it.codigo),
      codigosTemas: temasSelecionados.map((it) => it.codigo)
    }
    await axios.post('/api/analise-risco/gerar-relatorio', filtro, SLOW_REQUEST_CONFIG)
    showSuccessMsg('Estamos processando seu relatório, em alguns instantes enviaremos por e-mail.')
  }

  const handleExportToCSV = () => {
    const headlines = [
        'Empreendimento;Obra;Tipo de Obra;Orçamento;Tipo;Valor Global;Risco;Data sistema referência;UF sistema referência;Custo paramétrico;Risco Tipo 1;Risco Tipo 2;Risco Tipo 3;Risco Tipo 4;Importância;BDI;BDI Máximo;Erro de cálculo;Diferença de preço em serviços idênticos;Sobrepreço da Amostra;% itens na amostra'
    ];
    const dataRows = rowsDatagridRisco2(orcamentos, riscos).map(row => {
        const [, ...rowDataWithoutFirstColumn] = Object.values(row); // Destructure to skip the first element
        return rowDataWithoutFirstColumn.join(';');
    });
    const csvContent = headlines.concat(dataRows).join('\n');
    const csvData = new Uint8Array([...unescape(encodeURIComponent(csvContent))].map(c => c.charCodeAt(0)));
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'analise-risco-relatorio.csv');
    link.click();
    URL.revokeObjectURL(url);
};




  return (
    <Box mt={2}>
      <Paper variant='outlined' elevation={0}>
        <Box p={2} style={{ minHeight: '50vh' }}>
          <Box my={1}>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link color='inherit' href='/'>
                Início
              </Link>
              <Typography color='primary'>
                Análise de Risco
              </Typography>
            </Breadcrumbs>
          </Box>
          <Grid container spacing={3}>
            <Grid item md={3}>
              <Box mb={4} p={2} className={classes.containerThemes}>
                <Temas
                  temas={temas}
                  setTemasSelecionados={setTemasSelecionados}
                />
                <Box py={2}>
                  <Divider />
                </Box>
                <Button
                  color='secondary'
                  variant='contained'
                  onClick={handleExecutarAnaliseDeRisco}
                  fullWidth
                >
                  Gerar relatório
                </Button>
              </Box>
            </Grid>
            <Grid item md={9}>
              <Box style={{ position: 'relative' }}>
                <Box display='flex' justifyContent='space-between'>
                  <Box display='flex'>
                    <Box py={1} className={classes.stepActive}>
                      <Typography
                        color='primary'
                        className={classes.stepTitle}
                      >
                        Etapa 1
                      </Typography>
                      <Typography
                        style={{ paddingRight: 20 }}
                        variant='caption'
                      >
                        Selecionar orçamentos
                      </Typography>
                    </Box>
                    <Box className={classes.stepActive} py={1}>
                      <Typography
                        color='primary'
                        style={{ paddingRight: 20 }}
                        className={classes.stepTitle}
                      >
                        <span />
                        Etapa 2
                      </Typography>
                      <Typography
                        style={{ paddingRight: 20 }}
                        variant='caption'
                      >
                        Gerar relatório de risco
                      </Typography>
                    </Box>
                  </Box>
                  <Box display='flex' alignItems='flex-end'>
                    <Button
                      color='default'
                      variant='outlined'
                      size='large'
                      style={{ marginRight: 4 }}
                      onClick={() => onChangeEtapa(1)}
                      disabled={orcamentos.length === 0}
                    >
                      Etapa Anterior
                    </Button>
                    <Button
                      color='primary'
                      variant='contained'
                      size='large'
                      style={{ marginRight: 4 }}
                      disabled={!podeGerarRelatorio}
                      onClick={handleConcluir}
                    >
                      Concluir e enviar relatório por e-mail
                    </Button>
                    <Button
                      color='primary'
                      variant='contained'
                      size='large'
                      disabled={!podeGerarRelatorio}
                      onClick={handleExportToCSV}
                    >
                      Exportar para CSV &#10515;
                    </Button>

                  </Box>
                </Box>
              </Box>
              <AnaliseRiscoRelatorio
                riscos={riscos}
                orcamentos={orcamentos}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  )
}

export default AnaliseRiscoEtapa2
