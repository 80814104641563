import React, { useState } from 'react'
import { Box, IconButton, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import ModalExcluirObra from './ModalExcluirObra'
import axios from 'axios'
import { SLOW_REQUEST_CONFIG } from '../../js/operationProgressUtil'
import { showSuccessMsg } from '../../js/util'
import useStyles from './styles'
import { useDispatch } from 'react-redux'
import excluirObra from '../../actions/obras/excluirObra'

const TabelaObras = ({ obras, onSelecionarObraEdicao }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [handleModalExcluirObra, setHandleModalExcluirObra] = useState(false)
  const [obraExcluida, setObraExcluida] = useState({})

  const confirmarExclusao = async () => {
    await axios.delete(`/api/empreendimentos/${obraExcluida.codEmpreendimento}/obras/${obraExcluida.cod}`, SLOW_REQUEST_CONFIG)
    showSuccessMsg(`Obra ${obraExcluida.nome} excluída com sucesso.`)
    dispatch(excluirObra(obraExcluida))
    setHandleModalExcluirObra(false)
  }

  const handleRedirectToOrcamento = (obra) => `/empreendimentos/${obra.codEmpreendimento}/obras/${obra.cod}/orcamentos`

  return (
    <>
      <Paper elevation={0} variant='outlined'>
        <Table
          className={classes.table}
          size='medium'
          aria-label='a dense table'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Orçamento</TableCell>
              <TableCell align='left'>Obra</TableCell>
              <TableCell align='left'>Unidade Jurisdicionada</TableCell>
              <TableCell align='left'>Tipo de Obra</TableCell>
              <TableCell align='left'>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {obras.map((obra) => (
              <TableRow key={obra.cod}>
                <TableCell component='th' scope='row'>
                  <Link
                    to={() => handleRedirectToOrcamento(obra)}
                    style={{ textDecoration: 'none' }}
                  >
                    <Typography variant='caption' color='primary'>
                      {obra.numeroOrcamentos || '0'} {obra.numeroOrcamentos === 1 ? ' cadastrado':' cadastrados'}
                    </Typography>
                  </Link>
                </TableCell>
                <TableCell align='left'>{obra.nome}</TableCell>
                <TableCell align='left'>{obra.uj ? obra.uj.nome : ''}</TableCell>
                <TableCell align='left'>{obra.tipoObra.descricao}</TableCell>
                <TableCell align='left'>
                  <Box>
                    <IconButton
                      color='primary'
                      aria-label='edição da obra'
                      component='span'
                      onClick={() => onSelecionarObraEdicao(obra)}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      color='primary'
                      aria-label='exclusao da obra'
                      component='span'
                      onClick={() => {
                        setHandleModalExcluirObra(true)
                        setObraExcluida(obra)
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
      <ModalExcluirObra
        handleModalExcluir={handleModalExcluirObra}
        onConfirmar={confirmarExclusao}
        onCancelar={() => setHandleModalExcluirObra(false)}
      />
    </>
  )
}

TabelaObras.propTypes = {
  obras: PropTypes.array,
  onSelecionarObraEdicao: PropTypes.func
}
export default TabelaObras
