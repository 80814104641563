import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionDetails, Box, Button, Typography } from '@material-ui/core'
import StyledAccordionSumary from '../../../../comum/StyledAccordionSumary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import SimplePopover from '../../../../comum/SimplePopover'
import AddIcon from '@material-ui/icons/Add'
import NenhumResultadoEncontrado from '../../analise-conteudo/content/comum/NenhumResultadoEncontrado'
import LinhaItemVinculado from '../vincular-expressao/LinhaItemVinculado'

const FormulasVinculadas = ({
  classes,
  formulas,
  onVincularFormula,
  onDesvinculaFormula
}) => {
  const [expandedPanel, setExpandedPanel] = useState(true)

  const handleChangePanel = (panel) => (event, newExpanded) => {
    setExpandedPanel(newExpanded ? panel : false)
  }

  const handleVincularFormula = (event) => {
    event.stopPropagation()
    onVincularFormula()
  }

  return (
    <Box mt={2}>
      <Accordion
        square
        className={classes.accordionAnaliseConteudo}
        expanded={expandedPanel === true}
        onChange={handleChangePanel(true)}
      >
        <StyledAccordionSumary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='accordionFormulasTematicas'
          className={classes.accordionSummaryAnalise}
        >
          <Box display='flex' alignItems='center'>
            <Typography variant='subtitle2' className={classes.tituloAccordionAnaliseConteudo}>
              Fórmulas
            </Typography>
            <SimplePopover>
              Operações numéricas que utilizam variáveis do orçamento e valores de expressões de busca.
              Uma fórmula pode ser utilizada para definir uma tipologia a ser considerada nas inconsistências do orçamento.
            </SimplePopover>

            <Box display='flex'>
              <Button
                variant='text'
                color='primary'
                onClick={(e) => handleVincularFormula(e)}
                style={{ marginRight: 5 }}
              >
                <AddIcon fontSize='small' color='primary' /> Adicionar
              </Button>
            </Box>
          </Box>
        </StyledAccordionSumary>
        <AccordionDetails style={{ display: 'grid' }}>
          {formulas.length === 0 && <NenhumResultadoEncontrado titulo='Nenhuma fórmula encontrada' subtitulo='Cadastre fórmulas personalizadas' />}
          {formulas.map(formula => (
            <LinhaItemVinculado
              key={formula.codFormula}
              classes={classes}
              codigo={formula.codFormula}
              nome={formula.nomeFormula}
              descricao={formula.descricaoFormula}
              tipologia={formula.tipologia}
              isSubItem={formula.subFormula}
              onDesvincular={onDesvinculaFormula}
            />))
          }
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

FormulasVinculadas.propTypes = {
  classes: PropTypes.object,
  formulas: PropTypes.array,
  onVincularFormula: PropTypes.func.isRequired,
  onDesvinculaFormula: PropTypes.func.isRequired
}

export default FormulasVinculadas
