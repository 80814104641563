import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { converterMoedaBRParaNumero, mapStateToPropsContexto, seStringVazia, showSuccessMsg } from '../../../../../../../../js/util'
import { context_httppost } from '../../../../../../../../js/httpRequest'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FormularioEquipamentoComposicao from './FormularioEquipamentoComposicao'

class FormularioAdicionarEquipamentoComposicao extends Component {
  static propTypes = {
    codigoComposicao: PropTypes.string.isRequired,
    onDadosAlterados: PropTypes.func.isRequired,
    onCancelar: PropTypes.func.isRequired,
    contexto: PropTypes.object
  }

  constructor (props) {
    super(props)
    this.state = {
      codigo: '',
      codigoEmpty: false,
      descricao: '',
      descricaoEmpty: false,
      quantidade: '0,00',
      quantidadeEmpty: false,
      utilizacaoOperativa: '1,00',
      utilizacaoOperativaEmpty: false,
      custoProdutivo: '0,00',
      custoProdutivoEmpty: false,
      custoImprodutivo: '0,00',
      custoImprodutivoEmpty: false
    }
    this.handleSalvarClick = this.handleSalvarClick.bind(this)
    this.handleCampoTextoChange = this.handleCampoTextoChange.bind(this)
  }

  handleCampoTextoChange (event, campo) {
    const state = {}
    state[campo] = event.target.value
    state[`${campo}Empty`] = seStringVazia(event.target.value)
    this.setState(state)
  }

  async handleSalvarClick () {
    const {
      codigo,
      descricao,
      quantidade,
      utilizacaoOperativa,
      custoProdutivo,
      custoImprodutivo
    } = this.state
    const codigoEmpty = seStringVazia(codigo)
    const descricaoEmpty = seStringVazia(descricao)
    const quantidadeEmpty = seStringVazia(quantidade)
    const utilizacaoOperativaEmpty = seStringVazia(utilizacaoOperativa)
    const custoProdutivoEmpty = seStringVazia(custoProdutivo)
    const custoImprodutivoEmpty = seStringVazia(custoImprodutivo)

    const empties = [
      codigoEmpty,
      descricaoEmpty,
      quantidadeEmpty,
      utilizacaoOperativaEmpty,
      custoProdutivoEmpty,
      custoImprodutivoEmpty
    ]

    if (empties.some(Boolean)) {
      this.setState({
        codigoEmpty,
        descricaoEmpty,
        quantidadeEmpty,
        utilizacaoOperativaEmpty,
        custoProdutivoEmpty,
        custoImprodutivoEmpty
      })
    } else {
      const dadosSalvar = {
        codigo,
        descricao,
        quantidade: converterMoedaBRParaNumero(quantidade),
        utilizacaoOperativa: converterMoedaBRParaNumero(utilizacaoOperativa),
        custoProdutivo: converterMoedaBRParaNumero(custoProdutivo),
        custoImprodutivo: converterMoedaBRParaNumero(custoImprodutivo)
      }
      const { contexto, codigoComposicao, onDadosAlterados } = this.props
      const dados = await context_httppost(`precos/equipamentos/criar?composicao=${codigoComposicao}`, contexto, dadosSalvar)
      if (dados) {
        showSuccessMsg('Equipamento criado e incluído na composição.')
        onDadosAlterados()
      }
    }
  }

  render () {
    const {
      codigo,
      codigoEmpty,
      descricao,
      descricaoEmpty,
      quantidade,
      quantidadeEmpty,
      utilizacaoOperativa,
      utilizacaoOperativaEmpty,
      custoProdutivo,
      custoProdutivoEmpty,
      custoImprodutivo,
      custoImprodutivoEmpty
    } = this.state

    const { onCancelar } = this.props

    return (
      <div>
        <Typography variant='h6' gutterBottom>Cadastrar Novo Equipamento</Typography>
        <FormularioEquipamentoComposicao
          codigo={codigo}
          codigoEmpty={codigoEmpty}
          codigoEditavel
          descricao={descricao}
          descricaoEmpty={descricaoEmpty}
          quantidade={quantidade}
          quantidadeEmpty={quantidadeEmpty}
          utilizacaoOperativa={utilizacaoOperativa}
          utilizacaoOperativaEmpty={utilizacaoOperativaEmpty}
          custoProdutivo={custoProdutivo}
          custoProdutivoEmpty={custoProdutivoEmpty}
          custoImprodutivo={custoImprodutivo}
          custoImprodutivoEmpty={custoImprodutivoEmpty}
          handleCampoTextoChange={this.handleCampoTextoChange}
          onSalvar={this.handleSalvarClick}
          onCancelar={onCancelar}
        />
      </div>
    )
  }
}

export default connect(mapStateToPropsContexto)(FormularioAdicionarEquipamentoComposicao)
